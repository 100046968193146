import React, { useCallback } from "react";
import cx from "classnames";
import { includesSegment } from "router5-helpers";

import { ReactComponent as IconPlus } from "../../../images/plus.svg";
import { SceneAssetsPanelContainer } from "./scene-assets-panel/scene-assets-panel-container";
import { BackgroundPanel } from "./background-and-transition-panel/background-panel";
import TransitionPanel from "./background-and-transition-panel/transition-panel";
import { SceneEditorActions } from "../scene-editor-reducer";
import { useDispatch, useSelector } from "react-redux";
import * as RoutingSelectors from "../../routing/routing-selectors";
import { RouteParamSelector } from "../../routing/routing-selectors";
import { useReplaceUrl } from "../../routing/routing-hooks";

export const LeftPanel = () => {
  const routeName = useSelector(RoutingSelectors.getRouteName);
  const routeParams = useSelector(
    RoutingSelectors.getRouteParams as RouteParamSelector<any>
  );

  const dispatch = useDispatch();

  const { replaceUrl } = useReplaceUrl();

  const includesRoute = includesSegment(routeName as string);

  const deselectAsset = useCallback(() => {
    dispatch(SceneEditorActions.deselectAsset);
  }, [dispatch]);

  const toggleAssetsLibrary = useCallback(() => {
    if (routeName && routeName.includes("asset-library")) {
      replaceUrl(routeName.replace(".asset-library", ""), {
        ...routeParams
      });
    } else {
      replaceUrl(`${routeName}.asset-library`, {
        ...routeParams
      });
    }
  }, [routeName, routeParams, replaceUrl]);

  const openSceneAssets = useCallback(() => {
    if (!routeName) {
      return;
    }
    replaceUrl(
      routeName
        .replace(".backgrounds", ".assets")
        .replace(".transitions", ".assets"),
      routeParams
    );
  }, [routeParams, replaceUrl, routeName]);

  const openBackground = useCallback(() => {
    if (!routeName) {
      return;
    }
    deselectAsset();

    replaceUrl(
      routeName
        .replace(".assets", ".backgrounds")
        .replace(".transitions", ".backgrounds"),
      { ...routeParams, libraryTab: "my-media" }
    );
  }, [routeParams, replaceUrl, routeName, deselectAsset]);

  const openTransition = useCallback(() => {
    if (!routeName) {
      return;
    }

    deselectAsset();

    replaceUrl(
      routeName
        .replace(".assets", ".transitions")
        .replace(".backgrounds", ".transitions"),
      { ...routeParams, libraryTab: "my-media" }
    );
  }, [routeParams, replaceUrl, routeName, deselectAsset]);

  const isSceneTabShown = includesRoute("assets");
  const isBackgroundTabShown = includesRoute("backgrounds");
  const isTransitionTabShown = includesRoute("transitions");
  const libraryButtonLabel = [
    ...(includesRoute("asset-library") ? ["Close"] : ["Open"]),
    ...(isBackgroundTabShown || isTransitionTabShown ? [] : ["Media"]),
    ...(isBackgroundTabShown ? ["Backgrounds"] : []),
    ...(isTransitionTabShown ? ["Transitions"] : [])
  ].join(" ");

  return (
    <div className="left-panel" data-qa="edit-scene-left-panel">
      <div className="assets">
        <h2 onClick={toggleAssetsLibrary} data-qa="open-library-btn">
          <button className="open-library-btn">
            <IconPlus className="icon icon-plus icon20" />
          </button>
          {libraryButtonLabel}
        </h2>
        <div className="left-panel-tabs">
          <button
            onClick={openSceneAssets}
            className={cx({ active: isSceneTabShown })}
            data-qa="tab-scene"
          >
            Scene
          </button>
          <button
            onClick={openBackground}
            className={cx({ active: isBackgroundTabShown })}
            data-qa="tab-background"
          >
            Background
          </button>
          <button
            onClick={openTransition}
            className={cx({ active: isTransitionTabShown })}
            data-qa="tab-transitions"
          >
            Transition
          </button>
        </div>
        {isSceneTabShown && <SceneAssetsPanelContainer />}
        {isBackgroundTabShown && <BackgroundPanel />}
        {isTransitionTabShown && <TransitionPanel />}
      </div>
    </div>
  );
};
