import React from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { formValues } from "redux-form";
import { SelectRoomField } from "../../forms/select-room-field";
import { TextField } from "../../forms/text-field";
import { PinField } from "../../forms/pin-field/pin-field";
import { TextareaField } from "../../forms/textarea-field";
import { DateTimeField } from "../../forms/datetime-field";
import {
  getBranding,
  hasQ32Features,
  hasTriggersEnabled
} from "../../config/config-selectors";
import { withAcl } from "../../acl/with-acl";
import { ToggleField } from "../../forms/toggle-field";
import { getUser } from "../../auth/auth-selectors";
import { PresentationTriggersButton } from "./presentation-triggers-button";

import { ReactComponent as IconInfo } from "../../../images/info.svg";
import { ReactComponent as IconWarning } from "../../../images/warning.svg";
import { FiltersBlock } from "../../settings/custom-fields/components/filters/filters-block";

import { AclResource } from "../../../../shared/acl";
import { getPresentationCustomFieldMappings } from "../../settings/custom-fields/custom-fields-selectors";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

// eslint-disable-next-line react/prop-types
const NotCreatorMessage = ({ pinProtected }) =>
  pinProtected ? (
    <div className="message messageError">
      <IconWarning className="messageIcon" />
      <div className="messageTitle">PIN Protected</div>
      <div className="messageBody">
        This presentation is PIN protected! Only the creator of this
        presentation can change the PIN.
      </div>
    </div>
  ) : (
    <div className="message messageInfo">
      <IconInfo className="messageIcon" />
      <div className="messageBody">
        This presentation is not PIN protected. Only the creator of this
        presentation can set the PIN.
      </div>
    </div>
  );

const PresentationFormImpl = ({
  hasAccess,
  roomName,
  remoteAccessName,
  blackbelt,
  instances,
  blackbeltInstance,
  creatorId,
  pinProtected,
  resetBlackbeltInstance,
  onSubmit,
  handleSubmit,
  currentUser,
  q32Features,
  triggersEnabled,
  showTriggersButton
}) => {
  const customFieldMappings = useSelector(getPresentationCustomFieldMappings);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="title"
        label="Title"
        required
        dataQa={FORM_LOCATORS.inputs.getInput("title")}
      />
      <SelectRoomField
        name="instances"
        required
        isClearable
        isMulti={true}
        label={`${roomName}s`}
        onChange={(_, values) => {
          if (blackbeltInstance && !values.includes(blackbeltInstance)) {
            resetBlackbeltInstance();
          }
        }}
        dataQa={FORM_LOCATORS.inputs.getInput("room")}
      />
      {hasAccess(AclResource.MAKE_PRESENTATION_GLOBAL) && (
        <ToggleField
          name="public"
          dataQa={FORM_LOCATORS.toggles.getToggle("public")}
        >
          Public
        </ToggleField>
      )}
      <ToggleField
        name="blackbelt"
        dataQa={FORM_LOCATORS.toggles.getToggle("remote-access")}
      >
        {remoteAccessName}
      </ToggleField>
      {blackbelt && (
        <React.Fragment>
          <TextField
            name="blackbeltEmail"
            label="E-mail"
            required
            dataQa={FORM_LOCATORS.inputs.getInput("email")}
          />
          <DateTimeField
            label="Start"
            name="blackbeltFrom"
            dataQa={FORM_LOCATORS.inputs.getInput("remote-access-start")}
          />
          <DateTimeField
            label="End"
            name="blackbeltTill"
            dataQa={FORM_LOCATORS.inputs.getInput("remote-access-end")}
          />
          <SelectRoomField
            name="blackbeltInstance"
            required
            isClearable
            isMulti={false}
            label={roomName}
            filterOption={({ data }, query) => {
              return (
                instances &&
                instances.includes(data.uuid) &&
                data.name.indexOf(query) >= 0
              );
            }}
            dataQa={FORM_LOCATORS.inputs.getInput("remote-access-room")}
          />
        </React.Fragment>
      )}
      {q32Features && (
        <ToggleField
          disabled={currentUser.id !== creatorId}
          name="pinProtected"
          dataQa={FORM_LOCATORS.toggles.getToggle("pin")}
        >
          PIN protection
        </ToggleField>
      )}
      {pinProtected && currentUser.id === creatorId && (
        <>
          {/* hidden input helps to prevent password autocomplete */}
          <input
            autoComplete="off"
            name="hidden"
            type="password"
            className="displayNone"
          />
          <PinField
            name="pin"
            required
            dataQa={FORM_LOCATORS.inputs.getInput("pin")}
          />
        </>
      )}
      {currentUser.id !== creatorId && (
        <NotCreatorMessage pinProtected={pinProtected} />
      )}
      {showTriggersButton && triggersEnabled && (
        <PresentationTriggersButton instances={instances} />
      )}
      <TextareaField
        name="description"
        label="Description"
        required
        dataQa={FORM_LOCATORS.inputs.getInput("description")}
      />
      <FiltersBlock
        // TODO: use hierarchies
        customFieldHierarchies={customFieldMappings}
        parentId={null}
      />
    </form>
  );
};

PresentationFormImpl.propTypes = {
  hasAccess: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  remoteAccessName: PropTypes.string.isRequired,
  creatorId: PropTypes.number,
  pinProtected: PropTypes.bool,
  blackbelt: PropTypes.bool,
  instances: PropTypes.arrayOf(PropTypes.string.isRequired),
  blackbeltInstance: PropTypes.string,
  resetBlackbeltInstance: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  q32Features: PropTypes.bool.isRequired,
  triggersEnabled: PropTypes.bool.isRequired,
  showTriggersButton: PropTypes.bool.isRequired
};

export const PresentationForm = connect((state) => ({
  roomName: getBranding(state).roomName,
  remoteAccessName: getBranding(state).remoteAccessName,
  currentUser: getUser(state),
  q32Features: hasQ32Features(state),
  triggersEnabled: hasTriggersEnabled(state)
}))(
  withAcl(
    formValues(
      "creatorId",
      "pinProtected",
      "blackbelt",
      "instances",
      "blackbeltInstance"
    )(PresentationFormImpl)
  )
);
