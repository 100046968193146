import { toast } from "react-toastify";
import { all, call, takeEvery } from "redux-saga/effects";
import sprintf from "sprintf";

import Actions from "./notification-actions";

export const NOTIFICATION_SUCCESS = "success";
export const NOTIFICATION_ERROR = "error";
export const NOTIFICATION_WARN = "warn";
export const NOTIFICATION_INFO = "info";

function initNotifications() {
  toast.configure({
    autoClose: 2000,
    hideProgressBar: true
  });
}

function* notify(level, message, options) {
  const { replace = [], ...toastOptions } = options;
  const translatedMessage = sprintf(message, ...replace);

  yield toast[level](translatedMessage, toastOptions);
}

const createNotifier = (level, defaultOptions) => (message, options) =>
  notify(level, message, { ...defaultOptions, ...options });

const createNotifyActionHandler = (level, defaultOptions) => ({
  message,
  options
}) => notify(level, message, { ...defaultOptions, ...options });

const failDefaultOption = {
  autoClose: false
};

export const notifyInfo = createNotifier(NOTIFICATION_INFO);
export const notifySuccess = createNotifier(NOTIFICATION_SUCCESS);
export const notifyWarn = createNotifier(NOTIFICATION_WARN, failDefaultOption);
export const notifyError = createNotifier(
  NOTIFICATION_ERROR,
  failDefaultOption
);
export function* updateNotification(id, options) {
  const { replace = [], render, ...toastOptions } = options;

  yield toast.update(id, {
    ...toastOptions,
    render: typeof render === "string" ? sprintf(render, ...replace) : undefined
  });
}

export default function* () {
  yield all([
    call(initNotifications),

    // Actions below should be used just for backwards
    // compatibility with old code, which used actions.
    takeEvery(
      Actions.Types.NOTIFY_INFO,
      createNotifyActionHandler(NOTIFICATION_INFO)
    ),
    takeEvery(
      Actions.Types.NOTIFY_SUCCESS,
      createNotifyActionHandler(NOTIFICATION_SUCCESS)
    ),
    takeEvery(
      Actions.Types.NOTIFY_WARN,
      createNotifyActionHandler(NOTIFICATION_WARN, failDefaultOption)
    ),
    takeEvery(
      Actions.Types.NOTIFY_ERROR,
      createNotifyActionHandler(NOTIFICATION_ERROR, failDefaultOption)
    ),
    takeEvery(Actions.Types.UPDATE_NOTIFICATION, ({ id, options }) =>
      updateNotification(id, options)
    )
  ]);
}
