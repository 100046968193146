import React, { useEffect, useState } from "react";
import cx from "classnames";

import { useChangeRouteParam } from "../../../routing/routing-hooks";
import { AssetLibraryMedia } from "./asset-library-media";
import { AssetLibraryMediaShared } from "./asset-library-shared";
import { useSelector } from "react-redux";
import { getRouteName } from "../../../routing/routing-selectors";

enum AssetLibraryTab {
  MY_MEDIA = "my-media",
  SHARED = "shared"
}

const ASSET_LIBRARY_TABS_MEDIA: {
  route: AssetLibraryTab | undefined;
  label: string;
}[] = [
  { route: AssetLibraryTab.MY_MEDIA, label: "My Media" },
  { route: AssetLibraryTab.SHARED, label: "Company Library" }
];

const ASSET_LIBRARY_TABS_OTHER: {
  route: AssetLibraryTab | undefined;
  label: string;
}[] = [];

export const AssetLibrary = () => {
  const { navigate, param: routeParam } = useChangeRouteParam<AssetLibraryTab>(
    "libraryTab"
  );
  const [tabs, setTabs] = useState<typeof ASSET_LIBRARY_TABS_MEDIA>([]);
  const routeName = useSelector(getRouteName);

  useEffect(() => {
    if (
      routeName &&
      (routeName.includes("background") || routeName.includes("transition"))
    ) {
      setTabs(ASSET_LIBRARY_TABS_OTHER);
    } else {
      setTabs(ASSET_LIBRARY_TABS_MEDIA);
    }
  }, [routeName]);

  //TODO create multi-level tabs
  return (
    <>
      <div className="tabs">
        <div className="tabHeader">
          <ul className="tabNav">
            {tabs.map((tab) => {
              return (
                <li
                  className={cx("item", {
                    active: tab.route === routeParam
                  })}
                  onClick={() => navigate(tab.route ? tab.route : "")}
                  key={tab.route}
                  data-qa={`asset-library-tab-${tab.route}`}
                >
                  {tab.label}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {routeParam === AssetLibraryTab.MY_MEDIA && <AssetLibraryMedia />}
      {routeParam === AssetLibraryTab.SHARED && <AssetLibraryMediaShared />}
    </>
  );
};
