import { Logger as logger } from "purplex-logging";
import { buffers, eventChannel, END } from "redux-saga";

export function createControlledFileUpload<T>(url: string, body: FormData) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "json";
  let endEmitted = false;

  return {
    abort: () => xhr.abort(),
    channel: eventChannel((emitter) => {
      const onProgress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          emitter({ progress: event.loaded });
        }
      };

      const onFailure = () => {
        emitter({ err: new Error("Upload failed") });
        onEnd();
      };

      const onAbort = () => {
        emitter({ canceled: true });
        onEnd();
      };

      const onEnd = () => {
        endEmitted = true;
        emitter({ ended: true });
        emitter(END);
      };

      xhr.upload.addEventListener("progress", onProgress);
      xhr.upload.addEventListener("error", onFailure);
      xhr.upload.addEventListener("abort", onAbort);
      xhr.onreadystatechange = () => {
        const { readyState, status } = xhr;
        if (readyState === 4) {
          // if all is ok then status is 200, if the request was aborted, then
          // the status can be 0
          if (status === 200) {
            Promise.resolve()
              .then(() => {
                if (typeof xhr.response === "object") {
                  return xhr.response.data as T;
                } else {
                  logger.warn(
                    `Upload result is not an json object`,
                    xhr.response
                  );
                  return null;
                }
              })
              .then((unwrapped) => {
                emitter({ success: true, responseData: unwrapped });
                onEnd();
              })
              .catch((error) => {
                logger.warn(`Upload file error`, error);
                onFailure();
              });
          } else if (status !== 0) {
            onFailure();
          }
        }
      };
      xhr.open("PUT", url, true);
      xhr.send(body);

      return () => {
        xhr.abort();
        xhr.upload.removeEventListener("progress", onProgress);
        xhr.upload.removeEventListener("error", onFailure);
        xhr.upload.removeEventListener("abort", onAbort);
        xhr.onreadystatechange = null;

        if (!endEmitted) {
          onEnd();
        }
      };
    }, buffers.sliding(5))
  };
}

export function createFileUploadChannel(url: string, body: FormData) {
  return createControlledFileUpload(url, body).channel;
}
