import React from "react";
import { useSelector } from "react-redux";
import { MediaDialogType } from "./media-dialog/media-dialog-type";
import { getDialogType } from "../media-selectors";
import { AddMedia } from "./media-dialog/add-media";
import { AddSharedAsset } from "./shared-library/add-shared-asset";

export const UploadMedia = () => {
  const dialogType = useSelector(getDialogType);

  if (!dialogType) {
    return <></>;
  }

  switch (dialogType) {
    case MediaDialogType.WALL_ASSET:
    case MediaDialogType.ASSET:
      return <AddMedia />;
    case MediaDialogType.SHARED_ASSET:
      return <AddSharedAsset />;
    default:
      throw new Error(`Illegal state ${dialogType}`);
  }
};
