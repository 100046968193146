import cx from "classnames";
import React, { useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditingTextAssetId,
  getActiveWallInfo
} from "../../scene-editor-selectors";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { TextAssetAlignment, TextAssetFontSize } from "shared/types/asset";
import { useSceneEditorActions } from "../../hooks/asset-config/use-scene-editor-actions";

const getMouseEventCaretRange = (event: React.MouseEvent) =>
  document.caretRangeFromPoint(event.clientX, event.clientY);

const selectRange = (range: Range) => {
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

const calculateFontSize = (fontSize: TextAssetFontSize, wallHeight: number) => {
  switch (fontSize) {
    case TextAssetFontSize.XS:
      return Math.round(wallHeight * 0.035);
    case TextAssetFontSize.S:
      return Math.round(wallHeight * 0.06);
    case TextAssetFontSize.L:
      return Math.round(wallHeight * 0.11);
    case TextAssetFontSize.XL:
      return Math.round(wallHeight * 0.135);
    case TextAssetFontSize.XXL:
      return Math.round(wallHeight * 0.16);

    case TextAssetFontSize.M:
    default:
      return Math.round(wallHeight * 0.085);
  }
};

export const AssetText = ({
  sceneAssetId,
  sceneAssetUuid,
  fontSize,
  font,
  bold,
  italic,
  underline,
  alignment,
  canvasScale,
  color,
  textContent,
  height,
  width
}: {
  assetUuid: string;
  sceneAssetId: number;
  sceneAssetUuid: string;
  fontSize: TextAssetFontSize;
  font: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  alignment: TextAssetAlignment;
  canvasScale: number;
  color: string;
  textContent: string;
  width: number;
  height: number;
}) => {
  const dispatch = useDispatch();
  const activeWallInfo = useSelector(getActiveWallInfo);
  const onSelectTextAsset = useCallback(() => {
    dispatch(SceneEditorActions.setEditingTextAsset(sceneAssetId));
  }, [dispatch, sceneAssetId]);
  const { actionsMap } = useSceneEditorActions();

  const changeText = useCallback(
    (text: string) => {
      actionsMap.CHANGE_TEXT_ASSET_TEXT(text, true, [sceneAssetUuid]);
    },
    [actionsMap, sceneAssetUuid]
  );

  const editingTextAssetId = useSelector(getEditingTextAssetId);
  const editing = editingTextAssetId === sceneAssetId;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editing && ref.current) {
      ref.current.focus();
    }
  }, [ref, editing]);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = textContent;
    }
  }, [textContent]);

  const onBlur = useCallback(
    (ev: React.SyntheticEvent) => {
      const text = (ev.target as HTMLInputElement).innerHTML;
      changeText(text);
      dispatch(SceneEditorActions.setEditingTextAsset(null));
      if (ref.current) {
        ref.current.scroll({ top: 0, left: 0 });
      }
    },
    [dispatch, changeText]
  );

  const onDoubleClick = useCallback(
    (ev: React.MouseEvent) => {
      if (!editing) {
        ev.preventDefault();
        ev.stopPropagation();

        const caretRange = getMouseEventCaretRange(ev);
        onSelectTextAsset();
        window.setTimeout(() => {
          selectRange(caretRange);
        }, 10);
      }
    },
    [editing, onSelectTextAsset]
  );

  const onPasteStripHtml = useCallback((event: React.ClipboardEvent) => {
    if (event.clipboardData) {
      const paste = event.clipboardData.getData("text");

      const selection = window.getSelection();
      if (selection && selection.rangeCount) {
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      }

      event.preventDefault();
    }
  }, []);

  if (!activeWallInfo) {
    return <></>;
  }

  return (
    <div
      ref={ref}
      contentEditable={editing}
      onDoubleClick={onDoubleClick}
      className={cx({
        bold,
        italic,
        underline
      })}
      onPaste={onPasteStripHtml}
      data-qa={`text-asset`}
      style={{
        zIndex: 999,
        position: "relative",
        width,
        height,
        padding: `${activeWallInfo.height * 0.025}px`,
        overflow: "hidden",
        fontSize: `${calculateFontSize(fontSize, activeWallInfo.height)}px`,
        cursor: editing ? "text" : "default",
        textAlign: alignment,
        fontFamily: font,
        outline: "none",
        boxShadow: editing
          ? `inset 0 0 0 ${1 / canvasScale}px #ff9100`
          : "none",
        color,
        wordBreak: "break-word",
        display: "inline-block"
      }}
      onBlur={onBlur}
    />
  );
};
