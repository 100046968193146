import React from "react";
import PropTypes from "prop-types";

export const PinInputBox = ({
  value,
  type,
  inputRef,
  onChange,
  onFocusLeft,
  onFocusRight,
  onClearLeft,
  onClearRight,
  dataQa
}) => {
  const onKeyDown = (ev) => {
    const { selectionStart, selectionEnd } = ev.target;

    if (selectionStart !== selectionEnd) {
      return;
    }

    switch (ev.key) {
      case "ArrowRight":
        if (selectionStart === 1 || ev.target.value === "") {
          onFocusRight();
          ev.stopPropagation();
          ev.preventDefault();
        }
        break;

      case "ArrowLeft":
        if (selectionStart === 0 || ev.target.value === "") {
          onFocusLeft();
          ev.stopPropagation();
          ev.preventDefault();
        }
        break;

      case "Backspace":
        if (selectionStart === 0) {
          onClearLeft();
          ev.stopPropagation();
          ev.preventDefault();
        }
        break;

      case "Delete":
        if (selectionStart === 1 || ev.target.value === "") {
          onClearRight();
          ev.stopPropagation();
          ev.preventDefault();
        }
        break;

      default:
    }
  };

  const onKeyUp = (ev) => {
    const valid = /[0-9]/.test(ev.key);

    if (valid && ev.key === ev.target.value) {
      onFocusRight();
    }
  };

  const onInputChange = ({ target }) => {
    const valid = /^[0-9]?$/.test(target.value);

    if (!valid) {
      // cancel the change
      target.value = value;
    }

    onChange();
  };

  const onInputFocus = ({ target }) => {
    target.select();
  };

  return (
    <input
      className="input"
      autoComplete="off"
      spellCheck="false"
      inputMode="numeric"
      minLength="1"
      maxLength="1"
      ref={inputRef}
      type={type}
      value={value}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onChange={onInputChange}
      onFocus={onInputFocus}
      autoFocus={inputRef === 1}
      data-qa={dataQa}
    />
  );
};

PinInputBox.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inputRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocusLeft: PropTypes.func.isRequired,
  onFocusRight: PropTypes.func.isRequired,
  onClearLeft: PropTypes.func.isRequired,
  onClearRight: PropTypes.func.isRequired,
  dataQa: PropTypes.string
};
