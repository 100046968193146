import React, { useCallback, useEffect, useRef, useState } from "react";
import { Logger as logger } from "purplex-logging";
import { useDispatch } from "react-redux";
import { SceneEditorActions } from "../../scene-editor-reducer";
import {
  useBottomEdgeGuide,
  useHorizontalCenterGuide,
  useLeftEdgeGuide,
  useRightEdgeGuide,
  useTopEdgeGuide,
  useVerticalCenterGuide
} from "./utils/use-edit-background-positioning-guides";
import { useGetMouseMovement } from "./utils/use-mouse-movement";

export const useEditBackgroundPositioning = (
  canvasScale: number,
  wallHeight: number,
  totalWallWidth: number,
  assetHeight: number,
  assetWidth: number,
  assetTop: number,
  assetLeft: number,
  onUpdateBackground: (data: {
    left?: number;
    top?: number;
    height?: number;
    width?: number;
  }) => void
) => {
  const [draggingState, setDraggingState] = useState({
    draggingOffsetY: 0,
    draggingOffsetX: 0,
    dragging: false
  });

  const offsetRef = useRef({
    draggingOffsetY: 0,
    draggingOffsetX: 0
  });

  const dispatch = useDispatch();

  const {
    isHorizontalCenterGuideShown,
    applyHorizontalCenterGuide
  } = useHorizontalCenterGuide();
  const {
    isVerticalCenterGuideShown,
    applyVerticalCenterGuide
  } = useVerticalCenterGuide();
  const { applyLeftEdgeGuide } = useLeftEdgeGuide();
  const { applyTopEdgeGuide } = useTopEdgeGuide();
  const { applyRightEdgeGuide } = useRightEdgeGuide();
  const { applyBottomEdgeGuide } = useBottomEdgeGuide();
  const getMouseMovement = useGetMouseMovement();

  const applyDraggingGuides = useCallback(
    (
      currentPosition: { draggingOffsetX: number; draggingOffsetY: number },
      canvasScale: number,
      wallHeight: number,
      totalWallWidth: number,
      assetHeight: number,
      assetWidth: number,
      assetTop: number,
      assetLeft: number
    ) => {
      let position = { ...currentPosition };
      position = applyHorizontalCenterGuide(
        position,
        canvasScale,
        wallHeight,
        assetHeight,
        assetTop
      );
      position = applyVerticalCenterGuide(
        position,
        canvasScale,
        totalWallWidth,
        assetWidth,
        assetLeft
      );
      position = applyLeftEdgeGuide(position, canvasScale, assetLeft);
      position = applyTopEdgeGuide(position, canvasScale, assetTop);
      position = applyRightEdgeGuide(
        position,
        canvasScale,
        totalWallWidth,
        assetWidth,
        assetLeft
      );
      position = applyBottomEdgeGuide(
        position,
        canvasScale,
        wallHeight,
        assetHeight,
        assetTop
      );

      return position;
    },
    [
      applyHorizontalCenterGuide,
      applyVerticalCenterGuide,
      applyLeftEdgeGuide,
      applyRightEdgeGuide,
      applyBottomEdgeGuide,
      applyTopEdgeGuide
    ]
  );

  const mouseDownHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.button !== 0) {
        return;
      }
      logger.debug("Dragging background start.");
      setDraggingState((state) => ({ ...state, dragging: true }));
    },
    []
  );

  const mouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      if (draggingState.dragging) {
        const { movementX, movementY } = getMouseMovement(e);

        const currentPosition = {
          draggingOffsetX: offsetRef.current.draggingOffsetX - movementX,
          draggingOffsetY: offsetRef.current.draggingOffsetY - movementY
        };
        offsetRef.current = currentPosition;
        const transformedPosition = applyDraggingGuides(
          currentPosition,
          canvasScale,
          wallHeight,
          totalWallWidth,
          assetHeight,
          assetWidth,
          assetTop,
          assetLeft
        );

        logger.debug("Moving background", transformedPosition);

        setDraggingState({
          dragging: true,
          ...transformedPosition
        });
        dispatch(
          //Needs to be in absolute coordinates as it is rendered on different canvas with different scale
          SceneEditorActions.setEditBackgroundDraggingOffset({
            offsetX: transformedPosition.draggingOffsetX / canvasScale,
            offsetY: transformedPosition.draggingOffsetY / canvasScale
          })
        );
      }
    },
    [
      draggingState.dragging,
      canvasScale,
      wallHeight,
      totalWallWidth,
      assetHeight,
      assetLeft,
      assetTop,
      assetWidth,
      applyDraggingGuides,
      dispatch,
      getMouseMovement
    ]
  );

  const mouseUpHandler = useCallback(() => {
    if (draggingState.dragging) {
      logger.debug("Dragging finished", offsetRef.current);

      const transformedPosition = applyDraggingGuides(
        offsetRef.current,
        canvasScale,
        wallHeight,
        totalWallWidth,
        assetHeight,
        assetWidth,
        assetTop,
        assetLeft
      );

      onUpdateBackground({
        left: Math.round(
          assetLeft + transformedPosition.draggingOffsetX / canvasScale
        ),
        top: Math.round(
          assetTop + transformedPosition.draggingOffsetY / canvasScale
        )
      });
      setDraggingState({
        dragging: false,
        draggingOffsetX: 0,
        draggingOffsetY: 0
      });
      offsetRef.current = { draggingOffsetY: 0, draggingOffsetX: 0 };
      dispatch(
        SceneEditorActions.setEditBackgroundDraggingOffset({
          offsetX: 0,
          offsetY: 0
        })
      );
    }
  }, [
    draggingState.dragging,
    offsetRef,
    dispatch,
    applyDraggingGuides,
    canvasScale,
    wallHeight,
    totalWallWidth,
    assetLeft,
    assetTop,
    assetHeight,
    assetWidth,
    onUpdateBackground
  ]);

  const draggingContextMenuHandler = useCallback(
    (e: MouseEvent) => {
      if (draggingState.dragging) {
        e.preventDefault();
      }
    },
    [draggingState.dragging]
  );

  useEffect(() => {
    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
    window.addEventListener("contextmenu", draggingContextMenuHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      window.removeEventListener("contextmenu", draggingContextMenuHandler);
    };
  }, [mouseUpHandler, mouseMoveHandler, draggingContextMenuHandler]);

  return {
    draggingState,
    mouseDownHandler,
    isHorizontalCenterGuideShown:
      isHorizontalCenterGuideShown && draggingState.dragging,
    isVerticalCenterGuideShown:
      isVerticalCenterGuideShown && draggingState.dragging
  };
};
