import { getDefaultEditorValues } from "./../../../settings/editor-settings/editor-settings-selectors";
import { useCallback } from "react";
import { Logger as logger } from "purplex-logging";
import { useDispatch, useStore } from "react-redux";
import { v4 } from "uuid";

import { AssetType } from "../../../../../shared/types/asset-type";
import { useSceneEditorUtils } from "./use-scene-editor-utils";
import {
  getActiveWallInfo,
  getPresentationId,
  getScene,
  getSelectedSceneAssetUuids,
  getSelectedSceneAssets
} from "../../scene-editor-selectors";
import { getUser } from "../../../auth/auth-selectors";
import {
  getRouteParams,
  RouteParamSelector
} from "../../../routing/routing-selectors";
import * as UserTrackingCreators from "../../../user-tracking/user-tracking-creators";
import { SceneAssetViewModel } from "../../../entity-repository/entity.types";
import { SceneAssetBulkOperationFlag } from "../../../../../shared/types/scene-asset";
import { useTracking } from "../../../user-tracking/use-tracking";
import { UserTrackingActions } from "../../../user-tracking/user-tracking-slice";
import { useDeselectAssets } from "../use-select-asset";
import {
  presentation as presentationSchema,
  scene as sceneSchema
} from "../../../entity-repository/schema";
import { useNormalizeAndStore } from "../use-normalize-and-store";
import { useApi } from "../../../api/use-api";

export const useSceneAssetCrudActions = () => {
  const store = useStore();
  const {
    updateAssetsWithResponseStoreUpdate,
    deleteAssetsWithResponseStoreUpdate
  } = useSceneEditorUtils();
  const dispatch = useDispatch();
  const track = useTracking();
  const deselectAssets = useDeselectAssets();
  const normalizeAndStore = useNormalizeAndStore();
  const api = useApi();

  const deleteAssets = useCallback(
    async (assetsUuids: string[]) => {
      const scene = getScene(store.getState());
      const presentationId = getPresentationId(store.getState());
      const selectedSceneAssetIds = getSelectedSceneAssetUuids(
        store.getState()
      );

      if (!scene || !selectedSceneAssetIds) {
        return;
      }

      const assetsToBeDeleted = scene.sceneAssets.filter((asset) =>
        assetsUuids.includes(asset.uuid)
      );
      assetsToBeDeleted.forEach((asset) => {
        if (selectedSceneAssetIds.includes(asset.uuid)) {
          deselectAssets([asset.uuid]);
        }
      });

      const updatedAssets = scene.sceneAssets
        .filter((asset) =>
          assetsToBeDeleted.map((asset) => asset.uuid).includes(asset.uuid)
        )
        .map((asset) => {
          return {
            ...asset,
            operationFlag: SceneAssetBulkOperationFlag.DELETE
          };
        });

      await deleteAssetsWithResponseStoreUpdate(updatedAssets);

      const { roles } = getUser(store.getState());
      assetsToBeDeleted.forEach((asset) => {
        track(
          UserTrackingCreators.trackSceneEditorSceneAssetDetached(
            asset.asset.type,
            roles
          )
        );
      });

      if (presentationId) {
        // some asset actions might have been deleted from the presentation as well
        const presentation = await api.getPresentation(presentationId);
        normalizeAndStore(presentation, presentationSchema);
      }
    },
    [
      store,
      deleteAssetsWithResponseStoreUpdate,
      api,
      normalizeAndStore,
      deselectAssets,
      track
    ]
  );

  const createSceneAssets = useCallback(
    async (
      assets: {
        asset: any;
        index: number;
        wallNumber: number;
        attributes?: Partial<SceneAssetViewModel>;
      }[]
    ) => {
      logger.debug("Creating scene assets", assets);
      const scene = getScene(store.getState());
      const defaultValues = getDefaultEditorValues(store.getState());
      if (
        !scene ||
        !scene.instance ||
        !scene.instance.walls ||
        !defaultValues
      ) {
        return;
      }

      const updatedSceneAssets: Partial<
        SceneAssetViewModel & {
          operationFlag?: SceneAssetBulkOperationFlag;
          id: number;
        }
      >[] = scene.sceneAssets
        .map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          layer: asset.layer
        }))
        .sort((a, b) => b.layer - a.layer);

      assets.map(({ asset, index, wallNumber, attributes }) => {
        if (!scene || !scene.instance || !scene.instance.walls) {
          return;
        }

        const assetWall = scene.instance.walls.find(
          ({ num }) => num === wallNumber
        );

        if (!assetWall) {
          return;
        }
        const { start } = assetWall;

        if (index > scene.sceneAssets.length) {
          logger.warn(
            `Cannot create scene asset on index=${index}, the index is out range ${scene.sceneAssets.length}`
          );
          return;
        }

        updatedSceneAssets.splice(index, 0, {
          uuid: v4(),
          sceneId: scene.id,
          asset,
          left: attributes ? attributes.left : start,
          top: attributes ? attributes.top : 0,
          scale: attributes ? attributes.scale : 100,
          width: attributes ? attributes.width : asset.dimensions.width,
          height: attributes ? attributes.height : asset.dimensions.height,
          visible: attributes
            ? attributes.visible
            : defaultValues.EDITOR_DEFAULT_VISIBLE_ON_START,
          opacity: attributes
            ? attributes.opacity
            : defaultValues.EDITOR_DEFAULT_OPACITY,
          loop: attributes
            ? attributes.loop
            : defaultValues.EDITOR_DEFAULT_LOOP,
          autoplay: attributes
            ? attributes.autoplay
            : defaultValues.EDITOR_DEFAULT_AUTOPLAY,
          rounded: attributes
            ? attributes.rounded
            : defaultValues.EDITOR_DEFAULT_ROUNDED,
          glow: attributes
            ? attributes.glow
            : defaultValues.EDITOR_DEFAULT_GLOW,
          cropWidth: attributes ? attributes.cropWidth : asset.dimensions.width,
          cropHeight: attributes
            ? attributes.cropHeight
            : asset.dimensions.height,
          cropOffsetX: attributes ? attributes.cropOffsetX : 0,
          cropOffsetY: attributes ? attributes.cropOffsetY : 0,
          operationFlag: SceneAssetBulkOperationFlag.CREATE,
          positionLocked: attributes
            ? attributes.positionLocked
            : defaultValues.EDITOR_DEFAULT_POSITION_LOCKED
        });
      });

      const sortedUpdatedAssets = updatedSceneAssets.map(
        (asset, assetIndex) => ({
          ...asset,
          layer: updatedSceneAssets.length - assetIndex - 1
        })
      );

      await updateAssetsWithResponseStoreUpdate(sortedUpdatedAssets as any);

      const { roles } = getUser(store.getState());
      const { libraryTab } = (getRouteParams as RouteParamSelector<{
        libraryTab: string;
      }>)(store.getState());

      dispatch(UserTrackingActions.createSceneAssetTrackingEvent());
      assets.forEach(({ asset }) => {
        track(
          UserTrackingCreators.trackSceneEditorSceneAssetAdded(
            asset.type,
            roles,
            scene.title,
            libraryTab
          )
        );
      });
    },
    [store, track, updateAssetsWithResponseStoreUpdate, dispatch]
  );

  const cloneAssets = useCallback(async () => {
    const selectedSceneAssets = getSelectedSceneAssets(store.getState());
    const scene = getScene(store.getState());
    const activeWallInfo = getActiveWallInfo(store.getState());

    if (!scene || !activeWallInfo) {
      return;
    }

    const textAssets = selectedSceneAssets.filter(
      (sceneAsset) => sceneAsset.asset.type === AssetType.ASSET_TEXT
    );
    const otherAssets = selectedSceneAssets.filter(
      (sceneAsset) => sceneAsset.asset.type !== AssetType.ASSET_TEXT
    );

    await Promise.all(
      textAssets.map(async (sceneAsset) => {
        const updatedScene = await api.createTextAsset(
          scene.id,
          sceneAsset.asset.title,
          sceneAsset.asset.metadata.fontSize as string,
          sceneAsset.width,
          sceneAsset.height,
          !!sceneAsset.asset.metadata.bold,
          !!sceneAsset.asset.metadata.italic,
          !!sceneAsset.asset.metadata.underline,
          sceneAsset.asset.metadata.alignment as string,
          sceneAsset.asset.metadata.color as string,
          sceneAsset.asset.metadata.font as string,
          Math.round(sceneAsset.left),
          Math.round(sceneAsset.top),
          sceneAsset.rounded,
          sceneAsset.glow,
          sceneAsset.positionLocked,
          sceneAsset.visible,
          sceneAsset.opacity
        );

        normalizeAndStore(updatedScene, sceneSchema);
      })
    );

    const newAssets = otherAssets.map((sceneAsset, index) => {
      const isOnCurrentWall =
        sceneAsset.left > activeWallInfo.start &&
        sceneAsset.left < activeWallInfo.start + activeWallInfo.width;

      return {
        index: scene.sceneAssets.length + index,
        asset: sceneAsset.asset,
        wallNumber: sceneAsset.wall,
        attributes: {
          uuid: v4(),
          sceneId: scene.id,
          asset: sceneAsset.asset,
          left: isOnCurrentWall
            ? sceneAsset.left
            : Math.round(
                activeWallInfo.start +
                  activeWallInfo.width / 2 -
                  sceneAsset.width / 2
              ),
          top: isOnCurrentWall
            ? sceneAsset.top
            : Math.max(
                Math.round(activeWallInfo.height / 2 - sceneAsset.height / 2),
                0
              ),
          scale: sceneAsset.scale,
          width: sceneAsset.width,
          height: sceneAsset.height,
          visible: sceneAsset.visible,
          opacity: sceneAsset.opacity,
          loop: sceneAsset.loop,
          autoplay: sceneAsset.autoplay,
          rounded: sceneAsset.rounded,
          glow: sceneAsset.glow,
          cropWidth: sceneAsset.cropWidth,
          cropHeight: sceneAsset.cropHeight,
          cropOffsetX: sceneAsset.cropOffsetX,
          cropOffsetY: sceneAsset.cropOffsetY,
          positionLocked: sceneAsset.positionLocked
        }
      };
    });

    await createSceneAssets(newAssets);
  }, [api, createSceneAssets, store, normalizeAndStore]);

  return { deleteAssets, cloneAssets, createSceneAssets };
};
