import React, { FC } from "react";
import moment from "moment";

interface DateTimeFieldProps {
  datetime: string;
}

export const DateTimeField: FC<DateTimeFieldProps> = ({ datetime }) => (
  <>{moment(datetime).format("DD-MM-YYYY hh:mm")}</>
);
