import { createActions } from "reduxsauce";

export default createActions(
  {
    sharedAssetChanged: ["formData", "categories"],
    uploadFiles: ["files"],
    showDialog: ["assetIds"],
    hideDialog: null,
    assetCreated: ["assetUuid"],
    assetDeleted: ["assetUuid"],
    assetRemotelyUpdated: ["uuid"],
    selectAsset: ["uuid"],
    saveSelectedAsset: ["data"],
    deleteSelectedAsset: null,
    removeAsset: ["uuid"],
    saveMedia: ["data", "categories"],
    closeEditing: null,
    createUrlAsset: ["data"],
    enqueueUploadAsset: ["uuid", "size", "uploadId"],
    hideUploadByUploadId: ["uploadId"],
    uploadResult: ["uploadId", "uploadResult"],
    setUploadAssetProgress: ["uuid", "uploaded"],
    setUploadAssetStatus: ["uuid", "status"],
    cancelAssetUpload: ["uuid"],
    // open preview for an asset or a wall (it opens preview dialog or opens
    // new window - based on asset type)
    previewAsset: ["uuid"],
    // opens preview dialog (which support only limited set of asset types)
    openPreviewDialog: ["uuid"],
    addAsset: null,
    deleteAsset: ["uuid"],
    editAsset: ["uuid"],
    editSharedAsset: ["uuid"],
    incrementLoadingKey: null,
    assetsFetched: ["assets"],
    mediaChange: ["data"],
    lastEditedSharedAsset: ["uuid"]
  },
  { prefix: "MEDIA/" }
);
