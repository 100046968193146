import { call, put } from "redux-saga/effects";

import { getConfig } from "../api/client";
import Actions from "./config-actions";

function* configSaga() {
  const config = yield call(getConfig);
  yield put(Actions.Creators.configFetched(config));
}

export default configSaga;
