import React, { Component } from "react";
import cx from "classnames";
import { Field } from "redux-form";
import PropTypes from "prop-types";

export class Toggle extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.any
    }).isRequired,
    dataQa: PropTypes.string
  };

  // handler for component page select box..
  handleClick = () => {
    this.props.input.onChange(!this.props.input.value);
  };

  render() {
    const {
      children,
      className,
      disabled,
      input: { value },
      dataQa
    } = this.props;

    return (
      <label
        className={cx("toggle", className, {
          toggleWithLabel: children
        })}
        data-qa={`box-${dataQa}`}
      >
        <input
          checked={value}
          onChange={this.handleClick}
          type="checkbox"
          disabled={disabled}
          data-qa={dataQa}
        />
        <i className="toggleHandle" />
        {children && <span className="toggleLabel">{children}</span>}
      </label>
    );
  }
}

export const ToggleField = (props) => <Field component={Toggle} {...props} />;
