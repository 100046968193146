import { createActions } from "reduxsauce";

export default createActions(
  {
    createScene: ["data"],
    editScene: ["data"],
    requestEditScene: ["tab", "sceneId"],
    deleteScene: ["id", "goBack"],
    cloneScene: ["id"],
    shareScene: ["id", "title"],
    deleteShare: ["id", "title"],
    incrementLoadingKey: null
  },
  { prefix: "SCENES/" }
);
