import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "redux-form";
import { PinInput } from "./pin-field/pin-input";
import DateTimePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";

const InputField = ({
  meta: { error, touched },
  children,
  input,
  type,
  help,
  label,
  id,
  hidden,
  className,
  wrapperClass,
  focusOnMount,
  dataQa,
  required: _required,
  ...rest
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (focusOnMount) inputRef.current.focus();
  }, [focusOnMount]);

  return (
    <div
      className={cx(
        "inputBox",
        { hidden, error: touched && error, selectBox: type === "select" },
        wrapperClass
      )}
      id={id}
      data-qa={`box-${dataQa}`}
    >
      {label && <label>{label}</label>}
      {(() => {
        switch (type) {
          case "textarea":
            return (
              <textarea
                className={cx("textarea", className)}
                {...input}
                {...rest}
                data-qa={dataQa}
              >
                {input.value}
              </textarea>
            );
          case "select":
            return (
              <select
                className={cx("select", className)}
                {...input}
                {...rest}
                data-qa={dataQa}
              >
                {children}
              </select>
            );
          case "pin":
            return (
              <PinInput
                value={input.value}
                onChange={input.onChange}
                {...input}
                {...rest}
                dataQa={dataQa}
              />
            );
          case "date-time":
            return (
              <DateTimePicker
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                {...input}
                {...rest}
              />
            );
          default:
            return (
              <input
                type={type ? type : "text"}
                className={cx("input", className)}
                {...input}
                {...rest}
                data-qa={dataQa}
                ref={inputRef}
              />
            );
        }
      })()}
      {touched && error && <strong>{error}</strong>}
      {help && <strong>{help}</strong>}
    </div>
  );
};

InputField.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }).isRequired,
  required: PropTypes.bool,
  input: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  hidden: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  dataQa: PropTypes.string
};

export const TextField = (props) => <Field component={InputField} {...props} />;
