import React from "react";

export const EditBackgroundGuide = React.memo(
  (props: {
    type: "vertical" | "horizontal";
    x: number;
    y: number;
    width?: number;
    height?: number;
  }) => {
    const { type, x: left, y: top, width, height } = props;

    return (
      <div
        data-guide={type}
        style={{
          height,
          left,
          top,
          width
        }}
      />
    );
  }
);
