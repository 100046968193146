import React from "react";
import { endsWithSegment } from "router5-helpers";
import { useSelector } from "react-redux";

import { UsersEdit } from "./users/users-edit";
import { UsersAdd, UsersAddInvite } from "./users/users-add";
import { AclResource } from "../../../../../shared/acl";
import { getRouteName } from "../../../routing/routing-selectors";
import { useHasAccess } from "../../../auth/use-has-access";
import { UsersList } from "./users/users-list";
import { USERS_ROUTE } from "../../../routing/route-names";

export const UsersRoot = () => {
  const isSuperAdmin = useHasAccess(AclResource.GUEST_DOMAINS_WRITE);

  const routeName = useSelector(getRouteName) as string;
  const endsWith = endsWithSegment(routeName);

  if (endsWith("add") && isSuperAdmin) {
    return <UsersAdd />;
  }

  return (
    <>
      {routeName.includes(USERS_ROUTE) && <UsersList />}

      {endsWith("add") && !isSuperAdmin && <UsersAddInvite />}
      {endsWith("edit") && <UsersEdit />}
    </>
  );
};
