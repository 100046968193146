import React, { useCallback, useState } from "react";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import { endsWithSegment } from "router5-helpers";
import { useSelector } from "react-redux";

import { guestDomain } from "../../../../entity-repository/schema";
import { getGuestDomains } from "../../../../entity-repository/entity-repository-selectors";
import { useNavigateTo } from "../../../../routing/routing-hooks";
import { DebouncedSearchInput } from "../../../../ux/debounced-search-input";
import { ApiClient } from "../../../../api/use-api";
import { useRecordCrud } from "../../../../crud/use-record-crud";
import { getRouteName } from "../../../../routing/routing-selectors";
import { GuestDomainsEdit } from "./guest-domains-edit";
import { AddGuestDomain } from "./guest-domains-add";
import {
  DOMAINS_ADD_ROUTE,
  DOMAINS_EDIT_ROUTE
} from "../../../../routing/route-names";

import { ReactComponent as IconAdd } from "../../../../../images/add.svg";

const apiListExtractor = (api: ApiClient) => api.fetchGuestDomainsList;
const apiCreateExtractor = (api: ApiClient) => api.createGuestDomain;
const apiDeleteExtractor = (api: ApiClient) => api.deleteGuestDomain;

export const GuestDomains = () => {
  const routeName = useSelector(getRouteName) as string;
  const endsWith = endsWithSegment(routeName);

  const [filterParams, setFilterParams] = useState({
    search: "",
    startIndex: 0,
    stopIndex: 30
  });

  const { navigateTo, routeParams } = useNavigateTo();
  const {
    records,
    totalCount,
    fetchList,
    createRecordCommand,
    deleteRecordCommand,
    inProgress
  } = useRecordCrud(
    guestDomain,
    getGuestDomains,
    apiListExtractor,
    apiDeleteExtractor,
    apiCreateExtractor,
    filterParams
  );

  const onAdd = useCallback(() => {
    navigateTo(DOMAINS_ADD_ROUTE, { ...routeParams });
  }, [routeParams, navigateTo]);

  const onSearchChange = useCallback((search: string) => {
    setFilterParams((value) => ({ ...value, search }));
  }, []);

  const onEditDomain = useCallback(
    (id: number) => {
      navigateTo(DOMAINS_EDIT_ROUTE, { ...routeParams, id });
    },
    [navigateTo, routeParams]
  );

  const isRowLoaded = useCallback(
    ({ index }) => {
      return !!records[index];
    },
    [records]
  );

  const loadData = useCallback(
    async ({ startIndex, stopIndex }) => {
      await fetchList(
        { startIndex, stopIndex, search: filterParams.search },
        true
      );
    },
    [fetchList, filterParams]
  );

  const renderRow = useCallback(
    ({ key, index, style }) => {
      const record = records[index];
      const content = record && (
        <div className="gridRow">
          <span className="gridCell gridCellDomainName">
            Domain name
            <h4>{record.name}</h4>
          </span>
          <hr />
          <span className="gridCell">
            Domain admin
            <h4>
              {record.adminFirstName} {record.adminLastName}
            </h4>
          </span>
          <hr />
          <span className="gridCell">
            Admin Email
            <h4>{record.adminEmail}</h4>
          </span>
          <hr />
          <span className="gridCell actions">
            <button
              className="button gridButtons"
              onClick={() => onEditDomain(record.id)}
            >
              Edit
            </button>
          </span>
        </div>
      );
      const placeholder = <div className="gridItemPlaceholder" />;

      return (
        <div key={key} style={style}>
          {inProgress ? placeholder : content}
        </div>
      );
    },
    [records, onEditDomain, inProgress]
  );

  return (
    <>
      <div className="subpage">
        <header className="pageHeader">
          <h1 className="pageHeading" data-qa="tab-header">
            Guest Domains
          </h1>
          <div>
            <button
              className="button"
              onClick={onAdd}
              data-qa="users-management-add-user-button"
            >
              <IconAdd />
              Add Domain
            </button>
          </div>
        </header>

        <div className="pageContent">
          <div className="search inputWithButton">
            <DebouncedSearchInput
              placeholder="Search filters"
              onChange={onSearchChange}
            />
          </div>
          <div>
            <InfiniteLoader
              minimumBatchSize={50}
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadData}
              rowCount={totalCount}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      width={width}
                      height={height}
                      ref={registerChild}
                      onRowsRendered={onRowsRendered}
                      rowHeight={70}
                      rowCount={inProgress ? 10 : totalCount}
                      rowRenderer={renderRow}
                      noContentRenderer={() => (
                        <div className="gridPlaceholder">Nothing</div>
                      )}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        </div>
      </div>

      {endsWith("edit") && (
        <GuestDomainsEdit deleteRecordCommand={deleteRecordCommand} />
      )}
      {endsWith("add") && (
        <AddGuestDomain createRecordCommand={createRecordCommand} />
      )}
    </>
  );
};
