import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "redux-form";
import ReactPasswordStrength from "react-password-strength";

export const PasswordInput = ({ className, changeCallback, ...inputProps }) => (
  <ReactPasswordStrength
    className={className}
    minLength={5}
    minScore={2}
    scoreWords={["weak", "weak", "good", "strong", "strongest"]}
    inputProps={{
      autoComplete: "new-password",
      className: "input",
      ...inputProps
    }}
    changeCallback={changeCallback}
  />
);

PasswordInput.propTypes = {
  changeCallback: PropTypes.func,
  className: PropTypes.string
};

const PasswordBox = ({
  meta: { error, touched },
  input: { onChange, onBlur, ...input },
  help,
  label,
  id,
  hidden,
  className,
  wrapperClass,
  dataQa,
  ...rest
}) => (
  <div
    className={cx(
      "inputBox",
      "passwordBox",
      { hidden, error: touched && error },
      wrapperClass
    )}
    id={id}
    data-qa={`box-${dataQa}`}
  >
    {label && <label>{label}</label>}
    <PasswordInput
      className={cx("input", className)}
      changeCallback={(validationResult) => onChange(validationResult)}
      onBlur={() => onBlur(input.value)}
      {...input}
      {...rest}
      data-qa={dataQa}
    />
    {!(touched && error) && help && <strong>{help}</strong>}
    {touched && error && <strong>{error}</strong>}
  </div>
);

PasswordBox.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }).isRequired,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  hidden: PropTypes.bool,
  dataQa: PropTypes.string
};

export const PasswordField = (props) => (
  <Field component={PasswordBox} {...props} />
);
