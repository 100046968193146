export class ApiClientError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(reason?: string) {
    super(reason);
  }

  get error() {
    return this.message;
  }
}

export class BusinessError extends ApiClientError {
  httpCode: number;

  constructor(httpCode: number, reason?: string) {
    super(reason);
    this.httpCode = httpCode;
  }
}

export class NotFoundError extends ApiClientError {}

export class PermissionError extends ApiClientError {}
