import { Logger as logger } from "purplex-logging";
import { useCallback, useState } from "react";
import { pick } from "lodash";
import { useApi } from "client/modules/api/use-api";
import { useEffectWithMountGuard } from "client/modules/utils/use-effect-with-mount-guard";
import { ImInstance } from "shared/types/im-instance";

export const useExpandableInstanceList = () => {
  interface WithExpandedStatus {
    expanded: boolean;
  }

  const api = useApi();
  const [instances, setInstances] = useState<
    (ImInstance & WithExpandedStatus)[]
  >([]);

  const fetchInstances = useCallback(
    async (mountGuard: () => boolean) => {
      logger.debug("Fetching instances for triggers");
      const instances = await api.getInstanceConfigs();

      if (mountGuard()) {
        logger.debug(
          "Instances result",
          instances.map((instance) => pick(instance, "name", "uuid"))
        );

        setInstances(
          instances.map((instance) => ({ ...instance, expanded: false }))
        );
      }
    },
    [api]
  );

  useEffectWithMountGuard(fetchInstances);

  const toggleInstance = useCallback(
    (indexToToggle: number) => {
      setInstances((instances) =>
        instances.map((instance, index) => {
          if (index === indexToToggle) {
            return { ...instance, expanded: !instance.expanded };
          } else {
            return instance;
          }
        })
      );
    },
    [setInstances]
  );

  return { instances, toggleInstance };
};
