import React from "react";
import { useSelector } from "react-redux";

import COUNTRIES from "../../countries";
import { TextField } from "../../../../forms/text-field";
import { PasswordField } from "../../../../forms/password-field";
import { DropdownField } from "../../../../forms/dropdown-field";
import { AsyncSelectField } from "../../../../forms/async-select-field";
import { ToggleField } from "../../../../forms/toggle-field";
import { getRoleLabelFactory } from "../../../../config/config-selectors";
import { useHasAccess } from "../../../../auth/use-has-access";
import { AclResource } from "../../../../../../shared/acl";
import { UserRoleName } from "../../../../../../shared/types/user-role-name";
import { SsoProviderType } from "shared/types/sso-provider-type";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

export interface UsersFormData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  password: {
    isValid: boolean;
    password: string;
  };
  passwordConfirm: string;
  roles: string[];
  active: boolean;
  ssoProvider: SsoProviderType | null;
}

export const passwordNormalizer = ({
  password,
  ...formData
}: UsersFormData) => ({
  ...formData,
  password: password ? password.password : undefined
});

export const UsersForm = (props: {
  editableEmail: boolean;
  email?: string;
  ssoUser: boolean;
}) => {
  const { editableEmail, email, ssoUser } = props;
  const getRoleLabel = useSelector(getRoleLabelFactory);
  const getRoleId = (id: string) => id;

  const isSuperAdmin = useHasAccess(AclResource.GUEST_DOMAINS_WRITE);

  const roleOptionArray = isSuperAdmin
    ? [UserRoleName.USER, UserRoleName.ADMIN, UserRoleName.SUPER_ADMIN]
    : [
        UserRoleName.USER,
        UserRoleName.ADMIN,
        UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
      ];

  return (
    <>
      <TextField
        name="email"
        label="Email"
        value={email}
        required
        disabled={!editableEmail}
        dataQa={FORM_LOCATORS.inputs.getInput("email")}
      />
      <TextField
        name="firstName"
        label="First Name"
        required
        disabled={!isSuperAdmin}
        dataQa={FORM_LOCATORS.inputs.getInput("first-name")}
      />
      <TextField
        name="lastName"
        label="Last Name"
        required
        disabled={!isSuperAdmin}
        dataQa={FORM_LOCATORS.inputs.getInput("last-name")}
      />
      <TextField
        name="phone"
        label="Phone (optional)"
        disabled={!isSuperAdmin}
        dataQa={FORM_LOCATORS.inputs.getInput("phone")}
      />
      <DropdownField
        name="country"
        label="Country (optional)"
        disabled={!isSuperAdmin}
        dataQa={FORM_LOCATORS.select.getSelect("country")}
      >
        <option value="">-</option>
        {COUNTRIES.map(({ country_name, country_code }, index) => (
          <option value={country_code} key={index}>
            {country_name}
          </option>
        ))}
      </DropdownField>
      {!ssoUser && (
        <>
          <PasswordField
            name="password"
            type="password"
            label="Password"
            hidden={!isSuperAdmin}
            dataQa={FORM_LOCATORS.inputs.getInput("password")}
          />
          <TextField
            name="passwordConfirm"
            type="password"
            label="Password Confirm"
            dataQa={FORM_LOCATORS.inputs.getInput("confirm-password")}
            hidden={!isSuperAdmin}
          />
        </>
      )}
      <AsyncSelectField
        name={"roles"}
        async={false}
        required
        isMulti
        label={"Roles"}
        isClearable
        options={roleOptionArray}
        getOptionValue={getRoleId}
        getOptionLabel={getRoleLabel}
        dataQa={FORM_LOCATORS.inputs.getInput("user-role")}
      />
      <ToggleField
        name="active"
        dataQa={FORM_LOCATORS.toggles.getToggle("active")}
      >
        Active
      </ToggleField>
    </>
  );
};
