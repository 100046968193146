const hasSingleChoiceSelectedValue = (filterValue) =>
  filterValue !== undefined && filterValue !== null;

const isSingleChoiceSelected = (filterValue, choiceValue) =>
  filterValue === choiceValue;

const SELECT_SINGLE = {
  hasSelectedValue: hasSingleChoiceSelectedValue,

  toggleChoice: (filterValue, choiceValue) =>
    isSingleChoiceSelected(filterValue, choiceValue) ? null : choiceValue,

  isChoiceSelected: isSingleChoiceSelected,

  getSelectedChoiceValuesAsArray: (filterValue) =>
    hasSingleChoiceSelectedValue(filterValue) ? [filterValue] : []
};

const hasMultiChoiceSelectedValue = (filterValue) =>
  filterValue && filterValue.length > 0;

const isMultiChoiceSelected = (filterValue, choiceValue) =>
  filterValue && filterValue.includes(choiceValue);

const SELECT_MULTIPLE = {
  hasSelectedValue: hasMultiChoiceSelectedValue,

  toggleChoice: (filterValue, choiceValue) =>
    isMultiChoiceSelected(filterValue, choiceValue)
      ? filterValue.filter(
          (singleChoiceValue) => singleChoiceValue !== choiceValue
        )
      : [...(filterValue || []), choiceValue],

  isChoiceSelected: isMultiChoiceSelected,

  getSelectedChoiceValuesAsArray: (filterValue) =>
    hasMultiChoiceSelectedValue(filterValue) ? filterValue : []
};

export const ChoiceLogicImplementations = {
  SELECT_SINGLE,
  SELECT_MULTIPLE
};
