import { createSelector } from "reselect";

import { getInProgress as getState } from "../root/root-selectors";

export const getProgressSelector = createSelector(
  getState,
  (inProgress) => (progressKey, entityId = null) =>
    (inProgress[progressKey] || {})[entityId] || false
);

export const getProgressForKeySelector = createSelector(
  getProgressSelector,
  (getProgressSelectorFn) => (progressKey) => (entityId = null) =>
    getProgressSelectorFn(progressKey, entityId)
);
