import { useSelector } from "react-redux";
import { getUsesSapTransformations } from "../config/config-selectors";
import { EditedBackgroundStatusForRoom } from "../scene-editor/components/background-and-transition-panel/background-status";
import { SAPEditedBackgroundStatusForRoom } from "../sap/sap-background-status";
import { SapInstancesWallStatuses } from "../sap/sap-instances-wall-statuses";
import { InstancesWallStatuses } from "../media/components/wall-compatibility/instances-wall-statuses";
import { WallPreview } from "../media/components/wall-compatibility/wall-preview";
import { SAPWallPreview } from "../sap/sap-wall-preview";
import { userShouldBeWarnedAboutFittingBackground } from "../media/components/wall-compatibility/wall-dimensions-validity";
import { userShouldBeWarnedAboutFittingSAPBackground } from "../sap/sap-wall-dimensions-validity";

export const useComponentAdapter = () => {
  const usingSapTransformations = useSelector(getUsesSapTransformations);

  return {
    EditedBackgroundStatusForRoom: usingSapTransformations
      ? SAPEditedBackgroundStatusForRoom
      : EditedBackgroundStatusForRoom,
    InstancesWallStatuses: usingSapTransformations
      ? SapInstancesWallStatuses
      : InstancesWallStatuses,
    WallPreview: usingSapTransformations ? SAPWallPreview : WallPreview
  };
};

export const useHandlersAdapter = () => {
  const usingSapTransformations = useSelector(getUsesSapTransformations);

  return {
    userShouldBeWarnedAboutFittingBackground: usingSapTransformations
      ? userShouldBeWarnedAboutFittingSAPBackground
      : userShouldBeWarnedAboutFittingBackground
  };
};
