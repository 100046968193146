import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MEDIA_TITLE_MAPPING } from "./media-title-mapping";
import { Modal } from "../../../ux/modal";
import { UploadMediaGrid } from "./upload-media-grid";
import { UrlAssetForm } from "./url-asset-form";
import { getProgressForKeySelector } from "../../../in-progress/in-progress-selectors";
import Actions from "../../media-actions";
import { MediaDialogType } from "./media-dialog-type";
import { getSelectedAsset, getDialogType } from "../../media-selectors";
import { AssetFull } from "../../../../../shared/types/asset";
import { UploadMediaForm } from "./upload-media-form";
import { AssetType } from "../../../../../shared/types/asset-type";
import { validate } from "../../media-validate";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";

const noop = () => {};

export const AddMedia = () => {
  const dispatch = useDispatch();
  const dialogType = useSelector(getDialogType) as MediaDialogType;
  const createUrlInProgress = useSelector(getProgressForKeySelector)(
    Actions.Types.CREATE_URL_ASSET
  );
  const saveInProgress = useSelector(getProgressForKeySelector)(
    Actions.Types.SAVE_SELECTED_ASSET
  );
  const hideDialog = useCallback(() => {
    dispatch(Actions.Creators.hideDialog());
  }, [dispatch]);

  const createUrlAsset = useCallback(
    (...args) => {
      dispatch(Actions.Creators.createUrlAsset(...args));
    },
    [dispatch]
  );
  const selectAsset = useCallback(
    (asset: AssetFull) => dispatch(Actions.Creators.selectAsset(asset.uuid)),
    [dispatch]
  );
  const saveSelectedAsset = useCallback(
    (form: UploadMediaForm) => {
      dispatch(Actions.Creators.saveSelectedAsset(form));
    },
    [dispatch]
  );
  const deleteSelectedAsset = useCallback(() => {
    dispatch(Actions.Creators.deleteSelectedAsset());
  }, [dispatch]);

  useEscapeHook(true, hideDialog);

  const selectedAsset = useSelector(getSelectedAsset);

  return (
    <Modal
      title={`New ${MEDIA_TITLE_MAPPING[dialogType]}`}
      className="assetsUploadModal"
      onCloseClick={hideDialog}
    >
      <>
        <div className="uploadArea">
          <UploadMediaGrid
            cols={3}
            rows={5}
            selectedAssets={[selectedAsset]
              .filter(Boolean)
              .map((asset) => asset.uuid)}
            dialogType={dialogType}
            onSelectAsset={selectAsset}
            multiselectEnabled={false}
            onMultiSelectAsset={noop}
            onSelectAll={noop}
            onDeselectAll={noop}
          />
          {dialogType === MediaDialogType.ASSET && (
            <UrlAssetForm
              inProgress={createUrlInProgress()}
              createUrlAsset={createUrlAsset}
            />
          )}
        </div>
        <div className="editArea">
          {selectedAsset && (
            <UploadMediaForm
              asset={selectedAsset}
              dialogType={dialogType}
              initialValues={{
                title: selectedAsset.title,
                description: selectedAsset.description,
                url: (selectedAsset.metadata as { url: string }).url
              }}
              validate={validate(selectedAsset.type === AssetType.ASSET_URL)}
              onSave={saveSelectedAsset}
              saveInProgress={saveInProgress(selectedAsset.uuid)}
              onDelete={deleteSelectedAsset}
            />
          )}
        </div>
      </>
    </Modal>
  );
};
