/* global zE */

import { all, takeEvery } from "redux-saga/effects";

import AuthActions from "../auth/auth-actions";

function hideZendesk() {
  try {
    zE("webWidget", "hide");
  } catch (err) {
    // ZenDesk not available
  }
}

function showZendesk() {
  try {
    zE("webWidget", "show");
  } catch (err) {
    // ZenDesk not available
  }
}

export function* zendeskSaga() {
  yield all([
    takeEvery(AuthActions.Types.LOGGED_USER, showZendesk),
    takeEvery(AuthActions.Types.LOGOUT, hideZendesk)
  ]);
}
