import { useMemo } from "react";
import * as Api from "../api/client";

export const useApi = () => {
  return useMemo(() => {
    return Api;
  }, []);
};

export type ApiClient = ReturnType<typeof useApi>;
