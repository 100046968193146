import { useDrop, useDrag } from "react-dnd";
import { WALL_TYPES } from "../media/wall-types";
import { AssetReduced } from "../../../shared/types/asset";
import { useRef, useState } from "react";
import { AssetStatus } from "../../../shared/types/asset-status";

const SourceTypes = {
  LIBRARY_ASSET: Symbol("Library asset"),
  SCENE_ASSET: Symbol("Scene asset")
};

interface DragItem {
  id: string | number;
  asset: AssetReduced;
}
interface DragPayload {
  type: symbol;
  dragItem: DragItem;
  isWallAsset: boolean;
}

interface DropWallState {
  wallOver: null | AssetReduced;
  canDrop: boolean;
}

/// Background or transition drop from library into scene
export const useDropWall = (
  currentWallUuid: string | null,
  onWallDrop: (assetUuid: string) => unknown
) =>
  useDrop<DragPayload, void, DropWallState>({
    accept: SourceTypes.LIBRARY_ASSET,
    canDrop: ({ dragItem, isWallAsset }) => {
      return Boolean(
        isWallAsset && (!currentWallUuid || currentWallUuid !== dragItem.id)
      );
    },
    drop: ({ dragItem }) => {
      onWallDrop(dragItem.id as string);
    },
    collect: (monitor) => ({
      wallOver:
        monitor.canDrop() && monitor.isOver() ? monitor.getItem().asset : null,
      canDrop: monitor.canDrop()
    })
  });

export enum DropPosition {
  TOP,
  BOTTOM
}

interface DropAssetCollected {
  // asset instance if asset is being dragged and currently is over this drop target
  assetOver: null | DragItem;
  // asset instance if asset is being dragged, but currently is not over this drop target
  assetNotOver: null | DragItem;
  canDrop: boolean;
}

interface DropAssetState extends DropAssetCollected {
  position: DropPosition;
}

/// Regular asset drop from library or from scene into scene
export const useDropAsset = (
  ignoreSceneAssetId: null | number,
  onLibraryAssetDrop: (
    asset: AssetReduced,
    dropPosition: DropPosition
  ) => unknown,
  onSceneAssetDrop: (id: number, dropPosition: DropPosition) => unknown
): [DropAssetState, (rootElement: HTMLElement | null) => void] => {
  const [cursorPosition, setCursorPosition] = useState<DropPosition>(
    DropPosition.TOP
  );
  const dropRootRef = useRef<HTMLElement | null>(null);

  const [collected, setLibDropRef] = useDrop<
    DragPayload,
    void,
    DropAssetCollected
  >({
    accept: [SourceTypes.LIBRARY_ASSET, SourceTypes.SCENE_ASSET],
    canDrop: ({ dragItem, isWallAsset }) => {
      return Boolean(!isWallAsset && ignoreSceneAssetId !== dragItem.id);
    },
    hover: (_, monitor) => {
      if (!monitor.canDrop()) {
        return;
      }

      const cursorXY = monitor.getClientOffset();

      if (!cursorXY || !dropRootRef.current) {
        return;
      }

      const { top, bottom } = dropRootRef.current.getBoundingClientRect();

      const middle = (bottom - top) / 2;
      const mousePosition = cursorXY.y - top;

      const currentCursorPosition =
        mousePosition > middle ? DropPosition.BOTTOM : DropPosition.TOP;

      if (cursorPosition !== currentCursorPosition) {
        setCursorPosition(currentCursorPosition);
      }
    },
    drop: ({ dragItem, type }, monitor) => {
      if (monitor.didDrop()) {
        // drop already processed in nested drop target
        return;
      }

      if (type === SourceTypes.LIBRARY_ASSET) {
        onLibraryAssetDrop(dragItem.asset, cursorPosition);
      } else {
        onSceneAssetDrop(dragItem.id as number, cursorPosition);
      }
    },
    collect: (monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      const canDrop = monitor.canDrop();

      return {
        assetOver: canDrop && isOver ? monitor.getItem().dragItem : null,
        assetNotOver: canDrop && !isOver ? monitor.getItem().dragItem : null,
        canDrop: monitor.canDrop()
      };
    }
  });

  const setRef = (ref: HTMLElement | null): void => {
    setLibDropRef(ref);
    dropRootRef.current = ref;
  };

  return [{ ...collected, position: cursorPosition }, setRef];
};

const isWallAsset = (asset: AssetReduced): boolean =>
  WALL_TYPES.includes(asset.type);

const someAssetFileIsReady = (asset: AssetReduced): boolean =>
  asset &&
  (asset.status === AssetStatus.STATUS_READY ||
    asset.alternativeAssets.some(
      (alternativeAsset) => alternativeAsset.status === AssetStatus.STATUS_READY
    ));

interface DragState {
  isDragging: boolean;
}

export const useLibraryDragSource = (
  asset: AssetReduced,
  onDrop: (assetUuid: string) => unknown
) => {
  const item: DragPayload = {
    type: SourceTypes.LIBRARY_ASSET,
    dragItem: {
      id: asset.uuid,
      asset: asset
    },
    isWallAsset: isWallAsset(asset)
  };

  return useDrag<DragPayload, void, DragState>({
    item,
    canDrag: () => someAssetFileIsReady(asset),
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        onDrop(asset.uuid);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
};

export const useSceneAssetDragSource = (sceneAsset: {
  id: number;
  asset: AssetReduced;
}) => {
  const item: DragPayload = {
    type: SourceTypes.SCENE_ASSET,
    dragItem: {
      id: sceneAsset.id,
      asset: sceneAsset.asset
    },
    isWallAsset: false
  };

  return useDrag<DragPayload, void, DragState>({
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
};
