import { Logger as logger } from "purplex-logging";
import { call } from "redux-saga/effects";
import { constants } from "router5";
import { onRouteEntered } from "client/modules/routing/on-route-enter-saga";

function* onUnknownRouteEntered() {
  yield call(() => logger.warn("Unknonwn route"));
}

export function* routingSaga() {
  yield call(onRouteEntered, constants.UNKNOWN_ROUTE, onUnknownRouteEntered);
}
