import { useCallback } from "react";
import { UserSafe } from "shared/types/user";
import { useNormalizeAndStore } from "client/modules/scene-editor/hooks/use-normalize-and-store";
import * as api from "../../api/client";
import { presentation as presentationSchema } from "../../entity-repository/schema";
import { PresentationReduced } from "shared/types/presentation";
import { CustomFieldUpdateCommand } from "shared/types/custom-field-update-command";

const toId = (collaborator: UserSafe) => collaborator.id;

export const useUpdateCollaborators = (
  presentationId: number,
  presentation: Partial<
    PresentationReduced & { customFields: CustomFieldUpdateCommand }
  >
) => {
  const normalizeAndStore = useNormalizeAndStore();

  return useCallback(
    async (oldCollaborators: UserSafe[], newCollaborators: UserSafe[]) => {
      const oldCollaboratorIds = oldCollaborators.map(toId);
      const newCollaboratorIds = newCollaborators.map(toId);

      if (
        oldCollaboratorIds.sort().join("") !==
        newCollaboratorIds.sort().join("")
      ) {
        const updatedPresentation = await api.updatePresentation(
          presentationId,
          {
            ...presentation,
            customFields: undefined,
            collaborators: newCollaboratorIds
          }
        );

        normalizeAndStore(updatedPresentation, presentationSchema);
      }
    },
    [normalizeAndStore, presentation, presentationId]
  );
};
