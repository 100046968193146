import { createActions } from "reduxsauce";

export default createActions(
  {
    notifySuccess: ["message", "options"],
    notifyError: ["message", "options"],
    notifyWarn: ["message", "options"],
    notifyInfo: ["message", "options"],
    dismiss: ["id"],
    updateNotification: ["id", "options"]
  },
  { prefix: "NOTIFICATIONS/" }
);
