import React, { useCallback, useEffect, useRef } from "react";
import cx from "classnames";
import { Field } from "redux-form";
import isObject from "lodash/isObject";
import { useDropzone } from "react-dropzone";

const AvatarFieldComponent = (props: {
  meta: { error: string };
  input: {
    value: string & {
      file: { preview: string; path: string } & Blob;
      rejected: boolean;
    };
    onChange: ({
      file,
      rejected
    }: {
      file: File | null;
      rejected: boolean;
    }) => void;
  };
  avatar: any;
  maxSize: number;
}) => {
  const {
    meta: { error },
    input: { value, onChange },
    avatar
  } = props;

  let imageSource =
    isObject(value) && !value.rejected && value.file.preview
      ? value.file.preview
      : avatar;

  if (value.file) {
    imageSource = value.file.path;
  }

  const pendingSave = isObject(value) && !value.rejected;

  const image = useRef<HTMLImageElement>(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onChange({
          file: acceptedFiles[0],
          rejected: false
        });
      } else {
        onChange({
          file: null,
          rejected: true
        });
      }
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: ["image/png", "image/jpeg"]
  });

  useEffect(() => {
    if (value.file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (image.current && event.target)
          image.current.src = event.target.result as string;
      };
      reader.readAsDataURL(value.file);
    }
  }, [value]);

  return (
    <div className={cx(pendingSave)}>
      <div
        {...getRootProps()}
        className={cx("avatarDropZone", {
          empty: imageSource === null
        })}
      >
        <input {...getInputProps()} />
        {!imageSource && (
          <div className="avatarDropZoneLabel">
            Drop the file here,
            <br />
            or click to select file
          </div>
        )}
        <img src={imageSource} alt="" ref={image} />
        {error && <span className="error">{error}</span>}
      </div>
    </div>
  );
};

interface AvatarFieldProps {
  meta: {
    error: string;
  };
  input: {
    value:
      | string
      | {
          rejected: boolean;
          file: { preview: string };
        };
    onChange: (values: object) => void;
  };
  avatar: string;
}

export const AvatarField = (props: AvatarFieldProps) => (
  <Field component={AvatarFieldComponent} name="avatar" {...props} />
);
