import React, { ReactNode, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { actions as routerActions } from "redux-router5";
import {
  getRouteName,
  getRouteParams,
  RouteParamSelector
} from "../routing/routing-selectors";

export const Tabs = ({ children }: { children: ReactNode }) => (
  <div className="tabs">{children}</div>
);

export const TabsNavigationContainer = ({
  children
}: {
  children: ReactNode;
}) => <div className="tabHeader">{children}</div>;
TabsNavigationContainer.propTypes = {
  children: PropTypes.node
};

export const TabsNavigationHeader = ({ children }: { children: ReactNode }) => (
  <h1 className="tabHeading" data-qa="tab-header">
    {children}
  </h1>
);

export const TabsNavigationButtons = ({
  children
}: {
  children: ReactNode;
}) => <ul className="tabNav">{children}</ul>;

export const TabsNavigationButton = ({
  children,
  active,
  onClick,
  dataQa,
  elProps
}: {
  children: ReactNode;
  active: boolean;
  onClick: () => unknown;
  dataQa: string;
  elProps?: object;
}) => (
  <li
    className={cx("item", { active })}
    onClick={onClick}
    {...elProps}
    data-qa={dataQa}
  >
    {children}
  </li>
);

const NOOP = () => {};

export const TabPanel = (props: {
  children: (tab: string) => ReactNode;
  getTabProps?: (tab: { route: string; label: string }) => object;
  heading?: string;
  buttons?: ReactNode;
  replaceRoute?: boolean;
  buttonsRender?: (tab: string) => ReactNode;
  tabs: { route: string; label: string }[];
  onChange?: (route: string) => void;
  onAfterReload?: (route: string) => void;
}) => {
  const {
    children,
    onAfterReload,
    heading,
    buttons,
    tabs,
    replaceRoute,
    onChange,
    buttonsRender
  } = props;

  const getTabProps = props.getTabProps ? props.getTabProps : () => undefined;

  const dispatch = useDispatch();

  const routeName = useSelector(getRouteName) as string;
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{ tab: string }>
  );

  const changeTab = useCallback(
    (tabRoute: string) => {
      dispatch(
        routerActions.navigateTo(
          routeName,
          { ...routeParams, tab: tabRoute },
          { replace: !!replaceRoute }
        )
      );
    },
    [dispatch, routeName, routeParams, replaceRoute]
  );

  if (!routeParams.tab) {
    return <></>;
  }

  return (
    <>
      <TabsNavigationContainer>
        {heading && <TabsNavigationHeader>{heading}</TabsNavigationHeader>}
        <TabsNavigationButtons>
          {tabs.map((tab) => {
            const active = routeParams ? routeParams.tab === tab.route : false;
            const onClick = active
              ? NOOP
              : () => {
                  if (onChange) {
                    onChange(tab.route);
                  }
                  changeTab(tab.route);
                  if (onAfterReload) {
                    onAfterReload(tab.route);
                  }
                };

            return (
              <TabsNavigationButton
                key={tab.route}
                active={active}
                onClick={onClick}
                elProps={getTabProps(tab)}
                dataQa={`tab-${tab.route}`}
              >
                {tab.label}
              </TabsNavigationButton>
            );
          })}
        </TabsNavigationButtons>
        {buttons && <div className="tabButtons">{buttons}</div>}
        {routeParams && buttonsRender && (
          <div className="tabButtons">{buttonsRender(routeParams.tab)}</div>
        )}
      </TabsNavigationContainer>
      {routeParams && children(routeParams.tab)}
    </>
  );
};
