import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { reduxForm, submit } from "redux-form";
import { connect, useSelector } from "react-redux";
import { endsWithSegment } from "router5-helpers";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";

import Actions from "../scene-actions";
import { Modal } from "../../ux/modal";
import validate from "./scene-validate";
import { SceneForm } from "./scene-form";
import { getEditingScene } from "../scene-selectors";
import { getBranding } from "../../config/config-selectors";
import { getUser } from "../../auth/auth-selectors";
import { useDeleteScene } from "../hooks/use-scene-crud";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { getRouteName, getRouteParams } from "../../routing/routing-selectors";
import { SCENES_LIST_EDIT } from "../../routing/route-names";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "scene-edit";
const SceneFormEdit = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(SceneForm);

const mapDispatchToProps = {
  submitForm: () => submit(FORM_NAME),
  editScene: Actions.Creators.editScene,
  deleteScene: Actions.Creators.deleteScene
};

const mapStateToProps = (state) => ({
  scene: getEditingScene(state),
  user: getUser(state),
  roomName: getBranding(state).roomName,
  saveInProgress: getProgressForKeySelector(state)(Actions.Types.EDIT_SCENE),
  deleteInProgress: getProgressForKeySelector(state)(Actions.Types.DELETE_SCENE)
});

const useCanRemoveScene = () => {
  const routeName = useSelector(getRouteName);
  const routeParams = useSelector(getRouteParams);
  const endsWithRoute = endsWithSegment(routeName);

  return !endsWithRoute(SCENES_LIST_EDIT) || routeParams.tab !== "cooperation";
};

const SceneEditImpl = ({
  submitForm,
  editScene,
  deleteScene,
  scene,
  roomName,
  user,
  saveInProgress,
  deleteInProgress
}) => {
  const onDeleteModal = useDeleteScene();
  const closeModal = () => window.history.back();
  const deleteSceneCallBack = () => deleteScene(scene.id, true);
  const canRemoveScene = useCanRemoveScene();

  useEscapeHook(scene !== undefined, closeModal);
  if (!scene) {
    return null;
  }

  return (
    <Modal
      title="Scene Settings"
      className="modalBoxSm"
      onCloseClick={closeModal}
      data-qa={MODAL_LOCATORS.getModal("scene-settings")}
      footer={
        <>
          {" "}
          {canRemoveScene && user.id === scene.creatorId && (
            <button
              className={cx("button buttonAlert buttonOutlined left", {
                inProgress: deleteInProgress(scene.id)
              })}
              onClick={() => {
                onDeleteModal(
                  scene.title || scene.title_text,
                  deleteSceneCallBack
                );
              }}
              data-qa={MODAL_LOCATORS.buttons.delete}
            >
              Delete Scene
            </button>
          )}
          <button
            className={cx("button", {
              inProgress: saveInProgress(scene.id)
            })}
            onClick={submitForm}
            data-qa={MODAL_LOCATORS.buttons.save}
          >
            Save
          </button>
        </>
      }
    >
      <SceneFormEdit
        isEdit
        initialValues={scene}
        validate={validate(roomName)}
        onSubmit={editScene}
        hideCollaborators={false}
      />
    </Modal>
  );
};

SceneEditImpl.propTypes = {
  user: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  editScene: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  deleteScene: PropTypes.func.isRequired,
  saveInProgress: PropTypes.func.isRequired,
  deleteInProgress: PropTypes.func.isRequired,
  dataQa: PropTypes.string
};

export const SceneEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(SceneEditImpl);
