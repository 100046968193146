import React from "react";
import PropTypes from "prop-types";

export const FiltersOverview = ({
  activeFilters,
  onClearSingle,
  onClearAll
}) => {
  return (
    <div className="filterTags">
      {activeFilters.length > 0 &&
        activeFilters.map(({ filterId, value, label }) => (
          <button
            key={`${filterId}-${value}`}
            className="filterTag"
            onClick={() => onClearSingle(filterId, value)}
            title="Remove this value from filter"
            data-qa={`filter-tag-${label}`}
          >
            <i className="clearIcon" />
            {label}
          </button>
        ))}
      {activeFilters.length >= 2 && (
        <button
          className="clearAll"
          onClick={onClearAll}
          data-qa="filter-tag-clear-all"
        >
          Clear all filters
        </button>
      )}
    </div>
  );
};

FiltersOverview.propTypes = {
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      filterId: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired
      ]).isRequired,
      value: PropTypes.any,
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onClearSingle: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired
};
