import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import _ from "lodash";

import { Hint } from "./hint";

export const ensureRange = (min: number, max: number) => (value: number) => {
  if (value < min || value > max) {
    const diffToMin = min - value;
    const diffToMax = max - value;
    return (
      value +
      (Math.abs(diffToMin) < Math.abs(diffToMax) ? diffToMin : diffToMax)
    );
  } else {
    return value;
  }
};

export const NormalizedNumberInput = (props: any) => {
  const {
    input: { value: propsValue },
    normalize,
    title,
    label,
    prefix,
    unit,
    disabled,
    dataQa
  } = props;

  const [value, setValue] = useState<number | string>(0);
  const onChangeDebounced = useCallback(_.debounce(props.input.onChange, 500), [
    props.input.onChange
  ]);

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const onChange = useCallback(
    (e) => {
      const parsedValue = parseInt(e.target.value, 10);
      const localValue = !isNaN(parsedValue) ? parsedValue : "";
      setValue(localValue);

      // allow user to clear the field completely,
      // but don't save the value, it's not valid number
      if (localValue === "") {
        return;
      }

      // do not update value when user don't have cursor
      // at the end of the input, otherwise it would jump
      if (
        isNaN(localValue) &&
        e.selectionStart !== localValue.toString().length
      ) {
        return;
      }

      // don't update value if it's out of the range,
      // normalizing the value would prevent user from typing
      if (normalize && normalize(localValue) !== localValue) {
        onChangeDebounced(normalize(localValue), normalize(localValue));
        return;
      }

      onChangeDebounced(localValue, value);
    },
    [value, normalize, onChangeDebounced]
  );

  const onBlur = useCallback(() => {
    const localValue = parseInt(value.toString(), 10) || 0;
    const normalizedValue = normalize ? normalize(localValue) : localValue;

    props.input.onChange(normalizedValue, value);
    setValue(normalizedValue);
  }, [value, normalize, props.input]);

  return (
    <Hint label={title} placement="right" disabled={disabled}>
      <label
        className={cx("number", { "is-disabled": disabled })}
        data-qa={dataQa}
        style={{ ...props.style }}
      >
        <span className="number__body">
          {prefix && <span className="number__prefix">{prefix}</span>}
          <input
            {...props.input}
            type="number"
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
          />
          {unit && <span className="number__unit">{unit}</span>}
        </span>
        {label && <span className="number__label">{label}</span>}
      </label>
    </Hint>
  );
};
