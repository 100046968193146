import { UserRoleName } from "./types/user-role-name";

export enum AclResource {
  // Assets
  WALL_WRITE = "WALL_WRITE",
  SHARED_LIBRARY_WRITE = "SHARED_LIBRARY_WRITE",
  // Users
  USERS = "USERS",
  CHANGE_PROFILE_COUNTRY = "CHANGE_PROFILE_COUNTRY",
  // Presentations
  MAKE_PRESENTATION_GLOBAL = "MAKE_PRESENTATION_GLOBAL",
  READ_WRITE_FOREIGN_PRESENTATION = "READ_WRITE_FOREIGN_PRESENTATION",
  // Scenes
  PREDEFINED_SCENES_WRITE = "PREDEFINED_SCENES_WRITE",
  MAKE_SCENE_GLOBAL = "MAKE_SCENE_GLOBAL",
  READ_WRITE_FOREIGN_SCENE = "READ_WRITE_FOREIGN_SCENE",
  DOWNLOAD_PRESENTATIONS_REPORT = "DOWNLOAD_PRESENTATIONS_REPORT",
  // Custom fields
  CUSTOM_FIELDS_WRITE = "CUSTOM_FIELDS_WRITE",
  // Guest domains
  GUEST_DOMAINS_WRITE = "GUEST_DOMAINS_WRITE",
  AUTH_GENERATE_LINK = "AUTH_GENERATE_LINK",
  // Triggers
  TRIGGERS_WRITE = "TRIGGERS_WRITE",
  // Editor Settings
  EDITOR_SETTINS_WRITE = "EDITOR_SETTINS_WRITE"
}

export interface AclPermission {
  resource: AclResource;
  role: UserRoleName;
}

export const DEFAULT_ACL: AclPermission[] = [
  // Users
  {
    resource: AclResource.USERS,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.USERS,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.CHANGE_PROFILE_COUNTRY,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.CHANGE_PROFILE_COUNTRY,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  // Assets
  {
    resource: AclResource.WALL_WRITE,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.WALL_WRITE,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.WALL_WRITE,
    role: UserRoleName.ADMIN
  },
  {
    resource: AclResource.SHARED_LIBRARY_WRITE,
    role: UserRoleName.ADMIN
  },
  {
    resource: AclResource.SHARED_LIBRARY_WRITE,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.SHARED_LIBRARY_WRITE,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  } /*,
  {
    resource: AclResource.WALL_WRITE,
    role: UserRoleName.USER
  }*/,
  // Presentations
  {
    resource: AclResource.MAKE_PRESENTATION_GLOBAL,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.MAKE_PRESENTATION_GLOBAL,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.MAKE_PRESENTATION_GLOBAL,
    role: UserRoleName.ADMIN
  },
  {
    resource: AclResource.READ_WRITE_FOREIGN_PRESENTATION,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.READ_WRITE_FOREIGN_PRESENTATION,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.READ_WRITE_FOREIGN_PRESENTATION,
    role: UserRoleName.ADMIN
  },
  // Scenes
  {
    resource: AclResource.PREDEFINED_SCENES_WRITE,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.MAKE_SCENE_GLOBAL,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.MAKE_SCENE_GLOBAL,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.MAKE_SCENE_GLOBAL,
    role: UserRoleName.ADMIN
  },
  {
    resource: AclResource.READ_WRITE_FOREIGN_SCENE,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.READ_WRITE_FOREIGN_SCENE,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.READ_WRITE_FOREIGN_SCENE,
    role: UserRoleName.ADMIN
  },
  {
    resource: AclResource.DOWNLOAD_PRESENTATIONS_REPORT,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.DOWNLOAD_PRESENTATIONS_REPORT,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.CUSTOM_FIELDS_WRITE,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.GUEST_DOMAINS_WRITE,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.AUTH_GENERATE_LINK,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.AUTH_GENERATE_LINK,
    role: UserRoleName.GUEST_DOMAIN_SUPER_ADMIN
  },
  {
    resource: AclResource.TRIGGERS_WRITE,
    role: UserRoleName.SUPER_ADMIN
  },
  {
    resource: AclResource.EDITOR_SETTINS_WRITE,
    role: UserRoleName.SUPER_ADMIN
  }
];

export const hasAccess = (
  acl: AclPermission[],
  resource: AclResource,
  roles: UserRoleName[]
): boolean =>
  acl.some(
    (record) => record.resource === resource && roles.includes(record.role)
  );

export const getRolesForResource = (
  acl: AclPermission[],
  resource: AclResource
): UserRoleName[] =>
  acl
    .filter((record) => record.resource === resource)
    .map((record) => record.role);
