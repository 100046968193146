import {
  PresentationButtonType,
  PresentationSceneButton
} from "../types/presentation.types";

export const PRESENTATION_LOCATORS = {
  buttons: {
    save: "btn-pres-save",
    getButton: (button: PresentationButtonType) => `btn-pres-${button}`
  },
  scenes: {
    list: "presentation-scene-list",
    title: "presentation-scene-title",
    getScene: (id: number) => `presentation-scene-${id}`,
    getButton: (button: PresentationSceneButton) =>
      `presentation-scene-btn-${button}`
  },
  addScenes: {
    selectedScenes: "div-selected-scenes",
    list: "div-scenes-list",
    getTab: (value: "own" | "global") => `tab-scene-${value}`
  }
};
