import { useStore } from "react-redux";
import { useCallback } from "react";
import { Logger as logger } from "purplex-logging";

import { getScaledCoords } from "../dragging/use-scaling";
import {
  getActiveWallInfo,
  getSelectedSceneAssets
} from "../../scene-editor-selectors";
import { useAssetPropertiesActions } from "./use-asset-properties-actions";
import {
  trackSceneEditorAssetResized,
  trackSceneEditorAssetCropped
} from "client/modules/user-tracking/user-tracking-creators";
import { useTracking } from "client/modules/user-tracking/use-tracking";

export const useDraggingActions = () => {
  const store = useStore();
  const track = useTracking();
  const { updateAssetProperties } = useAssetPropertiesActions();

  const setDragPosition = useCallback(
    async (data: { offsetX: number; offsetY: number }) => {
      const { offsetX, offsetY } = data;

      logger.debug("Setting position after dragging - ", data);

      const assetsToProcess = getSelectedSceneAssets(store.getState());

      const updatedAssets = assetsToProcess.map((asset) => ({
        id: asset.id,
        uuid: asset.uuid,
        left: asset.left + offsetX,
        top: asset.top + offsetY
      }));
      const oldAssets = assetsToProcess.map((asset) => ({
        id: asset.id,
        uuid: asset.uuid,
        left: asset.left,
        top: asset.top
      }));
      await updateAssetProperties({ oldAssets, updatedAssets });
    },
    [updateAssetProperties, store]
  );

  const resizeTextAsset = useCallback(
    async (data: {
      width: number;
      height: number;
      top: number;
      left: number;
    }) => {
      const { width, height, top, left } = data;

      logger.debug("Resizing text asset - ", data);

      const assetsToProcess = getSelectedSceneAssets(store.getState());
      const activeWallInfo = getActiveWallInfo(store.getState());

      if (!activeWallInfo) {
        return;
      }

      const updatedAssets = assetsToProcess.map((asset) => ({
        id: asset.id,
        uuid: asset.uuid,
        left: Math.round(left),
        top: Math.round(top),
        height: Math.round((height / activeWallInfo.height) * 100),
        cropHeight: Math.round((height / activeWallInfo.height) * 100),
        width: Math.round((width / activeWallInfo.width) * 100),
        cropWidth: Math.round((width / activeWallInfo.width) * 100)
      }));
      const oldAssets = assetsToProcess.map((asset) => ({
        id: asset.id,
        uuid: asset.uuid,
        left: asset.left,
        top: asset.top,
        height: asset.height,
        cropHeight: asset.cropHeight,
        width: asset.width,
        cropWidth: asset.cropWidth
      }));
      await updateAssetProperties({ oldAssets, updatedAssets });

      track(trackSceneEditorAssetResized());
    },
    [store, updateAssetProperties, track]
  );

  const updateScale = useCallback(
    async (data: { scale: number; origin: { left: number; top: number } }) => {
      const assetsToProcess = getSelectedSceneAssets(store.getState());

      const updatedAssets = assetsToProcess.map((asset) => {
        const scaledCoords = getScaledCoords(
          data.origin,
          asset.left,
          asset.top,
          (asset.width * asset.scale) / 100,
          (asset.height * asset.scale) / 100,
          data.scale
        );

        const offsetLeft = scaledCoords.left < 0 ? scaledCoords.left : 0;
        const offsetTop = scaledCoords.top < 0 ? scaledCoords.top : 0;

        return {
          id: asset.id,
          uuid: asset.uuid,
          scale: Math.max(Math.round(asset.scale * data.scale), 1),
          left: Math.round(scaledCoords.left - offsetLeft),
          top: Math.round(scaledCoords.top - offsetTop)
        };
      });
      const oldAssets = assetsToProcess.map((asset) => ({
        id: asset.id,
        uuid: asset.uuid,
        left: asset.left,
        top: asset.top,
        scale: asset.scale
      }));
      await updateAssetProperties({ oldAssets, updatedAssets });
      track(trackSceneEditorAssetResized());
    },
    [updateAssetProperties, store, track]
  );

  const updateCrop = useCallback(
    async (change: {
      cropWidth: number;
      cropHeight: number;
      cropOffsetX: number;
      cropOffsetY: number;
    }) => {
      const assetsToProcess = getSelectedSceneAssets(store.getState());

      const updatedAssets = assetsToProcess.map((asset) => ({
        id: asset.id,
        uuid: asset.uuid,
        left: Math.round(
          asset.left + change.cropOffsetX < 0
            ? 0
            : asset.left + change.cropOffsetX
        ),
        top: Math.round(
          asset.top + change.cropOffsetY < 0
            ? 0
            : asset.top + change.cropOffsetY
        ),
        cropOffsetX: Math.round(
          asset.cropOffsetX + (change.cropOffsetX / asset.scale) * 100
        ),
        cropOffsetY: Math.round(
          asset.cropOffsetY + (change.cropOffsetY / asset.scale) * 100
        ),
        cropHeight: Math.round(
          asset.cropHeight + (change.cropHeight / asset.scale) * 100
        ),
        cropWidth: Math.round(
          asset.cropWidth + (change.cropWidth / asset.scale) * 100
        )
      }));
      const oldAssets = assetsToProcess.map((asset) => ({
        id: asset.id,
        uuid: asset.uuid,
        left: asset.left,
        top: asset.top,
        cropHeight: asset.cropHeight,
        cropWidth: asset.cropWidth,
        cropOffsetX: asset.cropOffsetX,
        cropOffsetY: asset.cropOffsetY
      }));
      await updateAssetProperties({ oldAssets, updatedAssets });
      track(trackSceneEditorAssetCropped());
    },
    [updateAssetProperties, store, track]
  );

  return { setDragPosition, resizeTextAsset, updateCrop, updateScale };
};
