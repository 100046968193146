import cx from "classnames";
import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect
} from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { reduxForm, DecoratedFormProps } from "redux-form";

import { Modal } from "../../../ux/modal";
import Actions from "../../media-actions";
import { getMediaEditInitialValues } from "../../media-selectors";
import { TextField } from "../../../forms/text-field";
import { AssetFileField } from "../asset-file-field";
import { UploadMediaCustomFieldsPanel } from "../../../settings/custom-fields/components/media/upload-media-custom-fields-panel";
import { AssetFull } from "../../../../../shared/types/asset";
import { DateTimeField } from "../../../ux/date-time-field";
import { notEmptyString } from "../../../forms/validations/not-empty-string";
import { ReactComponent as IconCaret } from "../../../../images/caret.svg";
import { customFieldsToEntity } from "client/modules/settings/custom-fields/components/filters/filters-block";
import { LegacyConfirmationModal } from "client/modules/ux/legacy-confirmation-modal";
import * as Api from "../../../api/client";
import { getCustomFieldHierarchies } from "client/modules/entity-repository/entity-repository-selectors";
import { getUncategorisedCustomFieldHierarchyId } from "client/modules/settings/custom-fields/custom-fields-selectors";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";

const noop = () => {};

export const FORM_NAME = "edit-shared-asset";

interface MediaFile {
  uuid: string;
}

interface EditSharedAssetFormProps {
  // Can't be called valid because
  // it would clash with redux-form valid
  customValid: boolean;
  onChangeCategories: (categories: number[]) => void;
}

interface MetadataBlockProps {
  asset: AssetFull;
}

const MetadataBlock: React.FC<MetadataBlockProps> = ({ asset }) => (
  <div className="metadataBlock">
    <table>
      <tbody>
        <tr>
          <td>
            <span className="title">Created on: </span>
            <DateTimeField datetime={asset.createdAt} /> by {asset.creator}
          </td>
        </tr>
        {asset.metadata && (
          <tr>
            <td>
              <span className="title">Resolution: </span>
              {asset.dimensions.width} x {asset.dimensions.height}
            </td>
          </tr>
        )}
        <tr>
          <td>
            <span className="title">Updated on: </span>
            <DateTimeField datetime={asset.updatedAt} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

interface FormData {
  uuid: string;
  asset: MediaFile;
  title: string;
  [key: string]: any;
}

export const useAssetValidator = (
  unfilteredCategories: number[] | null,
  filters?: { [key: string]: any }
) => {
  const customFieldHierarchies: { [key: string]: any } = useSelector(
    getCustomFieldHierarchies
  );

  const uncategorisedCategoryId = useSelector(
    getUncategorisedCustomFieldHierarchyId
  );

  const categories =
    unfilteredCategories &&
    unfilteredCategories.filter(
      (categoryId) => categoryId !== uncategorisedCategoryId
    );

  // There needs to be at least one category attached otherwise it's considered
  // invalid
  if (categories && categories.length) {
    // Get all hierarchies for selected categories
    const selectedCategoryHierarchies = Object.keys(
      customFieldHierarchies
    ).filter((customFieldHierarchyId) => {
      return categories.includes(
        customFieldHierarchies[customFieldHierarchyId].id
      );
    });

    const selectedFilterHiearchies = Object.keys(customFieldHierarchies).filter(
      (key) => {
        return selectedCategoryHierarchies
          .map((id) => parseInt(id))
          .includes(customFieldHierarchies[key].parentId);
      }
    );

    // For every required filter, there must be a value in form
    return (
      selectedFilterHiearchies
        // Find just the required filters
        .filter(
          (key) =>
            customFieldHierarchies[key].customFieldToEntityMapping.required
        )
        // Map the to customFieldToEntityMappingId because that's how they are stored
        // in the form (so that same value is selected within the hiearchy if the field is there multiple times)
        .map((key) => customFieldHierarchies[key].customFieldToEntityMapping.id)
        // And check the presence
        .every(
          (key: number) => filters && filters[`custom-field-${key.toString()}`]
        )
    );
  }

  return false;
};

const EditSharedAssetForm = reduxForm<FormData, EditSharedAssetFormProps>({
  enableReinitialize: true,
  form: "edit-shared-asset"
})(({ handleSubmit, onChangeCategories, customValid }) => {
  const asset: any = useSelector(getMediaEditInitialValues);
  const assetUuid = asset.uuid;
  const dispatch = useDispatch();
  const confirmationResolveRef = useRef<(confirmed: boolean) => void>();
  const [showingMetadata, setShowingMetadata] = useState(false);
  const [confirmationDialogSetting, setConfirmationDialogSetting] = useState({
    showing: false,
    title: "",
    text: "",
    cancelText: "",
    confirmText: ""
  });

  const close = useCallback(() => {
    if (customValid) {
      dispatch(Actions.Creators.closeEditing());
    } else {
      setConfirmationDialogSetting({
        showing: true,
        title: "Unassigned Media",
        text:
          "File has no assigned categories or filters. By continuing, media will be stored in Unassigned Categories.",
        confirmText: "Continue anyway",
        cancelText: "Cancel"
      });
      confirmationResolveRef.current = (confirmed: boolean) => {
        if (confirmed) {
          dispatch(Actions.Creators.closeEditing());
        } else {
          setConfirmationDialogSetting((setting) => ({
            ...setting,
            showing: false
          }));
        }
      };
    }
  }, [dispatch, customValid]);

  const toggleMetadata = useCallback(() => {
    setShowingMetadata((value) => !value);
  }, [setShowingMetadata]);

  // Make sure selected categories are memoized
  // So that reference remains unchanged ->
  // underlying hierarchy editor won't re-render if
  // not needed
  const selectedCategories = useMemo(() => {
    return asset && asset.uuid
      ? asset.customFieldCategories.map(
          (customFieldCategory: any) =>
            `${customFieldCategory.customFieldHierarchy.path}${customFieldCategory.customFieldHierarchy.id}`
        )
      : [];
  }, [asset]);

  const replaceFileOpenRef = useRef();

  const onFileFieldReady = useCallback((open) => {
    replaceFileOpenRef.current = open;
  }, []);

  const onDelete = useCallback(
    async (confirmed: boolean) => {
      setConfirmationDialogSetting((value) => ({ ...value, showing: false }));

      if (confirmed) {
        await Api.bulkDeleteSharedMediaAssets([assetUuid]);
        dispatch(Actions.Creators.closeEditing());
      }
    },
    [setConfirmationDialogSetting, assetUuid, dispatch]
  );

  const requestDelete = useCallback(() => {
    setConfirmationDialogSetting({
      showing: true,
      title: "Delete Media",
      text: `Are you sure you want to delete media "${asset.title}"`,
      cancelText: " Cancel",
      confirmText: "Delete"
    });
    confirmationResolveRef.current = (confirmed: boolean) => {
      onDelete(confirmed);
    };
  }, [setConfirmationDialogSetting, asset, onDelete]);

  useEscapeHook(asset !== undefined, close);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        onCloseClick={close}
        title="Edit Media"
        className={cx({
          assetsUploadModal: true,
          editSharedAsset: true,
          sharedMediaModal: true
        })}
      >
        <div className={cx({ content: true })}>
          <div className="left">
            <form className="assetTitleForm">
              {asset && (
                <div
                  className={cx({
                    extension: true,
                    hasFormat: asset && asset.formatName
                  })}
                >
                  {asset && asset.formatName}
                </div>
              )}
              <TextField name="title" validate={notEmptyString} />
              <IconCaret
                className={cx({ showingMetadata })}
                onClick={toggleMetadata}
              />
            </form>
            {showingMetadata && <MetadataBlock asset={asset} />}
            {asset && (
              <AssetFileField
                name="asset"
                rootAssetType={asset.type}
                alternative={false}
                onReady={onFileFieldReady}
                onRemoveFile={noop}
              />
            )}
          </div>
          <div className="right">
            {asset && asset.uuid && (
              <UploadMediaCustomFieldsPanel
                hideTree={false}
                selectedCategories={selectedCategories}
                onChangeSelectedCategories={onChangeCategories}
              />
            )}
          </div>
        </div>
        <div className="buttonBar">
          <div className="left">
            <button onClick={requestDelete} className="button buttonAlert">
              Delete
            </button>
            <button
              className="button buttonOutlined"
              onClick={replaceFileOpenRef.current}
            >
              Replace file
            </button>
          </div>
          <div className="right">
            <button className="button" onClick={close}>
              Close
            </button>
          </div>
        </div>
        {confirmationDialogSetting.showing &&
          confirmationResolveRef.current && (
            <LegacyConfirmationModal
              title={confirmationDialogSetting.title}
              text={confirmationDialogSetting.text}
              cancelText={confirmationDialogSetting.cancelText}
              okText={confirmationDialogSetting.confirmText}
              onResolve={confirmationResolveRef.current}
            />
          )}
      </Modal>
    </form>
  );
});

const EditSharedAssetImpl = () => {
  const mediaInitialValues: any = useSelector(getMediaEditInitialValues);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormData | undefined>();
  const [selectedCategories, setSelectedCategories] = useState<number[] | null>(
    null
  );

  let initialCategories: number[] | null = null;
  if (mediaInitialValues && mediaInitialValues.customFieldCategories) {
    initialCategories = mediaInitialValues.customFieldCategories.map(
      ({ customFieldHierarchy }: any) => customFieldHierarchy.id
    );
  }

  useEffect(() => {
    setSelectedCategories((categories) => {
      if (categories === null && initialCategories !== null) {
        return initialCategories;
      } else {
        return categories;
      }
    });
  }, [initialCategories]);

  const save = useCallback(
    (data: FormData, selectedCategories: number[] | null) => {
      dispatch(
        Actions.Creators.sharedAssetChanged(
          {
            ...data,
            files: [data.asset]
          },
          selectedCategories
        )
      );
    },
    [dispatch]
  );

  const onChangeForm = useCallback(
    (
      data: Partial<FormData>,
      dispatch: Dispatch,
      props: EditSharedAssetFormProps &
        DecoratedFormProps<FormData, EditSharedAssetFormProps>
    ) => {
      const validFormData = data as FormData;
      setFormData(validFormData);

      if (!props.pristine) {
        save(validFormData, selectedCategories);
      }
    },
    [setFormData, selectedCategories, save]
  );

  const onChangeCategories = useCallback(
    (categories: number[]) => {
      setSelectedCategories(categories);
      if (formData) {
        save(formData, categories);
      }
    },
    [setSelectedCategories, formData, save]
  );

  // A bit of hacking here
  // selectedCategories is front-end state which is not
  // populated prior actually changing any value in the form
  const valid = useAssetValidator(
    selectedCategories ||
      (mediaInitialValues.customFieldCategories &&
        mediaInitialValues.customFieldCategories.map(
          (category: any) => category.customFieldHierarchy.id
        )),
    formData
  );

  if (mediaInitialValues && mediaInitialValues.files) {
    // Convert entity to form shape
    const initialValues = {
      uuid: mediaInitialValues.uuid,
      title: mediaInitialValues.title,
      // On backend there's an array of files (alternative backgrounds)
      // This doesn't make sense for shared library asset, we treat it as single
      asset: mediaInitialValues.files[0],
      ...customFieldsToEntity(mediaInitialValues.customFields)
    };

    return (
      <EditSharedAssetForm
        form={FORM_NAME}
        initialValues={initialValues}
        onChangeCategories={onChangeCategories}
        onChange={onChangeForm}
        customValid={valid}
      />
    );
  } else {
    return <></>;
  }
};

export const EditSharedAsset = () =>
  createPortal(
    <EditSharedAssetImpl />,
    document.getElementById("modal") as Element
  );
