import React from "react";
import { useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { validate as validateEmail } from "email-validator";

import {
  getRouteParams,
  RouteParamSelector
} from "../../routing/routing-selectors";
import { AuthPageShell } from "./auth-page-shell";
import { TextField } from "../../forms/text-field";
import { PasswordField } from "../../forms/password-field";
import { useSignUp } from "../hooks/use-sign-up";
import * as api from "../../api/client";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

export interface SignUpFormData {
  email: string;
  firstName: string;
  lastName: string;
  password: {
    isValid: boolean;
    password: string;
  };
  passwordConfirm: string;
}

//TODO create form utils
type FormErrors = Partial<Record<keyof SignUpFormData, string>>;

//TODO create form utils
const isNotEmpty = (value: string | undefined) => !value || value.trim() === "";

const validateFactory = (ssoSignup: boolean) => (values: SignUpFormData) => {
  const errors: FormErrors = {};

  if (!values.email || !validateEmail(values.email.trim())) {
    errors.email = `Please provide valid e-mail`;
  }

  if (
    !ssoSignup &&
    values.password &&
    values.password.password.trim() !== "" &&
    !values.password.isValid
  ) {
    errors.password = "Password is not secure enough";
  }

  if (
    !ssoSignup &&
    values.password &&
    values.password.password.trim() !== "" &&
    values.password.password !== values.passwordConfirm
  ) {
    errors.passwordConfirm = "Password confirmation does not match";
  }

  if (isNotEmpty(values.email)) {
    errors.email = "Email is Required";
  }

  if (isNotEmpty(values.firstName)) {
    errors.firstName = "First name is Required";
  }

  if (isNotEmpty(values.lastName)) {
    errors.lastName = "Last name is Required";
  }

  if (!ssoSignup && values.password && isNotEmpty(values.password.password)) {
    errors.password = "Password is Required";
  }

  if (!ssoSignup && isNotEmpty(values.passwordConfirm)) {
    errors.passwordConfirm = "Confirm password is Required";
  }

  return errors;
};

const asyncValidate = async (values: SignUpFormData): Promise<void> => {
  const errors: FormErrors = {};

  const {
    data: { unique }
  } = await api.isEmailUnique({
    email: values.email
  });

  if (!unique) {
    errors.email = "This email address is already used.";
  }

  if (Object.values(errors).length) {
    throw errors;
  }
};

interface UserSignUpExtraProps {
  ssoSignup: boolean;
}

const UserSignUpForm = reduxForm<SignUpFormData, UserSignUpExtraProps>({
  form: "users-sign-up",
  asyncBlurFields: ["email"]
})(({ handleSubmit, ssoSignup }) => {
  const signUp = useSignUp(ssoSignup);

  return (
    <form onSubmit={handleSubmit(signUp)}>
      <TextField
        name="email"
        label="Email"
        required
        disabled={ssoSignup}
        dataQa={FORM_LOCATORS.inputs.getInput("email")}
      />
      <TextField
        name="firstName"
        label="First Name"
        required
        dataQa={FORM_LOCATORS.inputs.getInput("first-name")}
      />
      <TextField
        name="lastName"
        label="Last Name"
        required
        dataQa={FORM_LOCATORS.inputs.getInput("last-name")}
      />
      {!ssoSignup && (
        <>
          <PasswordField
            name="password"
            type="password"
            label="Password"
            required
            dataQa={FORM_LOCATORS.inputs.getInput("password")}
          />
          <TextField
            name="passwordConfirm"
            type="password"
            label="Password Confirm"
            required
            dataQa={FORM_LOCATORS.inputs.getInput("confirm-password")}
          />
        </>
      )}

      <button
        className="button"
        type="submit"
        data-qa={FORM_LOCATORS.buttons.getButton("submit")}
      >
        Sign up
      </button>
    </form>
  );
});

export const AuthSignUp = (props: {
  ssoSignup: boolean;
  initialValues?: Partial<SignUpFormData>;
}) => {
  const { error } = useSelector(
    getRouteParams as RouteParamSelector<{
      token?: string;
      error?: string;
    }>
  );

  if (error) {
    return (
      <AuthPageShell>
        <div
          className="flash-message flash-message-error"
          data-qa="flash-message"
        >
          <div className="flash-message-text" data-qa="flash-message-error">
            "Invalid token."
          </div>
        </div>
      </AuthPageShell>
    );
  }

  return (
    <AuthPageShell className="login-newUserForm">
      <h1>Welcome, please sign up!</h1>
      <UserSignUpForm
        validate={validateFactory(props.ssoSignup)}
        asyncValidate={!props.ssoSignup ? asyncValidate : undefined}
        {...props}
      />
    </AuthPageShell>
  );
};
