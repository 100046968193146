import { useStore } from "react-redux";

import { AssetType } from "../../../../../../shared/types/asset-type";
import { getActiveWallInfo } from "../../../scene-editor-selectors";
import { getResizedCoords, ResizingTransformOrigin } from "../use-resizing";
import { getScaledCoords } from "../use-scaling";
import { getCropHeight, getCropWidth } from "../use-cropping";

export const AssetsWithRelativeCoords = [AssetType.ASSET_TEXT];

export const useAssetDraggingTransformations = (
  type: AssetType,
  selected: boolean,
  width: number,
  height: number,
  left: number,
  top: number,
  cropWidth: number,
  cropHeight: number,
  cropOffsetX: number,
  cropOffsetY: number,
  scale: number,
  wallStart: number,
  dragOffsetX: number,
  dragOffsetY: number,
  scaleChangeOrigin: { left: number; top: number },
  scaleChangeScale: number,
  cropChangeOffsetY: number,
  cropChangeOffsetX: number,
  cropChangeWidth: number,
  cropChangeHeight: number,
  resizeChangeOrigin: ResizingTransformOrigin,
  resizeChangeWidth: number,
  resizeChangeHeight: number
) => {
  const store = useStore();
  const activeWallInfo = getActiveWallInfo(store.getState());

  if (!activeWallInfo) {
    return {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      cropHeight: 0,
      cropWidth: 0,
      cropOffsetY: 0,
      cropOffsetX: 0
    };
  }

  let relativeScalingFactorWidth = 1;
  let relativeScalingFactorHeight = 1;

  if (AssetsWithRelativeCoords.includes(type)) {
    relativeScalingFactorWidth = activeWallInfo.width / 100;
    relativeScalingFactorHeight = activeWallInfo.height / 100;
  }

  const draggedLeft = left + dragOffsetX;
  const draggedTop = top + dragOffsetY;

  const resizedCropCoords = getResizedCoords(
    resizeChangeOrigin,
    resizeChangeWidth,
    resizeChangeHeight,
    draggedLeft,
    draggedTop,
    cropWidth * relativeScalingFactorWidth,
    cropHeight * relativeScalingFactorHeight
  );

  const scaledAndResizedCropCoords = getScaledCoords(
    scaleChangeOrigin,
    resizedCropCoords.left,
    resizedCropCoords.top,
    (resizedCropCoords.width * scale) / 100,
    (resizedCropCoords.height * scale) / 100,
    scaleChangeScale
  );

  const scaledCoords = getScaledCoords(
    scaleChangeOrigin,
    draggedLeft,
    draggedTop,
    (width * scale) / 100,
    (height * scale) / 100,
    scaleChangeScale
  );

  return {
    top: scaledAndResizedCropCoords.top + cropChangeOffsetY,
    left: scaledAndResizedCropCoords.left - wallStart + cropChangeOffsetX,
    width: scaledCoords.width,
    height: scaledCoords.height,
    cropWidth: getCropWidth(scaledAndResizedCropCoords.width + cropChangeWidth),
    cropHeight: getCropHeight(
      scaledAndResizedCropCoords.height + cropChangeHeight
    ),
    cropOffsetX:
      (cropOffsetX * scale * scaleChangeScale) / 100 + cropChangeOffsetX,
    cropOffsetY:
      (cropOffsetY * scale * scaleChangeScale) / 100 + cropChangeOffsetY
  };
};
