import React from "react";
import { useSelector } from "react-redux";

import {
  getBackgroundState,
  getInstanceWallHeight,
  getMainWallAssetUrl,
  getScene,
  getTotalInstanceWidth,
  getWallAssetHeight,
  getWallAssetWidth
} from "../../../scene-editor-selectors";
import { useRenderCount } from "../../../utils";
import { useEditBackgroundResizing } from "../../../hooks/dragging/use-edit-background-resizing";
import { EditBackgroundResizingTransformOrigin } from "../../../hooks/dragging/utils/edit-background-resizing-handlers";

export const EditBackgroundWallAsset = React.memo(
  (props: {
    height: number;
    screenScale: number;
    ratioLocked: boolean;
    padding: number;
    draggingOffsetY: number;
    draggingOffsetX: number;
    onDraggingStart: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onUpdateBackground: (data: {
      left?: number;
      top?: number;
      height?: number;
      width?: number;
    }) => void;
  }) => {
    const {
      height,
      screenScale,
      padding,
      ratioLocked,
      onDraggingStart,
      draggingOffsetX,
      draggingOffsetY,
      onUpdateBackground
    } = props;

    const scene = useSelector(getScene);
    const backgroundState = useSelector(getBackgroundState);
    const wallAssetWidth = useSelector(getWallAssetWidth) || 0;
    const wallAssetHeight = useSelector(getWallAssetHeight) || 0;
    const wallHeight = useSelector(getInstanceWallHeight) || 0;
    const totalWallWidth = useSelector(getTotalInstanceWidth) || 0;
    const url = useSelector(getMainWallAssetUrl);

    const renderCount = useRenderCount();

    const backgroundSettings = {
      height:
        (backgroundState && backgroundState.height) || wallAssetHeight || 0,
      width: (backgroundState && backgroundState.width) || wallAssetWidth || 0,
      top: (backgroundState && backgroundState.top) || 0,
      left: (backgroundState && backgroundState.left) || 0
    };

    const {
      mouseDownHandlerFactory,
      resizingState
    } = useEditBackgroundResizing(
      screenScale,
      ratioLocked,
      backgroundSettings.height,
      backgroundSettings.width,
      backgroundSettings.top,
      backgroundSettings.left,
      totalWallWidth,
      wallHeight,
      onUpdateBackground
    );

    if (
      !wallAssetWidth ||
      !wallAssetHeight ||
      !scene ||
      !scene.wall ||
      !scene.transformedWall
    ) {
      return <></>;
    }

    const transformedAsset = scene.transformedWall;

    const assetWidth = backgroundState
      ? transformedAsset.scale.width
      : scene.wall.dimensions.width;
    const assetHeight = backgroundState
      ? transformedAsset.scale.height
      : scene.wall.dimensions.height;
    const assetTop = backgroundState ? transformedAsset.pad.top : 0;
    const assetLeft = backgroundState ? transformedAsset.pad.left : 0;

    return (
      <div
        className="background-asset"
        style={{
          //TODO turn into separate functions to distinguish functionalities
          top:
            height / 2 -
            (wallHeight / 2) * screenScale +
            (assetTop + resizingState.absoluteTopChange) * screenScale +
            draggingOffsetY,
          left:
            padding / 2 +
            assetLeft * screenScale +
            resizingState.absoluteLeftChange * screenScale +
            draggingOffsetX,
          width: (assetWidth + resizingState.absoluteWidthChange) * screenScale,
          height:
            (assetHeight + resizingState.absoluteHeightChange) * screenScale
        }}
      >
        {renderCount}
        <img
          style={{ userSelect: "none" }}
          src={url}
          alt="Background asset"
          draggable={false}
          onMouseDown={onDraggingStart}
        />

        {/* edges */}
        <div
          className="background-asset__handle"
          data-edge="top"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.TOP
          )}
        />
        <div
          className="background-asset__handle"
          data-edge="right"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.RIGHT
          )}
        />
        <div
          className="background-asset__handle"
          data-edge="bottom"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.BOTTOM
          )}
        />
        <div
          className="background-asset__handle"
          data-edge="left"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.LEFT
          )}
        />

        {/* corners */}
        <div
          className="background-asset__handle"
          data-corner="left top"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.LEFT_TOP
          )}
        />
        <div
          className="background-asset__handle"
          data-corner="right top"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.RIGHT_TOP
          )}
        />
        <div
          className="background-asset__handle"
          data-corner="left bottom"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.LEFT_BOTTOM
          )}
        />
        <div
          className="background-asset__handle"
          data-corner="right bottom"
          onMouseDown={mouseDownHandlerFactory(
            EditBackgroundResizingTransformOrigin.RIGHT_BOTTOM
          )}
        />
      </div>
    );
  }
);
