import React from "react";

export const SelectionBox = React.memo(
  (props: { left: number; top: number; width: number; height: number }) => {
    const { left, top, width, height } = props;
    return (
      <div
        style={{
          left: left,
          top: top,
          width: Math.abs(width),
          height: Math.abs(height),
          position: "absolute",
          border: "1px solid #43B0FF",
          zIndex: 100,
          pointerEvents: "none"
        }}
      />
    );
  }
);
