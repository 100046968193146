import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Logger as logger } from "purplex-logging";

import { useApi } from "../../api/use-api";
import { SignUpFormData } from "../components/auth-sign-up";
import {
  getRouteParams,
  RouteParamSelector
} from "../../routing/routing-selectors";
import { useNavigateToWithDelay } from "../../routing/routing-hooks";
import { useNotifications } from "../../ux/notifications/use-notifications";
import { AUTH_LOGIN_ROUTE } from "../../routing/route-names";
import { DOMAIN_NOT_ALLOWED_ERROR } from "../../../../shared/api-errors";
import { useNormalizeAndStore } from "client/modules/scene-editor/hooks/use-normalize-and-store";
import { userSchema } from "../../entity-repository/schema";

export const useSignUp = (ssoSignup: boolean) => {
  const api = useApi();
  const normalizeAndStore = useNormalizeAndStore();
  const { token } = useSelector(
    getRouteParams as RouteParamSelector<{
      token?: string;
    }>
  );

  const navigateTo = useNavigateToWithDelay();
  const { notifyError, notifySuccess } = useNotifications();

  return useCallback(
    async (values: SignUpFormData) => {
      try {
        await api.selfSignUp({
          token,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password && values.password.password,
          ssoSignup
        });
        if (!ssoSignup) {
          notifySuccess(
            "Your account has been created! We’ve sent you a verification email to verify the account.",
            { autoClose: false }
          );
          navigateTo(AUTH_LOGIN_ROUTE, {}, 2000);
        } else {
          const user = await api.getLoggedInUser();
          normalizeAndStore(user, userSchema);
          notifySuccess("Your account has just been activated!");
        }
      } catch (e) {
        logger.error("Error while signing up", e);
        if (e.error === DOMAIN_NOT_ALLOWED_ERROR) {
          notifyError("Email domain is not allowed.");
        } else {
          notifyError("There was an error while creating account.");
        }
      }
    },
    [
      api,
      token,
      notifySuccess,
      notifyError,
      navigateTo,
      ssoSignup,
      normalizeAndStore
    ]
  );
};
