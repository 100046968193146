import { Logger as logger } from "purplex-logging";
import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

import { getCustomFields as getStateSlice } from "../../root/root-selectors";
import {
  getCustomFieldHierarchies,
  getCustomFields,
  getCustomFieldToEntityMappings
} from "../../entity-repository/entity-repository-selectors";
import {
  getRouteName,
  getRouteParams,
  RouteParamSelector
} from "../../routing/routing-selectors";
import { ChoiceLogicImplementations } from "../../ux/filters/choice-logic-implementations";
import * as Api from "../../api/client";
import { EDIT_CATEGORY_FORM_NAME } from "./components/category-forms/custom-fields-category-edit";
import { CustomFieldEntity } from "../../../../shared/types/custom-field-entity";
import { CustomFieldType } from "../../../../shared/types/custom-field-type";
import { RootState } from "../../root/root-reducer";
import {
  AddFilterRouteParentParam,
  CategoryTreeNode,
  CustomFieldMappingCategoryTreeNode,
  EditedCategoryCategoryTreeNode
} from "./custom-field-types";
import { CustomFieldToEntityMappingViewModel } from "../../entity-repository/entity.types";
import { getCustomFieldTypeFromRouteName } from "./hooks/use-get-custom-field-type";

export const getAllCustomFields = createSelector(
  getStateSlice,
  getCustomFields,
  (state, customFieldsRepository) =>
    state.customFields.map((customField) => customFieldsRepository[customField])
);

export const getAllCustomFieldHierarchies = createSelector(
  getStateSlice,
  getCustomFieldHierarchies,
  (state, customFieldHierarchiesRepository) => {
    return state.customFieldHierarchies.map(
      (hierarchyId) => customFieldHierarchiesRepository[hierarchyId]
    );
  }
);

export const getAllCustomFieldMappings = createSelector(
  getStateSlice,
  getCustomFieldToEntityMappings,
  (state, mappingRepository) => {
    return state.customFieldMappings.map((id) => mappingRepository[id]);
  }
);

export const getSortedEntityMappings = createSelector(
  getAllCustomFieldMappings,
  (entityMappings) => {
    return entityMappings.sort((a, b) => a.sort - b.sort);
  }
);

export const getPresentationCustomFieldMappings = createSelector(
  getSortedEntityMappings,
  (sortedEntityMappings) => {
    return sortedEntityMappings.filter(
      ({ entity }) => entity === CustomFieldEntity.PRESENTATION
    );
  }
);

export const getAssetCustomFieldMappings = createSelector(
  getSortedEntityMappings,
  (sortedEntityMappings) =>
    sortedEntityMappings.filter(
      ({ entity }) => entity === CustomFieldEntity.ASSET
    )
);

export const getCustomFieldMappingsForEntity = (state: RootState) => (
  entity: CustomFieldEntity
) => {
  switch (entity) {
    case CustomFieldEntity.PRESENTATION:
      return getPresentationCustomFieldMappings(state);

    default:
      logger.warn(
        `getPresentationCustomFieldMappingsForEntity: invalid entity "${entity}"`
      );
      return [];
  }
};

export const getPresentationInitialCustomFields = createSelector(
  getPresentationCustomFieldMappings,
  (mappings) =>
    mappings.map(({ customField }) => ({
      id: null,
      value: null,
      type: customField.type,
      customFieldId: customField.id
    }))
);

export const getEditedCustomFieldInitialValues = createSelector(
  getCustomFields,
  getRouteParams as RouteParamSelector<{ fieldId: string }>,
  (customFields, { fieldId }) => {
    if (!fieldId) {
      return {};
    }
    if (customFields[fieldId]) {
      const { id, label, type, values } = customFields[fieldId];
      return {
        id,
        label,
        type,
        choices: values,
        helpers: {
          addChoiceInput: ""
        }
      };
    } else {
      return {};
    }
  }
);

export const getEditedCustomFieldMappingInitialValues = createSelector(
  getCustomFields,
  getRouteParams as RouteParamSelector<{
    fieldId: string;
  }>,
  getRouteName,
  (customFields, { fieldId }, routeName) => {
    if (!fieldId || !routeName) {
      return {};
    }
    if (customFields[fieldId]) {
      const { id, label, type, values } = customFields[fieldId];
      const customField = customFields[fieldId].entityMappings.find(
        (mapping) =>
          mapping.entity === getCustomFieldTypeFromRouteName(routeName)
      );
      return {
        id,
        label,
        type,
        choices: values,
        required: customField && customField.required,
        filterable: customField && customField.filterable,
        helpers: {
          addChoiceInput: ""
        }
      };
    } else {
      return {};
    }
  }
);

const customFieldMappingToCategoryTree = (
  customFieldHierarchies: ReturnType<typeof getAllCustomFieldHierarchies>
) => {
  const entityHierarchies = Object.values(customFieldHierarchies).filter(
    (hierarchy) => hierarchy.customFieldToEntityMapping.entity === "ASSET"
  );

  const toTree = (
    categories: typeof entityHierarchies
  ): CustomFieldMappingCategoryTreeNode[] => {
    return categories.map((item) => {
      return {
        id: item.id,
        label: item.customField.label,
        type: item.customField.type,
        path: item.path,
        service: item.customField.service,
        children: toTree(
          entityHierarchies
            .sort((a, b) => a.sort - b.sort)
            .filter((hierarchy) => hierarchy.parentId === item.id)
        )
      };
    });
  };
  const rootCategories = entityHierarchies
    .sort((a, b) => a.sort - b.sort)
    .filter(
      (hierarchy) =>
        !hierarchy.parentId &&
        hierarchy.customField.type === CustomFieldType.CATEGORY
    );
  return toTree(rootCategories);
};

export const getAssetCustomFieldCategoryTree = createSelector(
  getAllCustomFieldHierarchies,
  customFieldMappingToCategoryTree
);

export const customFieldMappingsToFiltersConfig = (
  customFieldMappings: CustomFieldToEntityMappingViewModel[]
) =>
  customFieldMappings
    .filter(
      ({ filterable, customField: { type } }: any) =>
        filterable &&
        [CustomFieldType.SINGLE_CHOICE, CustomFieldType.TEXT].includes(type)
    )
    //Always one hieararchy
    .sort(
      (a, b) =>
        a.customFieldHierarchies[0].sort - b.customFieldHierarchies[0].sort
    )
    .map(
      ({
        id: mappingId,
        entity,
        parentId,
        customField: { id, label, type }
      }: any) => ({
        id,
        label,
        parentId,
        choiceLogic: ChoiceLogicImplementations.SELECT_MULTIPLE,
        choices: async (
          searchPhrase: any,
          selectedFilters: any,
          globalSearch: any,
          additionalFilterData: any,
          category: any
        ) => {
          try {
            const api =
              type === CustomFieldType.SINGLE_CHOICE
                ? Api.searchChoiceCustomFields
                : Api.searchTextCustomFields;

            const result = await api(
              mappingId,
              searchPhrase,
              selectedFilters,
              globalSearch,
              additionalFilterData,
              entity,
              category
            );

            const rowMapper =
              type === CustomFieldType.SINGLE_CHOICE
                ? (value: any) => ({ label: value.label, value: value.id })
                : (value: any) => ({ label: value, value });

            return {
              count: result.count,
              rows: result.rows.map(rowMapper)
            };
          } catch (e) {
            logger.error(`Error searching custom fields`, e);
            return {
              count: 0,
              rows: []
            };
          }
        }
      })
    );

export const getPresentationFiltersConfig = createSelector(
  getPresentationCustomFieldMappings,
  customFieldMappingsToFiltersConfig
);

export const getCreateFilterParentData = createSelector(
  getAllCustomFieldHierarchies,
  getRouteParams as RouteParamSelector<{
    id: string;
  }>,
  getRouteName,
  (hierarchies, { id }, routeName) => {
    if (!id || !routeName) {
      return {};
    }
    if (id === AddFilterRouteParentParam.NONE) {
      return {
        entity: getCustomFieldTypeFromRouteName(routeName)
      };
    }
    if (id === AddFilterRouteParentParam.ROOT) {
      return {
        entity: getCustomFieldTypeFromRouteName(routeName),
        path: "/",
        parentId: null
      };
    }
    const hierarchy = hierarchies.find(
      (hierarchy) => hierarchy.id === parseInt(id)
    );
    if (!hierarchy) {
      return {
        entity: getCustomFieldTypeFromRouteName(routeName)
      };
    }
    return {
      entity: getCustomFieldTypeFromRouteName(routeName),
      path: hierarchy.path + id + "/",
      parentId: id
    };
  }
);

export const getAssetCategoryTree = createSelector(
  getAllCustomFieldHierarchies,
  (hierarchies) => {
    const entityHierarchies = hierarchies.filter(
      (hierarchy) =>
        hierarchy.customFieldToEntityMapping.entity === CustomFieldEntity.ASSET
    );

    const toTree = (
      categories: typeof entityHierarchies
    ): CategoryTreeNode[] => {
      return categories.map((item) => {
        return {
          id: item.id,
          label: item.customField.label,
          type: item.customField.type,
          path: item.path,
          sort: item.sort,
          service: item.customField.service,
          customFieldId: item.customField.id,
          values: item.customField.values.map((value) => value.value),
          children: toTree(
            entityHierarchies
              .filter((hierarchy) => hierarchy.parentId === item.id)
              .sort((a, b) => a.sort - b.sort)
          )
        };
      });
    };
    const rootCategories = entityHierarchies
      .filter((hierarchy) => !hierarchy.parentId)
      .sort((a, b) => a.sort - b.sort);
    return toTree(rootCategories);
  }
);

export const getPresentationActiveFilters = createSelector(
  getAllCustomFieldHierarchies,
  (hierarchies) => {
    const entityHierarchies = hierarchies.filter(
      (hierarchy) =>
        hierarchy.customFieldToEntityMapping.entity ===
        CustomFieldEntity.PRESENTATION
    );

    const toTree = (
      categories: typeof entityHierarchies
    ): CategoryTreeNode[] => {
      return categories.map((item) => {
        return {
          id: item.id,
          label: item.customField.label,
          type: item.customField.type,
          path: item.path,
          sort: item.sort,
          service: item.customField.service,
          customFieldId: item.customField.id,
          values: item.customField.values.map((value) => value.value),
          children: toTree(
            entityHierarchies
              .filter((hierarchy) => hierarchy.parentId === item.id)
              .sort((a, b) => a.sort - b.sort)
          )
        };
      });
    };
    const rootCategories = entityHierarchies
      .filter((hierarchy) => !hierarchy.parentId)
      .sort((a, b) => a.sort - b.sort);
    return toTree(rootCategories);
  }
);

export const getEditedCategoryInitialValues = createSelector(
  getCustomFieldHierarchies,
  getRouteParams as RouteParamSelector<{ id: string }>,
  (hierarchies, { id }) => {
    if (!id || !hierarchies[id]) {
      return {
        label: "",
        parentId: 0
      };
    }
    return {
      label: hierarchies[id].customField.label,
      parentId: hierarchies[id].parentId ? hierarchies[id].parentId : 0
    };
  }
);

export const getEditedCategoryData = createSelector(
  getFormValues(EDIT_CATEGORY_FORM_NAME),
  getCustomFieldHierarchies,
  getRouteParams as RouteParamSelector<{ id: string }>,
  (formState, hierarchies, { id }) => {
    if (!formState || !id) {
      return;
    }
    const { parentId } = formState as { parentId: number };

    if (id && hierarchies[id]) {
      return {
        path:
          parentId === 0 ? "/" : `${hierarchies[parentId].path}${parentId}/`,
        parentId: parentId === 0 ? null : parentId,
        customFieldId: hierarchies[id].customField.id
      };
    }
    return undefined;
  }
);

export const getDeletedCategoryData = createSelector(
  getCustomFieldHierarchies,
  getRouteParams as RouteParamSelector<{ id: string }>,
  (hierarchies, { id }) => {
    if (!id) {
      return {
        customFieldId: null,
        label: null
      };
    }
    return {
      customFieldId: hierarchies[id].customField.id,
      label: hierarchies[id].customField.label
    };
  }
);

export const getUncategorisedCustomFieldHierarchyId = createSelector(
  getCustomFieldHierarchies,
  (hierarchies) => {
    const hierarchyId = Object.keys(hierarchies).find((id) => {
      const hierarchy = hierarchies[id];
      if (
        hierarchy.customField.service &&
        hierarchy.customField.label === "Uncategorised"
      ) {
        return true;
      }
    });

    if (hierarchyId) {
      return parseInt(hierarchyId);
    } else {
      return;
    }
  }
);

export const getEditedCategorySelectParentTree = createSelector(
  getAllCustomFieldHierarchies,
  getRouteParams as RouteParamSelector<{
    id: string;
  }>,
  getRouteName,
  getUncategorisedCustomFieldHierarchyId,
  (hierarchies, { id }, routeName, uncategorisedId) => {
    if (!id || !routeName) {
      return [];
    }

    const hierarchyArray = Object.values(hierarchies);

    const filteredCategories = hierarchyArray.filter((hierarchy) => {
      const children = hierarchyArray.filter(
        (item) => item.parentId === hierarchy.id
      );
      const hasFilters = children.find(
        (child) =>
          child.customField.type === CustomFieldType.SINGLE_CHOICE ||
          child.customField.type === CustomFieldType.TEXT
      );
      return (
        hierarchy.customFieldToEntityMapping.entity ===
          getCustomFieldTypeFromRouteName(routeName) &&
        hierarchy.customField.type === CustomFieldType.CATEGORY &&
        hierarchy.id !== parseInt(id) &&
        !hierarchy.path.includes(`/${id}/`) &&
        !hasFilters &&
        hierarchy.id !== uncategorisedId
      );
    });

    const toTree = (
      categories: typeof filteredCategories
    ): EditedCategoryCategoryTreeNode[] => {
      return categories.map((item) => {
        return {
          id: item.id,
          label: item.customField.label,
          type: item.customField.type,
          path: item.path,
          sort: item.sort,
          customFieldId: item.customField.id,
          children: toTree(
            filteredCategories
              .filter((hierarchy) => hierarchy.parentId === item.id)
              .sort((a, b) => a.sort - b.sort)
          )
        };
      });
    };
    const rootCategories = filteredCategories
      .filter((hierarchy) => !hierarchy.parentId)
      .sort((a, b) => a.sort - b.sort);
    return toTree(rootCategories);
  }
);
