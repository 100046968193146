import { Logger as logger } from "purplex-logging";
import { actions as routerActions } from "redux-router5";
import {
  call,
  put,
  race,
  select,
  take,
  fork,
  takeLatest,
  delay
} from "redux-saga/effects";
import * as Api from "../../api/client";
import { normalizeAndStore } from "../../entity-repository/entity-repository-saga";
import { presentation as presentationSchema } from "../../entity-repository/schema";
import { onRouteEntered } from "../../routing/on-route-enter-saga";
import { getRouteParams, getRouteName } from "../../routing/routing-selectors";
import { notifyError } from "../../ux/notifications/notifications-saga";
import {
  getAssetActionSceneAssetId,
  getPresentation,
  getPresentationId,
  getShowAssetActions
} from "../scene-editor-selectors";
import { SceneEditorActions } from "../scene-editor-reducer";

import * as UserTrackingCreators from "../../user-tracking/user-tracking-creators";
import * as UserTrackingSaga from "../../user-tracking/user-tracking-saga";

let actionsToCommit = [];

function* createShowAssetAction({ payload: actionPayload, type }) {
  const routeParams = yield select(getRouteParams);
  const sceneAssetId = yield select(getAssetActionSceneAssetId);
  const presentation = yield select(getPresentation);

  // Let's create the entity in the app store
  const id = `transient-${Date.now()}`;
  presentation.assetActions = [
    ...presentation.assetActions,
    {
      id,
      presentationId: presentation.id,
      sceneAssetId,
      targetSceneAssetId: actionPayload.sceneAssetId
    }
  ];
  yield call(normalizeAndStore, presentation, presentationSchema);

  // And store the original action
  actionsToCommit.push({
    ...actionPayload,
    type,
    id, // We have to store id of the transient entity as well
    // so that we can filter it out in case user will "delete"
    // the entity before even committing (in that case
    // it should completely ignore the action)
    tab: routeParams.tab // Current tab needs to be stored as well
    // because we send to backend only changes from active tab
  });
}

function* createGotoSceneAction({ payload: actionPayload, type }) {
  const routeParams = yield select(getRouteParams);
  const sceneAssetId = yield select(getAssetActionSceneAssetId);
  const presentation = yield select(getPresentation);

  presentation.assetActions = presentation.assetActions.filter(
    (assetAction) =>
      assetAction.sceneAssetId !== sceneAssetId || !assetAction.targetSceneId
  );

  // Again, let's just create the entity
  const id = `transient-${Date.now()}`;
  presentation.assetActions = [
    ...presentation.assetActions,
    {
      id,
      presentationId: presentation.id,
      sceneAssetId,
      targetSceneId: actionPayload.sceneId
    }
  ];

  yield call(normalizeAndStore, presentation, presentationSchema);

  actionsToCommit.push({
    ...actionPayload,
    type,
    id,
    tab: routeParams.tab
  });
}

function* deleteSceneAssetAction({ payload: actionPayload, type }) {
  const routeParams = yield select(getRouteParams);
  const presentation = yield select(getPresentation);

  // Filter out the asset action
  presentation.assetActions = presentation.assetActions.filter(
    (assetAction) => assetAction.id !== actionPayload.assetAction.id
  );
  yield call(normalizeAndStore, presentation, presentationSchema);

  if (!actionPayload.assetAction.id.toString().startsWith("transient-")) {
    actionsToCommit.push({
      ...actionPayload,
      type,
      tab: routeParams.tab
    });
  } else {
    // If user just deleted transient record
    // we have to find corresponding CREATE
    // action and remove it from the list as well
    actionsToCommit = actionsToCommit.filter(
      (action) => action.id !== actionPayload.assetAction.id
    );
  }
}

function* fetchPresentation(presentationId) {
  try {
    const presentation = yield call(Api.getPresentation, presentationId);
    yield call(normalizeAndStore, presentation, presentationSchema);
  } catch (ex) {
    logger.error(
      `An error occurred while loading presentation id=${presentationId}`,
      ex
    );
    yield call(notifyError, "An error occurred while loading presentation.");
  }
}

function* closeModal() {
  const routeName = yield select(getRouteName);
  const routeParams = yield select(getRouteParams);

  yield put(
    routerActions.navigateTo(
      routeName.replace(".asset-actions.tab", ""),
      routeParams,
      {
        replace: true
      }
    )
  );
}

function* saveAssetActionModal() {
  const sceneAssetId = yield select(getAssetActionSceneAssetId);
  const presentationId = yield select(getPresentationId);

  // Let's take all the changes for current tab
  const routeParams = yield select(getRouteParams);
  const currentTabActions = actionsToCommit.filter(
    (action) => action.tab === routeParams.tab
  );

  try {
    yield put(SceneEditorActions.updateAssetActionsSetLoader());

    const updateAssetActionsCommand = {
      delete: currentTabActions
        .filter(
          (action) =>
            action.type === SceneEditorActions.deleteSceneAssetAction.type
        )
        .map((action) => action.assetAction.id),
      createShowAsset: currentTabActions
        .filter(
          (action) =>
            action.type === SceneEditorActions.createShowAssetAction.type
        )
        .map((action) => action.sceneAssetId),
      createGotoScene: currentTabActions
        .filter(
          (action) =>
            action.type === SceneEditorActions.createGotoSceneAction.type
        )
        .map((action) => action.sceneId)
    };
    yield call(
      Api.updateAssetActions,
      presentationId,
      sceneAssetId,
      updateAssetActionsCommand
    );

    if (updateAssetActionsCommand.createShowAsset.length) {
      yield call(
        UserTrackingSaga.track,
        UserTrackingCreators.trackSceneEditorOnClickActionsAssigned(
          "Media",
          updateAssetActionsCommand.createShowAsset.length
        )
      );
    } else if (updateAssetActionsCommand.createGotoScene.length) {
      yield call(
        UserTrackingSaga.track,
        UserTrackingCreators.trackSceneEditorOnClickActionsAssigned("Scene")
      );
    }

    // eslint-disable-next-line require-atomic-updates
    actionsToCommit = [];
    yield call(fetchPresentation, presentationId);
  } catch (ex) {
    logger.error("An error occurred while updating asset on-click action.", ex);
    yield call(
      notifyError,
      "An error occurred while updating asset on-click action."
    );
  } finally {
    yield put(SceneEditorActions.updateAssetActionsResetLoader());
    yield call(closeModal);
  }
}

function* closeAssetActionModal() {
  // Clear all the actions - rollback
  actionsToCommit = [];
  const routeParams = yield select(getRouteParams);

  // Refetch the presentation to clean up the state
  yield call(fetchPresentation, routeParams.presentationId);

  yield call(closeModal);
}

function* onDialogOpened(route) {
  if (!String(route.name).endsWith(".tab")) {
    const openAssetActions = yield select(getShowAssetActions);

    yield put(
      routerActions.navigateTo(
        `${route.name}.tab`,
        {
          ...route.params,
          tab: openAssetActions.length > 0 ? "assets" : "scenes"
        },
        {
          replace: true
        }
      )
    );
  }
}

function* analyticsOnChangeQuery({ query }) {
  if (query !== "") {
    const { assigned } = yield race({
      assigned: take([
        SceneEditorActions.createGotoSceneAction.type,
        SceneEditorActions.createShowAssetAction.type
      ]),
      timeout: delay(10000)
    });

    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackSceneEditorOnClickActionsSearched(!!assigned)
    );
  }
}

export function* onClickActionModalSaga() {
  yield fork(
    onRouteEntered,
    "presentations.edit.editor.assets.asset-actions",
    onDialogOpened
  );

  yield takeLatest(
    SceneEditorActions.analyticsChangeQueryForAssetActions.type,
    analyticsOnChangeQuery
  );

  while (true) {
    const result = yield race({
      createShowAssetAction: take(
        SceneEditorActions.createShowAssetAction.type
      ),
      createGotoSceneAction: take(
        SceneEditorActions.createGotoSceneAction.type
      ),
      deleteSceneAssetAction: take(
        SceneEditorActions.deleteSceneAssetAction.type
      ),
      saveAssetActionModal: take(SceneEditorActions.saveAssetActionModal.type),
      closeAssetActionModal: take(SceneEditorActions.closeAssetActionModal.type)
    });

    if (result.createShowAssetAction) {
      yield call(createShowAssetAction, result.createShowAssetAction);
    } else if (result.createGotoSceneAction) {
      yield call(createGotoSceneAction, result.createGotoSceneAction);
    } else if (result.deleteSceneAssetAction) {
      yield call(deleteSceneAssetAction, result.deleteSceneAssetAction);
    } else if (result.saveAssetActionModal) {
      yield call(saveAssetActionModal);
    } else if (result.closeAssetActionModal) {
      yield call(closeAssetActionModal);
    }
  }
}
