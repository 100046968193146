import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import cx from "classnames";

import { ReactComponent as IconFitSize } from "client/images/fit-size.svg";
import { ReactComponent as IconLock } from "client/images/lock.svg";
import { ReactComponent as IconLockOpen } from "client/images/lock-open.svg";
import {
  getBackgroundSettingsUuid,
  getBackgroundState,
  getWallAssetHeight,
  getWallAssetWidth
} from "../../../scene-editor-selectors";
import { useRenderCount } from "../../../utils";
import { useSceneEditorUtils } from "../../../hooks/asset-config/use-scene-editor-utils";
import { NormalizedNumberInput } from "../../ux/number-input";

const noop = () => {};

const EditBackgroundForm = reduxForm<
  { left: number; top: number },
  { onUpdateBackground: (data: { left?: number; top?: number }) => void }
>({
  form: "edit-background-config",
  enableReinitialize: true
})(({ handleSubmit, onUpdateBackground }) => {
  return (
    <form onSubmit={handleSubmit(noop)}>
      <Field
        component={NormalizedNumberInput}
        name="left"
        title="X-position"
        prefix="X"
        onChange={(e: any) => {
          onUpdateBackground({ left: e });
        }}
      />
      <Field
        component={NormalizedNumberInput}
        name="top"
        title="Y-position"
        prefix="Y"
        onChange={(e: any) => {
          onUpdateBackground({ top: e });
        }}
      />
    </form>
  );
});

export const EditBackgroundControls = (props: {
  onCloseModal: () => void;
  onToggleRatio: () => void;
  ratioLocked: boolean;
  onFitToHeight: () => void;
  onFitToWidth: () => void;
  onResetToOriginal: () => void;
  onUpdateBackground: (data: {
    left?: number;
    top?: number;
    height?: number;
    width?: number;
  }) => void;
}) => {
  const {
    onCloseModal,
    onFitToHeight,
    ratioLocked,
    onFitToWidth,
    onResetToOriginal,
    onToggleRatio,
    onUpdateBackground
  } = props;

  const renderCount = useRenderCount();

  const uuid = useSelector(getBackgroundSettingsUuid);
  const backgroundState = useSelector(getBackgroundState);
  const wallAssetHeight = useSelector(getWallAssetHeight);
  const wallAssetWidth = useSelector(getWallAssetWidth);

  const { fetchSceneData } = useSceneEditorUtils();

  const closeModal = useCallback(() => {
    onCloseModal();
    fetchSceneData();
  }, [onCloseModal, fetchSceneData]);

  const backgroundSettings = {
    height: (backgroundState && backgroundState.height) || wallAssetHeight || 0,
    width: (backgroundState && backgroundState.width) || wallAssetWidth || 0,
    top: (backgroundState && backgroundState.top) || 0,
    left: (backgroundState && backgroundState.left) || 0
  };

  return (
    <div className="background-editor__head">
      {renderCount}
      <div>
        <button
          type="button"
          className={cx("button --toggle", { "is-active": ratioLocked })}
          onClick={onToggleRatio}
        >
          {ratioLocked ? <IconLock /> : <IconLockOpen />}
        </button>
        <button type="button" className="button" onClick={onFitToHeight}>
          <IconFitSize style={{ transform: "rotate(90deg)" }} />
          Fit to height
        </button>
        <button type="button" className="button" onClick={onFitToWidth}>
          <IconFitSize />
          Fit to width
        </button>
        <EditBackgroundForm
          onUpdateBackground={onUpdateBackground}
          initialValues={{
            left: backgroundSettings.left,
            top: backgroundSettings.top
          }}
        />
        {uuid && <a onClick={onResetToOriginal}>Back to original</a>}
      </div>
      <div>
        <button className="button" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
};
