import { createReducer } from "reduxsauce";

import Actions from "./presentation-actions";

const INITIAL_STATE = {
  listVersion: 1,
  predefinedScenes: []
};

const incrementPresentationListVersion = (state) => ({
  ...state,
  listVersion: state.listVersion + 1
});

export default createReducer(INITIAL_STATE, {
  [Actions.Types
    .INCREMENT_PRESENTATION_LIST_VERSION]: incrementPresentationListVersion
});
