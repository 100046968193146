import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import { Logger as logger } from "purplex-logging";
import { useDispatch, useSelector } from "react-redux";

import * as AssetLibrarySelectors from "../asset-library-selectors";
import * as MediaSelectors from "../../../media/media-selectors";
import {
  getCreatableAssetMimes,
  WALL_ASSET_MIMES
} from "../../../media/asset-mime-types";
import {
  getMaxUploadSize,
  getDisabledAssetTypes
} from "../../../config/config-selectors";
import { ReactComponent as IconLink } from "client/images/assets/link.svg";
import { ReactComponent as IconAddBold } from "client/images/add-bold.svg";
import { AssetLibraryActions } from "../asset-library-reducer";
import { getRouteName } from "client/modules/routing/routing-selectors";
import { getAssetTab, AssetTab } from "client/modules/utils/assetsTab";

export const UploadAsset = (props: {
  wallAssets: boolean;
  onAddUrlAsset: () => void;
}) => {
  const { onAddUrlAsset, wallAssets } = props;

  const maxUploadFileSize = useSelector(getMaxUploadSize);
  const progress = useSelector(MediaSelectors.getUploadingProgress);
  const rejected = useSelector(AssetLibrarySelectors.isRejected);
  const disabledAssetTypes = useSelector(getDisabledAssetTypes);
  const routeName = useSelector(getRouteName) as string;

  const [drop, setDrop] = useState(false);

  const dispatch = useDispatch();

  const onFilesDropped = useCallback(
    (acceptedFiles: File[], wallAssets: boolean) => {
      dispatch(AssetLibraryActions.filesDropped({ acceptedFiles, wallAssets }));
    },
    [dispatch]
  );

  const onFilesRejected = useCallback(() => {
    dispatch(AssetLibraryActions.filesRejected());
  }, [dispatch]);

  const changeDropClass = useCallback(
    (drop) => () => {
      setDrop(drop);
    },
    []
  );

  const onAddUrlAssetHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onAddUrlAsset();
    },
    [onAddUrlAsset]
  );

  const enableDropClass = useCallback(() => changeDropClass(true), [
    changeDropClass
  ]);

  const disableDropClass = useCallback(() => changeDropClass(false), [
    changeDropClass
  ]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      disableDropClass();
      if (acceptedFiles.length) {
        onFilesDropped(acceptedFiles, wallAssets);
      } else {
        logger.info(`Rejecting ${rejectedFiles.length}`);
        onFilesRejected();
      }
    },
    [disableDropClass, onFilesDropped, onFilesRejected, wallAssets]
  );

  const accept = wallAssets
    ? WALL_ASSET_MIMES
    : getCreatableAssetMimes(disabledAssetTypes);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: maxUploadFileSize,
    accept,
    onDragEnter: enableDropClass,
    onDragLeave: disableDropClass
  });

  const [activeTab, setActiveTab] = useState(AssetTab.SCENE_MEDIA);

  useEffect(() => {
    setActiveTab(getAssetTab(routeName));
  }, [routeName, setActiveTab]);

  if (progress !== null && progress < 100) {
    return (
      <div className="upload-wrapper">
        <div className="upload uploading">
          Uploading ({progress}%)...
          <span className="bar" style={{ width: progress + "%" }} />
        </div>
      </div>
    );
  }

  return (
    <div className="upload-wrapper">
      <div
        {...getRootProps()}
        className={cx({
          upload: true,
          zone: true,
          error: rejected,
          drop: drop
        })}
      >
        <input
          {...getInputProps()}
          data-qa="asset-library-upload-asset-input"
        />
        <IconAddBold className="icon icon-add icon20" />
        {rejected && (
          <span>
            Neither any of the files can be uploaded. Accepting{" "}
            {accept.join(", ")}. With maximum size of{" "}
            {Math.round(maxUploadFileSize / 1024 / 1024)} MB.
          </span>
        )}
        {!rejected && <span>Drag & Drop files here to add to library</span>}
      </div>

      {activeTab === AssetTab.SCENE_MEDIA && (
        <button
          type="button"
          onClick={onAddUrlAssetHandler}
          className="add-url-button"
          data-qa="add-url-asset-button"
        >
          <IconLink className="icon icon-link icon20" />
          Add a Website to library
        </button>
      )}
    </div>
  );
};
