import React from "react";
import { reduxForm } from "redux-form";
import { UploadMediaCustomFieldsPanel } from "../../../settings/custom-fields/components/media/upload-media-custom-fields-panel";

interface UploadMediaCustomFieldsPanelFormProps {
  selectedAssets: string[];
  selectedCategories: string[];
  onChangeSelectedCategories: (categories: number[]) => void;
  onRequestChangeSelectedCategories: () => Promise<boolean>;
}

export const UploadMediaCustomFieldsPanelForm = reduxForm<
  unknown,
  UploadMediaCustomFieldsPanelFormProps
>({
  form: "upload-media-custom-fields-form",
  enableReinitialize: true
})(
  ({
    selectedAssets,
    selectedCategories,
    onChangeSelectedCategories,
    onRequestChangeSelectedCategories
  }) => {
    return (
      <div className="right">
        <UploadMediaCustomFieldsPanel
          selectedCategories={selectedCategories}
          hideTree={selectedAssets.length === 0}
          onChangeSelectedCategories={onChangeSelectedCategories}
          onRequestChangeSelectedCategories={onRequestChangeSelectedCategories}
        />
      </div>
    );
  }
);
