import React, { useCallback, useState } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { useSelector } from "react-redux";

import { AssetType } from "../../../../../shared/types/asset-type";
import {
  getBackgroundSettingFormInitialValues,
  getBackgroundState,
  getScene
} from "../../scene-editor-selectors";
import { EditBackground } from "./edit-background/edit-background";
import { useEditBackgroundActions } from "../../hooks/edit-background/use-edit-background-actions";
import { useConfirmationDialog } from "../../../dialogs/dialog-hooks";
import { DialogResult } from "../../../dialogs/event-types";
import { useHandlersAdapter } from "../../../root/feature-adapter";

export const FORM_NAME = "backgroundSettingForm";

interface BackgroundAssetFormData {
  autoplay: boolean;
  loop: boolean;
}

interface BackgroundAssetFormProps {
  playAs: AssetType;
}

interface BackgroundAssetFormError {}

const Form = reduxForm<
  BackgroundAssetFormData,
  BackgroundAssetFormProps,
  BackgroundAssetFormError
>({
  form: FORM_NAME,
  enableReinitialize: true
})(
  (
    props: InjectedFormProps<
      BackgroundAssetFormData,
      BackgroundAssetFormProps,
      BackgroundAssetFormError
    > &
      BackgroundAssetFormProps
  ) => {
    const { playAs } = props;
    const [editBackgroundModalOpened, setEditBackgroundModalOpened] = useState(
      false
    );

    const scene = useSelector(getScene);
    const backgroundState = useSelector(getBackgroundState);

    const { userShouldBeWarnedAboutFittingBackground } = useHandlersAdapter();
    const { setBackgroundForm } = useEditBackgroundActions();
    const confirm = useConfirmationDialog();

    const onCloseModal = useCallback(
      () => setEditBackgroundModalOpened(false),
      []
    );
    const onOpenModal = useCallback(async () => {
      if (!scene || !scene.instance) {
        return;
      }

      const shouldBeWarned = userShouldBeWarnedAboutFittingBackground(
        scene.transformedWall,
        scene.instance,
        backgroundState
      );

      if (!shouldBeWarned || backgroundState) {
        setEditBackgroundModalOpened(true);
        return;
      }

      const result = await confirm({
        title: "Backgrounds Fits Perfectly",
        text:
          "The background you are about to edit fits perfectly with the selected room. By continuing you overwrite all alternative backgrounds set. But don’t worry! Changes can be undone in the editor.",
        subtext: "",
        confirmLabel: "Continue Editing",
        cancelLabel: "Oops. No!"
      });
      if (result.type === DialogResult.CONFIRM) {
        setEditBackgroundModalOpened(true);
      }
    }, [
      confirm,
      scene,
      backgroundState,
      userShouldBeWarnedAboutFittingBackground
    ]);

    return (
      <>
        <form
          className="config"
          style={{
            justifyContent:
              playAs === AssetType.ASSET_WALL_VIDEO
                ? "space-between"
                : "flex-end"
          }}
        >
          {playAs === AssetType.ASSET_WALL_VIDEO && (
            <>
              <div>
                <h3>Loop</h3>
                <label className="switch" data-qa="background-loop">
                  <Field
                    component="input"
                    type="checkbox"
                    name="loop"
                    // Field behaves super weird for some reason here, returns wrong value and string?? WTF?
                    onChange={(event: any) => {
                      const loop = event.target.value !== "true";
                      setBackgroundForm({ loop });
                    }}
                  />
                  <i className="handle" />
                  <span className="label-on">Yes</span>
                  <span className="label-off">No</span>
                </label>
              </div>
              <div>
                <h3>Autoplay</h3>
                <label className="switch" data-qa="background-autoplay">
                  <Field
                    component="input"
                    type="checkbox"
                    name="autoplay"
                    // Field behaves super weird for some reason here, returns wrong value and string?? WTF?
                    onChange={(event: any) => {
                      const autoplay = event.target.value !== "true";
                      setBackgroundForm({ autoplay });
                    }}
                  />
                  <i className="handle" />
                  <span className="label-on">Yes</span>
                  <span className="label-off">No</span>
                </label>
              </div>
            </>
          )}
          <div>
            <button type="button" className="button" onClick={onOpenModal}>
              Edit Background
            </button>
          </div>
        </form>
        {editBackgroundModalOpened && (
          <EditBackground onCloseModal={onCloseModal} />
        )}
      </>
    );
  }
);

export const BackgroundSettingForm = (props: BackgroundAssetFormProps) => {
  const { playAs } = props;
  const initialValues = useSelector(getBackgroundSettingFormInitialValues);

  return (
    <Form
      enableReinitialize={true}
      playAs={playAs}
      initialValues={initialValues}
    />
  );
};
