import React, { SyntheticEvent, useCallback, useState } from "react";
import {
  FieldArray,
  FieldArrayFieldsProps,
  FormSection,
  InjectedFormProps,
  SubmissionError
} from "redux-form";
import { includesSegment } from "router5-helpers";
import cx from "classnames";

import { TextField } from "../../../../forms/text-field";
import { DropdownField } from "../../../../forms/dropdown-field";
import { ModalTemplate } from "../../../../ux/modal";
import { CustomFieldEntity } from "../../../../../../shared/types/custom-field-entity";
import { ReactComponent as IconTrash } from "../../../../../images/btn-delete.svg";
import { ReactComponent as IconEdit } from "../../../../../images/btn-edit.svg";
import { ToggleField } from "../../../../forms/toggle-field";
import {
  deleteCustomFieldChoiceCount,
  validateFilterLabel
} from "../../../../api/client";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";
import { CustomFieldChoiceValue } from "../../custom-field-types";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const closeModal = () => window.history.back();

export interface CustomFieldFormData {
  required: boolean;
  filterable: boolean;
  label: string;
  type: CustomFieldType;
  choices: CustomFieldChoiceValue[];
  helpers: {
    addChoiceInput: string;
  };
}

const validateChoices = (value: string, allValues: CustomFieldFormData) => {
  const item =
    allValues.choices && allValues.choices.find((val) => val.value === value);
  if (item) {
    return `Value "${value}" is already in the list.`;
  }
};

const validateChoicesDuringEditing = (editedIndex: number | null) => (
  value: string,
  allValues: CustomFieldFormData
) => {
  const arrayWithoutEditedItem = allValues.choices.filter(
    (_, index) => index !== editedIndex
  );

  const item =
    allValues.choices &&
    arrayWithoutEditedItem.find((val) => val.value === value);
  if (item) {
    return `Value "${value}" is already in the list.`;
  }
};

interface AddChoicesComponentProps {
  fields: FieldArrayFieldsProps<CustomFieldChoiceValue>;
  addChoiceInput: string;
  editedIndex: number | null;
  editing: boolean;
  editingError: boolean;
  startEditing: (value: string, index: number) => void;
  stopEditing: () => void;
  setNoValuesError: (value: boolean) => void;
  change: (field: string, value: any) => void;
}

const AddChoicesComponent = (props: AddChoicesComponentProps) => {
  const {
    fields,
    addChoiceInput,
    change,
    editedIndex,
    editing,
    editingError,
    startEditing,
    stopEditing,
    setNoValuesError
  } = props;

  const [itemAdded, setItemAdded] = useState(false);

  const add = useCallback(
    (addChoiceInput) => {
      if (!addChoiceInput || addChoiceInput.toString().trim() === "") {
        return;
      }
      const values = fields.getAll();
      const item = values && values.find((val) => val.value === addChoiceInput);
      if (!item) {
        fields.push({ value: addChoiceInput });
        change("helpers.addChoiceInput", "");
        setNoValuesError(false);
      }
      setItemAdded(true);
    },
    [fields, setNoValuesError, change]
  );

  const edit = useCallback(
    (addChoiceInput, editedIndex) => {
      if (!addChoiceInput || addChoiceInput.toString().trim() === "") {
        return;
      }
      const values = fields.getAll();
      const arrayWithoutEditedItem = values.filter(
        (_, index) => index !== editedIndex
      );

      const item = arrayWithoutEditedItem.find(
        (val) => val.value === addChoiceInput
      );

      if (item) {
        return;
      }
      fields.remove(editedIndex);
      fields.insert(editedIndex, { value: addChoiceInput });
      stopEditing();
    },
    [fields, stopEditing]
  );

  const remove = useCallback(
    (index) => {
      const deletedItem = fields.get(index);
      stopEditing();
      if (!deletedItem.id) {
        fields.remove(index);
        if (
          fields.getAll().filter((value) => !value.toBeDeleted).length === 1
        ) {
          setNoValuesError(true);
        }
      }
      if (deletedItem.id) {
        if (deletedItem.toBeDeleted) {
          fields.remove(index);
          setNoValuesError(false);
        } else {
          if (
            fields.getAll().filter((value) => !value.toBeDeleted).length === 1
          ) {
            setNoValuesError(true);
          }
          deleteCustomFieldChoiceCount(deletedItem.id).then(({ count }) => {
            fields.remove(index);
            if (count !== 0) {
              fields.insert(index, {
                ...deletedItem,
                toBeDeleted: true,
                count
              });
            }
          });
        }
      }
    },
    [fields, setNoValuesError, stopEditing]
  );

  const validate = useCallback(
    editing ? validateChoicesDuringEditing(editedIndex) : validateChoices,
    [editing, editedIndex]
  );

  const onAddHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (editing) {
          edit(addChoiceInput, editedIndex);
        } else {
          add(addChoiceInput);
        }
      }
    },
    [editing, edit, add, addChoiceInput, editedIndex]
  );

  return (
    <>
      <div className="listItemForm">
        <FormSection name="helpers">
          <TextField
            name="addChoiceInput"
            label="List Item Name"
            onKeyDown={onAddHandler}
            validate={validate}
            focusOnMount={editing || itemAdded}
            key={`${editing}${editedIndex}${fields.length}`}
          />
        </FormSection>
        <div className="inputBox inputBoxButton">
          <label>&nbsp;</label>
          {editing ? (
            <>
              <button
                className="button"
                type="button"
                onClick={() => edit(addChoiceInput, editedIndex)}
                data-qa="btn-edit"
              >
                Edit
              </button>
            </>
          ) : (
            <button
              className="button"
              type="button"
              onClick={() => add(addChoiceInput)}
              data-qa="btn-add"
            >
              Add
            </button>
          )}
        </div>
      </div>
      <ul className="list">
        {fields.map((member, index, fields) => {
          const item = fields.get(index);
          return (
            <span key={item.value}>
              <li
                className={cx("listItem", {
                  editing: editing && editedIndex === index,
                  deleteFlagged: item.toBeDeleted
                })}
                key={item.value}
                data-qa={`list-item-${item.value}`}
              >
                <span className={cx("listItemName", { editingError })}>
                  {item.value}
                </span>
                {editing && editedIndex === index ? (
                  <button
                    className="button"
                    type="button"
                    onClick={stopEditing}
                    data-qa="btn-cancel"
                  >
                    Cancel
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      className="button buttonIcon buttonOutlined"
                      onClick={() => startEditing(item.value, index)}
                      data-qa="btn-edit"
                    >
                      <IconEdit />
                    </button>
                    <button
                      type="button"
                      className="button buttonIcon buttonOutlined danger"
                      onClick={() => remove(index)}
                      data-qa="btn-delete"
                    >
                      <IconTrash />
                    </button>
                  </>
                )}
              </li>
              {item.toBeDeleted && (
                <span className={cx("", { toBeDeleted: item.toBeDeleted })}>
                  {"Removing this filter will delete " +
                    item.count +
                    " assigned values."}
                </span>
              )}
            </span>
          );
        })}
      </ul>
    </>
  );
};

const asyncValidate = async (
  values: CustomFieldFormData,
  initialValues: Partial<CustomFieldFormData>,
  entity: CustomFieldEntity
) => {
  const errors: { label?: string } = {};
  if (initialValues.label === values.label) {
    return errors;
  }

  const { valid } = await validateFilterLabel(values.label, entity);
  if (!valid) {
    errors.label = "Filter label must be unique";
  }
  return errors;
};

export interface CustomFieldsFormProps {
  allowTypeEditing?: boolean;
  assetType: CustomFieldEntity;
  selectedType: CustomFieldType;
  addChoiceInput: string;
  choices: { value: string; id?: number; toBeDeleted?: boolean }[];
  route: string;
  onSubmit: (formData: {
    required: boolean;
    filterable: boolean;
    label: string;
    type: CustomFieldType;
    choices: CustomFieldChoiceValue[];
    entity: CustomFieldEntity;
  }) => void;
}
export interface CustomFieldsFormPropsInjected
  extends CustomFieldsFormProps,
    InjectedFormProps<CustomFieldFormData, CustomFieldsFormProps> {}

export const CustomFieldsForm = (props: CustomFieldsFormPropsInjected) => {
  const {
    handleSubmit,
    onSubmit,
    allowTypeEditing = false,
    assetType,
    selectedType,
    addChoiceInput,
    choices,
    route,
    change,
    initialValues
  } = props;

  const include = includesSegment(route);
  const editingMapping = include("edit-filter-mapping");

  const [editing, setEditing] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  const [editingError, setEditingError] = useState(false);
  const [noValuesError, setNoValuesError] = useState(false);

  const startEditing = useCallback(
    (value, index) => {
      setEditing(true);
      setEditedIndex(index);
      change("helpers.addChoiceInput", value);
    },
    [setEditing, setEditedIndex, change]
  );

  const stopEditing = useCallback(() => {
    change("helpers.addChoiceInput", "");
    setEditing(false);
    setEditingError(false);
  }, [setEditing, setEditingError, change]);

  const onSubmitWithCustomValidation = useCallback(
    (() => {
      if (editing) {
        return (event: SyntheticEvent) => {
          event.preventDefault();
          setEditingError(true);
        };
      } else if (
        selectedType === CustomFieldType.SINGLE_CHOICE &&
        choices &&
        choices.filter((choice) => !choice.toBeDeleted).length === 0
      ) {
        return (event: SyntheticEvent) => {
          event.preventDefault();
          setNoValuesError(true);
        };
      }

      return handleSubmit((values) => {
        return (async () => {
          const asyncErrors = await asyncValidate(
            values,
            initialValues,
            assetType
          );

          if (asyncErrors.label) {
            throw new SubmissionError(asyncErrors);
          } else {
            onSubmit({
              required: values.required,
              filterable: values.filterable,
              label: values.label,
              type: values.type,
              choices: values.choices,
              entity: assetType
            });
          }
        })();
      });
    })(),
    [editing, selectedType, choices, onSubmit, handleSubmit]
  );

  const preventDefaultSubmit = useCallback((e) => {
    if (e.key === "Enter") e.preventDefault();
  }, []);

  useEscapeHook(true, closeModal);

  return (
    <form
      onSubmit={onSubmitWithCustomValidation}
      onKeyDown={preventDefaultSubmit}
    >
      {/*
 // @ts-ignore */}
      <ModalTemplate
        title="Filter"
        className="modalFilters"
        onCloseClick={closeModal}
        footer={
          <>
            <button
              className="button buttonOutlined danger"
              type="button"
              onClick={closeModal}
              data-qa="btn-cancel"
            >
              Cancel
            </button>
            <button
              className="button"
              type="submit"
              disabled={
                CustomFieldType.SINGLE_CHOICE === selectedType &&
                (!choices || choices.length === 0)
              }
              data-qa={MODAL_LOCATORS.buttons.save}
            >
              Save
            </button>
          </>
        }
      >
        <TextField name="label" label="Name" required focusOnMount />
        {allowTypeEditing && assetType === CustomFieldEntity.PRESENTATION && (
          <DropdownField name="type" label="Type" required>
            <option value={CustomFieldType.TEXT}>Text</option>
            <option value={CustomFieldType.SINGLE_CHOICE}>List</option>
          </DropdownField>
        )}
        {editingMapping && (
          <>
            <ToggleField name="required">Required</ToggleField>
            <ToggleField name="filterable">Filterable</ToggleField>
          </>
        )}
        {CustomFieldType.SINGLE_CHOICE === selectedType && (
          <>
            <FieldArray
              name="choices"
              component={AddChoicesComponent}
              props={{
                addChoiceInput,
                change,
                editing,
                editedIndex,
                editingError,
                startEditing,
                stopEditing,
                setNoValuesError
              }}
            />
          </>
        )}
        {noValuesError && (
          <span className="editingError">
            There must be at least one choice.
          </span>
        )}
      </ModalTemplate>
    </form>
  );
};
