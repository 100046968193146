import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import cx from "classnames";

import { GuestDomainFormData } from "../../../../../../shared/types/guest-domain";
import { ApiClient } from "../../../../api/use-api";
import { useFetchRecord } from "../../../../crud/use-fetch-record";
import { guestDomain } from "../../../../entity-repository/schema";
import { useNavigateTo } from "../../../../routing/routing-hooks";
import { TextField } from "../../../../forms/text-field";
import {
  guestDomainAsyncValidate,
  guestDomainBlurFields,
  guestDomainValidate
} from "./guest-domain-validate";
import { getGuestDomainFormData } from "../../users-selectors";
import { useEditRecord } from "../../../../crud/use-edit-records";
import { notifyError } from "../../../../ux/notifications/notifications-saga";
import { DeleteRecordCommand } from "../../../../crud/use-delete-records";
import {
  getRouteParams,
  RouteParamSelector
} from "../../../../routing/routing-selectors";
import {
  useConfirmationWithInputDialog,
  useEscapeHook
} from "../../../../dialogs/dialog-hooks";
import { DialogResult } from "../../../../dialogs/event-types";
import { createPortal } from "react-dom";
import { ModalTemplate } from "../../../../ux/modal";
import { DOMAINS_ROUTE } from "../../../../routing/route-names";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

export const GUEST_DOMAINS_EDIT_FORM = "guest-domains-form-edit";

const Form = reduxForm<
  GuestDomainFormData,
  { deleteRecordCommand: DeleteRecordCommand }
>({
  form: GUEST_DOMAINS_EDIT_FORM,
  validate: guestDomainValidate,
  asyncValidate: guestDomainAsyncValidate,
  asyncBlurFields: guestDomainBlurFields,
  enableReinitialize: true
})(({ handleSubmit, deleteRecordCommand }) => {
  const [formHidden, setFormHidden] = useState(false);

  const { id } = useSelector(
    getRouteParams as RouteParamSelector<{ id: number }>
  );

  const editGuestDomainCall = useCallback(
    (api: ApiClient) => api.editGuestDomain,
    []
  );

  const { editRecord, inProgress } = useEditRecord(
    guestDomain,
    editGuestDomainCall
  );

  const confirm = useConfirmationWithInputDialog();

  const { navigateTo, routeParams } = useNavigateTo();

  const onBack = useCallback(() => {
    navigateTo(DOMAINS_ROUTE, { ...routeParams });
  }, [navigateTo, routeParams]);

  const onSubmit = useCallback(
    async (values: GuestDomainFormData) => {
      const success = await editRecord(values);
      if (!success) {
        notifyError("Error updating guest domain.");
      } else {
        onBack();
      }
    },
    [editRecord, onBack]
  );

  const onDelete = useCallback(async () => {
    if (id) {
      setFormHidden(true);
      const answer = await confirm({
        cancelLabel: "Cancel",
        confirmLabel: "Delete Domain",
        subtext: "",
        text:
          "Are you sure you want to delete this domain? This action instantly removes all content and users within this domain. This action is irreversible.",
        title: "Delete Domain",
        fieldLabel: 'Type "DELETE DOMAIN" to proceed',
        confirmText: "DELETE DOMAIN"
      });

      if (answer.type === DialogResult.CONFIRM) {
        const result = await deleteRecordCommand.deleteRecord({ id });
        if (result) {
          onBack();
        } else {
          notifyError("Error deleting guest domain.");
        }
      } else {
        setFormHidden(false);
      }
    }
  }, [id, confirm, deleteRecordCommand, onBack]);

  useEscapeHook(id !== undefined, onBack);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: formHidden ? "none" : "block" }}
    >
      {/*
      // @ts-ignore */}
      <ModalTemplate
        title="Edit Guest Domain"
        onCloseClick={onBack}
        footer={
          <>
            <button
              type="button"
              className="button buttonOutlined buttonAlert left"
              onClick={onDelete}
            >
              Delete Domain
            </button>
            <button
              className={cx("button", {
                inProgress: inProgress
              })}
              type="submit"
              data-qa={MODAL_LOCATORS.buttons.submit}
            >
              Save
            </button>
          </>
        }
      >
        <TextField
          name="name"
          label="Domain name"
          required
          dataQa={FORM_LOCATORS.inputs.getInput("domain-name")}
        />
        <h4 className="wideMargin">Domain Admin</h4>
        <TextField
          name="adminFirstName"
          label="First name"
          disabled
          dataQa={FORM_LOCATORS.inputs.getInput("first-name")}
        />
        <TextField
          name="adminLastName"
          label="Last name"
          disabled
          dataQa={FORM_LOCATORS.inputs.getInput("last-name")}
        />
        <TextField
          name="adminEmail"
          label="Email"
          disabled
          dataQa={FORM_LOCATORS.inputs.getInput("email")}
        />
      </ModalTemplate>
    </form>
  );
});

export const GuestDomainsEdit = (props: {
  deleteRecordCommand: DeleteRecordCommand;
}) => {
  const fetchGuestDomainDetail = useCallback(
    (api: ApiClient) => api.fetchGuestDomainDetail,
    []
  );

  const { record } = useFetchRecord(
    guestDomain,
    fetchGuestDomainDetail,
    getGuestDomainFormData
  );

  return createPortal(
    <Form
      deleteRecordCommand={props.deleteRecordCommand}
      initialValues={record || {}}
    />,
    document.getElementById("modal") as Element
  );
};
