import React, { useCallback, useEffect, useRef, useState } from "react";
import { Logger as logger } from "purplex-logging";
import { useDispatch } from "react-redux";

import { SceneEditorActions } from "../../scene-editor-reducer";
import {
  EditBackgroundResizingTransformOrigin,
  resizingTransformationHandler
} from "./utils/edit-background-resizing-handlers";
import { useGetMouseMovement } from "./utils/use-mouse-movement";

export const useEditBackgroundResizing = (
  canvasScale: number,
  ratioLocked: boolean,
  assetHeight: number,
  assetWidth: number,
  assetTop: number,
  assetLeft: number,
  totalWallWidth: number,
  wallHeight: number,
  onUpdateBackground: (data: {
    left?: number;
    top?: number;
    height?: number;
    width?: number;
  }) => void
) => {
  const [resizingState, setResizingState] = useState({
    absoluteWidthChange: 0,
    absoluteHeightChange: 0,
    absoluteLeftChange: 0,
    absoluteTopChange: 0,
    resizing: false,
    resizingOrigin: EditBackgroundResizingTransformOrigin.LEFT_TOP
  });

  const offsetRef = useRef({
    offsetX: 0,
    offsetY: 0
  });

  const dispatch = useDispatch();
  const getMouseMovement = useGetMouseMovement();

  const mouseDownHandlerFactory = useCallback(
    (origin: EditBackgroundResizingTransformOrigin) => (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.button !== 0) {
        return;
      }
      logger.debug("Resizing background start.", origin);
      setResizingState((state) => ({
        ...state,
        resizing: true,
        resizingOrigin: origin
      }));
    },
    []
  );

  const mouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      if (resizingState.resizing) {
        const { movementX, movementY } = getMouseMovement(e);

        const currentOffset = {
          offsetX: offsetRef.current.offsetX - movementX,
          offsetY: offsetRef.current.offsetY - movementY
        };
        offsetRef.current = currentOffset;

        const transformedState = resizingTransformationHandler[
          resizingState.resizingOrigin
        ](
          currentOffset,
          canvasScale,
          ratioLocked,
          assetWidth,
          assetHeight,
          assetLeft,
          assetTop,
          wallHeight,
          totalWallWidth
        );

        logger.debug(
          "Resizing background",
          transformedState,
          resizingState.resizingOrigin
        );

        setResizingState((state) => ({
          ...state,
          ...transformedState
        }));

        dispatch(
          SceneEditorActions.setEditBackgroundResizingState({
            absoluteWidthChange: transformedState.absoluteWidthChange,
            absoluteHeightChange: transformedState.absoluteHeightChange,
            absoluteLeftChange: transformedState.absoluteLeftChange,
            absoluteTopChange: transformedState.absoluteTopChange
          })
        );
      }
    },
    [
      resizingState.resizing,
      resizingState.resizingOrigin,
      offsetRef,
      ratioLocked,
      dispatch,
      assetLeft,
      assetTop,
      totalWallWidth,
      wallHeight,
      assetHeight,
      assetWidth,
      canvasScale,
      getMouseMovement
    ]
  );

  const mouseUpHandler = useCallback(() => {
    if (resizingState.resizing) {
      logger.debug("Resizing finished");

      const transformedState = resizingTransformationHandler[
        resizingState.resizingOrigin
      ](
        offsetRef.current,
        canvasScale,
        ratioLocked,
        assetWidth,
        assetHeight,
        assetLeft,
        assetTop,
        wallHeight,
        totalWallWidth
      );

      onUpdateBackground({
        width: Math.round(assetWidth + transformedState.absoluteWidthChange),
        height: Math.round(assetHeight + transformedState.absoluteHeightChange),
        left: Math.round(assetLeft + transformedState.absoluteLeftChange),
        top: Math.round(assetTop + transformedState.absoluteTopChange)
      });
      offsetRef.current = { offsetY: 0, offsetX: 0 };
      //TODO ditch local state and use just redux store
      setResizingState({
        resizing: false,
        absoluteHeightChange: 0,
        absoluteWidthChange: 0,
        absoluteTopChange: 0,
        absoluteLeftChange: 0,
        resizingOrigin: EditBackgroundResizingTransformOrigin.RIGHT_BOTTOM
      });
      dispatch(
        SceneEditorActions.setEditBackgroundResizingState({
          absoluteWidthChange: 0,
          absoluteHeightChange: 0,
          absoluteLeftChange: 0,
          absoluteTopChange: 0
        })
      );
    }
  }, [
    offsetRef,
    resizingState.resizing,
    onUpdateBackground,
    assetTop,
    assetLeft,
    assetHeight,
    assetWidth,
    canvasScale,
    ratioLocked,
    totalWallWidth,
    wallHeight,
    resizingState.resizingOrigin,
    dispatch
  ]);

  const draggingContextMenuHandler = useCallback(
    (e: MouseEvent) => {
      if (resizingState.resizing) {
        e.preventDefault();
      }
    },
    [resizingState.resizing]
  );

  useEffect(() => {
    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
    window.addEventListener("contextmenu", draggingContextMenuHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      window.removeEventListener("contextmenu", draggingContextMenuHandler);
    };
  }, [mouseUpHandler, mouseMoveHandler, draggingContextMenuHandler]);

  return {
    resizingState,
    mouseDownHandlerFactory
  };
};
