import React from "react";

import { AssetStatus } from "shared/types/asset-status";
import { AssetType } from "shared/types/asset-type";
import { ReactComponent as IconImage } from "../../../../images/assets/image.svg";
import { ReactComponent as IconVideo } from "../../../../images/assets/video.svg";
import { ReactComponent as IconAudio } from "../../../../images/assets/audio.svg";
import { ReactComponent as IconLink } from "../../../../images/assets/link.svg";
import { ReactComponent as IconPdf } from "../../../../images/assets/pdf.svg";
import { ReactComponent as IconPresentation } from "../../../../images/assets/presentation.svg";

export const renderAssetIcon = (type: AssetType, status: AssetStatus) => {
  switch (type) {
    case AssetType.ASSET_URL:
      return (
        <>
          <IconLink />
          <small>URL</small>
        </>
      );
    case AssetType.ASSET_VIDEO:
      return (
        <>
          {status !== AssetStatus.STATUS_READY && <IconVideo />}
          <small>Video</small>
        </>
      );
    case AssetType.ASSET_IMAGE:
      return (
        <>
          {status !== AssetStatus.STATUS_READY && <IconImage />}
          <small>Image</small>
        </>
      );
    case AssetType.ASSET_SOUND:
      return (
        <>
          <IconAudio />
          <small>Audio</small>
        </>
      );
    case AssetType.ASSET_PDF:
      return (
        <>
          <IconPdf />
          <small>PDF</small>
        </>
      );
    case AssetType.ASSET_PRESENTATION:
      return (
        <>
          <IconPresentation />
          <small>Presentation</small>
        </>
      );
    default:
      return null;
  }
};
