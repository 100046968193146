import React, { useEffect, useState } from "react";
import { InjectedFormProps, SubmissionError } from "redux-form";

import { TextField } from "../../../../forms/text-field";
import { ModalTemplate } from "../../../../ux/modal";
import { HierarchicalSelectField } from "../../../../forms/hierarchical-select-field";
import { validateCategoryLabel } from "../../../../api/client";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const closeModal = () => window.history.back();

export interface CustomFieldCategoryFormData {
  label?: string;
  parentId?: number;
}

export interface CustomFieldCategoryFormErrors {
  label?: string;
  parentId?: string;
}

export interface CustomFieldsCategoryFormProps {
  onSubmit: (values: CustomFieldCategoryFormData) => void;
  onDelete: (payload: { id: string | number }) => void;
  categoryTree: any[];
}

export interface CustomFieldsCategoryFormPropsInjected
  extends CustomFieldsCategoryFormProps,
    InjectedFormProps<CustomFieldCategoryFormData> {}

const asyncValidate = async (
  values: CustomFieldCategoryFormData,
  initialValues: CustomFieldCategoryFormData
): Promise<CustomFieldCategoryFormErrors> => {
  const errors: CustomFieldCategoryFormErrors = {};
  if (values.label === initialValues.label) {
    return errors;
  }
  const { valid } = await validateCategoryLabel({
    label: values.label || "",
    parentId: values.parentId !== undefined ? values.parentId : null
  });
  if (!valid) {
    errors.label = "Category label must be unique";
  }
  return errors;
};

export const CustomFieldsCategoryForm = (props: any) => {
  const {
    handleSubmit,
    onSubmit,
    onDelete,
    categoryTree,
    initialValues
  } = props;

  const [localOptionTree, setLocalOptionTree]: any = useState([]);

  useEffect(() => {
    setLocalOptionTree([
      { id: 0, label: "No category", children: [] },
      ...categoryTree
    ]);
  }, [categoryTree]);

  const onSubmitHandler = handleSubmit((values: any) => {
    return (async () => {
      const errors = await asyncValidate(values, initialValues);
      if (errors.label) {
        throw new SubmissionError(errors);
      } else {
        onSubmit(values);
        return;
      }
    })();
  });

  return (
    <form onSubmit={onSubmitHandler} style={{ position: "absolute" }}>
      {/*
      // @ts-ignore */}
      <ModalTemplate
        title="Category"
        className="modalEditCategory"
        onCloseClick={closeModal}
        data-qa={MODAL_LOCATORS.getModal("edit-category")}
        footer={
          <>
            <button
              className="button buttonAlert left buttonOutlined"
              type="button"
              onClick={onDelete}
              data-qa="btn-delete"
            >
              Delete
            </button>
            <button
              className="button"
              type="submit"
              data-qa={MODAL_LOCATORS.buttons.save}
            >
              Save
            </button>
          </>
        }
      >
        <TextField name="label" label="Name" required />
        <HierarchicalSelectField
          name="parentId"
          label="Parent Category"
          optionTree={localOptionTree}
        />
      </ModalTemplate>
    </form>
  );
};
