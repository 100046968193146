import React from "react";
import { useSelector } from "react-redux";
import { endsWithSegment } from "router5-helpers";

import { CustomFieldsList } from "./lists/custom-fields-list";
import { CustomFieldsCreate } from "./filter-forms/custom-fields-create";
import { CustomFieldsEdit } from "./filter-forms/custom-fields-edit";
import { getRouteName } from "../../../routing/routing-selectors";
import { CustomFieldsCategoryEdit } from "./category-forms/custom-fields-category-edit";
import { CustomFieldsEditMapping } from "./filter-forms/custom-fields-edit-mapping";
export const CustomFieldsIndex = () => {
  const route = useSelector(getRouteName) as string;
  const ends = endsWithSegment(route);

  return (
    <>
      <CustomFieldsList />
      {ends("add-filter") && <CustomFieldsCreate />}
      {ends("edit-filter") && <CustomFieldsEdit />}
      {ends("edit-filter-mapping") && <CustomFieldsEditMapping />}
      {ends("edit-category") && <CustomFieldsCategoryEdit />}
    </>
  );
};
