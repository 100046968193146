import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as routerActions } from "redux-router5";
import { DropTargetMonitor, useDrop } from "react-dnd";
import cx from "classnames";

import { ReactComponent as ArrowUpIcon } from "../../../../../images/arrow-up.svg";

import {
  getRouteName,
  getRouteParams,
  RouteParamSelector
} from "../../../../routing/routing-selectors";
import { CustomFieldsCategoryAddForm } from "../category-forms/custom-fields-category-add";
import { ReactComponent as IconAdd } from "../../../../../images/add.svg";
import { CustomFieldEntity } from "../../../../../../shared/types/custom-field-entity";
import { DebouncedSearchInput } from "../../../../ux/debounced-search-input";
import {
  AddFilterRouteParentParam,
  CategoryTreeNode
} from "../../custom-field-types";
import {
  useCreateCategory,
  useDeleteCategory
} from "../../hooks/use-category-crud";
import {
  useAssignFilterToCategory,
  useChangeFilterOrder,
  useUnAssignFilterFromCategory
} from "../../hooks/use-filters-crud";
import { FilterListItem } from "./filter-list-item";
import { moveDownFactory, moveUpFactory } from "../../custom-field-utils";
import { CategoryListItem } from "./category-list-item";
import { useGetCustomFieldType } from "../../hooks/use-get-custom-field-type";

const noop = () => {};

export const CategoryTreeRoot = (props: {
  categoryTree: CategoryTreeNode[];
}) => {
  const { categoryTree } = props;

  const route = useSelector(getRouteName) as string;
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{
      id: number;
    }>
  );
  const assetType = useGetCustomFieldType();

  const dispatch = useDispatch();
  const createCategory = useCreateCategory();
  const deleteCategory = useDeleteCategory();
  const onAssignFilter = useAssignFilterToCategory();
  const unAssignFilter = useUnAssignFilterFromCategory();
  const changeSort = useChangeFilterOrder();

  const [filterValue, setFilterValue] = useState("");
  const [openedChildren, setOpenedChildren] = useState<number[]>([]);
  const [matchSelected, setMatchSelected] = useState(false);

  const [{ isOver }, drop] = useDrop({
    accept: "dndAssignFilter",
    canDrop: (item: never, monitor: DropTargetMonitor) => {
      return !categoryTree.find(
        (child: any) => child.customFieldId === monitor.getItem().id
      );
    },
    drop: (item: never, monitor: DropTargetMonitor) => {
      onAssignFilter({
        filterId: monitor.getItem().id,
        categoryId: 0,
        entity: assetType
      });
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  const editFilter = useCallback(
    (fieldId: number) => {
      dispatch(
        routerActions.navigateTo(`${route}.edit-filter-mapping`, {
          ...routeParams,
          fieldId
        })
      );
    },
    [dispatch, routeParams, route]
  );

  const editCategory = useCallback(
    (id: number) => {
      dispatch(
        routerActions.navigateTo(`${route}.edit-category`, {
          ...routeParams,
          id
        })
      );
    },
    [dispatch, routeParams, route]
  );

  const addFilter = useCallback(
    (id: number | string) => {
      dispatch(
        routerActions.navigateTo(`${route}.add-filter`, {
          ...routeParams,
          id
        })
      );
    },
    [dispatch, routeParams, route]
  );

  const collapseTree = useCallback(() => setOpenedChildren([]), []);

  const matchSelectedHandler = useCallback((id: number) => {
    setFilterValue("");
    setOpenedChildren([id]);
    setMatchSelected(true);
  }, []);

  const moveUp = useCallback(moveUpFactory(categoryTree, changeSort), [
    categoryTree,
    changeSort
  ]);

  const moveDown = useCallback(moveDownFactory(categoryTree, changeSort), [
    categoryTree,
    changeSort
  ]);

  useEffect(() => {
    if (!filterValue && !matchSelected) collapseTree();
    setMatchSelected(false);
    // eslint-disable-next-line
  }, [filterValue]);

  return (
    <>
      <header className="contentHeader">
        <div>
          {assetType === CustomFieldEntity.ASSET && (
            <CustomFieldsCategoryAddForm
              parentId={null}
              onSubmit={({ label }: { label: string }) => {
                createCategory({
                  label,
                  path: "/",
                  parentId: null,
                  entity: assetType
                });
              }}
            />
          )}
          {assetType === CustomFieldEntity.PRESENTATION && (
            <button
              className={cx("addItemButton")}
              onClick={() => addFilter(AddFilterRouteParentParam.ROOT)}
              data-qa="btn-add-filter"
            >
              <IconAdd />
              Add Filter
            </button>
          )}
        </div>
        <div className="search inputWithButton">
          <DebouncedSearchInput
            placeholder={
              assetType === CustomFieldEntity.ASSET
                ? "Search categories"
                : "Search filters"
            }
            onChange={(filterString: string) => {
              setFilterValue(filterString);
            }}
            value={filterValue}
          />
        </div>
      </header>

      {assetType === CustomFieldEntity.ASSET && (
        <>
          {categoryTree
            .filter((hierarchy) => !hierarchy.service)
            .map((item) => (
              <CategoryListItem
                {...item}
                categoryTree={item.children}
                assetType={assetType}
                key={item.id}
                onToggle={() => {
                  if (filterValue) {
                    setOpenedChildren([...openedChildren, item.id]);
                  } else {
                    if (openedChildren.includes(item.id)) {
                      setOpenedChildren([]);
                    } else {
                      setOpenedChildren([item.id]);
                    }
                  }
                }}
                onOpen={() => {
                  setOpenedChildren((state) => [...state, item.id]);
                }}
                onClose={() => {
                  setOpenedChildren((state) =>
                    state.filter((child) => child !== item.id)
                  );
                }}
                onEditCategory={editCategory}
                onEditFilter={editFilter}
                onAddCategory={createCategory}
                onAddFilter={addFilter}
                onAssignFilter={onAssignFilter}
                onUnAssignFilter={unAssignFilter}
                onMoveUp={moveUp}
                onMoveDown={moveDown}
                onChangeSort={changeSort}
                filterValue={filterValue}
                opened={openedChildren.includes(item.id)}
                onDeleteCategory={deleteCategory}
                matchSelected={matchSelectedHandler}
              />
            ))}
        </>
      )}
      {assetType === CustomFieldEntity.PRESENTATION && (
        <>
          <div
            ref={drop}
            className={cx("gridRowDnD rowFilter", {
              gridRowDnD: true,
              gridRowDndOver: isOver
            })}
          >
            <div
              className="rowLevel"
              style={{
                paddingTop: categoryTree.length ? "2rem" : "1rem"
              }}
            >
              {categoryTree.length ? (
                categoryTree.map((item) => (
                  <FilterListItem
                    {...item}
                    key={item.id}
                    onUnAssignFilter={unAssignFilter}
                    onEdit={editFilter}
                    onMoveUp={moveUp}
                    onMoveDown={moveDown}
                    filterValue={filterValue}
                    onMatchSelected={noop}
                  />
                ))
              ) : (
                <div className="emptyFilters">
                  <span className="dndIcon">
                    <ArrowUpIcon />
                  </span>
                  Drag &nbsp; Drop to activate filters.
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
