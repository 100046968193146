import React, { useCallback } from "react";
import { useSelector, useStore } from "react-redux";
import { Logger as logger } from "purplex-logging";

import { ReactComponent as IconTextAdd } from "client/images/text-add.svg";
import { useSceneEditorActions } from "../../hooks/asset-config/use-scene-editor-actions";
import { getActiveWallInfo } from "../../scene-editor-selectors";
import { Hint } from "../ux/hint";
import { getDefaultEditorValues } from "client/modules/settings/editor-settings/editor-settings-selectors";
import { TextAssetFontSize } from "../../../../../shared/types/asset";

const DEFAULT_TEXT_SIZE_DIMENSIONS_MAP = {
  [TextAssetFontSize.XS]: { width: 15, height: 10 },
  [TextAssetFontSize.S]: { width: 20, height: 13 },
  [TextAssetFontSize.M]: { width: 28, height: 17 },
  [TextAssetFontSize.L]: { width: 35, height: 20 },
  [TextAssetFontSize.XL]: { width: 40, height: 25 },
  [TextAssetFontSize.XXL]: { width: 47, height: 30 }
};

export const TextAssetsControl = () => {
  const activeWallInfo = useSelector(getActiveWallInfo);
  const { actionsMap } = useSceneEditorActions();
  const store = useStore();

  const onAddNewTextAsset = useCallback(() => {
    const defaultValues = getDefaultEditorValues(store.getState());

    if (!activeWallInfo || !defaultValues) {
      return;
    }
    actionsMap.CREATE_TEXT_ASSET({
      text: "New Text",
      fontSize: defaultValues.EDITOR_DEFAULT_TEXT_SIZE,
      width:
        DEFAULT_TEXT_SIZE_DIMENSIONS_MAP[defaultValues.EDITOR_DEFAULT_TEXT_SIZE]
          .width,
      height:
        DEFAULT_TEXT_SIZE_DIMENSIONS_MAP[defaultValues.EDITOR_DEFAULT_TEXT_SIZE]
          .height,
      bold: false,
      italic: false,
      underline: false,
      alignment: "left",
      color: "#000",
      font: defaultValues.EDITOR_DEFAULT_TEXT_FONT,
      left: activeWallInfo.start,
      top: 0,
      opacity: defaultValues.EDITOR_DEFAULT_OPACITY,
      rounded: defaultValues.EDITOR_DEFAULT_ROUNDED,
      glow: defaultValues.EDITOR_DEFAULT_GLOW,
      positionLocked: defaultValues.EDITOR_DEFAULT_POSITION_LOCKED,
      visible: defaultValues.EDITOR_DEFAULT_VISIBLE_ON_START
    });
    logger.debug("Adding new text asset");
  }, [actionsMap, activeWallInfo]);

  return (
    <Hint label="Add Text" placement="right">
      <button
        type="button"
        className="button buttonIcon buttonConfigPanel"
        data-qa="new-text-asset-button"
        onClick={onAddNewTextAsset}
      >
        <IconTextAdd />
      </button>
    </Hint>
  );
};
