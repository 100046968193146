import React from "react";
import PropTypes from "prop-types";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";

export const ROW_STATUS = {
  LOADING: "LOADING",
  LOADED: "LOADED"
};

export class ListInfiniteScroll extends React.Component {
  state = {
    rows: {},
    totalCount: 10
  };

  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    rowHeight: PropTypes.number.isRequired,
    emptyPlaceholder: PropTypes.node.isRequired,
    rowRendererProps: PropTypes.any
  };

  loadMoreRows = async ({ startIndex, stopIndex }) => {
    let rowsCopy = { ...this.state.rows };

    for (let i = startIndex; i <= stopIndex; i++) {
      rowsCopy[i] = { status: ROW_STATUS.LOADING, record: null };
    }

    this.setState({
      rows: rowsCopy
    });

    const data = await this.props.fetchData(startIndex, stopIndex);

    rowsCopy = { ...this.state.rows };
    for (let i = startIndex; i <= stopIndex; i++) {
      rowsCopy[i] = {
        status: ROW_STATUS.LOADED,
        record: data.rows[i - startIndex]
      };
    }
    this.setState({ rows: rowsCopy, totalCount: data.count });
  };

  isRowLoaded = ({ index }) =>
    this.state.rows[index] &&
    this.state.rows[index].status !== ROW_STATUS.LOADING;

  rowRenderer = (rowRendererProps) => ({ index, key, style }) => {
    const row = this.state.rows[index];

    return (
      <div key={key} style={style}>
        {row && this.props.children(row.status, row.record, rowRendererProps)}
      </div>
    );
  };

  render() {
    const { totalCount } = this.state;
    const { rowHeight, emptyPlaceholder } = this.props;

    return (
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={totalCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => {
              return totalCount > 0 ? (
                <List
                  ref={registerChild}
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  rowCount={totalCount}
                  rowHeight={rowHeight}
                  rowRenderer={this.rowRenderer(this.props.rowRendererProps)}
                />
              ) : (
                emptyPlaceholder
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  }
}
