import React, { useState, useMemo, useCallback } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import { components } from "react-select";

import { ReactComponent as BinIcon } from "client/images/bin.svg";
import { ReactComponent as CrossIcon } from "client/images/cross-alt.svg";
import { Avatar } from "client/modules/settings/users/components/avatar";
import { SelectUserField } from "client/modules/settings/users/components/select-user-field";
import { UserSafe } from "shared/types/user";
import { RootState } from "../../root/root-reducer";
import { DialogEvent } from "../event-types";
import { ModalDialogBase } from "./modal-dialog-base";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const COLLABORATOR_FORM_NAME = "collaborators-form";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <CrossIcon />
    </components.MultiValueRemove>
  );
};

const RenderOption = ({
  firstName,
  lastName,
  email,
  avatar
}: Record<string, string>) => (
  <>
    <Avatar firstName={firstName} lastName={lastName} avatar={avatar} />
    <span>
      <span className="username">
        {firstName} {lastName}
      </span>
      <span className="email">{email}</span>
    </span>
  </>
);

interface FormData {
  collaborators: UserSafe[];
}

const validate = (data: FormData) => {
  const errors: { [key: string]: string } = {};

  if (!data.collaborators || data.collaborators.length === 0) {
    errors.collaborators = "At least one user account is required.";
  }

  return errors;
};

const CollaboratorForm = reduxForm<FormData, { collaboratorsToHide: number[] }>(
  {
    form: COLLABORATOR_FORM_NAME,
    enableReinitialize: true,
    validate
  }
)(({ handleSubmit, collaboratorsToHide }) => {
  const collaborators =
    useSelector((state: RootState) =>
      formValueSelector(COLLABORATOR_FORM_NAME)(state, "collaborators")
    ) || [];

  return (
    <form onSubmit={handleSubmit}>
      <SelectUserField
        name="collaborators"
        label=""
        isMulti
        components={{ MultiValueRemove }}
        resourceAccess={undefined}
        filterOption={(option: { data: UserSafe }) =>
          !collaboratorsToHide.includes(option.data.id)
        }
        formatOptionLabel={RenderOption}
      />
      <button
        type="submit"
        className="button"
        disabled={!collaborators.length}
        data-qa={MODAL_LOCATORS.buttons.submit}
      >
        Invite
      </button>
    </form>
  );
});

export const CollaboratorsDialog = (props: {
  open: boolean;
  creatorId: number;
  collaborators: UserSafe[];
  onEvent: (type: DialogEvent, payload?: any) => void;
}) => {
  const [resetCounter, setResetCounter] = useState(0);
  const incrementResetCounter = useCallback(() => {
    setResetCounter((value) => value + 1);
  }, [setResetCounter]);
  const { open, collaborators, creatorId, onEvent } = props;
  const [selectedCollaborators, setSelectedCollaborators] = useState(
    collaborators
  );

  const collaboratorsToHide = useMemo(
    () => selectedCollaborators.map((collaborator) => collaborator.id),
    [selectedCollaborators]
  );
  const removeCollaborator = useCallback(
    (collaborator: UserSafe) => {
      setSelectedCollaborators((collaborators) =>
        collaborators.filter(({ id }) => id !== collaborator.id)
      );
    },
    [setSelectedCollaborators]
  );

  const sortedCollaborators = [...selectedCollaborators];
  sortedCollaborators.sort((a, b) => {
    if (a.id === creatorId) {
      return -1;
    } else if (b.id === creatorId) {
      return 1;
    } else {
      return a.lastName.localeCompare(b.lastName);
    }
  });

  return (
    <ModalDialogBase
      open={open}
      title="Collaborate"
      className="collaborateModal"
      text={
        <div data-qa="collaborators-dialog">
          <CollaboratorForm
            key={resetCounter}
            collaboratorsToHide={collaboratorsToHide}
            onSubmit={(data, _, form) => {
              setSelectedCollaborators((collaborators) => [
                ...collaborators,
                ...data.collaborators
              ]);

              form && form.reset && form.reset();

              // This is a hack because of bug in React-select
              // so basically even when we reset the value of the form
              // it doesn't reset the internal state in the react-select component
              // so we need to reinitialize whole component.
              incrementResetCounter();
            }}
          />
          <ul className="avatarList">
            {sortedCollaborators.map((collaborator) => (
              <li key={collaborator.id}>
                <div>
                  <Avatar
                    firstName={collaborator.firstName}
                    lastName={collaborator.lastName}
                    avatar={collaborator.avatar}
                  />
                  {collaborator.firstName} {collaborator.lastName}
                </div>
                <span>
                  {collaborator.id === creatorId && <>Owner</>}
                  {collaborator.id !== creatorId && <>Collaborator</>}
                  {collaborator.id !== creatorId && (
                    <button
                      type="button"
                      className="button buttonIcon"
                      onClick={() => {
                        removeCollaborator(collaborator);
                      }}
                    >
                      <BinIcon />
                    </button>
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      }
      onEvent={onEvent}
      onClose={() => {
        onEvent(DialogEvent.ON_CLOSE, selectedCollaborators);
      }}
    />
  );
};
