import { RowActionType, RowCellType } from "../types/row.types";

export const ROW_LOCATORS = {
  title: "row-title",
  creator: "row-creator",
  created: "row-created",
  updated: "row-updated",
  actions: "actions",
  getAction: (action: RowActionType) => `action-${action}`,
  getRow: (id: number) => `row-${id}`,
  getRowCell: (cell: RowCellType) => `row-${cell}`,
  getCustomFieldColumn: (value: string) => `custom-field-${value}`
};
