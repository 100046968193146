import React from "react";
import { useSelector } from "react-redux";
import { getVixUrl } from "client/modules/config/config-selectors";

export const DashboardTileVix = () => {
  const vixUrl: string = useSelector(getVixUrl);

  return (
    <div className="card" data-qa="card-vix">
      <h2 className="title" data-qa="card-title">
        Online presentations
      </h2>
      <div className="buttonWrapper" data-qa="btn-vix">
        <a
          className="button"
          href={`${vixUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to Lobby
        </a>
      </div>
    </div>
  );
};
