import {
  all,
  select,
  fork,
  call,
  put,
  take,
  race,
  takeEvery,
  spawn,
  join,
  delay
} from "redux-saga/effects";
import { getFormSyncErrors } from "redux-form";
import { Logger as logger } from "purplex-logging";
import uniqueId from "lodash/uniqueId";
import { actions as routerActions } from "redux-router5";
import { reset, change } from "redux-form";
import { getCdnHost } from "../config/config-selectors";
import { takeEveryWithCheckProgress } from "../in-progress/in-progress-saga";
import { WALLS_LIST_ROUTE } from "../routing/route-names";
import { MEDIA_TITLE_MAPPING } from "./components/media-dialog/media-title-mapping";
import { getAssetFile } from "./get-asset-file";
import Actions from "./media-actions";
import * as Api from "../api/client";
import * as Websockets from "../websockets/websocket-effects";
import { getAssetType } from "./asset-mime-types";
import { normalizeAndStore } from "../entity-repository/entity-repository-saga";
import {
  asset as assetSchema,
  assets as assetsSchema,
  instances as instancesSchema
} from "../entity-repository/schema";
import { getAssets } from "../entity-repository/entity-repository-selectors";
import {
  ASSET_TYPES as RegularAssetTypes,
  getHumanReadableAssetTypeGroupName
} from "./asset-types";
import { WALL_TYPES as WallTypes } from "./wall-types";
import {
  getCurrentNumberOfUploads,
  getNextUpload,
  getSelectedAsset,
  getUploadQueue,
  getUploadStates,
  getDialogType,
  enableAlternativeBackgroundAssets
} from "./media-selectors";
import { confirmDialogSaga } from "../dialogs/dialogs-saga";
import { getRouteName, getRouteParams } from "../routing/routing-selectors";
import { onRouteEntered } from "../routing/on-route-enter-saga";
import {
  notifySuccess,
  notifyError
} from "../ux/notifications/notifications-saga";
import { URL_ASSET_FORM } from "./components/media-dialog/url-asset-form";
import { UploadResults } from "./upload-results";
import { UploadStatus } from "./upload-statuses";
import { MediaDialogType } from "./components/media-dialog/media-dialog-type";
import { getCustomFieldToEntityMappings } from "../entity-repository/entity-repository-selectors";
import { entityFormToCustomFields } from "../settings/custom-fields/components/filters/filters-block";
import { FORM_NAME as editSharedAssetForm } from "./components/shared-library/edit-shared-asset";
import { DialogResult } from "../dialogs/event-types";
import * as UserTrackingSaga from "../user-tracking/user-tracking-saga";
import * as UserTrackingCreators from "../user-tracking/user-tracking-creators";
import { getUser } from "../auth/auth-selectors";

const { AssetType } = require("../../../shared/types/asset-type");

function* createAsset(file) {
  const dialogType = yield select(getDialogType);
  const type = getAssetType(
    file.type,
    dialogType === MediaDialogType.WALL_ASSET
  );

  let asset;
  switch (dialogType) {
    case MediaDialogType.WALL_ASSET:
      asset = yield call(Api.createWallAsset, file.name, file.name, type);
      break;
    case MediaDialogType.ASSET:
      asset = yield call(
        Api.createAsset,
        file.name,
        file.name,
        type,
        {},
        false
      );
      break;
    case MediaDialogType.SHARED_ASSET:
      asset = yield call(Api.createAsset, file.name, file.name, type, {}, true);
      break;
    default:
      throw new Error(`Unknown state ${dialogType}`);
  }

  yield call(normalizeAndStore, asset, assetSchema);
  return asset;
}

function* uploadAll(assets) {
  for (let i = 0; i < assets.length; i++) {
    const { asset, file } = assets[i];
    yield fork(uploadAsset, asset.uuid, file);
  }
}

export function* openUploadDialog() {
  const queue = yield select(getUploadQueue);

  const dialogType = yield select(getDialogType);

  const wallAssetsPredicate = ({ asset: { type, shared } }) =>
    WallTypes.includes(type) && !shared;
  const nonWallAssetsPredicate = ({ asset: { type, shared } }) =>
    !WallTypes.includes(type) && !shared;
  const sharedAssetsPredicate = ({ asset }) => asset.shared;

  const getAssetTypeFilterPredicate = () => {
    switch (dialogType) {
      case MediaDialogType.ASSET:
        return nonWallAssetsPredicate;
      case MediaDialogType.WALL_ASSET:
        return wallAssetsPredicate;
      case MediaDialogType.SHARED_ASSET:
        return sharedAssetsPredicate;
      default:
        throw new Error("Invalid state");
    }
  };

  // adds also files which are in progress of uploaded but were not
  // created directly by this dialog
  const prefillAssets = queue
    .filter(getAssetTypeFilterPredicate())
    .filter(
      ({ upload: { status } }) =>
        status === UploadStatus.PENDING || status === UploadStatus.UPLOADING
    )
    .map(({ asset: { uuid } }) => uuid);

  yield put(Actions.Creators.showDialog(prefillAssets));

  let finished = false;
  let dirty = false;
  while (!finished) {
    const { hide } = yield race({
      hide: take(Actions.Types.HIDE_DIALOG),
      upload: take(Actions.Types.UPLOAD_FILES),
      edit: take(Actions.Types.SAVE_SELECTED_ASSET),
      createUrl: take(Actions.Types.CREATE_URL_ASSET),
      removed: take(Actions.Types.REMOVE_ASSET)
    });

    if (hide) {
      finished = true;
    } else {
      // If user does at least one action whcih actually
      // changes the asset we will return true from the saga
      // otherwise false is returned to indicate that nothing has changed
      dirty = true;
    }
  }

  return dirty;
}

export function* openEditDialog(uuid) {
  const routeName = yield select(getRouteName);
  const routeParams = yield select(getRouteParams);

  yield put(
    routerActions.navigateTo(`${routeName}.edit-media`, {
      ...routeParams,
      uuid
    })
  );
}

export function* openEditSharedAssetDialog({ uuid }) {
  const routeName = yield select(getRouteName);
  const routeParams = yield select(getRouteParams);

  yield put(
    routerActions.navigateTo(`${routeName}.edit-shared-asset`, {
      ...routeParams,
      uuid
    })
  );
  yield take(Actions.Types.CLOSE_EDITING);
}

function* createAndUploadAlternativeFile(assetUuid, file) {
  const alternativeAssetFile = yield call(
    Api.createWallAlternativeAsset,
    assetUuid
  );
  yield call(normalizeAndStore, alternativeAssetFile, assetSchema);

  // Since creating alternative asset not only returns new entity
  // it also updates the root entity we have to refetch the asset
  // in order to fill the alternativeAssets field on the root asset
  const updatedWall = yield call(Api.getWall, assetUuid);
  yield call(normalizeAndStore, updatedWall, assetSchema);

  yield fork(uploadAsset, alternativeAssetFile.uuid, file);
}

function* onEditMedia() {
  const { uuid } = yield select(getRouteParams);
  const dialogType = yield select(getDialogType);

  let asset;
  switch (dialogType) {
    case MediaDialogType.ASSET:
      asset = yield call(Api.getAsset, uuid);
      break;
    case MediaDialogType.WALL_ASSET:
      asset = yield call(Api.getWall, uuid);
      break;
    default:
      throw new Error(`Unknown state ${dialogType}`);
  }

  yield call(normalizeAndStore, asset, assetSchema);
}

function* onSaveMedia({ data, categories }) {
  try {
    const { uuid } = data;

    const metadata = {};
    if (data.url) {
      metadata.url = data.url;
    }

    const dialogType = yield select(getDialogType);
    const customFieldToEntityMappings = yield select(
      getCustomFieldToEntityMappings
    );
    const customFields = entityFormToCustomFields(
      data,
      customFieldToEntityMappings
    );

    let updatedAsset;
    switch (dialogType) {
      case MediaDialogType.WALL_ASSET:
        // eslint-disable-next-line no-unused-vars,no-case-declarations
        const [, ...alternativeAssets] = data.files
          .map((file) => ({
            uuid: file.uuid
          }))
          .filter(({ uuid }) => Boolean(uuid));

        updatedAsset = yield call(
          Api.updateWall,
          uuid,
          data.title,
          data.description,
          alternativeAssets
        );
        break;
      case MediaDialogType.ASSET:
        updatedAsset = yield call(
          Api.updateAsset,
          uuid,
          data.title,
          data.description,
          metadata,
          customFields,
          categories
        );
        break;
      default:
        throw new Error(`Unknown state ${dialogType}`);
    }

    yield call(normalizeAndStore, updatedAsset, assetSchema);
    const routeName = yield select(getRouteName);
    const routeParams = yield select(getRouteParams);

    yield put(
      routerActions.navigateTo(
        routeName.replace(".edit-media", ""),
        routeParams,
        {
          replace: true
        }
      )
    );

    yield all(
      (data.files || [])
        // Filter out the one transient non-uploaded record (placeholder)
        .filter(Boolean)
        // Filter out files which haven't been changed
        .filter((data) => data.file instanceof File && !data.rejected)
        .map((data) => {
          // If data.uuid is null it means it's new file (alternative file)
          if (data.uuid === null) {
            return fork(createAndUploadAlternativeFile, uuid, data.file);
          } else {
            // Otherwise let's just uppdate the content of the file
            return fork(uploadAsset, data.uuid, data.file);
          }
        })
    );
  } catch (ex) {
    yield call(
      notifyError,
      `An error occurred while updating asset ${data.title}.`
    );
    logger.warn("Asset update error", ex);
  }
}

function* onCloseEditing() {
  const routeName = yield select(getRouteName);
  const routeParams = yield select(getRouteParams);

  delete routeParams.uuid;

  yield put(
    routerActions.navigateTo(
      routeName.replace(".edit-media", "").replace(".edit-shared-asset", ""),
      routeParams,
      {
        replace: true
      }
    )
  );
}

function* onSaveSelectedAsset({ data }) {
  const { title, description, url } = data;
  try {
    const selectedAsset = yield select(getSelectedAsset);

    const metadata = {};

    if (selectedAsset.type === AssetType.ASSET_URL) {
      metadata.url = url;
    }

    const dialogType = yield select(getDialogType);

    let updatedAsset;
    switch (dialogType) {
      case MediaDialogType.ASSET:
        updatedAsset = yield call(
          Api.updateAsset,
          selectedAsset.uuid,
          title,
          description,
          metadata,
          []
        );
        break;
      case MediaDialogType.WALL_ASSET:
        updatedAsset = yield call(
          Api.updateWall,
          selectedAsset.uuid,
          title,
          description
        );
        break;
      default:
        throw new Error(`Unknown state ${dialogType}`);
    }

    yield call(normalizeAndStore, updatedAsset, assetSchema);
    yield call(notifySuccess, `Asset ${title} has been successfully saved.`);
  } catch (ex) {
    yield call(notifyError, `An error occurred while updating asset ${title}.`);
    logger.warn("Asset update error", ex);
  }
}

function* onDeleteSelectedAsset() {
  try {
    const selectedAsset = yield select(getSelectedAsset);
    const dialogType = yield select(getDialogType);
    const assetNoun = MEDIA_TITLE_MAPPING[dialogType];

    const { type } = yield call(confirmDialogSaga, {
      title: "Delete " + assetNoun,
      text: `Are you sure you want to delete ${assetNoun.toLowerCase()} - ${
        selectedAsset.title
      }?`,
      subtext: "",
      confirmLabel: "Delete",
      cancelLabel: "Cancel"
    });

    if (type === DialogResult.CONFIRM) {
      yield put(Actions.Creators.cancelAssetUpload(selectedAsset.uuid));
      yield put(Actions.Creators.removeAsset(selectedAsset.uuid));

      let apiCall;
      switch (dialogType) {
        case MediaDialogType.ASSET:
          apiCall = Api.deleteAsset;
          break;
        case MediaDialogType.WALL_ASSET:
          apiCall = Api.deleteWall;
          break;
        default:
          throw new Error(`Unknown state ${dialogType}`);
      }
      yield call(apiCall, selectedAsset.uuid);
      yield call(
        notifySuccess,
        `Asset ${selectedAsset.title} has been successfully deleted.`
      );
    }
  } catch (ex) {
    yield call(notifyError, `An error occurred while deleting asset.`);
    logger.warn("Asset delete error", ex);
  }
}

function* uploadingSaga({ files }) {
  try {
    const assets = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const asset = yield call(createAsset, file);

      yield call(normalizeAndStore, asset, assetSchema);
      yield put(Actions.Creators.assetCreated(asset.uuid));

      const { roles } = yield select(getUser);
      const { tab } = yield select(getRouteParams);
      yield call(
        UserTrackingSaga.track,
        UserTrackingCreators.trackAssetUploaded(
          "Overview page",
          asset.type,
          roles,
          tab
        )
      );

      assets.push({ asset, file });

      // After uploading first asset
      // let's just preselect it
      const assetSelected = Boolean(yield select(getSelectedAsset));
      if (!assetSelected) {
        yield put(Actions.Creators.selectAsset(asset.uuid));
      }
    }

    yield call(uploadAll, assets);
  } catch (ex) {
    yield call(notifyError, `An error occurred while creating asset.`);
    logger.warn("Asset create error", ex);
  }
}

function* onCreateUrlAsset({ data }) {
  try {
    const { url } = data;

    const asset = yield call(
      Api.createAsset,
      url,
      url,
      AssetType.ASSET_URL,
      {
        url
      },
      false
    );

    yield call(normalizeAndStore, asset, assetSchema);
    yield put(Actions.Creators.assetCreated(asset.uuid));

    const assetSelected = Boolean(yield select(getSelectedAsset));
    if (!assetSelected) {
      yield put(Actions.Creators.selectAsset(asset.uuid));
    }

    yield put(reset(URL_ASSET_FORM));
  } catch (ex) {
    yield call(notifyError, `An error occurred while creating URL asset.`);
    logger.warn("URL asset create error", ex);
  }
}

function* getSelectedAssetId() {
  const selectedAsset = yield select(getSelectedAsset);
  return selectedAsset && selectedAsset.uuid;
}

function* notifyAssetUploadState(uuid, notifySaga, getMessage) {
  const assets = yield select(getAssets);
  const asset = assets[uuid];

  if (asset) {
    yield spawn(notifySaga, getMessage(asset));
  }
}

const buildNotifyFunction = (
  messageFactory,
  alternativeFileMessageFactory,
  notifyFunction
) =>
  function* notifyAssetStatus(uuid) {
    const assets = yield select(getAssets);
    const asset = assets[uuid];

    if (!asset) {
      return;
    }

    const rootAsset = assets[asset.parentUuid] || asset;

    const getMessage = (asset) => {
      if (Boolean(asset.parentUuid) === false) {
        return messageFactory(
          getHumanReadableAssetTypeGroupName(rootAsset.type).toLowerCase(),
          rootAsset.title
        );
      } else {
        return alternativeFileMessageFactory(
          getHumanReadableAssetTypeGroupName(rootAsset.type).toLowerCase(),
          rootAsset.title
        );
      }
    };

    yield call(notifyAssetUploadState, uuid, notifyFunction, getMessage);
  };

export const notifyAssetUploadSuccess = buildNotifyFunction(
  (entityType, title) =>
    `Uploading of ${entityType} ${title} has been successfully finished`,
  (entityType, title) =>
    `Uploading of alternative file for ${entityType} ${title} has been successfully finished`,
  notifySuccess
);

const notifyAssetUploadFail = buildNotifyFunction(
  (entityType, title) => `Uploading of ${entityType} ${title} has failed`,
  (entityType, title) =>
    `Uploading of alternative file for ${entityType} ${title} has failed`,
  notifyError
);

function* onUploadEnd(uuid, status, uploadId = null) {
  const uploadStates = yield select(getUploadStates);
  const originalState = uploadStates[uuid];

  if (
    originalState &&
    (uploadId === null || originalState.uploadId === uploadId)
  ) {
    try {
      yield put(Actions.Creators.setUploadAssetStatus(uuid, status));
      // let users see upload result for a while
      yield delay(5000);
    } finally {
      // hide from the queue based on uploadId to make sure we don't remove
      // a new upload of the same asset which may have started in the meantime
      yield put(Actions.Creators.hideUploadByUploadId(originalState.uploadId));
    }
  }
}

const MAXIMUM_PARALLEL_UPLOADS = 1;
let uniqueAccessLocked = false;

/**
 * Waits until it gets unique access lock
 */
function* acquireLock() {
  while (uniqueAccessLocked) {
    yield take(Actions.Types.SET_UPLOAD_ASSET_STATUS);
  }
  uniqueAccessLocked = true;
}

/**
 * Must be called after acquireLock() is finished
 */
// eslint-disable-next-line require-yield
function* releaseLock() {
  uniqueAccessLocked = false;
}

/**
 * Waits until it is possible to upload the asset.
 * @return boolean Returns true if the upload can start or false if the upload is no more valid
 */
function* waitForUploadSlot(uuid, uploadId) {
  while (true) {
    try {
      // this part is accessed only from one call at a time
      yield call(acquireLock);

      const numberOfUploads = yield select(getCurrentNumberOfUploads);

      if (numberOfUploads < MAXIMUM_PARALLEL_UPLOADS) {
        yield put(
          Actions.Creators.setUploadAssetStatus(uuid, UploadStatus.UPLOADING)
        );

        return true;
      }
    } finally {
      yield call(releaseLock);
    }

    // wait here until it is not turn of this asset upload
    // or the asset upload is cancelled
    // or another upload of the same asset has been enqueued after this upload request
    while (true) {
      yield take(Actions.Types.SET_UPLOAD_ASSET_STATUS);
      const uploadStates = yield select(getUploadStates);
      const uploadState = uploadStates[uuid];
      if (
        // already cancelled
        !uploadState ||
        uploadState.status !== UploadStatus.PENDING ||
        // another more recent request of upload of the same asset have been created
        uploadState.uploadId !== uploadId
      ) {
        return false;
      } else {
        const nextUpload = yield select(getNextUpload);

        if (nextUpload.asset.uuid === uuid) {
          break;
        }
      }
    }
  }
}

export function* uploadAsset(uuid, file) {
  // uploadId enables to distinguish between multiple uploads of same asset
  // at the time
  const uploadId = uniqueId();

  // spawn, not fork to ensure the uploadAssetImpl saga doesn't get cancelled
  yield spawn(uploadAssetImpl, uuid, file, uploadId);

  const { uploadResult } = yield take(
    (action) =>
      action.type === Actions.Types.UPLOAD_RESULT &&
      action.uploadId === uploadId
  );

  return uploadResult;
}

const uploads = {};
function* uploadAssetImpl(uuid, file, uploadId) {
  const inProgressUpload = uploads[uuid];
  let uploadResult = UploadResults.FAILED;

  try {
    if (inProgressUpload) {
      yield call(inProgressUpload.abort);
      // give other sagas time to process the abort event and also show user
      // briefly cancelled state of the previous upload
      yield delay(3000);
    }

    yield put(Actions.Creators.enqueueUploadAsset(uuid, file.size, uploadId));

    const canUpload = yield call(waitForUploadSlot, uuid, uploadId);

    if (!canUpload) {
      uploadResult = UploadStatus.CANCELLED;
    } else {
      // yield spawn(notifyAssetUploadStart, uuid);

      const assets = yield select(getAssets);
      const asset = assets[uuid];

      if (!asset) {
        throw Error(`Asset uuid=${uuid} not found in entity repository.`);
      }

      let apiUpload;

      if (RegularAssetTypes.includes(asset.type)) {
        apiUpload = Api.controlledUploadAsset;
      } else if (WallTypes.includes(asset.type)) {
        apiUpload = Api.controlledUploadWall;
      } else {
        throw Error(
          `Asset type=${asset.type} is not supported for file upload.`
        );
      }

      const controlledUpload = yield call(apiUpload, uuid, file);
      // eslint-disable-next-line require-atomic-updates
      uploads[uuid] = controlledUpload;
      logger.info("upload asset start", { uuid });

      let done = false;
      while (!done) {
        const message = yield take(controlledUpload.channel);

        if (message.progress) {
          yield put(
            Actions.Creators.setUploadAssetProgress(uuid, message.progress)
          );
        } else if (message.err) {
          logger.info("upload asset error", uuid, message.err);
        } else if (message.success) {
          uploadResult = UploadResults.SUCCESS;
          logger.info("upload asset success", {
            uuid,
            success: message.success
          });
          yield call(normalizeAndStore, message.responseData, assetSchema);
        } else if (message.canceled) {
          uploadResult = UploadResults.CANCELLED;
          logger.info("upload asset canceled", { uuid });
        } else if (message.done) {
          done = true;
          logger.info("upload asset done", { uuid });
        }
      }
    }
  } catch (ex) {
    logger.warn("upload asset exception", { uuid, ex });
    throw ex;
  } finally {
    delete uploads[uuid];
    yield spawn(onUploadEnd, uuid, uploadResult, uploadId);

    switch (uploadResult) {
      case UploadStatus.SUCCESS:
        // NOOP
        break;
      case UploadStatus.CANCELLED:
        // NOOP
        break;
      case UploadStatus.FAILED:
      default:
        yield spawn(notifyAssetUploadFail, uuid);
        break;
    }

    yield put(Actions.Creators.uploadResult(uploadId, uploadResult));
  }
}

function* cancelAssetUpload({ uuid }) {
  const uploadStates = yield select(getUploadStates);
  const uploadState = uploadStates[uuid];

  if (
    uploadState &&
    (uploadState.status === UploadStatus.PENDING ||
      uploadState.status === UploadStatus.UPLOADING)
  ) {
    if (uploads[uuid]) {
      // cancelled when in progress
      yield call(uploads[uuid].abort);
    } else {
      // cancelled before it even started
      yield call(onUploadEnd, uuid, UploadStatus.CANCELLED);
    }
  }
}

function* onRemoteAssetUpdate({ uuid }) {
  try {
    const assets = yield select(getAssets);
    const asset = assets[uuid];
    let apiGet;

    if (asset) {
      const rootAsset = assets[asset.parentUuid] || asset;

      if (RegularAssetTypes.includes(rootAsset.type)) {
        apiGet = Api.getAsset;
      } else if (WallTypes.includes(rootAsset.type)) {
        apiGet = Api.getWall;
      }

      const updatedAsset = yield call(apiGet, rootAsset.uuid);
      yield call(normalizeAndStore, updatedAsset, assetSchema);
      yield put(Actions.Creators.assetRemotelyUpdated(rootAsset.uuid));
    }
  } catch (e) {
    // exception can happen if the updated asset is not accessible by the current user
    // (the asset is owned by another user)
    logger.info("Update asset status error", e);
  }
}

function* onAddAsset() {
  const updated = yield call(openUploadDialog);

  if (updated) {
    yield put(Actions.Creators.incrementLoadingKey());
  }
}

function* onEditAsset({ uuid }) {
  yield call(openEditDialog, uuid);
}

function* onAssetsFetched({ assets }) {
  yield call(normalizeAndStore, assets, assetsSchema);
}

function* onDeleteAsset({ uuid }) {
  const assets = yield select(getAssets);
  const asset = assets[uuid];
  let deleteApi;

  if (!asset) {
    return;
  }

  const isAlternativeAsset = !!asset.parentUuid;
  const rootAsset = assets[asset.parentUuid] || asset;
  const assetTypeGroupName = getHumanReadableAssetTypeGroupName(rootAsset.type);

  try {
    let confirmCountMessage = "";

    if (WallTypes.includes(rootAsset.type)) {
      deleteApi = Api.deleteWall;
    } else {
      deleteApi = Api.deleteAsset;

      const { count } = yield call(Api.getAssetRelatedScenes, uuid);

      confirmCountMessage =
        count > 0
          ? ` The ${assetTypeGroupName.toLowerCase()} is used in ${count} scene${
              count > 1 ? "s" : ""
            }.`
          : "";
    }

    const { type } = yield call(confirmDialogSaga, {
      title: `Delete ${
        isAlternativeAsset ? "Alternative " : ""
      }${assetTypeGroupName}${isAlternativeAsset ? " File" : ""}`,
      text: `Are you sure you want to delete${
        isAlternativeAsset ? " alternative file of" : ""
      } ${assetTypeGroupName.toLowerCase()} "${
        rootAsset.title
      }"?${confirmCountMessage}`,
      subtext: "",
      confirmLabel: "Delete",
      cancelLabel: "Cancel"
    });

    if (type === DialogResult.CONFIRM) {
      yield put(Actions.Creators.cancelAssetUpload(uuid));
      yield all(
        rootAsset.alternativeAssets.map((alternativeAsset) =>
          put(Actions.Creators.cancelAssetUpload(alternativeAsset.uuid))
        )
      );

      yield call(deleteApi, uuid, asset.shared);
      yield fork(
        notifySuccess,
        assetTypeGroupName + " has been successfully deleted."
      );
      yield put(Actions.Creators.assetDeleted(uuid));
      yield put(Actions.Creators.incrementLoadingKey());
    }
  } catch (ex) {
    logger.warn("Asset delete error", ex);
    yield fork(
      notifyError,
      `Request to delete ${assetTypeGroupName.toLowerCase()} has failed`
    );
  }
}

function* onWallsList() {
  const instances = yield call(Api.getInstanceConfigs);
  yield call(normalizeAndStore, instances, instancesSchema);
}

function* onMediaChange({ data }) {
  const dialogType = yield select(getDialogType);

  //Could not be refactored totally out.
  const alternativeBackgroundsEnabled = yield select(
    enableAlternativeBackgroundAssets
  );

  if (dialogType === MediaDialogType.WALL_ASSET) {
    if (data.files[data.files.length - 1].file !== null) {
      const files = alternativeBackgroundsEnabled
        ? [...data.files, { file: null, uuid: null }]
        : [...data.files];
      yield put(change("edit-media", "files", files));
    }
  }
}

function* previewAsset({ uuid }) {
  if (!uuid) {
    // closes currently opened modal
    yield put(Actions.Creators.openPreviewDialog(null));
    return;
  }

  const cdnHost = yield select(getCdnHost);
  const assets = yield select(getAssets);
  const asset = assets[uuid];

  if (!asset) {
    return;
  }

  const { roles } = yield select(getUser);
  const routeName = yield select(getRouteName);
  const { libraryTab, tab } = yield select(getRouteParams);

  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackAssetPreviewed(
      routeName,
      asset.type,
      roles,
      libraryTab || tab
    )
  );

  switch (asset.type) {
    case AssetType.ASSET_IMAGE:
    case AssetType.ASSET_SOUND:
    case AssetType.ASSET_VIDEO:
    case AssetType.ASSET_WALL_IMAGE:
    case AssetType.ASSET_WALL_VIDEO:
      yield put(Actions.Creators.openPreviewDialog(uuid));
      break;

    case AssetType.ASSET_URL:
      window.open(asset.metadata.url, null, "noreferrer=yes,noopener=yes");
      break;

    case AssetType.ASSET_PRESENTATION:
    case AssetType.ASSET_PDF:
    default:
      window.open(
        getAssetFile(asset, cdnHost),
        null,
        "noreferrer=yes,noopener=yes"
      );
  }
}

function* changeSharedAsset({ formData, categories }, fileToUpload) {
  if (fileToUpload) {
    yield fork(uploadAsset, formData.uuid, fileToUpload);
  }

  const customFieldToEntityMappings = yield select(
    getCustomFieldToEntityMappings
  );

  // Filter out all the invalid custom fields
  const formErrors = yield select(getFormSyncErrors(editSharedAssetForm));
  const formFieldErrors = Object.keys(formErrors);
  const validFormData = Object.keys(formData).reduce((memo, key) => {
    if (!formFieldErrors.includes(key)) {
      memo[key] = formData[key];
    }

    return memo;
  }, {});

  const customFields = entityFormToCustomFields(
    validFormData,
    customFieldToEntityMappings
  );

  yield put(Actions.Creators.lastEditedSharedAsset(null));

  const asset = yield call(
    Api.updateAsset,
    validFormData.uuid,
    validFormData.title,
    validFormData.title,
    {},
    customFields.filter((customField) => customField.value !== null),
    categories || [],
    true
  );

  yield put(Actions.Creators.lastEditedSharedAsset(formData.uuid));

  return asset;
}

function* sharedAssetChangesDebounced() {
  let action, updatingTask, lastFile;

  while (true) {
    const raceConfig = {
      debounced: delay(1500),
      sharedAssetChanged: take(Actions.Types.SHARED_ASSET_CHANGED)
    };

    if (updatingTask) {
      raceConfig.updatingTaskResult = join(updatingTask);
    }

    const { debounced, sharedAssetChanged, updatingTaskResult } = yield race(
      raceConfig
    );

    if (debounced) {
      if (action) {
        const file = action.formData.files[0];
        let fileToUpload = null;
        if (lastFile === null || lastFile !== file) {
          if (!file.rejected && file.file instanceof File) {
            fileToUpload = file.file;
          }
        }
        lastFile = file;

        updatingTask = yield fork(changeSharedAsset, action, fileToUpload);
        action = null;
      }
    } else if (sharedAssetChanged) {
      action = sharedAssetChanged;
    } else if (updatingTaskResult) {
      updatingTask = null;

      delete updatingTaskResult.fileVersion;
      delete updatingTaskResult.status;
      yield call(normalizeAndStore, updatingTaskResult, assetSchema);
    }
  }
}
export function* mediaSaga() {
  yield takeEveryWithCheckProgress(
    Actions.Types.CANCEL_ASSET_UPLOAD,
    cancelAssetUpload,
    ({ uuid }) => uuid
  );
  yield takeEvery(Actions.Types.ADD_ASSET, onAddAsset);
  yield takeEvery(Actions.Types.EDIT_ASSET, onEditAsset);
  yield takeEvery(Actions.Types.EDIT_SHARED_ASSET, openEditSharedAssetDialog);
  yield takeEveryWithCheckProgress(
    Actions.Types.DELETE_ASSET,
    onDeleteAsset,
    ({ uuid }) => uuid
  );
  yield fork(sharedAssetChangesDebounced);
  yield takeEvery(Actions.Types.UPLOAD_FILES, uploadingSaga);
  yield takeEvery(Actions.Types.ASSETS_FETCHED, onAssetsFetched);
  yield takeEvery(Actions.Types.MEDIA_CHANGE, onMediaChange);
  yield takeEveryWithCheckProgress(
    Actions.Types.SAVE_SELECTED_ASSET,
    onSaveSelectedAsset,
    getSelectedAssetId
  );
  yield takeEveryWithCheckProgress(
    Actions.Types.DELETE_SELECTED_ASSET,
    onDeleteSelectedAsset,
    getSelectedAssetId
  );
  yield takeEveryWithCheckProgress(
    Actions.Types.SAVE_MEDIA,
    onSaveMedia,
    ({ data: { uuid } }) => uuid
  );
  yield takeEvery(Actions.Types.CLOSE_EDITING, onCloseEditing);
  yield takeEveryWithCheckProgress(
    Actions.Types.CREATE_URL_ASSET,
    onCreateUrlAsset
  );

  yield fork(onRouteEntered, "assets.list.edit-media", onEditMedia);
  yield fork(onRouteEntered, "walls.list.edit-media", onEditMedia);
  yield fork(onRouteEntered, WALLS_LIST_ROUTE, onWallsList);

  const socket = yield call(Websockets.connect);

  const assetStatusChannel = yield call(
    Websockets.createMessageChannel,
    socket,
    "asset.status"
  );

  yield takeEvery(assetStatusChannel, onRemoteAssetUpdate);

  yield takeEveryWithCheckProgress(
    Actions.Types.PREVIEW_ASSET,
    previewAsset,
    ({ uuid }) => uuid
  );
}
