import React, { useCallback, useMemo } from "react";
import { reduxForm, submit } from "redux-form";
import { connect } from "react-redux";

import Actions from "../presentation-actions";
import validate from "../../scenes/components/scene-validate";
import { SceneForm } from "../../scenes/components/scene-form";
import {
  getCreateSceneInitialValues,
  getPresentation
} from "../presentation-selectors";
import { getBranding } from "../../config/config-selectors";
import { Modal } from "../../ux/modal";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "scene-presentation-create";
const SceneFormCreate = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(SceneForm);

const mapDispatchToProps = {
  submitForm: () => submit(FORM_NAME),
  createScene: Actions.Creators.createScene
};

const mapStateToProps = (state) => ({
  initialValues: getCreateSceneInitialValues(state),
  roomName: getBranding(state).roomName,
  presentation: getPresentation(state)
});

export const PresentationEditCreateScene = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ submitForm, createScene, initialValues, roomName, presentation }) => {
  const closeModal = useCallback(() => {
    window.history.back();
  }, []);

  useEscapeHook(true, closeModal);

  const presentationInstanceCount = useMemo(
    () => (presentation ? presentation.instances.length : 0),
    [presentation]
  );

  return (
    <Modal
      title="New Scene"
      className="modalBoxSm"
      onCloseClick={closeModal}
      closeButtonProps={{ "data-qa": "close-modal-btn" }}
      data-qa={MODAL_LOCATORS.getModal("new-scene")}
      footer={
        <button
          className="button"
          onClick={submitForm}
          data-qa={MODAL_LOCATORS.buttons.save}
        >
          Save
        </button>
      }
    >
      <SceneFormCreate
        initialValues={initialValues}
        validate={validate(roomName)}
        presentationId={presentation && presentation.id}
        presentationInstanceCount={presentationInstanceCount}
        hideCollaborators={true}
        onSubmit={createScene}
      />
    </Modal>
  );
});
