import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getUser, getUserAvatar } from "../../auth/auth-selectors";
import { getMainRole } from "../../utils/roles";
import { getRoleLabelFactory } from "../../config/config-selectors";
import { Avatar } from "../../settings/users/components/avatar";

const mapStateToProps = (state) => {
  const user = getUser(state);

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    country: user.country,
    role: getMainRole(user),
    getRoleLabel: getRoleLabelFactory(state),
    avatar: getUserAvatar(state)
  };
};

const DashboardTileProfileImpl = ({
  firstName,
  lastName,
  email,
  phone,
  country,
  role,
  getRoleLabel,
  avatar
}) => (
  <div className="card" data-qa="dashboard-profile">
    <h2 className="title" data-qa="dashboard-profile-title">
      Profile
    </h2>
    <div className="avatarBox" data-qa="dashboard-profile-avatar">
      <Avatar avatar={avatar} firstName={firstName} lastName={lastName} />
      <div className="info" data-qa="dashboard-profile-info">
        <p className="name" data-qa="dashboard-profile-name">
          {firstName} {lastName}
        </p>
        <p className="role" data-qa="dashboard-profile-role">
          {getRoleLabel(role)}
        </p>
      </div>
    </div>
    <div className="itemList">
      <div className="item">
        <span className="label">E-mail</span>
        <span data-qa="dashboard-profile-email">{email}</span>
      </div>
      {country && country.trim() !== "" && (
        <div className="item">
          <span className="label">Country</span>
          <span data-qa="dashboard-profile-country">{country}</span>
        </div>
      )}
      {phone && phone.trim() !== "" && (
        <div className="item">
          <span className="label">Phone</span>
          <span data-qa="dashboard-profile-phone">{phone}</span>
        </div>
      )}
    </div>
  </div>
);

DashboardTileProfileImpl.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  country: PropTypes.string,
  role: PropTypes.string.isRequired,
  getRoleLabel: PropTypes.func.isRequired,
  avatar: PropTypes.string
};

export const DashboardTileProfile = connect(mapStateToProps)(
  DashboardTileProfileImpl
);
