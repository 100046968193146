import React, { useCallback, useState } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import { passwordNormalizer, UsersForm, UsersFormData } from "./users-form";
import Actions from "../../users-actions";
import validate from "../../users-validate";
import * as Selectors from "../../users-selectors";
import { RootState } from "../../../../root/root-reducer";
import { UserRoleName } from "../../../../../../shared/types/user-role-name";
import {
  useConfirmationWithInputDialog,
  useConfirmationDialog,
  useEscapeHook
} from "../../../../dialogs/dialog-hooks";
import {
  getRouteParams,
  RouteParamSelector
} from "../../../../routing/routing-selectors";
import { DialogResult } from "../../../../dialogs/event-types";
import { useNavigateTo } from "../../../../routing/routing-hooks";
import { DASHBOARD_ROUTE, USERS_ROUTE } from "../../../../routing/route-names";
import { createPortal } from "react-dom";
import { ModalTemplate } from "../../../../ux/modal";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "users-edit";

const emailValueSelector = (state: RootState) =>
  formValueSelector(FORM_NAME)(state, "email");

const UsersEditForm = reduxForm<UsersFormData, {}>({
  form: FORM_NAME,
  enableReinitialize: true,
  validate
})(({ handleSubmit, initialValues }) => {
  const [modalHidden, setModalHidden] = useState(false);
  const dispatch = useDispatch();

  const confirm = useConfirmationWithInputDialog();
  const { navigateTo, routeParams } = useNavigateTo();

  const email = useSelector(emailValueSelector);

  const { id } = useSelector(
    getRouteParams as RouteParamSelector<{ id: string }>
  );

  const onBack = useCallback(() => {
    navigateTo(USERS_ROUTE, { ...routeParams });
  }, [navigateTo, routeParams]);

  const editUser = useCallback(
    async (values: UsersFormData) => {
      const isNewGuestDomainAdminAssigned =
        initialValues.roles &&
        !initialValues.roles.includes(UserRoleName.GUEST_DOMAIN_SUPER_ADMIN) &&
        values.roles.includes(UserRoleName.GUEST_DOMAIN_SUPER_ADMIN);

      if (isNewGuestDomainAdminAssigned) {
        setModalHidden(true);
        const answer = await confirm({
          cancelLabel: "Cancel",
          confirmLabel: "Yes",
          subtext: "",
          text:
            "Are you sure you want to change the admin role for this domain? Your current admin role will be changed to a user role.",
          title: "Change admin",
          fieldLabel: 'Type "CHANGE ADMIN" to proceed',
          confirmText: "CHANGE ADMIN"
        });
        setModalHidden(false);
        if (answer.type === DialogResult.CONFIRM) {
          dispatch(Actions.Creators.editUser(passwordNormalizer(values)));
          navigateTo(DASHBOARD_ROUTE, {});
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
      } else {
        dispatch(Actions.Creators.editUser(passwordNormalizer(values)));
      }
    },
    [dispatch, confirm, initialValues.roles, navigateTo]
  );

  const confirmEmail = useConfirmationDialog();

  const onSendConfirmationEmail = useCallback(async () => {
    setModalHidden(true);
    const result = await confirmEmail({
      cancelLabel: "Cancel",
      confirmLabel: "Send",
      subtext: `Are you sure you want to resend confirmation email?`,
      text: "",
      title: `Resend confirmation email`
    });
    setModalHidden(false);
    if (result.type === DialogResult.CONFIRM) {
      dispatch(Actions.Creators.sendConfirmationEmail(id));
    }
  }, [dispatch, id, confirmEmail]);

  useEscapeHook(id !== undefined, onBack);

  const ssoUser = initialValues.ssoProvider !== null;

  return (
    <form
      onSubmit={handleSubmit(editUser)}
      className="editUserForm"
      style={{ display: modalHidden ? "none" : "block" }}
    >
      {/*
      // @ts-ignore */}
      <ModalTemplate
        title="Edit user"
        onCloseClick={onBack}
        footer={
          <>
            {!ssoUser && (
              <button
                className="button buttonOutlined left"
                type="button"
                onClick={onSendConfirmationEmail}
              >
                Send Confirmation Email Again
              </button>
            )}
            <button
              className="button"
              type="submit"
              data-qa={MODAL_LOCATORS.buttons.submit}
            >
              Edit user
            </button>
          </>
        }
      >
        <UsersForm email={email} editableEmail={false} ssoUser={ssoUser} />
      </ModalTemplate>
    </form>
  );
});

export const UsersEdit = () => {
  const initialValues = useSelector(Selectors.getUserEditInitialValues);

  return createPortal(
    <UsersEditForm initialValues={initialValues} />,
    document.getElementById("modal") as Element
  );
};
