interface Filter {
  id: number | string;
}

interface CustomFieldHierarchy {
  path: string;
  customField: {
    id: number;
  };
}

export const getFilterConfigForSelectedCategory = (
  customFieldHierarchies: CustomFieldHierarchy[],
  filterConfig: Filter[],
  category: string
) =>
  filterConfig.filter((filter) => {
    // If filter is type we always show it
    if (filter.id === "type") {
      return true;
    }

    // All the other filters will be hidden
    // if category is not selected (we don't show any filters for root)
    if (!category) {
      return false;
    }

    // Find all hiearchies for the filter
    const allHierarchiesForCustomField = customFieldHierarchies.filter(
      (hierarchy) => hierarchy.customField.id === filter.id
    );

    // There must be some hiearchy which is within selected category
    return allHierarchiesForCustomField.some((hierarchy) =>
      hierarchy.path.startsWith(`${category}/`)
    );
  });
