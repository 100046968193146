import { createReducer } from "reduxsauce";

import Actions from "./shares-actions";

const INITIAL_STATE = {
  presentations: {
    shared: 0,
    coop: 0
  },
  scenes: {
    shared: 0,
    coop: 0
  }
};

const onStatsFetched = (state, { stats }) => stats;

const resetPresentationCollaborate = (state) => ({
  ...state,
  presentations: {
    ...state.presentations,
    coop: 0
  }
});

const resetPresentationShared = (state) => ({
  ...state,
  presentations: {
    ...state.presentations,
    shared: 0
  }
});

const resetSceneCollaborate = (state) => ({
  ...state,
  scenes: {
    ...state.scenes,
    coop: 0
  }
});

const resetSceneShared = (state) => ({
  ...state,
  scenes: {
    ...state.scenes,
    shared: 0
  }
});

export default createReducer(INITIAL_STATE, {
  [Actions.Types.STATS_FETCHED]: onStatsFetched,
  [Actions.Types.RESET_PRESENTATION_COLLABORATE]: resetPresentationCollaborate,
  [Actions.Types.RESET_PRESENTATION_SHARED]: resetPresentationShared,
  [Actions.Types.RESET_SCENE_COLLABORATE]: resetSceneCollaborate,
  [Actions.Types.RESET_SCENE_SHARED]: resetSceneShared
});
