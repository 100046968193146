import React from "react";
import { createPortal } from "react-dom";
import { reduxForm, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";

import {
  CustomFieldFormData,
  CustomFieldsForm,
  CustomFieldsFormProps
} from "./custom-fields-form";
import { getRouteName } from "../../../../routing/routing-selectors";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";
import { CustomFieldChoice } from "../../../../../../server/models/custom-field-choice";
import { RootState } from "../../../../root/root-reducer";
import { useCreateFilter } from "../../hooks/use-filters-crud";
import { useGetCustomFieldType } from "../../hooks/use-get-custom-field-type";

const FORM_NAME = "custom-field-add";

const validate = (values: CustomFieldFormData) => {
  const errors: Partial<Record<keyof CustomFieldFormData, string>> = {};
  if (!values.label) {
    errors.label = `Value is required`;
  }
  return errors;
};

const CustomFieldsCreateForm = reduxForm<
  CustomFieldFormData,
  CustomFieldsFormProps
>({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  initialValues: {
    label: "",
    required: true,
    filterable: true,
    type: CustomFieldType.SINGLE_CHOICE,
    helpers: {
      addChoiceInput: ""
    }
  }
})(CustomFieldsForm);

export const CustomFieldsCreate = () => {
  const createCustomField = useCreateFilter();
  const assetType = useGetCustomFieldType();

  const selectedType = useSelector((state: RootState) =>
    formValueSelector(FORM_NAME)(state, "type")
  ) as CustomFieldType;
  const helpers = useSelector((state: RootState) =>
    formValueSelector(FORM_NAME)(state, "helpers")
  ) as {
    addChoiceInput: string;
  };
  const choices = useSelector((state: RootState) =>
    formValueSelector(FORM_NAME)(state, "choices")
  ) as CustomFieldChoice[];
  const route = useSelector(getRouteName) as string;

  return createPortal(
    <CustomFieldsCreateForm
      onSubmit={createCustomField}
      assetType={assetType}
      allowTypeEditing
      selectedType={selectedType}
      addChoiceInput={helpers && helpers.addChoiceInput}
      route={route}
      choices={choices}
    />,
    document.getElementById("modal") as Element
  );
};
