import { ModalButtonType, ModalType } from "../types/modal.types";

export const MODAL_LOCATORS = {
  header: "modal-header",
  footer: "modal-footer",
  content: "modal-content",
  buttons: {
    close: "modal-btn-close",
    save: "modal-btn-save",
    clone: "modal-btn-clone",
    cancel: "modal-btn-cancel",
    confirm: "modal-btn-confirm",
    submit: "modal-btn-submit",
    delete: "modal-btn-delete",
    getButton: (button: ModalButtonType): string => `modal-btn-${button}`
  },

  getModal: (modal: ModalType): string => `modal-${modal}`
};
