export default [
  {
    country_code: "AF",
    country_name: "Afghanistan",
    dialling_code: 93,
    index: 0
  },
  {
    country_code: "AL",
    country_name: "Albania",
    dialling_code: 355,
    index: 1
  },
  {
    country_code: "DZ",
    country_name: "Algeria",
    dialling_code: 213,
    index: 2
  },
  {
    country_code: "AS",
    country_name: "American Samoa",
    dialling_code: 1,
    index: 3
  },
  {
    country_code: "AD",
    country_name: "Andorra",
    dialling_code: 376,
    index: 4
  },
  {
    country_code: "AO",
    country_name: "Angola",
    dialling_code: 244,
    index: 5
  },
  {
    country_code: "AI",
    country_name: "Anguilla",
    dialling_code: 1,
    index: 6
  },
  {
    country_code: "AG",
    country_name: "Antigua",
    dialling_code: 1,
    index: 7
  },
  {
    country_code: "AR",
    country_name: "Argentina",
    dialling_code: 54,
    index: 8
  },
  {
    country_code: "AM",
    country_name: "Armenia",
    dialling_code: 374,
    index: 9
  },
  {
    country_code: "AW",
    country_name: "Aruba",
    dialling_code: 297,
    index: 10
  },
  {
    country_code: "AU",
    country_name: "Australia",
    dialling_code: 61,
    index: 11
  },
  {
    country_code: "AI",
    country_name: "Austria",
    dialling_code: 43,
    index: 12
  },
  {
    country_code: "AZ",
    country_name: "Azerbaijan",
    dialling_code: 994,
    index: 13
  },
  {
    country_code: "BH",
    country_name: "Bahrain",
    dialling_code: 973,
    index: 14
  },
  {
    country_code: "BD",
    country_name: "Bangladesh",
    dialling_code: 880,
    index: 15
  },
  {
    country_code: "BB",
    country_name: "Barbados",
    dialling_code: 1,
    index: 16
  },
  {
    country_code: "BY",
    country_name: "Belarus",
    dialling_code: 375,
    index: 17
  },
  {
    country_code: "BE",
    country_name: "Belgium",
    dialling_code: 32,
    index: 18
  },
  {
    country_code: "BZ",
    country_name: "Belize",
    dialling_code: 501,
    index: 19
  },
  {
    country_code: "BJ",
    country_name: "Benin",
    dialling_code: 229,
    index: 20
  },
  {
    country_code: "BM",
    country_name: "Bermuda",
    dialling_code: 1,
    index: 21
  },
  {
    country_code: "BT",
    country_name: "Bhutan",
    dialling_code: 975,
    index: 22
  },
  {
    country_code: "BO",
    country_name: "Bolivia",
    dialling_code: 591,
    index: 23
  },
  {
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    dialling_code: 387,
    index: 24
  },
  {
    country_code: "BW",
    country_name: "Botswana",
    dialling_code: 267,
    index: 25
  },
  {
    country_code: "BR",
    country_name: "Brazil",
    dialling_code: 55,
    index: 26
  },
  {
    country_code: "IO",
    country_name: "British Indian Ocean Territory",
    dialling_code: 246,
    index: 27
  },
  {
    country_code: "VG",
    country_name: "British Virgin Islands",
    dialling_code: 1,
    index: 28
  },
  {
    country_code: "BN",
    country_name: "Brunei",
    dialling_code: 673,
    index: 29
  },
  {
    country_code: "BG",
    country_name: "Bulgaria",
    dialling_code: 359,
    index: 30
  },
  {
    country_code: "BF",
    country_name: "Burkina Faso",
    dialling_code: 226,
    index: 31
  },
  {
    country_code: "MM",
    country_name: "Burma Myanmar",
    dialling_code: 95,
    index: 32
  },
  {
    country_code: "BI",
    country_name: "Burundi",
    dialling_code: 257,
    index: 33
  },
  {
    country_code: "KH",
    country_name: "Cambodia",
    dialling_code: 855,
    index: 34
  },
  {
    country_code: "CM",
    country_name: "Cameroon",
    dialling_code: 237,
    index: 35
  },
  {
    country_code: "CA",
    country_name: "Canada",
    dialling_code: 1,
    index: 36
  },
  {
    country_code: "CV",
    country_name: "Cape Verde",
    dialling_code: 238,
    index: 37
  },
  {
    country_code: "KY",
    country_name: "Cayman Islands",
    dialling_code: 1,
    index: 38
  },
  {
    country_code: "CF",
    country_name: "Central African Republic",
    dialling_code: 236,
    index: 39
  },
  {
    country_code: "ID",
    country_name: "Chad",
    dialling_code: 235,
    index: 40
  },
  {
    country_code: "CL",
    country_name: "Chile",
    dialling_code: 56,
    index: 41
  },
  {
    country_code: "CN",
    country_name: "China",
    dialling_code: 86,
    index: 42
  },
  {
    country_code: "CO",
    country_name: "Colombia",
    dialling_code: 57,
    index: 43
  },
  {
    country_code: "KM",
    country_name: "Comoros",
    dialling_code: 269,
    index: 44
  },
  {
    country_code: "CK",
    country_name: "Cook Islands",
    dialling_code: 682,
    index: 45
  },
  {
    country_code: "CR",
    country_name: "Costa Rica",
    dialling_code: 506,
    index: 46
  },
  {
    country_code: "CI",
    country_name: "Cote d'Ivoire",
    dialling_code: 225,
    index: 47
  },
  {
    country_code: "HR",
    country_name: "Croatia",
    dialling_code: 385,
    index: 48
  },
  {
    country_code: "CU",
    country_name: "Cuba",
    dialling_code: 53,
    index: 49
  },
  {
    country_code: "CY",
    country_name: "Cyprus",
    dialling_code: 357,
    index: 50
  },
  {
    country_code: "CZ",
    country_name: "Czech Republic",
    dialling_code: 420,
    index: 51
  },
  {
    country_code: "CD",
    country_name: "Democratic Republic of Congo",
    dialling_code: 243,
    index: 52
  },
  {
    country_code: "DK",
    country_name: "Denmark",
    dialling_code: 45,
    index: 53
  },
  {
    country_code: "DJ",
    country_name: "Djibouti",
    dialling_code: 253,
    index: 54
  },
  {
    country_code: "DM",
    country_name: "Dominica",
    dialling_code: 1,
    index: 55
  },
  {
    country_code: "DO",
    country_name: "Dominican Republic",
    dialling_code: 1,
    index: 56
  },
  {
    country_code: "EC",
    country_name: "Ecuador",
    dialling_code: 593,
    index: 57
  },
  {
    country_code: "EG",
    country_name: "Egypt",
    dialling_code: 20,
    index: 58
  },
  {
    country_code: "SV",
    country_name: "El Salvador",
    dialling_code: 503,
    index: 59
  },
  {
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    dialling_code: 240,
    index: 60
  },
  {
    country_code: "ER",
    country_name: "Eritrea",
    dialling_code: 291,
    index: 61
  },
  {
    country_code: "EE",
    country_name: "Estonia",
    dialling_code: 372,
    index: 62
  },
  {
    country_code: "ET",
    country_name: "Ethiopia",
    dialling_code: 251,
    index: 63
  },
  {
    country_code: "FK",
    country_name: "Falkland Islands",
    dialling_code: 500,
    index: 64
  },
  {
    country_code: "FO",
    country_name: "Faroe Islands",
    dialling_code: 298,
    index: 65
  },
  {
    country_code: "FM",
    country_name: "Federated States of Micronesia",
    dialling_code: 691,
    index: 66
  },
  {
    country_code: "FJ",
    country_name: "Fiji",
    dialling_code: 679,
    index: 67
  },
  {
    country_code: "FI",
    country_name: "Finland",
    dialling_code: 358,
    index: 68
  },
  {
    country_code: "FR",
    country_name: "France",
    dialling_code: 33,
    index: 69
  },
  {
    country_code: "GF",
    country_name: "French Guiana",
    dialling_code: 594,
    index: 70
  },
  {
    country_code: "PF",
    country_name: "French Polynesia",
    dialling_code: 689,
    index: 71
  },
  {
    country_code: "GA",
    country_name: "Gabon",
    dialling_code: 241,
    index: 72
  },
  {
    country_code: "GE",
    country_name: "Georgia",
    dialling_code: 995,
    index: 73
  },
  {
    country_code: "DE",
    country_name: "Germany",
    dialling_code: 49,
    index: 74
  },
  {
    country_code: "GH",
    country_name: "Ghana",
    dialling_code: 233,
    index: 75
  },
  {
    country_code: "GI",
    country_name: "Gibraltar",
    dialling_code: 350,
    index: 76
  },
  {
    country_code: "GR",
    country_name: "Greece",
    dialling_code: 30,
    index: 77
  },
  {
    country_code: "GL",
    country_name: "Greenland",
    dialling_code: 299,
    index: 78
  },
  {
    country_code: "GD",
    country_name: "Grenada",
    dialling_code: 1,
    index: 79
  },
  {
    country_code: "GP",
    country_name: "Guadeloupe",
    dialling_code: 590,
    index: 80
  },
  {
    country_code: "GU",
    country_name: "Guam",
    dialling_code: 1,
    index: 81
  },
  {
    country_code: "GT",
    country_name: "Guatemala",
    dialling_code: 502,
    index: 82
  },
  {
    country_code: "GN",
    country_name: "Guinea",
    dialling_code: 224,
    index: 83
  },
  {
    country_code: "GW",
    country_name: "Guinea-Bissau",
    dialling_code: 245,
    index: 84
  },
  {
    country_code: "GY",
    country_name: "Guyana",
    dialling_code: 592,
    index: 85
  },
  {
    country_code: "HT",
    country_name: "Haiti",
    dialling_code: 509,
    index: 86
  },
  {
    country_code: "HN",
    country_name: "Honduras",
    dialling_code: 504,
    index: 87
  },
  {
    country_code: "HK",
    country_name: "Hong Kong",
    dialling_code: 852,
    index: 88
  },
  {
    country_code: "HU",
    country_name: "Hungary",
    dialling_code: 36,
    index: 89
  },
  {
    country_code: "IS",
    country_name: "Iceland",
    dialling_code: 354,
    index: 90
  },
  {
    country_code: "IN",
    country_name: "India",
    dialling_code: 91,
    index: 91
  },
  {
    country_code: "ID",
    country_name: "Indonesia",
    dialling_code: 62,
    index: 92
  },
  {
    country_code: "IR",
    country_name: "Iran",
    dialling_code: 98,
    index: 93
  },
  {
    country_code: "IQ",
    country_name: "Iraq",
    dialling_code: 964,
    index: 94
  },
  {
    country_code: "IE",
    country_name: "Ireland",
    dialling_code: 353,
    index: 95
  },
  {
    country_code: "IL",
    country_name: "Israel",
    dialling_code: 972,
    index: 96
  },
  {
    country_code: "IT",
    country_name: "Italy",
    dialling_code: 39,
    index: 97
  },
  {
    country_code: "JM",
    country_name: "Jamaica",
    dialling_code: 1,
    index: 98
  },
  {
    country_code: "JP",
    country_name: "Japan",
    dialling_code: 81,
    index: 99
  },
  {
    country_code: "JO",
    country_name: "Jordan",
    dialling_code: 962,
    index: 100
  },
  {
    country_code: "KZ",
    country_name: "Kazakhstan",
    dialling_code: 7,
    index: 101
  },
  {
    country_code: "KE",
    country_name: "Kenya",
    dialling_code: 254,
    index: 102
  },
  {
    country_code: "KI",
    country_name: "Kiribati",
    dialling_code: 686,
    index: 103
  },
  {
    country_code: "XK",
    country_name: "Kosovo",
    dialling_code: 381,
    index: 104
  },
  {
    country_code: "KW",
    country_name: "Kuwait",
    dialling_code: 965,
    index: 105
  },
  {
    country_code: "KG",
    country_name: "Kyrgyzstan",
    dialling_code: 996,
    index: 106
  },
  {
    country_code: "LA",
    country_name: "Laos",
    dialling_code: 856,
    index: 107
  },
  {
    country_code: "LV",
    country_name: "Latvia",
    dialling_code: 371,
    index: 108
  },
  {
    country_code: "LB",
    country_name: "Lebanon",
    dialling_code: 961,
    index: 109
  },
  {
    country_code: "LS",
    country_name: "Lesotho",
    dialling_code: 266,
    index: 110
  },
  {
    country_code: "LR",
    country_name: "Liberia",
    dialling_code: 231,
    index: 111
  },
  {
    country_code: "LY",
    country_name: "Libya",
    dialling_code: 218,
    index: 112
  },
  {
    country_code: "LI",
    country_name: "Liechtenstein",
    dialling_code: 423,
    index: 113
  },
  {
    country_code: "LT",
    country_name: "Lithuania",
    dialling_code: 370,
    index: 114
  },
  {
    country_code: "LU",
    country_name: "Luxembourg",
    dialling_code: 352,
    index: 115
  },
  {
    country_code: "MO",
    country_name: "Macau",
    dialling_code: 853,
    index: 116
  },
  {
    country_code: "MK",
    country_name: "Macedonia",
    dialling_code: 389,
    index: 117
  },
  {
    country_code: "MG",
    country_name: "Madagascar",
    dialling_code: 261,
    index: 118
  },
  {
    country_code: "MW",
    country_name: "Malawi",
    dialling_code: 265,
    index: 119
  },
  {
    country_code: "MY",
    country_name: "Malaysia",
    dialling_code: 60,
    index: 120
  },
  {
    country_code: "MV",
    country_name: "Maldives",
    dialling_code: 960,
    index: 121
  },
  {
    country_code: "ML",
    country_name: "Mali",
    dialling_code: 223,
    index: 122
  },
  {
    country_code: "MT",
    country_name: "Malta",
    dialling_code: 356,
    index: 123
  },
  {
    country_code: "MH",
    country_name: "Marshall Islands",
    dialling_code: 692,
    index: 124
  },
  {
    country_code: "MQ",
    country_name: "Martinique",
    dialling_code: 596,
    index: 125
  },
  {
    country_code: "MR",
    country_name: "Mauritania",
    dialling_code: 222,
    index: 126
  },
  {
    country_code: "MU",
    country_name: "Mauritius",
    dialling_code: 230,
    index: 127
  },
  {
    country_code: "YT",
    country_name: "Mayotte",
    dialling_code: 262,
    index: 128
  },
  {
    country_code: "MX",
    country_name: "Mexico",
    dialling_code: 52,
    index: 129
  },
  {
    country_code: "MD",
    country_name: "Moldova",
    dialling_code: 373,
    index: 130
  },
  {
    country_code: "MC",
    country_name: "Monaco",
    dialling_code: 377,
    index: 131
  },
  {
    country_code: "MN",
    country_name: "Mongolia",
    dialling_code: 976,
    index: 132
  },
  {
    country_code: "ME",
    country_name: "Montenegro",
    dialling_code: 382,
    index: 133
  },
  {
    country_code: "MS",
    country_name: "Montserrat",
    dialling_code: 1,
    index: 134
  },
  {
    country_code: "MA",
    country_name: "Morocco",
    dialling_code: 212,
    index: 135
  },
  {
    country_code: "MZ",
    country_name: "Mozambique",
    dialling_code: 258,
    index: 136
  },
  {
    country_code: "NA",
    country_name: "Namibia",
    dialling_code: 264,
    index: 137
  },
  {
    country_code: "NR",
    country_name: "Nauru",
    dialling_code: 674,
    index: 138
  },
  {
    country_code: "NP",
    country_name: "Nepal",
    dialling_code: 977,
    index: 139
  },
  {
    country_code: "NL",
    country_name: "Netherlands",
    dialling_code: 31,
    index: 140
  },
  {
    country_code: "AN",
    country_name: "Netherlands Antilles",
    dialling_code: 599,
    index: 141
  },
  {
    country_code: "NC",
    country_name: "New Caledonia",
    dialling_code: 687,
    index: 142
  },
  {
    country_code: "NZ",
    country_name: "New Zealand",
    dialling_code: 64,
    index: 143
  },
  {
    country_code: "NI",
    country_name: "Nicaragua",
    dialling_code: 505,
    index: 144
  },
  {
    country_code: "NE",
    country_name: "Niger",
    dialling_code: 227,
    index: 145
  },
  {
    country_code: "NG",
    country_name: "Nigeria",
    dialling_code: 234,
    index: 146
  },
  {
    country_code: "NU",
    country_name: "Niue",
    dialling_code: 683,
    index: 147
  },
  {
    country_code: "NF",
    country_name: "Norfolk Island",
    dialling_code: 672,
    index: 148
  },
  {
    country_code: "KP",
    country_name: "North Korea",
    dialling_code: 850,
    index: 149
  },
  {
    country_code: "MP",
    country_name: "Northern Mariana Islands",
    dialling_code: 1,
    index: 150
  },
  {
    country_code: "NO",
    country_name: "Norway",
    dialling_code: 47,
    index: 151
  },
  {
    country_code: "OM",
    country_name: "Oman",
    dialling_code: 968,
    index: 152
  },
  {
    country_code: "PK",
    country_name: "Pakistan",
    dialling_code: 92,
    index: 153
  },
  {
    country_code: "PW",
    country_name: "Palau",
    dialling_code: 680,
    index: 154
  },
  {
    country_code: "PS",
    country_name: "Palestine",
    dialling_code: 970,
    index: 155
  },
  {
    country_code: "PA",
    country_name: "Panama",
    dialling_code: 507,
    index: 156
  },
  {
    country_code: "PG",
    country_name: "Papua New Guinea",
    dialling_code: 675,
    index: 157
  },
  {
    country_code: "PY",
    country_name: "Paraguay",
    dialling_code: 595,
    index: 158
  },
  {
    country_code: "PE",
    country_name: "Peru",
    dialling_code: 51,
    index: 159
  },
  {
    country_code: "PH",
    country_name: "Philippines",
    dialling_code: 63,
    index: 160
  },
  {
    country_code: "PL",
    country_name: "Poland",
    dialling_code: 48,
    index: 161
  },
  {
    country_code: "PT",
    country_name: "Portugal",
    dialling_code: 351,
    index: 162
  },
  {
    country_code: "PR",
    country_name: "Puerto Rico",
    dialling_code: 1,
    index: 163
  },
  {
    country_code: "QA",
    country_name: "Qatar",
    dialling_code: 974,
    index: 164
  },
  {
    country_code: "CG",
    country_name: "Republic of the Congo",
    dialling_code: 242,
    index: 165
  },
  {
    country_code: "RE",
    country_name: "Reunion",
    dialling_code: 262,
    index: 166
  },
  {
    country_code: "RO",
    country_name: "Romania",
    dialling_code: 40,
    index: 167
  },
  {
    country_code: "RU",
    country_name: "Russia",
    dialling_code: 7,
    index: 168
  },
  {
    country_code: "RW",
    country_name: "Rwanda",
    dialling_code: 250,
    index: 169
  },
  {
    country_code: "BL",
    country_name: "Saint Barthelemy",
    dialling_code: 590,
    index: 170
  },
  {
    country_code: "SH",
    country_name: "Saint Helena",
    dialling_code: 290,
    index: 171
  },
  {
    country_code: "KN",
    country_name: "Saint Kitts and Nevis",
    dialling_code: 1,
    index: 172
  },
  {
    country_code: "MF",
    country_name: "Saint Martin",
    dialling_code: 590,
    index: 173
  },
  {
    country_code: "PM",
    country_name: "Saint Pierre and Miquelon",
    dialling_code: 508,
    index: 174
  },
  {
    country_code: "VC",
    country_name: "Saint Vincent and the Grenadines",
    dialling_code: 1,
    index: 175
  },
  {
    country_code: "WS",
    country_name: "Samoa",
    dialling_code: 685,
    index: 176
  },
  {
    country_code: "SM",
    country_name: "San Marino",
    dialling_code: 378,
    index: 177
  },
  {
    country_code: "ST",
    country_name: "Sao Tome and Principe",
    dialling_code: 239,
    index: 178
  },
  {
    country_code: "SA",
    country_name: "Saudi Arabia",
    dialling_code: 966,
    index: 179
  },
  {
    country_code: "SN",
    country_name: "Senegal",
    dialling_code: 221,
    index: 180
  },
  {
    country_code: "RS",
    country_name: "Serbia",
    dialling_code: 381,
    index: 181
  },
  {
    country_code: "SC",
    country_name: "Seychelles",
    dialling_code: 248,
    index: 182
  },
  {
    country_code: "SL",
    country_name: "Sierra Leone",
    dialling_code: 232,
    index: 183
  },
  {
    country_code: "SG",
    country_name: "Singapore",
    dialling_code: 65,
    index: 184
  },
  {
    country_code: "SK",
    country_name: "Slovakia",
    dialling_code: 421,
    index: 185
  },
  {
    country_code: "SI",
    country_name: "Slovenia",
    dialling_code: 386,
    index: 186
  },
  {
    country_code: "SB",
    country_name: "Solomon Islands",
    dialling_code: 677,
    index: 187
  },
  {
    country_code: "SO",
    country_name: "Somalia",
    dialling_code: 252,
    index: 188
  },
  {
    country_code: "ZA",
    country_name: "South Africa",
    dialling_code: 27,
    index: 189
  },
  {
    country_code: "KR",
    country_name: "South Korea",
    dialling_code: 82,
    index: 190
  },
  {
    country_code: "ES",
    country_name: "Spain",
    dialling_code: 34,
    index: 191
  },
  {
    country_code: "LK",
    country_name: "Sri Lanka",
    dialling_code: 94,
    index: 192
  },
  {
    country_code: "LC",
    country_name: "St. Lucia",
    dialling_code: 1,
    index: 193
  },
  {
    country_code: "SD",
    country_name: "Sudan",
    dialling_code: 249,
    index: 194
  },
  {
    country_code: "SR",
    country_name: "Suriname",
    dialling_code: 597,
    index: 195
  },
  {
    country_code: "SZ",
    country_name: "Swaziland",
    dialling_code: 268,
    index: 196
  },
  {
    country_code: "SE",
    country_name: "Sweden",
    dialling_code: 46,
    index: 197
  },
  {
    country_code: "CH",
    country_name: "Switzerland",
    dialling_code: 41,
    index: 198
  },
  {
    country_code: "SY",
    country_name: "Syria",
    dialling_code: 963,
    index: 199
  },
  {
    country_code: "TW",
    country_name: "Taiwan",
    dialling_code: 886,
    index: 200
  },
  {
    country_code: "TJ",
    country_name: "Tajikistan",
    dialling_code: 992,
    index: 201
  },
  {
    country_code: "TZ",
    country_name: "Tanzania",
    dialling_code: 255,
    index: 202
  },
  {
    country_code: "TH",
    country_name: "Thailand",
    dialling_code: 66,
    index: 203
  },
  {
    country_code: "BS",
    country_name: "The Bahamas",
    dialling_code: 1,
    index: 204
  },
  {
    country_code: "GM",
    country_name: "The Gambia",
    dialling_code: 220,
    index: 205
  },
  {
    country_code: "TL",
    country_name: "Timor-Leste",
    dialling_code: 670,
    index: 206
  },
  {
    country_code: "TG",
    country_name: "Togo",
    dialling_code: 228,
    index: 207
  },
  {
    country_code: "TK",
    country_name: "Tokelau",
    dialling_code: 690,
    index: 208
  },
  {
    country_code: "TO",
    country_name: "Tonga",
    dialling_code: 676,
    index: 209
  },
  {
    country_code: "TT",
    country_name: "Trinidad and Tobago",
    dialling_code: 1,
    index: 210
  },
  {
    country_code: "TN",
    country_name: "Tunisia",
    dialling_code: 216,
    index: 211
  },
  {
    country_code: "TR",
    country_name: "Turkey",
    dialling_code: 90,
    index: 212
  },
  {
    country_code: "TM",
    country_name: "Turkmenistan",
    dialling_code: 993,
    index: 213
  },
  {
    country_code: "TC",
    country_name: "Turks and Caicos Islands",
    dialling_code: 1,
    index: 214
  },
  {
    country_code: "TV",
    country_name: "Tuvalu",
    dialling_code: 688,
    index: 215
  },
  {
    country_code: "UG",
    country_name: "Uganda",
    dialling_code: 256,
    index: 216
  },
  {
    country_code: "UA",
    country_name: "Ukraine",
    dialling_code: 380,
    index: 217
  },
  {
    country_code: "AE",
    country_name: "United Arab Emirates",
    dialling_code: 971,
    index: 218
  },
  {
    country_code: "GB",
    country_name: "United Kingdom",
    dialling_code: 44,
    index: 219
  },
  {
    country_code: "US",
    country_name: "United States",
    dialling_code: 1,
    index: 220
  },
  {
    country_code: "UY",
    country_name: "Uruguay",
    dialling_code: 598,
    index: 221
  },
  {
    country_code: "VI",
    country_name: "US Virgin Islands",
    dialling_code: 1,
    index: 222
  },
  {
    country_code: "UZ",
    country_name: "Uzbekistan",
    dialling_code: 998,
    index: 223
  },
  {
    country_code: "VU",
    country_name: "Vanuatu",
    dialling_code: 678,
    index: 224
  },
  {
    country_code: "VA",
    country_name: "Vatican City",
    dialling_code: 39,
    index: 225
  },
  {
    country_code: "VE",
    country_name: "Venezuela",
    dialling_code: 58,
    index: 226
  },
  {
    country_code: "VN",
    country_name: "Vietnam",
    dialling_code: 84,
    index: 227
  },
  {
    country_code: "WF",
    country_name: "Wallis and Futuna",
    dialling_code: 681,
    index: 228
  },
  {
    country_code: "YE",
    country_name: "Yemen",
    dialling_code: 967,
    index: 229
  },
  {
    country_code: "ZM",
    country_name: "Zambia",
    dialling_code: 260,
    index: 230
  },
  {
    country_code: "ZW",
    country_name: "Zimbabwe",
    dialling_code: 263,
    index: 231
  }
];
