import { normalize, schema } from "normalizr";
import { OutputSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Logger as logger } from "purplex-logging";

import { ApiClient, useApi } from "../api/use-api";
import { ApiListPayload, LongTask } from "./crud-types";
import { RootState } from "../root/root-reducer";
import {
  EntityRepositoryActions,
  EntityRepositoryHasChangedAction
} from "../entity-repository/entity-repository-reducer";
import { useNotifications } from "../ux/notifications/use-notifications";

//TODO add more parameters for categories/filters - we also must formalize this everywhere
export interface ApiListEntityParameters {
  search: string | null;
  startIndex: number;
  stopIndex: number;
}

export interface FetchListCommand<MappedEntity> extends LongTask {
  records: MappedEntity[];
  totalCount: number;
  fetchList: (params: ApiListEntityParameters) => Promise<void>;
  addEntity: (id: number | string) => void;
  deleteEntity: (id: number | string) => void;
}

export type ApiExtractorList<Result> = (
  api: ApiClient
) => (listParams: ApiListEntityParameters) => Promise<ApiListPayload<Result[]>>;

export const defaultInitialListParams = {
  startIndex: 40,
  stopIndex: 50,
  search: ""
};

export const useFetchList = <ApiResult, NormalizeEntity, MappedEntity>(
  entitySchema: schema.Entity<NormalizeEntity>,
  entityRepositorySelector: OutputSelector<
    RootState,
    { [key: string]: MappedEntity },
    unknown
  >,
  apiListExtractor: ApiExtractorList<ApiResult>,
  listParams: ApiListEntityParameters = defaultInitialListParams
): FetchListCommand<MappedEntity> => {
  const dispatch = useDispatch();
  const api = useApi();
  const { notifyError } = useNotifications();
  const [records, setRecords] = useState<(number | string)[]>([]);
  const [mappedRecords, setMappedRecords] = useState<MappedEntity[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchList = useCallback(
    async (listParams: ApiListEntityParameters, infinite?: boolean) => {
      try {
        logger.debug(
          `Fetching list - from ${listParams.startIndex} to ${listParams.stopIndex}`
        );

        setInProgress(true);
        const { rows, count } = await apiListExtractor(api)({
          ...listParams
        });

        logger.debug(
          `Fetched records ${listParams.startIndex} - ${listParams.stopIndex} records out of ${count}`
        );

        const { entities: repository, result: idList } = normalize<
          ApiResult,
          EntityRepositoryHasChangedAction,
          number[]
        >(rows, [entitySchema]);

        dispatch(EntityRepositoryActions.repositoryHasChanged({ repository }));
        logger.info("infinite", infinite);
        if (infinite) {
          setRecords((list) => [...list, ...idList]);
        } else {
          setRecords(idList);
        }

        setTotalCount(count);
      } catch (ex) {
        logger.error(`Error during fetch list.`, ex);
        notifyError("There was an error fetching data.");
      } finally {
        setInProgress(false);
      }
    },
    [api, apiListExtractor, entitySchema, dispatch, notifyError]
  );

  const addEntity = useCallback((id: string | number) => {
    setRecords((records) => [...records, id]);
    setTotalCount((count) => count + 1);
  }, []);

  const deleteEntity = useCallback((id: string | number) => {
    setRecords((records) => records.filter((recordId) => recordId !== id));
    setTotalCount((count) => count - 1);
  }, []);

  useEffect(() => {
    fetchList(listParams, false);
  }, [fetchList, listParams]);

  const entityRepository = useSelector(entityRepositorySelector);

  useEffect(() => {
    setMappedRecords(records.map((recordId) => entityRepository[recordId]));
  }, [entityRepository, records]);

  return useMemo(() => {
    return {
      records: mappedRecords,
      totalCount,
      fetchList,
      inProgress,
      addEntity,
      deleteEntity
    };
  }, [
    mappedRecords,
    totalCount,
    fetchList,
    inProgress,
    addEntity,
    deleteEntity
  ]);
};
