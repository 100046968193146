import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Tooltip from "rc-tooltip";
import cx from "classnames";

import { AssetReduced } from "shared/types/asset";
import { UploadStatus } from "../../upload-statuses";
import Actions from "../../media-actions";
import { AssetStatus } from "shared/types/asset-status";
import { AssetType } from "shared/types/asset-type";
import { renderAssetIcon } from "./render-asset-icon";
import { ReactComponent as IconTrash } from "../../../../images/trash.svg";
import { ReactComponent as IconNewTab } from "../../../../images/new-tab.svg";
import { ReactComponent as IconExpand } from "../../../../images/expand.svg";
import { getAssetThumbnail } from "../../get-asset-thumbnail";
import { DateTimeField } from "../../../ux/date-time-field";
import { RelativeTimeField } from "client/modules/ux/relative-time-field";
import { AssetUploadState } from "../asset-upload-state";

export interface UploadState {
  status: UploadStatus;
  uploaded: number;
  size: number;
}

export type ListRowAsset = Pick<
  AssetReduced,
  | "uuid"
  | "title"
  | "description"
  | "createdAt"
  | "updatedAt"
  | "creator"
  | "type"
  | "playAs"
  | "status"
  | "dimensions"
  | "metadata"
  | "formatName"
>;

const newAssetTimeout = moment.duration(2, "hours");

const shouldRenderDimensions = (asset: ListRowAsset) =>
  [AssetType.ASSET_IMAGE, AssetType.ASSET_VIDEO].includes(asset.type);

const renderMetadata = (asset: ListRowAsset) => {
  return [
    asset.formatName,
    shouldRenderDimensions(asset) &&
      asset.dimensions &&
      `${asset.dimensions.width}x${asset.dimensions.height}`,
    asset.metadata?.pageCount && `Pages ${asset.metadata?.pageCount}`,
    asset.metadata?.url
  ]
    .filter(Boolean)
    .map((value, index) => <span key={index}>{value}&nbsp;</span>);
};

export const AssetsListRow = React.memo(
  ({
    uploadState,
    asset,
    cdnHost,
    isDeletingInProgress,
    sharedMedia,
    onDelete,
    readOnly
  }: {
    readOnly: boolean;
    uploadState: UploadState;
    cdnHost: string;
    asset: ListRowAsset;
    isDeletingInProgress: boolean;
    sharedMedia: boolean;
    onDelete: (assetUuid: string, title: string) => void;
  }) => {
    const {
      uuid,
      title,
      description,
      createdAt,
      updatedAt,
      creator,
      type,
      playAs
    } = asset;

    const dispatch = useDispatch();
    const onPreview = useCallback(() => {
      dispatch(Actions.Creators.previewAsset(uuid));
    }, [uuid, dispatch]);

    const onEdit = useCallback(() => {
      if (sharedMedia) {
        dispatch(Actions.Creators.editSharedAsset(uuid));
      } else {
        dispatch(Actions.Creators.editAsset(uuid));
      }
    }, [uuid, dispatch, sharedMedia]);

    const onDeleteInternal = useCallback(() => {
      onDelete(uuid, title);
    }, [title, uuid, onDelete]);

    const status = useMemo(() => {
      if (asset.status === AssetStatus.STATUS_NEW) {
        const timeoutOn = moment(createdAt).add(newAssetTimeout);
        const isTimeouted = moment().isAfter(timeoutOn);

        if (isTimeouted) {
          return AssetStatus.STATUS_ERROR;
        } else {
          return AssetStatus.STATUS_PENDING;
        }
      }

      return asset.status;
    }, [createdAt, asset.status]);

    return (
      <div className="gridRow" data-qa={`media-row-${title}`}>
        {status === AssetStatus.STATUS_READY ? (
          playAs === AssetType.ASSET_URL ||
          playAs === AssetType.ASSET_PDF ||
          playAs === AssetType.ASSET_PRESENTATION ? (
            <button onClick={onPreview} className="gridImage openInNewTab">
              {renderAssetIcon(type, status)}
              <i className="previewIcon">
                <IconNewTab />
              </i>
            </button>
          ) : (
            <button
              className="gridImage"
              style={{
                backgroundImage: `url(${getAssetThumbnail(asset, cdnHost)})`
              }}
              onClick={onPreview}
            >
              {renderAssetIcon(type, status)}
              <i className="previewIcon">
                <IconExpand />
              </i>
            </button>
          )
        ) : (
          <i className="gridImage">{renderAssetIcon(type, status)}</i>
        )}
        <span className="gridCell" data-qa="media-title">
          <h3 className="gridRowTitle">{title}</h3>
          <em
            className={cx({
              error: status === AssetStatus.STATUS_ERROR,
              success: status === AssetStatus.STATUS_READY
            })}
            data-qa="media-status"
          >
            {status.replace("STATUS_", "")}
          </em>
          {description}
        </span>
        <hr />
        <span className="gridCell" data-qa="media-creator">
          Created by
          <h4>{creator}</h4>
        </span>
        <hr />
        <span className="gridCell" data-qa="media-created">
          Created
          <Tooltip
            placement="bottomLeft"
            mouseEnterDelay={0.4}
            overlay={<DateTimeField datetime={createdAt} />}
          >
            <h4>
              <RelativeTimeField datetime={createdAt} />
            </h4>
          </Tooltip>
        </span>
        {updatedAt && (
          <>
            <hr />
            <span className="gridCell" data-qa="media-updated">
              Updated
              <Tooltip
                placement="bottomLeft"
                mouseEnterDelay={0.4}
                overlay={<DateTimeField datetime={createdAt} />}
              >
                <h4>
                  <RelativeTimeField datetime={updatedAt} />
                </h4>
              </Tooltip>
            </span>
          </>
        )}
        <hr />
        <span className="gridCell" data-qa="media-metadata">
          Metadata <h4>{renderMetadata(asset)}</h4>
        </span>

        {!readOnly && (
          <>
            <hr />
            <span className="gridCell">
              <button className="button" onClick={onEdit} data-qa="action-edit">
                Edit
              </button>
              <button
                className={cx("button buttonAlert buttonOutlined buttonIcon", {
                  inProgress: isDeletingInProgress
                })}
                onClick={onDeleteInternal}
                data-qa="action-delete"
              >
                <IconTrash />
              </button>
            </span>
          </>
        )}

        <AssetUploadState uploadState={uploadState} />
      </div>
    );
  }
);
