import { notEmptyString } from "client/modules/forms/validations/not-empty-string";
import { TriggersFormData } from "./triggers-form";
import * as Api from "../../../api/client";

export const validate = (data: TriggersFormData) => {
  const errors: { [key: string]: string } = {};

  if (notEmptyString(data.name)) {
    errors.name = "Required";
  }

  if (notEmptyString(data.triggerId)) {
    errors.triggerId = "Required";
  }

  if (notEmptyString(data.instanceUuid)) {
    errors.instanceUuid = "Required";
  }

  return errors;
};

export const asyncValidate = async (data: TriggersFormData) => {
  if (data.instanceUuid && data.triggerId) {
    const {
      data: { unique }
    } = await Api.isUniqueTrigger(data.id, data.instanceUuid, data.triggerId);

    if (!unique) {
      throw { triggerId: "Combination of room & Trigger ID already exists." };
    }
  }
};
