import React, { Fragment } from "react";
import { connect } from "react-redux";
import { includesSegment, endsWithSegment } from "router5-helpers";

import { getRouteName } from "../../routing/routing-selectors";
import { SceneList } from "./scene-list";
import { SceneAdd } from "./scene-add";
import { SceneEdit } from "./scene-edit";

const mapStateToProps = (state) => ({
  route: getRouteName(state)
});

export const SceneIndex = connect(mapStateToProps)(({ route }) => {
  const includes = includesSegment(route);
  const ends = endsWithSegment(route);

  if (includes("list")) {
    return (
      <Fragment>
        <SceneList />
        {ends("add") && <SceneAdd />}
        {ends("edit") && <SceneEdit />}
      </Fragment>
    );
  } else {
    throw new Error(`Unknown route ${route}`);
  }
});
