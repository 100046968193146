import { createReducer } from "reduxsauce";

import Actions from "./dashboard-actions";

const INITIAL_STATE = {
  stats: {
    presentations: 0,
    presentationsPublic: 0,
    assets: 0,
    scenes: 0
  }
};

const onStatsFetched = (state, { stats }) => ({
  ...state,
  stats
});

export const HANDLERS = {
  [Actions.Types.STATS_FETCHED]: onStatsFetched
};

export default createReducer(INITIAL_STATE, HANDLERS);
