import cx from "classnames";
import React, { useCallback } from "react";
import { AutoSizer, List, InfiniteLoader } from "react-virtualized";

import { useCustomFieldAssetFilters } from "client/modules/settings/custom-fields/hooks/use-custom-fields";
import { TabPanel, Tabs } from "client/modules/ux/tab-panel";
import * as Api from "client/modules/api/client";
import { AssetReduced } from "shared/types/asset";
import { ReactComponent as IconAdd } from "../../../../images/add.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCdnHost } from "client/modules/config/config-selectors";
import {
  getInfiniteScrollLoadingKey,
  getUploadStates
} from "../../media-selectors";
import Actions from "../../media-actions";
import { useHasAccess } from "client/modules/auth/use-has-access";
import { AclResource } from "shared/acl";
import {
  getRouteParams,
  RouteParamSelector
} from "client/modules/routing/routing-selectors";
import { getAssets } from "client/modules/entity-repository/entity-repository-selectors";
import {
  InfiniteListRowStatus,
  useInfiniteListStore
} from "client/modules/ux/infinite-list/use-infinite-list-store";
import { useAssetList } from "./use-asset-list";
import { AssetsListRow, ListRowAsset, UploadState } from "./asset-list-row";

const AssetsListLoading = () => <div className="gridItemPlaceholder" />;

export const AssetList = () => {
  const dispatch = useDispatch();
  const cdnHost: string = useSelector(getCdnHost);
  const loadingKey = useSelector(getInfiniteScrollLoadingKey);

  const uploadStates: { [key: string]: UploadState } = useSelector(
    getUploadStates
  );
  const canWriteSharedLibrary = useHasAccess(AclResource.SHARED_LIBRARY_WRITE);
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{ tab: string }>
  );
  const tab = routeParams.tab as string;
  const sharedMedia = tab === "shared";

  const onAddAsset = useCallback(() => {
    dispatch(Actions.Creators.addAsset());
  }, [dispatch]);

  const assetsRepository = useSelector(getAssets);

  const tabs = [
    { label: "My Media", route: "my" },
    { label: "Company Library", route: "shared" }
  ];
  const {
    category,
    filters,
    filtersComponent,
    categorySelectComponent,
    resetFilters
  } = useCustomFieldAssetFilters({ type: tab }, false, tab);

  const fetchAssets = useCallback(
    async (startIndex: number, stopIndex: number) => {
      const result = await Api.fetchAssets(
        filters && filters.fulltext,
        startIndex,
        stopIndex,
        tab,
        filters && filters.filters,
        category
      );

      dispatch(Actions.Creators.assetsFetched(result.rows));

      return result;
    },
    [dispatch, filters, tab, category]
  );

  const {
    requestMoreData,
    removeRecord,
    records,
    count,
    resetCount
  } = useInfiniteListStore(fetchAssets, [tab, category, filters, loadingKey]);

  const { onDelete, deletingUuid } = useAssetList(
    removeRecord,
    category,
    filters,
    records,
    tab,
    sharedMedia
  );

  return (
    <Tabs>
      <TabPanel
        tabs={tabs}
        heading="Media"
        onChange={resetFilters}
        buttons={
          <button
            className={cx({
              button: true,
              invisible: tab === "shared" && !canWriteSharedLibrary
            })}
            onClick={onAddAsset}
            data-qa="new-media-btn"
          >
            <IconAdd />
            New Media
          </button>
        }
      >
        {() => {
          return (
            <div className="withCategoryWrapper">
              {sharedMedia && categorySelectComponent}
              <div className="tabContent">
                <div className="tabFilter">{filtersComponent}</div>

                <div className="listContent">
                  <AutoSizer>
                    {({ width, height }) => {
                      return (
                        <InfiniteLoader
                          key={resetCount}
                          isRowLoaded={({ index }) =>
                            Boolean(records[index]) &&
                            records[index].status !==
                              InfiniteListRowStatus.Error
                          }
                          rowCount={count}
                          loadMoreRows={requestMoreData}
                        >
                          {({ onRowsRendered, registerChild }) => {
                            return (
                              <List
                                key={resetCount}
                                height={height}
                                rowCount={count}
                                rowHeight={70}
                                width={width}
                                ref={registerChild}
                                onRowsRendered={onRowsRendered}
                                rowRenderer={({ index, style }) => {
                                  if (
                                    !records[index] ||
                                    [
                                      InfiniteListRowStatus.Loading,
                                      InfiniteListRowStatus.Error
                                    ].includes(records[index].status)
                                  ) {
                                    return (
                                      <div key={index} style={style}>
                                        <AssetsListLoading />
                                      </div>
                                    );
                                  }

                                  const asset = records[index]
                                    .data as AssetReduced;
                                  const uploadState = uploadStates[asset.uuid];

                                  let finalAsset: ListRowAsset = asset;
                                  if (assetsRepository[asset.uuid]) {
                                    finalAsset = assetsRepository[asset.uuid];
                                  }

                                  return (
                                    <div key={index} style={style}>
                                      <AssetsListRow
                                        uploadState={uploadState}
                                        asset={finalAsset}
                                        cdnHost={cdnHost}
                                        onDelete={onDelete}
                                        isDeletingInProgress={
                                          deletingUuid === asset.uuid
                                        }
                                        sharedMedia={sharedMedia}
                                        readOnly={
                                          sharedMedia && !canWriteSharedLibrary
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              ></List>
                            );
                          }}
                        </InfiniteLoader>
                      );
                    }}
                  </AutoSizer>
                </div>
              </div>
            </div>
          );
        }}
      </TabPanel>
    </Tabs>
  );
};
