import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import cx from "classnames";

import {
  getAssetConfigInitialValues,
  isSaving,
  isSaveFailure,
  getAssetConfigWall,
  getSelectAssetBounds,
  getSelectedAssetSizeRange,
  getSelectedSceneAssetsFormConfig,
  getSelectedSceneAssetsType,
  getActiveWallInfo,
  getSelectedSceneAssets
} from "../../scene-editor-selectors";
import { NormalizedNumberInput, ensureRange } from "../ux/number-input";
import { TextAssetsConfig } from "./text-assets-config";
import { TextAssetsControl } from "./text-assets-control";

import { ReactComponent as IconAction } from "client/images/action.svg";
import { ReactComponent as IconAlignBottom } from "client/images/align-bottom.svg";
import { ReactComponent as IconAlignTop } from "client/images/align-top.svg";
import { ReactComponent as IconAlignLeft } from "client/images/align-left.svg";
import { ReactComponent as IconAlignRight } from "client/images/align-right.svg";
import { ReactComponent as IconAlignCenterVertically } from "client/images/align-center-vertically.svg";
import { ReactComponent as IconAlignCenterHorizontally } from "client/images/align-center-horizontally.svg";
import { ReactComponent as IconAlignDistributeVertically } from "client/images/align-distribute-vertically.svg";
import { ReactComponent as IconAlignDistributeHotizontally } from "client/images/align-distribute-horizontally.svg";
import { ReactComponent as IconEnlarge } from "client/images/enlarge.svg";

import { useAlignmentTools } from "../../hooks/use-alignment-tools";
import { getRouteName } from "../../../routing/routing-selectors";
import { AssetType } from "../../../../../shared/types/asset-type";
import { useAssetPropertiesActions } from "../../hooks/asset-config/use-asset-properties-actions";
import { CheckboxField } from "../../../forms/checkbox-field";
import { useReplaceUrl } from "../../../routing/routing-hooks";
import { Hint } from "../ux/hint";

const noop = () => {};

const Form = reduxForm<any, any>({
  form: "asset-config",
  enableReinitialize: true
})(({ handleSubmit }) => {
  const {
    showAutoPlayAndLoop,
    showGlowAndRoundedCorners,
    showOnClickActions,
    singleAsset
  } = useSelector(getSelectedSceneAssetsFormConfig);
  const saving = useSelector(isSaving);
  const failed = useSelector(isSaveFailure);
  const wall = useSelector(getAssetConfigWall);
  const bounds = useSelector(getSelectAssetBounds);
  const sizeRange = useSelector(getSelectedAssetSizeRange);
  const assetType = useSelector(getSelectedSceneAssetsType);
  const activeWallInfo = useSelector(getActiveWallInfo);
  const selectedAssets = useSelector(getSelectedSceneAssets);

  const routeName = useSelector(getRouteName);
  const isInPresentation = routeName && routeName.includes("presentation");
  const isOnSceneAssetsTab = routeName && routeName.includes("assets");

  const { replaceUrl } = useReplaceUrl();

  const editOnClickAction = useCallback(
    (payload: { sceneAssetId: number }) => {
      replaceUrl(`presentations.edit.editor.assets.asset-actions`, {
        sceneAssetId: payload.sceneAssetId
      });
    },
    [replaceUrl]
  );

  const {
    setCropWidth,
    setWidth,
    setCropHeight,
    setHeight,
    setX,
    setScale,
    setAutoplay,
    setGlow,
    setLoop,
    setOpacity,
    setPositionLocked,
    setRounded,
    setVisible,
    setY
  } = useAssetPropertiesActions();

  const {
    alignRight,
    alignTop,
    alignLeft,
    alignBottom,
    alignCenterHorizontally,
    alignCenterVertically,
    evenlyDistributeVertically,
    evenlyDistributeHorizontally
  } = useAlignmentTools();

  const isMultiSelection = selectedAssets.length > 1;
  const nothingSelected = selectedAssets.length === 0;

  if (!isOnSceneAssetsTab || !activeWallInfo) {
    return <></>;
  }

  return (
    <div
      className={cx("asset-config", { saving, failed })}
      data-qa="asset-config-form"
    >
      {/* tools */}
      <fieldset>
        <h3>Tools</h3>
        <TextAssetsControl />
      </fieldset>

      {!nothingSelected && (
        <>
          {/* position */}
          <form>
            <fieldset data-qa="position-fieldset">
              <h3 className="savingIndicator">Position</h3>

              <div style={{ display: "flex" }}>
                <Field
                  component={NormalizedNumberInput}
                  name="left"
                  title="X-position"
                  prefix="X"
                  dataQa="position-x"
                  format={(value: number) => (wall ? value - wall.start : 0)}
                  parse={(value: number) => (wall ? wall.start + value : 0)}
                  style={{ display: "flex" }}
                  props={{
                    normalize: ensureRange(
                      bounds ? bounds.leftMin : 0,
                      bounds ? bounds.leftMax : 0
                    )
                  }}
                  onChange={(e: any) => {
                    setX(e + activeWallInfo.start);
                  }}
                />
                <Field
                  component={NormalizedNumberInput}
                  name="top"
                  title="Y-position"
                  prefix="Y"
                  dataQa="position-y"
                  props={{
                    normalize: ensureRange(
                      bounds ? bounds.topMin : 0,
                      bounds ? bounds.topMax : 0
                    )
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setY(e)}
                />
              </div>
              <div style={{ display: "flex" }}>
                <Field
                  component={NormalizedNumberInput}
                  name="width"
                  title="Width"
                  prefix="W"
                  unit={assetType === AssetType.ASSET_TEXT ? `%` : `px`}
                  disabled={!sizeRange}
                  dataQa="size-width"
                  props={{
                    normalize: ensureRange(
                      assetType !== AssetType.ASSET_TEXT
                        ? (sizeRange && sizeRange.minWidth) || 0
                        : 0,
                      assetType !== AssetType.ASSET_TEXT
                        ? (sizeRange && sizeRange.maxWidth) || 0
                        : 100
                    )
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setWidth(e);
                    setCropWidth(e);
                  }}
                />
                <Field
                  component={NormalizedNumberInput}
                  name="height"
                  title="Height"
                  prefix="H"
                  unit={assetType === AssetType.ASSET_TEXT ? `%` : `px`}
                  disabled={!sizeRange}
                  dataQa="size-height"
                  style={{ display: "flex" }}
                  props={{
                    normalize: ensureRange(
                      assetType !== AssetType.ASSET_TEXT
                        ? (sizeRange && sizeRange.minHeight) || 0
                        : 0,
                      assetType !== AssetType.ASSET_TEXT
                        ? (sizeRange && sizeRange.maxHeight) || 0
                        : 100
                    )
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setHeight(e);
                    setCropHeight(e);
                  }}
                />
                <Field
                  component={NormalizedNumberInput}
                  name="scale"
                  title="Scale"
                  prefix={<IconEnlarge />}
                  unit="%"
                  disabled={sizeRange}
                  dataQa="scale"
                  style={{ display: "flex" }}
                  props={{
                    normalize: ensureRange(1, 1000)
                  }}
                  onChange={(e: any) => setScale(e)}
                />
              </div>
            </fieldset>
          </form>

          {/* playback */}
          <form>
            <fieldset data-qa="playback-fieldset">
              <h3>Playback</h3>

              <CheckboxField
                name="positionLocked"
                label="Lock Position"
                dataQa="lock-checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPositionLocked(e.target.value === "false")
                }
              />
              <CheckboxField
                name="visible"
                label="Visible on start"
                dataQa="visible-checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setVisible(e.target.value === "false")
                }
              />
              <div>
                <CheckboxField
                  name="autoplay"
                  label="Autoplay"
                  disabled={!showAutoPlayAndLoop}
                  dataQa="autoplay-checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAutoplay(e.target.value === "false")
                  }
                />
                <CheckboxField
                  name="loop"
                  label="Loop"
                  disabled={!showAutoPlayAndLoop}
                  dataQa="loop-checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setLoop(e.target.value === "false")
                  }
                />
              </div>
            </fieldset>
          </form>

          {/* appearance */}
          <form onSubmit={handleSubmit(noop)}>
            <fieldset data-qa="appearance-fieldset">
              <h3>Appearance</h3>

              <Field
                component={NormalizedNumberInput}
                name="opacity"
                label="Opacity"
                prefix="O"
                unit="%"
                dataQa="opacity"
                props={{
                  normalize: ensureRange(0, 100)
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setOpacity(e)
                }
              />
              <div>
                <CheckboxField
                  name="glow"
                  label="Glow"
                  disabled={!showGlowAndRoundedCorners}
                  dataQa="glow-checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setGlow(e.target.value === "false")
                  }
                />
                <CheckboxField
                  name="rounded"
                  label="Radius"
                  title="Rounded Corners"
                  disabled={!showGlowAndRoundedCorners}
                  dataQa="rounded-checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRounded(e.target.value === "false")
                  }
                />
              </div>
            </fieldset>
          </form>

          {/* alignment */}
          <form>
            <fieldset data-qa="alignment-fieldset">
              <h3>Alignment</h3>

              <div style={{ display: "flex" }}>
                <div>
                  <div>
                    <Hint label="Align Left" placement="bottom">
                      <button
                        type="button"
                        className="button buttonIcon buttonConfigPanel"
                        onClick={alignLeft}
                      >
                        <IconAlignLeft />
                      </button>
                    </Hint>
                    <Hint label="Align Vertical Centers" placement="bottom">
                      <button
                        type="button"
                        className="button buttonIcon buttonConfigPanel"
                        onClick={alignCenterVertically}
                      >
                        <IconAlignCenterVertically />
                      </button>
                    </Hint>
                    <Hint label="Align Right" placement="bottom">
                      <button
                        type="button"
                        className="button buttonIcon buttonConfigPanel"
                        onClick={alignRight}
                      >
                        <IconAlignRight />
                      </button>
                    </Hint>
                  </div>
                  <div>
                    <Hint label="Align Top" placement="top">
                      <button
                        type="button"
                        className="button buttonIcon buttonConfigPanel"
                        onClick={alignTop}
                      >
                        <IconAlignTop />
                      </button>
                    </Hint>
                    <Hint label="Align Horizontal Centers" placement="top">
                      <button
                        type="button"
                        className="button buttonIcon buttonConfigPanel"
                        onClick={alignCenterHorizontally}
                      >
                        <IconAlignCenterHorizontally />
                      </button>
                    </Hint>
                    <Hint label="Align Bottom" placement="top">
                      <button
                        type="button"
                        className="button buttonIcon buttonConfigPanel"
                        onClick={alignBottom}
                      >
                        <IconAlignBottom />
                      </button>
                    </Hint>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Hint
                    label="Distribute Vertical Spacing"
                    placement="bottom"
                    disabled={!isMultiSelection}
                  >
                    <button
                      type="button"
                      className="button buttonIcon buttonConfigPanel"
                      disabled={!isMultiSelection}
                      onClick={evenlyDistributeHorizontally}
                    >
                      <IconAlignDistributeVertically />
                    </button>
                  </Hint>
                  <Hint
                    label="Distribute Horizontal Spacing"
                    placement="top"
                    disabled={!isMultiSelection}
                  >
                    <button
                      type="button"
                      className="button buttonIcon buttonConfigPanel"
                      disabled={!isMultiSelection}
                      onClick={evenlyDistributeVertically}
                    >
                      <IconAlignDistributeHotizontally />
                    </button>
                  </Hint>
                </div>
              </div>
            </fieldset>
          </form>

          {/* On-Click */}
          {showOnClickActions && isInPresentation && (
            <form>
              <fieldset className="actionConfigFieldset">
                <h3>On-Click</h3>
                <button
                  type="button"
                  className="button"
                  onClick={
                    singleAsset
                      ? () =>
                          editOnClickAction({ sceneAssetId: singleAsset.id })
                      : () => {}
                  }
                >
                  <IconAction />
                  <span>On-Click</span> Action
                </button>
              </fieldset>
            </form>
          )}

          {/* text */}
          <TextAssetsConfig />
        </>
      )}
    </div>
  );
});

export const AssetConfig = () => {
  const initialValues = useSelector(getAssetConfigInitialValues);

  return <Form initialValues={initialValues} />;
};
