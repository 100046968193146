import { Logger as logger } from "purplex-logging";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { getLastEditedSharedAsset } from "../../media-selectors";
import * as Api from "../../../api/client";
import { InfiniteListRecord } from "client/modules/ux/infinite-list/use-infinite-list-store";
import { DialogResult } from "client/modules/dialogs/event-types";
import { useConfirmationDialog } from "client/modules/dialogs/dialog-hooks";
import { MEDIA_TITLE_MAPPING } from "../media-dialog/media-title-mapping";
import { MediaDialogType } from "../media-dialog/media-dialog-type";
import { AssetReduced } from "shared/types/asset";

export const useAssetList = (
  removeRecordFromInfiniteList: (
    predicate: (record: AssetReduced) => boolean
  ) => void,
  category: string,
  filters: any,
  records: { [key: string]: InfiniteListRecord<AssetReduced> },
  tab: string,
  sharedMedia: boolean
) => {
  const [deletingUuid, setDeletingUuid] = useState<null | string>(null);

  const lastEditedSharedAsset: null | string = useSelector(
    getLastEditedSharedAsset
  );

  const filterHolder = useRef({ category, filters, records, tab });
  filterHolder.current = { category, filters, records, tab };

  // This effect will remove the record from the list
  // if after editing an asset it is no longer part of the filter selection
  // eg. if user moves asset within Company library across categories
  // so that the asset no longer matches the selected filter
  useEffect(() => {
    (async () => {
      if (lastEditedSharedAsset) {
        const { filters, category, records, tab } = filterHolder.current;

        logger.debug(
          "Shared asset has been changed, checking if it still exists within the list view."
        );
        const assetIndex = Object.keys(records).find(
          (index) => records[index].data?.uuid === lastEditedSharedAsset
        );

        logger.debug(`Edited asset is on index ${assetIndex}`);
        if (assetIndex !== undefined) {
          const assetIndexInt = parseInt(assetIndex);

          const result = await Api.fetchAssets(
            filters && filters.fulltext,
            assetIndexInt,
            assetIndexInt,
            tab,
            filters && filters.filters,
            category
          );
          logger.debug("Result for the same filter from back-end", result);
          if (
            result.rows.length !== 1 ||
            result.rows[0].uuid !== lastEditedSharedAsset
          ) {
            logger.debug(
              "Result from back-end does not contain same asset on the same index - removing from list."
            );
            removeRecordFromInfiniteList(
              (record) => record.uuid === lastEditedSharedAsset
            );
          } else {
            logger.debug("Asset is still within list view");
          }
        }
      }
    })();
  }, [removeRecordFromInfiniteList, lastEditedSharedAsset]);

  const confirm = useConfirmationDialog();

  const onDelete = useCallback(
    async (assetUuid: string, title: string) => {
      const assetNoun = MEDIA_TITLE_MAPPING[MediaDialogType.ASSET];

      setDeletingUuid(assetUuid);
      const result = await confirm({
        title: `Delete  ${assetNoun}`,
        text: `Are you sure you want to delete ${assetNoun.toLowerCase()} - ${title}?`,
        subtext: "",
        confirmLabel: "Delete",
        cancelLabel: "Cancel"
      });

      if (result.type === DialogResult.CONFIRM) {
        await Api.deleteAsset(assetUuid, sharedMedia);
        removeRecordFromInfiniteList((record) => record.uuid === assetUuid);
      }
      setDeletingUuid(null);
    },
    [removeRecordFromInfiniteList, confirm, sharedMedia]
  );

  return { onDelete, deletingUuid };
};
