import React, { useMemo } from "react";
import cx from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  firstName: string;
  lastName: string;
  avatar: string | null;
}

export const Avatar: React.FC<Props> = (avatarProps) => {
  const { firstName, lastName, avatar, className, ...props } = avatarProps;

  const userCredentials = useMemo(() => {
    const [firstLetterFromFirstName] = firstName;
    const [firstLetterFromLastName] = lastName;

    return [firstLetterFromFirstName, firstLetterFromLastName]
      .join("")
      .toUpperCase();
  }, [firstName, lastName]);

  if (!avatar) {
    return (
      <div className={cx("avatar avatarPlaceholder", className)} {...props}>
        <span>{userCredentials}</span>
      </div>
    );
  } else {
    return (
      <div className={cx("avatar", className)} {...props}>
        <img className="userAvatar" src={avatar} alt="avatar" />
      </div>
    );
  }
};
