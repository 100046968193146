import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions as routerActions } from "redux-router5";
import { AUTH_RESET_PASSWORD_ROUTE } from "../../routing/route-names";
import { hasSsoEnabled } from "client/modules/config/config-selectors";

import {
  getRouteParams,
  RouteParamSelector
} from "../../routing/routing-selectors";
import { LoggedOutHeader } from "./logged-out-header";
import { SsoLoginButton } from "./sso-login-button";

import { ReactComponent as IconAlertError } from "client/images/alert-error.svg";
import { ReactComponent as IconAlertSuccess } from "client/images/alert-success.svg";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

const errorMessages = {
  "email-blocked-error":
    "Too many failed login attempts. Try resetting your password by clicking on Forgot Password link below.",
  "ip-blocked-error": "Too many failed login attempts from your IP address.",
  "invalid-confirmation-token": "Confirmation token expired or already used.",
  "unknown-confirmation-error":
    "There was an error during confirmation of your account.",
  "invalid-credentials":
    "There was an error during login, make sure provided credentials are valid.",
  "user-deactivated": "Your account is de-activated by the administrator."
};

const successMessages = {
  "account-confirmed":
    "Email address successfully confirmed. You can login to your account now."
};

export const AuthLogin = () => {
  const dispatch = useDispatch();
  const gotoResetPassword = useCallback(() => {
    dispatch(routerActions.navigateTo(AUTH_RESET_PASSWORD_ROUTE));
  }, [dispatch]);

  const { failure, success } = useSelector(
    getRouteParams as RouteParamSelector<{
      failure?: keyof typeof errorMessages;
      success?: keyof typeof successMessages;
    }>
  );

  const ssoEnabled: boolean = useSelector(hasSsoEnabled);

  return (
    <div className="application">
      <LoggedOutHeader />

      <div
        className="alert-container"
        style={{ top: "8vh" }}
        data-qa="alert-container"
      >
        {failure && (
          <div className="alert --danger">
            <IconAlertError className="ico" />
            {errorMessages[failure] || errorMessages["invalid-credentials"]}
          </div>
        )}

        {success && successMessages[success] && (
          <div className="alert --success">
            <IconAlertSuccess className="icon" />
            {successMessages[success]}
          </div>
        )}
      </div>

      <div className="loginPage">
        <div className="loginBox">
          <h1>Log in</h1>
          <form method="POST" action="/api/login" data-qa="login-form">
            <div className="inputBox">
              <label>E-mail</label>
              <input
                type="email"
                className="input"
                name="username"
                data-qa={FORM_LOCATORS.inputs.getInput("email")}
              />
            </div>
            <div className="inputBox">
              <label>Password</label>
              <input
                type="password"
                className="input"
                name="password"
                data-qa={FORM_LOCATORS.inputs.getInput("password")}
              />
            </div>
            <div className="row">
              <button
                type="button"
                className="link"
                onClick={gotoResetPassword}
                data-qa="forgot-password-link"
              >
                Forgot password?
              </button>
              <button
                type="submit"
                className="button"
                data-qa={FORM_LOCATORS.buttons.getButton("submit")}
              >
                Login
              </button>
            </div>
            {ssoEnabled && (
              <div className="row loginSSO">
                or sign in with SSO <SsoLoginButton />
              </div>
            )}
          </form>
          <p className="loginAlert" data-qa="unauthorized-access">
            Unauthorized access is prohibited
          </p>
        </div>
      </div>
    </div>
  );
};
