import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import {
  AutoSizer,
  ColumnSizer,
  Grid,
  InfiniteLoader
} from "react-virtualized";

import * as RouterSelectors from "../../../routing/routing-selectors";
import * as AssetLibrarySelectors from "../asset-library-selectors";
import { TextField } from "../../../forms/text-field";
import { UploadAssetConfirmationModal } from "./upload-asset-confirmation-modal";
import { UrlAssetModal, UrlAssetModalFormData } from "./url-asset-modal";
import { COLUMN_COUNT } from "../asset-library-saga";

import { ReactComponent as IconSearchBold } from "client/images/search-bold.svg";

import { AclResource } from "../../../../../shared/acl";
import { AssetLibraryActions } from "../asset-library-reducer";
import { UploadAsset } from "./upload-asset";
import { DraggableAsset } from "./asset-library-draggeble-asset";
import { useHasAccess } from "../../../auth/use-has-access";
import { getAssetTab, AssetTab } from "client/modules/utils/assetsTab";
import { SCENE_EDITOR_LOCATORS } from "shared/tests/locators/scene-editor.locators";

export const ASSET_LIBRARY_FORM_NAME = "asset-library";

// placeholder when grid is empty
const EmptyCmp = () => (
  <div
    className="asset-list-empty"
    data-qa={SCENE_EDITOR_LOCATORS.noMediaPlaceholder}
  >
    No Media
  </div>
);

// loading placeholder for the card when particular card is loading
const LoadingCardCmp = () => <div className="asset-loading">Loading...</div>;

const WallPreview = () => <div>TODO</div>;

const MIN_CARD_WIDTH = 150;
const MAX_CARD_WIDTH = 400;
const CARD_HEIGHT = 210;
const GRID_PADDING = 70;

const AssetLibraryMediaComponent = () => {
  const hasWallWriteAccess = useHasAccess(AclResource.WALL_WRITE);

  const records = useSelector(AssetLibrarySelectors.getRecords);
  const recordsValidityVersion = useSelector(
    AssetLibrarySelectors.getRecordsValidityVersion
  );
  const count = useSelector(AssetLibrarySelectors.getCount);
  const editingAsset = useSelector(AssetLibrarySelectors.getEditingAsset);
  const loading = useSelector(AssetLibrarySelectors.isLoading);
  const assetIndex = useSelector(AssetLibrarySelectors.getAssetIndex);
  const totalAssets = useSelector(AssetLibrarySelectors.getTotalAssets);
  const previewWall = useSelector(AssetLibrarySelectors.getPreviewWall);
  const routeName = useSelector(RouterSelectors.getRouteName) as string;

  const wallAssets =
    routeName.includes("backgrounds") || routeName.includes("transitions");

  const [urlAssetModal, setUrlAssetModal] = useState(false);

  const infiniteLoader = useRef<InfiniteLoader | null>(null);

  const dispatch = useDispatch();

  const fetchData = useCallback(
    (startIndex: number, stopIndex: number, done: () => void) => {
      dispatch(AssetLibraryActions.fetchData({ startIndex, stopIndex, done }));
    },
    [dispatch]
  );
  const onQueryChanged = useCallback(() => {
    dispatch(AssetLibraryActions.queryChanged());
  }, [dispatch]);
  const addUrlAsset = useCallback(
    (values: UrlAssetModalFormData) => {
      dispatch(AssetLibraryActions.addUrlAsset({ data: values }));
    },
    [dispatch]
  );

  const toggleUrlAssetModal = useCallback(
    (state: boolean) => () => {
      setUrlAssetModal(state);
    },
    []
  );

  const loadMoreRows = useCallback(
    ({ startIndex: startRowIndex, stopIndex: stopRowIndex }) => {
      const startIndex = (Math.max(startRowIndex, 1) - 1) * COLUMN_COUNT;
      const stopIndex = (stopRowIndex + 1) * COLUMN_COUNT;

      let done;
      const resolver = (res: (value: unknown) => void) => {
        done = res;
      };
      const promise = new Promise(resolver);

      // @ts-ignore It is captured and defined in resolver, tsc does not handle this well.
      fetchData(startIndex, stopIndex, done);
      return promise;
    },
    [fetchData]
  );

  const isRowLoaded = useCallback(
    ({ index }) => {
      const firstRecordIndex = index * COLUMN_COUNT;
      return (
        records[firstRecordIndex] &&
        records[firstRecordIndex].status === "LOADED"
      );
    },
    [records]
  );

  const onSectionRendered = useCallback(
    (onRowsRendered) => ({
      rowStartIndex,
      rowStopIndex
    }: {
      rowStartIndex: number;
      rowStopIndex: number;
    }) => {
      onRowsRendered({ startIndex: rowStartIndex, stopIndex: rowStopIndex });
    },
    []
  );

  const cellRenderer = useCallback(
    ({ style, columnIndex, rowIndex, key }) => {
      const index = rowIndex * COLUMN_COUNT + columnIndex;

      if (index >= count) {
        return false;
      }

      const record = records[index];
      return (
        <div style={style} key={key} className="assetWrapper">
          {record && (record.status === "LOADED" || record.temporaryAsset) ? (
            <DraggableAsset asset={record.asset || record.temporaryAsset} />
          ) : (
            <LoadingCardCmp />
          )}
        </div>
      );
    },
    [count, records]
  );

  useEffect(() => {
    if (infiniteLoader.current && count !== 0) {
      infiniteLoader.current.resetLoadMoreRowsCache(true);
    }
  }, [count, recordsValidityVersion]);

  const rowCount = Math.ceil(count / COLUMN_COUNT);

  const MEDIA_SEARCH_PLACEHOLDER = "Search in Media Library";
  const BACKGROUND_SEARCH_PLACEHOLDER = "Search in Backgrounds";
  const TRANSITION_SEARCH_PLACEHOLDER = "Search in Transitions";

  const [searchPlaceholder, setSearchPlaceholder] = useState(
    MEDIA_SEARCH_PLACEHOLDER
  );

  useEffect(() => {
    const tab = getAssetTab(routeName);
    let placeholder = MEDIA_SEARCH_PLACEHOLDER;
    switch (tab) {
      case AssetTab.BACKGROUNDS:
        placeholder = BACKGROUND_SEARCH_PLACEHOLDER;
        break;

      case AssetTab.TRANSITIONS:
        placeholder = TRANSITION_SEARCH_PLACEHOLDER;
        break;

      case AssetTab.SCENE_MEDIA:
      default:
        placeholder = MEDIA_SEARCH_PLACEHOLDER;
        break;
    }

    setSearchPlaceholder(placeholder);
  }, [routeName, setSearchPlaceholder]);

  const editingAssetTitle = editingAsset && editingAsset.title;
  const initialValues = useMemo(() => ({ title: editingAssetTitle }), [
    editingAssetTitle
  ]);

  return (
    <form>
      <div className="query">
        <span className="button">
          <IconSearchBold className="icon icon-search icon20" />
        </span>
        <TextField
          name="query"
          placeholder={searchPlaceholder}
          dataQa={SCENE_EDITOR_LOCATORS.search}
          onChange={() => onQueryChanged()}
        />
      </div>
      <div className="asset-list-wrapper">
        {loading && <div className="asset-list-loading">Loading...</div>}
        {!loading && (
          /* key={routeName} helps to reset and properly load data whenever
           *assets/backgrounds/transitions tab is switched
           */
          <InfiniteLoader
            key={routeName}
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
            minimumBatchSize={10}
            ref={(el) => {
              infiniteLoader.current = el;
            }}
          >
            {({ onRowsRendered, registerChild: registerChildIL }) => {
              return (
                <AutoSizer>
                  {({ width, height }: { width: number; height: number }) => (
                    <ColumnSizer
                      columnMaxWidth={MAX_CARD_WIDTH}
                      columnMinWidth={MIN_CARD_WIDTH}
                      columnCount={COLUMN_COUNT}
                      width={width - GRID_PADDING}
                    >
                      {({
                        adjustedWidth,
                        getColumnWidth,
                        registerChild: registerChildCS
                      }) => (
                        <Grid
                          ref={(el) => {
                            registerChildCS(el);
                            registerChildIL(el);
                          }}
                          height={height - GRID_PADDING}
                          overscanRowCount={10}
                          overscanColumnCount={10}
                          onSectionRendered={onSectionRendered(onRowsRendered)}
                          noContentRenderer={EmptyCmp}
                          columnWidth={getColumnWidth}
                          columnCount={COLUMN_COUNT}
                          rowHeight={CARD_HEIGHT}
                          rowCount={rowCount}
                          cellRenderer={cellRenderer}
                          width={adjustedWidth}
                        />
                      )}
                    </ColumnSizer>
                  )}
                </AutoSizer>
              );
            }}
          </InfiniteLoader>
        )}
      </div>
      {(!wallAssets || hasWallWriteAccess) && (
        <UploadAsset
          wallAssets={wallAssets}
          onAddUrlAsset={toggleUrlAssetModal(true)}
        />
      )}
      {editingAsset && totalAssets && (
        <UploadAssetConfirmationModal
          initialValues={initialValues}
          uuid={editingAsset.uuid}
          type={editingAsset.type}
          fileVersion={editingAsset.fileVersion}
          assetIndex={assetIndex as number}
          totalAssets={totalAssets}
        />
      )}
      {urlAssetModal && (
        <UrlAssetModal
          onModalConfirmed={(values) => {
            addUrlAsset(values);
            toggleUrlAssetModal(false)();
          }}
          onModalCanceled={toggleUrlAssetModal(false)}
        />
      )}
      {previewWall && <WallPreview />}
    </form>
  );
};

export const AssetLibraryMedia = reduxForm({
  form: ASSET_LIBRARY_FORM_NAME
})(AssetLibraryMediaComponent);
