import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  getPresentationCollaborators,
  getPresentationId,
  getPresentationCreator,
  getPresentationInitialValues
} from "../../presentation-selectors";
import { Avatar } from "../../../settings/users/components/avatar";
import { useCollaboratorsDialog } from "client/modules/dialogs/dialog-hooks";
import { UserSafe } from "shared/types/user";
import { useUpdateCollaborators } from "../../hooks/use-update-collaborators";
import { DialogResult } from "client/modules/dialogs/event-types";
import { Hint } from "../../../scene-editor/components/ux/hint";

export const PresentationCollaborate = () => {
  const collaborators: UserSafe[] | null = useSelector(
    getPresentationCollaborators
  );
  const creatorId: number | null = useSelector(getPresentationCreator);
  const presentationId: number = useSelector(getPresentationId);
  const presentation: any = useSelector(getPresentationInitialValues);

  const updateCollaborators = useUpdateCollaborators(
    presentationId,
    presentation
  );

  const collaboratorsDialog = useCollaboratorsDialog();
  const openCollaborate = useCallback(async () => {
    if (creatorId && collaborators) {
      const dialogResult = await collaboratorsDialog({
        collaborators,
        creatorId
      });
      if (dialogResult.type === DialogResult.COLLABORATORS_SELECTED) {
        updateCollaborators(collaborators, dialogResult.payload as UserSafe[]);
      }
    }
  }, [collaboratorsDialog, updateCollaborators, collaborators, creatorId]);

  // TODO: UI - we should likely add some kind of limitation for users
  // also names should show in tooltip i suppose
  return (
    <>
      {collaborators &&
        collaborators
          .filter((collaborator) => collaborator.id !== creatorId)
          .map((collaborator) => (
            <Hint
              key={collaborator.id}
              label={`${collaborator.firstName} ${collaborator.lastName}`}
              placement="bottom"
            >
              <Avatar
                avatar={collaborator.avatar}
                firstName={collaborator.firstName}
                lastName={collaborator.lastName}
                style={{ marginLeft: 2 }}
              />
            </Hint>
          ))}
      <button
        className="button"
        style={{ marginLeft: 10 }}
        onClick={openCollaborate}
        data-qa="presentation-collaborate-button"
      >
        Collaborate
      </button>
    </>
  );
};
