import { EditorSettingActions } from "./../editor-settings-reducer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Logger } from "purplex-logging";

import { useApi } from "./../../../api/use-api";
export const useFetchEditorSettings = () => {
  const api = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.getEditorSettings();
        dispatch(EditorSettingActions.onEditorSettingsFetched(data));
        Logger.debug("Editor settings fetched.");
      } catch (e) {
        Logger.error("Error while fetching editor settings", e);
      }
    })();
  }, [api, dispatch]);
};
