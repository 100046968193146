import React from "react";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

import { DialogEvent } from "../event-types";
import { ModalDialogBase } from "./modal-dialog-base";

export const MessageDialog = (props: {
  open: boolean;
  title: string;
  text: string;
  subtext: string;
  confirmLabel: string;
  onEvent: (type: DialogEvent, typePayload?: any) => void;
}) => {
  const { open, title, text, subtext, onEvent, confirmLabel } = props;

  return (
    <ModalDialogBase
      open={open}
      title={title}
      text={text}
      subtext={subtext}
      onEvent={onEvent}
      buttons={
        <>
          <button
            className="button"
            onClick={() => onEvent(DialogEvent.ON_CONFIRM)}
            data-qa={MODAL_LOCATORS.buttons.confirm}
          >
            {confirmLabel || "Ok"}
          </button>
        </>
      }
    />
  );
};
