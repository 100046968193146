import React from "react";

import { SceneView } from "../scene-model/scene-view";
import { PreviewWallNavigation } from "../preview-wall-navigation";
import { PreviewToggleAndInstanceSelector } from "./preview-toggle-and-instance-selector";

export const SceneEditorPreview = () => {
  return (
    <div className="content">
      <div className="main-panel">
        <SceneView isInPreviewMode={true}>
          <PreviewWallNavigation />
          <PreviewToggleAndInstanceSelector />
        </SceneView>
      </div>
    </div>
  );
};
