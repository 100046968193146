import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { getInstanceConfigs } from "../api/client";
import { AsyncSelectField } from "./async-select-field";

const getInstanceUuid = ({ uuid }) => uuid;
const getInstanceName = ({ name }) => name;

const useInstances = (filteredInstances) => {
  const [instances, setInstances] = useState([]);
  const [shouldLoad, setShouldLoad] = useState(true);

  useEffect(() => {
    if (shouldLoad) {
      setShouldLoad(false);
      getInstanceConfigs()
        .then((loadedInstances) => {
          const newInstances = loadedInstances.filter((instance) => {
            if (!filteredInstances) {
              return true;
            }

            return filteredInstances.includes(instance.uuid);
          });
          setInstances(newInstances);
        })
        .catch((e) => console.warn("Load instances failed", e)); // eslint-disable-line no-console
    }
  }, [shouldLoad, filteredInstances]);

  return instances;
};

const uuidToEntity = (uuid, instances) => {
  const loadedInstance = instances.find((i) => i.uuid === uuid);
  return loadedInstance || { uuid, name: uuid };
};

const formatFactory = (instances, isMulti) => (normalizedData) => {
  if (isMulti) {
    const uuids = normalizedData;

    if (!uuids) {
      return [];
    }

    return uuids.map((uuid) => uuidToEntity(uuid, instances));
  } else {
    const uuid = normalizedData;

    if (!uuid) {
      return null;
    }

    return uuidToEntity(uuid, instances);
  }
};

const normalizeFactory = (isMulti) => (inputData) => {
  if (isMulti) {
    const rooms = inputData;
    return (rooms || []).map(({ uuid }) => uuid);
  } else {
    const room = inputData;
    return room ? room.uuid : null;
  }
};

export const SelectRoomField = ({
  name,
  help,
  label,
  required,
  isClearable,
  isMulti,
  filteredInstances,
  dataQa,
  ...rest
}) => {
  const instances = useInstances(filteredInstances);
  const format = useCallback(formatFactory(instances, isMulti), [
    instances,
    isMulti
  ]);
  const normalize = useCallback(normalizeFactory(isMulti), [isMulti]);

  return (
    <AsyncSelectField
      name={name}
      async={false}
      required={required}
      isMulti={isMulti}
      label={label}
      help={help}
      isClearable={isClearable}
      options={instances}
      getOptionValue={getInstanceUuid}
      getOptionLabel={getInstanceName}
      {...rest}
      format={format}
      normalize={normalize}
      dataQa={dataQa}
    />
  );
};

SelectRoomField.propTypes = {
  name: PropTypes.string.isRequired,
  help: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool.isRequired,
  filteredInstances: PropTypes.arrayOf(PropTypes.string),
  dataQa: PropTypes.string
};
