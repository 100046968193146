import React from "react";
import { connect } from "react-redux";
import { getAcl } from "../config/config-selectors";
import { getRoles } from "../auth/auth-selectors";
import { hasAccess } from "../../../shared/acl";

const mapStateToProps = (state) => ({
  acl: getAcl(state),
  roles: getRoles(state)
});

export const withAcl = (Cmp, componentResource) =>
  connect(mapStateToProps)(({ acl, roles, ...rest }) => {
    if (componentResource && !hasAccess(acl, componentResource, roles)) {
      return false;
    }

    return (
      <Cmp
        {...rest}
        hasAccess={(resource) => hasAccess(acl, resource, roles)}
      />
    );
  });
