import { createReducer } from "reduxsauce";

import Actions from "./context-menu-actions";

const INITIAL_STATE = {
  lastOpenedContextMenuId: null
};

const setOpenedContextMenu = (state, { contextMenuId }) => ({
  ...state,
  lastOpenedContextMenuId: contextMenuId
});

export default createReducer(INITIAL_STATE, {
  [Actions.Types.SET_OPENED_CONTEXT_MENU]: setOpenedContextMenu
});
