import React from "react";
import cx from "classnames";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { TextField } from "../../forms/text-field";
import { DropdownField } from "../../forms/dropdown-field";
import { PasswordField } from "../../forms/password-field";
import Countries from "../../utils/countries";
import { getUser, getUserAvatar } from "../auth-selectors";
import { getMainRole } from "../../utils/roles";
import { formatBytes } from "../../utils";
import { getRoleLabelFactory } from "../../config/config-selectors";
import { getProgressSelector } from "../../in-progress/in-progress-selectors";
import Actions from "../auth-actions";
import { AvatarField } from "./avatar-field";
import usersValidate from "../../settings/users/users-validate";
import { passwordNormalizer } from "../../settings/users/components/users/users-form";
import { useHasAccess } from "../use-has-access";
import { AclResource } from "../../../../shared/acl";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";
import VersionTag from "../../config/version-tag";

const MAX_AVATAR_SIZE = 1000 * 1024;

const validate = (values) => {
  const errors = usersValidate(values);

  if (values.avatar && values.avatar.rejected) {
    errors.avatar = `Provided file can't be accepted. Supported format is jpg or png in a size not exceeding ${formatBytes(
      MAX_AVATAR_SIZE
    )}`;
  }

  return errors;
};

const goBack = () => window.history.back();

const UserProfileImpl = ({
  role,
  getRoleLabel,
  avatar,
  initialValues: { email, ssoProvider },
  handleSubmit,
  save,
  saveInProgress
}) => {
  const canChangeProfileCountry = useHasAccess(
    AclResource.CHANGE_PROFILE_COUNTRY
  );

  const ssoUser = ssoProvider !== null;

  return (
    <>
      <header className="pageHeader">
        <h1 className="pageHeading">Edit Profile</h1>
      </header>

      <div className="pageContent">
        <div className="editProfile">
          <form
            onSubmit={handleSubmit((data) => {
              save(passwordNormalizer(data));
            })}
            data-qa="form-user-profile"
          >
            <div className="item">
              <div className="label">Email</div>
              <span data-qa="span-email">{email}</span>
            </div>
            <TextField
              name="firstName"
              label="First Name"
              required
              dataQa={FORM_LOCATORS.inputs.getInput("first-name")}
            />
            <TextField
              name="lastName"
              label="Last Name"
              required
              dataQa={FORM_LOCATORS.inputs.getInput("last-name")}
            />
            <div className="contactBox">
              <TextField
                name="phone"
                label="Phone"
                dataQa={FORM_LOCATORS.inputs.getInput("phone")}
              />
              {canChangeProfileCountry && (
                <DropdownField
                  name="country"
                  label="Country"
                  dataQa={FORM_LOCATORS.select.getSelect("country")}
                >
                  <option value="">-</option>
                  {Countries.map(({ country_name, country_code }, index) => (
                    <option value={country_code} key={index}>
                      {country_name}
                    </option>
                  ))}
                </DropdownField>
              )}
            </div>
            <div className="item">
              <span className="label">Avatar</span>
            </div>
            <div className="photoBox">
              <AvatarField
                className="avatar"
                avatar={avatar}
                maxSize={MAX_AVATAR_SIZE}
              />
              <div className="item">
                <p>(maximum file size 5 MB, allowed types: png and jpg)</p>
              </div>
            </div>
            <div>Role: {getRoleLabel(role)}</div>
            {!ssoUser && (
              <div>
                <PasswordField
                  name="password"
                  type="password"
                  label="Password"
                  dataQa={FORM_LOCATORS.inputs.getInput("password")}
                />
                <TextField
                  name="passwordConfirm"
                  type="password"
                  label="Password Confirm"
                  dataQa={FORM_LOCATORS.inputs.getInput("confirm-password")}
                />
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="button buttonOutlined"
                type="button"
                onClick={goBack}
              >
                Cancel
              </button>
              <button
                className={cx("button", {
                  inProgress: saveInProgress
                })}
                type="submit"
                data-qa={FORM_LOCATORS.buttons.getButton("submit")}
              >
                Save
              </button>
            </div>
          </form>

          <VersionTag />
        </div>
      </div>
    </>
  );
};

UserProfileImpl.propTypes = {
  role: PropTypes.string.isRequired,
  getRoleLabel: PropTypes.func.isRequired,
  avatar: PropTypes.string,
  initialValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
    ssoProvider: PropTypes.string
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  saveInProgress: PropTypes.bool.isRequired
};

const mapStatToProps = (state) => {
  const user = getUser(state);

  return {
    initialValues: user,
    role: getMainRole(user),
    getRoleLabel: getRoleLabelFactory(state),
    avatar: getUserAvatar(state),
    saveInProgress: getProgressSelector(state)(Actions.Types.SAVE_USER_PROFILE)
  };
};

const mapDispatchToProps = {
  save: Actions.Creators.saveUserProfile
};

export const UserProfile = connect(
  mapStatToProps,
  mapDispatchToProps
)(reduxForm({ form: "user-profile", validate })(UserProfileImpl));
