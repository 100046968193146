export const AUTH_LOGIN_ROUTE = "login";
export const AUTH_SIGN_UP_ROUTE = "sign-up";
export const AUTH_RESET_PASSWORD_ROUTE = "reset-password";
export const AUTH_RESET_PASSWORD_SEND_ROUTE = "reset-password.request-send";
export const AUTH_NEW_PASSWORD_ROUTE = "new-password";
export const AUTH_NEW_PASSWORD_DONE_ROUTE = "new-password.done";
export const AUTH_USER_PROFILE_ROUTE = "user-profile";

export const DASHBOARD_ROUTE = "dashboard";

export const PRESENTATIONS_ROUTE = "presentations";
export const PRESENTATIONS_EDIT_ROUTE = "presentations.edit";
export const PRESENTATIONS_LIST_ROUTE = "presentations.list";

export const ASSETS_ROUTE = "assets";
export const ASSETS_LIST_ROUTE = "assets.list";

export const WALLS_ROUTE = "walls";
export const WALLS_LIST_ROUTE = "walls.list";

export const SCENES_ROUTE = "scenes";
export const SCENES_LIST_ROUTE = "scenes.list";
export const SCENES_LIST_EDIT = "scenes.list.edit";
export const SCENES_EDITOR_ROUTE = "scenes.list.edit.editor";

export const SETTINGS_ROUTE = "settings";

export const USERS_ROUTE = `${SETTINGS_ROUTE}.users`;
export const USERS_ADD_ROUTE = `${USERS_ROUTE}.add`;
export const USERS_EDIT_ROUTE = `${USERS_ROUTE}.edit`;

export const DOMAINS_ROUTE = `${SETTINGS_ROUTE}.domains`;
export const DOMAINS_ADD_ROUTE = `${DOMAINS_ROUTE}.add`;
export const DOMAINS_EDIT_ROUTE = `${DOMAINS_ROUTE}.edit`;

export const CUSTOM_FIELDS_COMPANY_LIBRARY_ROUTE = `${SETTINGS_ROUTE}.company-library`;
export const CUSTOM_FIELDS_PRESENTATIONS_ROUTE = `${SETTINGS_ROUTE}.presentations`;

export const PREDEFINED_SCENE_NAMES_ROUTE = `${SETTINGS_ROUTE}.predefined-scene-names`;

export const TRIGGERS_ROUTE = `${SETTINGS_ROUTE}.triggers`;
export const TRIGGERS_LIST_ROUTE = `${TRIGGERS_ROUTE}.list`;
export const TRIGGERS_CREATE_ROUTE = `${TRIGGERS_LIST_ROUTE}.add-trigger`;
export const TRIGGERS_EDIT_ROUTE = `${TRIGGERS_LIST_ROUTE}.edit-trigger`;

export const EDITOR_SETTINGS_ROUTE = `${SETTINGS_ROUTE}.editor-settings`;
