import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { reduxForm, submit } from "redux-form";

import { SelectUserField } from "../../settings/users/components/select-user-field";
import { ModalDialogBase } from "./modal-dialog-base";
import { DialogResult } from "../event-types";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

const FORM_NAME = "user-picker-form";

interface FormData {
  user: { id: string };
}

interface FormProps {
  fieldLabel: string;
  resourceAccess: string;
  onEvent: (type: DialogResult, payload: string) => void;
}

const validate = (values: FormData) => {
  const errors: Partial<Record<keyof FormData, string>> = {};

  if (!values.user) {
    errors.user = "Please, select some user";
  }

  return errors;
};

const UserPickerDialogContent = reduxForm<FormData, FormProps>({
  form: FORM_NAME,
  validate,
  onSubmit: (values, _, props) => {
    props.onEvent(DialogResult.USER_SELECTED, values.user.id);
  }
})(({ fieldLabel, resourceAccess, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <SelectUserField
      name="user"
      label={fieldLabel}
      isMulti={false}
      resourceAccess={resourceAccess}
      dataQa={FORM_LOCATORS.inputs.getInput("select-user")}
    />
  </form>
));

export const UserPickerDialog = (props: {
  open: boolean;
  resourceAccess: string;
  title: string;
  fieldLabel: string;
  onEvent: (type: DialogResult, payload: string) => void;
}) => {
  const { open, resourceAccess, title, fieldLabel, onEvent } = props;
  const dispatch = useDispatch();

  const save = useCallback(() => {
    dispatch(submit(FORM_NAME));
  }, [dispatch]);

  return (
    <ModalDialogBase
      open={open}
      title={title}
      className="modalOverflowVisible"
      text={
        <UserPickerDialogContent
          resourceAccess={resourceAccess}
          fieldLabel={fieldLabel}
          onEvent={onEvent}
        />
      }
      onEvent={onEvent}
      buttons={
        <React.Fragment>
          <button
            className="button"
            onClick={save}
            data-qa={MODAL_LOCATORS.buttons.save}
          >
            Share
          </button>
        </React.Fragment>
      }
    />
  );
};
