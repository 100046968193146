import React from "react";
import { ReactComponent as IconAudio } from "client/images/assets/audio.svg";

export const AssetSound = (props: {
  glow: boolean;
  rounded: boolean;
  width: number;
  height: number;
}) => {
  const { glow, rounded, width, height } = props;
  return (
    <div
      className="placeholder-sound"
      style={{
        borderRadius: rounded ? "20px" : 0,
        boxShadow: glow ? "0 0 50px #fff" : "",
        width,
        height
      }}
    >
      <IconAudio className="icon icon-audio icon96" />
    </div>
  );
};
