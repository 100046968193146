import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getPreviewInstance,
  getSortedInstances,
  getOriginalInstance
} from "../../scene-editor-selectors";

import { ReactComponent as IconFullscreen } from "client/images/fullscreen.svg";
import { ReactComponent as IconFullscreenExit } from "client/images/fullscreen-exit.svg";
import { ReactComponent as IconArrowDown } from "client/images/arrow-down.svg";
import { SceneEditorActions } from "../../scene-editor-reducer";

export const PreviewToggleAndInstanceSelector = () => {
  const [opened, setOpened] = useState(false);

  const previewInstance = useSelector(getPreviewInstance);
  const originalInstance = useSelector(getOriginalInstance);
  const instances = useSelector(getSortedInstances);

  const dispatch = useDispatch();

  const onSetPreviewInstance = useCallback(
    (uuid: string | null) => {
      dispatch(SceneEditorActions.setPreviewInstance({ instanceUuid: uuid }));
    },
    [dispatch]
  );

  const toggle = useCallback(() => {
    setOpened((state) => !state);
  }, []);

  const onSelectInstance = useCallback(
    (uuid: string | null) => {
      toggle();
      onSetPreviewInstance(uuid);
    },
    [toggle, onSetPreviewInstance]
  );

  const onExitPreview = useCallback(() => {
    setOpened(false);
    onSetPreviewInstance(null);
  }, [onSetPreviewInstance]);

  const selection = (
    <div className="list">
      {instances.map(({ uuid, name }) => (
        <button
          key={uuid}
          className={
            previewInstance && uuid === previewInstance.uuid ? "selected" : ""
          }
          onClick={() => onSelectInstance(uuid)}
        >
          {name}
        </button>
      ))}
    </div>
  );

  if (!instances || instances.length < 1) {
    return <></>;
  }

  return (
    <div className="preview-instance-selector">
      {previewInstance && (
        <React.Fragment>
          <button
            className="button-instance-toggle"
            onClick={toggle}
            data-qa="btn-choose-instance"
          >
            {previewInstance.name}
            <IconArrowDown className="icon icon-arrow-down icon24" />
          </button>
          <button
            className="button-fullscreen-exit"
            onClick={onExitPreview}
            data-qa="btn-exit-fullscreen"
          >
            <IconFullscreenExit className="icon icon-fullscreen-exit icon24" />
          </button>
          {opened && selection}
        </React.Fragment>
      )}
      {!previewInstance && (
        <button
          className="button-fullscreen"
          onClick={() =>
            onSelectInstance(originalInstance && originalInstance.uuid)
          }
          title="Scene Preview"
          data-qa="btn-fullscreen"
        >
          <IconFullscreen className="icon icon-fullscreen icon24" />
        </button>
      )}
    </div>
  );
};
