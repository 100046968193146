import { Logger as logger } from "purplex-logging";
import { normalize, schema } from "normalizr";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { OutputSelector } from "reselect";

import {
  EntityRepositoryActions,
  EntityRepositoryHasChangedAction
} from "../entity-repository/entity-repository-reducer";
import { LongTask } from "./crud-types";
import {
  getRouteParams,
  RouteParamSelector
} from "../routing/routing-selectors";
import { ApiClient, useApi } from "../api/use-api";
import { RootState } from "../root/root-reducer";

export interface ApiDetailParameters {
  id: string | number;
}

export interface FetchRecordCommand<Entity> extends LongTask {
  record: Entity | null;
}

export type ApiExtractorGet<Entity> = (
  api: ApiClient
) => (data: ApiDetailParameters) => Promise<Entity>;

export function useFetchRecord<ApiResult, NormalizedEntity, MappedEntity>(
  entitySchema: schema.Entity<NormalizedEntity>,
  getExtractor: ApiExtractorGet<ApiResult>,
  entityRepositorySelector: OutputSelector<RootState, MappedEntity, unknown>
): FetchRecordCommand<MappedEntity> {
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{ id: number }>
  );
  const id = routeParams && routeParams.id;
  const [inProgress, setInProgress] = useState(false);

  const api = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) {
      logger.error("Missing id parameter, aborting...");
      return;
    }
    (async () => {
      try {
        logger.debug("Fetching detail for entity");
        setInProgress(true);
        const apiCall = getExtractor(api);
        const data = await apiCall({ id });

        const { entities: repository } = normalize<
          NormalizedEntity,
          EntityRepositoryHasChangedAction,
          number
        >(data, entitySchema);

        dispatch(EntityRepositoryActions.repositoryHasChanged({ repository }));
      } catch (ex) {
        logger.error(
          "There was an error during fetching initial values for entity - ",
          ex
        );
      } finally {
        setInProgress(false);
      }
    })();
  }, [id, api, entitySchema, dispatch, getExtractor]);

  const mappedResult = useSelector(entityRepositorySelector);

  return useMemo(() => {
    return { record: mappedResult, inProgress };
  }, [mappedResult, inProgress]);
}
