import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import cx from "classnames";

export const CheckboxField = (props) => {
  const { label, title, dataQa, ...otherProps } = props;
  return (
    <>
      <label
        title={title}
        className={cx("checkbox", { "is-disabled": props.disabled })}
        data-qa={dataQa}
      >
        <span className="checkbox__control">
          <Field component="input" type="checkbox" {...otherProps} />
          <span className="checkbox__shape" />
        </span>
        {label && <span>{label}</span>}
      </label>
    </>
  );
};

CheckboxField.propTypes = {
  dataQa: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
};
