import React from "react";
import PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";

import * as Selectors from "../../scene-editor-selectors";
import { NormalizedNumberInput, ensureRange } from "../ux/number-input";
import { SceneEditorActions } from "../../scene-editor-reducer";

const { AssetType } = require("../../../../../server/models/enums/asset-type");

export const FORM_NAME = "transitionSettingForm";

const MAX_TRANSITION_MS = 60 * 1000; // one minute

const TransitionSettingForm = ({
  asset: { playAs },
  transitionUseWholeVideo
}) => {
  return (
    <form className="config">
      {playAs === AssetType.ASSET_WALL_VIDEO && (
        <>
          <div>
            <h3>Loop</h3>
            <label className="switch" data-qa="transition-loop">
              <Field component="input" type="checkbox" name="transitionLoop" />
              <i className="handle" />
              <span className="label-on">Yes</span>
              <span className="label-off">No</span>
            </label>
          </div>
          <div>
            <h3>Use whole video length</h3>
            <label className="switch" data-qa="transition-length">
              <Field
                component="input"
                type="checkbox"
                name="transitionUseWholeVideo"
              />
              <i className="handle" />
              <span className="label-on">Yes</span>
              <span className="label-off">No</span>
            </label>
          </div>
        </>
      )}
      {!transitionUseWholeVideo && (
        <div>
          <h3>Fade duration</h3>
          <label data-qa="transition-fade">
            <Field
              component={NormalizedNumberInput}
              name="transitionFadeMs"
              props={{
                normalize: ensureRange(500, MAX_TRANSITION_MS),
                disabled: transitionUseWholeVideo
              }}
            />
            ms
          </label>
        </div>
      )}
    </form>
  );
};

TransitionSettingForm.propTypes = {
  asset: PropTypes.shape({
    playAs: PropTypes.string.isRequired
  }),
  transitionUseWholeVideo: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  onChange: SceneEditorActions.transitionSettingFormHasChanged
};

const mapStateToProps = (state) => ({
  initialValues: Selectors.getTransitionSettingFormInitialValues(state),
  transitionUseWholeVideo: formValueSelector(FORM_NAME)(
    state,
    "transitionUseWholeVideo"
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME
  })(TransitionSettingForm)
);
