import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { reduxForm } from "redux-form";

import { isEmailValid } from "../../utils";
import { LoggedOutHeader } from "./logged-out-header";
import * as RoutingSelectors from "../../routing/routing-selectors";
import AuthActions from "../auth-actions";
import { TextField } from "../../forms/text-field";
import { getProgressSelector } from "../../in-progress/in-progress-selectors";
import { AUTH_RESET_PASSWORD_SEND_ROUTE } from "../../routing/route-names";
import { getPasswordResetEmail } from "../auth-selectors";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

const ResetForm = connect(
  (state: any) => ({
    sendInProgress: getProgressSelector(state)(
      AuthActions.Types.SEND_PASSWORD_RESET_TOKEN
    )
  }),
  {
    sendPasswordResetToken: AuthActions.Creators.sendPasswordResetToken
  }
)(
  reduxForm<
    { email?: string },
    { sendPasswordResetToken: Function; sendInProgress: boolean }
  >({
    form: "AUTH_RESET_PASSWORD",
    validate: ({ email }) => {
      if (!isEmailValid(email)) {
        return {
          email: "E-mail is not valid"
        };
      }

      return {};
    }
  })(
    ({
      anyTouched,
      invalid,
      handleSubmit,
      sendPasswordResetToken,
      sendInProgress
    }) => {
      return (
        <form
          onSubmit={handleSubmit(({ email }) => sendPasswordResetToken(email))}
        >
          <TextField
            name="email"
            type="email"
            label="E-mail"
            dataQa={FORM_LOCATORS.inputs.getInput("email")}
          />
          <button
            disabled={sendInProgress || (anyTouched && invalid)}
            className={cx("button", {
              inProgress: sendInProgress
            })}
            type="submit"
            data-qa={FORM_LOCATORS.buttons.getButton("submit")}
          >
            Send Reset Link
          </button>
        </form>
      );
    }
  )
);

const AuthResetPasswordImpl = ({
  routeName,
  sendToEmail
}: {
  routeName: string;
  sendToEmail: string | null;
}) => (
  <div className="application">
    <LoggedOutHeader />
    <div className="loginPage">
      <div className="loginBox">
        <h1>Reset Password</h1>
        {routeName === AUTH_RESET_PASSWORD_SEND_ROUTE ? (
          <>
            <p>
              Email has been sent to <strong>{sendToEmail}</strong>
            </p>
            <p>Please check your mailbox for instructions.</p>
          </>
        ) : (
          <ResetForm />
        )}
      </div>
    </div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthResetPassword = connect((state: any) => {
  return {
    routeName: RoutingSelectors.getRouteName(state) as string,
    sendToEmail: getPasswordResetEmail(state) as string | null
  };
})(AuthResetPasswordImpl);
