import { createSelector } from "reselect";
import { getEntityRepository as getState } from "../root/root-selectors";
import { getCdnHost } from "../config/config-selectors";
import {
  AssetViewModel,
  CustomFieldHierarchyViewModel,
  CustomFieldToEntityMappingViewModel,
  CustomFieldViewModel,
  PresentationSceneViewModel,
  PresentationViewModel,
  SceneAssetViewModel,
  SceneViewModel
} from "./entity.types";

const {
  CustomFieldEntity
} = require("../../../shared/types/custom-field-entity");

export const customFieldPresentationPredicate = (
  customField: CustomFieldViewModel
) =>
  customField.entityMappings
    .map((entityMapping) => entityMapping.entity)
    .includes(CustomFieldEntity.PRESENTATION);

export const getUsers = createSelector(getState, (state) => state.users);

export const getInstances = createSelector(
  getState,
  (state) => state.instances
);

export const getOrderedInstances = createSelector(getInstances, (instances) =>
  Object.values(instances).sort(({ name }, { name: name2 }) =>
    String(name).localeCompare(String(name2))
  )
);

export const getAssets = createSelector(
  getState,
  getCdnHost,
  (state, cdnHost) =>
    Object.keys(state.assets).reduce<{ [key: string]: AssetViewModel }>(
      (memo, assetId) => {
        const asset = state.assets[assetId];

        memo[assetId] = {
          ...asset,
          alternativeAssets: asset.alternativeAssets.map(
            (uuid) => state.assets[uuid]
          ),
          thumbnail: `${cdnHost}/assets/${asset.fileName}_thumb.jpg`
        };

        return memo;
      },
      {}
    )
);

export const getSceneAssets = createSelector(
  getState,
  getAssets,
  (state, assets) =>
    Object.keys(state.sceneassets).reduce<{
      [key: string]: SceneAssetViewModel;
    }>((memo, sceneAssetId) => {
      const sceneAsset = state.sceneassets[sceneAssetId];

      memo[sceneAssetId] = {
        ...sceneAsset,
        asset: assets[sceneAsset.asset]
      };

      return memo;
    }, {})
);

export const getScenes = createSelector(
  getState,
  getAssets,
  getSceneAssets,
  getInstances,
  (state, assets, sceneAssets, instances) =>
    Object.keys(state.scenes).reduce<{ [key: string]: SceneViewModel }>(
      (memo, sceneId) => {
        const scene = state.scenes[sceneId];
        const instance = instances[scene.instanceUuid] || null;

        if (!instance) {
          // TODO:
          // logger.warn(
          //   `Scene id=${sceneId} has assigned instanceUuid=${
          //     scene.instanceUuid
          //   } but instance with this uuid doesn't exist.`
          // );
        }

        memo[sceneId] = {
          ...scene,
          instance,
          sceneAssets: (scene.sceneAssets || []).map(
            (sceneAssetId) => sceneAssets[sceneAssetId]
          ),
          wall: scene.wall ? assets[scene.wall] : null
        };

        return memo;
      },
      {}
    )
);

export const getPresentationScenes = createSelector(
  getState,
  getScenes,
  (state, scenes) =>
    Object.keys(state.presentationscenes).reduce<{
      [key: string]: PresentationSceneViewModel;
    }>((memo, presentationSceneId) => {
      const presentationScene = state.presentationscenes[presentationSceneId];
      memo[presentationSceneId] = {
        ...presentationScene,
        scene: scenes[presentationScene.sceneId]
      };

      return memo;
    }, {})
);

export const getAssetActions = createSelector(
  getState,
  (state) => state.assetactions
);

export const getCustomFieldHierarchies = createSelector(getState, (state) => {
  return Object.keys(state.customFieldHierarchies).reduce<{
    [key: string]: CustomFieldHierarchyViewModel;
  }>((memo, hierarchyId) => {
    const hierarchy = state.customFieldHierarchies[hierarchyId];
    const customFieldToEntityMapping =
      state.customFieldToEntityMappings[hierarchy.customFieldToEntityMappingId];
    const customField =
      state.customFields[customFieldToEntityMapping.customFieldId];

    memo[hierarchyId] = {
      ...hierarchy,
      customFieldToEntityMapping,
      customField
    };
    return memo;
  }, {});
});

export const getCustomFieldToEntityMappings = createSelector(
  getState,
  (state) =>
    Object.keys(state.customFieldToEntityMappings).reduce<{
      [key: string]: CustomFieldToEntityMappingViewModel;
    }>((memo, mappingId) => {
      const mapping = state.customFieldToEntityMappings[mappingId];

      memo[mappingId] = {
        ...mapping,
        customField: state.customFields[mapping.customFieldId],
        customFieldHierarchies: mapping.customFieldHierarchies.map(
          (id) => state.customFieldHierarchies[id]
        )
      };
      return memo;
    }, {})
);

export const getCustomFields = createSelector(
  getState,
  getCustomFieldToEntityMappings,
  (state, customFieldToEntityMappings) =>
    Object.keys(state.customFields).reduce<{
      [key: string]: CustomFieldViewModel;
    }>((memo, customFieldId) => {
      const customField = state.customFields[customFieldId];
      memo[customFieldId] = {
        ...customField,
        entityMappings: customField.entityMappings.map(
          (mappingId) => customFieldToEntityMappings[mappingId]
        )
      };

      return memo;
    }, {})
);

export const getPresentations = createSelector(
  getState,
  getPresentationScenes,
  getAssetActions,
  getCustomFields,
  (state, presentationScenes, assetActions, customFieldsRepository) =>
    Object.keys(state.presentations).reduce<{
      [key: string]: PresentationViewModel;
    }>((memo, presentationId) => {
      const presentation = state.presentations[presentationId];

      // Find all the custom fields which are not present
      // in the entity (eg. old presentation without new fields sent from backend)
      const missingCustomFields = Object.keys(customFieldsRepository).filter(
        (customFieldId) => {
          const customField = customFieldsRepository[customFieldId];

          return (
            customFieldPresentationPredicate(customField) &&
            !presentation.customFields
              .map(
                (presentationCustomField) =>
                  presentationCustomField.customFieldId
              )
              .includes(`${customField.id}`)
          );
        }
      );

      memo[presentationId] = {
        ...presentation,
        presentationScenes: presentation.presentationScenes.map(
          (presentationSceneId) => presentationScenes[presentationSceneId]
        ),
        assetActions: presentation.assetActions.map(
          (assetActionId) => assetActions[assetActionId]
        ),
        customFields: [
          ...presentation.customFields,
          // Add missing custom fields as empty values
          ...missingCustomFields.map((customFieldId) => ({
            id: null,
            value: null,
            type: customFieldsRepository[customFieldId].type,
            customFieldId: customFieldId
          }))
        ]
      };

      return memo;
    }, {})
);

export const getPredefinedScenes = createSelector(
  getState,
  (state) => state.predefinedscenes
);

export const getGuestDomains = createSelector(
  getState,
  (state) => state.guestDomains
);

export const getTriggers = createSelector(getState, (state) => state.triggers);
