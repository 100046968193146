import React from "react";
import { createPortal } from "react-dom";
import { AutoSizer } from "react-virtualized";

import { EditBackgroundSceneView } from "./edit-background-scene-view";
import { EditBackgroundControls } from "./edit-background-controls";
import { useRenderCount } from "../../../utils";
import { useEditBackgroundActions } from "../../../hooks/edit-background/use-edit-background-actions";

const EditBackgroundModal = (props: { onCloseModal: () => void }) => {
  const { onCloseModal } = props;

  const renderCount = useRenderCount();

  const {
    updateBackground,
    fitToWidth,
    fitToHeight,
    toggleRatio,
    ratioLocked,
    resetBackground
  } = useEditBackgroundActions();

  return (
    <section className="background-editor">
      {renderCount}
      <EditBackgroundControls
        onCloseModal={onCloseModal}
        onFitToHeight={fitToHeight}
        onFitToWidth={fitToWidth}
        onResetToOriginal={resetBackground}
        onToggleRatio={toggleRatio}
        ratioLocked={ratioLocked}
        onUpdateBackground={updateBackground}
      />
      <div className="background-editor__body">
        <AutoSizer>
          {({ width, height }) => (
            <EditBackgroundSceneView
              width={width}
              height={height}
              onUpdateBackground={updateBackground}
              ratioLocked={ratioLocked}
            />
          )}
        </AutoSizer>
      </div>
    </section>
  );
};

export const EditBackground = (props: { onCloseModal: () => void }) => {
  const { onCloseModal } = props;

  return createPortal(
    <>
      <EditBackgroundModal onCloseModal={onCloseModal} />
    </>,
    document.getElementById("modal") as HTMLElement
  );
};
