import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import { AssetImage } from "./asset-image";
import { AssetSound } from "./asset-sound";
import { AssetUrl } from "./asset-url";
import { AssetPDF } from "./asset-pdf";
import { AssetPresentation } from "./asset-presentation";
import { getCdnHost } from "../../../config/config-selectors";
import { AssetType } from "../../../../../shared/types/asset-type";
import { useRenderCount } from "../../utils";
import { AssetText } from "./asset-text";
import { TextAssetMetadata } from "shared/types/asset";
import { useAssetDraggingTransformations } from "../../hooks/dragging/utils/use-asset-dragging-transformations";
import { useOnMouseDownDragStart } from "../../hooks/dragging/use-dragging";
import { ResizingTransformOrigin } from "../../hooks/dragging/use-resizing";

const getAssetUrl = ({
  type,
  cdnHost,
  uuid,
  fileVersion
}: {
  type: AssetType;
  cdnHost: string;
  uuid: string;
  fileVersion: number;
}) => {
  const baseUrl = `${cdnHost}/assets/${uuid}_${fileVersion}`;
  return type === AssetType.ASSET_IMAGE ? baseUrl : baseUrl + "_thumb_big.jpg";
};

//TODO fix any
export const Asset = React.memo(
  (props: {
    sceneAssetId: number;
    sceneAssetUuid: string;
    wallStart: number;
    type: AssetType;
    uuid: string;
    fileVersion: number;
    top: number;
    left: number;
    scale: number;
    width: number;
    height: number;
    cropHeight: number;
    cropWidth: number;
    cropOffsetX: number;
    cropOffsetY: number;
    canvasScale: number;
    layer: any;
    opacity: number;
    rounded: boolean;
    glow: boolean;
    selected: boolean;
    textMetadata?: TextAssetMetadata;
    selectMultipleAsset: any;
    selectAsset: any;
    enableDragging: boolean;
    dragOffsetX: number;
    dragOffsetY: number;
    scaleChangeOriginLeft: number;
    scaleChangeOriginTop: number;
    scaleChangeScale: number;
    cropChangeOffsetY: number;
    cropChangeOffsetX: number;
    cropChangeWidth: number;
    cropChangeHeight: number;
    resizeChangeOrigin: ResizingTransformOrigin;
    resizeChangeWidth: number;
    resizeChangeHeight: number;
  }) => {
    const {
      sceneAssetId,
      sceneAssetUuid,
      uuid,
      fileVersion,
      wallStart,
      type,
      left,
      top,
      scale,
      canvasScale,
      layer,
      opacity,
      selected,
      rounded,
      glow,
      textMetadata,
      cropOffsetX,
      width,
      height,
      cropWidth,
      cropHeight,
      cropOffsetY,
      selectAsset,
      selectMultipleAsset,
      enableDragging,
      dragOffsetX,
      dragOffsetY,
      scaleChangeScale,
      scaleChangeOriginLeft,
      scaleChangeOriginTop,
      cropChangeOffsetY,
      cropChangeOffsetX,
      cropChangeHeight,
      cropChangeWidth,
      resizeChangeHeight,
      resizeChangeOrigin,
      resizeChangeWidth
    } = props;

    const cdnHost = useSelector(getCdnHost);

    const transformedCoords = useAssetDraggingTransformations(
      type,
      selected,
      width,
      height,
      left,
      top,
      cropWidth,
      cropHeight,
      cropOffsetX,
      cropOffsetY,
      scale,
      wallStart,
      dragOffsetX,
      dragOffsetY,
      { left: scaleChangeOriginLeft, top: scaleChangeOriginTop },
      scaleChangeScale,
      cropChangeOffsetY,
      cropChangeOffsetX,
      cropChangeWidth,
      cropChangeHeight,
      resizeChangeOrigin,
      resizeChangeWidth,
      resizeChangeHeight
    );

    const renderAsset = useCallback(() => {
      switch (type) {
        case AssetType.ASSET_URL:
          return (
            <AssetUrl
              width={transformedCoords.cropWidth}
              height={transformedCoords.cropHeight}
              rounded={rounded}
              glow={glow}
            />
          );
        case AssetType.ASSET_IMAGE:
        case AssetType.ASSET_VIDEO:
          return (
            <AssetImage
              width={transformedCoords.width}
              height={transformedCoords.height}
              rounded={rounded}
              glow={glow}
              src={getAssetUrl({ type, cdnHost, uuid, fileVersion })}
              cropHeight={transformedCoords.cropHeight}
              cropWidth={transformedCoords.cropWidth}
              cropOffsetX={transformedCoords.cropOffsetX}
              cropOffsetY={transformedCoords.cropOffsetY}
            />
          );
        case AssetType.ASSET_PDF:
          return (
            <AssetPDF
              width={transformedCoords.cropWidth}
              height={transformedCoords.cropHeight}
            />
          );
        case AssetType.ASSET_PRESENTATION:
          return (
            <AssetPresentation
              glow={glow}
              rounded={rounded}
              width={transformedCoords.cropWidth}
              height={transformedCoords.cropHeight}
            />
          );
        case AssetType.ASSET_SOUND:
          return (
            <AssetSound
              glow={glow}
              rounded={rounded}
              width={transformedCoords.cropWidth}
              height={transformedCoords.cropHeight}
            />
          );
        case AssetType.ASSET_TEXT:
          return (
            <AssetText
              sceneAssetId={sceneAssetId}
              sceneAssetUuid={sceneAssetUuid}
              assetUuid={uuid}
              canvasScale={canvasScale}
              {...(textMetadata as TextAssetMetadata)}
              width={transformedCoords.cropWidth}
              height={transformedCoords.cropHeight}
            />
          );

        default:
          throw new Error(`Invalid asset type ${type}`);
      }
    }, [
      rounded,
      glow,
      type,
      uuid,
      sceneAssetId,
      textMetadata,
      transformedCoords,
      canvasScale,
      fileVersion,
      cdnHost,
      sceneAssetUuid
    ]);

    const renderCount = useRenderCount("Asset");

    const onMouseDownDragStart = useOnMouseDownDragStart();

    const onMouseDownHandler = useCallback(
      ($event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        $event.stopPropagation();
        if (enableDragging) {
          onMouseDownDragStart($event);
        }

        if (selected) {
          return;
        }

        if ($event.button === 0) {
          if (selected) {
            return;
          }
          if ($event.shiftKey) {
            selectMultipleAsset(sceneAssetUuid);
          } else {
            selectAsset(sceneAssetUuid);
          }
        }
      },
      [
        enableDragging,
        selected,
        selectMultipleAsset,
        selectAsset,
        onMouseDownDragStart,
        sceneAssetUuid
      ]
    );

    return (
      <div
        onMouseDown={onMouseDownHandler}
        className={cx({
          asset: true,
          selected,
          [type]: true
        })}
        style={{
          zIndex: layer,
          top: transformedCoords.top,
          left: transformedCoords.left,
          opacity: opacity / 100,
          position: "absolute"
        }}
      >
        {renderCount}
        {renderAsset()}
      </div>
    );
  }
);
