import { createSelector } from "reselect";

import { getAssetLibrary as getState } from "../../root/root-selectors";
import { getAssets as getERAssets } from "../../entity-repository/entity-repository-selectors";

export const getAssetIds = createSelector(getState, (state) => state.assets);

export const getRecords = createSelector(
  getState,
  getERAssets,
  (state, assets) => {
    const records = Object.keys(state.records).reduce<{ [key: string]: any }>(
      (memo, index) => {
        const { asset: assetId, status } = state.records[index];
        memo[index] = {
          asset: assets[assetId],
          status
        };

        return memo;
      },
      {}
    );

    Object.keys(state.temporaryRecords).reduce<{ [key: string]: any }>(
      (memo, index) => {
        const temporaryRecord = state.temporaryRecords[index];
        const record = state.records[index];
        if (
          (!record || record.status !== "LOADED") &&
          temporaryRecord.status === "LOADED"
        ) {
          memo[index] = {
            asset: record ? assets[record.asset] : null,
            status: record ? record.status : "TEMPORARY",
            temporaryAsset: assets[temporaryRecord.asset]
          };
        }

        return memo;
      },
      records
    );

    return records;
  }
);

export const getRecordsValidityVersion = createSelector(
  getState,
  (state) => state.recordsValidityVersion
);

export const getCount = createSelector(getState, (state) => state.count);

export const getEditingAsset = createSelector(
  getState,
  getERAssets,
  (state, assets) => {
    if (state.editingAsset) {
      return assets[state.editingAsset];
    } else {
      return null;
    }
  }
);

export const isLoading = createSelector(getState, (state) => state.loading);

export const isRejected = createSelector(getState, (state) => state.rejected);

export const getTotalAssets = createSelector(
  getState,
  (state) => state.totalAssets
);

export const getAssetIndex = createSelector(
  getState,
  (state) => state.assetIndex
);

export const getPreviewWall = createSelector(
  getState,
  getERAssets,
  ({ previewWallUuid }, assets) =>
    previewWallUuid ? assets[previewWallUuid] : null
);
