import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserTrackingEvent } from "./user-tracking-event";

export interface UserTrackingState {
  mixpanelToken?: string;
}

export interface TrackActionPayload {
  eventName: UserTrackingEvent;
  metadata?: object;
}

export interface TrackUserPayload {}

const initialState: UserTrackingState = {};

const { actions, reducer } = createSlice({
  name: "userTracking",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    track: (state, action: PayloadAction<TrackActionPayload>) => state,
    // eslint-disable-next-line no-unused-vars
    trackUser: (state, action: PayloadAction<TrackUserPayload>) => state,
    initiate: (state, action: PayloadAction<{ mixpanelToken: string }>) => ({
      ...state,
      mixpanelToken: action.payload.mixpanelToken
    }),
    createSceneAssetTrackingEvent: (state) => state
  }
});

export const UserTrackingActions = actions;
export const userTrackingReducer = reducer;
