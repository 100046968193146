import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import { getTitle } from "../scene-editor-selectors";
import { SceneEditorActions } from "../scene-editor-reducer";
import { ReactComponent as IconBack } from "../../../images/back.svg";

export const SceneEditorHeader = () => {
  const title = useSelector(getTitle);

  const dispatch = useDispatch();

  const leave = useCallback(() => {
    dispatch(SceneEditorActions.leave());
  }, [dispatch]);

  return (
    <header className="header">
      <div>
        <button
          type="button"
          onClick={leave}
          className={cx("appName", "appNameBack")}
        >
          <IconBack />
          {title}
        </button>
      </div>
    </header>
  );
};
