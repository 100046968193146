import React, { useCallback, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSafeDebounce } from "../../utils/use-safe-debounce";
import ContextMenu from "../context-menu/context-menu";
import { ChoiceLogicImplementations } from "./choice-logic-implementations";

import { ReactComponent as IconCheck } from "../../../images/check.svg";

const ENABLE_SEARCH_FROM_COUNT = 10;

export const Filter = ({
  value,
  onChange,
  id,
  label,
  choiceLogic: { hasSelectedValue, toggleChoice, isChoiceSelected },
  selectedFilters,
  globalSearch,
  additionalFilterData,
  category,
  choices
}) => {
  const isFirstLoad = useRef(true);
  const isLazyLoaded = typeof choices === "function";

  const [filteredChoices, setFilteredChoices] = useState(
    isLazyLoaded ? null : choices
  );

  const [searchPhrase, setSearchPhrase] = useState("");
  const [enableSearch, setEnableSearch] = useState(false);

  const stringAdditionalFilter = JSON.stringify(additionalFilterData);
  const selectedFiltersCopy = {
    ...selectedFilters
  };
  delete selectedFiltersCopy[id];
  const stringSelectedFilter = JSON.stringify(selectedFiltersCopy);

  const debouncedSearch = useSafeDebounce(
    async (searchPhrase) => {
      let values;
      let count;

      if (isLazyLoaded) {
        ({ rows: values, count } = await choices(
          searchPhrase,
          selectedFiltersCopy,
          globalSearch,
          additionalFilterData,
          category
        ));

        if (!Array.isArray(values)) {
          throw Error(`Lazy loaded choices.rows must be an array.`);
        }

        if (!Number.isInteger(count)) {
          throw Error(`Lazy loaded choices.count must be an integer.`);
        }
      } else {
        values = choices;
        count = choices.length;
      }

      if (searchPhrase === "" && count >= ENABLE_SEARCH_FROM_COUNT) {
        setEnableSearch(true);
      }

      isFirstLoad.current = false;
      setFilteredChoices(values);
    },
    [
      choices,
      stringSelectedFilter,
      globalSearch,
      stringAdditionalFilter,
      category
    ],
    200 // debounce time 200ms
  );

  const onSearchChange = useCallback(({ target: { value } }) => {
    setSearchPhrase(value);
  }, []);

  useEffect(() => {
    debouncedSearch(searchPhrase);
  }, [debouncedSearch, searchPhrase, stringAdditionalFilter]);

  if (isFirstLoad.current || !filteredChoices || filteredChoices.length === 0) {
    return <></>;
  }

  return (
    <ContextMenu
      title={label}
      placement={ContextMenu.placement.bottom}
      className={cx({
        filterContextMenu: true
      })}
      triggerClass={cx("filterButton button buttonOutlined", {
        isActive: hasSelectedValue(value),
        noResult:
          (value === undefined || value.length === 0) &&
          filteredChoices &&
          filteredChoices.length === 0
      })}
    >
      <ContextMenu.InteractiveContent>
        {enableSearch && (
          <input
            className="searchInput"
            type="text"
            placeholder="Type to search"
            value={searchPhrase}
            onChange={onSearchChange}
          />
        )}
        {!filteredChoices && <div className="loading">Loading...</div>}
        {filteredChoices && filteredChoices.length === 0 && (
          <div className="noResult">No results</div>
        )}
        {filteredChoices &&
          filteredChoices.map((choice) => (
            <button
              key={
                // make sure that also choices with null values has some key
                choice.value || String(choice.value)
              }
              onClick={() =>
                onChange(toggleChoice(value, choice.value), id, choice)
              }
              data-qa={`filter-option-${choice.label}`}
            >
              {isChoiceSelected(value, choice.value) ? (
                <i className="check checked">
                  <IconCheck />
                </i>
              ) : (
                <i className="check" />
              )}
              {choice.label}
            </button>
          ))}
      </ContextMenu.InteractiveContent>
    </ContextMenu>
  );
};

Filter.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  globalSearch: PropTypes.string.isRequired,
  category: PropTypes.string,
  additionalFilterData: PropTypes.object,
  id: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]).isRequired,
  label: PropTypes.string.isRequired,
  choiceLogic: PropTypes.oneOf(Object.values(ChoiceLogicImplementations))
    .isRequired,
  choices: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired
      }).isRequired
    ).isRequired
  ]).isRequired
};
