import React, { useCallback, useEffect, useState } from "react";
import {
  useDeselectAllAssets,
  useSelectMultipleAssets
} from "../use-select-asset";
import { useStore } from "react-redux";

import { AssetType } from "shared/types/asset-type";
import {
  getActiveWallCoords,
  getActiveWallInfo,
  getIsSelectionDraggingDisabled
} from "../../scene-editor-selectors";
import { useGetMouseMovement } from "./utils/use-mouse-movement";

const isAssetIntersectingSelectionBox = (
  selectionBox: { top: number; left: number; right: number; bottom: number },
  asset: {
    id: number;
    left: number;
    top: number;
    cropWidth: number;
    cropHeight: number;
    scale: number;
    asset: { type: AssetType };
  },
  activeWallInfo: { start: number; width: number; height: number }
) => {
  const assetWidth =
    asset.asset.type === AssetType.ASSET_TEXT
      ? (asset.cropWidth * activeWallInfo.width) / 100
      : asset.cropWidth;
  const assetHeight =
    asset.asset.type === AssetType.ASSET_TEXT
      ? (asset.cropHeight * activeWallInfo.height) / 100
      : asset.cropHeight;

  const assetLeft = asset.left - activeWallInfo.start;
  const assetRight =
    asset.left - activeWallInfo.start + (assetWidth * asset.scale) / 100;
  const assetTop = asset.top;
  const assetBottom = asset.top + (assetHeight * asset.scale) / 100;

  const isHorizontalIntersection =
    (selectionBox.left > assetLeft && selectionBox.left < assetRight) ||
    (selectionBox.right > assetLeft && selectionBox.right < assetRight) ||
    (selectionBox.left < assetLeft && selectionBox.right > assetRight);

  const isVerticalIntersection =
    (selectionBox.top > assetTop && selectionBox.top < assetBottom) ||
    (selectionBox.bottom > assetTop && selectionBox.bottom < assetBottom) ||
    (selectionBox.top < assetTop && selectionBox.bottom > assetBottom);

  return isHorizontalIntersection && isVerticalIntersection;
};

const transformSelectionBoxCoordsToWallCoords = (
  selectionBox: {
    top: number;
    left: number;
    pivotX: number;
    pivotY: number;
    width: number;
    height: number;
  },
  activeWallCoords: { left: number; top: number },
  canvasScale: number
) => {
  const left =
    selectionBox.width > 0
      ? (selectionBox.pivotX - activeWallCoords.left) / canvasScale
      : (selectionBox.pivotX + selectionBox.width - activeWallCoords.left) /
        canvasScale;
  const top =
    selectionBox.height > 0
      ? (selectionBox.pivotY - activeWallCoords.top) / canvasScale
      : (selectionBox.pivotY + selectionBox.height - activeWallCoords.top) /
        canvasScale;

  const right = left + Math.abs(selectionBox.width) / canvasScale;
  const bottom = top + Math.abs(selectionBox.height) / canvasScale;

  return { right, bottom, left, top };
};

export const useSelection = (
  wallAssets: {
    id: number;
    uuid: string;
    left: number;
    top: number;
    cropWidth: number;
    cropHeight: number;
    scale: number;
    asset: { type: AssetType };
  }[],
  canvasScale: number
) => {
  const [selectionBox, setSelectionBox] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    pivotX: 0,
    pivotY: 0
  });
  const [selecting, setSelecting] = useState(false);

  const store = useStore();

  const selectMultipleAssets = useSelectMultipleAssets();
  const deselectAllAssets = useDeselectAllAssets();
  const getMouseMovement = useGetMouseMovement();

  const selectionMouseDownHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const isSelectionDisabled = getIsSelectionDraggingDisabled(
        store.getState()
      );
      if (isSelectionDisabled) {
        return;
      }

      setSelecting(true);
      const clientX = e.clientX;
      const clientY = e.clientY;

      setSelectionBox({
        pivotX: clientX,
        pivotY: clientY,
        left: clientX,
        top: clientY,
        height: 0,
        width: 0
      });
    },
    [store]
  );

  const selectionMouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      if (selecting) {
        const { movementX, movementY } = getMouseMovement(e);

        setSelectionBox((state) => {
          return {
            ...state,
            top: state.height > 0 ? state.pivotY : state.pivotY + state.height,
            left: state.width > 0 ? state.pivotX : state.pivotX + state.width,
            height: state.height - movementY,
            width: state.width - movementX
          };
        });
      }
    },
    [selecting, getMouseMovement]
  );

  const selectionMouseUpHandler = useCallback(() => {
    const activeWallInfo = getActiveWallInfo(store.getState());
    const activeWallCoords = getActiveWallCoords(store.getState());

    if (selecting) {
      if (!activeWallInfo) {
        return;
      }

      deselectAllAssets();

      const selectionBoxInWallCoords = transformSelectionBoxCoordsToWallCoords(
        selectionBox,
        activeWallCoords,
        canvasScale
      );

      const assetIds = wallAssets
        .filter((asset) =>
          isAssetIntersectingSelectionBox(
            selectionBoxInWallCoords,
            asset,
            activeWallInfo
          )
        )
        .map((asset) => asset.uuid);

      assetIds.forEach((id) => selectMultipleAssets(id));
      setSelecting(false);
    }
  }, [
    store,
    selectionBox,
    canvasScale,
    selecting,
    selectMultipleAssets,
    wallAssets,
    deselectAllAssets
  ]);

  const selectionContextMenuHandler = useCallback(
    (e: MouseEvent) => {
      if (selecting) {
        e.preventDefault();
      }
    },
    [selecting]
  );

  useEffect(() => {
    document.addEventListener("mousemove", selectionMouseMoveHandler);
    document.addEventListener("mouseup", selectionMouseUpHandler);
    document.addEventListener("contextmenu", selectionContextMenuHandler);

    return () => {
      document.removeEventListener("mousemove", selectionMouseMoveHandler);
      document.removeEventListener("mouseup", selectionMouseUpHandler);
      document.removeEventListener("contextmenu", selectionContextMenuHandler);
    };
  }, [
    selectionMouseMoveHandler,
    selectionMouseUpHandler,
    selectionContextMenuHandler
  ]);

  return {
    selectionBox: selecting ? selectionBox : undefined,
    selectionMouseDownHandler
  };
};
