import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../api/use-api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Logger as logger } from "purplex-logging";
import { normalize } from "normalizr";
import { EntityRepositoryActions } from "../../../entity-repository/entity-repository-reducer";
import { customFields as customFieldsSchema } from "../../../entity-repository/schema";
import { CustomFieldsActions } from "../custom-fields-reducer";
import {
  getAllCustomFieldHierarchies,
  getAllCustomFields,
  getAssetCategoryTree,
  getAssetCustomFieldCategoryTree,
  getPresentationActiveFilters
} from "../custom-fields-selectors";
import { CustomFieldEntity } from "../../../../../shared/types/custom-field-entity";
import { CustomFieldType } from "../../../../../shared/types/custom-field-type";
import { CustomFieldsCategorySelector } from "../../../ux/filters/custom-fields-category-selector";
import { FulltextAndFilters } from "../../../ux/filters/fulltext-and-filters";
import { getFilterConfigForSelectedCategory } from "../../../media/components/get-filter-config-for-selected-category";
import { getAssetFiltersConfig } from "../../../media/media-selectors";

export const useFetchCustomFields = () => {
  const dispatch = useDispatch();
  const api = useApi();

  return useCallback(async () => {
    try {
      logger.debug(`Fetching custom fields`);

      const { rows: customFields } = await api.fetchCustomFields();

      const { entities: repository, result: customFieldIds } = normalize(
        customFields,
        customFieldsSchema
      );

      dispatch(EntityRepositoryActions.repositoryHasChanged({ repository }));
      dispatch(CustomFieldsActions.onFetchedCustomFields({ customFieldIds }));

      const customFieldMappingIds = customFields
        .map((field) => field.entityMappings.map((mapping) => mapping.id))
        .flat();

      dispatch(
        CustomFieldsActions.onFetchedCustomFieldMappings({
          customFieldMappingIds
        })
      );

      const customFieldHierarchyIds = customFields
        .map((field) =>
          field.entityMappings
            .map((mapping) =>
              mapping.customFieldHierarchies.map((hierarchy) => hierarchy.id)
            )
            .flat()
        )
        .flat();
      dispatch(
        CustomFieldsActions.onFetchedCustomFieldHierarchies({
          customFieldHierarchyIds
        })
      );
    } catch (ex) {
      logger.error(`Error during fetching custom fields.`, ex);
    }
  }, [api, dispatch]);
};

export const useCustomFieldLists = () => {
  const fetchCustomFields = useFetchCustomFields();

  useEffect(() => {
    (async () => {
      await fetchCustomFields();
    })();
  }, [fetchCustomFields]);

  const customFields = useSelector(getAllCustomFields);
  const assetCategoryTree = useSelector(getAssetCategoryTree);
  const presentationActiveFilters = useSelector(getPresentationActiveFilters);

  return useMemo(() => {
    return {
      customFields,
      presentationFilters: customFields.filter(
        (field) => field.entity === CustomFieldEntity.PRESENTATION
      ),
      assetFilters: customFields.filter(
        (field) =>
          field.entity === CustomFieldEntity.ASSET &&
          field.type === CustomFieldType.SINGLE_CHOICE
      ),
      assetCategoryTree,
      presentationActiveFilters
    };
  }, [customFields, assetCategoryTree, presentationActiveFilters]);
};

const filtersInitState = {
  filters: null,
  fullText: ""
};

export const useCustomFieldAssetFilters = (
  additionalFilterData: { type: string },
  hideServiceCategory: boolean,
  key?: string | number,
  dataQa?: string
) => {
  const fetchCustomFields = useFetchCustomFields();

  const [filters, setFilters] = useState<any>(filtersInitState);
  const [category, setCategory] = useState("");

  const categoryConfig = useSelector(getAssetCustomFieldCategoryTree);
  const filterConfig = useSelector(getAssetFiltersConfig);
  const customFieldHierarchies = useSelector(getAllCustomFieldHierarchies);

  const filteredFilterConfig = getFilterConfigForSelectedCategory(
    customFieldHierarchies,
    filterConfig,
    category
  );

  const onFiltersChanged = useCallback(
    (filters: { fulltext: string; filters: any }) => {
      logger.debug("Filters changed", filters);
      setFilters(filters);
    },
    []
  );

  const onCategorySelected = useCallback(
    (category) => {
      setCategory(category);

      const filterConfigForNextCategory = getFilterConfigForSelectedCategory(
        customFieldHierarchies,
        filterConfig,
        category
      );

      setFilters((filters: any) => ({
        ...filters,
        filters:
          filters.filters &&
          Object.keys(filters.filters).reduce(
            (memo: { [key: string]: string }, filterId: string) => {
              if (
                filterConfigForNextCategory
                  .map((filter) => filter.id)
                  .includes(filterId)
              ) {
                memo[filterId] = filters.filters[filterId];
              }
              return memo;
            },
            {}
          )
      }));
      logger.debug("Category selected ", category);
    },
    [setCategory, customFieldHierarchies, filterConfig]
  );

  const resetFilters = useCallback(() => {
    setCategory("");
    setFilters(filtersInitState);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCustomFields();
    })();
  }, [fetchCustomFields]);

  const categorySelectComponent = (
    <CustomFieldsCategorySelector
      root={categoryConfig}
      hideServiceCategories={hideServiceCategory}
      onPathSelected={onCategorySelected}
    />
  );

  const filtersComponent = (
    <FulltextAndFilters
      key={key}
      onChange={onFiltersChanged}
      filters={filteredFilterConfig}
      additionalFilterData={additionalFilterData}
      category={category}
      dataQa={dataQa}
    />
  );

  return {
    filtersComponent,
    categorySelectComponent,
    filters,
    category,
    resetFilters
  };
};
