import React from "react";
import { useSelector } from "react-redux";
import { includesSegment, endsWithSegment } from "router5-helpers";
import cx from "classnames";

import { AssetConfig } from "./toolbar/asset-config";
import * as RoutingSelectors from "../../routing/routing-selectors";
import { SceneView } from "./scene-model/scene-view";
import { PreviewToggleAndInstanceSelector } from "./preview/preview-toggle-and-instance-selector";
import { AssetActionsModal } from "./asset-actions-modal";
import { LeftPanel } from "./left-panel";
import { usePreloadFonts } from "../hooks/use-preload-fonts";
import { AssetLibrary } from "../asset-library/components/asset-library";
import { useKeyboardShortcuts } from "../hooks/use-keyboard-shortcuts";
import { useFetchEditorSettings } from "client/modules/settings/editor-settings/hooks/use-fetch-editor-settings";

export const SceneEditor = () => {
  const routeName = useSelector(RoutingSelectors.getRouteName);

  useFetchEditorSettings();
  usePreloadFonts();
  useKeyboardShortcuts();

  const includesRoute = includesSegment(routeName as string);
  const endsWithRoute = endsWithSegment(routeName as string);

  return (
    <>
      <div
        className={cx("content", {
          "has-panel-open": includesRoute("asset-library")
        })}
      >
        <div className="main-panel">
          <div className="left-top-bar">
            <AssetConfig />
          </div>
          <SceneView isInPreviewMode={false}>
            <PreviewToggleAndInstanceSelector />
          </SceneView>
        </div>
        <div className="asset-library" data-qa="asset-library-panel">
          <AssetLibrary />
        </div>
        <LeftPanel />
      </div>
      {endsWithRoute("asset-actions.tab") && <AssetActionsModal />}
    </>
  );
};
