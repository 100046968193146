import { Logger as logger } from "purplex-logging";
import { useCallback, useEffect, useRef, useState } from "react";

import { ApiListPayload } from "client/modules/crud/crud-types";

export enum InfiniteListRowStatus {
  Loading,
  Loaded,
  Error
}

export interface InfiniteListRecord<T> {
  status: InfiniteListRowStatus;
  data?: T;
}

export function useInfiniteListStore<T>(
  fetchData: (
    startIndex: number,
    stopIndex: number
  ) => Promise<ApiListPayload<T[]>>,
  resetStateDependencies: unknown[]
) {
  const defaultCount = 1000;
  const records = useRef<{ [key: string]: InfiniteListRecord<T> }>({});
  const [resetCount, changeResetCount] = useState(0);
  const [count, setCount] = useState(defaultCount);

  useEffect(() => {
    logger.debug(
      "Cleaning up list because some of the reset dependencies has changed"
    );
    setCount(defaultCount);
    records.current = {};
    changeResetCount((resetCount) => resetCount + 1);
  }, resetStateDependencies); // eslint-disable-line react-hooks/exhaustive-deps

  const requestMoreData = useCallback(
    async ({
      startIndex,
      stopIndex
    }: {
      startIndex: number;
      stopIndex: number;
    }) => {
      logger.debug(
        `Requesting more data to be fetched to infinite list ${startIndex} - ${stopIndex}`
      );

      const fillRecords = (
        status: InfiniteListRowStatus,
        result?: ApiListPayload<T[]>
      ) => {
        for (let i = startIndex; i < stopIndex; i++) {
          records.current[i] = {
            status,
            data:
              status === InfiniteListRowStatus.Loaded
                ? result && result.rows[i - startIndex]
                : undefined
          };
        }
      };

      fillRecords(InfiniteListRowStatus.Loading);

      try {
        const result = await fetchData(startIndex, stopIndex);
        fillRecords(InfiniteListRowStatus.Loaded, result);
        setCount(result.count);
      } catch (ex) {
        logger.error(
          `Error while fetching infinite list rows ${startIndex} - ${stopIndex}`
        );
        fillRecords(InfiniteListRowStatus.Error);
      }
    },
    [fetchData, setCount]
  );

  const removeRecord = useCallback(
    (predicate: (record: T) => boolean) => {
      const recordKey = Object.keys(records.current).find((key) => {
        const record = records.current[key];
        return record && record.data && predicate(record.data);
      });

      if (recordKey) {
        delete records.current[recordKey];
      }
      let i = 0;

      const newData = Object.keys(records.current).reduce((memo, key) => {
        memo[i++] = records.current[key];
        return memo;
      }, {} as { [key: string]: InfiniteListRecord<T> });

      records.current = newData;
      setCount((currentCount) => currentCount - 1);
    },
    [setCount]
  );

  return {
    requestMoreData,
    removeRecord,
    records: records.current,
    count,
    resetCount
  };
}
