import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { AutoSizer } from "react-virtualized";

import { getActiveWall, getScene } from "../../scene-editor-selectors";
import { useRenderCount } from "../../utils";
import { SceneModel } from "./scene-model";
import { usePreloadFonts } from "../../hooks/use-preload-fonts";

export const SceneView = (props: {
  children: ReactNode;
  isInPreviewMode: boolean;
}) => {
  const { children, isInPreviewMode } = props;

  const scene = useSelector(getScene);
  const activeWall = useSelector(getActiveWall);

  const renderCount = useRenderCount();
  usePreloadFonts();

  if (
    !scene ||
    !scene.instance ||
    !scene.instance.walls ||
    !scene.sceneAssets
  ) {
    return (
      <div className="view">Please select scene&apos;s instance first.</div>
    );
  }

  const wallAsset = scene.wall;
  const walls = scene.instance.walls;
  const sceneAssets = scene.sceneAssets;

  return (
    <div className="view" data-qa="scene-view">
      {renderCount}
      <AutoSizer>
        {({ width, height }) => (
          <SceneModel
            width={width}
            height={height}
            enableDnD={!isInPreviewMode}
            activeWall={activeWall}
            wallAsset={wallAsset}
            walls={walls}
            sceneAssets={sceneAssets}
          />
        )}
      </AutoSizer>
      {children}
    </div>
  );
};
