import React from "react";
import { useCallback } from "react";
import cx from "classnames";

import { FiltersBlock } from "./filters-block";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";
import { ReactComponent as IconCheck } from "../../../../../images/check.svg";
import { ReactComponent as IconCaret } from "../../../../../images/caret.svg";

export interface CustomFieldCategoryTree {
  id: number;
  label: string;
  type: string;
  path: string;
  service: boolean;
  children: CustomFieldCategoryTree[];
}

interface CategoryNodeProps {
  query: string;
  node: CustomFieldCategoryTree;
  path: string;
  nestingLevel: number;
  selectedCategories: string[];
  toggledCategories: string[];
  onSelectCategory: (path: string, isLeaf: boolean) => void;
  onToggleCategory: (path: string) => void;
}

export interface CategorySelectorProps {
  isSelected: boolean;
  onClick: () => void;
}

export const CategorySelector = ({
  onClick,
  isSelected
}: CategorySelectorProps) => (
  <span className={cx("selector", { isSelected })} onClick={onClick}>
    {isSelected && <IconCheck width={13} height={13} />}
  </span>
);

export const CategoryNode: React.FC<CategoryNodeProps> = ({
  query,
  node,
  path,
  selectedCategories,
  nestingLevel,
  toggledCategories,
  onSelectCategory,
  onToggleCategory
}) => {
  const nodes = node.children;
  const { id, label } = node;

  const categoryStartsWithPathPredicate = (category: string) =>
    `${category}/`.startsWith(`${path}/`);

  const isSelected = selectedCategories.some(categoryStartsWithPathPredicate);
  const isToggled = toggledCategories.some(categoryStartsWithPathPredicate);

  const isLeaf = nodes.every((node) => node.type !== CustomFieldType.CATEGORY);
  const hasFilters = nodes.some(
    (node) => node.type === CustomFieldType.SINGLE_CHOICE
  );

  const selectCategory = useCallback(() => {
    onSelectCategory(path, isLeaf);
  }, [onSelectCategory, path, isLeaf]);

  const toggleCategory = useCallback(() => {
    onToggleCategory(path);
    onSelectCategory(path, isLeaf);
  }, [onToggleCategory, onSelectCategory, path, isLeaf]);

  const recursiveMatchingSearch = (node: CustomFieldCategoryTree): boolean =>
    node.label.toLowerCase().includes(query.toLowerCase()) ||
    node.children.some(recursiveMatchingSearch);

  const expandedAll = query !== "";
  const fullfillFilter = !query || recursiveMatchingSearch(node);

  if (!fullfillFilter) {
    return <></>;
  }

  if (node.service) {
    return <></>;
  }

  return (
    <li
      className={cx({ isLeaf, hasFilters })}
      style={{ ["--level" as string]: nestingLevel - 1 }}
    >
      <div className="categoryItem">
        <CategorySelector onClick={selectCategory} isSelected={isSelected} />
        <span
          className={cx("categoryLabel", {
            isActive: hasFilters,
            isSelected,
            isToggled
          })}
          onClick={toggleCategory}
        >
          {label}
          <IconCaret />
        </span>
        {(isSelected || isToggled || expandedAll) && (
          <FiltersBlock disabled={!isSelected} parentId={id} />
        )}
      </div>
      {(isSelected || isToggled || expandedAll) && nodes.length > 0 && (
        <ul>
          {nodes
            .filter((node) => node.type === CustomFieldType.CATEGORY)
            .map((node) => (
              <CategoryNode
                nestingLevel={nestingLevel + 1}
                query={query}
                key={node.id}
                node={node}
                path={`${path}/${node.id}`}
                selectedCategories={selectedCategories}
                toggledCategories={toggledCategories}
                onSelectCategory={onSelectCategory}
                onToggleCategory={onToggleCategory}
              />
            ))}
        </ul>
      )}
    </li>
  );
};
