import { useCallback } from "react";
import { useStore } from "react-redux";

import {
  getActiveWallInfo,
  getSelectedSceneAssets,
  getSelectionBoundingBox
} from "../scene-editor-selectors";
import { useAssetRenderedDimensions } from "./use-asset-rendered-dimensions";
import { useAssetPropertiesActions } from "./asset-config/use-asset-properties-actions";

export const useAlignmentTools = () => {
  const store = useStore();
  const { setX, setY, updateAssetProperties } = useAssetPropertiesActions();
  const { getAssetHeight, getAssetWidth } = useAssetRenderedDimensions();

  const alignToolFactory = useCallback(
    (singleAssetHandler: () => void, multiAssetHandler: () => void) => () => {
      const selectedAssets = getSelectedSceneAssets(store.getState());
      const isMultiSelect = selectedAssets.length > 1;
      const isSingleSelect = selectedAssets.length === 1;

      if (isMultiSelect) {
        multiAssetHandler();
        return;
      }
      if (isSingleSelect) {
        singleAssetHandler();
      }
    },
    [store]
  );

  const alignLeft = useCallback(
    alignToolFactory(
      () => {
        const wallInfo = getActiveWallInfo(store.getState());
        if (!wallInfo) {
          return;
        }
        setX(wallInfo.start);
      },
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }
        setX(boundingBox.left);
      }
    ),
    [setX, alignToolFactory, store]
  );

  const alignRight = useCallback(
    alignToolFactory(
      () => {
        const wallInfo = getActiveWallInfo(store.getState());
        if (!wallInfo) {
          return;
        }

        const selectedAssets = getSelectedSceneAssets(store.getState());
        setX(Math.round(wallInfo.end - getAssetWidth(selectedAssets[0])));
      },
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }

        const assetsToProcess = getSelectedSceneAssets(store.getState());
        const updatedAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          left: Math.round(boundingBox.right - getAssetWidth(asset))
        }));
        const oldAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          left: asset.left
        }));
        updateAssetProperties({ oldAssets, updatedAssets });
      }
    ),
    [updateAssetProperties, alignToolFactory, getAssetWidth, store]
  );

  const alignTop = useCallback(
    alignToolFactory(
      () => {
        const wallInfo = getActiveWallInfo(store.getState());
        if (!wallInfo) {
          return;
        }

        setY(0);
      },
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }

        setY(boundingBox.top);
      }
    ),
    [setY, alignToolFactory, store]
  );

  const alignBottom = useCallback(
    alignToolFactory(
      () => {
        const wallInfo = getActiveWallInfo(store.getState());
        if (!wallInfo) {
          return;
        }

        const selectedAssets = getSelectedSceneAssets(store.getState());
        setY(Math.round(wallInfo.height - getAssetHeight(selectedAssets[0])));
      },
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }

        const assetsToProcess = getSelectedSceneAssets(store.getState());
        const updatedAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          top: Math.round(boundingBox.bottom - getAssetHeight(asset))
        }));
        const oldAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          top: asset.top
        }));

        updateAssetProperties({ oldAssets, updatedAssets });
      }
    ),
    [updateAssetProperties, setY, alignToolFactory, store, getAssetHeight]
  );

  const alignCenterHorizontally = useCallback(
    alignToolFactory(
      () => {
        const wallInfo = getActiveWallInfo(store.getState());
        if (!wallInfo) {
          return;
        }

        const selectedAssets = getSelectedSceneAssets(store.getState());
        setY(
          Math.round((wallInfo.height - getAssetHeight(selectedAssets[0])) / 2)
        );
      },
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }

        const assetsToProcess = getSelectedSceneAssets(store.getState());
        const updatedAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          top: Math.round(
            boundingBox.top +
              (boundingBox.bottom - boundingBox.top) / 2 -
              getAssetHeight(asset) / 2
          )
        }));
        const oldAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          top: asset.top
        }));

        updateAssetProperties({ oldAssets, updatedAssets });
      }
    ),
    [setY, updateAssetProperties, alignToolFactory, store, getAssetHeight]
  );

  const alignCenterVertically = useCallback(
    alignToolFactory(
      () => {
        const wallInfo = getActiveWallInfo(store.getState());
        if (!wallInfo) {
          return;
        }

        const selectedAssets = getSelectedSceneAssets(store.getState());
        setX(
          Math.round(
            (wallInfo.start + wallInfo.end - getAssetWidth(selectedAssets[0])) /
              2
          )
        );
      },
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }

        const assetsToProcess = getSelectedSceneAssets(store.getState());
        const updatedAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          left: Math.round(
            boundingBox.left +
              (boundingBox.right - boundingBox.left) / 2 -
              getAssetWidth(asset) / 2
          )
        }));
        const oldAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          left: asset.left
        }));

        updateAssetProperties({ oldAssets, updatedAssets });
      }
    ),
    [updateAssetProperties, setX, alignToolFactory, store, getAssetWidth]
  );

  const evenlyDistributeHorizontally = useCallback(
    alignToolFactory(
      () => {},
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }

        const assetsToProcess = getSelectedSceneAssets(store.getState());
        const totalAssetHeight = assetsToProcess.reduce(
          (result, asset) => result + getAssetHeight(asset),
          0
        );
        const margin =
          (boundingBox.bottom - boundingBox.top - totalAssetHeight) /
          (assetsToProcess.length - 1);

        let currentTop = boundingBox.top;
        const updatedAssets = assetsToProcess
          .sort((a, b) => a.top - b.top)
          .map((asset) => {
            const tempValue = currentTop;
            currentTop += margin + getAssetHeight(asset);
            return {
              top: Math.round(tempValue),
              id: asset.id,
              uuid: asset.uuid
            };
          });

        const oldAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          top: asset.top
        }));

        updateAssetProperties({ oldAssets, updatedAssets });
      }
    ),
    [updateAssetProperties, alignToolFactory, store]
  );

  const evenlyDistributeVertically = useCallback(
    alignToolFactory(
      () => {},
      () => {
        const boundingBox = getSelectionBoundingBox(store.getState());
        if (!boundingBox) {
          return;
        }

        const assetsToProcess = getSelectedSceneAssets(store.getState());
        const totalAssetWidth = assetsToProcess.reduce(
          (result, asset) => result + getAssetWidth(asset),
          0
        );
        const margin =
          (boundingBox.right - boundingBox.left - totalAssetWidth) /
          (assetsToProcess.length - 1);

        let currentLeft = boundingBox.left;
        const updatedAssets = assetsToProcess
          .sort((a, b) => a.left - b.left)
          .map((asset) => {
            const tempValue = currentLeft;
            currentLeft += margin + getAssetWidth(asset);
            return {
              left: Math.round(tempValue),
              uuid: asset.uuid,
              id: asset.id
            };
          });

        const oldAssets = assetsToProcess.map((asset) => ({
          id: asset.id,
          uuid: asset.uuid,
          left: asset.left
        }));

        updateAssetProperties({ oldAssets, updatedAssets });
      }
    ),
    [updateAssetProperties, alignToolFactory, store]
  );

  return {
    alignTop,
    alignRight,
    alignLeft,
    alignBottom,
    alignCenterHorizontally,
    alignCenterVertically,
    evenlyDistributeHorizontally,
    evenlyDistributeVertically
  };
};
