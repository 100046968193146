import React from "react";
import { ReactComponent as IconLink } from "client/images/assets/link.svg";

export const AssetUrl = (props: {
  width: number;
  height: number;
  glow: boolean;
  rounded: boolean;
}) => {
  const { width, height, glow, rounded } = props;
  return (
    <div
      className="placeholder-url"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: rounded ? "20px" : 0,
        boxShadow: glow ? "0 0 50px #fff" : ""
      }}
    >
      <IconLink className="icon icon-link icon192" />
    </div>
  );
};
