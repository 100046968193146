import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getBranding } from "../../../config/config-selectors";
import {
  getInitializingError,
  getScene,
  isInitializing
} from "../../scene-editor-selectors";
import { CriticalError } from "../ux/critical-error";
import { SceneView } from "../scene-model/scene-view";
import { PreviewWallNavigation } from "../preview-wall-navigation";
import { SceneEditorPageTemplate } from "../scene-editor-page-template";
import { useSceneEditorUtils } from "../../hooks/asset-config/use-scene-editor-utils";

export const GlobalScenePreview = () => {
  const scene = useSelector(getScene);
  const roomName = useSelector(getBranding).roomName;
  const initializing = useSelector(isInitializing);
  const initializingError = useSelector(getInitializingError);

  const { initialize } = useSceneEditorUtils();

  useEffect(() => {
    initialize();
  }, [initialize]);

  let error = null;

  if (initializing) {
    if (initializingError) {
      error = initializingError;
    } else {
      error = "Loading scene...";
    }
  } else if (!scene || !scene.instance) {
    error = `Scene's Targeted ${roomName} is not selected.`;
  }

  return (
    <SceneEditorPageTemplate>
      <div className="scene-editor preview">
        {error ? (
          <CriticalError>{error}</CriticalError>
        ) : (
          <div className="content">
            <div className="main-panel">
              <SceneView isInPreviewMode={true}>
                <PreviewWallNavigation />
              </SceneView>
            </div>
          </div>
        )}
      </div>
    </SceneEditorPageTemplate>
  );
};
