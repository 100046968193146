import { toast } from "react-toastify";
import { useEffect, useMemo } from "react";
import { Logger as logger } from "purplex-logging";

const failDefaultOption = {
  autoClose: false
};

enum NOTIFICATION_LEVELS {
  SUCCESS = "success",
  ERROR = "error",
  WARN = "warn",
  INFO = "info"
}

const createNotifier = (level: NOTIFICATION_LEVELS, defaultOptions: object) => (
  message: string,
  options?: object
) => {
  logger.debug("Triggering notifications " + level);
  toast[level](message, { ...defaultOptions, ...options });
};

export const useNotifications = () => {
  useEffect(() => {
    toast.configure({
      autoClose: 2000,
      hideProgressBar: true
    });
  }, []);

  return useMemo(() => {
    return {
      notifyWarn: createNotifier(NOTIFICATION_LEVELS.WARN, failDefaultOption),
      notifyInfo: createNotifier(NOTIFICATION_LEVELS.INFO, {}),
      notifySuccess: createNotifier(NOTIFICATION_LEVELS.SUCCESS, {}),
      notifyError: createNotifier(NOTIFICATION_LEVELS.ERROR, failDefaultOption)
    };
  }, []);
};
