import { Logger as logger } from "purplex-logging";
import { actionTypes as routerActions } from "redux-router5";
import mixpanel from "mixpanel-browser";
import { all, call, take, select, takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  UserTrackingActions,
  TrackActionPayload,
  TrackUserPayload
} from "./user-tracking-slice";
import { isMixpanelEnabled, getMixpanelToken } from "./user-tracking-selectors";
import { getUserId, getUser } from "../auth/auth-selectors";
import AuthActions from "../auth/auth-actions";
import { UserTrackingEvent } from "./user-tracking-event";
import * as UserTrackingCreators from "./user-tracking-creators";
import * as Api from "../api/client";
import { normalizeAndStore } from "../entity-repository/entity-repository-saga";
import { userSchema } from "../entity-repository/schema";

export function* track(payload: {
  eventName: UserTrackingEvent;
  metadata: object;
}) {
  yield put(UserTrackingActions.track(payload));
}

export function* trackUser(payload: object) {
  yield put(UserTrackingActions.trackUser(payload));
}

function* trackPageView() {
  logger.silly("Tracking page view");

  const loggedInUser = yield select(getUser);
  yield call(
    trackUser,
    UserTrackingCreators.trackUser(!!loggedInUser.guestDomain)
  );

  yield call(
    track,
    UserTrackingCreators.trackPageView(window.location.href, document.title)
  );
}

export function* userTrackingSaga() {
  yield all([
    take(UserTrackingActions.initiate.type),
    take(AuthActions.Types.LOGGED_USER)
  ]);

  const userTrackingEnabled: ReturnType<
    typeof isMixpanelEnabled
  > = yield select(isMixpanelEnabled);

  if (userTrackingEnabled) {
    logger.info("Enabling mixpanel integration");

    const mixpanelToken: string = yield select(getMixpanelToken);
    const userId: number = yield select(getUserId);

    logger.debug(`Identifying mixpanel user with id ${userId}`);
    mixpanel.init(mixpanelToken);
    mixpanel.identify(`user ${userId}`);

    yield call(trackPageView);
    yield takeEvery(routerActions.TRANSITION_SUCCESS, trackPageView);

    yield takeEvery(
      UserTrackingActions.track.type,
      function* trackingHandler(action: PayloadAction<TrackActionPayload>) {
        try {
          const {
            payload: { eventName, metadata }
          } = action;
          logger.silly(`Tracking "${eventName}" event to mixpanel`, metadata);
          mixpanel.track(eventName, metadata);
        } catch (ex) {
          logger.error("There was an error during logging user action", ex);
        }
      }
    );

    yield takeEvery(
      UserTrackingActions.trackUser.type,
      function* trackingUserHandler(action: PayloadAction<TrackUserPayload>) {
        logger.silly("Tracking user", action.payload);
        mixpanel.people.set(action.payload);
      }
    );

    const user = yield select(getUser);
    if (!user.firstLogin) {
      const user = yield call(Api.setFirstLogin);
      yield call(normalizeAndStore, user, userSchema);

      yield call(
        track,
        UserTrackingCreators.trackFirstLogin(
          user.firstLogin,
          !!user.guestDomain,
          user.guestDomain && user.guestDomain.name
        )
      );
    }
  } else {
    logger.info("Mixpanel integration is disabled");
  }
}
