import { validate as validateEmail } from "email-validator";

const REQUIRED_FIELDS = ["firstName", "lastName", "email"];

const FIELD_NAMES = {
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  password: "Password",
  passwordConfirm: "Password confirmation"
};

const validate = (values) => {
  const errors = {};

  if (!values.email || !validateEmail(values.email.trim())) {
    errors.email = `Please provide valid e-mail`;
  }

  if (!values.roles || !values.roles.length) {
    errors.roles = "At least one role is required";
  }

  if (
    values.password &&
    values.password.password.trim() !== "" &&
    !values.password.isValid
  ) {
    errors.password = "Password is not secure enough";
  }

  if (
    values.password &&
    values.password.password.trim() !== "" &&
    values.password.password !== values.passwordConfirm
  ) {
    errors.passwordConfirm = "Password confirmation does not match";
  }

  const invalidFields = REQUIRED_FIELDS.filter(
    (field) => !values[field] || values[field].trim() === ""
  );

  return invalidFields.reduce((memo, field) => {
    memo[field] = `${FIELD_NAMES[field]} is required`;
    return memo;
  }, errors);
};

export default validate;
