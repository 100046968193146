import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getVersion } from "client/modules/config/config-selectors";

const VersionTag = ({ version }) => (
  <span className="appVersion">{version && `Version ${version}`}</span>
);

VersionTag.propTypes = {
  version: PropTypes.string
};

export default connect((state) => ({
  version: getVersion(state)
}))(VersionTag);
