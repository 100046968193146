import React from "react";
import { useSelector } from "react-redux";
import { SsoProviderType } from "../../../../shared/types/sso-provider-type";
import { getSsoProvider } from "client/modules/config/config-selectors";

const providerToLoginUrl = {
  [SsoProviderType.Azure]: "ad"
};

const providerToButton = {
  [SsoProviderType.Azure]: "Login with Azure"
};

export const SsoLoginButton = () => {
  const provider: SsoProviderType = useSelector(getSsoProvider);

  return (
    <a
      href={`/api/auth/${providerToLoginUrl[provider]}/login`}
      className="button buttonOutlined"
    >
      {providerToButton[provider]}
    </a>
  );
};
