import React from "react";
import { useSelector } from "react-redux";
import Intercom from "react-intercom";

import { getRouteName } from "../../routing/routing-selectors";
import { getUser } from "../../auth/auth-selectors";
import { getIntercomAppId, getClientName } from "../../config/config-selectors";

export const IntercomConnector = () => {
  const user = useSelector(getUser);
  const routeName = useSelector(getRouteName);
  const appId = useSelector(getIntercomAppId);
  const client = useSelector(getClientName);

  if (user && appId) {
    return (
      <Intercom
        appID={appId}
        user_id={`${client}${user.id}`}
        email={user.email}
        name={`${user.firstName} ${user.lastName}`}
        user_roles={user.roles.join(",")}
        company={{ company_id: client }}
        cms_url={window.location.origin}
        url={routeName}
      />
    );
  } else {
    return <></>;
  }
};
