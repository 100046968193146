import React, { useRef } from "react";
import { EditedBackgroundState } from "./scene-editor-selectors";

const SHOW_RENDER_COUNT = false;

//TODO turn into component
export const useRenderCount = (
  title?: string,
  props?: {
    top: number | string;
    left: number | string;
  }
) => {
  const renderCount = useRef(0);

  if (!SHOW_RENDER_COUNT) {
    return;
  }

  renderCount.current++;
  return (
    <p
      style={{
        fontSize: 20,
        color: "black",
        zIndex: 100000,
        background: "white",
        fontWeight: 700,
        padding: 15,
        position: "absolute",
        top: (props && props.top) || 0,
        left: (props && props.left) || 0,
        opacity: 0.5
      }}
    >
      {`${title || ""} ${renderCount.current}`}
    </p>
  );
};

export const checkEditBackgroundStateValidity = (
  backgroundState: {
    left: number;
    top: number;
    width: number;
    height: number;
  },
  roomDimensions: {
    width: number;
    height: number;
  },
  resizingState: {
    absoluteLeftChange: number;
    absoluteTopChange: number;
    absoluteHeightChange: number;
    absoluteWidthChange: number;
  } = {
    absoluteTopChange: 0,
    absoluteHeightChange: 0,
    absoluteWidthChange: 0,
    absoluteLeftChange: 0
  },
  draggingState: {
    absoluteOffsetX: number;
    absoluteOffsetY: number;
  } = { absoluteOffsetY: 0, absoluteOffsetX: 0 }
) => {
  const isValid =
    backgroundState.left +
      resizingState.absoluteLeftChange +
      draggingState.absoluteOffsetX <=
      0 &&
    backgroundState.width +
      resizingState.absoluteWidthChange +
      backgroundState.left +
      resizingState.absoluteLeftChange +
      draggingState.absoluteOffsetX >=
      roomDimensions.width;

  const isRecommended =
    backgroundState.top +
      resizingState.absoluteTopChange +
      draggingState.absoluteOffsetY <=
      0 &&
    backgroundState.height +
      resizingState.absoluteHeightChange +
      backgroundState.top +
      resizingState.absoluteTopChange +
      draggingState.absoluteOffsetY >=
      roomDimensions.height;

  if (!isValid) {
    return EditedBackgroundState.INVALID;
  }

  if (isRecommended) {
    return EditedBackgroundState.RECOMMENDED;
  }

  return EditedBackgroundState.VALID;
};
