import { createReducer } from "reduxsauce";

import Actions from "client/modules/config/config-actions";

const INITIAL_STATE = {};

const onConfigFetched = (state, { config }) => ({
  ...state,
  ...config
});

export default createReducer(INITIAL_STATE, {
  [Actions.Types.CONFIG_FETCHED]: onConfigFetched
});
