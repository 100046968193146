import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { actions as RouterActions } from "redux-router5";
import { getSiteTitle } from "../../config/config-selectors";

const LoggedOutHeaderImpl = ({ navigateTo }: { navigateTo: Function }) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, maximum-scale=1" />
        <style>{`
          :root { font-size: 11px }
        `}</style>
      </Helmet>
      <header className="header headerLogin">
        <a onClick={() => navigateTo("login")} className="appName">
          <i className="cmsLogo" />
        </a>
      </header>
    </>
  );
};

export const LoggedOutHeader = connect(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: any) => ({
    siteTitle: getSiteTitle(state) as string
  }),
  {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    navigateTo: RouterActions.navigateTo
  }
)(LoggedOutHeaderImpl);
