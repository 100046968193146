import React, { useCallback, useState } from "react";
import { Logger as logger } from "purplex-logging";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import { useSelector } from "react-redux";

import { getRoleLabelFactory } from "../../../../config/config-selectors";
import { useConfirmationDialog } from "../../../../dialogs/dialog-hooks";
import { DialogResult } from "../../../../dialogs/event-types";
import { DebouncedSearchInput } from "../../../../ux/debounced-search-input";
import { userSchema } from "../../../../entity-repository/schema";
import { getUsers } from "../../../../entity-repository/entity-repository-selectors";
import { ApiClient } from "../../../../api/use-api";
import { useNavigateTo } from "../../../../routing/routing-hooks";
import {
  USERS_ADD_ROUTE,
  USERS_EDIT_ROUTE
} from "../../../../routing/route-names";
import { useRecordCrud } from "../../../../crud/use-record-crud";
import { SsoProviderType } from "shared/types/sso-provider-type";

import { ReactComponent as IconTrash } from "../../../../../images/trash.svg";
import { useHasAccess } from "../../../../auth/use-has-access";
import { AclResource } from "../../../../../../shared/acl";

import { ReactComponent as IconAdd } from "../../../../../images/add.svg";
import { ROW_LOCATORS } from "shared/tests/locators/row.locators";

const UserRow = (props: {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  ssoProvider: SsoProviderType | null;
  roles: string[];
  getRoleLabel: (role: string) => string;
  onEditUser: (id: number) => void;
  onDeleteUser: (id: number) => void;
}) => {
  const {
    id,
    email,
    firstName,
    lastName,
    country,
    ssoProvider,
    roles,
    getRoleLabel,
    onEditUser,
    onDeleteUser
  } = props;

  const confirm = useConfirmationDialog();

  const onDelete = useCallback(async () => {
    const result = await confirm({
      cancelLabel: "Cancel",
      confirmLabel: "Delete",
      subtext: `Are you sure you want to delete user "${email}"?`,
      text: "",
      title: `Delete User`
    });
    if (result.type === DialogResult.CONFIRM) {
      onDeleteUser(id);
    }
  }, [onDeleteUser, email, id, confirm]);

  const onEdit = useCallback(() => {
    onEditUser(id);
  }, [onEditUser, id]);

  return (
    <div className="gridRow" data-qa={ROW_LOCATORS.getRow(id)}>
      <span
        className="gridCell gridCellFirstName"
        data-qa={ROW_LOCATORS.getRowCell("first-name")}
      >
        First Name
        <h4>{firstName}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.getRowCell("last-name")}>
        Last Name
        <h4>{lastName}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.getRowCell("email")}>
        Email
        <h4>{email}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.getRowCell("country")}>
        Country
        <h4>{country}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.getRowCell("role")}>
        Role
        <h4>{roles.map(getRoleLabel).join(",")}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.getRowCell("provider")}>
        IDP
        <h4>{ssoProvider}</h4>
      </span>
      <hr />
      <span className="gridCell actions">
        <button
          className="button"
          onClick={onEdit}
          data-qa={ROW_LOCATORS.getAction("edit")}
        >
          Edit
        </button>
        {ssoProvider === null && (
          <button
            className="button buttonAlert buttonOutlined buttonIcon"
            onClick={onDelete}
            data-qa={ROW_LOCATORS.getAction("delete")}
          >
            <IconTrash />
          </button>
        )}
      </span>
    </div>
  );
};

const apiListExtractor = (api: ApiClient) => api.getUsers;
const apiCreateExtractor = (api: ApiClient) => api.createUser;
const apiDeleteExtractor = (api: ApiClient) => api.deleteUser;

export const UsersList = () => {
  const isSuperAdmin = useHasAccess(AclResource.GUEST_DOMAINS_WRITE);

  const [filterParams, setFilterParams] = useState({
    search: "",
    startIndex: 0,
    stopIndex: 30
  });

  const getRoleLabel = useSelector(getRoleLabelFactory);

  const {
    records,
    totalCount,
    fetchList,
    inProgress,
    deleteRecordCommand
  } = useRecordCrud(
    userSchema,
    getUsers,
    apiListExtractor,
    apiDeleteExtractor,
    apiCreateExtractor,
    filterParams
  );
  const { navigateTo, routeParams } = useNavigateTo();

  const addButtonText = isSuperAdmin ? "Add User" : "Invite users";

  const onAdd = useCallback(() => {
    navigateTo(USERS_ADD_ROUTE, { ...routeParams });
  }, [routeParams, navigateTo]);

  const onEditUser = useCallback(
    (id: number) => {
      navigateTo(USERS_EDIT_ROUTE, { ...routeParams, id });
    },
    [navigateTo, routeParams]
  );

  const isRowLoaded = useCallback(
    ({ index }) => {
      return !!records[index];
    },
    [records]
  );

  const loadData = useCallback(
    async ({ startIndex, stopIndex }) => {
      await fetchList({ ...filterParams, startIndex, stopIndex }, true);
    },
    [filterParams, fetchList]
  );

  const onDeleteUser = useCallback(
    async (id: number) => {
      try {
        await deleteRecordCommand.deleteRecord({ id });
        logger.info(`User with id=${id} deleted`);
      } catch (ex) {
        logger.error(ex);
      }
    },
    [deleteRecordCommand]
  );

  const renderRow = useCallback(
    ({ key, index, style }) => {
      const row = records[index];
      const content = row && (
        <UserRow
          {...row}
          getRoleLabel={getRoleLabel}
          onEditUser={onEditUser}
          onDeleteUser={onDeleteUser}
        />
      );
      const placeholder = <div className="gridItemPlaceholder" />;

      return (
        <div key={key} style={style}>
          {inProgress ? placeholder : content}
        </div>
      );
    },
    [records, getRoleLabel, onDeleteUser, onEditUser, inProgress]
  );

  const onSearchChange = useCallback((search: string) => {
    setFilterParams((value) => ({ ...value, search }));
  }, []);

  return (
    <div className="subpage">
      <header className="pageHeader">
        <h1 className="pageHeading" data-qa="tab-header">
          Users
        </h1>
        <div>
          <button
            className="button"
            onClick={onAdd}
            data-qa="users-management-add-user-button"
          >
            <IconAdd />
            {addButtonText}
          </button>
        </div>
      </header>

      <div className="pageContent">
        <div className="search inputWithButton">
          <DebouncedSearchInput
            placeholder="Search filters"
            onChange={onSearchChange}
          />
        </div>
        <div>
          <InfiniteLoader
            minimumBatchSize={20}
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadData}
            rowCount={totalCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height}
                    ref={(el) => {
                      registerChild(el);
                    }}
                    onRowsRendered={onRowsRendered}
                    rowHeight={70}
                    rowCount={totalCount}
                    rowRenderer={renderRow}
                    noContentRenderer={() => (
                      <div className="gridPlaceholder">Nothing</div>
                    )}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      </div>
    </div>
  );
};
