import React from "react";

import { ReactComponent as IconCircleInfo } from "../../../../images/circle-info.svg";

export const CompatibilityInfo = () => (
  <span
    className="wall-dimension-status info"
    title={"Basic compatibility with existing rooms."}
    data-qa="wall-dimension-status"
  >
    <IconCircleInfo className="icon icon-circle-info icon20" />
  </span>
);
