import React from "react";
import { Logger as logger } from "purplex-logging";
import { connect } from "react-redux";
import cx from "classnames";
import { reduxForm, change, submit } from "redux-form";
import { actions as routerActions } from "redux-router5";
import PropTypes from "prop-types";
import { getUser } from "../../auth/auth-selectors";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { PRESENTATIONS_LIST_ROUTE } from "../../routing/route-names";
import {
  getPresentationInitialValues,
  getPresentationValidation
} from "../presentation-selectors";
import { PresentationForm } from "./presentation-form";
import { getRouteName, getRouteParams } from "../../routing/routing-selectors";
import PresentationActions from "../presentation-actions";
import { withSortableDnd } from "../../ux/sortable";
import { ButtonDialog } from "../../ux/button-dialog";
import { withAcl } from "../../acl/with-acl";
import { hasTriggersEnabled } from "../../config/config-selectors";

import { ReactComponent as IconTrash } from "client/images/trash.svg";
import { ReactComponent as IconShare } from "client/images/share-outlined.svg";
import { ReactComponent as IconCog } from "client/images/cog.svg";
import { ReactComponent as IconDrag } from "client/images/drag.svg";

import { UserTrackingActions } from "../../user-tracking/user-tracking-slice";
import * as UserTrackingCreators from "../../user-tracking/user-tracking-creators";
import { PRESENTATION_LOCATORS } from "shared/tests/locators/presentation.locators";

const FORM_NAME = "presentation-edit";

const PresentationEditForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(PresentationForm);

const mapStateToProps = (state) => ({
  presentation: getPresentationInitialValues(state),
  route: getRouteName(state),
  routeParams: getRouteParams(state),
  validate: getPresentationValidation(state),
  currentUser: getUser(state),
  saveInProgress: getProgressForKeySelector(state)(
    PresentationActions.Types.UPDATE_PRESENTATION
  ),
  isRequestPublishInProgress: getProgressForKeySelector(state)(
    PresentationActions.Types.REQUEST_PUBLISH_PRESENTATION
  ),
  isDetachingInProgress: getProgressForKeySelector(state)(
    PresentationActions.Types.DETACH_SCENE
  ),
  triggersEnabled: hasTriggersEnabled(state)
});

const mapDispatchToProps = {
  changeRoute: routerActions.navigateTo,
  resetBlackbeltInstance: () => change(FORM_NAME, "blackbeltInstance", null),
  submitForm: () => submit(FORM_NAME),
  updatePresentation: PresentationActions.Creators.updatePresentation,
  detachScene: PresentationActions.Creators.detachScene,
  shareScene: PresentationActions.Creators.shareScene,
  changeSceneOrder: PresentationActions.Creators.changeSceneOrder,
  requestPublish: PresentationActions.Creators.requestPublishPresentation,
  track: UserTrackingActions.track,
  onEditRequestEditScene: PresentationActions.Creators.requestEditScene
};

class AddNewSceneButton extends React.Component {
  static propTypes = {
    createNewScene: PropTypes.func.isRequired,
    addExistingScene: PropTypes.func.isRequired
  };

  render() {
    const { createNewScene, addExistingScene } = this.props;

    return (
      <ButtonDialog className="item addMore">
        <button
          className="button itemDialog"
          onClick={createNewScene}
          data-qa={PRESENTATION_LOCATORS.buttons.getButton("create-new-scene")}
        >
          Create new scene
        </button>
        <button
          className="button itemDialog"
          onClick={addExistingScene}
          data-qa={PRESENTATION_LOCATORS.buttons.getButton(
            "add-existing-scene"
          )}
        >
          Add existing scene
        </button>
      </ButtonDialog>
    );
  }
}

class DnDSceneTile extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    wall: PropTypes.shape({
      thumbnail: PropTypes.string.isRequired
    }),
    onRemove: PropTypes.func.isRequired,
    isDetachingInProgress: PropTypes.bool,
    onEdit: PropTypes.func,
    onShare: PropTypes.func,
    onOpenSceneEditor: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    dndIsDragging: PropTypes.bool.isRequired,
    dndIsOver: PropTypes.bool.isRequired
  };

  render() {
    const {
      id,
      title,
      wall,
      onRemove,
      isDetachingInProgress,
      onEdit,
      onShare,
      onOpenSceneEditor,
      connectDragSource,
      connectDropTarget,
      dndIsDragging,
      dndIsOver
    } = this.props;

    let backgroundImage;
    if (wall) {
      backgroundImage = `url('${wall.thumbnail}')`;
    }

    return connectDropTarget(
      connectDragSource(
        <div
          className={cx({ item: true, dndIsDragging, dndIsOver })}
          data-qa={PRESENTATION_LOCATORS.scenes.getScene(id)}
        >
          <div className="aside">
            <button
              className="buttonDrag"
              data-qa={PRESENTATION_LOCATORS.scenes.getButton("drag")}
            >
              <IconDrag />
            </button>
            {onShare && (
              <button
                className="buttonShare"
                onClick={onShare}
                title="Share"
                data-qa={PRESENTATION_LOCATORS.scenes.getButton("share")}
              >
                <IconShare />
              </button>
            )}
            {onEdit && (
              <button
                className="buttonEdit"
                onClick={onEdit}
                title="Settings"
                data-qa={PRESENTATION_LOCATORS.scenes.getButton("settings")}
              >
                <IconCog />
              </button>
            )}
            <button
              className={cx("buttonRemove", {
                inProgress: isDetachingInProgress
              })}
              onClick={onRemove}
              title="Remove"
              data-qa={PRESENTATION_LOCATORS.scenes.getButton("delete")}
            >
              <IconTrash />
            </button>
          </div>
          <div
            className="bg"
            onClick={onOpenSceneEditor}
            style={{ backgroundImage }}
          >
            <span
              className="title"
              data-qa={PRESENTATION_LOCATORS.scenes.title}
            >
              {title}
            </span>
          </div>
        </div>
      )
    );
  }
}

const SortableSceneTile = withSortableDnd(
  {
    dndIdentifier: "sceneTile",
    onDndMove: (draggedProps, targetProps) => {
      draggedProps.onMove(
        draggedProps.dndIndex < targetProps.dndIndex
          ? targetProps.order + 1
          : targetProps.order
      );
    }
  },
  DnDSceneTile
);

class PresentationEditImpl extends React.Component {
  static propTypes = {
    presentation: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      presentationScenes: PropTypes.array.isRequired,
      instances: PropTypes.array.isRequired
    }),
    resetBlackbeltInstance: PropTypes.any,
    changeRoute: PropTypes.func.isRequired,
    route: PropTypes.string.isRequired,
    routeParams: PropTypes.object,
    validate: PropTypes.any,
    submitForm: PropTypes.func.isRequired,
    updatePresentation: PropTypes.func.isRequired,
    changeSceneOrder: PropTypes.func.isRequired,
    detachScene: PropTypes.func.isRequired,
    shareScene: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired,
    saveInProgress: PropTypes.func.isRequired,
    isDetachingInProgress: PropTypes.func.isRequired,
    onEditRequestEditScene: PropTypes.func.isRequired,
    track: PropTypes.func.isRequired,
    triggersEnabled: PropTypes.bool.isRequired
  };

  render() {
    const {
      presentation,
      resetBlackbeltInstance,
      changeRoute,
      route,
      routeParams,
      validate,
      submitForm,
      updatePresentation,
      changeSceneOrder,
      detachScene,
      shareScene,
      currentUser,
      saveInProgress,
      isDetachingInProgress,
      track,
      onEditRequestEditScene
    } = this.props;

    if (presentation) {
      const createNewScene = () =>
        changeRoute(`${route}.create-scene`, routeParams);

      const addExistingScene = () =>
        changeRoute(`${route}.attach-scene`, routeParams);

      const openSceneEditor = (presentationId, sceneId) => {
        changeRoute(`presentations.edit.editor.assets`, {
          sceneId,
          presentationId,
          libraryTab: "my-media"
        });
        track(
          UserTrackingCreators.trackEnterSceneEditor("Presentation detail")
        );
      };

      const openScenePreview = (sceneId) => {
        changeRoute(`presentations.edit.preview`, {
          ...routeParams,
          sceneId
        });
      };

      return (
        <>
          <div className="pageContent" data-qa="presentation-content">
            <div className="pageHeader">
              <h1 className="pageTitle" data-qa="presentation-header">
                <span className="pageBreadcrumbs">
                  <button
                    onClick={() =>
                      changeRoute(PRESENTATIONS_LIST_ROUTE, { tab: "own" })
                    }
                  >
                    Presentations
                  </button>
                  /
                </span>
                <span data-qa="presentation-title">{presentation.title}</span>
              </h1>
            </div>
            <h3>Scenes</h3>
            <div
              className="galleryList"
              data-qa={PRESENTATION_LOCATORS.scenes.list}
            >
              {presentation.presentationScenes
                .sort((a, b) => a.order - b.order)
                .map(({ scene, order }, index) => (
                  <SortableSceneTile
                    key={scene.id}
                    {...scene}
                    order={order}
                    dndId={scene.id}
                    dndIndex={index}
                    onRemove={() => detachScene(scene.id)}
                    isDetachingInProgress={isDetachingInProgress(scene.id)}
                    onEdit={
                      currentUser.id === scene.creatorId
                        ? () => onEditRequestEditScene(scene.id)
                        : null
                    }
                    onShare={
                      currentUser.id === scene.creatorId
                        ? () => shareScene(scene.id, scene.title)
                        : null
                    }
                    onOpenSceneEditor={() => {
                      if (scene.creatorId !== currentUser.id && scene.public) {
                        logger.debug(
                          "Opening scene preview instead of scene editor, because scene is global and user is not owner"
                        );
                        openScenePreview(scene.id);
                      } else {
                        openSceneEditor(presentation.id, scene.id);
                      }
                    }}
                    onMove={(newOrder) => changeSceneOrder(scene.id, newOrder)}
                  />
                ))}
              <AddNewSceneButton
                createNewScene={createNewScene}
                addExistingScene={addExistingScene}
              />
            </div>
          </div>
          <div className="pageAside" data-qa="presentation-edit-side-panel">
            <div className="pageAsideHeader">
              <h2>Settings</h2>
              {
                <button
                  className={cx("button", {
                    inProgress: saveInProgress(presentation.id)
                  })}
                  onClick={submitForm}
                  data-qa={PRESENTATION_LOCATORS.buttons.save}
                >
                  Save
                </button>
              }
            </div>
            <PresentationEditForm
              initialValues={presentation}
              resetBlackbeltInstance={resetBlackbeltInstance}
              validate={validate}
              onSubmit={updatePresentation}
              showTriggersButton={true}
            />
          </div>
        </>
      );
    } else {
      return false;
    }
  }
}

export const PresentationEdit = withAcl(
  connect(mapStateToProps, mapDispatchToProps)(PresentationEditImpl)
);
