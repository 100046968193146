import { createActions } from "reduxsauce";

export default createActions(
  {
    createPredefinedSceneName: ["data"],
    predefinedSceneNameCreated: ["predefinedSceneName"],
    fetched: ["totalCount", "data"],
    deleteRecord: ["id"],
    recordDeleted: ["id"],
    reset: null
  },
  { prefix: "predefined-scene-name/" }
);
