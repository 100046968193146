import React, { ReactNode } from "react";
import Tooltip from "rc-tooltip";

const SHORTCUT_SEPARATOR = "+";
const SUPER_KEY = "super";
const SUPER_VALUE = navigator.platform.includes("Mac") ? "⌘" : "Ctrl";

interface Props {
  disabled?: boolean;
  label?: string;
  placement?: "bottom" | "left" | "right" | "top";
  shortcut?: string;
  children: ReactNode;
}

export const Hint = (keyboardHintProps: Props) => {
  const {
    disabled,
    label,
    placement,
    shortcut,
    children,
    ...props
  } = keyboardHintProps;

  if (disabled || !(label || shortcut)) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      mouseEnterDelay={0.5}
      overlay={
        <>
          {label && <span className="hint-label">{label}</span>}
          {shortcut &&
            shortcut.split(SHORTCUT_SEPARATOR).map((short, i) => (
              <kbd key={i} className="hint-key">
                {short.replace(SUPER_KEY, SUPER_VALUE)}
              </kbd>
            ))}
        </>
      }
      placement={placement}
      prefixCls="hint"
      {...props}
    >
      {children as React.ReactElement}
    </Tooltip>
  );
};
