import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  CategoryNode,
  CustomFieldCategoryTree
} from "../filters/category-selector";
import { getAssetCustomFieldCategoryTree } from "../../custom-fields-selectors";
import { ReactComponent as IconSearch } from "../../../../../images/search.svg";

interface UploadMediaCustomFieldsPanelProps {
  selectedCategories: string[];
  hideTree: boolean;
  onRequestChangeSelectedCategories?: () => Promise<boolean>;
  onChangeSelectedCategories: (selectedCategories: number[]) => void;
}

const categoriesToCategoryIds = (categories: string[]) =>
  categories.map((category) => {
    const parts = category.split("/");

    return parseInt(parts[parts.length - 1], 10);
  });

const toggleCategories = (
  categories: string[],
  pathToAdd: string,
  allowTogglingLeafsOnly: boolean,
  discardWholePathToParent: boolean,
  isLeaf?: boolean
) => {
  if (categories.some((category) => category.startsWith(pathToAdd))) {
    // Let's find the category which follows pathToAdd
    const changedCategories = categories
      .map((category) => {
        // This is the category which is in the array and follows pathToAdd
        // and we need to detoggle it
        if (category.startsWith(pathToAdd)) {
          // We suppose here that path that we are adding to the list
          // is already in the list (could be even nested) so the only thing
          // we need to do here is to find the path and replace it by toggled
          // pathToAdd

          // If this flag is enabled
          // it will discard the whole path to the
          // parent when _deselecting_ node
          if (discardWholePathToParent) {
            return null;
          }

          // Otherwise just deselected the last part of path
          const splitted = pathToAdd.split("/");
          splitted.pop();
          return splitted.join("/");
        } else {
          // Leave all the others untouched
          return category;
        }
      })
      .filter(Boolean) as string[]; // It may happen that there will be empty string (when you toggle root category)... so just sanitization here

    return changedCategories;
  } else {
    // This condition makes sure that
    // it's only possible to toggle ONLY
    // leaves - it can be disabled via allowTogglingLeafsOnly
    if (isLeaf || !allowTogglingLeafsOnly) {
      // Adding is simple
      // We only need to make sure that all the related paths will get filtered
      // out so that there are no duplicates.
      const changedCategories = [
        ...categories.filter((category) => !pathToAdd.startsWith(category)),
        pathToAdd
      ];

      return changedCategories;
    } else {
      return categories;
    }
  }
};

export const UploadMediaCustomFieldsPanel: React.FC<UploadMediaCustomFieldsPanelProps> = ({
  selectedCategories,
  hideTree,
  onChangeSelectedCategories,
  onRequestChangeSelectedCategories = () => true
}) => {
  const [searchQuery, changeSearchQuery] = useState("");

  const categoryTree = useSelector(
    getAssetCustomFieldCategoryTree
  ) as CustomFieldCategoryTree[];

  const [selectedCategoriesInternal, setSelectedCategoriesLocal] = useState<
    string[] | null
  >(null);
  const [toggledCategories, setToggledCategories] = useState<string[]>([]);

  // Preselect & open all the categories
  useEffect(() => {
    setSelectedCategoriesLocal((localCategories) => {
      // Only if local categories have not been set
      if (!localCategories) {
        // Pre-open all categories upon mounting
        setToggledCategories(selectedCategories);

        return selectedCategories;
      } else {
        // Ignore all the subsequent calls
        return localCategories;
      }
    });
  }, [setSelectedCategoriesLocal, setToggledCategories, selectedCategories]);

  useEffect(() => {
    setSelectedCategoriesLocal(selectedCategories);
  }, [selectedCategories]);

  const onSelectCategory = useCallback(
    async (pathToAdd: string, isLeaf: boolean) => {
      const proceed = await onRequestChangeSelectedCategories();

      if (proceed) {
        setSelectedCategoriesLocal((currentCategories) => {
          const changedCategories = toggleCategories(
            currentCategories as string[],
            pathToAdd,
            true,
            true,
            isLeaf
          );

          if (changedCategories !== currentCategories) {
            onChangeSelectedCategories(
              categoriesToCategoryIds(changedCategories)
            );
          }

          return changedCategories;
        });
      }
    },
    [
      setSelectedCategoriesLocal,
      onChangeSelectedCategories,
      onRequestChangeSelectedCategories
    ]
  );

  const onToggleCategory = useCallback(
    (pathToAdd: string) => {
      setToggledCategories((categories) =>
        toggleCategories(categories, pathToAdd, false, false)
      );
    },
    [setToggledCategories]
  );

  const changeSearch = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      changeSearchQuery(ev.target.value);
    },
    [changeSearchQuery]
  );

  return (
    <>
      <div className="search">
        <input
          type="text"
          placeholder="Search Category"
          value={searchQuery}
          onChange={changeSearch}
        />
        <IconSearch width={14} height={14} />
      </div>
      {hideTree && (
        <div className="placeholderWrapper">
          <p>Select media to assign categories and filters.</p>
        </div>
      )}
      {!hideTree && (
        <div className="categories-tree">
          <ul>
            {selectedCategoriesInternal &&
              categoryTree.map((node: CustomFieldCategoryTree) => (
                <CategoryNode
                  key={node.id}
                  query={searchQuery}
                  node={node}
                  path={`/${node.id}`}
                  nestingLevel={1}
                  selectedCategories={selectedCategoriesInternal}
                  toggledCategories={toggledCategories}
                  onSelectCategory={onSelectCategory}
                  onToggleCategory={onToggleCategory}
                />
              ))}
          </ul>
        </div>
      )}
    </>
  );
};
