import { createActions } from "reduxsauce";

export default createActions(
  {
    statsFetched: ["stats"],
    resetPresentationCollaborate: null,
    resetPresentationShared: null,
    resetSceneCollaborate: null,
    resetSceneShared: null
  },
  { prefix: "SHARES/" }
);
