import { EditorSettings } from "./../../../../../shared/types/app-setting";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Logger } from "purplex-logging";

import { useApi } from "client/modules/api/use-api";
import { EditorSettingActions } from "../editor-settings-reducer";

export const useSetEditorSettings = () => {
  const api = useApi();
  const dispatch = useDispatch();

  const setEditorSettings = useCallback(
    async (data: EditorSettings) => {
      try {
        const result = await api.setEditorSettings(data);
        dispatch(EditorSettingActions.onEditorSettingsFetched(result.data));
        Logger.debug("Editor settings saved");
        Logger.silly("Editor settings saved", data, result);
      } catch (e) {
        Logger.error("Error while saving editor setings", e, data);
      }
    },
    [api, dispatch]
  );

  return setEditorSettings;
};
