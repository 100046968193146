import React, { useEffect, useState } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import cx from "classnames";

import { DialogEvent } from "../event-types";
import { TextField } from "../../forms/text-field";
import { RootState } from "../../root/root-reducer";
import { ModalDialogBase } from "./modal-dialog-base";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "confirm_dialog_form";

interface FormData {
  confirm: string;
}

interface FormProps {
  fieldLabel: string;
  className?: string;
}

const ConfirmInputForm = reduxForm<FormData, FormProps>({
  form: FORM_NAME
})((props) => {
  const { fieldLabel, className } = props;
  return (
    <form
      className={cx(className)}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <TextField
        name="confirm"
        label={fieldLabel}
        required
        className="confirm-input"
        dataQa="confirm-input"
      />
    </form>
  );
});

export const ConfirmDialogWithInput = (props: {
  open: boolean;
  title: string;
  text: string;
  subtext: string;
  onEvent: (type: DialogEvent) => void;
  confirmLabel: string;
  fieldLabel: string;
  cancelLabel: string;
  confirmText: string;
}) => {
  const {
    open,
    title,
    text,
    subtext,
    onEvent,
    confirmLabel,
    fieldLabel,
    cancelLabel,
    confirmText
  } = props;

  const confirmTextValue = useSelector((state: RootState) =>
    formValueSelector(FORM_NAME)(state, "confirm")
  );

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (confirmTextValue && confirmText) {
      setValid(confirmTextValue.toLowerCase() === confirmText.toLowerCase());
    }
  }, [confirmTextValue, confirmText]);

  return (
    <ModalDialogBase
      open={open}
      title={title}
      text={
        <>
          <div className="modalConfirmText">{text}</div>
          <ConfirmInputForm
            fieldLabel={fieldLabel}
            className="modalConfirmForm"
          />
        </>
      }
      subtext={subtext}
      onEvent={onEvent}
      buttons={
        <React.Fragment>
          <button
            className="button buttonOutlined"
            onClick={() => onEvent(DialogEvent.ON_CANCEL)}
            data-qa={MODAL_LOCATORS.buttons.cancel}
          >
            {cancelLabel || "Cancel"}
          </button>
          <button
            className="button buttonAlert"
            disabled={!valid}
            onClick={() => onEvent(DialogEvent.ON_CONFIRM)}
            data-qa={MODAL_LOCATORS.buttons.confirm}
          >
            {confirmLabel || "Ok"}
          </button>
        </React.Fragment>
      }
    />
  );
};
