import { useApi } from "../../../api/use-api";
import { useCallback } from "react";
import { Logger as logger } from "purplex-logging";
import { notifyError } from "../../../ux/notifications/notifications-saga";
import { useSelector } from "react-redux";
import { getEditedCategoryData } from "../custom-fields-selectors";
import {
  useConfirmationDialog,
  useConfirmationWithInputDialog
} from "../../../dialogs/dialog-hooks";
import { getCustomFieldHierarchies } from "../../../entity-repository/entity-repository-selectors";
import {
  getRouteParams,
  RouteParamSelector
} from "../../../routing/routing-selectors";
import { DialogResult } from "../../../dialogs/event-types";
import { useFetchCustomFields } from "./use-custom-fields";
import { goBack } from "../../../routing/routing-hooks";

export const useCreateCategory = () => {
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();

  return useCallback(
    async (data: {
      label: string;
      path: string;
      parentId: number | null;
      entity: string;
    }) => {
      try {
        await api.createCategory(data);
        await fetchCustomFields();
      } catch (ex) {
        logger.warn("Create Category Error", ex);
        notifyError("Creating of category has failed.");
      }
    },
    [fetchCustomFields, api]
  );
};

export const useEditCategory = () => {
  const editedCategoryData = useSelector(getEditedCategoryData);
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();

  return useCallback(
    async (payload: { label: string; parentId: number }) => {
      if (editedCategoryData) {
        const { path, parentId, customFieldId } = editedCategoryData;
        try {
          await api.editCategory(customFieldId, { ...payload, path, parentId });
          await fetchCustomFields();
          goBack();
        } catch (ex) {
          logger.warn("Edit Category Error", ex);
          notifyError("Editing of category has failed.");
        }
      }
    },
    [api, editedCategoryData, fetchCustomFields]
  );
};

export const useDeleteCategory = () => {
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();
  const confirmDialog = useConfirmationDialog();
  const confirmationWithInputDialog = useConfirmationWithInputDialog();

  const hierarchies = useSelector(getCustomFieldHierarchies);
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{ id: string }>
  );

  return useCallback(
    async (payload: { id: string | number }) => {
      let usedId;
      if (payload && payload.id) {
        usedId = payload.id;
      } else {
        usedId = routeParams && routeParams.id;
      }

      if (!usedId) {
        return;
      }

      const customFieldId = hierarchies[usedId].customField.id;
      const label = hierarchies[usedId].customField.label;
      const { count } = await api.deleteCategoryCount(customFieldId);

      let type;
      if (count === 0) {
        const result = await confirmDialog({
          title: "Delete Category",
          text: `WARNING! Are you sure you want to delete Category ${label}?`,
          subtext: ``,
          confirmLabel: "Delete",
          cancelLabel: "Cancel"
        });
        type = result.type;
      } else {
        const result = await confirmationWithInputDialog({
          title: "Delete Category",
          text: `WARNING! Are you sure you want to delete Category ${label}? Doing so will delete selected categories and values across all entities.`,
          subtext: `Values to be deleted: ${count}`,
          confirmLabel: "Delete",
          cancelLabel: "Cancel",
          fieldLabel: 'Type "LOSE DATA" to proceed',
          confirmText: "LOSE DATA"
        });
        type = result.type;
      }

      if (type === DialogResult.CONFIRM) {
        try {
          await api.deleteCategory(customFieldId);
          await fetchCustomFields();
          if (routeParams && routeParams.id) goBack();
        } catch (ex) {
          logger.warn("Delete Category error", ex);
          notifyError("Deleting of category has failed.");
        }
      }
    },
    [
      hierarchies,
      api,
      confirmationWithInputDialog,
      confirmDialog,
      routeParams,
      fetchCustomFields
    ]
  );
};
