export class ResourceDoesNotExist {}

export class BusinessError {
  reason: string;
  constructor(reason: string) {
    this.reason = reason;
  }
}
export class ServiceUnavailableError {
  reason: string;
  constructor(reason: string) {
    this.reason = reason;
  }
}
export class UnauthorizedAccess {}
export class CrossGuestDomainAccessDenied {}
