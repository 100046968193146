import React from "react";
import { connect } from "react-redux";

import { getUploadingProgress } from "../media-selectors";

const mapStateToProps = (state) => ({
  progress: getUploadingProgress(state)
});

export const UploadingBar = connect(mapStateToProps)(({ progress }) => {
  return (
    progress &&
    progress < 100 && (
      <span
        className="uploadingBar"
        style={{ transform: `translate3d(${progress}vw, 0, 0)` }}
      />
    )
  );
});
