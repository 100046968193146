import { useDispatch, useStore } from "react-redux";
import {
  getActiveWallInfo,
  getHorizontalCenterHelper,
  getIsDragging,
  getSelectAssetBounds,
  getSelectionBoundingBox,
  getVerticalCenterHelper,
  getEditingTextAssetId,
  getDraggingOffset
} from "../../scene-editor-selectors";
import React, { useCallback, useEffect, useRef } from "react";
import { Logger as logger } from "purplex-logging";

import { SceneEditorActions } from "../../scene-editor-reducer";
import { useDraggingActions } from "../asset-config/use-dragging-actions";
import { useGetMouseMovement } from "./utils/use-mouse-movement";

const CENTER_HELPER_THRESHOLD = 25;
const DRAGGING_CHANGE_THRESHOLD = 2;

export const useGetVerticallyBoundedValue = () => {
  const store = useStore();

  return useCallback(
    (left: number) => {
      const bounds = getSelectAssetBounds(store.getState());
      const activeWallInfo = getActiveWallInfo(store.getState());

      if (bounds && activeWallInfo) {
        return Math.max(
          bounds.leftMin + activeWallInfo.start,
          Math.min(left, bounds.leftMax + activeWallInfo.start)
        );
      } else {
        logger.warn("Error getting correct D&D bounds");
        return 0;
      }
    },
    [store]
  );
};

export const useGetHorizontallyBoundedValue = () => {
  const store = useStore();

  return useCallback(
    (top: number) => {
      const bounds = getSelectAssetBounds(store.getState());
      const activeWallInfo = getActiveWallInfo(store.getState());
      if (bounds && activeWallInfo) {
        return Math.max(bounds.topMin || 0, Math.min(top, bounds.topMax || 0));
      } else {
        return 0;
      }
    },
    [store]
  );
};

export const useGetVerticallyCenteredValue = () => {
  const store = useStore();
  const dispatch = useDispatch();

  const showVerticalCenterHelper = useCallback(() => {
    dispatch(SceneEditorActions.showVerticalCenterHelper());
  }, [dispatch]);

  const hideVerticalCenterHelper = useCallback(() => {
    dispatch(SceneEditorActions.hideVerticalCenterHelper());
  }, [dispatch]);

  return useCallback(
    (left: number, width: number) => {
      const activeWallInfo = getActiveWallInfo(store.getState());
      const verticalCenter =
        activeWallInfo && (activeWallInfo.end - activeWallInfo.start) / 2;
      const isVerticalCenterHelperShown = getVerticalCenterHelper(
        store.getState()
      );

      if (!activeWallInfo) {
        return left;
      }
      const isNearCenter =
        verticalCenter &&
        Math.abs(verticalCenter + activeWallInfo.start - left - width / 2) <
          CENTER_HELPER_THRESHOLD;

      if (isNearCenter && verticalCenter) {
        if (!isVerticalCenterHelperShown) {
          showVerticalCenterHelper();
        }
        return verticalCenter + activeWallInfo.start - width / 2;
      } else {
        if (isVerticalCenterHelperShown) {
          hideVerticalCenterHelper();
        }
        return left;
      }
    },
    [store, showVerticalCenterHelper, hideVerticalCenterHelper]
  );
};

export const useGetHorizontallyCenteredValue = () => {
  const store = useStore();
  const dispatch = useDispatch();

  const showHorizontalCenterHelper = useCallback(() => {
    dispatch(SceneEditorActions.showHorizontalCenterHelper());
  }, [dispatch]);

  const hideHorizontalCenterHelper = useCallback(() => {
    dispatch(SceneEditorActions.hideHorizontalCenterHelper());
  }, [dispatch]);

  return useCallback(
    (top: number, height: number) => {
      const activeWallInfo = getActiveWallInfo(store.getState());
      const horizontalCenter = activeWallInfo && activeWallInfo.height / 2;
      const isHorizontalCenterHelperShown = getHorizontalCenterHelper(
        store.getState()
      );

      const isNearCenter =
        horizontalCenter &&
        height < horizontalCenter * 2 &&
        Math.abs(horizontalCenter - top - height / 2) < CENTER_HELPER_THRESHOLD;

      if (isNearCenter && horizontalCenter) {
        if (!isHorizontalCenterHelperShown) {
          showHorizontalCenterHelper();
        }
        return horizontalCenter - height / 2;
      } else {
        if (isHorizontalCenterHelperShown) {
          hideHorizontalCenterHelper();
        }
        return top;
      }
    },
    [store, showHorizontalCenterHelper, hideHorizontalCenterHelper]
  );
};

export const useDragging = (canvasScale: number) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { setDragPosition } = useDraggingActions();
  const getMouseMovement = useGetMouseMovement();

  const applyVerticalWallBounding = useGetVerticallyBoundedValue();
  const applyHorizontalWallBounding = useGetHorizontallyBoundedValue();
  const applyVerticalCenterHelper = useGetVerticallyCenteredValue();
  const applyHorizontalCenterHelper = useGetHorizontallyCenteredValue();

  const draggingOffset = useRef({
    draggingOffsetY: 0,
    draggingOffsetX: 0
  });

  const draggingMouseUpHandler = useCallback(
    (e: MouseEvent) => {
      if (e.button !== 0) {
        return;
      }

      const dragging = getIsDragging(store.getState());
      const editingAsset = getEditingTextAssetId(store.getState());

      if (dragging && !editingAsset) {
        dispatch(
          SceneEditorActions.setDragging({
            dragging: false
          })
        );

        const offset = getDraggingOffset(store.getState());
        if (
          Math.abs(offset.offsetX) + Math.abs(offset.offsetY) >
          DRAGGING_CHANGE_THRESHOLD
        ) {
          setDragPosition({
            //TODO for some reason there needs to be at least 1 to render correctly - why?
            offsetX: Math.round(offset.offsetX) + 1,
            offsetY: Math.round(offset.offsetY) + 1
          });

          draggingOffset.current = {
            draggingOffsetY: 0,
            draggingOffsetX: 0
          };
          dispatch(
            SceneEditorActions.setDraggingOffset({
              offsetX: 0,
              offsetY: 0
            })
          );
        }
        return;
      }
    },
    [dispatch, setDragPosition, store, draggingOffset]
  );

  const draggingMouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      const dragging = getIsDragging(store.getState());
      const editingAsset = getEditingTextAssetId(store.getState());
      const boundingBox = getSelectionBoundingBox(store.getState());

      const { movementX, movementY } = getMouseMovement(e);

      if (dragging && !editingAsset) {
        draggingOffset.current = {
          draggingOffsetX:
            draggingOffset.current.draggingOffsetX - movementX / canvasScale,
          draggingOffsetY:
            draggingOffset.current.draggingOffsetY - movementY / canvasScale
        };

        if (
          boundingBox &&
          dragging &&
          Math.abs(draggingOffset.current.draggingOffsetX) +
            Math.abs(draggingOffset.current.draggingOffsetY) >
            DRAGGING_CHANGE_THRESHOLD
        ) {
          const left =
            boundingBox.left + draggingOffset.current.draggingOffsetX;
          const boundedLeft = applyVerticalWallBounding(left);
          const centeredLeft = applyVerticalCenterHelper(
            boundedLeft,
            boundingBox.right - boundingBox.left
          );

          const top = boundingBox.top + draggingOffset.current.draggingOffsetY;
          const boundedTop = applyHorizontalWallBounding(top);
          const centeredTop = applyHorizontalCenterHelper(
            boundedTop,
            boundingBox.bottom - boundingBox.top
          );

          dispatch(
            SceneEditorActions.setDraggingOffset({
              offsetX: centeredLeft - boundingBox.left,
              offsetY: centeredTop - boundingBox.top
            })
          );
        }

        return;
      }
    },
    [
      dispatch,
      canvasScale,
      store,
      draggingOffset,
      applyVerticalWallBounding,
      applyVerticalCenterHelper,
      applyHorizontalWallBounding,
      applyHorizontalCenterHelper,
      getMouseMovement
    ]
  );

  const draggingContextMenuHandler = useCallback(
    (e: MouseEvent) => {
      const dragging = getIsDragging(store.getState());

      if (dragging) {
        e.preventDefault();
      }
    },
    [store]
  );

  useEffect(() => {
    document.addEventListener("mousemove", draggingMouseMoveHandler);
    document.addEventListener("mouseup", draggingMouseUpHandler);
    window.addEventListener("contextmenu", draggingContextMenuHandler);

    return () => {
      document.removeEventListener("mousemove", draggingMouseMoveHandler);
      document.removeEventListener("mouseup", draggingMouseUpHandler);
      window.removeEventListener("contextmenu", draggingContextMenuHandler);
    };
  }, [
    draggingMouseUpHandler,
    draggingMouseMoveHandler,
    draggingContextMenuHandler
  ]);
};

export const useOnMouseDownDragStart = () => {
  const dispatch = useDispatch();
  const store = useStore();

  return useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const editingTextAsset = getEditingTextAssetId(store.getState());

      if (!editingTextAsset && e.button === 0) {
        dispatch(SceneEditorActions.setDragging({ dragging: true }));
      }
    },
    [dispatch, store]
  );
};
