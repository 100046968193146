import React from "react";
import { useSelector } from "react-redux";
import {
  getIsDragging,
  getVerticalCenterHelper
} from "../../scene-editor-selectors";

export const VerticalCenterHelper = ({
  canvasScale
}: {
  canvasScale: number;
}) => {
  const isVerticalCenterHelperShown = useSelector(getVerticalCenterHelper);
  const isDragging = useSelector(getIsDragging);

  if (!isDragging || !isVerticalCenterHelperShown) {
    return <> </>;
  }

  return (
    <div
      style={{
        left: "50%",
        position: "absolute",
        width: 1 / canvasScale,
        height: "100%",
        transform: "translateX(-50%)",
        background: "#fc4444"
      }}
    />
  );
};
