import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "redux-form";
import { ReactComponent as IconFolder } from "../../images/folder.svg";
import { ReactComponent as IconFolderOpened } from "../../images/folder-open.svg";
import { ReactComponent as IconSubFolder } from "../../images/sub-folder.svg";
import { ReactComponent as IconSubFolderOpened } from "../../images/sub-folder-open.svg";

const CustomSelect = ({ input, optionTree, label }) => {
  const [openedChild, setOpenedChild] = useState(null);

  const change = useCallback(
    (val) => {
      input.onChange(val);
    },
    [input]
  );

  return (
    <div>
      <div className="treeLabel">{label}</div>
      <div>
        {optionTree.map((node) => (
          <CustomSelectNode
            key={node.id}
            node={node}
            onChange={change}
            value={input.value}
            child={false}
            opened={openedChild === node.id}
            onToggle={() => {
              setOpenedChild((state) => (state === node.id ? null : node.id));
            }}
          />
        ))}
      </div>
    </div>
  );
};

CustomSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func
  }).isRequired,
  optionTree: PropTypes.array.isRequired,
  label: PropTypes.string
};

const CustomSelectNode = ({
  node,
  onChange,
  value,
  child,
  opened: openedProps,
  onToggle
}) => {
  const [opened, setOpened] = useState(false);
  const [openedChild, setOpenedChild] = useState(null);

  const changeHandler = (e) => {
    e.stopPropagation();
    onChange(node.id);
    onToggle();
  };

  useEffect(() => {
    setOpened(openedProps);
  }, [openedProps]);

  const isChildSelected = useCallback(
    (node) => {
      if (node.id === value) return true;
      return node.children.some(isChildSelected);
    },
    [value]
  );

  useEffect(() => {
    const hasChildren = node.children.some(isChildSelected);
    if (hasChildren) onToggle();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="treeNode">
      <div
        className={cx("treeItem", {
          selected: value === node.id,
          opened
        })}
        onClick={changeHandler}
      >
        {child ? (
          opened ? (
            <IconSubFolderOpened />
          ) : (
            <IconSubFolder />
          )
        ) : opened ? (
          <IconFolderOpened />
        ) : (
          <IconFolder />
        )}
        {node.label}
      </div>
      {opened && (
        <div className="treeSubNode">
          {node.children.map((child) => (
            <CustomSelectNode
              key={child.id}
              node={child}
              onChange={onChange}
              value={value}
              child={true}
              opened={child.id === openedChild}
              onToggle={() => {
                setOpenedChild((state) =>
                  state === child.id ? null : child.id
                );
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

CustomSelectNode.propTypes = {
  node: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number.isRequired,
    children: PropTypes.array
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  child: PropTypes.bool.isRequired,
  opened: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export const HierarchicalSelectField = (props) => (
  <Field component={CustomSelect} {...props} />
);

HierarchicalSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  optionTree: PropTypes.array.isRequired,
  label: PropTypes.string
};
