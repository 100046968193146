import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DialogEvent, DialogResult } from "./event-types";
import { DialogType } from "./dialog-type";

export interface DialogsState {
  type: DialogType | null;
  open: boolean;
  config: { [key: string]: any };
  event?: {
    type: DialogEvent | DialogResult;
    payload: any;
  };
}

const initialState: DialogsState = {
  type: null,
  open: false,
  config: {},
  event: undefined
};

const { actions, reducer } = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    open: (state) => ({
      ...state,
      open: true
    }),
    close: (state) => ({
      ...state,
      open: false
    }),
    configure: (
      state,
      action: PayloadAction<{
        dialogType: DialogType;
        config: { [key: string]: any };
      }>
    ) => ({
      ...state,
      config: action.payload.config,
      type: action.payload.dialogType
    }),
    onEvent: (
      state,
      action: PayloadAction<{
        eventType: DialogEvent | DialogResult;
        eventPayload?: any;
      }>
    ) => ({
      ...state,
      event: {
        type: action.payload.eventType,
        payload: action.payload.eventPayload
      }
    }),
    cleanUp: () => initialState
  }
});

export const DialogsActions = actions;
export const dialogsReducer = reducer;
