import { Logger as logger } from "purplex-logging";
import { schema, normalize } from "normalizr";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { ApiClient, useApi } from "../api/use-api";
import { LongTask } from "./crud-types";
import {
  EntityRepositoryActions,
  EntityRepositoryHasChangedAction
} from "../entity-repository/entity-repository-reducer";

export interface CreatedEntity {
  success: boolean;
  id: string | number | null;
  ex?: Error;
}

export interface CreateRecordCommand<F> extends LongTask {
  createRecord: (inputEntity: F) => Promise<CreatedEntity>;
}

export type ApiExtractorCreate<CallParams, Result> = (
  api: ApiClient
) => (data: CallParams) => Promise<Result>;

export function useCreateRecord<Result, CallParams, ENormalized>(
  entitySchema: schema.Entity<ENormalized>,
  apiExtractor: ApiExtractorCreate<CallParams, Result>
): CreateRecordCommand<CallParams> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const dispatch = useDispatch();
  const api = useApi();

  const createRecord = useCallback(
    async (inputForm: CallParams) => {
      try {
        logger.debug("Requesting to create record", inputForm);
        setInProgress(true);

        const apiCall = apiExtractor(api);
        const entity = await apiCall(inputForm);
        const { entities: repository, result: id } = normalize<
          ENormalized,
          EntityRepositoryHasChangedAction,
          number
        >(entity, entitySchema);

        dispatch(EntityRepositoryActions.repositoryHasChanged({ repository }));

        //TODO add created id to proper slice, need action as param

        logger.debug("Entity has been succesfully created.", entity);

        return { success: true, id };
      } catch (ex) {
        logger.error("There was an error during creating record - ", ex);
        return { success: false, id: null, ex };
      } finally {
        setInProgress(false);
      }
    },
    [api, dispatch, entitySchema, apiExtractor]
  );

  return { createRecord, inProgress };
}
