import React from "react";
import { useSelector } from "react-redux";

import {
  getActiveWallInfo,
  getIsScalingShown,
  getIsCroppingShown,
  getSelectionBoundingBox,
  getIsBoundingBox,
  getIsResizingShown,
  getDraggingOffset,
  getCroppingChange,
  getScaleChange,
  getScaleOrigin,
  getResizingChange,
  getResizingOrigin
} from "../../scene-editor-selectors";
import { getScaledCoords, useScaling } from "../../hooks/dragging/use-scaling";
import {
  getCropHeight,
  getCropWidth,
  useCropping
} from "../../hooks/dragging/use-cropping";
import {
  getResizedCoords,
  useResizing
} from "../../hooks/dragging/use-resizing";

export const BoundingBox = ({ canvasScale }: { canvasScale: number }) => {
  const boundingBox = useSelector(getSelectionBoundingBox);
  const isBoundingBox = useSelector(getIsBoundingBox);
  const wallInfo = useSelector(getActiveWallInfo);

  const isCroppingShown = useSelector(getIsCroppingShown);
  const isScalingShown = useSelector(getIsScalingShown);
  const isResizingShown = useSelector(getIsResizingShown);

  const dragOffset = useSelector(getDraggingOffset);

  const {
    scalingMouseDownHandlerLeftTop,
    scalingMouseDownHandlerLeftBottom,
    scalingMouseDownHandlerRightTop,
    scalingMouseDownHandlerRightBottom
  } = useScaling(canvasScale);
  const scalingChange = useSelector(getScaleChange);
  const scalingOrigin = useSelector(getScaleOrigin);

  const {
    cropMouseDownHandlerLeft,
    cropMouseDownHandlerTop,
    cropMouseDownHandlerBottom,
    cropMouseDownHandlerRight
  } = useCropping(canvasScale);
  const croppingChange = useSelector(getCroppingChange);

  const {
    resizeMouseDownHandlerRightBottom,
    resizeMouseDownHandlerLeftTop,
    resizeMouseDownHandlerLeftBottom,
    resizeMouseDownHandlerRightTop
  } = useResizing(canvasScale);
  const resizingChange = useSelector(getResizingChange);
  const resizingOrigin = useSelector(getResizingOrigin);

  if (!boundingBox || !wallInfo) {
    return <></>;
  }

  const left = boundingBox.left + dragOffset.offsetX;
  const top = boundingBox.top + dragOffset.offsetY;
  const width = boundingBox.right - boundingBox.left;
  const height = boundingBox.bottom - boundingBox.top;

  const resizedCoords = getResizedCoords(
    resizingOrigin,
    resizingChange.widthChange,
    resizingChange.heightChange,
    left,
    top,
    width,
    height
  );

  const scaledCoords = getScaledCoords(
    scalingOrigin,
    resizedCoords.left,
    resizedCoords.top,
    resizedCoords.width,
    resizedCoords.height,
    scalingChange
  );

  const scaledAndCroppedCords = {
    left:
      scaledCoords.left +
      Math.min(croppingChange.cropOffsetX, scaledCoords.width) -
      wallInfo.start,
    top:
      scaledCoords.top +
      Math.min(croppingChange.cropOffsetY, scaledCoords.height),
    width: getCropWidth(scaledCoords.width + croppingChange.cropWidth),
    height: getCropHeight(scaledCoords.height + croppingChange.cropHeight)
  };

  const handleBorderWidth = 1 / canvasScale;
  const resizeHandleSize = 7 / canvasScale;
  const cropHandleLength = 20 / canvasScale;
  const cropHandleThickness = 4 / canvasScale;

  return (
    <>
      {isBoundingBox && (
        <div
          className="boundingBox"
          style={{
            left: scaledAndCroppedCords.left,
            top: scaledAndCroppedCords.top,
            width: scaledAndCroppedCords.width,
            height: scaledAndCroppedCords.height,
            boxShadow: `inset 0 0 0 ${1 / canvasScale}px #43B0FF`,
            pointerEvents: "none"
          }}
        />
      )}
      {isScalingShown && (
        <>
          <div
            onMouseDown={scalingMouseDownHandlerLeftTop}
            className="boundingBoxResizeHandle"
            data-qa="scaling-handle-left-top"
            style={{
              width: resizeHandleSize,
              height: resizeHandleSize,
              borderWidth: handleBorderWidth,
              top: scaledAndCroppedCords.top,
              left: scaledAndCroppedCords.left,
              cursor: "nw-resize"
            }}
          />
          <div
            onMouseDown={scalingMouseDownHandlerRightTop}
            className="boundingBoxResizeHandle"
            data-qa="scaling-handle-right-top"
            style={{
              width: resizeHandleSize,
              height: resizeHandleSize,
              borderWidth: handleBorderWidth,
              top: scaledAndCroppedCords.top,
              left: scaledAndCroppedCords.left + scaledAndCroppedCords.width,
              cursor: "ne-resize"
            }}
          />
          <div
            onMouseDown={scalingMouseDownHandlerLeftBottom}
            className="boundingBoxResizeHandle"
            data-qa="scaling-handle-left-bottom"
            style={{
              width: resizeHandleSize,
              height: resizeHandleSize,
              borderWidth: handleBorderWidth,
              top: scaledAndCroppedCords.top + scaledAndCroppedCords.height,
              left: scaledAndCroppedCords.left,
              cursor: "sw-resize"
            }}
          />
          <div
            onMouseDown={scalingMouseDownHandlerRightBottom}
            className="boundingBoxResizeHandle"
            data-qa="scaling-handle-right-bottom"
            style={{
              width: resizeHandleSize,
              height: resizeHandleSize,
              borderWidth: handleBorderWidth,
              top: scaledAndCroppedCords.top + scaledAndCroppedCords.height,
              left: scaledAndCroppedCords.left + scaledAndCroppedCords.width,
              cursor: "se-resize"
            }}
          />
        </>
      )}
      {isCroppingShown && (
        <>
          <div
            onMouseDown={cropMouseDownHandlerRight}
            className="boundingBoxCropHandle"
            style={{
              width: cropHandleThickness,
              height: cropHandleLength,
              cursor: "e-resize",
              borderWidth: handleBorderWidth,
              borderLeftWidth: 0,
              borderTopRightRadius: cropHandleThickness,
              borderBottomRightRadius: cropHandleThickness,
              top:
                scaledAndCroppedCords.top +
                scaledAndCroppedCords.height / 2 -
                cropHandleLength / 2,
              left: scaledAndCroppedCords.left + scaledAndCroppedCords.width
            }}
          />
          <div
            onMouseDown={cropMouseDownHandlerLeft}
            className="boundingBoxCropHandle"
            style={{
              position: "absolute",
              width: cropHandleThickness,
              height: cropHandleLength,
              cursor: "w-resize",
              borderWidth: handleBorderWidth,
              borderRightWidth: 0,
              borderTopLeftRadius: cropHandleThickness,
              borderBottomLeftRadius: cropHandleThickness,
              top:
                scaledAndCroppedCords.top +
                scaledAndCroppedCords.height / 2 -
                cropHandleLength / 2,
              left: scaledAndCroppedCords.left - cropHandleThickness
            }}
          />
          <div
            onMouseDown={cropMouseDownHandlerTop}
            className="boundingBoxCropHandle"
            style={{
              width: cropHandleLength,
              height: cropHandleThickness,
              cursor: "n-resize",
              borderWidth: handleBorderWidth,
              borderBottomWidth: 0,
              borderTopLeftRadius: cropHandleThickness,
              borderTopRightRadius: cropHandleThickness,
              top: scaledAndCroppedCords.top - cropHandleThickness,
              left:
                scaledAndCroppedCords.left +
                scaledAndCroppedCords.width / 2 -
                cropHandleLength / 2
            }}
          />
          <div
            onMouseDown={cropMouseDownHandlerBottom}
            className="boundingBoxCropHandle"
            style={{
              width: cropHandleLength,
              height: cropHandleThickness,
              cursor: "s-resize",
              borderWidth: handleBorderWidth,
              borderTopWidth: 0,
              borderBottomLeftRadius: cropHandleThickness,
              borderBottomRightRadius: cropHandleThickness,
              top: scaledAndCroppedCords.top + scaledAndCroppedCords.height,
              left:
                scaledAndCroppedCords.left +
                scaledAndCroppedCords.width / 2 -
                cropHandleLength / 2
            }}
          />
        </>
      )}

      {isResizingShown && (
        <>
          <div
            onMouseDown={resizeMouseDownHandlerRightBottom}
            className="boundingBoxResizeHandle"
            data-qa="resize-handle-right-bottom"
            style={{
              width: resizeHandleSize,
              height: resizeHandleSize,
              cursor: "nwse-resize",
              top: scaledAndCroppedCords.top + scaledAndCroppedCords.height,
              left: scaledAndCroppedCords.left + scaledAndCroppedCords.width
            }}
          />
          <div
            onMouseDown={resizeMouseDownHandlerLeftBottom}
            className="boundingBoxResizeHandle"
            data-qa="resize-handle-left-bottom"
            style={{
              position: "absolute",
              width: resizeHandleSize,
              height: resizeHandleSize,
              cursor: "nesw-resize",
              top: scaledAndCroppedCords.top + scaledAndCroppedCords.height,
              left: scaledAndCroppedCords.left
            }}
          />
          <div
            onMouseDown={resizeMouseDownHandlerRightTop}
            className="boundingBoxResizeHandle"
            data-qa="resize-handle-right-top"
            style={{
              width: resizeHandleSize,
              height: resizeHandleSize,
              cursor: "nesw-resize",
              top: scaledAndCroppedCords.top,
              left: scaledAndCroppedCords.left + scaledAndCroppedCords.width
            }}
          />
          <div
            onMouseDown={resizeMouseDownHandlerLeftTop}
            className="boundingBoxResizeHandle"
            data-qa="resize-handle-left-top"
            style={{
              width: resizeHandleSize,
              height: resizeHandleSize,
              cursor: "nwse-resize",
              top: scaledAndCroppedCords.top,
              left: scaledAndCroppedCords.left
            }}
          />
        </>
      )}
    </>
  );
};
