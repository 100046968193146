//TODO complete all types in custom field module

import { CustomFieldType } from "../../../../shared/types/custom-field-type";
import { CustomFieldEntity } from "../../../../shared/types/custom-field-entity";

export type CustomFieldMappingCategoryTreeNode = {
  id: number;
  label: string;
  type: CustomFieldType;
  path: string;
  service: boolean;
  children: CustomFieldMappingCategoryTreeNode[];
};

export type EditedCategoryCategoryTreeNode = {
  id: number;
  label: string;
  type: CustomFieldType;
  path: string;
  sort: number;
  customFieldId: number;
  children: EditedCategoryCategoryTreeNode[];
};

export enum AddFilterRouteParentParam {
  ROOT = "root",
  NONE = "none"
}

export type CategoryTreeNode = {
  id: number;
  label: string;
  type: CustomFieldType;
  path: string;
  sort: number;
  service: boolean;
  customFieldId: number;
  values: string[];
  children: CategoryTreeNode[];
};

export interface CustomField {
  id: number;
  label: string;
  type: CustomFieldType;
  entityMappings: EntityMapping[];
  values: { value: string }[];
}

export interface EntityMapping {
  id: number;
  customFieldId: number;
  entity: CustomFieldEntity;
  filterable: boolean;
  required: boolean;
  customFieldHierarchies: EntityHierarchy[];
}

export interface EntityHierarchy {
  id: number;
  sort: number;
  path: string;
  parentId: number;
  customFieldToEntityMappingId: number;
}

export interface CustomFieldChoiceValue {
  value: string;
  id?: number;
  toBeDeleted?: boolean;
  count?: number;
}
