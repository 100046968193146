import { getCreateabeAssetTypes } from "./asset-types";

/* eslint one-var: 0 */
export const ASSET_IMAGE = ["image/png", "image/jpeg", "image/gif"];
export const ASSET_WALL_IMAGE = ["image/png", "image/jpeg"];
export const ASSET_VIDEO = [
  "video/mp4",
  "video/mov",
  "video/quicktime",
  "video/webm"
];
export const ASSET_WALL_VIDEO = ASSET_VIDEO;
export const ASSET_SOUND = [
  "audio/mp3",
  "audio/mpeg",
  /* 'audio/mp4',*/ "audio/ogg"
];
export const ASSET_PDF = ["application/pdf"];
export const ASSET_PRESENTATION = [
  ".ppt",
  ".pptx",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
];

export const ASSET_MIMES_BY_ASSET_TYPE: {
  [key: string]: string[];
} = {
  ASSET_IMAGE,
  ASSET_VIDEO,
  ASSET_SOUND,
  ASSET_PDF,
  ASSET_PRESENTATION,
  ASSET_WALL_IMAGE,
  ASSET_WALL_VIDEO
};

export const ASSET_MIMES: string[] = ([] as string[]).concat(
  ASSET_IMAGE,
  ASSET_VIDEO,
  ASSET_SOUND,
  ASSET_PDF,
  ASSET_PRESENTATION
);

export const WALL_ASSET_MIMES: string[] = ([] as string[]).concat(
  ASSET_WALL_IMAGE,
  ASSET_WALL_VIDEO
);

const flatten = (memo: string[], value: string[]): string[] => {
  memo.push(...value);
  return memo;
};

export const getCreatableAssetMimes = (
  disabledAssetTypes: string[]
): string[] => {
  return getCreateabeAssetTypes(disabledAssetTypes)
    .map((assetType) => ASSET_MIMES_BY_ASSET_TYPE[assetType] || [])
    .filter((types) => types.length > 0)
    .reduce(flatten, [] as string[]);
};

export const CREATABLE_WALL_MIMES: string[] = WALL_ASSET_MIMES.flat();

export const getAssetType = (mime: string, isWall: boolean) => {
  if (ASSET_IMAGE.includes(mime)) {
    return isWall ? "ASSET_WALL_IMAGE" : "ASSET_IMAGE";
  } else if (ASSET_VIDEO.includes(mime)) {
    return isWall ? "ASSET_WALL_VIDEO" : "ASSET_VIDEO";
  } else if (ASSET_SOUND.includes(mime)) {
    return "ASSET_SOUND";
  } else if (ASSET_PDF.includes(mime)) {
    return "ASSET_PDF";
  } else if (ASSET_PRESENTATION) {
    return "ASSET_PRESENTATION";
  } else {
    return null;
  }
};

export default ASSET_MIMES_BY_ASSET_TYPE;
