import { useCallback, useEffect, useState } from "react";
import { Logger as logger } from "purplex-logging";

import { useApi } from "../../../api/use-api";
import { useNotifications } from "../../../ux/notifications/use-notifications";

export const useSignUpLink = () => {
  const [signUpLink, setSignUpLinks] = useState<{
    allowedEmailDomains: string[];
    token: string;
  }>();

  const api = useApi();
  const { notifySuccess, notifyError } = useNotifications();

  useEffect(() => {
    (async () => {
      try {
        const result = await api.fetchSignUpLink();
        setSignUpLinks(result);
      } catch (e) {
        logger.debug("There is no sign up link for this domain.");
      }
    })();
  }, [api]);

  const generateLink = useCallback(
    async (data: { allowedEmailDomains: string }) => {
      try {
        const result = await api.generateNewSignUpLink(data);
        setSignUpLinks(result.data);
        notifySuccess("New sign up link generated!");
      } catch (e) {
        logger.error("Error generating new sign up link", e);
        notifyError("There was an error generating new link.");
      }
    },
    [api, notifyError, notifySuccess]
  );

  const updateAllowedDomains = useCallback(
    async (data: { allowedEmailDomains: string }) => {
      try {
        const result = await api.updateSignUpDomains(data);
        setSignUpLinks(result.data);
        notifySuccess("Allowed domains updated!");
      } catch (e) {
        logger.error("Error updating allowedEmailDomains", e);
      }
    },
    [api, notifySuccess]
  );

  return {
    signUpLink,
    generateLink,
    updateAllowedDomains
  };
};
