import { createReducer } from "reduxsauce";

import Actions from "client/modules/settings/predefined-scene-names/predefined-scene-names-actions";

const INITIAL_STATE = {
  totalCount: null,
  predefinedSceneNames: []
};

const onFetched = (state, { totalCount, data }) => ({
  ...state,
  totalCount,
  predefinedSceneNames: [...state.predefinedSceneNames, ...data]
});

const onCreated = (state, { predefinedSceneName }) => ({
  ...state,
  predefinedSceneNames: [predefinedSceneName, ...state.predefinedSceneNames]
});

const onRecordDeleted = (state, { id }) => ({
  ...state,
  totalCount: state.totalCount - 1,
  predefinedSceneNames: state.predefinedSceneNames.filter(
    (predefinedSceneName) => predefinedSceneName.id !== id
  )
});

const onReset = () => INITIAL_STATE;

export const HANDLERS = {
  [Actions.Types.FETCHED]: onFetched,
  [Actions.Types.PREDEFINED_SCENE_NAME_CREATED]: onCreated,
  [Actions.Types.RECORD_DELETED]: onRecordDeleted,
  [Actions.Types.RESET]: onReset
};

export default createReducer(INITIAL_STATE, HANDLERS);
