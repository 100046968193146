import React from "react";
import { createPortal } from "react-dom";
import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";

import { CustomFieldsCategoryForm } from "./custom-fields-category-form";
import {
  getEditedCategorySelectParentTree,
  getEditedCategoryInitialValues
} from "../../custom-fields-selectors";
import {
  useDeleteCategory,
  useEditCategory
} from "../../hooks/use-category-crud";
import { EditedCategoryCategoryTreeNode } from "../../custom-field-types";

export const EDIT_CATEGORY_FORM_NAME = "custom-field-category-edit";

interface FormData {
  label: string;
  parentId: number;
}

const validate = (values: FormData) => {
  const errors: Partial<Record<keyof FormData, string>> = {};
  if (!values.label) {
    errors.label = `Value is required`;
  }
  return errors;
};

const CustomFieldsEditCategoryForm = reduxForm<
  FormData,
  {
    categoryTree: EditedCategoryCategoryTreeNode[];
    onDelete: (payload: { id: string | number }) => void;
  }
>({
  form: EDIT_CATEGORY_FORM_NAME,
  validate,
  enableReinitialize: true
})(CustomFieldsCategoryForm);

export const CustomFieldsCategoryEdit = () => {
  const initialValues = useSelector(getEditedCategoryInitialValues);
  const categoryTree = useSelector(getEditedCategorySelectParentTree);

  const editCategory = useEditCategory();
  const deleteCategory = useDeleteCategory();

  return createPortal(
    <CustomFieldsEditCategoryForm
      initialValues={initialValues}
      categoryTree={categoryTree}
      onSubmit={editCategory}
      onDelete={deleteCategory}
    />,
    document.getElementById("modal") as Element
  );
};
