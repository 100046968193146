import { useCallback } from "react";
import { AssetType } from "../../../../shared/types/asset-type";
import { useStore } from "react-redux";
import { getActiveWallInfo } from "../scene-editor-selectors";

export const useAssetRenderedDimensions = () => {
  const store = useStore();

  const getAssetWidth = useCallback(
    (asset: {
      asset: { type: AssetType };
      cropWidth: number;
      scale: number;
    }) => {
      const wallInfo = getActiveWallInfo(store.getState());

      if (!wallInfo) {
        return 0;
      }

      if (asset.asset.type === AssetType.ASSET_TEXT) {
        return (((asset.cropWidth * wallInfo.width) / 100) * asset.scale) / 100;
      }
      return (asset.cropWidth * asset.scale) / 100;
    },
    [store]
  );

  const getAssetHeight = useCallback(
    (asset: {
      asset: { type: AssetType };
      cropHeight: number;
      scale: number;
    }) => {
      const wallInfo = getActiveWallInfo(store.getState());
      if (!wallInfo) {
        return 0;
      }

      if (asset.asset.type === AssetType.ASSET_TEXT) {
        return (
          (((asset.cropHeight * wallInfo.height) / 100) * asset.scale) / 100
        );
      }
      return (asset.cropHeight * asset.scale) / 100;
    },
    [store]
  );

  return { getAssetWidth, getAssetHeight };
};
