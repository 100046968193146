import { Logger as logger } from "purplex-logging";
import { actions as routerActions } from "redux-router5";
import { all, call, takeEvery, put, fork, select } from "redux-saga/effects";
import { CloningErrors } from "../../../shared/types/bussiness-errors";
import { takeEveryWithCheckProgress } from "../in-progress/in-progress-saga";

import { onRouteEntered } from "../routing/on-route-enter-saga";
import { getRouteParams } from "../routing/routing-selectors";
import { normalizeAndStore } from "../entity-repository/entity-repository-saga";
import { scene as sceneSchema } from "../entity-repository/schema";
import * as api from "../api/client";
import SceneActions from "./scene-actions";
import {
  confirmDialogSaga,
  userPickerDialogSaga
} from "../dialogs/dialogs-saga";
import {
  notifyError,
  notifySuccess,
  notifyWarn
} from "../ux/notifications/notifications-saga";
import { BusinessError } from "../api/client-errors";
import { DialogResult } from "../dialogs/event-types";
import * as UserTrackingSaga from "../user-tracking/user-tracking-saga";
import * as UserTrackingCreators from "../user-tracking/user-tracking-creators";

const historyBack = () => window.history.back();

export const sanitizeSceneTitle = (scene) => {
  if (!scene.title) {
    return {
      ...scene,
      title: scene.title_text
    };
  } else {
    return scene;
  }
};

function* onSceneEnterEdit() {
  const { sceneId } = yield select(getRouteParams);
  try {
    const scene = yield call(api.fetchScene, sceneId);
    yield call(normalizeAndStore, scene, sceneSchema);
  } catch (e) {
    logger.error(`Failed to load the scene ${sceneId}`, e);
    yield call(notifyError, "Failed to load the scene");
  }
}

function* onCreateScene({ data }) {
  try {
    const scene = yield call(api.createScene, sanitizeSceneTitle(data));
    yield call(normalizeAndStore, scene, sceneSchema);

    const { tab } = yield select(getRouteParams);
    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackSceneCreated(
        "Scenes overview",
        tab,
        data,
        scene.title
      )
    );

    yield call(
      closeDialogAndRefreshScenes("Scene has been successfully created.")
    );
  } catch (e) {
    logger.error(`Failed to create the scene`, e);
    yield call(notifyError, "Failed to create the scene");
  }
}

const closeDialogAndRefreshScenes = (message) =>
  function* closeDialogAndRefreshScenes() {
    yield call(historyBack);
    yield put(SceneActions.Creators.incrementLoadingKey());
    yield call(notifySuccess, message);
  };

function* onEditScene({ data }) {
  try {
    const id = data.id;

    const updatedScene = yield call(
      api.updateScene,
      id,
      sanitizeSceneTitle(data)
    );
    yield call(normalizeAndStore, updatedScene, sceneSchema);

    const { tab } = yield select(getRouteParams);

    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackSceneChange("Scenes overview", data, tab)
    );

    yield call(
      closeDialogAndRefreshScenes("Scene has been successfully updated.")
    );
  } catch (e) {
    logger.error(`Failed to update the scene`, e);
    yield call(notifyError, "Failed to update the scene");
  }
}

function* onDeleteScene({ id, goBack }) {
  try {
    yield call(api.deleteScene, id);

    yield put(SceneActions.Creators.incrementLoadingKey());
    yield call(notifySuccess, "Scene has been successfully deleted.");
    if (goBack) {
      yield call(historyBack);
    }
  } catch (e) {
    logger.error(`Failed to delete the scene`, e);
    yield call(notifyError, "Failed to delete the scene");
  }
}

function* onCloneScene({ id }) {
  const { type } = yield call(confirmDialogSaga, {
    title: "Clone Scene",
    text: `Are you sure you want to clone this scene?`,
    subtext: "",
    confirmLabel: "Clone",
    cancelLabel: "Cancel"
  });

  if (type === DialogResult.CONFIRM) {
    try {
      yield call(api.cloneScene, id);

      yield put(SceneActions.Creators.incrementLoadingKey());
      yield call(notifySuccess, "Scene has been successfully cloned.");
    } catch (e) {
      if (e instanceof BusinessError) {
        if (e.error === CloningErrors.NOT_READY_ASSET) {
          yield call(
            notifyError,
            `Scene has not been cloned because its media are currently being processed.`
          );
          return;
        }

        if (e.error === CloningErrors.CORRUPTED_ASSET) {
          yield call(
            notifyError,
            `Scene has not been cloned because its media are corrupted. Please contact scene's owner.`
          );
          return;
        }
      }

      logger.error(`Failed to clone the scene`, e);
      yield call(notifyError, "Failed to clone the scene");
    }
  }
}

function* onShareScene({ id, title }) {
  const userId = yield call(userPickerDialogSaga, {
    title: "Share Scene",
    fieldLabel: "Share with"
  });

  if (userId) {
    try {
      yield call(api.shareScene, id, userId);

      yield call(notifySuccess, `Scene ${title} has been successfully shared.`);
    } catch (ex) {
      if (ex instanceof BusinessError) {
        yield call(
          notifyWarn,
          `Scene ${title} has already been shared with the user.`
        );
        return;
      }
      yield call(notifyError, "Failed to share the scene");
      logger.error(ex);
    }
  }
}

function* onDeleteSceneShare({ id, title }) {
  const { type } = yield call(confirmDialogSaga, {
    title: "Delete Share Request",
    text: `Are you sure you want to delete share request of "${title}" scene?`,
    subtext: "",
    confirmLabel: "Delete",
    cancelLabel: "Cancel"
  });

  if (type === DialogResult.CONFIRM) {
    try {
      yield call(api.deleteSceneShare, id);
      yield put(SceneActions.Creators.incrementLoadingKey());

      yield call(
        notifySuccess,
        `Shared scene ${title} has been successfully removed.`
      );
    } catch (e) {
      logger.error(`Failed to remove the shared scene ${id}`, e);
      yield call(notifyError, "Failed to remove the shared scene");
    }
  }
}

function* onRequestEdit({ sceneId, tab }) {
  yield put(routerActions.navigateTo("scenes.list.edit", { sceneId, tab }));
  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackSceneChangeRequested("Scenes overview", tab)
  );
}

export default function* () {
  yield all([
    takeEvery(SceneActions.Types.REQUEST_EDIT_SCENE, onRequestEdit),
    takeEveryWithCheckProgress(SceneActions.Types.CREATE_SCENE, onCreateScene),
    takeEveryWithCheckProgress(
      SceneActions.Types.EDIT_SCENE,
      onEditScene,
      ({ data: { id } }) => id
    ),
    takeEveryWithCheckProgress(
      SceneActions.Types.DELETE_SCENE,
      onDeleteScene,
      ({ id }) => id
    ),
    takeEveryWithCheckProgress(
      SceneActions.Types.CLONE_SCENE,
      onCloneScene,
      ({ id }) => id
    ),
    takeEvery(SceneActions.Types.SHARE_SCENE, onShareScene),
    takeEveryWithCheckProgress(
      SceneActions.Types.DELETE_SHARE,
      onDeleteSceneShare,
      ({ id }) => id
    ),
    fork(onRouteEntered, "scenes.list.edit", onSceneEnterEdit)
  ]);
}
