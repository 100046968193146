import { validate as validateEmail } from "email-validator";
import moment from "moment";

const REQUIRED_FIELDS = ["title"];

function countUniqueChars(string) {
  return new Set(String(string)).size;
}

const validate = (remoteAccessName, roomName) => (values) => {
  const errors = {};

  const invalidFields = [...REQUIRED_FIELDS].filter(
    (field) => !values[field] || values[field].trim() === ""
  );

  if (!values.instances || values.instances.length === 0) {
    errors.instances = `At least one ${roomName} needs to be assigned`;
  }

  if (values.blackbelt) {
    if (!values.blackbeltEmail || !validateEmail(values.blackbeltEmail)) {
      errors.blackbeltEmail = `Please provide valid ${remoteAccessName} e-mail`;
    }

    if (!moment.isMoment(values.blackbeltFrom)) {
      errors.blackbeltFrom = "Please provide presentation Start date time";
    }

    if (!moment.isMoment(values.blackbeltTill)) {
      errors.blackbeltTill = "Please provide presentation End date time";
    }

    if (
      moment.isMoment(values.blackbeltFrom) &&
      moment.isMoment(values.blackbeltTill) &&
      values.blackbeltFrom.isAfter(values.blackbeltTill)
    ) {
      const errorMessage = "Start needs to be before End!";
      errors.blackbeltFrom = errorMessage;
      errors.blackbeltTill = errorMessage;
    }

    if (!values.blackbeltInstance) {
      errors.blackbeltInstance = `Please provide instance where ${remoteAccessName} session takes the place`;
    }
  }

  if (values.pinProtected) {
    if (String(values.pin).length !== 4) {
      errors.pin = "PIN must contain 4 digits";
    }

    if (countUniqueChars(values.pin) === 1) {
      errors.pin = "PIN must not contain 4 same digits";
    }
  }

  return invalidFields.reduce((memo, field) => {
    memo[field] = `${field} is required`;
    return memo;
  }, errors);
};

export default validate;
