import React, { useCallback } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { reduxForm, change, submit } from "redux-form";
import { connect } from "react-redux";
import { actions as routerActions } from "redux-router5";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import PresentationActions from "../presentation-actions";
import { PresentationForm } from "./presentation-form";
import { getRouteName, getRouteParams } from "../../routing/routing-selectors";
import { getPresentationValidation } from "../presentation-selectors";
import { getUser } from "../../auth/auth-selectors";
import { getPresentationInitialCustomFields } from "../../settings/custom-fields/custom-fields-selectors";
import { Modal } from "../../ux/modal";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "presentation-add";

const PresentationCreateForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(PresentationForm);

const PresentationAddImpl = ({
  resetBlackbeltInstance,
  changeRoute,
  route,
  routeParams,
  validate,
  currentUser,
  addPresentation,
  submitForm,
  saveInProgress,
  customFields
}) => {
  const closeModal = useCallback(() => {
    changeRoute(`${route.replace(".add", "")}`, routeParams);
  }, [changeRoute, route, routeParams]);

  useEscapeHook(true, closeModal);

  return (
    <Modal
      title="Presentation Settings"
      className="modalBoxSm"
      onCloseClick={closeModal}
      headerProps={{ "data-qa": "presentation-create-new-header" }}
      closeButtonProps={{ "data-qa": "presentation-create-new-close-btn" }}
      footer={
        <button
          className={cx("button", {
            inProgress: saveInProgress()
          })}
          onClick={submitForm}
          data-qa={MODAL_LOCATORS.buttons.save}
        >
          Save
        </button>
      }
      data-qa={MODAL_LOCATORS.getModal("presentation-settings")}
    >
      <PresentationCreateForm
        initialValues={{
          creatorId: currentUser.id,
          public: false,
          blackbelt: false,
          pinProtected: false,
          customFields
        }}
        resetBlackbeltInstance={resetBlackbeltInstance}
        validate={validate}
        onSubmit={addPresentation}
        showTriggersButton={false}
      />
    </Modal>
  );
};

PresentationAddImpl.propTypes = {
  resetBlackbeltInstance: PropTypes.func.isRequired,
  changeRoute: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  routeParams: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  addPresentation: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  saveInProgress: PropTypes.func.isRequired,
  customFields: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  route: getRouteName(state),
  routeParams: getRouteParams(state),
  validate: getPresentationValidation(state),
  currentUser: getUser(state),
  saveInProgress: getProgressForKeySelector(state)(
    PresentationActions.Types.ADD_PRESENTATION
  ),
  customFields: getPresentationInitialCustomFields(state)
});

const mapDispatchToProps = {
  addPresentation: PresentationActions.Creators.addPresentation,
  changeRoute: routerActions.navigateTo,
  resetBlackbeltInstance: () => change(FORM_NAME, "blackbeltInstance", null),
  submitForm: () => submit(FORM_NAME)
};

export const PresentationAdd = connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentationAddImpl);
