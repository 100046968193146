import React, { useState } from "react";
import {
  DecoratedFormProps,
  InjectedFormProps,
  reduxForm,
  SubmissionError
} from "redux-form";
import cx from "classnames";

import { TextField } from "../../../../forms/text-field";
import { ReactComponent as IconAdd } from "../../../../../images/add.svg";
import { ReactComponent as IconFolder } from "../../../../../images/folder.svg";
import { validateCategoryLabel } from "../../../../api/client";
import { ReactComponent as IconDelete } from "../../../../../images/btn-delete.svg";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

interface AddFormData {
  label: string;
}

interface CustomFieldsCategoryAddProps {
  onSubmit: (values: AddFormData) => void;
  child?: boolean;
  onEditing?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  parentId: number | null;
}

interface CustomFieldsCategoryAddPropsInjected
  extends InjectedFormProps<AddFormData, CustomFieldsCategoryAddProps>,
    CustomFieldsCategoryAddProps {}

const validate = (
  values: AddFormData,
  props: CustomFieldsCategoryAddProps &
    DecoratedFormProps<AddFormData, CustomFieldsCategoryAddProps>
) => {
  if ((!values.label || values.label.trim() === "") && props.submitting) {
    return { label: "Value is required" };
  }
  return {};
};

const asyncValidate = async (values: AddFormData, parentId: number | null) => {
  const errors: Partial<Record<keyof AddFormData, string>> = {};
  const { valid } = await validateCategoryLabel({
    label: values.label,
    parentId
  });
  if (!valid) {
    errors.label = "Category label must be unique";
  }
  return errors;
};

const CustomFieldsCategoryAdd = (
  props: CustomFieldsCategoryAddPropsInjected
) => {
  const {
    handleSubmit,
    onSubmit,
    reset,
    onEditing,
    onCancel,
    disabled,
    parentId
  } = props;
  const [toggled, setToggled] = useState(false);

  const toggle = (): void => {
    if (disabled) {
      return;
    }
    setToggled(!toggled);
    if (onEditing && !toggled) onEditing();
    if (onCancel && toggled) onCancel();
  };

  const submit = handleSubmit((values) => {
    return (async () => {
      const syncErrors = validate(values, props as any);
      const asyncErrors = await asyncValidate(values, parentId);
      const errors = {
        ...syncErrors,
        ...asyncErrors
      };

      if (errors.label) {
        throw new SubmissionError(errors);
      } else {
        toggle();
        onSubmit(values);
        reset && reset();
        return;
      }
    })();
  });

  return (
    <>
      {!toggled ? (
        <button
          className={cx({
            gridRowDnD: true,
            addItemButton: true,
            child: props.child
          })}
          onClick={toggle}
          data-qa="btn-add-category"
        >
          <IconAdd />
          Add Category
        </button>
      ) : (
        <form
          className="gridRow categoryForm"
          onSubmit={submit}
          data-qa="form-add-category"
        >
          <span className="gridIcon">
            <IconFolder />
          </span>
          <TextField
            name="label"
            placeholder="Category name"
            required
            focusOnMount
          />
          <span className="gridActions">
            <button
              type="submit"
              className="button"
              data-qa={FORM_LOCATORS.buttons.getButton("save")}
            >
              Save
            </button>
            <button
              type="button"
              className="button buttonIcon outlined danger"
              onClick={toggle}
              data-qa={FORM_LOCATORS.buttons.getButton("delete")}
            >
              <IconDelete />
            </button>
          </span>
        </form>
      )}
    </>
  );
};

const FORM_NAME = "custom-field-category-add";

export const CustomFieldsCategoryAddForm = reduxForm<
  AddFormData,
  CustomFieldsCategoryAddProps
>({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  initialValues: {
    label: ""
  }
})(CustomFieldsCategoryAdd);
