import { delay } from "redux-saga/effects";
import { Logger as logger } from "purplex-logging";
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
import { includesSegment } from "router5-helpers";
import { getFormValues } from "redux-form";
import { change } from "redux-form";

import { messageDialogSaga } from "../../dialogs/dialogs-saga";
import { getAssets } from "../../entity-repository/entity-repository-selectors";
import { uploadAsset } from "../../media/media-saga";
import { UploadStatus } from "../../media/upload-statuses";
import { onRouteEntered } from "../../routing/on-route-enter-saga";
import {
  assets as assetsSchema,
  asset as assetSchema
} from "../../entity-repository/schema";
import { normalizeAndStore } from "../../entity-repository/entity-repository-saga";
import { getAssetType } from "../../media/asset-mime-types";
import MediaActions from "../../media/media-actions";
import { SceneEditorActions } from "../scene-editor-reducer";
import * as SceneEditorSelectors from "../scene-editor-selectors";
import * as RouterSelectors from "../../routing/routing-selectors";
import * as Api from "../../api/client";
import { notifyError } from "../../ux/notifications/notifications-saga";
import { ASSET_LIBRARY_FORM_NAME } from "./components/asset-library-media";
import { AssetLibraryActions } from "./asset-library-reducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { AssetStatus } from "shared/types/asset-status";
import { notifyAssetUploadSuccess } from "../../media/media-saga";
import { getUser } from "client/modules/auth/auth-selectors";
import * as UserTrackingSaga from "../../user-tracking/user-tracking-saga";
import * as UserTrackingCreators from "../../user-tracking/user-tracking-creators";
import { UserTrackingActions } from "../../user-tracking/user-tracking-slice";
import { isWallsPageActive } from "../../routing/routing-selectors";

const { AssetType } = require("../../../../shared/types/asset-type");

const assetLibraryFormSelector = getFormValues(ASSET_LIBRARY_FORM_NAME);

export const COLUMN_COUNT = 5;
const DEFAULT_URL_ASSET_DIMENSION = {
  WIDTH: 1500,
  HEIGHT: 800
};

const withLoading = (fn: (...args: any[]) => any) =>
  function* (...args: any[]) {
    yield put(AssetLibraryActions.setLoading());
    try {
      // @ts-ignore
      return yield call<any>(fn, ...args);
    } finally {
      yield put(AssetLibraryActions.resetLoading());
    }
  };

function* getType() {
  const routeName = yield select(RouterSelectors.getRouteName);
  const routeIncludes = includesSegment(routeName);
  if (routeIncludes("backgrounds")) {
    return "walls";
  } else if (routeIncludes("transitions")) {
    return "transitions";
  } else {
    return "assets";
  }
}

function* fetchAssets(payload: { startIndex: number; stopIndex: number }) {
  const { startIndex, stopIndex } = payload;
  try {
    const sceneId = yield select(SceneEditorSelectors.getSceneId);
    const formData = yield select(assetLibraryFormSelector);
    const type = yield call(getType);

    yield put(
      AssetLibraryActions.assetsFetchingStarted({ startIndex, stopIndex })
    );

    const { rows: assets, count } = yield call(
      Api.fetchAssetsBySafeRange,
      stopIndex - startIndex + 1,
      startIndex,
      {
        type,
        sceneId,
        query: formData && formData.query
      }
    );

    const assetIds = yield call(normalizeAndStore, assets, assetsSchema);
    yield put(
      AssetLibraryActions.assetsFetched({ assets: assetIds, count, startIndex })
    );
  } catch (e) {
    logger.error("Fetch assets error", e);
    yield call(notifyError, "An error occurred while loading media files.");
  }
}

function* resetAssetLibrary() {
  yield put(AssetLibraryActions.clearAssets());
}

function* onFetchData(
  action: PayloadAction<{
    startIndex: number;
    stopIndex: number;
    done: () => void;
  }>
) {
  const { startIndex, stopIndex, done } = action.payload;
  yield call(fetchAssets, { startIndex, stopIndex });
  yield call(done);
}

const onQueryChanged = withLoading(function* onQueryChangedInternal() {
  // Make sure query is debounced
  yield delay(300);

  // After debouncing let's just clear the assets
  yield put(AssetLibraryActions.clearAssets());
});

function* uploadFiles(files: File[], isWall: boolean, parentAsset?: any) {
  let checkedFiles;

  if (parentAsset) {
    checkedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (getAssetType(file.type, isWall) === parentAsset.type) {
        checkedFiles.push(file);
      } else {
        // @ts-ignore
        yield call(messageDialogSaga, {
          title: "Invalid File Type",
          text: `File ${file.name} doesn't have allowed format for this background. This file will not be uploaded.`
        });
      }
    }
  } else {
    checkedFiles = files;
  }

  if (checkedFiles.length === 0) {
    return;
  }

  logger.info(`Got request to upload ${checkedFiles.length} files`);

  const uploaded = [];

  for (let i = 0; i < checkedFiles.length; i++) {
    const file = checkedFiles[i];

    try {
      let asset;

      if (parentAsset) {
        asset = yield call(Api.createWallAlternativeAsset, parentAsset.uuid);
        const updatedParent = yield call(Api.getWall, parentAsset.uuid);
        yield call(normalizeAndStore, updatedParent, assetSchema);
      } else {
        const apiCreateCall = isWall ? Api.createWallAsset : Api.createAsset;
        const type = getAssetType(file.type, isWall);
        // @ts-ignore
        asset = yield call(apiCreateCall, file.name, file.name, type);
      }

      const assetUuid = yield call(
        normalizeAndStore,
        { ...asset, fileVersion: 1 },
        assetSchema
      );

      const uploadResult = yield call(uploadAsset, asset.uuid, file);

      logger.info(
        `File #${i + 1} out of ${
          checkedFiles.length
        } files uploaded - status: ${uploadResult}, uuid: ${assetUuid}`
      );

      switch (uploadResult) {
        case UploadStatus.SUCCESS:
          uploaded.push({
            file,
            asset,
            uuid: assetUuid
          });
          break;

        case UploadStatus.CANCELLED:
        case UploadStatus.FAILED:
        default:
          logger.info(
            `Upload of asset/wall canceled/failed -> deleting the entity uuid=${asset.uuid}`
          );
          // eslint-disable-next-line no-case-declarations
          const apiDeleteCall = isWall ? Api.deleteWall : Api.deleteAsset;

          try {
            // @ts-ignore
            yield call(apiDeleteCall, asset.uuid);
          } catch (e) {
            logger.info(`Delete of asset/wall failed, uuid=${asset.uuid}`);
          }
      }
    } catch (e) {
      logger.error(
        `File #${i + 1} out of ${checkedFiles.length} files upload error`,
        e
      );
    }
  }

  return uploaded;
}

function* onFilesDropped(
  action: PayloadAction<{ acceptedFiles: File[]; wallAssets: boolean }>
) {
  const { acceptedFiles: files, wallAssets: isWall } = action.payload;
  let totalSavedAssets = 0;
  try {
    // @ts-ignore
    const uploaded = yield call(uploadFiles, files, isWall);

    const routeName = yield select(RouterSelectors.getRouteName);
    const routeIncludes = includesSegment(routeName);

    if (!routeIncludes("asset-library")) {
      // do not show the "asset rename/discard after upload dialog" if the asset library is closed
      return;
    }

    for (let i = 0; i < uploaded.length; i++) {
      const { uuid, file, asset } = uploaded[i];
      yield put(
        AssetLibraryActions.openAssetEdit({
          assetUuid: uuid,
          assetIndex: i,
          totalAssets: uploaded.length
        })
      );

      const { roles } = yield select(getUser);
      const { tab } = yield select(
        RouterSelectors.getRouteParams as RouterSelectors.RouteParamSelector<{
          tab: string;
        }>
      );

      const { save, discard } = yield race({
        save: take(AssetLibraryActions.saveAsset),
        discard: take(AssetLibraryActions.discardAsset)
      });

      if (save) {
        const title = save.payload.data.title;

        yield call(
          UserTrackingSaga.track,
          UserTrackingCreators.trackAssetUploaded(
            "Asset library",
            asset.type,
            roles,
            tab
          )
        );

        if (title !== file.name) {
          logger.info(
            `User requested to change name of file from ${file.name} to ${title}`
          );

          const apiUpdateCall = isWall ? Api.updateWall : Api.updateAsset;
          // @ts-ignore
          const updatedAsset = yield call(apiUpdateCall, uuid, title);
          yield call(normalizeAndStore, updatedAsset, assetSchema);
        }

        totalSavedAssets += 1;
      } else if (discard) {
        const apiDeleteCall = isWall ? Api.deleteWall : Api.deleteAsset;
        // @ts-ignore
        yield call(apiDeleteCall, uuid);
        logger.info(`User requested to discard asset ${uuid}`);
      } else {
        logger.error("Unsupported state");
      }
    }
  } catch (e) {
    logger.error(`An error occurred while processing dropped files.`, e);
    yield call(notifyError, "An error occurred while processing files.");
  } finally {
    yield put(AssetLibraryActions.closeEditingAsset());

    if (totalSavedAssets > 0) {
      yield put(change(ASSET_LIBRARY_FORM_NAME, "query", ""));
    }

    yield put(AssetLibraryActions.reloadAssets());
  }
}

function* onAddUrlAsset(
  action: PayloadAction<{ data: { title: string; url: string } }>
) {
  const {
    data: { title, url }
  } = action.payload;

  // @ts-ignore
  const asset = yield call(Api.createAsset, title, title, AssetType.ASSET_URL, {
    url,
    width: DEFAULT_URL_ASSET_DIMENSION.WIDTH,
    height: DEFAULT_URL_ASSET_DIMENSION.HEIGHT
  });

  yield call(normalizeAndStore, { ...asset, fileVersion: 1 }, assetSchema);

  yield put(change(ASSET_LIBRARY_FORM_NAME, "query", ""));
  yield put(AssetLibraryActions.reloadAssets());
}

function* onAssetDeleted(action: PayloadAction<{ assetUuid: string }>) {
  // @ts-ignore // it seems some refactoring but "assetUuid" arrived without payload wrapper
  const { assetUuid } = action;
  yield put(AssetLibraryActions.removeAsset({ assetUuid }));

  const assets = yield select(getAssets);
  const asset = assets[assetUuid];

  const { roles } = yield select(getUser);
  const wallsPage = yield select(isWallsPageActive);
  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackAssetDeleted(
      wallsPage ? "Backgrounds Overview" : "Scene Editor",
      asset.type,
      roles
    )
  );

  if (!asset) {
    return;
  }

  const parentAsset = assets[asset.parentUuid];

  if (!parentAsset) {
    return;
  }

  yield put(AssetLibraryActions.reloadAssets());
}

function* createWallAlternativeAssets(
  action: PayloadAction<{
    parentAsset: any;
    files: File[];
  }>
) {
  const { parentAsset, files } = action.payload;
  // @ts-ignore
  yield call(uploadFiles, files, true, parentAsset);
}

function* onAssetRemotelyUpdated(
  action: PayloadAction<{
    uuid: string;
  }>
) {
  // @ts-ignore
  const uuid = action.uuid;

  const assets = yield select(getAssets);
  const asset = assets[uuid];
  const assetStatus = asset && asset.status;
  if (assetStatus === AssetStatus.STATUS_READY) {
    yield call(notifyAssetUploadSuccess, uuid);
  }

  yield put(AssetLibraryActions.reloadAssets());
}

function* assetSearchTracking() {
  const { assetAssigned, timeout } = yield race({
    assetAssigned: take([
      SceneEditorActions.setSceneTransitionAsset.type,
      SceneEditorActions.setSceneWallTrackingAction.type,
      UserTrackingActions.createSceneAssetTrackingEvent.type
    ]),
    timeout: delay(10000)
  });

  const routeName = yield select(RouterSelectors.getRouteName);

  if (assetAssigned) {
    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackSceneEditorAssetSearched(routeName, true)
    );
  } else if (timeout) {
    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackSceneEditorAssetSearched(routeName, false)
    );
  }
}

export default function* () {
  yield all([
    fork(
      onRouteEntered,
      "scenes.list.edit.editor.assets.asset-library",
      resetAssetLibrary
    ),
    fork(
      onRouteEntered,
      "presentations.edit.editor.assets.asset-library",
      resetAssetLibrary
    ),
    fork(
      onRouteEntered,
      "scenes.list.edit.editor.backgrounds.asset-library",
      resetAssetLibrary
    ),
    fork(
      onRouteEntered,
      "presentations.edit.editor.backgrounds.asset-library",
      resetAssetLibrary
    ),
    fork(
      onRouteEntered,
      "scenes.list.edit.editor.transitions.asset-library",
      resetAssetLibrary
    ),
    fork(
      onRouteEntered,
      "presentations.edit.editor.transitions.asset-library",
      resetAssetLibrary
    ),
    takeEvery(AssetLibraryActions.fetchData, onFetchData),
    takeLatest(AssetLibraryActions.queryChanged, onQueryChanged),
    takeEvery(AssetLibraryActions.filesDropped, onFilesDropped),
    takeLatest(AssetLibraryActions.addUrlAsset, onAddUrlAsset),
    takeEvery(MediaActions.Types.ASSET_DELETED, onAssetDeleted),
    takeEvery(
      AssetLibraryActions.createWallAlternativeAssets,
      createWallAlternativeAssets
    ),
    takeEvery(
      MediaActions.Types.ASSET_REMOTELY_UPDATED,
      onAssetRemotelyUpdated
    ),
    takeLatest(AssetLibraryActions.queryChanged.type, assetSearchTracking)
  ]);
}
