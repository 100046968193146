import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { getScene } from "client/modules/scene-editor/scene-editor-selectors";

import { useDropWall } from "../../scene-editor-dnd";
import { AssignedWall } from "./assigned-wall";
import { BackgroundPlaceholder } from "./background-placeholder";
import { SceneEditorActions } from "../../scene-editor-reducer";

// temporal solution until typescript redux forms are not addressed
const TransitionSettingForm = require("./transition-setting-form").default;

const TransitionPanel = ({
  scene: { transitionAsset },
  setSceneTransitionAsset,
  removeTransitionAsset
}: any) => {
  const [{ canDrop, wallOver }, dropRef] = useDropWall(
    transitionAsset ? transitionAsset.uuid : null,
    setSceneTransitionAsset
  );
  return (
    <div
      className={cx("backgrounds", {
        droppable: canDrop
      })}
      ref={dropRef}
      data-qa="transitions-panel"
    >
      {wallOver ? (
        <AssignedWall asset={wallOver} onRemoveClick={() => {}} />
      ) : transitionAsset ? (
        <AssignedWall
          asset={transitionAsset}
          onRemoveClick={removeTransitionAsset}
        >
          <TransitionSettingForm asset={transitionAsset} />
        </AssignedWall>
      ) : (
        <BackgroundPlaceholder />
      )}
    </div>
  );
};

TransitionPanel.propTypes = {
  scene: PropTypes.shape({
    transitionAsset: PropTypes.object
  }).isRequired,
  setSceneTransitionAsset: PropTypes.func.isRequired,
  removeTransitionAsset: PropTypes.func.isRequired
};

const mapStateToProps = (state: any) => ({
  scene: getScene(state)
});

const mapDispatchToProps = {
  removeTransitionAsset: SceneEditorActions.removeTransitionAsset,
  setSceneTransitionAsset: SceneEditorActions.setSceneTransitionAsset
};

export default connect(mapStateToProps, mapDispatchToProps)(TransitionPanel);
