import { ImInstance } from "../../../shared/types/im-instance";
import { dimensionsFitRecommendedBackground } from "../../../shared/scene-transformations/sap/sap-scene-transformations";

export const isSAPBackgroundValidForScene = (
  wallAsset: {
    dimensions: { width: number; height: number };
    alternativeAssets: { dimensions: { width: number; height: number } }[];
  },
  instance: ImInstance,
  backgroundSettings: { width: number } | undefined
) => {
  if (!instance) {
    return false;
  }

  if (backgroundSettings && instance.roomDimensions) {
    return backgroundSettings.width >= instance.roomDimensions.width;
  }

  if (
    !instance.recommendedBackgrounds ||
    instance.recommendedBackgrounds.length === 0
  ) {
    return false;
  }

  const assets = [wallAsset, ...wallAsset.alternativeAssets];

  const { recommendedBackgrounds } = instance;

  return assets.some(({ dimensions }) =>
    recommendedBackgrounds.some((background) =>
      dimensionsFitRecommendedBackground(dimensions, background)
    )
  );
};

export const isSapWallAssetOptimalForInstance = (
  wallAsset: {
    title: string;
    dimensions: { width: number; height: number };
    alternativeAssets: {
      dimensions: { width: number; height: number };
    }[];
  },
  instance: ImInstance
) => {
  if (!instance) {
    return false;
  }

  if (
    !instance.recommendedBackgrounds ||
    instance.recommendedBackgrounds.length === 0
  ) {
    return false;
  }

  const assets = [wallAsset, ...wallAsset.alternativeAssets];

  const { recommendedBackgrounds } = instance;

  return assets.some(({ dimensions }) =>
    recommendedBackgrounds.some((background) => {
      return dimensionsFitRecommendedBackground(dimensions, background);
    })
  );
};

export const userShouldBeWarnedAboutFittingSAPBackground = (
  wallAsset: {
    dimensions: { width: number; height: number };
    alternativeAssets: { dimensions: { width: number; height: number } }[];
  },
  instance: ImInstance,
  backgroundSettings: { width: number } | undefined
) => {
  return isSAPBackgroundValidForScene(wallAsset, instance, backgroundSettings);
};

export const SAP_WALL_ASSET_OK_FOR_ROOM =
  "Background's dimensions fit this room.";

export const getWarningMessage = (
  recommendedBackgrounds: {
    width: number;
    height: number;
  }[]
) => {
  return `File doesn't have recommended dimensions ${recommendedBackgrounds
    .map(({ width, height }) => `${width}px x ${height}px`)
    .join(", ")}.`;
};
