import { useCallback, useMemo } from "react";
import { useDispatch, useStore } from "react-redux";
import { Logger as logger } from "purplex-logging";

import { SceneEditorActions } from "../../scene-editor-reducer";
import { getRedoStack, getUndoStack } from "../../scene-editor-selectors";
import { SceneEditorAssetActionType } from "./scene-editor-actions";
import { useAssetPropertiesActions } from "./use-asset-properties-actions";
import { useTextAssetActions } from "./use-text-asset-actions";
import { useSceneAssetCrudActions } from "./use-scene-asset-crud-actions";

export const useSceneEditorActions = () => {
  const dispatch = useDispatch();
  const store = useStore();

  const { updateAssetProperties } = useAssetPropertiesActions();

  const {
    changeTextAssetText,
    changeTextAssetConfig,
    createTextAsset
  } = useTextAssetActions();

  const { cloneAssets, deleteAssets } = useSceneAssetCrudActions();

  const actionsMap: {
    [key in keyof typeof SceneEditorAssetActionType]: (
      nextValue: any,
      replaceHistory?: boolean,
      assetIds?: number[] | string[]
    ) => void;
  } = useMemo(
    () => ({
      [SceneEditorAssetActionType.UPDATE_ASSETS_PROPERTIES]: updateAssetProperties,
      [SceneEditorAssetActionType.DELETE_ASSETS]: deleteAssets,
      [SceneEditorAssetActionType.CLONE_ASSETS]: cloneAssets,
      [SceneEditorAssetActionType.CREATE_TEXT_ASSET]: createTextAsset,
      [SceneEditorAssetActionType.CHANGE_TEXT_ASSET_CONFIG]: changeTextAssetConfig,
      [SceneEditorAssetActionType.CHANGE_TEXT_ASSET_TEXT]: changeTextAssetText
    }),
    [
      deleteAssets,
      cloneAssets,
      createTextAsset,
      changeTextAssetConfig,
      changeTextAssetText,
      updateAssetProperties
    ]
  );

  const undoLastAction = useCallback(() => {
    const undoStack = getUndoStack(store.getState());

    if (!undoStack.length) {
      logger.debug("There is no action to undo.");
      return;
    }
    const lastAction = undoStack[undoStack.length - 1];
    if (!lastAction) {
      logger.debug("There is no action to undo.");
      return;
    }

    if (lastAction.newVersion && lastAction.undoCallback) {
      lastAction.undoCallback(false);
    } else {
      lastAction.actions.forEach((action) => {
        actionsMap[action.type](action.previousValue, false, [
          action.sceneAssetId
        ]);
      });
    }

    dispatch(SceneEditorActions.addActionBatchToRedoStack(lastAction));
    dispatch(SceneEditorActions.popUndoStack());
  }, [actionsMap, dispatch, store]);

  const redoLastAction = useCallback(() => {
    const redoStack = getRedoStack(store.getState());

    if (!redoStack.length) {
      logger.debug("There is no action to redo.");
      return;
    }
    const lastAction = redoStack[redoStack.length - 1];
    if (!lastAction) {
      logger.debug("There is no action to redo.");
      return;
    }

    if (lastAction.newVersion && lastAction.redoCallback) {
      lastAction.redoCallback(false);
    } else {
      lastAction.actions.forEach((action) => {
        actionsMap[action.type](action.nextValue, false, [action.sceneAssetId]);
      });
    }
    dispatch(SceneEditorActions.addActionBatchToUndoStack(lastAction));
    dispatch(SceneEditorActions.popRedoStack());
  }, [actionsMap, dispatch, store]);

  return useMemo(() => {
    return {
      actionsMap,
      undoLastAction,
      redoLastAction
    };
  }, [actionsMap, undoLastAction, redoLastAction]);
};
