import { useCallback } from "react";
import { useConfirmationDialog } from "client/modules/dialogs/dialog-hooks";
import { DialogResult } from "client/modules/dialogs/event-types";

export const useDeleteScene = () => {
  const confirmDialog = useConfirmationDialog();

  return useCallback(
    async (title: string, onDelete: Function) => {
      const result = await confirmDialog({
        title: "Delete Scene",
        text: `WARNING! Are you sure you want to delete Scene ${title}?`,
        subtext: ``,
        confirmLabel: "Delete",
        cancelLabel: "Cancel"
      });

      if (result.type === DialogResult.CONFIRM) {
        await onDelete();
      }
    },
    [confirmDialog]
  );
};
