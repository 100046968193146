import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getActiveWall, getScene } from "../scene-editor-selectors";
import { SceneEditorActions } from "../scene-editor-reducer";

export const PreviewWallNavigation = () => {
  const scene = useSelector(getScene);
  const activeWall = useSelector(getActiveWall);

  const dispatch = useDispatch();

  const onChangeWall = useCallback(
    (activeWall: number) => {
      dispatch(SceneEditorActions.wallChanged({ activeWall }));
    },
    [dispatch]
  );
  return (
    <>
      {scene && scene.instance && scene.instance.walls.length > 1 && (
        <div className="wall-navigation">
          <div
            className="button backward"
            onClick={() =>
              scene &&
              scene.instance &&
              onChangeWall(
                (activeWall - 1 + scene.instance.walls.length) %
                  scene.instance.walls.length
              )
            }
          />
          <div
            className="button forward"
            onClick={() =>
              scene &&
              scene.instance &&
              onChangeWall((activeWall + 1) % scene.instance.walls.length)
            }
          />
        </div>
      )}
    </>
  );
};
