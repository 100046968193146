import React from "react";
import { useSelector } from "react-redux";
import { CustomFieldToEntityMappingReduced } from "../../../../../../shared/types/custom-field-to-entity-mapping";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";
import { DropdownField } from "../../../../forms/dropdown-field";
import { TextField } from "../../../../forms/text-field";
import { CustomFieldUpdateCommand } from "../../../../../../shared/types/custom-field-update-command";
import { CustomFieldSelectedChoiceReduced } from "../../../../../../shared/types/custom-field-selected-choice";
import { CustomFieldTextValueReduced } from "../../../../../../shared/types/custom-field-text-value";
import { CustomFieldHierarchyReduced } from "../../../../../../shared/types/custom-field-hierarchy";
import {
  getCustomFieldHierarchies,
  getCustomFieldToEntityMappings
} from "../../../../entity-repository/entity-repository-selectors";
import { getCustomFields } from "../../../../root/root-selectors";
import { notEmptyString } from "../../../../forms/validations/not-empty-string";

interface CustomFieldsFormBlockComponentProps {
  parentId?: number;
  disabled?: boolean;
}

export interface CustomFieldValues {
  [key: string]: string | number;
}

const CUSTOM_FIELD_KEY_PREFIX = "custom-field-";

export const entityFormToCustomFields = (
  entityForm: CustomFieldValues,
  customFieldToEntityMappings: {
    [key: number]: CustomFieldToEntityMappingReduced;
  }
): CustomFieldUpdateCommand[] => {
  const customFields = Object.keys(entityForm)
    .map((key) => {
      const customFieldValue = entityForm[key] as string;
      const customFieldToEntityMappingId = parseInt(
        key.replace(CUSTOM_FIELD_KEY_PREFIX, ""),
        10
      );

      const customFieldToEntityMapping =
        customFieldToEntityMappings[customFieldToEntityMappingId];

      if (
        customFieldToEntityMapping &&
        customFieldToEntityMapping.customField
      ) {
        return {
          id: customFieldToEntityMapping.id,
          type: customFieldToEntityMapping.customField.type,
          value: customFieldValue,
          customFieldId: customFieldToEntityMapping.customField.id
        };
      } else {
        return null;
      }
    })
    .filter(Boolean) as CustomFieldUpdateCommand[];

  return customFields;
};

export const customFieldsToEntity = (
  customFields: (
    | CustomFieldSelectedChoiceReduced
    | CustomFieldTextValueReduced
  )[]
) => {
  type Reduction = {
    [key: string]: string | number;
  };

  return customFields.reduce<Reduction>(
    (
      memo: Reduction,
      value: CustomFieldSelectedChoiceReduced | CustomFieldTextValueReduced
    ) => {
      memo[`${CUSTOM_FIELD_KEY_PREFIX}${value.customFieldToEntityMappingId}`] =
        value.value;
      return memo;
    },
    {}
  );
};

const noValidation = () => null;

export const buildFieldName = (id: number) => `${CUSTOM_FIELD_KEY_PREFIX}${id}`;

export const FiltersBlock: React.FC<CustomFieldsFormBlockComponentProps> = ({
  parentId,
  disabled
}) => {
  const customFieldHierarchies: {
    [key: string]: CustomFieldHierarchyReduced;
  } = useSelector(getCustomFieldHierarchies);

  const customFieldToEntityMappings = useSelector(
    getCustomFieldToEntityMappings
  );

  const customFieldState: { customFieldHierarchies: number[] } = useSelector(
    getCustomFields
  );

  return (
    <>
      {customFieldState.customFieldHierarchies
        .map((key: number) => {
          const customFieldHierarchy = customFieldHierarchies[key];
          const customFieldToEntityMapping =
            customFieldToEntityMappings[
              customFieldHierarchy.customFieldToEntityMappingId
            ];

          return {
            ...customFieldHierarchy,
            customFieldToEntityMapping
          };
        })
        .filter((customFieldHierarchy) => {
          return (
            customFieldHierarchy.customFieldToEntityMapping.customField &&
            [CustomFieldType.SINGLE_CHOICE, CustomFieldType.TEXT].includes(
              customFieldHierarchy.customFieldToEntityMapping.customField.type
            ) &&
            customFieldHierarchy.parentId === parentId
          );
        })
        .sort((a, b) => a.sort - b.sort)
        .map((customFieldHierarchy) => {
          const customField =
            customFieldHierarchy.customFieldToEntityMapping.customField;

          switch (customField.type) {
            case CustomFieldType.TEXT:
              return (
                <TextField
                  disabled={disabled}
                  key={customFieldHierarchy.customFieldToEntityMappingId}
                  name={buildFieldName(
                    customFieldHierarchy.customFieldToEntityMappingId
                  )}
                  label={customField.label}
                  validate={
                    customFieldHierarchy.customFieldToEntityMapping.required
                      ? notEmptyString
                      : noValidation
                  }
                />
              );
            case CustomFieldType.SINGLE_CHOICE:
              return (
                <DropdownField
                  disabled={disabled}
                  key={customFieldHierarchy.customFieldToEntityMappingId}
                  parse={(value: string) => (value === "" ? null : value)}
                  name={buildFieldName(
                    customFieldHierarchy.customFieldToEntityMappingId
                  )}
                  label={customField.label}
                  validate={
                    customFieldHierarchy.customFieldToEntityMapping.required &&
                    customField.values.length
                      ? notEmptyString
                      : noValidation
                  }
                >
                  <option value="">--</option>
                  {customField.values.map(({ value, id }) => (
                    <option value={id} key={id}>
                      {value}
                    </option>
                  ))}
                </DropdownField>
              );
            default:
              throw new Error("Invalid state");
          }
        })}
    </>
  );
};
