import { AssetCh, AssetReduced } from "./asset";

export interface SceneBase {
  id: number;
  uuid: string;
  sceneId: number;
  left: number;
  top: number;
  positionLocked: boolean;
  scale: number;
  layer: number;
  visible: boolean;
  opacity: number;
  loop: boolean;
  autoplay: boolean;
  width: number;
  height: number;
  glow: boolean;
  rounded: boolean;
  cropHeight: number;
  cropWidth: number;
  cropOffsetX: number;
  cropOffsetY: number;
}

export interface SceneAssetCh extends SceneBase {
  asset: AssetCh;
}

export interface SceneAssetReduced extends SceneBase {
  asset: AssetReduced;
}

export enum SceneAssetBulkOperationFlag {
  DELETE = "DELETE",
  CREATE = "CREATE",
  UPDATE = "UPDATE"
}
