import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { endsWithSegment, includesSegment } from "router5-helpers";

import { getRouteName } from "../../routing/routing-selectors";
import { PresentationList } from "./presentation-list";
import { PresentationAdd } from "./presentation-add";
import { PresentationEdit } from "./presentation-edit";
import { PresentationEditAttachScene } from "./presentation-edit-attach-scene";
import { PresentationEditCreateScene } from "./presentation-edit-create-scene";
import { PresentationEditEditScene } from "./presentation-edit-edit-scene";
import { useFetchCustomFields } from "../../settings/custom-fields/hooks/use-custom-fields";
import { PresentationTriggersManagementModal } from "./presentation-triggers-management-modal";

export const PresentationIndex = () => {
  const route = useSelector(getRouteName) as string;
  const includes = includesSegment(route);
  const ends = endsWithSegment(route);

  const fetchCustomFields = useFetchCustomFields();

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  if (includes("list")) {
    return (
      <>
        <PresentationList />
        {/*
        // @ts-ignore */}
        {ends("add") && <PresentationAdd />}
      </>
    );
  } else if (includes("edit")) {
    return (
      <>
        <PresentationEdit />
        {ends("attach-scene") && <PresentationEditAttachScene />}
        {ends("create-scene") && <PresentationEditCreateScene />}
        {ends("edit-scene") && <PresentationEditEditScene />}
        {ends("triggers") && <PresentationTriggersManagementModal />}
      </>
    );
  } else {
    throw new Error(`Unknown route ${route}`);
  }
};
