import React, { InputHTMLAttributes } from "react";
import { Field, WrappedFieldProps } from "redux-form";
import cx from "classnames";

interface Props {
  className?: string;
  dataQa?: string;
  id?: string;
  style?: object;
}

const Input: React.FC<Props & WrappedFieldProps> = ({
  className,
  dataQa,
  id,
  input: { onChange, value },
  style
}) => (
  <label
    className={cx("swytch", className, { "is-active": value })}
    style={{ ...style }}
  >
    <input
      type="checkbox"
      value={value}
      id={id}
      data-qa={dataQa}
      onChange={() => onChange(!value)}
    />
    <span className="swytch__label">On</span>
    <span className="swytch__label">Off</span>
    <span className="swytch__handle" />
  </label>
);

export const SwitchField = (
  props: Props & InputHTMLAttributes<HTMLInputElement>
) => <Field component={Input} {...props} />;
