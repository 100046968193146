import { Logger as logger } from "purplex-logging";
import { useCallback, useState } from "react";

import { LongTask } from "./crud-types";
import { ApiClient, useApi } from "../api/use-api";

export interface DeletedEntity {
  success: boolean;
  id: number | string | null;
}

export interface DeleteRecordCommand extends LongTask {
  deleteRecord: (params: ApiDeleteEntityParameters) => Promise<DeletedEntity>;
  inProgress: boolean;
}

export interface ApiDeleteEntityParameters {
  id: string | number;
}

export type ApiExtractorDelete<Result> = (
  api: ApiClient
) => (params: ApiDeleteEntityParameters) => Promise<Result>;

export function useDeleteRecord<Result>(
  deleteExtractor: ApiExtractorDelete<Result>
): DeleteRecordCommand {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const api = useApi();

  const deleteRecord = useCallback(
    async ({ id }: ApiDeleteEntityParameters) => {
      try {
        logger.silly("Requesting delete record");
        setInProgress(true);
        const apiCall = deleteExtractor(api);
        const data = await apiCall({ id });

        logger.silly(
          "Entity has been successfully deleted - storing entity to repo",
          data
        );

        //TODO id should be fetched from the backend @usehooks
        return { success: true, id };
      } catch (ex) {
        logger.error("There was an error during deleting record - ", ex);
        //TODO id should be fetched from the backend @usehooks
        return { success: false, id };
      } finally {
        setInProgress(false);
      }
    },
    [api, deleteExtractor]
  );

  return { deleteRecord, inProgress };
}
