import React, { useCallback, useMemo } from "react";
import { reduxForm, submit } from "redux-form";
import cx from "classnames";
import { connect } from "react-redux";
import { actions as routerActions } from "redux-router5";
import { getUser } from "../../auth/auth-selectors";
import { getBranding } from "../../config/config-selectors";
import { PRESENTATIONS_EDIT_ROUTE } from "../../routing/route-names";
import Actions from "../presentation-actions";
import { Modal } from "../../ux/modal";
import validate from "../../scenes/components/scene-validate";
import { SceneForm } from "../../scenes/components/scene-form";
import {
  getEditingSceneInitialValues,
  getPresentation
} from "../presentation-selectors";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "scene-edit";
const SceneFormEdit = reduxForm({ form: FORM_NAME, enableReinitialize: true })(
  SceneForm
);

const mapDispatchToProps = {
  submitForm: () => submit(FORM_NAME),
  editScene: Actions.Creators.editScene,
  deleteScene: Actions.Creators.deleteScene,
  replaceUrl: (route, params) =>
    routerActions.navigateTo(route, params, {
      replace: true
    })
};

const mapStateToProps = (state) => ({
  initialValues: getEditingSceneInitialValues(state),
  user: getUser(state),
  presentation: getPresentation(state),
  roomName: getBranding(state).roomName,
  isDeletingInProgress: getProgressForKeySelector(state)(
    Actions.Types.DELETE_SCENE
  )
});

export const PresentationEditEditScene = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    initialValues,
    submitForm,
    editScene,
    deleteScene,
    replaceUrl,
    user,
    presentation,
    roomName,
    isDeletingInProgress
  }) => {
    const closeModal = useCallback(() => {
      replaceUrl(PRESENTATIONS_EDIT_ROUTE, { presentationId: presentation.id });
    }, [replaceUrl, presentation]);

    useEscapeHook(initialValues.id !== undefined, closeModal);

    const presentationInstanceCount = useMemo(
      () => (presentation ? presentation.instances.length : 0),
      [presentation]
    );

    if (!initialValues.id) {
      // waits until the scene is loaded (mainly if the form is opened
      // directly by entering url)
      return null;
    }

    return (
      <Modal
        title="Scene Settings"
        className="modalBoxSm"
        onCloseClick={closeModal}
        data-qa={MODAL_LOCATORS.getModal("scene-settings")}
        footer={
          <>
            {user.id === initialValues.creatorId && (
              <button
                className={cx("button buttonAlert buttonOutlined left", {
                  inProgress: isDeletingInProgress(initialValues.id)
                })}
                onClick={() => deleteScene(initialValues)}
              >
                Remove scene
              </button>
            )}
            <button
              className="button"
              onClick={submitForm}
              data-qa={MODAL_LOCATORS.buttons.save}
            >
              Save
            </button>
          </>
        }
      >
        <SceneFormEdit
          isEdit
          validate={validate(roomName)}
          initialValues={initialValues}
          presentationId={presentation && presentation.id}
          presentationInstanceCount={presentationInstanceCount}
          hideCollaborators={true}
          onSubmit={editScene}
        />
      </Modal>
    );
  }
);
