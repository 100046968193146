import React from "react";
import { reduxForm } from "redux-form";
import { createPortal } from "react-dom";

import { SelectRoomField } from "../../forms/select-room-field";

import { ModalTemplate } from "../../ux/modal";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { useSelector } from "react-redux";
import { getBranding } from "client/modules/config/config-selectors";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

export const CLONE_PRESENTATION_FORM = "clone-presentation";

const Form = reduxForm<
  { instances: string[] },
  {
    id: number;
    title: string;
    onClose: () => void;
    onClone: (id: number, title: string, instances: string[]) => void;
  }
>({
  form: CLONE_PRESENTATION_FORM
})(({ id, title, handleSubmit, onClone, onClose }) => {
  const branding = useSelector(getBranding);
  useEscapeHook(true, onClose);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onClone(id, title, values.instances);
      })}
    >
      {/*
      // @ts-ignore */}
      <ModalTemplate
        title="Clone presentation"
        className="is-bleeding"
        onCloseClick={onClose}
        footer={
          <>
            <button
              className="button buttonOutlined danger"
              type="button"
              onClick={onClose}
              data-qa={MODAL_LOCATORS.buttons.cancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="button"
              data-qa={MODAL_LOCATORS.buttons.clone}
            >
              Clone presentation
            </button>
          </>
        }
      >
        <SelectRoomField
          name="instances"
          required
          isClearable
          isMulti={true}
          label={`Select ${branding.roomName}s`}
          filteredInstances={null}
          help=""
          dataQa={FORM_LOCATORS.inputs.getInput("room")}
        />
      </ModalTemplate>
    </form>
  );
});

export const ClonePresentation = ({
  id,
  title,
  instances,
  onClose,
  onClone
}: {
  id: number;
  title: string;
  instances: string[];
  onClone: (id: number, title: string, instances: string[]) => void;
  onClose: () => void;
}) => {
  return createPortal(
    <Form
      initialValues={{ instances }}
      onClose={onClose}
      onClone={onClone}
      id={id}
      title={title}
    />,
    document.getElementById("modal") as Element
  );
};
