import WebFontLoader from "webfontloader";
import { useEffect } from "react";

export const FONTS = [
  "Roboto",
  "Anton",
  "Montserrat",
  "Josefin Sans",
  "Raleway",
  "Bree Serif",
  "Merriweather",
  "DM Serif Text",
  "Roboto Mono",
  "Playfair Display",
  "Noto Sans SC",
  "Noto Sans KR"
];

export const usePreloadFonts = () => {
  useEffect(() => {
    (async () => {
      WebFontLoader.load({
        google: {
          families: FONTS
        }
      });
    })();
  }, []);
};
