import React from "react";
import { ReactComponent as IconPresentation } from "client/images/assets/presentation.svg";

export const AssetPresentation = (props: {
  glow: boolean;
  rounded: boolean;
  width: number;
  height: number;
}) => {
  const { glow, rounded, width, height } = props;

  return (
    <div
      className="placeholder-presentation"
      style={{
        borderRadius: rounded ? "20px" : 0,
        boxShadow: glow ? "0 0 50px #fff" : "",
        width,
        height
      }}
    >
      <IconPresentation className="icon icon-presentation icon192" />
    </div>
  );
};
