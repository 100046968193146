import { useDropzone } from "react-dropzone";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import isString from "lodash/isString";
import { Field, WrappedFieldProps, BaseFieldProps } from "redux-form";
import { useSelector } from "react-redux";

import AssetMimeTypes from "../asset-mime-types";
import { getMaxUploadSize } from "../../config/config-selectors";
import { getAssets } from "../../entity-repository/entity-repository-selectors";
import { MediaDialogType } from "./media-dialog/media-dialog-type";
import { getDialogType } from "../media-selectors";
import { MEDIA_TITLE_MAPPING } from "./media-dialog/media-title-mapping";

import { ReactComponent as IconTrash } from "../../../images/trash.svg";
import { ReactComponent as IconWarning } from "../../../images/warning.svg";
import { AssetType } from "shared/types/asset-type";

const { AssetStatus } = require("../../../../shared/types/asset-status");

const stopPropagation = (fn: () => void) => (ev: React.SyntheticEvent) => {
  ev.preventDefault();
  ev.stopPropagation();
  fn();
};

interface AssetFileFieldProps {
  rootAssetType: AssetType;
  alternative: boolean;
  useNativePreview?: boolean;
  onRemoveFile: () => void;
  onReady?: (open: () => void) => void;
}

const getFileThumbnail = (assetType: AssetType, file: { name: string }) => {
  const preview = URL.createObjectURL(file);

  switch (assetType) {
    case AssetType.ASSET_IMAGE:
    case AssetType.ASSET_WALL_IMAGE:
      return <img src={preview} alt="" />;
    case AssetType.ASSET_VIDEO:
    case AssetType.ASSET_WALL_VIDEO:
      return (
        <video src={preview} muted={true} preload="metadata" autoPlay={false} />
      );
    case AssetType.ASSET_SOUND:
    case AssetType.ASSET_PDF:
    case AssetType.ASSET_PRESENTATION:
      return <span className="assetPlaceholder">{file.name}</span>;
    default:
      throw new Error(`Unknown type ${assetType}`);
  }
};

const AssetFileFieldImpl = ({
  rootAssetType,
  meta: { error },
  input: { onChange, value },
  alternative,
  useNativePreview, // Normal assets are stil using the old implementation of native preview
  // That's obviously wrong, but this is temporary until whole asset uploading for normal
  // assets is redesigned
  onRemoveFile,
  onReady
}: AssetFileFieldProps & WrappedFieldProps) => {
  const assets = useSelector(getAssets);
  const dialogType: MediaDialogType | null = useSelector(getDialogType);
  const maxUploadFileSize: number = useSelector(getMaxUploadSize);
  const [rejected, setRejected] = useState(false);
  const [dropping, setDropping] = useState(false);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: AssetMimeTypes[rootAssetType],
    maxSize: maxUploadFileSize,
    onDragEnter: () => setDropping(true),
    onDragLeave: () => setDropping(false)
  });

  if (!dialogType) {
    throw new Error("Missing dialog type");
  }

  let thumbnail;

  // Asset entity from entity repository
  let assetEntity = null;
  if (value.uuid && assets[value.uuid]) {
    assetEntity = assets[value.uuid];
  }

  const preloading = (
    <div className="assetLoading">
      <i className="preload" />
    </div>
  );

  // File has not been selected, it's empty
  if (value.file === null || value.rejected) {
    thumbnail = null;
  } else if (value.file && value.file instanceof File) {
    if (useNativePreview) {
      // This is legacy, because in normal assets
      // we show native preview instead of server generated thumbnail (which is right)
      thumbnail = getFileThumbnail(rootAssetType, value.file);
    } else {
      thumbnail = preloading;
    }
  } else if (value.file && isString(value.file)) {
    // Providing src of the thumbnail
    // let's just make sure the asset entity has been processed already
    if (
      assetEntity !== null &&
      assetEntity.status === AssetStatus.STATUS_READY
    ) {
      thumbnail = <img src={value.file} alt="" />;
    } else {
      if (
        assetEntity !== null &&
        assetEntity.status === AssetStatus.STATUS_ERROR
      ) {
        thumbnail = (
          <div className="assetError">
            <IconWarning />
            ERROR: File processing has failed.
          </div>
        );
      } else {
        thumbnail = preloading;
      }
    }
  } else {
    throw new Error("Forbidden state");
  }

  const onRemove = stopPropagation(onRemoveFile);

  const uuid = value.uuid;
  useEffect(() => {
    setDropping(false);

    if (acceptedFiles.length) {
      setRejected(false);

      onChange({
        file: acceptedFiles[0],
        rejected: false,
        uuid
      });
    } else if (fileRejections.length) {
      setRejected(true);

      onChange({
        file: fileRejections[0].file,
        rejected: true,
        uuid
      });
    }
  }, [acceptedFiles, fileRejections, uuid, onChange]);

  useEffect(() => {
    if (open && onReady) {
      onReady(open);
    }
  }, [onReady, open]);

  return (
    <>
      <div
        {...getRootProps({
          className: cx({
            dropzone: true,
            dropping
          })
        })}
      >
        <input {...getInputProps()} data-qa="alternative-bg-input" />
        {thumbnail}{" "}
        {!rejected && alternative && value.uuid !== null && (
          <button
            onClick={onRemove}
            className="button buttonAlert buttonDelete buttonIcon buttonDelete"
          >
            <IconTrash />
          </button>
        )}
        <div className="dropzoneText">
          {rejected && (
            <div className="dropzoneFlash flash-message flash-message-error">
              File can't be uploaded.
            </div>
          )}
          {alternative && value.uuid === null && (
            <div>Add Alternative File</div>
          )}
          {!alternative && dialogType !== "WALL_ASSET" && (
            <span>
              Drag &amp; Drop {MEDIA_TITLE_MAPPING[dialogType]} Here or <br />
            </span>
          )}
          {dialogType === MediaDialogType.WALL_ASSET &&
          value.file !== null &&
          rejected !== true ? (
            <button
              type="button"
              className="button buttonUpload buttonReplace"
              onClick={open}
            >
              Replace File
            </button>
          ) : (
            <button
              type="button"
              className="button buttonUpload"
              onClick={open}
              data-qa="browse-file-btn"
            >
              Browse File
            </button>
          )}
        </div>
      </div>
      {error && <div className="help-block">{error}</div>}
    </>
  );
};

export const AssetFileField = (
  props: BaseFieldProps<AssetFileFieldProps> & AssetFileFieldProps
) => {
  return <Field component={AssetFileFieldImpl} {...props} />;
};
