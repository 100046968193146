import React from "react";
import { useRenderCount } from "../../../utils";

export const EditBackgroundWall = React.memo(
  (props: {
    wall: { num: number; start: number; width: number; height: number };
    screenScale: number;
    height: number;
    padding: number;
  }) => {
    const { wall, screenScale, height, padding } = props;

    const renderCount = useRenderCount();

    return (
      <div
        className="background-wall"
        style={{
          top: height / 2 - (wall.height / 2) * screenScale,
          left: wall.start * screenScale + padding / 2,
          width: wall.width * screenScale,
          height: wall.height * screenScale
        }}
      >
        {renderCount}
        {wall.num}
      </div>
    );
  }
);
