// this interface is not yet complete
import { AssetType } from "./asset-type";
import { AssetStatus } from "./asset-status";
import { UpdateInfo } from "./update-info";
import { CustomFieldTextValueReduced } from "./custom-field-text-value";
import { CustomFieldSelectedChoiceReduced } from "./custom-field-selected-choice";
import { CustomFieldSelectedCategoryReduced } from "./custom-field-selected-category";
import { GuestDomainReduced } from "./guest-domain";
import { AssetMetadata } from "./asset-metadata";

export enum TextAssetAlignment {
  Left = "left",
  Center = "center",
  Right = "right"
}

export enum TextAssetFontSize {
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl"
}

export interface TextAssetMetadata {
  fontSize: TextAssetFontSize;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  alignment: TextAssetAlignment;
  font: string;
  color: string;
  textContent: string;
}

export interface AssetCh {
  uuid: string;
  title: string;
  type: AssetType;
  metadata: AssetMetadata | null;
  fileVersion: number;

  playAs: AssetType;
  formatName: string | null;
  fileName: string | null;
  hasAlpha: boolean;
  dimensions: {
    width: number;
    height: number;
  };

  alternativeAssets: AssetReduced[];
}

export interface AssetReduced extends AssetCh, UpdateInfo {
  parentUuid: string | null;
  description: string;
  status: AssetStatus;
  shared: boolean;
  guestDomain?: GuestDomainReduced;
  customFields: (
    | CustomFieldTextValueReduced
    | CustomFieldSelectedChoiceReduced
  )[];
  customFieldCategories: CustomFieldSelectedCategoryReduced[];
}

export interface AssetFull extends AssetReduced {}
