import React, { MouseEventHandler } from "react";
import cx from "classnames";

import { ReactComponent as IconLayers } from "client/images/layers.svg";
import { ReactComponent as IconEye } from "client/images/eye.svg";
import { DropPosition, useDropAsset } from "../../scene-editor-dnd";
import { SceneAssetRow } from "./scene-asset-row";

//TODO good typing
export const WallBlock = (props: {
  wallNumber: number;
  onlySceneAssetId: number | null;
  wallAssets: JSX.Element[];
  isActiveWall: boolean;
  onChangeWall: MouseEventHandler;
  wallFirstIndex: number;
  createSceneAsset: Function;
  changeSceneAssetOrder: Function;
}) => {
  const {
    wallNumber,
    // id of asset if that asset is the only one on this wall
    onlySceneAssetId,
    wallAssets,
    wallFirstIndex,
    isActiveWall,
    onChangeWall,
    createSceneAsset,
    changeSceneAssetOrder
  } = props;
  const getDropIndexBaseOnDropPosition = (position: DropPosition): number => {
    if (position === DropPosition.TOP) {
      return wallFirstIndex;
    }

    return wallFirstIndex + wallAssets.length;
  };

  const [{ assetOver, assetNotOver, position }, dropRef] = useDropAsset(
    null,
    (asset, dropPosition) => {
      createSceneAsset([
        {
          asset: asset,
          index: getDropIndexBaseOnDropPosition(dropPosition),
          wallNumber
        }
      ]);
    },
    (sceneAssetId, dropPosition) => {
      changeSceneAssetOrder({
        sceneAssetId,
        moveToIndex: getDropIndexBaseOnDropPosition(dropPosition),
        wallNumber
      });
    }
  );

  const assetOverComponent = assetOver ? (
    <SceneAssetRow
      key={"dnd-preview"}
      hasOnClickAction={false}
      layer={null}
      type={assetOver.asset.type}
      title={assetOver.asset.title}
      dragging={false}
      selected={false}
      onClick={() => {}}
      onClickDelete={() => {}}
      onEditOnClickAction={() => {}}
      wallNumber={wallNumber}
    />
  ) : null;

  const renderEmptyWall =
    (wallAssets.length === 0 && !assetOver) ||
    (onlySceneAssetId && assetNotOver && assetNotOver.id === onlySceneAssetId);

  return (
    <div ref={dropRef} className="wall" data-qa={"wall-" + wallNumber}>
      <h3 className={isActiveWall ? "active" : ""} onClick={onChangeWall}>
        <IconLayers className="icon icon-layers" />
        <a>
          <IconEye className="icon icon-eye" />
        </a>
        Wall {wallNumber}
      </h3>
      <ul
        className={cx("dnd-list", {
          "dnd-list-empty": renderEmptyWall
        })}
        data-qa={`wall-${wallNumber}-dnd-list`}
      >
        {position === DropPosition.TOP && assetOverComponent}
        {wallAssets}
        {position === DropPosition.BOTTOM && assetOverComponent}
        {renderEmptyWall && (
          <li
            key={"empty-wall"}
            className="dnd-item-empty"
            data-qa="dnd-empty-item"
          >
            Empty Wall
          </li>
        )}
      </ul>
    </div>
  );
};
