import { Logger as logger } from "purplex-logging";

import { AssetType } from "../../../server/models/enums/asset-type";
import { AssetCh } from "../../types/asset";
import { SceneAssetCh } from "../../types/scene-asset";
import { BackgroundSettingsReduced } from "../../types/background-settings";
import { ImAssetTransformation, ImInstance } from "../../types/im-instance";

interface ZeroPoint {
  left: number;
  top: number;
}

/**
 * Transform assets so they fit exactly width of target room, doesn't care of
 * edges and height of the room.
 * @param sceneAssets
 * @param originalInstanceConfig
 * @param targetInstanceConfig
 */
const transformSceneAssetsAsWhole = (
  sceneAssets: SceneAssetCh[],
  originalInstanceConfig: ImInstance,
  targetInstanceConfig: ImInstance
) => {
  const originalRoomWidth = originalInstanceConfig.roomDimensions.width;
  const originalRoomHeight = originalInstanceConfig.roomDimensions.height;

  if (!originalRoomWidth || !originalRoomHeight) {
    throw Error(`Source room dimensions must be not zero numbers.`);
  }

  const targetRoomWidth = targetInstanceConfig.roomDimensions.width;
  const targetRoomHeight = targetInstanceConfig.roomDimensions.height;

  // we always try to fill the room horizontally, vertically the room content
  // can overflow
  const widthScale = targetRoomWidth / originalRoomWidth;

  // the center point is bottom left corner
  const transformationCenterPoint = {
    left: 0,
    top: originalRoomHeight
  };

  const transformedCenterPoint = {
    left: 0,
    top: targetRoomHeight
  };

  return sceneAssets.map(
    ({ left, top, width, height, scale, asset, ...rest }) => ({
      ...rest,
      asset,
      width: asset.type === AssetType.ASSET_URL ? width * widthScale : width,
      height: asset.type === AssetType.ASSET_URL ? height * widthScale : height,
      scale: asset.type === AssetType.ASSET_URL ? scale : scale * widthScale,
      ...transformPointLockedScale(
        left,
        top,
        widthScale,
        transformationCenterPoint,
        transformedCenterPoint
      )
    })
  );
};

const transformPointLockedScale = (
  left: number,
  top: number,
  scale: number,
  zeroPoint: ZeroPoint,
  transformedZeroPoint: ZeroPoint
) => {
  const relativeLeft = left - zeroPoint.left;
  const relativeTop = top - zeroPoint.top;

  return {
    left: relativeLeft * scale + transformedZeroPoint.left,
    top: relativeTop * scale + transformedZeroPoint.top
  };
};

const transformPointUnlockedScale = (
  left: number,
  top: number,
  scaleHorizontally: number,
  scaleVertically: number,
  zeroPoint: ZeroPoint,
  transformedZeroPoint: ZeroPoint
) => {
  const relativeLeft = left - zeroPoint.left;
  const relativeTop = top - zeroPoint.top;

  return {
    left: relativeLeft * scaleHorizontally + transformedZeroPoint.left,
    top: relativeTop * scaleVertically + transformedZeroPoint.top
  };
};

/**
 * Transform assets so they fit all walls widths, so edges are preserved on same
 * positions as are in the source room.
 * @param sceneAssets
 * @param originalInstanceConfig
 * @param targetInstanceConfig
 */
const transformSceneAssetsPerWall = (
  sceneAssets: SceneAssetCh[],
  originalInstanceConfig: ImInstance,
  targetInstanceConfig: ImInstance
) => {
  const originalRoomHeight = originalInstanceConfig.roomDimensions.height;
  const targetRoomHeight = targetInstanceConfig.roomDimensions.height;

  if (!originalRoomHeight) {
    throw Error(`Source room height must be not zero numbers.`);
  }

  const sourceWalls = [...(originalInstanceConfig.walls || [])].sort(
    (a, b) => a.num - b.num
  );

  const targetWalls = [...(targetInstanceConfig.walls || [])].sort(
    (a, b) => a.num - b.num
  );

  const wallsTransformedScaling = targetWalls.filter(
    ({ assetTransformation }) =>
      assetTransformation === ImAssetTransformation.SCALE_TO_FIT_WIDTH
  );

  const wallsScale =
    wallsTransformedScaling.length > 0
      ? Math.min(
          ...wallsTransformedScaling.map(({ width }, index) => {
            return width / sourceWalls[index].width;
          })
        )
      : 1;

  /**
   * Transform URL asset so it keeps its position relative to walls (while
   * aspect ratio of the asset can be changed). By this:
   * 1. URL asset can span across multiple walls.
   * 2. If the asset spans eg across whole wall number 3 + 4 in NY room scene
   * and then it is played in WD room, the asset will span also across whole
   * wall 3 + 4 even if it means to make the assets cca 370px wider compared
   * to the when shown in NY room. This is different compared to the other asset
   * types transformations which keeps their aspect ratio.
   */
  const transformUrlAsset = (
    left: number,
    top: number,
    width: number,
    height: number,
    scale: number
  ) => {
    const sourceLeftWallIndex = Math.max(
      sourceWalls.findIndex(({ end }) => left < end),
      0
    );

    const sourceRightWallIndex = Math.max(
      sourceWalls.findIndex(({ end }) => left + width < end),
      0
    );

    const sourceLeftWall = sourceWalls[sourceLeftWallIndex];
    const targetLeftWall = targetWalls[sourceLeftWallIndex];

    const sourceRightWall = sourceWalls[sourceRightWallIndex];
    const targetRightWall = targetWalls[sourceRightWallIndex];

    // the left origin is bottom left corner
    const transformationLeftOriginPoint = {
      left: sourceLeftWall.start,
      top: originalRoomHeight
    };

    const transformedLeftOriginPoint = {
      left: targetLeftWall.start,
      top: targetRoomHeight
    };

    // the right origin is top right corner
    const transformationRightOriginPoint = {
      left: sourceRightWall.start + sourceRightWall.width,
      top: 0
    };

    const transformedRightOriginPoint = {
      left: targetRightWall.start + targetRightWall.width,
      top: 0
    };

    const transformedStartPoint = transformPointUnlockedScale(
      left,
      top,
      targetLeftWall.width / sourceLeftWall.width,
      1,
      transformationLeftOriginPoint,
      transformedLeftOriginPoint
    );

    const transformedEndPoint = transformPointUnlockedScale(
      left + width * (scale / 100),
      top + height * (scale / 100),
      targetRightWall.width / sourceRightWall.width,
      1,
      transformationRightOriginPoint,
      transformedRightOriginPoint
    );

    const transformedScale = (scale / 100) * wallsScale;

    return {
      width:
        (transformedEndPoint.left - transformedStartPoint.left) /
        transformedScale,
      height:
        (transformedEndPoint.top - transformedStartPoint.top) /
        transformedScale,
      scale: transformedScale * 100,
      ...transformedStartPoint
    };
  };

  const transformOtherAsset = (
    left: number,
    top: number,
    width: number,
    height: number,
    scale: number
  ) => {
    const sourceWallIndex = Math.max(
      sourceWalls.findIndex(({ end }) => left < end),
      0
    );

    const sourceWall = sourceWalls[sourceWallIndex];
    const targetWall = targetWalls[sourceWallIndex];

    const transformedWidth = width;
    const transformedHeight = height;
    const transformedScale = scale * wallsScale;

    // the center point is bottom left corner
    const transformationCenterPoint = {
      left: sourceWall.start,
      top: originalRoomHeight
    };

    const transformedCenterPoint = {
      left: targetWall.start,
      top: targetRoomHeight
    };

    const transformedPoint = transformPointLockedScale(
      left,
      top,
      wallsScale,
      transformationCenterPoint,
      transformedCenterPoint
    );

    // algorithm which moves overflowed assets left (Made specially for
    // converting to NY room - assets from the right side of the shorter
    // wall would overflow out of the wall -> they are moved left so they
    // remains in the wall and can be visible)
    if (targetWall.assetTransformation === ImAssetTransformation.MOVE) {
      // scale is from range <0, 100>
      const assetRenderedWidth = (transformedWidth * transformedScale) / 100;

      const overflowedWidth =
        transformedPoint.left + assetRenderedWidth - targetWall.end - 1;

      if (overflowedWidth > 0) {
        transformedPoint.left -= overflowedWidth;
      }
    }

    return {
      width: transformedWidth,
      height: transformedHeight,
      scale: transformedScale,
      ...transformedPoint
    };
  };

  return sceneAssets
    .map(({ left, top, width, height, scale, asset, ...rest }) => {
      const transformed =
        asset.type === AssetType.ASSET_URL
          ? transformUrlAsset(left, top, width, height, scale)
          : transformOtherAsset(left, top, width, height, scale);

      return {
        ...rest,
        asset,
        ...transformed
      };
    })
    .filter(Boolean);
};

export const transformSceneAssets = (
  sceneAssets: SceneAssetCh[],
  originalInstanceConfig: ImInstance,
  targetInstanceConfig: ImInstance
) => {
  if (
    !originalInstanceConfig.walls ||
    originalInstanceConfig.walls.length === 0 ||
    !targetInstanceConfig.walls ||
    targetInstanceConfig.walls.length === 0
  ) {
    throw Error(`Source room or target room doesn't have configured walls.`);
  }

  if (
    originalInstanceConfig.walls.length !== targetInstanceConfig.walls.length
  ) {
    return transformSceneAssetsAsWhole(
      sceneAssets,
      originalInstanceConfig,
      targetInstanceConfig
    );
  } else {
    return transformSceneAssetsPerWall(
      sceneAssets,
      originalInstanceConfig,
      targetInstanceConfig
    );
  }
};

export const transformWall = (
  sceneWall: AssetCh,
  targetInstanceConfig: ImInstance,
  backgroundSettings: BackgroundSettingsReduced | undefined
) => {
  const { roomDimensions, projectors } = targetInstanceConfig;

  // Transformed image is first scaled.
  const scale = {
    width: backgroundSettings
      ? backgroundSettings.backgroundWidth
      : sceneWall.dimensions.width,
    height: backgroundSettings
      ? backgroundSettings.backgroundHeight
      : sceneWall.dimensions.height
  };

  // Negative padding is done by cropping operation with origin on the opposite side.
  // Positive padding is done by padding operation - bigger image is created with black padding on left/top.
  // Pad width/height must be bigger than previously cropped dimensions in either case, otherwise ffmpeg will fail.
  // Final dimensions are achieved by final cropping of overflowing parts.
  const pad = {
    width: backgroundSettings
      ? Math.max(
          backgroundSettings.backgroundWidth +
            backgroundSettings.backgroundLeft,
          roomDimensions.width
        )
      : Math.max(sceneWall.dimensions.width, roomDimensions.width),
    height: backgroundSettings
      ? Math.max(
          backgroundSettings.backgroundHeight +
            backgroundSettings.backgroundTop,
          roomDimensions.height
        )
      : Math.max(sceneWall.dimensions.height, roomDimensions.height),
    top: backgroundSettings ? backgroundSettings.backgroundTop : 0,
    left: backgroundSettings ? backgroundSettings.backgroundLeft : 0,
    edited: true
  };

  // Scaled, padded and cropped image is then cropped to fit the projector/wall.
  const slices = projectors
    .map(({ startOffset, num, width }) => {
      let sliceWidth = width;

      if (startOffset >= roomDimensions.width) {
        logger.warn(
          `Projector num=${num} is configured so it would start outside room's width. Projector's startOffset=${startOffset}, width=${width}. Room's width=${roomDimensions.width}`
        );

        return false;
      } else if (startOffset + width > roomDimensions.width) {
        logger.warn(
          `Projector num=${num} is configured so it would overflow room's width. Projector's startOffset=${startOffset}, width=${width}. Room's width=${roomDimensions.width}`
        );

        sliceWidth = roomDimensions.width - startOffset;
      }

      return {
        width: sliceWidth,
        height: roomDimensions.height,
        leftOffset: startOffset,
        num
      };
    })
    .filter(Boolean);

  return {
    ...sceneWall,
    scale,
    pad,
    slices
  };
};

export const getSceneMessages = () => {
  return [];
};
