export const isBackgroundValidForScene = (
  assetWidth: number,
  roomWidth: number
) => {
  return assetWidth >= roomWidth;
};

export const isWallAssetOptimalForInstance = (
  assetWidth: number,
  roomWidth: number
) => {
  return assetWidth >= roomWidth * 0.8;
};

export const userShouldBeWarnedAboutFittingBackground = () => {
  return false;
};
