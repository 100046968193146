import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Logger as logger } from "purplex-logging";

import { useApi } from "../../api/use-api";
import * as RoutingSelectors from "../../routing/routing-selectors";
import { RouteParamSelector } from "../../routing/routing-selectors";
import { useNotifications } from "../../ux/notifications/use-notifications";
import { useNavigateTo } from "../../routing/routing-hooks";
import { AUTH_LOGIN_ROUTE } from "../../routing/route-names";
import { BusinessError, NotFoundError } from "../../api/client-errors";

export const useCreateDomainAdminPassword = () => {
  const api = useApi();
  const { notifyError, notifySuccess } = useNotifications();
  const { navigateTo } = useNavigateTo();

  const { token } = useSelector(
    RoutingSelectors.getRouteParams as RouteParamSelector<{
      token: string;
    }>
  );
  return useCallback(
    (password: string) => {
      (async () => {
        if (token) {
          try {
            await api.createGuestAdminPassword(token, password);
            navigateTo(AUTH_LOGIN_ROUTE);
            notifySuccess(
              "Your password has been created! You can log in now.",
              { autoClose: false }
            );
          } catch (e) {
            if (e instanceof BusinessError) {
              notifyError("Password is not secure");
            } else if (e instanceof NotFoundError) {
              notifyError("Invalid token");
            } else {
              notifyError("An error occurred during the request");
              logger.warn("UserPasswordReset failed", {
                exception: e,
                token
              });
            }
          }
        }
      })();
    },
    [api, navigateTo, notifyError, notifySuccess, token]
  );
};
