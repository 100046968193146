import React from "react";
import cx from "classnames";
import { reduxForm } from "redux-form";

import { MEDIA_TITLE_MAPPING } from "./media-title-mapping";
import { MediaDialogType } from "./media-dialog-type";
import { DateTimeField } from "../../../ux/date-time-field";
import { TextField } from "../../../forms/text-field";
import { TextareaField } from "client/modules/forms/textarea-field";
import { AssetType } from "shared/types/asset-type";
import { ReactComponent as IconTrash } from "../../../../images/trash.svg";
import { AssetViewModel } from "../../../entity-repository/entity.types";

export interface UploadMediaForm {
  title: string;
  description?: string;
  url?: string;
}

interface UploadMediaFormProps {
  saveInProgress: boolean;
  onSave: (data: UploadMediaForm) => void;
  onDelete: () => void;
  asset: AssetViewModel;
  dialogType: MediaDialogType;
}

export const UploadMediaForm = reduxForm<UploadMediaForm, UploadMediaFormProps>(
  {
    form: "upload-media",
    enableReinitialize: true
  }
)(
  ({
    saveInProgress,
    handleSubmit,
    onSave,
    onDelete,
    asset: { createdAt, updatedAt, title, formatName, dimensions, type },
    dialogType
  }) => {
    return (
      <form onSubmit={handleSubmit(onSave)}>
        <h2 className="editMediaHeader">
          Edit {MEDIA_TITLE_MAPPING[dialogType]}
        </h2>
        <table className="mediaSummary">
          <tbody>
            <tr>
              <th>Created on:</th>
              <td>
                <DateTimeField datetime={createdAt} />
              </td>
            </tr>
            <tr>
              <th>Name:</th>
              <td>{title}</td>
            </tr>
            <tr>
              <th>Type:</th>
              <td>{formatName ? formatName : "—"}</td>
            </tr>
            <tr>
              <th>Resolution:</th>
              <td>
                {dimensions && dimensions.width !== 0 ? (
                  <>
                    {dimensions.width}&times;{dimensions.height}
                  </>
                ) : (
                  "—"
                )}
              </td>
            </tr>
            {updatedAt && (
              <tr>
                <th>Updated on:</th>
                <td>
                  <DateTimeField datetime={updatedAt} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <TextField name="title" label="Name" />
        <TextareaField name="description" label="Description" />
        {type === AssetType.ASSET_URL && <TextField name="url" label="URL" />}
        <div className="modalButtons">
          <button
            className="button buttonAlert buttonOutlined buttonIcon left"
            type="button"
            onClick={onDelete}
          >
            <IconTrash />
          </button>
          <button
            className={cx("button", {
              inProgress: saveInProgress
            })}
            type="submit"
            data-qa="update-info-btn"
          >
            Update Info
          </button>
        </div>
      </form>
    );
  }
);
