import React, { useState, useCallback, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Field, BaseFieldProps, WrappedFieldProps } from "redux-form";
import { ChromePicker } from "react-color";
import { validateHTMLColorHex } from "validate-color";

import { useSafeDebounce } from "../utils/use-safe-debounce";

interface ColorPickerFieldProps {
  colors: string[];
  onVisibleChange: (value: boolean) => void;
}

const ColorPickerFieldImpl = ({
  input,
  onVisibleChange,
  ...rest
}: ColorPickerFieldProps & WrappedFieldProps) => {
  const value = input.value;
  const [color, setColor] = useState(value);
  const [visible, setVisible] = useState(false);

  const changeColorDebounced = useSafeDebounce(input.onChange, [], 300, {});
  const changeColor = useCallback(
    (color: string) => {
      setColor(color);
      if (validateHTMLColorHex(color)) {
        changeColorDebounced(color);
      }
    },
    [changeColorDebounced, setColor]
  );

  const canToggleOn = useRef(true);

  const toggleOn = useCallback(() => {
    if (canToggleOn.current) {
      setVisible(true);
      onVisibleChange(true);
    }
  }, [setVisible, onVisibleChange]);

  const blur = useCallback(
    (ev) => {
      canToggleOn.current = false;
      setVisible(false);
      onVisibleChange(false);

      // Postpone to next cycle
      // unfortunately this is a bug OutsideClickHandler
      // because ev.stopPropagation simply doesn't work
      setTimeout(() => {
        canToggleOn.current = true;
      });
      ev.stopPropagation();
    },
    [setVisible, onVisibleChange]
  );

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <>
      <button
        className="button colorButton"
        type="button"
        onClick={toggleOn}
        data-qa="color-picker-button"
      />
      {visible && (
        <OutsideClickHandler onOutsideClick={blur}>
          <ChromePicker
            onChange={(value) => changeColor(value.hex)}
            color={color}
            disableAlpha
            {...rest}
          />
        </OutsideClickHandler>
      )}
    </>
  );
};

export const ColorPickerField = (
  props: BaseFieldProps<ColorPickerFieldProps> & ColorPickerFieldProps
) => <Field component={ColorPickerFieldImpl} {...props} />;
