import { useCallback, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DialogsActions } from "./dialogs-reducer";
import { DialogType } from "./dialog-type";
import { DialogEvent, DialogResult } from "./event-types";
import { getDialogEvent } from "./dialogs-selectors";
import { UserSafe } from "shared/types/user";

interface DialogEventInterface {
  type: DialogEvent | DialogResult;
  payload: any;
}

const useBaseDialog = <E, T = any>(
  dialogType: DialogType,
  resultResolver: (dialogEvent: DialogEventInterface) => any
) => {
  const dialogEvent = useSelector(getDialogEvent);

  const dispatch = useDispatch();

  const dialogResolver = useRef<
    (dialogEvent: { type: DialogResult; payload?: T }) => void
  >();

  const openConfirmDialog = useCallback(
    async (config: E) => {
      dispatch(DialogsActions.cleanUp());
      dispatch(
        DialogsActions.configure({
          dialogType: dialogType,
          config
        })
      );
      dispatch(DialogsActions.open());
      return new Promise<{ type: DialogResult; payload?: T }>((resolve) => {
        dialogResolver.current = resolve;
      });
    },
    [dispatch, dialogType]
  );

  useEffect(() => {
    if (dialogEvent && dialogResolver.current) {
      dispatch(DialogsActions.close());
      const result = resultResolver(dialogEvent);
      dialogResolver.current(result);
      dispatch(DialogsActions.cleanUp());
      dialogResolver.current = undefined;
    }
    // We need want to call resolver just for events when the dialog is opened. If we would include resultResolver into dep array,
    // the resolver would be called while reopening the dialog and potentially with foreign events.
    // eslint-disable-next-line
  }, [dialogEvent, dispatch]);

  return openConfirmDialog;
};

export const useConfirmationDialog = () => {
  return useBaseDialog<{
    title: string;
    text: string;
    subtext: string;
    confirmLabel: string;
    cancelLabel: string;
  }>(DialogType.CONFIRM, (dialogEvent: DialogEventInterface) => {
    if (dialogEvent.type === DialogEvent.ON_CONFIRM) {
      return {
        type: DialogResult.CONFIRM
      };
    } else {
      return {
        type: DialogResult.CANCEL
      };
    }
  });
};

export const useConfirmationWithInputDialog = () => {
  return useBaseDialog<{
    title: string;
    text: string;
    subtext: string;
    confirmLabel: string;
    cancelLabel: string;
    fieldLabel: string;
    confirmText: string;
  }>(DialogType.CONFIRM_INPUT, (dialogEvent: DialogEventInterface) => {
    if (dialogEvent.type === DialogEvent.ON_CONFIRM) {
      return {
        type: DialogResult.CONFIRM
      };
    } else {
      return {
        type: DialogResult.CANCEL
      };
    }
  });
};

export const useCollaboratorsDialog = () => {
  return useBaseDialog<
    {
      creatorId: number;
      collaborators: UserSafe[];
    },
    UserSafe[]
  >(DialogType.COLLABORATORS, (dialogEvent: DialogEventInterface) => {
    if (dialogEvent.type === DialogEvent.ON_CLOSE) {
      return {
        type: DialogResult.COLLABORATORS_SELECTED,
        payload: dialogEvent.payload
      };
    } else if (dialogEvent.type === DialogEvent.ON_CANCEL) {
      return { type: DialogResult.CANCEL };
    } else {
      throw new Error("Invalid state");
    }
  });
};

export const useEscapeHook = (open: boolean, closeDialog: Function) => {
  const closeDialogRef = useRef(closeDialog);

  useEffect(() => {
    if (closeDialogRef) {
      closeDialogRef.current = closeDialog;
    }
  }, [closeDialog, closeDialogRef]);

  const onDocumentKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (!open) {
        return;
      }
      if (ev.key === "Escape" && closeDialogRef.current) {
        closeDialogRef.current();
      }
    },
    [open, closeDialogRef]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", onDocumentKeyDown);
    } else {
      document.removeEventListener("keydown", onDocumentKeyDown);
    }

    return () => document.removeEventListener("keydown", onDocumentKeyDown);
  }, [open, onDocumentKeyDown]);
};

//TODO finish all dialogs
