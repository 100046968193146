import { getPresentationTitle } from "../presentations/presentation-selectors";
import {
  ASSETS_ROUTE,
  AUTH_LOGIN_ROUTE,
  AUTH_NEW_PASSWORD_ROUTE,
  AUTH_RESET_PASSWORD_ROUTE,
  AUTH_SIGN_UP_ROUTE,
  AUTH_USER_PROFILE_ROUTE,
  DASHBOARD_ROUTE,
  PRESENTATIONS_ROUTE,
  SCENES_ROUTE,
  SETTINGS_ROUTE,
  WALLS_ROUTE
} from "./route-names";

const editorChildNodes = [
  {
    name: "assets",
    path: "/assets/:libraryTab<my-media|shared>",
    children: [
      { name: "asset-library", path: "/asset-library" },
      {
        name: "asset-actions",
        path: "/actions/:sceneAssetId",
        children: [
          {
            name: "tab",
            path: "/:tab<scenes|assets>"
          }
        ]
      }
    ]
  },
  {
    name: "backgrounds",
    path: "/backgrounds/:libraryTab<my-media>",
    children: [{ name: "asset-library", path: "/asset-library" }]
  },
  {
    name: "transitions",
    path: "/transitions/:libraryTab<my-media>",
    children: [{ name: "asset-library", path: "/asset-library" }]
  }
];

export default [
  {
    name: "index",
    path: "/",
    forwardTo: "dashboard"
  },
  {
    name: "components",
    path: "/components"
  },
  {
    name: AUTH_USER_PROFILE_ROUTE,
    path: "/user-profile"
  },
  {
    name: "help",
    path: "/help"
  },
  {
    name: AUTH_LOGIN_ROUTE,
    path: "/login?failure"
  },
  {
    name: AUTH_RESET_PASSWORD_ROUTE,
    path: "/reset-password",
    children: [
      {
        name: "request-send",
        path: "/send"
      }
    ]
  },
  {
    name: AUTH_NEW_PASSWORD_ROUTE,
    path: "/new-password?token&error",
    children: [
      {
        name: "done",
        path: "/done"
      }
    ]
  },
  {
    name: AUTH_SIGN_UP_ROUTE,
    path: "/sign-up?token&error"
  },
  {
    name: DASHBOARD_ROUTE,
    path: "/dashboard?merged"
  },
  {
    name: ASSETS_ROUTE,
    path: "/assets",
    children: [
      {
        name: "list",
        path: "/:tab<my|shared>",
        children: [
          {
            name: "edit-media",
            path: "/:uuid"
          },
          { name: "edit-shared-asset", path: "/shared-asset/:uuid" }
        ]
      }
    ]
  },
  {
    name: SCENES_ROUTE,
    path: "/scenes",
    children: [
      {
        name: "list",
        path: "/:tab<own|global|shared|cooperation>",
        children: [
          {
            name: "add",
            path: "/add"
          },
          {
            name: "edit",
            path: "/:sceneId",
            children: [
              {
                name: "editor",
                path: "/edit",
                children: editorChildNodes
              },
              {
                name: "preview",
                path: "/preview"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: PRESENTATIONS_ROUTE,
    path: "/presentations",
    breadcrumb: {
      title: "Presentations",
      route: "presentations.list",
      routeParams: { tab: "own" }
    },
    children: [
      {
        name: "edit",
        path: "/edit/:presentationId",
        breadcrumb: {
          title: getPresentationTitle,
          route: "presentations.edit"
        },
        children: [
          {
            name: "create-scene",
            path: "/create-scene"
          },
          {
            name: "attach-scene",
            path: "/attach-scene"
          },
          {
            name: "edit-scene",
            path: "/edit-scene/:sceneId"
          },
          {
            name: "editor",
            path: "/editor/:sceneId",
            children: editorChildNodes
          },
          {
            name: "triggers",
            path: "/triggers"
          },
          {
            name: "preview",
            path: "/preview/:sceneId"
          }
        ]
      },
      {
        name: "list",
        path: "/:tab<own|public|shared|collaborate>",
        children: [
          {
            name: "add",
            path: "/add"
          }
        ]
      }
    ]
  },
  {
    name: WALLS_ROUTE,
    path: "/walls",
    children: [
      {
        name: "list",
        path: "/:tab<all|image|video>",
        children: [
          {
            name: "edit-media",
            path: "/:uuid"
          }
        ]
      }
    ]
  },
  {
    name: SETTINGS_ROUTE,
    path: "/settings",
    children: [
      {
        name: "users",
        path: "/users",
        children: [
          {
            name: "add",
            path: "/add"
          },
          {
            name: "edit",
            path: "/:id/edit"
          }
        ]
      },
      {
        name: "domains",
        path: "/domains",
        children: [
          {
            name: "add",
            path: "/add"
          },
          {
            name: "edit",
            path: "/:id/edit"
          }
        ]
      },
      {
        name: "company-library",
        path: "/company-library",
        children: [
          {
            name: "add-filter",
            path: "/:id/add-filter"
          },
          {
            name: "edit-filter",
            path: "/edit-filter/:fieldId"
          },
          {
            name: "edit-filter-mapping",
            path: "/edit-filter-mapping/:fieldId"
          },
          {
            name: "edit-category",
            path: "/:id/edit-category"
          }
        ]
      },
      {
        name: "presentations",
        path: "/presentations",
        children: [
          {
            name: "add-filter",
            path: "/:id/add-filter"
          },
          {
            name: "edit-filter",
            path: "/edit-filter/:fieldId"
          },
          {
            name: "edit-filter-mapping",
            path: "/edit-filter-mapping/:fieldId"
          },
          {
            name: "edit-category",
            path: "/:id/edit-category"
          }
        ]
      },
      {
        name: "predefined-scene-names",
        path: "/predefined-scene-names"
      },
      {
        name: "editor-settings",
        path: "/editor-settings"
      },
      {
        name: "triggers",
        path: "/triggers",
        breadcrumb: {
          title: "Triggers",
          route: "triggers.list"
        },
        children: [
          {
            name: "list",
            path: "/list",
            children: [
              {
                name: "add-trigger",
                path: "/add-trigger"
              },
              {
                name: "edit-trigger",
                path: "/:id"
              }
            ]
          }
        ]
      }
    ]
  }
];
