import React, { ReactNode } from "react";
import cx from "classnames";
import { createPortal } from "react-dom";
import { DialogEvent } from "../event-types";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

export const ModalDialogBase = (props: {
  open: boolean;
  title: string;
  className?: string;
  text?: ReactNode;
  subtext?: string;
  onEvent: (type: any, payload?: any) => void;
  onClose?: () => void;
  buttons?: ReactNode;
  dataQa?: string;
}) => {
  const {
    open,
    title,
    className,
    text,
    subtext,
    onEvent,
    onClose,
    buttons,
    dataQa
  } = props;

  return createPortal(
    open && (
      <div
        className={cx("modalBox", "modalConfirm", className)}
        data-qa={dataQa}
      >
        <h2 className="modalHeader">
          {title}
          <button
            onClick={() => {
              if (onClose) {
                onClose();
              } else {
                onEvent(DialogEvent.ON_CLOSE);
              }
            }}
            className="closeBtn"
            data-qa={MODAL_LOCATORS.buttons.close}
          />
        </h2>
        <div className="modalContent" data-qa={MODAL_LOCATORS.content}>
          {text && (
            <div className="text" data-qa="text">
              {text}
            </div>
          )}
          {subtext && (
            <div className="subtext" data-qa="sub-text">
              {subtext}
            </div>
          )}
        </div>
        {buttons && (
          <div className="modalFooter" data-qa={MODAL_LOCATORS.footer}>
            {buttons}
          </div>
        )}
      </div>
    ),
    document.getElementById("modal") as HTMLElement
  );
};
