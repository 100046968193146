import { createActions } from "reduxsauce";

export default createActions(
  {
    loggedUser: ["id"],
    logout: null,
    saveUserProfile: ["data"],
    sendPasswordResetToken: ["email"],
    resetPassword: ["newPassword"]
  },
  { prefix: "AUTH/" }
);
