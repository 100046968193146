import React, { useCallback } from "react";
import { reduxForm } from "redux-form";
import { actions as routerActions } from "redux-router5";

import { Modal } from "../../../ux/modal";
import {
  TriggersForm,
  TriggersFormCustomProps,
  TriggersFormData
} from "./triggers-form";
import { TRIGGERS_LIST_ROUTE } from "client/modules/routing/route-names";
import { useDispatch } from "react-redux";
import {
  ApiCallExtractorEdit,
  useEditRecord
} from "client/modules/crud/use-edit-records";
import { trigger as triggerSchema } from "client/modules/entity-repository/schema";
import {
  ApiExtractorGet,
  useFetchRecord
} from "client/modules/crud/use-fetch-record";
import { TriggerFull } from "shared/types/trigger";
import { getEditingTrigger } from "../triggers-selectors";
import {
  useConfirmationDialog,
  useEscapeHook
} from "client/modules/dialogs/dialog-hooks";
import { asyncValidate, validate } from "./triggers-form-validate";
import { DialogResult } from "client/modules/dialogs/event-types";
import { useApi } from "client/modules/api/use-api";
import { useNotifications } from "client/modules/ux/notifications/use-notifications";

const FORM_NAME = "triggers-edit";
const TriggersEditForm = reduxForm<TriggersFormData, TriggersFormCustomProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  validate,
  asyncValidate
})(TriggersForm);

const fetchRecordExtractor: ApiExtractorGet<TriggersFormData> = (api) =>
  api.getTrigger;
const updateRecordExtractor: ApiCallExtractorEdit<
  TriggerFull,
  TriggersFormData
> = (api) => api.updateTrigger;

export const TriggersEdit = () => {
  const dispatch = useDispatch();
  const goBack = useCallback(() => {
    dispatch(
      routerActions.navigateTo(TRIGGERS_LIST_ROUTE, {}, { replace: true })
    );
  }, [dispatch]);

  const fetchRecordCommand = useFetchRecord(
    triggerSchema,
    fetchRecordExtractor,
    getEditingTrigger
  );
  const editRecordCommand = useEditRecord(triggerSchema, updateRecordExtractor);
  const confirmationDialog = useConfirmationDialog();
  const api = useApi();
  const notifications = useNotifications();

  const onSave = useCallback(
    async (data: TriggersFormData) => {
      const record = fetchRecordCommand.record as TriggerFull;

      if (record.instanceUuid !== data.instanceUuid) {
        const {
          data: { triggersToDelete }
        } = await api.getAffectedPresentationSceneTriggersCount(
          record.id,
          data.instanceUuid as string
        );

        if (triggersToDelete > 0) {
          // TODO: define microcopy
          const confirmationResult = await confirmationDialog({
            title: `Triggers are connected`,
            text: `There are ${triggersToDelete} selected triggers connected and those will be deleted`,
            subtext: "",
            confirmLabel: "Yes",
            cancelLabel: "No"
          });

          if (confirmationResult.type === DialogResult.CANCEL) {
            return;
          }
        }
      }

      const success = await editRecordCommand.editRecord(data);
      if (success) {
        notifications.notifySuccess("Trigger has been updated.");
        goBack();
      } else {
        notifications.notifyError(
          "There's been an error during updating of the trigger."
        );
      }
    },
    [
      goBack,
      fetchRecordCommand,
      editRecordCommand,
      api,
      confirmationDialog,
      notifications
    ]
  );

  useEscapeHook(true, goBack);

  return (
    <Modal
      className="triggersModal has-footer is-bleeding"
      onCloseClick={goBack}
      title="Edit Trigger"
    >
      <TriggersEditForm
        initialValues={fetchRecordCommand.record as TriggersFormData}
        saving={fetchRecordCommand.inProgress || editRecordCommand.inProgress}
        onSave={onSave}
      />
    </Modal>
  );
};
