import { useNotifications } from "client/modules/ux/notifications/use-notifications";
import { useCallback } from "react";
import { useDispatch, useStore } from "react-redux";
import { Logger as logger } from "purplex-logging";

import { getScene, getSceneId } from "../../scene-editor-selectors";
import { useApi } from "../../../api/use-api";
import { SceneEditorActions } from "../../scene-editor-reducer";
import {
  instances as instancesSchema,
  scene as sceneSchema,
  sceneAsset as sceneAssetSchema
} from "../../../entity-repository/schema";
import { useNormalizeAndStore } from "../use-normalize-and-store";
import { SceneAssetBulkOperationFlag } from "../../../../../shared/types/scene-asset";

export type SceneAssetUpdatableProperties = Partial<{
  left: number;
  top: number;
  positionLocked: boolean;
  scale: number;
  width: number;
  height: number;
  layer: number;
  visible: boolean;
  opacity: number;
  loop: boolean;
  autoplay: boolean;
  glow: boolean;
  rounded: boolean;
  cropHeight: number;
  cropWidth: number;
  cropOffsetX: number;
  cropOffsetY: number;
}>;

export const useSceneEditorUtils = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const api = useApi();
  const normalizeAndStore = useNormalizeAndStore();
  const { notifyError } = useNotifications();

  const initialize = useCallback(async () => {
    logger.debug("Initializing scene editor state.");
    const id = getSceneId(store.getState());
    if (!id) {
      logger.debug("Can not initialize scene, missing scene id");
      return;
    }
    try {
      dispatch(SceneEditorActions.resetSceneEditor());
      const instances = await api.getInstanceConfigs();
      normalizeAndStore(instances, instancesSchema);

      const scene = await api.fetchScene(id);
      normalizeAndStore(scene, sceneSchema);
      dispatch(SceneEditorActions.setInitializingSuccess());
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 404) {
          dispatch(
            SceneEditorActions.setInitializingError({
              message:
                "The scene you are trying to open doesn't exist or you do not have permission to open it."
            })
          );
          return;
        }
      }
      dispatch(
        SceneEditorActions.setInitializingError({
          message: "Scene load failed."
        })
      );
      notifyError("There was an error while initializing scene editor.");
      logger.error(`Error initalizing scene editor for scene ${id}`, ex);
    }
  }, [dispatch, normalizeAndStore, store, api, notifyError]);

  const fetchSceneData = useCallback(async () => {
    logger.debug("Fetching scene editor data.");
    const id = getSceneId(store.getState());
    if (!id) {
      logger.warn("Can not fetch scene data, missing scene id");
      return;
    }
    try {
      logger.debug("Refetching scene data");
      const scene = await api.fetchScene(id);
      normalizeAndStore(scene, sceneSchema);
    } catch (ex) {
      notifyError("There was an error while getting scene editor data.");
      logger.error(`Can not fetch scene data for scene ${id}`);
    }
  }, [store, api, normalizeAndStore, notifyError]);

  const bulkUpdateApiCall = useCallback(
    async (formData: SceneAssetUpdatableProperties & { uuid: string }[]) => {
      const scene = getScene(store.getState());

      if (!scene) {
        logger.warn("Can not update scene assets, missing initialized scene.");
        return;
      }

      return api.updateSceneAssets(scene.id, formData);
    },
    [store, api]
  );

  const updateAssets = useCallback(
    async (
      assets: SceneAssetUpdatableProperties &
        { uuid: string; operationFlag?: SceneAssetBulkOperationFlag }[]
    ) => {
      logger.debug("Updating assets", assets);
      assets.forEach((asset) => {
        normalizeAndStore(asset, sceneAssetSchema);
      });
      try {
        await bulkUpdateApiCall(assets);
      } catch (e) {
        logger.error("Error while saving scene assets", e, assets);
        notifyError("There was an error while saving scene assets.");
        await initialize();
      }
    },
    [bulkUpdateApiCall, initialize, normalizeAndStore, notifyError]
  );

  const updateAssetsWithResponseStoreUpdate = useCallback(
    async (
      assets: SceneAssetUpdatableProperties &
        { uuid: string; operationFlag?: SceneAssetBulkOperationFlag }[]
    ) => {
      logger.debug(
        "Updating assets with store update after getting response",
        assets
      );
      const scene = getScene(store.getState());
      if (!scene) {
        logger.warn("Can not update scene assets, missing initialized scene.");
        return;
      }

      normalizeAndStore(
        {
          ...scene,
          sceneAssets: assets.filter(
            (asset) =>
              asset.operationFlag !== SceneAssetBulkOperationFlag.DELETE
          )
        },
        sceneSchema
      );
      try {
        const updatedScene = await bulkUpdateApiCall(assets);
        normalizeAndStore(updatedScene, sceneSchema);
      } catch (e) {
        logger.error("Error while saving scene asset", e, assets);
        notifyError("There was an error while saving scene assets.");
        await initialize();
      }
    },
    [bulkUpdateApiCall, initialize, normalizeAndStore, store, notifyError]
  );

  const deleteAssetsWithResponseStoreUpdate = useCallback(
    async (
      assets: SceneAssetUpdatableProperties &
        { uuid: string; operationFlag?: SceneAssetBulkOperationFlag }[]
    ) => {
      logger.debug(
        "Deleting assets with store update after getting response",
        assets
      );
      const scene = getScene(store.getState());
      if (!scene) {
        logger.warn("Can not delete scene assets, missing initialized scene.");
        return;
      }

      normalizeAndStore(
        {
          ...scene,
          sceneAssets: scene.sceneAssets.filter(
            (sceneAsset) =>
              !assets.map((asset) => asset.uuid).includes(sceneAsset.uuid)
          )
        },
        sceneSchema
      );
      try {
        const updatedScene = await bulkUpdateApiCall(assets);
        normalizeAndStore(updatedScene, sceneSchema);
      } catch (e) {
        logger.error("Error while deleting scene assets", e, assets);
        notifyError("There was an error while saving scene assets.");
        await initialize();
      }
    },
    [bulkUpdateApiCall, initialize, normalizeAndStore, store, notifyError]
  );

  return {
    initialize,
    fetchSceneData,
    bulkUpdateApiCall,
    updateAssets,
    updateAssetsWithResponseStoreUpdate,
    deleteAssetsWithResponseStoreUpdate
  };
};
