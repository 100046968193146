import React from "react";

import { OkStatus } from "./wall-dimensions-ok-status";
import { WarningStatus } from "./wall-dimensions-warning";

export const WallDimensionsStatus = (props: {
  isValid: boolean;
  okMessage: string;
  warningMessage: string;
}) => {
  const { isValid, okMessage, warningMessage } = props;

  if (isValid) {
    return <OkStatus message={okMessage} />;
  }

  return <WarningStatus message={warningMessage} />;
};
