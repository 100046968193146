import { createSelector } from "reselect";

import { getBranding } from "../config/config-selectors";
import validate from "./components/scene-validate";
import { getRouteParams } from "../routing/routing-selectors";
import { getScenes } from "../entity-repository/entity-repository-selectors";
import {
  getPredefinedSceneNames,
  getScene as getState
} from "../root/root-selectors";

export const getInfiniteScrollLoadingKey = createSelector(
  getState,
  (state) => state.infiniteScrollLoadingKey
);

export const getSceneValidation = createSelector(getBranding, (branding) =>
  validate(branding.roomName)
);

export const getEditingScene = createSelector(
  getRouteParams,
  getScenes,
  getPredefinedSceneNames,
  (routeParams, scenes, predefinedSceneNames) => {
    if (routeParams.sceneId) {
      const scene = scenes[routeParams.sceneId];
      return getSceneInitialValuesImpl(scene, predefinedSceneNames);
    } else {
      return null;
    }
  }
);

export const getSceneInitialValuesImpl = (scene, { predefinedSceneNames }) => {
  if (scene) {
    if (
      !predefinedSceneNames.some(
        (predefinedSceneName) => predefinedSceneName.sceneName === scene.title
      )
    ) {
      const sceneCopy = { ...scene };
      sceneCopy.title_text = sceneCopy.title;
      delete sceneCopy.title;
      return sceneCopy;
    }
  }

  return scene;
};
