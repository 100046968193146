import { useCallback, useState } from "react";
import { Logger as logger } from "purplex-logging";
import { useDispatch, useStore } from "react-redux";
import { v4 } from "uuid";

import {
  EditedBackgroundState,
  getBackgroundAssetRatio,
  getBackgroundSettingFormInitialValues,
  getBackgroundSettingsUuid,
  getBackgroundState,
  getInstanceWallHeight,
  getScene,
  getSceneWallAsset,
  getTotalInstanceWidth,
  getWallAssetHeight,
  getWallAssetWidth
} from "../../scene-editor-selectors";
import { scene as sceneSchema } from "../../../entity-repository/schema";
import { useApi } from "../../../api/use-api";
import { useNormalizeAndStore } from "../use-normalize-and-store";
import { useNotifications } from "../../../ux/notifications/use-notifications";
import { checkEditBackgroundStateValidity } from "../../utils";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { getAssets } from "../../../entity-repository/entity-repository-selectors";
import { useTracking } from "../../../user-tracking/use-tracking";
import * as UserTrackingCreators from "../../../user-tracking/user-tracking-creators";
import { AssetLibraryActions } from "../../asset-library/asset-library-reducer";

const noop = () => {};

export type RedoableActionFactory<Params extends Array<any>> = (
  ...args: Params
) => {
  redoCallback: () => Promise<void> | void;
  undoCallback: () => Promise<void> | void;
};

const useRedoableAction = <Params extends Array<any>>(
  action: RedoableActionFactory<Params>
) => {
  const dispatch = useDispatch();

  return useCallback(
    async (...args: Params) => {
      const { redoCallback, undoCallback } = action(...args);

      await redoCallback();

      dispatch(
        SceneEditorActions.addActionBatchToUndoStack({
          actions: [],
          newVersion: true,
          redoCallback,
          undoCallback
        })
      );
      dispatch(SceneEditorActions.resetRedoStack());
    },
    [action, dispatch]
  );
};

export const useEditBackgroundActions = () => {
  const [ratioLocked, setRatioLocked] = useState(true);

  const store = useStore();
  const api = useApi();
  const normalizeAndStore = useNormalizeAndStore();
  const dispatch = useDispatch();
  const { notifyError } = useNotifications();
  const tracking = useTracking();

  const setBackgroundSettings = useCallback(
    async (
      data:
        | {
            uuid: string;
            left: number;
            top: number;
            width: number;
            height: number;
          }
        | undefined
    ) => {
      const scene = getScene(store.getState());
      const roomHeight = getInstanceWallHeight(store.getState());
      const roomWidth = getTotalInstanceWidth(store.getState());

      if (!scene || !roomHeight || !roomWidth) {
        return;
      }

      const validity = data
        ? checkEditBackgroundStateValidity(data, {
            width: roomWidth,
            height: roomHeight
          })
        : EditedBackgroundState.VALID;

      const updatedData = data && {
        backgroundTop: data.top,
        backgroundLeft: data.left,
        backgroundWidth: data.width,
        backgroundHeight: data.height,
        uuid: data.uuid
      };

      logger.debug("Updating background", updatedData);
      const updatedScene = {
        ...scene,
        backgroundSettings: updatedData ? [updatedData] : []
      };
      normalizeAndStore(updatedScene, sceneSchema);

      if (validity === EditedBackgroundState.INVALID) {
        logger.debug(
          "Background settings is in invalid state, saving just locally"
        );
        return;
      }

      try {
        await api.updateBackgroundSettings(scene.id, {
          backgroundSettings: updatedData
        });
      } catch (e) {
        //TODO REINIT scene
        logger.error(`Error updating background settings`, e);
        notifyError(`Error updating background settings.`);
      }
    },
    [normalizeAndStore, api, notifyError, store]
  );

  const setBackgroundFormData = useCallback(
    async (data: { autoplay?: boolean; loop?: boolean }) => {
      const scene = getScene(store.getState());

      if (!scene) {
        return;
      }

      logger.debug("Setting background form settings.", data);
      const updatedScene = {
        ...scene,
        ...data
      };
      normalizeAndStore(updatedScene, sceneSchema);

      try {
        await api.updateScene(scene.id, data);
        data.autoplay &&
          tracking(
            UserTrackingCreators.trackSceneEditorBackgroundChangeAutoplay(
              data.autoplay
            )
          );
        data.loop &&
          tracking(
            UserTrackingCreators.trackSceneEditorBackgroundChangeLoop(data.loop)
          );
      } catch (e) {
        //TODO REINIT scene
        logger.error(`Error setting background asset`, e);
        notifyError(`Error setting background asset.`);
      }
    },
    [store, api, notifyError, normalizeAndStore, tracking]
  );

  const setBackgroundAssetAction = useCallback(
    async (
      uuid: string | undefined | null,
      backgroundState:
        | {
            uuid: string;
            left: number;
            top: number;
            width: number;
            height: number;
          }
        | undefined,
      formState?: { loop?: boolean; autoplay?: boolean }
    ) => {
      const assets = getAssets(store.getState());
      const wall = uuid ? assets[uuid] : null;

      logger.debug("Setting background asset", uuid);

      await setBackgroundSettings(backgroundState);
      formState && (await setBackgroundFormData(formState));

      const scene = getScene(store.getState());
      const updatedScene = {
        ...scene,
        wall
      };
      normalizeAndStore(updatedScene, sceneSchema);

      if (!scene) {
        return;
      }

      if (wall) {
        tracking(
          UserTrackingCreators.trackSceneEditorBackgroundAdded(
            wall.type,
            scene.title
          )
        );
      } else {
        tracking(UserTrackingCreators.trackSceneEditorBackgrounDetached());
      }
      try {
        await api.changeSceneWall(scene.id, uuid);
        dispatch(SceneEditorActions.setSceneWallTrackingAction());
        dispatch(AssetLibraryActions.reloadAssets());
      } catch (e) {
        //TODO REINIT scene
        logger.error(`Error setting background asset`, e);
        notifyError(`Error setting background asset.`);
      }
    },
    [
      tracking,
      store,
      api,
      notifyError,
      normalizeAndStore,
      dispatch,
      setBackgroundSettings,
      setBackgroundFormData
    ]
  );

  const updateBackgroundActionFactory = useCallback(
    (data: {
      left?: number;
      top?: number;
      width?: number;
      height?: number;
    }) => {
      const uuidState = getBackgroundSettingsUuid(store.getState());
      const backgroundState = getBackgroundState(store.getState());
      const wallAssetHeight = getWallAssetHeight(store.getState());
      const wallAssetWidth = getWallAssetWidth(store.getState());
      const backgroundSettings = {
        height:
          (backgroundState && backgroundState.height) || wallAssetHeight || 0,
        width:
          (backgroundState && backgroundState.width) || wallAssetWidth || 0,
        top: (backgroundState && backgroundState.top) || 0,
        left: (backgroundState && backgroundState.left) || 0
      };

      logger.debug(`Creating undo&redo callbacks update background`);
      const uuid = uuidState || v4();

      const undoCallback = async () => {
        logger.debug("Running update background undo callback.");
        await setBackgroundSettings(backgroundState);
      };

      const redoCallback = async () => {
        logger.debug("Running update background redo callback.");
        await setBackgroundSettings({ ...backgroundSettings, ...data, uuid });
      };

      return { undoCallback, redoCallback };
    },
    [store, setBackgroundSettings]
  );

  const resetBackgroundActionsFactory = useCallback(() => {
    const backgroundState = getBackgroundState(store.getState());

    logger.debug(`Creating undo&redo reset background callbacks`);

    const undoCallback = async () => {
      logger.debug("Running reset background undo callback.");
      await setBackgroundSettings(backgroundState);
    };

    const redoCallback = async () => {
      logger.debug("Running reset background redo callback.");
      await setBackgroundSettings(undefined);
    };

    return { undoCallback, redoCallback };
  }, [store, setBackgroundSettings]);

  const setBackgroundAssetActionsFactory = useCallback(
    (assetUuid: string | undefined) => {
      const wallAsset = getSceneWallAsset(store.getState());
      const backgroundState = getBackgroundState(store.getState());
      const formState = getBackgroundSettingFormInitialValues(store.getState());

      logger.debug(`Creating undo&redo set background asset callbacks`);

      const undoCallback = async () => {
        logger.debug("Running set background asset undo callback.");
        await setBackgroundAssetAction(
          wallAsset && wallAsset.uuid,
          backgroundState,
          formState
        );
      };

      const redoCallback = async () => {
        logger.debug("Running set background asset redo callback.");
        await setBackgroundAssetAction(assetUuid, undefined);
      };

      return { undoCallback, redoCallback };
    },
    [store, setBackgroundAssetAction]
  );

  const setBackgroundFormActionsFactory = useCallback(
    (data: { autoplay?: boolean; loop?: boolean }) => {
      const scene = getScene(store.getState());

      logger.debug(`Creating undo&redo set background asset callbacks`);

      if (!scene) {
        logger.error(
          "Can not create undo/redo set background form action, missing scene"
        );
        return { undoCallback: noop, redoCallback: noop };
      }

      const undoCallback = async () => {
        logger.debug("Running set background form undo callback.");
        await setBackgroundFormData({
          autoplay: scene.autoplay,
          loop: scene.loop
        });
      };

      const redoCallback = async () => {
        logger.debug("Running set background form redo callback.");
        await setBackgroundFormData(data);
      };

      return { undoCallback, redoCallback };
    },
    [store, setBackgroundFormData]
  );

  const resetBackground = useRedoableAction(resetBackgroundActionsFactory);

  const updateBackground = useRedoableAction(updateBackgroundActionFactory);

  const setBackgroundForm = useRedoableAction(setBackgroundFormActionsFactory);

  const setBackgroundAsset = useRedoableAction(
    setBackgroundAssetActionsFactory
  );

  const fitToWidth = useCallback(async () => {
    const totalWidth = getTotalInstanceWidth(store.getState());
    const ratio = getBackgroundAssetRatio(store.getState());

    if (totalWidth === null || !ratio) {
      logger.error(
        `Could not perform fit to width action, room width or asset ratio is undefined`
      );
      notifyError(`Error while performing "Fit to width" action`);
      return;
    }

    if (ratioLocked) {
      await updateBackground({
        left: 0,
        top: 0,
        width: totalWidth,
        height: Math.round(totalWidth / ratio)
      });
    } else {
      await updateBackground({
        left: 0,
        width: totalWidth
      });
    }
  }, [store, updateBackground, notifyError, ratioLocked]);

  const fitToHeight = useCallback(async () => {
    const height = getInstanceWallHeight(store.getState());
    const ratio = getBackgroundAssetRatio(store.getState());

    if (height === null || !ratio) {
      logger.error(
        `Could not perform fit to height action, room width or asset ratio is undefined`
      );
      notifyError(`Error while performing "Fit to height" action`);
      return;
    }

    if (ratioLocked) {
      await updateBackground({
        left: 0,
        top: 0,
        width: Math.round(ratio * height),
        height: height
      });
    } else {
      await updateBackground({
        top: 0,
        height: height
      });
    }
  }, [store, updateBackground, notifyError, ratioLocked]);

  const toggleRatio = useCallback(() => {
    setRatioLocked((state) => !state);
  }, []);

  return {
    updateBackground,
    fitToWidth,
    fitToHeight,
    toggleRatio,
    ratioLocked,
    resetBackground,
    setBackgroundAsset,
    setBackgroundForm
  };
};
