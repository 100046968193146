import { createReducer } from "reduxsauce";

import Actions from "./scene-actions";

const INITIAL_STATE = {
  infiniteScrollLoadingKey: 0
};

const onIncrementLoadingKey = (state) => ({
  ...state,
  infiniteScrollLoadingKey: state.infiniteScrollLoadingKey + 1
});

export const HANDLERS = {
  [Actions.Types.INCREMENT_LOADING_KEY]: onIncrementLoadingKey
};

export default createReducer(INITIAL_STATE, HANDLERS);
