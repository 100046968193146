import Tooltip from "rc-tooltip";
import cx from "classnames";
import React, { useState } from "react";
import { actions as routerActions } from "redux-router5";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { TabPanel, Tabs } from "../../ux/tab-panel";
import { ListInfiniteScroll, ROW_STATUS } from "../../ux/list-infinite-scroll";
import { DebouncedSearchInput } from "../../ux/debounced-search-input";
import * as Api from "../../api/client";
import { DateTimeField } from "../../ux/date-time-field";
import { RelativeTimeField } from "../../ux/relative-time-field";
import { getScenes } from "../../entity-repository/entity-repository-selectors";
import { getRouteParams } from "../../routing/routing-selectors";
import { getInfiniteScrollLoadingKey } from "../scene-selectors";
import Actions from "../scene-actions";
import * as AuthSelectors from "../../auth/auth-selectors";
import {
  getSceneCollaborations,
  getSceneShares
} from "../../shares/shares-selectors";
import { getCdnHost } from "../../config/config-selectors";
import { getAssetThumbnail } from "../../media/get-asset-thumbnail";

import { ReactComponent as IconTrash } from "../../../images/trash.svg";
import { ReactComponent as IconShare } from "../../../images/share.svg";
import { ReactComponent as IconSettings } from "../../../images/cog.svg";
import { ReactComponent as IconClone } from "../../../images/clone.svg";
import { ReactComponent as IconScenes } from "../../../images/navigation/scenes.svg";
import { ReactComponent as IconAdd } from "../../../images/add.svg";
import { useDeleteScene } from "../hooks/use-scene-crud";

import { UserTrackingActions } from "../../user-tracking/user-tracking-slice";
import * as UserTrackingCreators from "../../user-tracking/user-tracking-creators";
import { ROW_LOCATORS } from "shared/tests/locators/row.locators";
import { SCENE_LOCATORS } from "shared/tests/locators/scenes.locators";

const TABS = [
  { label: "My scenes", route: "own" },
  { label: "Global scenes", route: "global" },
  { label: "Shared with me", route: "shared" },
  { label: "Collaborate", route: "cooperation" }
];

const SceneListLoading = () => <div className="gridItemPlaceholder"></div>;

const SceneListEmpty = () => (
  <div className="gridPlaceholder">The list is empty</div>
);

const SceneListRow = ({
  tab,
  scene,
  onEdit,
  onDelete,
  onClone,
  onSceneEditor,
  onShare,
  onPreview,
  onDeleteShare,
  isCreator,
  cdnHost,
  isCloningInProgress,
  isDeletingInProgress,
  isShareDeletingInProgress
}) => {
  const { id, title, description, createdAt, updatedAt, creator, wall } = scene;

  const onDeleteModal = useDeleteScene();

  const PREVIEW_BUTTON = (
    <button
      key="preview"
      onClick={onPreview}
      className="button"
      data-qa={ROW_LOCATORS.getAction("preview")}
    >
      Preview
    </button>
  );
  const DELETE_SHARE_BUTTON = (
    <button
      key="share"
      onClick={onDeleteShare}
      className={cx(
        "button buttonOutlined buttonAlert buttonIcon buttonRounded",
        {
          inProgress: isShareDeletingInProgress
        }
      )}
      data-qa={ROW_LOCATORS.getAction("delete-share")}
      title="Delete share request"
    >
      <IconTrash />
    </button>
  );
  const EDIT_BUTTON = (
    <button
      key="edit"
      onClick={onEdit}
      className="button buttonRounded buttonOutlined buttonIcon"
      data-qa={ROW_LOCATORS.getAction("edit")}
      title="Settings"
    >
      <IconSettings />
    </button>
  );
  const DELETE_BUTTON = (
    <button
      key="delete"
      onClick={() => onDeleteModal(title, onDelete)}
      className={cx("button buttonAlert buttonIcon buttonOutlined", {
        inProgress: isDeletingInProgress
      })}
      data-qa={ROW_LOCATORS.getAction("delete")}
    >
      <IconTrash />
    </button>
  );
  const CLONE_BUTTON = (
    <button
      key="clone"
      onClick={onClone}
      className={cx("button buttonRounded buttonOutlined buttonIcon", {
        inProgress: isCloningInProgress
      })}
      data-qa={ROW_LOCATORS.getAction("clone")}
      title="Clone"
    >
      <IconClone />
    </button>
  );
  const SCENE_EDITOR_BUTTON = (
    <button
      key="sceneeditor"
      onClick={onSceneEditor}
      className="button"
      data-qa={ROW_LOCATORS.getAction("edit-scene")}
    >
      Edit
    </button>
  );
  const SHARE_BUTTON = (
    <button
      key="share"
      onClick={onShare}
      className="button buttonRounded buttonOutlined buttonIcon"
      data-qa={ROW_LOCATORS.getAction("share")}
      title="Share"
    >
      <IconShare />
    </button>
  );

  const buttons = [];
  switch (tab) {
    case "own":
      buttons.push(
        SHARE_BUTTON,
        EDIT_BUTTON,
        CLONE_BUTTON,
        SCENE_EDITOR_BUTTON,
        DELETE_BUTTON
      );
      break;
    case "shared":
      buttons.push(DELETE_SHARE_BUTTON, CLONE_BUTTON, PREVIEW_BUTTON);
      break;
    case "global":
      buttons.push(
        isCreator && EDIT_BUTTON,
        CLONE_BUTTON,
        PREVIEW_BUTTON,
        isCreator && SCENE_EDITOR_BUTTON
      );
      break;
    case "cooperation":
      buttons.push(
        SHARE_BUTTON,
        EDIT_BUTTON,
        CLONE_BUTTON,
        SCENE_EDITOR_BUTTON
      );
      break;
    default:
      throw new Error("Illegal state");
  }

  return (
    <div className="gridRow" data-qa={ROW_LOCATORS.getRow(id)}>
      <i
        className="gridImage"
        style={{
          backgroundImage: wall
            ? `url(${getAssetThumbnail(wall, cdnHost)})`
            : "none"
        }}
      >
        {!wall && <IconScenes />}
      </i>
      <span className="gridCell" data-qa={ROW_LOCATORS.title}>
        <h3 className="gridRowTitle">{title}</h3>
        {description}
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.creator}>
        Created by
        <h4>{creator}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.created}>
        Created
        <Tooltip
          placement="bottomLeft"
          mouseEnterDelay={0.4}
          overlay={DateTimeField({ datetime: createdAt })}
        >
          <h4>
            <RelativeTimeField datetime={createdAt} />
          </h4>
        </Tooltip>
      </span>
      {updatedAt && (
        <>
          <hr />
          <span className="gridCell" data-qa={ROW_LOCATORS.updated}>
            Updated
            <Tooltip
              placement="bottomLeft"
              mouseEnterDelay={0.4}
              overlay={DateTimeField({ datetime: updatedAt })}
            >
              <h4>
                <RelativeTimeField datetime={updatedAt} />
              </h4>
            </Tooltip>
          </span>
        </>
      )}
      <hr />
      <span className="gridCell gridButtons" data-qa={ROW_LOCATORS.actions}>
        {buttons.filter(Boolean)}
      </span>
    </div>
  );
};

SceneListRow.propTypes = {
  tab: PropTypes.string.isRequired,
  scene: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    creator: PropTypes.string.isRequired,
    wall: PropTypes.object
  }),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onSceneEditor: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onDeleteShare: PropTypes.func.isRequired,
  isCreator: PropTypes.bool.isRequired,
  cdnHost: PropTypes.string.isRequired,
  isCloningInProgress: PropTypes.bool.isRequired,
  isDeletingInProgress: PropTypes.bool.isRequired,
  isShareDeletingInProgress: PropTypes.bool.isRequired
};

const mapStateToPropps = (state) => ({
  scenesRepository: getScenes(state),
  routeParams: getRouteParams(state),
  loadingKey: getInfiniteScrollLoadingKey(state),
  userId: AuthSelectors.getUserId(state),
  sceneShares: getSceneShares(state),
  sceneCollaborations: getSceneCollaborations(state),
  cdnHost: getCdnHost(state),
  isCloningInProgress: getProgressForKeySelector(state)(
    Actions.Types.CLONE_SCENE
  ),
  isDeletingInProgress: getProgressForKeySelector(state)(
    Actions.Types.DELETE_SCENE
  ),
  isShareDeletingInProgress: getProgressForKeySelector(state)(
    Actions.Types.DELETE_SHARE
  )
});

const mapDispatchToProps = {
  addScene: () => routerActions.navigateTo("scenes.list.add", { tab: "own" }),
  editScene: Actions.Creators.requestEditScene,
  deleteScene: Actions.Creators.deleteScene,
  cloneScene: Actions.Creators.cloneScene,
  shareScene: Actions.Creators.shareScene,
  deleteShare: Actions.Creators.deleteShare,
  track: UserTrackingActions.track,
  openSceneEditor: (tab, sceneId) =>
    routerActions.navigateTo("scenes.list.edit.editor.assets", {
      sceneId,
      tab,
      libraryTab: "my-media"
    }),
  previewScene: (tab, sceneId) =>
    routerActions.navigateTo("scenes.list.edit.preview", { sceneId, tab })
};

export const SceneList = connect(
  mapStateToPropps,
  mapDispatchToProps
)(
  ({
    scenesRepository,
    addScene,
    routeParams,
    editScene,
    deleteScene,
    cloneScene,
    previewScene,
    deleteShare,
    openSceneEditor,
    shareScene,
    loadingKey,
    sceneShares,
    sceneCollaborations,
    userId,
    cdnHost,
    track,
    isCloningInProgress,
    isDeletingInProgress,
    isShareDeletingInProgress
  }) => {
    const [search, changeSearch] = useState("");

    const getTabProps = (tab) => {
      return {
        "data-badge":
          tab.route === "shared" && sceneShares
            ? sceneShares
            : tab.route === "cooperation" && sceneCollaborations > 0
            ? sceneCollaborations
            : null
      };
    };

    return (
      <Tabs>
        <TabPanel
          tabs={TABS}
          getTabProps={getTabProps}
          heading="Scenes"
          buttons={
            <div className="buttons">
              <button
                className={cx("button", {
                  "is-enter": routeParams.tab === "own",
                  "is-leave": routeParams.tab !== "own"
                })}
                onClick={addScene}
                data-qa={SCENE_LOCATORS.buttons.createNew}
              >
                <IconAdd />
                New Scene
              </button>
            </div>
          }
        >
          {(tab) => (
            <>
              <div className="search inputWithButton">
                <DebouncedSearchInput
                  placeholder="Search scenes"
                  onChange={changeSearch}
                />
              </div>
              <div className="tabContent">
                <ListInfiniteScroll
                  rowHeight={70}
                  key={`${tab}${search}${loadingKey}`}
                  emptyPlaceholder={<SceneListEmpty />}
                  rowRendererProps={scenesRepository}
                  fetchData={(startIndex, stopIndex) =>
                    Api.fetchScenes(search, startIndex, stopIndex, tab)
                  }
                >
                  {(status, record, scenesRepositoryDependency) => {
                    switch (status) {
                      case ROW_STATUS.LOADED:
                        if (record) {
                          let scene = record;
                          if (scenesRepositoryDependency[record.id]) {
                            scene = scenesRepositoryDependency[record.id];
                          }

                          return (
                            <SceneListRow
                              tab={tab}
                              scene={scene}
                              cdnHost={cdnHost}
                              isCreator={userId === scene.creatorId}
                              onEdit={() =>
                                editScene(routeParams.tab, record.id)
                              }
                              onDelete={() => deleteScene(record.id, false)}
                              isDeletingInProgress={isDeletingInProgress(
                                record.id
                              )}
                              onClone={() => cloneScene(record.id)}
                              isCloningInProgress={isCloningInProgress(
                                record.id
                              )}
                              onSceneEditor={() => {
                                openSceneEditor(routeParams.tab, record.id);
                                track(
                                  UserTrackingCreators.trackEnterSceneEditor(
                                    "Scenes overview"
                                  )
                                );
                              }}
                              onShare={() =>
                                shareScene(record.id, record.title)
                              }
                              onPreview={() =>
                                previewScene(routeParams.tab, record.id)
                              }
                              onDeleteShare={() =>
                                deleteShare(record.id, record.title)
                              }
                              isShareDeletingInProgress={isShareDeletingInProgress(
                                record.id
                              )}
                            />
                          );
                        } else {
                          return false;
                        }
                      case ROW_STATUS.LOADING:
                        return <SceneListLoading />;
                      default:
                        throw new Error(`Unknown row state ${status}`);
                    }
                  }}
                </ListInfiniteScroll>
              </div>
            </>
          )}
        </TabPanel>
      </Tabs>
    );
  }
);
