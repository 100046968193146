import { createSelector } from "reselect";

import { getDialogs as getState } from "../root/root-selectors";

export const isDialogOpened = createSelector(getState, (state) => state.open);
export const getDialogConfig = createSelector(
  getState,
  (state) => state.config
);
export const getDialogType = createSelector(getState, (state) => state.type);
export const getDialogEvent = createSelector(getState, (state) => state.event);
