import { createSelector } from "reselect";
import { getAuth as getState } from "../root/root-selectors";
import { getUsers } from "../entity-repository/entity-repository-selectors";
import { getCdnHost } from "../config/config-selectors";
import {
  AUTH_LOGIN_ROUTE,
  AUTH_NEW_PASSWORD_ROUTE,
  AUTH_RESET_PASSWORD_ROUTE,
  AUTH_SIGN_UP_ROUTE
} from "../routing/route-names";
import { getRouteName } from "../routing/routing-selectors";

export const getUserId = createSelector(getState, (state) => state.user);

export const getUser = createSelector(
  getUserId,
  getUsers,
  (userId, users) => users[userId]
);

export const hasProfile = createSelector(getUserId, (userId) =>
  Boolean(userId)
);

export const isRegistrationCompleted = createSelector(
  hasProfile,
  getUser,
  (profileLoaded, user) => {
    if (profileLoaded) {
      return user.registrationCompleted;
    } else {
      return;
    }
  }
);

export const getUserEmail = createSelector(
  getUser,
  (user) => (user && user.email) || ""
);

export const getRoles = createSelector(getUser, (user) =>
  user ? user.roles : []
);

export const getUserAvatar = createSelector(
  getCdnHost,
  getUser,
  (cdnHost, user) => user && user.avatar && `${cdnHost}/avatars/${user.avatar}`
);

export const getPasswordResetEmail = createSelector(
  getState,
  ({ passwordResetSendTo }) => passwordResetSendTo
);

export const isLoginProtectedPage = createSelector(
  getRouteName,
  (routeName) =>
    ![
      AUTH_LOGIN_ROUTE,
      AUTH_SIGN_UP_ROUTE,
      AUTH_RESET_PASSWORD_ROUTE,
      AUTH_NEW_PASSWORD_ROUTE
    ].some((route) => String(routeName).startsWith(route))
);
