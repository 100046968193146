import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as routerActions } from "redux-router5";
import {
  getRouteName,
  getRouteParams,
  RouteParamSelector
} from "client/modules/routing/routing-selectors";
import { useEffectWithMountGuard } from "client/modules/utils/use-effect-with-mount-guard";
import { useApi } from "client/modules/api/use-api";

export const PresentationTriggersButton = ({
  instances
}: {
  instances: string[];
}) => {
  const [hasTriggers, setHasTriggers] = useState(false);
  const dispatch = useDispatch();

  const route = useSelector(getRouteName);
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<unknown>
  );

  const changeRoute = useCallback(
    (route: string, params: any) => {
      dispatch(routerActions.navigateTo(route, params));
    },
    [dispatch]
  );
  const editTriggers = useCallback(
    () => changeRoute(`${route}.triggers`, routeParams),
    [changeRoute, route, routeParams]
  );

  const api = useApi();

  const fetchTriggersEffect = useCallback(
    async (mountGuard) => {
      const triggers = await api.getTriggers({
        search: "",
        startIndex: 0,
        stopIndex: 1,
        instanceUuids: instances
      });

      if (mountGuard()) {
        setHasTriggers(triggers.count > 0);
      }
    },
    [instances, api]
  );

  useEffectWithMountGuard(fetchTriggersEffect);

  return hasTriggers ? (
    <div className="inputBox">
      <label>Trigger Interactions</label>
      <div>
        <button
          type="button"
          className="button"
          onClick={editTriggers}
          data-qa="presentation-assign-triggers"
        >
          Set Triggers
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};
