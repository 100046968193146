import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AssetLibraryState {
  count: number;
  records: { [key: string]: any };
  temporaryRecords: { [key: string]: any };
  recordsValidityVersion: number;
  editingAsset: number | string | null;
  loading: boolean;
  rejected: boolean;
  assetIndex: number | string | null;
  totalAssets: number | null;
  assets: { [key: string]: any };
  previewWallUuid: string | null;
}

const initialState: AssetLibraryState = {
  // arbitrary big enough random number ensures that the lazy load loads initial data after
  // the library state is cleared or is in initial and also that users' scroll position
  // is kept during data reloading
  count: 5000,
  records: {},
  // temporary records to show during assets reload (so we can show at least some content
  // instead of just all "Loading" placeholders during library update after a single asset
  // was added to or removed from the library)
  temporaryRecords: {},
  // whenever data are invalidated, version gets incremented (to force lazy load to refresh records)
  recordsValidityVersion: 1,
  editingAsset: null,
  loading: false,
  rejected: false,
  assetIndex: null,
  totalAssets: null,
  assets: [],
  previewWallUuid: null
};

const { actions, reducer } = createSlice({
  name: "assetLibrary",
  initialState,
  reducers: {
    assetsFetched: (
      state,
      action: PayloadAction<{
        assets: any;
        count: number;
        startIndex: number;
      }>
    ) => {
      const recordsCpy = { ...state.records };

      for (
        let i = action.payload.startIndex;
        i < action.payload.assets.length + action.payload.startIndex;
        i++
      ) {
        recordsCpy[i] = {
          asset: action.payload.assets[i - action.payload.startIndex],
          status: "LOADED"
        };
      }

      return {
        ...state,
        records: recordsCpy,
        count: action.payload.count
      };
    },
    assetsFetchingStarted: (
      state,
      action: PayloadAction<{
        startIndex: number;
        stopIndex: number;
      }>
    ) => {
      const recordsCpy = { ...state.records };

      for (
        let i = action.payload.startIndex;
        i <= action.payload.stopIndex;
        i++
      ) {
        if (recordsCpy[i]) {
          recordsCpy[i] = {
            ...recordsCpy[i],
            status: "LOADING"
          };
        } else {
          recordsCpy[i] = { status: "LOADING", asset: null };
        }
      }

      return {
        ...state,
        records: recordsCpy
      };
    },
    removeAsset: (
      state,
      action: PayloadAction<{
        assetUuid: string;
      }>
    ) => {
      // state.records is object not array!
      const removeKey = Object.keys(state.records).find((recordKey) => {
        return action.payload.assetUuid === state.records[recordKey].asset;
      });

      if (!removeKey) {
        return state;
      }

      const index = Number(removeKey);

      const records = Object.keys(state.records).reduce<{ [key: number]: any }>(
        (memo, recordKey) => {
          const recordIndex = Number(recordKey);

          if (recordIndex < index) {
            memo[recordIndex] = state.records[recordIndex];
          } else if (recordIndex > index) {
            memo[recordIndex - 1] = state.records[recordIndex];
          } else {
            // recordIndex === index -> noop
          }
          return memo;
        },
        {}
      );

      return {
        ...state,
        records,
        count: state.count - 1
      };
    },
    insertAsset: (
      state,
      action: PayloadAction<{
        index: number;
        asset: any;
      }>
    ) => {
      // state.records is object not array!
      const records = Object.keys(state.records).reduce<{ [key: number]: any }>(
        (memo, recordKey) => {
          const recordIndex = Number(recordKey);
          if (recordIndex < action.payload.index) {
            memo[recordIndex] = state.records[recordIndex];
          } else if (recordIndex >= action.payload.index) {
            memo[recordIndex + 1] = state.records[recordIndex];
          }
          return memo;
        },
        {}
      );

      records[action.payload.index] = {
        asset: action.payload.asset.asset.uuid,
        status: action.payload.asset.status
      };

      return {
        ...state,
        records,
        count: state.count + 1
      };
    },
    clearAssets: (state) => ({
      ...state,
      // we do not reset the asset count, if it is already set to make sure scroll bar
      // doesn't jump during assets refresh
      count: state.count > 0 ? state.count : initialState.count,
      records: initialState.records,
      temporaryRecords: initialState.temporaryRecords,
      recordsValidityVersion: state.recordsValidityVersion + 1
    }),
    reloadAssets: (state) => ({
      ...state,
      // we do not reset the asset count, if it is already set to make sure scroll bar
      // doesn't jump during assets refresh
      count: state.count > 0 ? state.count : 1,
      records: initialState.records,
      temporaryRecords: state.records, // keep current loaded assets as placeholder during loading from BE
      recordsValidityVersion: state.recordsValidityVersion + 1
    }),
    filesDropped: (
      state,
      // eslint-disable-next-line
      action: PayloadAction<{ acceptedFiles: File[]; wallAssets: boolean }>
    ) => ({
      ...state,
      progress: 0,
      rejected: false
    }),
    filesRejected: (state) => ({
      ...state,
      rejected: true
    }),
    openAssetEdit: (
      state,
      action: PayloadAction<{
        assetUuid: string;
        assetIndex: number;
        totalAssets: number;
      }>
    ) => ({
      ...state,
      editingAsset: action.payload.assetUuid,
      assetIndex: action.payload.assetIndex,
      totalAssets: action.payload.totalAssets
    }),
    setLoading: (state) => ({
      ...state,
      loading: true
    }),
    resetLoading: (state) => ({
      ...state,
      loading: false
    }),
    closeEditingAsset: (state) => ({
      ...state,
      editingAsset: null,
      assetIndex: null,
      totalAssets: null
    }),
    fetchData: (
      state,
      // eslint-disable-next-line
      action: PayloadAction<{
        startIndex: number;
        stopIndex: number;
        done: () => void;
      }>
    ) => state,
    queryChanged: (state) => state,
    // eslint-disable-next-line
    addUrlAsset: (state, action: PayloadAction<{ data: any }>) => state,
    deleteAsset: (state) => state,
    previewWall: (state) => state,
    createWallAlternativeAssets: (
      state,
      // eslint-disable-next-line
      action: PayloadAction<{ parentAsset: any; files: File[] }>
    ) => state,
    // eslint-disable-next-line
    saveAsset: (state, action: PayloadAction<{ data: any }>) => state,
    // eslint-disable-next-line
    discardAsset: (state, action: PayloadAction<{ uuid: string }>) => state
  }
});

export const AssetLibraryActions = actions;
export const assetLibraryReducer = reducer;
