import cx from "classnames";
import React from "react";
import { connect } from "react-redux";
import { actions as routerActions } from "redux-router5";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";

import { withAcl } from "../acl/with-acl";
import AuthActions from "../auth/auth-actions";
import * as AuthSelectors from "../auth/auth-selectors";
import Navigation from "../root/components/navigation";
import {
  AUTH_USER_PROFILE_ROUTE,
  DASHBOARD_ROUTE
} from "../routing/route-names";
import { Avatar } from "../settings/users/components/avatar";

import { ReactComponent as IconLogout } from "client/images/logout.svg";
import { ReactComponent as IconUser } from "client/images/user.svg";

import { MENU_LOCATORS } from "shared/tests/locators/menu.locators";

class SideBar extends React.Component {
  state = {
    collapsed: false,
    menuOpened: false
  };

  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  hideMenu = () => {
    this.setState({ menuOpened: false });
  };

  onMenuChange = (visible) => {
    this.setState({ menuOpened: visible });
  };

  render() {
    const {
      goToDashboard,
      goToEditProfile,
      logout,
      userAvatar,
      user
    } = this.props;
    const { collapsed } = this.state;

    return (
      <div className={cx("sidebar", { collapsed })} data-qa="menu-sidebar">
        <div className="logo">
          <button type="button" className="cmsLogo" onClick={goToDashboard} />
        </div>
        <Navigation />
        <i
          className="collapseButton"
          onClick={this.toggleCollapsed}
          data-qa="icon-collapseMenu"
        />
        <Tooltip
          placement="right"
          trigger={["click"]}
          visible={this.state.menuOpened}
          onVisibleChange={this.onMenuChange}
          overlayClassName="userMenu"
          overlay={
            <div onClick={this.hideMenu}>
              <button
                type="button"
                onClick={goToEditProfile}
                data-qa={MENU_LOCATORS.buttons.editUser}
              >
                <IconUser />
                Your Profile
              </button>
              <button
                type="button"
                onClick={logout}
                data-qa={MENU_LOCATORS.buttons.logout}
              >
                <IconLogout />
                Log Out
              </button>
            </div>
          }
        >
          <button type="button" className="userInfo">
            <div className="userInfoAvatar">
              <Avatar
                avatar={userAvatar}
                firstName={user.firstName}
                lastName={user.lastName}
              />
            </div>
            <span className="userName" data-qa={MENU_LOCATORS.buttons.userMenu}>
              {user.firstName} {user.lastName}
            </span>
          </button>
        </Tooltip>
      </div>
    );
  }
}

SideBar.propTypes = {
  goToDashboard: PropTypes.func.isRequired,
  goToEditProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userAvatar: PropTypes.string
};

function mapStateToProps(state) {
  return {
    user: AuthSelectors.getUser(state),
    userAvatar: AuthSelectors.getUserAvatar(state)
  };
}

const mapDispatchToProps = {
  goToDashboard: () => routerActions.navigateTo(DASHBOARD_ROUTE),
  goToEditProfile: () => routerActions.navigateTo(AUTH_USER_PROFILE_ROUTE),
  logout: AuthActions.Creators.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(withAcl(SideBar));
