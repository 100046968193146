import React, { useCallback, useState, useRef, useEffect } from "react";
import isEqual from "lodash/isEqual";
import cx from "classnames";

import Actions from "../../media-actions";
import { Modal } from "../../../ux/modal";
import {
  UploadMediaGrid,
  AssetUploads,
  AssetClasses
} from "../media-dialog/upload-media-grid";
import { useDispatch, useSelector } from "react-redux";
import { MediaDialogType } from "../media-dialog/media-dialog-type";
import { getAllCustomFieldHierarchies } from "../../../settings/custom-fields/custom-fields-selectors";
import { AssetFull } from "../../../../../shared/types/asset";
import { UploadMediaCustomFieldsPanelForm } from "./upload-media-custom-fields-panel-form";
import { LegacyConfirmationModal } from "client/modules/ux/legacy-confirmation-modal";
import * as Api from "../../../api/client";

import { getUploadsInDialog } from "../../media-selectors";
import {
  getCustomFieldHierarchies,
  getAssets
} from "client/modules/entity-repository/entity-repository-selectors";
import { reduxForm } from "redux-form";
import { TextField } from "client/modules/forms/text-field";
import { ReactComponent as ExclamationIcon } from "../../../../images/exclamation.svg";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";

interface SelectedCategoriesMap {
  [key: string]: string[];
}

interface SelectedFilters {
  [key: string]: { [key: string]: any };
}

const EMPTY_CATEGORIES: string[] = [];
const EMPTY_FILTERS: { [key: string]: any } = {};
const noConfirmationForCategoryChange = () =>
  new Promise<boolean>((res) => res(true));

export const isConflictInSelection = (
  selectedAssets: string[],
  selectedCategories: SelectedCategoriesMap,
  selectedFilters: SelectedFilters
) => {
  if (selectedAssets.length) {
    const firstSelectedAsset = selectedAssets[0];
    const categoriesForSelectedAsset = selectedCategories[firstSelectedAsset];
    const filtersForSelectedAsset = selectedFilters[firstSelectedAsset];

    return !selectedAssets.every(
      (asset) =>
        isEqual(selectedCategories[asset], categoriesForSelectedAsset) &&
        isEqual(selectedFilters[asset], filtersForSelectedAsset)
    );
  } else {
    return false;
  }
};

const useWatchConflict = (conflict: boolean) => {
  const ref = useRef(conflict);

  useEffect(() => {
    ref.current = conflict;
  }, [ref, conflict]);

  return { last: ref.current, next: conflict };
};

type WatchConflict = ReturnType<typeof useWatchConflict>;

const hasConflictChanged = (watchConflict: WatchConflict) =>
  !watchConflict.last && watchConflict.next;

const ConflictWarning = () => (
  <div className="conflictWarning">
    <ExclamationIcon />
    <p>
      The current selected media has different categories and values assigned.
      Any changes you make will overwrite these values.
    </p>
  </div>
);

// Gets validation status for all the assets in media grid
const useGetAssetClasses = (
  categories: SelectedCategoriesMap,
  filters: SelectedFilters
) => {
  const customFieldHierarchies: { [key: string]: any } = useSelector(
    getCustomFieldHierarchies
  );
  const assetUploads: AssetUploads[] = useSelector(getUploadsInDialog);

  return assetUploads
    .map(({ asset }) => {
      let className = "invalid";

      // Get selected categories & filters for particular asset
      const selectedCategories = categories[asset.uuid];
      const selectedFilters = filters[asset.uuid];

      // There needs to be at least one category attached otherwise it's considered
      // invalid
      if (selectedCategories && selectedCategories.length) {
        // Get all hierarchies for selected categories
        const selectedCategoryHierarchies = Object.keys(
          customFieldHierarchies
        ).filter((customFieldHierarchyId) => {
          return selectedCategories.includes(
            `${customFieldHierarchies[customFieldHierarchyId].path}${customFieldHierarchies[customFieldHierarchyId].id}`
          );
        });

        const selectedFilterHiearchies = Object.keys(
          customFieldHierarchies
        ).filter((key) => {
          return selectedCategoryHierarchies
            .map(parseInt)
            .includes(customFieldHierarchies[key].parentId);
        });

        // For every required filter, there must be a value in form
        const valid = selectedFilterHiearchies
          // Find just the required filters
          .filter(
            (key) =>
              customFieldHierarchies[key].customFieldToEntityMapping.required
          )
          // Map the to customFieldToEntityMappingId because that's how they are stored
          // in the form (so that same value is selected within the hiearchy if the field is there multiple times)
          .map(
            (key) => customFieldHierarchies[key].customFieldToEntityMapping.id
          )
          // And check the presence
          .every((key) =>
            Boolean(selectedFilters && selectedFilters[`custom-field-${key}`])
          );

        className = valid ? "valid" : "invalid";
      }

      return {
        uuid: asset.uuid,
        className
      };
    })
    .reduce((memo, { uuid, className }) => {
      memo[uuid] = className;
      return memo;
    }, {} as AssetClasses);
};

interface AssetTitleFormProps {
  selectedAssets: string[];
}
interface AssetTitleFormData {
  name: string;
}

const AssetTitleForm = reduxForm<AssetTitleFormData, AssetTitleFormProps>({
  form: "asset-title",
  enableReinitialize: true
})(({ selectedAssets, handleSubmit }) => {
  const assets = useSelector(getAssets);
  const asset = selectedAssets.length === 1 && assets[selectedAssets[0]];

  return (
    <form className="assetTitleForm" onSubmit={handleSubmit}>
      <div
        className={cx({
          extension: true,
          hasFormat: asset && asset.formatName
        })}
      >
        {asset && asset.formatName}
      </div>
      {asset && <TextField name="name" />}
      {!asset && <span className="placeholder">select a file</span>}
    </form>
  );
});

export const AddSharedAsset = () => {
  const dispatch = useDispatch();
  const onCloseClick = useCallback(() => {
    dispatch(Actions.Creators.hideDialog());
  }, [dispatch]);

  const [showingConfirmation, setShowingConfirmation] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const assetUploads: AssetUploads[] = useSelector(getUploadsInDialog);
  const [multiselect, setMultiselect] = useState<boolean>(false);
  const [confirmationDialogSetting, setConfirmationDialogSetting] = useState({
    title: "",
    text: "",
    cancelText: "",
    confirmText: ""
  });

  const customFieldHierarchies = useSelector(getAllCustomFieldHierarchies);

  // Turns category id into full path
  const toCategoryPath = useCallback(
    (id: number) => {
      const hierarchy = customFieldHierarchies.find(
        (customFieldHierarchy: { id: number; path: string }) =>
          customFieldHierarchy.id === id
      );
      if (hierarchy) return hierarchy.path + id;
      return "";
    },
    [customFieldHierarchies]
  );

  const fromCategoryPath = useCallback((path: string) => {
    const result = /(.*\/)([0-9]*)/.exec(path);

    if (result) {
      return parseInt(result[2]);
    } else {
      return null;
    }
  }, []);

  const multiSelectAsset = useCallback(
    (asset: AssetFull) => {
      setMultiselect(true);

      setSelectedAssets((assets) => {
        if (assets.includes(asset.uuid)) {
          const filteredAssets = assets.filter((uuid) => uuid !== asset.uuid);
          if (filteredAssets.length === 0) {
            setMultiselect(false);
          }

          return filteredAssets;
        } else {
          return [...assets, asset.uuid];
        }
      });
    },
    [setSelectedAssets, setMultiselect]
  );

  const selectAsset = useCallback(
    (asset: AssetFull) => {
      if (multiselect) {
        multiSelectAsset(asset);
      } else {
        setSelectedAssets([asset.uuid]);
      }
    },
    [setSelectedAssets, multiselect, multiSelectAsset]
  );

  const [
    selectedCategories,
    setSelectedCategories
  ] = useState<SelectedCategoriesMap>({});
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});

  const [assetTitles, setAssetTitles] = useState<{ [key: string]: string }>({});

  const conflict = isConflictInSelection(
    selectedAssets,
    selectedCategories,
    selectedFilters
  );

  const conflictWatch = useWatchConflict(conflict);

  const onChangeSelectedCategories = useCallback(
    (newSelectedCategories: number[]) => {
      const paths = newSelectedCategories.map(toCategoryPath);

      if (conflict) {
        // reset selection
        setSelectedFilters((selectedFilters) => {
          const selectedFiltersCopy = { ...selectedFilters };
          selectedAssets.forEach((uuid) => {
            delete selectedFiltersCopy[uuid];
          });

          return selectedFiltersCopy;
        });
      }

      setSelectedCategories((selectedCategories) => {
        return selectedAssets.reduce((memo, uuid) => {
          return {
            ...memo,
            [uuid]: paths
          };
        }, selectedCategories);
      });
    },
    [
      setSelectedCategories,
      setSelectedFilters,
      toCategoryPath,
      selectedAssets,
      conflict
    ]
  );

  const selectAll = useCallback(
    (uuids: string[]) => {
      setMultiselect(true);
      setSelectedAssets(uuids);
    },
    [setMultiselect, setSelectedAssets]
  );

  const deselectAll = useCallback(() => {
    setMultiselect(false);
    setSelectedAssets([]);
  }, [setMultiselect, setSelectedAssets]);

  const onChangeSelectedFilters = useCallback(
    (data: any) => {
      if (!hasConflictChanged(conflictWatch)) {
        setSelectedFilters((selectedFilters) => {
          return selectedAssets.reduce((memo, uuid) => {
            return {
              ...memo,
              [uuid]: data
            };
          }, selectedFilters);
        });
      }
    },
    [setSelectedFilters, selectedAssets, conflictWatch]
  );

  // Turn map of selected categories per assets
  // into flat list of selected categories
  const flatSelectedCategories =
    (selectedAssets.length && selectedCategories[selectedAssets[0]]) ||
    EMPTY_CATEGORIES;

  const flatSelectedFilters =
    (selectedAssets.length &&
      selectedFilters[selectedAssets[0]] &&
      selectedFilters[selectedAssets[0]]) ||
    EMPTY_FILTERS;

  const onDelete = useCallback(async () => {
    await Api.bulkDeleteSharedMediaAssets(selectedAssets);

    selectedAssets.forEach((assetUuid) => {
      delete selectedCategories[assetUuid];
      delete selectedFilters[assetUuid];
      dispatch(Actions.Creators.removeAsset(assetUuid));
    });

    setSelectedAssets([]);
  }, [dispatch, selectedAssets, selectedCategories, selectedFilters]);

  const confirmationDialogConfirmationCallback = useRef<
    (value: boolean) => void
  >();
  const conflictConfirmationDialog = async () => {
    setConfirmationDialogSetting({
      title: "Multiple Categories & Filters",
      text:
        "The selected files have mismatched categories and filters. Changes affect all files within your selection.",
      confirmText: "Continue anyway",
      cancelText: "Cancel"
    });
    setShowingConfirmation(true);

    return new Promise<boolean>((res) => {
      confirmationDialogConfirmationCallback.current = (confirmed: boolean) => {
        setShowingConfirmation(false);
        res(confirmed);
      };
    });
  };

  const assetClasses = useGetAssetClasses(selectedCategories, selectedFilters);
  const [uploadButton, setUploadButton] = useState<React.ReactNode | null>(
    null
  );

  const empty = assetUploads.length === 0;

  const assets = useSelector(getAssets);
  const selectedAsset = selectedAssets[0];

  const onChangeAssetTitle = useCallback(
    (data: AssetTitleFormData) => {
      if (data.name && data.name.trim() !== "" && selectedAsset) {
        setAssetTitles((assetTitles) => ({
          ...assetTitles,
          [selectedAsset]: data.name
        }));
      }
    },
    [selectedAsset, setAssetTitles]
  );

  const onUpdate = useCallback(async () => {
    const isValid = Object.keys(assetClasses).every(
      (uuid) => assetClasses[uuid] === "valid"
    );

    const doUpdate = async () => {
      const selectedCustomFieldHierarchyIds = Object.keys(
        selectedCategories
      ).reduce((memo, assetUuid) => {
        const categoryPaths = selectedCategories[assetUuid];
        memo[assetUuid] = categoryPaths
          .map(fromCategoryPath)
          .filter(Boolean) as number[];
        return memo;
      }, {} as { [key: string]: number[] });

      await Api.bulkUpdateSharedMediaAssets(
        selectedCustomFieldHierarchyIds,
        selectedFilters,
        assetTitles
      );

      dispatch(Actions.Creators.hideDialog());
    };

    if (isValid) {
      await doUpdate();
    } else {
      setConfirmationDialogSetting({
        title: "Unsassigned Media",
        text:
          "Files that are marked orange has no assigned categories or filters. By continuing, unassigned media will be stored in Unassigned Categories.",
        confirmText: "Continue anyway",
        cancelText: "Cancel"
      });
      confirmationDialogConfirmationCallback.current = async (
        confirmed: boolean
      ) => {
        setShowingConfirmation(false);

        if (confirmed) {
          await doUpdate();
        }
      };
      setShowingConfirmation(true);
    }
  }, [
    selectedCategories,
    selectedFilters,
    assetTitles,
    assetClasses,
    fromCategoryPath,
    dispatch
  ]);

  // we should not close dialog on Escape if some asset is added
  useEscapeHook(empty, onCloseClick);

  return (
    <Modal
      title="Add Media"
      hideCloseButton={!empty}
      className={cx({
        assetsUploadModal: true,
        addSharedMedia: true,
        sharedMediaModal: true
      })}
      onCloseClick={onCloseClick}
    >
      <div className={cx({ content: true, empty })}>
        <div className="left">
          {!empty && (
            <AssetTitleForm
              selectedAssets={selectedAssets}
              initialValues={{
                name:
                  selectedAssets.length === 1
                    ? assetTitles[selectedAsset] || assets[selectedAsset].title
                    : ""
              }}
              onChange={onChangeAssetTitle}
            />
          )}
          {conflict && <ConflictWarning />}
          <UploadMediaGrid
            cols={4}
            dialogType={MediaDialogType.SHARED_ASSET}
            onSelectAsset={selectAsset}
            onUploadButton={setUploadButton}
            selectedAssets={selectedAssets}
            onMultiSelectAsset={multiSelectAsset}
            assetClasses={assetClasses}
            multiselectMode={multiselect}
            multiselectEnabled={true}
            onSelectAll={selectAll}
            onDeselectAll={deselectAll}
          />
        </div>
        {!empty && (
          <UploadMediaCustomFieldsPanelForm
            selectedAssets={selectedAssets}
            selectedCategories={
              conflict ? EMPTY_CATEGORIES : flatSelectedCategories
            }
            initialValues={conflict ? EMPTY_FILTERS : flatSelectedFilters}
            onChangeSelectedCategories={onChangeSelectedCategories}
            onRequestChangeSelectedCategories={
              conflict
                ? conflictConfirmationDialog
                : noConfirmationForCategoryChange
            }
            onChange={onChangeSelectedFilters}
          />
        )}
      </div>
      <div className="buttonBar">
        <div className="left">
          {uploadButton}
          {selectedAssets.length > 0 && (
            <>
              <button
                onClick={onDelete}
                className="button buttonOutlined buttonAlert"
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="right">
          {!empty && (
            <button className="button" onClick={onUpdate}>
              Save
            </button>
          )}
        </div>
      </div>
      {showingConfirmation &&
        confirmationDialogConfirmationCallback.current && (
          <LegacyConfirmationModal
            title={confirmationDialogSetting.title}
            text={confirmationDialogSetting.text}
            cancelText={confirmationDialogSetting.cancelText}
            okText={confirmationDialogSetting.confirmText}
            onResolve={confirmationDialogConfirmationCallback.current}
          />
        )}
    </Modal>
  );
};
