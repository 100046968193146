import moment from "moment";
import { createSelector } from "reselect";
import validate from "./presentation-validate";
import {
  getPresentation as getState,
  getPredefinedSceneNames
} from "../root/root-selectors";
import { getBranding, getCdnHost } from "../config/config-selectors";
import {
  getPresentations,
  getScenes,
  getPresentationScenes,
  getUsers
} from "../entity-repository/entity-repository-selectors";
import { getRouteParams } from "../routing/routing-selectors";
import { getSceneInitialValuesImpl } from "../scenes/scene-selectors";
import { customFieldsToEntity } from "../settings/custom-fields/components/filters/filters-block";
import { getUser } from "../auth/auth-selectors";

export const getPresentationId = createSelector(
  getRouteParams,
  (routeParams) => routeParams.presentationId
);

export const getPresentation = createSelector(
  getPresentationId,
  getPresentations,
  (presentationId, presentations) => presentations[presentationId]
);

export const getPresentationTitle = createSelector(
  getPresentation,
  (presentation) => {
    if (presentation) {
      return presentation.title;
    } else {
      return "";
    }
  }
);

export const getPresentationInitialValues = createSelector(
  getPresentation,
  (presentation) => {
    if (presentation) {
      return {
        ...presentation,
        ...customFieldsToEntity(presentation.customFields),
        blackbeltFrom: presentation.blackbeltFrom
          ? moment(presentation.blackbeltFrom)
          : null,
        blackbeltTill: presentation.blackbeltFrom
          ? moment(presentation.blackbeltTill)
          : null
      };
    } else {
      return null;
    }
  }
);

export const getPresentationCollaborators = createSelector(
  getPresentationInitialValues,
  getCdnHost,
  (presentation, cdnHost) =>
    presentation &&
    presentation.collaborators.map((collaborator) => {
      if (collaborator.avatar) {
        return {
          ...collaborator,
          avatar: `${cdnHost}/avatars/${collaborator.avatar}`
        };
      } else {
        return collaborator;
      }
    })
);

export const getPresentationCreator = createSelector(
  getPresentation,
  (presentation) => presentation && presentation.creatorId
);

export const getPresentationValidation = createSelector(
  getBranding,
  (branding) => validate(branding.remoteAccessName, branding.roomName)
);

export const getPresentationsScenesById = createSelector(
  getPresentation,
  (presentation) =>
    ((presentation && presentation.presentationScenes) || []).reduce(
      (acc, { scene }) => {
        acc[scene.id] = scene;
        return acc;
      },
      {}
    )
);

export const getPresentationSceneConnectionsByOutboundSceneId = createSelector(
  getPresentation,
  getPresentationsScenesById,
  (presentation, scenesById) => {
    const scenesConnectionsByOutboundSceneId = (
      (presentation && presentation.sceneConnections) ||
      []
    ).reduce((acc, { outboundSceneId, inboundSceneId, id, order }) => {
      const connections = acc[outboundSceneId] || [];
      connections.push({
        title: scenesById[inboundSceneId].title,
        id,
        order,
        outboundSceneId,
        inboundSceneId
      });
      acc[outboundSceneId] = connections;
      return acc;
    }, {});

    Object.keys(scenesConnectionsByOutboundSceneId).forEach((key) =>
      scenesConnectionsByOutboundSceneId[key].sort((a, b) => a.order - b.order)
    );

    return scenesConnectionsByOutboundSceneId;
  }
);

export const getPresentationSceneConnectionsSuggestions = createSelector(
  getPresentation,
  (presentation) => {
    const childrenSceneIds = new Set(
      ((presentation && presentation.sceneConnections) || []).map(
        ({ inboundSceneId }) => inboundSceneId
      )
    );

    return ((presentation && presentation.presentationScenes) || [])
      .map(({ scene }) => scene)
      .filter(({ id }) => !childrenSceneIds.has(id));
  }
);

export const getPresentationListVersion = createSelector(
  getState,
  (state) => state.listVersion
);

export const getEditingScene = createSelector(
  getRouteParams,
  getScenes,
  (routeParams, scenes) => scenes[routeParams.sceneId]
);

export const getEditingPresentationScene = createSelector(
  getRouteParams,
  getPresentationScenes,
  (routeParams, presentationScenes) =>
    presentationScenes[`${routeParams.sceneId}-${routeParams.presentationId}`]
);

export const getEditingSceneInitialValues = createSelector(
  getEditingPresentationScene,
  getPredefinedSceneNames,
  getPresentation,
  (editingPresentationScene, predefinedSceneNames, presentation) => {
    let scene = {};
    if (editingPresentationScene) {
      scene = editingPresentationScene.scene;
      scene.autoswitch = editingPresentationScene.autoswitch;
      scene.isDefault = editingPresentationScene.isDefault;
    }

    if (presentation) {
      const presentationSceneTrigger = presentation.presentationSceneTriggers.find(
        ({ sceneId }) => sceneId === scene.id
      );

      if (presentationSceneTrigger) {
        scene.trigger = presentationSceneTrigger.trigger;
      }
    }

    if (scene) {
      return getSceneInitialValuesImpl(scene, predefinedSceneNames);
    } else {
      return scene;
    }
  }
);

export const getCreateSceneInitialValues = createSelector(
  getPresentation,
  getUsers,
  getUser,
  (presentation, users, loggedInUser) => {
    if (presentation) {
      const creator = users[presentation.creatorId];
      const initialValules = {
        collaborators: [...presentation.collaborators]
      };

      if (
        creator &&
        !initialValules.collaborators.some(
          (collaborator) => collaborator.id === creator.id
        ) &&
        loggedInUser.id !== creator.id
      ) {
        initialValules.collaborators.push(creator);
      }

      if (presentation.instances.length === 1) {
        initialValules.instanceUuid = presentation.instances[0];
      }

      return initialValules;
    } else {
      return {};
    }
  }
);
