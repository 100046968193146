import React, { useState } from "react";

import CreatePredefinedSceneNameForm from "client/modules/settings/predefined-scene-names/create-predefined-scene-name-form";
import PredefinedSceneNamesList from "client/modules/settings/predefined-scene-names/predefined-scene-names-list";
import { withAcl } from "../../acl/with-acl";
import { Modal } from "../../ux/modal";

import { AclResource } from "../../../../shared/acl";
import { ReactComponent as IconAdd } from "../../../images/add.svg";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const PredefinedSceneNamesView = () => {
  const [modalIsShown, showModal] = useState(false);

  return (
    <div className="subpage">
      <header className="pageHeader">
        <h1 className="pageHeading" data-qa="tab-header">
          Scene Names
        </h1>
        <div>
          <button
            type="button"
            className="button"
            onClick={() => showModal(true)}
          >
            <IconAdd />
            New Scene Name
          </button>
        </div>
      </header>

      <div className="pageContent">
        <PredefinedSceneNamesList />
      </div>

      {modalIsShown && (
        <Modal
          title={`New Scene Name`}
          onCloseClick={() => showModal(false)}
          data-qa={MODAL_LOCATORS.getModal("new-scene-name")}
        >
          <CreatePredefinedSceneNameForm
            beforeSubmit={() => showModal(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default withAcl(
  PredefinedSceneNamesView,
  AclResource.PREDEFINED_SCENES_WRITE
);
