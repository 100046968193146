import { reduxForm } from "redux-form";
import React from "react";

import { AsyncSelectField } from "client/modules/forms/async-select-field";
import { NumberField } from "client/modules/forms/number-field";
import { SwitchField } from "client/modules/forms/switch-field";
import {
  FONTS,
  usePreloadFonts
} from "client/modules/scene-editor/hooks/use-preload-fonts";
import { TextAssetFontSize } from "shared/types/asset";
import { AppSettingType, EditorSettings } from "shared/types/app-setting";

export const EDITOR_SETTINGS_FORM_NAME = "editor_settings_form";

export interface EDITOR_SETTINGS_FORM_DATA
  extends Omit<
    EditorSettings,
    "EDITOR_DEFAULT_TEXT_FONT" | "EDITOR_DEFAULT_TEXT_SIZE"
  > {
  [AppSettingType.EDITOR_DEFAULT_TEXT_FONT]: { label: string; value: string };
  [AppSettingType.EDITOR_DEFAULT_TEXT_SIZE]: { label: string; value: string };
}

const RenderFontOption = ({ value, label }: any) => (
  <span style={{ fontFamily: value }}>{label}</span>
);

const fontFamilyFieldOptions = FONTS.map((font) => ({
  label: font,
  value: font
}));

const fontSizeFieldOptions = Object.entries(TextAssetFontSize).map((entry) => {
  const [key, value] = entry;
  return { label: key, value: value };
});

const validate = () => {
  return {};
};

export const EditorSettingsForm = reduxForm<EDITOR_SETTINGS_FORM_DATA, {}>({
  form: EDITOR_SETTINGS_FORM_NAME,
  validate,
  enableReinitialize: true
})(() => {
  usePreloadFonts();

  return (
    <form className="editor-settings-form">
      <label htmlFor="settings-opacity">
        <h5>Opacity</h5>
        <p>Set the default transparency of media in the scene.</p>
      </label>
      <NumberField
        name={AppSettingType.EDITOR_DEFAULT_OPACITY}
        max={100}
        min={0}
        unit="%"
        id="settings-opacity"
        dataQa="opacity"
      />

      <label htmlFor="settings-visibility">
        <h5>Visible on start</h5>
        <p>
          Force all media added to a scene to be visible on start of the scene.
        </p>
      </label>
      <SwitchField
        name={AppSettingType.EDITOR_DEFAULT_VISIBLE_ON_START}
        id="settings-visibility"
        dataQa="visible-checkbox"
      />

      <label htmlFor="settings-lock">
        <h5>Lock position</h5>
        <p>Lock the position of all media added to a scene by&nbsp;default.</p>
      </label>
      <SwitchField
        name={AppSettingType.EDITOR_DEFAULT_POSITION_LOCKED}
        id="settings-lock"
        dataQa="lock-checkbox"
      />

      <label htmlFor="settings-glow">
        <h5>Outter Glow</h5>
        <p>
          Force an outter glow around all media that is added
          to&nbsp;a&nbsp;scene.
        </p>
      </label>
      <SwitchField
        name={AppSettingType.EDITOR_DEFAULT_GLOW}
        id="settings-glow"
        dataQa="glow-checkbox"
      />

      <label htmlFor="settings-rounded-corners">
        <h5>Radius</h5>
        <p>
          Force rounded corners on all media that is added to&nbsp;a&nbsp;scene.
        </p>
      </label>
      <SwitchField
        name={AppSettingType.EDITOR_DEFAULT_ROUNDED}
        id="settings-rounded-corners"
        dataQa="rounded-checkbox"
      />

      <label htmlFor="settings-loop">
        <h5>Loop video</h5>
        <p>Force videos to be looped when added to a scene.</p>
      </label>
      <SwitchField
        name={AppSettingType.EDITOR_DEFAULT_LOOP}
        id="settings-loop"
        dataQa="loop-checkbox"
      />

      <label htmlFor="settings-autoplay">
        <h5>Autoplay</h5>
        <p>Force videos to autoplay when added to a scene.</p>
      </label>
      <SwitchField
        name={AppSettingType.EDITOR_DEFAULT_AUTOPLAY}
        id="settings-autoplay"
        dataQa="autoplay-checkbox"
      />

      <label htmlFor="settings-text-size">
        <h5>Text size</h5>
        <p>
          Set a default text size, new text will be added
          in&nbsp;this&nbsp;specific size.
        </p>
      </label>
      <AsyncSelectField
        wrapperClass="fontCombo"
        name={AppSettingType.EDITOR_DEFAULT_TEXT_SIZE}
        async={false}
        options={fontSizeFieldOptions}
        dataQa="text-font-size"
      />

      <label htmlFor="settings-text-font">
        <h5>Text font styles</h5>
        <p>
          Set a standard font style, new text will be added
          in&nbsp;this&nbsp;specific style.
        </p>
      </label>
      <AsyncSelectField
        name={AppSettingType.EDITOR_DEFAULT_TEXT_FONT}
        async={false}
        options={fontFamilyFieldOptions}
        formatOptionLabel={RenderFontOption}
        className="is-static"
        dataQa="text-font-family"
      />
    </form>
  );
});
