import React from "react";
import { InjectedFormProps } from "redux-form";
import { useSelector } from "react-redux";

import { SelectRoomField } from "client/modules/forms/select-room-field";
import { TextField } from "client/modules/forms/text-field";

import { getBranding } from "client/modules/config/config-selectors";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

export interface TriggersFormData {
  id?: number;
  name?: string;
  instanceUuid?: string;
  triggerId?: string;
}

export interface TriggersFormCustomProps {
  saving: boolean;
  onSave: (data: TriggersFormData) => void;
}

interface TriggersFormProps
  extends InjectedFormProps<TriggersFormData, TriggersFormCustomProps>,
    TriggersFormCustomProps {}

export const TriggersForm = ({
  handleSubmit,
  saving,
  onSave
}: TriggersFormProps) => {
  const { roomName } = useSelector(getBranding);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <TextField
        name="name"
        data-qa="trigger-form-name-field"
        placeholder="Trigger Name"
        required
        dataQa={FORM_LOCATORS.inputs.getInput("trigger-name")}
      />
      <SelectRoomField
        dataQa={FORM_LOCATORS.inputs.getInput("room")}
        name="instanceUuid"
        required
        isClearable={false}
        isMulti={false}
        label={null}
        placeholder={roomName}
        help=""
        filteredInstances={undefined}
      />
      <TextField
        data-qa="trigger-form-id-field"
        name="triggerId"
        placeholder="Trigger ID"
        dataQa={FORM_LOCATORS.inputs.getInput("trigger-id")}
      />
      <div className="modalFooter">
        <button
          className="button"
          disabled={saving}
          data-qa={FORM_LOCATORS.buttons.getButton("save")}
        >
          Save
        </button>
      </div>
    </form>
  );
};
