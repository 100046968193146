import React from "react";
import PropTypes from "prop-types";

import { AsyncSelectField } from "../../../forms/async-select-field";
import * as api from "../../../api/client";
import { useSelector } from "react-redux";
import { getCdnHost } from "../../../config/config-selectors";

const API_LIMIT = 10;
const PAGE = 1;

// eslint-disable-next-line react/prop-types
const RenderOption = ({ firstName, lastName, email }) => (
  <div>
    {firstName} {lastName} <br /> {email}
  </div>
);

const RenderSelected = ({ data: { firstName, lastName, email } }) =>
  `${firstName} ${lastName}, ${email}`;

const getUserId = (user) => user.id;

export const SelectUserField = ({
  resourceAccess,
  name,
  label,
  isMulti,
  ...rest
}) => {
  const cdnHost = useSelector(getCdnHost);
  const { dataQa } = rest;

  return (
    <AsyncSelectField
      name={name}
      label={label}
      isMulti={isMulti}
      placeholder={"Type to search users"}
      isClearable
      defaultOptions
      components={{
        SingleValue: RenderSelected
      }}
      formatOptionLabel={RenderOption}
      getOptionValue={getUserId}
      dataQa={dataQa}
      loadOptions={async (query) => {
        const {
          data: { rows }
        } = await api.searchUsers(query, resourceAccess, PAGE, API_LIMIT);

        return (
          rows &&
          rows.map((row) => {
            if (row.avatar) {
              return {
                ...row,
                avatar: `${cdnHost}/avatars/${row.avatar}`
              };
            } else {
              return row;
            }
          })
        );
      }}
      {...rest}
    />
  );
};

SelectUserField.propTypes = {
  resourceAccess: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isMulti: PropTypes.bool.isRequired,
  dataQa: PropTypes.string
};
