import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useStore } from "react-redux";

import {
  getIsScaling,
  getScaleChange,
  getSelectionBoundingBox
} from "../../scene-editor-selectors";
import { useDraggingActions } from "../asset-config/use-dragging-actions";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { useGetMouseMovement } from "./utils/use-mouse-movement";

export enum ScalingTransformOrigin {
  LEFT_TOP = "left top",
  RIGHT_TOP = "right top",
  LEFT_BOTTOM = "left bottom",
  RIGHT_BOTTOM = "right bottom"
}
export const getScaledCoords = (
  origin: { left: number; top: number },
  left: number,
  top: number,
  width: number,
  height: number,
  scale: number
) => {
  return {
    left: Math.round(left + (1 - scale) * (origin.left - left)),
    top: Math.round(top + (1 - scale) * (origin.top - top)),
    width: Math.round(width * scale),
    height: Math.round(height * scale)
  };
};

const getBoundingBoxOrigin = (
  origin: ScalingTransformOrigin,
  boundingBox:
    | { left: number; top: number; right: number; bottom: number }
    | undefined
) => {
  if (!boundingBox) {
    return { left: 0, top: 0 };
  }
  const { left, top, right, bottom } = boundingBox;
  const width = right - left;
  const height = bottom - top;
  let center = { left, top };
  switch (origin) {
    case ScalingTransformOrigin.LEFT_TOP:
      break;
    case ScalingTransformOrigin.RIGHT_TOP:
      center = { left: left + width, top };
      break;
    case ScalingTransformOrigin.LEFT_BOTTOM:
      center = { left: left, top: top + height };
      break;
    case ScalingTransformOrigin.RIGHT_BOTTOM:
      center = { left: left + width, top: top + height };
      break;
  }
  return center;
};

export const useScaling = (canvasScale: number) => {
  const scaleChangeRefX = useRef(0);
  const scaleChangeRefY = useRef(0);
  const transformOrigin = useRef<ScalingTransformOrigin>(
    ScalingTransformOrigin.LEFT_BOTTOM
  );

  const dispatch = useDispatch();
  const store = useStore();
  const { updateScale } = useDraggingActions();
  const getMouseMovement = useGetMouseMovement();

  const scalingAnchorMouseDownHandlerFactory = useCallback(
    (origin: ScalingTransformOrigin) => (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const boundingBox = getSelectionBoundingBox(store.getState());

      e.stopPropagation();
      dispatch(SceneEditorActions.setIsScaling(true));
      transformOrigin.current = origin;
      dispatch(
        SceneEditorActions.setScaleOrigin(
          getBoundingBoxOrigin(origin, boundingBox)
        )
      );
      scaleChangeRefX.current = 0;
      scaleChangeRefY.current = 0;
    },
    [scaleChangeRefX, scaleChangeRefY, dispatch, store]
  );

  const scalingMouseDownHandlerLeftTop = useCallback(
    scalingAnchorMouseDownHandlerFactory(ScalingTransformOrigin.RIGHT_BOTTOM),
    [scalingAnchorMouseDownHandlerFactory]
  );

  const scalingMouseDownHandlerRightTop = useCallback(
    scalingAnchorMouseDownHandlerFactory(ScalingTransformOrigin.LEFT_BOTTOM),
    [scalingAnchorMouseDownHandlerFactory]
  );

  const scalingMouseDownHandlerLeftBottom = useCallback(
    scalingAnchorMouseDownHandlerFactory(ScalingTransformOrigin.RIGHT_TOP),
    [scalingAnchorMouseDownHandlerFactory]
  );

  const scalingMouseDownHandlerRightBottom = useCallback(
    scalingAnchorMouseDownHandlerFactory(ScalingTransformOrigin.LEFT_TOP),
    [scalingAnchorMouseDownHandlerFactory]
  );

  const scalingMouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      const isScaling = getIsScaling(store.getState());
      const boundingBox = getSelectionBoundingBox(store.getState());

      if (isScaling && boundingBox) {
        const { movementX, movementY } = getMouseMovement(e);

        scaleChangeRefX.current -= movementX;
        scaleChangeRefY.current -= movementY;

        switch (transformOrigin.current) {
          case ScalingTransformOrigin.LEFT_TOP:
            if (scaleChangeRefX.current > scaleChangeRefY.current) {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 +
                      scaleChangeRefX.current /
                        (boundingBox.right - boundingBox.left) /
                        canvasScale,
                    0
                  )
                )
              );
            } else {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 +
                      scaleChangeRefY.current /
                        (boundingBox.bottom - boundingBox.top) /
                        canvasScale,
                    0
                  )
                )
              );
            }
            break;
          case ScalingTransformOrigin.LEFT_BOTTOM:
            if (scaleChangeRefX.current > scaleChangeRefY.current) {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 +
                      scaleChangeRefX.current /
                        (boundingBox.right - boundingBox.left) /
                        canvasScale,
                    0
                  )
                )
              );
            } else {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 -
                      scaleChangeRefY.current /
                        (boundingBox.bottom - boundingBox.top) /
                        canvasScale,
                    0
                  )
                )
              );
            }
            break;

          case ScalingTransformOrigin.RIGHT_TOP:
            if (scaleChangeRefX.current > scaleChangeRefY.current) {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 -
                      scaleChangeRefX.current /
                        (boundingBox.right - boundingBox.left) /
                        canvasScale,
                    0
                  )
                )
              );
            } else {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 +
                      scaleChangeRefY.current /
                        (boundingBox.bottom - boundingBox.top) /
                        canvasScale,
                    0
                  )
                )
              );
            }
            break;

          case ScalingTransformOrigin.RIGHT_BOTTOM:
            if (scaleChangeRefX.current > scaleChangeRefY.current) {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 -
                      scaleChangeRefX.current /
                        (boundingBox.right - boundingBox.left) /
                        canvasScale,
                    0
                  )
                )
              );
            } else {
              dispatch(
                SceneEditorActions.setScaleChange(
                  Math.max(
                    1 -
                      scaleChangeRefY.current /
                        (boundingBox.bottom - boundingBox.top) /
                        canvasScale,
                    0
                  )
                )
              );
            }
            break;
        }
      }
    },
    [
      store,
      dispatch,
      scaleChangeRefX,
      scaleChangeRefY,
      canvasScale,
      getMouseMovement
    ]
  );

  const scalingMouseUpHandler = useCallback(() => {
    const isScaling = getIsScaling(store.getState());
    const boundingBox = getSelectionBoundingBox(store.getState());
    const scaleChange = getScaleChange(store.getState());

    if (isScaling) {
      dispatch(SceneEditorActions.setIsScaling(false));
      scaleChangeRefX.current = 0;
      scaleChangeRefY.current = 0;
      updateScale({
        scale: scaleChange,
        origin: getBoundingBoxOrigin(transformOrigin.current, boundingBox)
      });
      dispatch(SceneEditorActions.setScaleChange(1));
    }
  }, [updateScale, dispatch, scaleChangeRefX, scaleChangeRefY, store]);

  useEffect(() => {
    document.addEventListener("mouseup", scalingMouseUpHandler);
    document.addEventListener("mousemove", scalingMouseMoveHandler);
    return () => {
      document.removeEventListener("mouseup", scalingMouseUpHandler);
      document.removeEventListener("mousemove", scalingMouseMoveHandler);
    };
  }, [scalingMouseMoveHandler, scalingMouseUpHandler]);

  return {
    scalingMouseDownHandlerLeftTop,
    scalingMouseDownHandlerLeftBottom,
    scalingMouseDownHandlerRightBottom,
    scalingMouseDownHandlerRightTop
  };
};
