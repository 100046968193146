const { AssetType } = require("../../../shared/types/asset-type");

export const {
  ASSET_WALL_IMAGE: WALL_IMAGE,
  ASSET_WALL_VIDEO: WALL_VIDEO
} = AssetType;

export const WALL_TYPES = [WALL_IMAGE, WALL_VIDEO];

export default {
  WALL_IMAGE,
  WALL_VIDEO
};
