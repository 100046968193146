import { call, put, select } from "redux-saga/effects";

import * as Api from "../../api/client";
import { normalizeAndStore } from "../../entity-repository/entity-repository-saga";
import {
  instances as instancesSchema,
  scene as sceneSchema
} from "../../entity-repository/schema";
import { getSceneId } from "../scene-editor-selectors";
import { SceneEditorActions } from "../scene-editor-reducer";

export function* initializeSceneEditorSaga() {
  try {
    yield put(SceneEditorActions.resetSceneEditor());

    const instances = yield call(Api.getInstanceConfigs);
    yield call(normalizeAndStore, instances, instancesSchema);

    const id = yield select(getSceneId);
    const scene = yield call(Api.fetchScene, id);
    yield call(normalizeAndStore, scene, sceneSchema);
    yield put(SceneEditorActions.setInitializingSuccess());
  } catch (ex) {
    if (ex.response) {
      if (ex.response.status === 404) {
        yield put(
          SceneEditorActions.setInitializingError({
            message:
              "The scene you are trying to open doesn't exist or you do not have permission to open it."
          })
        );
        return;
      }
    }
    yield put(
      SceneEditorActions.setInitializingError({ message: "Scene load failed." })
    );
  }
}
