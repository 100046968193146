import { eventChannel } from "redux-saga";
import io from "socket.io-client";

export const createMessageChannel = (socket, message) =>
  eventChannel((emitter) => {
    socket.on(message, emitter);

    return () => {
      socket.off(message, emitter);
    };
  });

export const connect = () => io({ transports: ["websocket"] });
