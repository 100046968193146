import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { getCdnHost } from "../../../config/config-selectors";

const POLLING_INTERVAL = 1000;

export const PollableThumbnail = (props: {
  uuid: string;
  fileVersion: number;
}) => {
  const { uuid, fileVersion } = props;

  const cdnHost = useSelector(getCdnHost);

  const [version, setVersion] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const interval = useRef<NodeJS.Timeout | null>(null);

  const onError = useCallback(() => {
    interval.current = setTimeout(() => {
      setVersion(version + 1);
    }, POLLING_INTERVAL);
  }, [version]);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    interval.current && clearTimeout(interval.current);
  }, []);

  return (
    <img
      className={cx({ loading: !loaded })}
      src={`${cdnHost}/assets/${uuid}_${fileVersion}_thumb.jpg?v_=${version}`}
      alt=""
      onError={onError}
      onLoad={onLoad}
    />
  );
};
