import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Logger as logger } from "purplex-logging";

import {
  AutoSizer,
  ColumnSizer,
  Grid,
  InfiniteLoader
} from "react-virtualized";
import { DraggableAsset } from "./asset-library-draggeble-asset";
import * as Api from "../../../api/client";
import { getCdnHost } from "../../../config/config-selectors";
import Actions from "../../../media/media-actions";
import { AssetReduced } from "../../../../../shared/types/asset";
import { useCustomFieldAssetFilters } from "../../../settings/custom-fields/hooks/use-custom-fields";
import { AssetLibraryActions } from "../asset-library-reducer";
import { SCENE_EDITOR_LOCATORS } from "shared/tests/locators/scene-editor.locators";

const MIN_CARD_WIDTH = 150;
const MAX_CARD_WIDTH = 400;
const CARD_HEIGHT = 210;
const GRID_PADDING = 70;
const COLUMN_COUNT = 5;
const MAX_QUERY_LENGTH = 45;

// placeholder when grid is empty
const EmptyCmp = () => (
  <div
    className="asset-list-empty"
    data-qa={SCENE_EDITOR_LOCATORS.noMediaPlaceholder}
  >
    No Media
  </div>
);

export const AssetLibraryMediaShared = () => {
  const [rowCount, setRowCount] = useState(0);
  const [count, setCount] = useState(5000);

  const [filterVersion, setFilterVersion] = useState(0);
  const [records, setRecords] = useState<any[]>([]);

  const cdnHost = useSelector(getCdnHost);

  const dispatch = useDispatch();

  const onAssetsFetched = useCallback(
    (rows: AssetReduced[]) => {
      dispatch(Actions.Creators.assetsFetched(rows));
    },
    [dispatch]
  );

  const infiniteLoader = useRef<InfiniteLoader | null>(null);

  const {
    filters,
    category,
    categorySelectComponent,
    filtersComponent
  } = useCustomFieldAssetFilters(
    { type: "shared" },
    true,
    undefined,
    "asset-library-shared-filters"
  );

  // HACK: Just to make analytics module work
  useEffect(() => {
    dispatch(AssetLibraryActions.queryChanged());
  }, [filters.fulltext, dispatch]);

  const fetchMoreData = useCallback(
    async ({
      startIndex,
      stopIndex
    }: {
      startIndex: number;
      stopIndex: number;
    }) => {
      const startItemIndex = startIndex * COLUMN_COUNT;
      let stopItemIndex = stopIndex * COLUMN_COUNT - 1;

      stopItemIndex =
        stopItemIndex - startItemIndex > MAX_QUERY_LENGTH - 1
          ? startItemIndex + MAX_QUERY_LENGTH - 1
          : stopItemIndex;

      stopItemIndex =
        stopItemIndex < startItemIndex ? stopItemIndex + 1 : stopItemIndex;

      logger.debug(
        "Fetching more data",
        startItemIndex,
        stopItemIndex,
        filters,
        category
      );

      try {
        const { rows: assets, count } = await Api.fetchAssets(
          filters.fulltext,
          startItemIndex,
          stopItemIndex,
          "shared",
          filters.filters,
          category
        );
        const mappedRecords = assets.map((asset) => ({
          ...asset,
          thumbnail: `${cdnHost}/assets/${asset.fileName}_thumb.jpg`
        }));
        logger.debug("Fetched assets from shared library.");
        setRecords([...records, ...mappedRecords]);
        setCount(count);
        onAssetsFetched(assets);
      } catch (e) {
        logger.error("Error fetching data", e);
      }
    },
    [category, records, filters, cdnHost, onAssetsFetched]
  );

  const isRowLoaded = useCallback(
    ({ index }) => {
      const firstRecordIndex = index * COLUMN_COUNT;
      return records[firstRecordIndex];
    },
    [records]
  );

  const onSectionRendered = useCallback(
    (onRowsRendered) => ({
      rowStartIndex,
      rowStopIndex
    }: {
      rowStartIndex: number;
      rowStopIndex: number;
    }) => {
      onRowsRendered({ startIndex: rowStartIndex, stopIndex: rowStopIndex });
    },
    []
  );

  const cellRenderer = useCallback(
    ({ style, columnIndex, rowIndex, key }) => {
      const index = rowIndex * COLUMN_COUNT + columnIndex;

      if (index >= count) {
        return false;
      }

      const record = records[index];
      return (
        <div style={style} key={key} className="assetWrapper">
          {record && <DraggableAsset asset={record} />}
        </div>
      );
    },
    [records, count]
  );

  useEffect(() => {
    setRecords([]);
    setCount(1000);
    logger.debug("Category or filters changed - refetching ", category);
    setFilterVersion((filterVersion) => filterVersion + 1);
  }, [filters, category]);

  useEffect(() => {
    if (count !== 0) {
      setRowCount(Math.ceil(count / COLUMN_COUNT));
      logger.debug("Row count changed", Math.ceil(count / COLUMN_COUNT));
    }
  }, [count]);

  return (
    <form className="shared-assets">
      {categorySelectComponent}

      <div className="assets-body">
        {filtersComponent}
        <div className="assets-content asset-list-wrapper">
          <InfiniteLoader
            key={filterVersion}
            isRowLoaded={isRowLoaded}
            loadMoreRows={fetchMoreData}
            rowCount={rowCount}
            minimumBatchSize={10}
            ref={(el) => {
              infiniteLoader.current = el;
            }}
          >
            {({ onRowsRendered, registerChild: registerChildIL }) => {
              return (
                <AutoSizer>
                  {({ width, height }: { width: number; height: number }) => (
                    <ColumnSizer
                      columnMaxWidth={MAX_CARD_WIDTH}
                      columnMinWidth={MIN_CARD_WIDTH}
                      columnCount={COLUMN_COUNT}
                      width={width - GRID_PADDING}
                    >
                      {({
                        adjustedWidth,
                        getColumnWidth,
                        registerChild: registerChildCS
                      }) => (
                        <Grid
                          ref={(el) => {
                            registerChildCS(el);
                            registerChildIL(el);
                          }}
                          height={height - GRID_PADDING}
                          overscanRowCount={10}
                          overscanColumnCount={10}
                          onSectionRendered={onSectionRendered(onRowsRendered)}
                          noContentRenderer={EmptyCmp}
                          columnWidth={getColumnWidth}
                          columnCount={COLUMN_COUNT}
                          rowHeight={CARD_HEIGHT}
                          rowCount={rowCount}
                          cellRenderer={cellRenderer}
                          width={adjustedWidth}
                        />
                      )}
                    </ColumnSizer>
                  )}
                </AutoSizer>
              );
            }}
          </InfiniteLoader>
        </div>
      </div>
    </form>
  );
};
