import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useStore } from "react-redux";

import {
  getCropOrigin,
  getCroppingChange,
  getIsCropping,
  getSelectionBoundingBox
} from "../../scene-editor-selectors";
import { useDraggingActions } from "../asset-config/use-dragging-actions";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { useGetMouseMovement } from "./utils/use-mouse-movement";

export enum CropOrigin {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  BOTTOM = "BOTTOM",
  TOP = "TOP"
}

export const getCropWidth = (cropWidth: number) => {
  return Math.max(cropWidth, 10);
};

export const getCropHeight = (cropHeight: number) => {
  return Math.max(cropHeight, 10);
};

export const useCropping = (canvasScale: number) => {
  const cropChangeRefX = useRef(0);
  const cropChangeRefY = useRef(0);

  const { updateCrop } = useDraggingActions();
  const getMouseMovement = useGetMouseMovement();

  const dispatch = useDispatch();
  const store = useStore();

  const cropAnchorMouseDownHandlerFactory = useCallback(
    (origin: CropOrigin) => (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      e.stopPropagation();
      dispatch(SceneEditorActions.setIsCropping(true));
      dispatch(SceneEditorActions.setCropOrigin(origin));
      cropChangeRefX.current = 0;
      cropChangeRefY.current = 0;
    },
    [cropChangeRefX, cropChangeRefY, dispatch]
  );

  const cropMouseDownHandlerRight = useCallback(
    cropAnchorMouseDownHandlerFactory(CropOrigin.RIGHT),
    [cropAnchorMouseDownHandlerFactory]
  );

  const cropMouseDownHandlerLeft = useCallback(
    cropAnchorMouseDownHandlerFactory(CropOrigin.LEFT),
    [cropAnchorMouseDownHandlerFactory]
  );

  const cropMouseDownHandlerTop = useCallback(
    cropAnchorMouseDownHandlerFactory(CropOrigin.TOP),
    [cropAnchorMouseDownHandlerFactory]
  );

  const cropMouseDownHandlerBottom = useCallback(
    cropAnchorMouseDownHandlerFactory(CropOrigin.BOTTOM),
    [cropAnchorMouseDownHandlerFactory]
  );

  const cropMouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      const boundingBox = getSelectionBoundingBox(store.getState());
      const cropping = getIsCropping(store.getState());
      const cropOrigin = getCropOrigin(store.getState());

      if (cropping && boundingBox) {
        const { movementX, movementY } = getMouseMovement(e);

        switch (cropOrigin) {
          case CropOrigin.RIGHT:
            cropChangeRefX.current -= movementX;

            dispatch(
              SceneEditorActions.setCroppingChange({
                cropWidth: cropChangeRefX.current / canvasScale,
                cropHeight: 0,
                cropOffsetX: 0,
                cropOffsetY: 0
              })
            );
            break;
          case CropOrigin.LEFT:
            cropChangeRefX.current -= movementX;
            dispatch(
              SceneEditorActions.setCroppingChange({
                cropWidth: -cropChangeRefX.current / canvasScale,
                cropHeight: 0,
                cropOffsetX: cropChangeRefX.current / canvasScale,
                cropOffsetY: 0
              })
            );
            break;
          case CropOrigin.TOP:
            cropChangeRefY.current -= movementY;
            dispatch(
              SceneEditorActions.setCroppingChange({
                cropWidth: 0,
                cropHeight: -cropChangeRefY.current / canvasScale,
                cropOffsetX: 0,
                cropOffsetY: cropChangeRefY.current / canvasScale
              })
            );
            break;
          case CropOrigin.BOTTOM:
            cropChangeRefY.current -= movementY;
            dispatch(
              SceneEditorActions.setCroppingChange({
                cropWidth: 0,
                cropHeight: cropChangeRefY.current / canvasScale,
                cropOffsetX: 0,
                cropOffsetY: 0
              })
            );
            break;
        }
      }
    },
    [canvasScale, store, dispatch, getMouseMovement]
  );

  const cropMouseUpHandler = useCallback(() => {
    const cropping = getIsCropping(store.getState());

    if (cropping) {
      dispatch(SceneEditorActions.setIsCropping(false));
      cropChangeRefX.current = 0;
      cropChangeRefY.current = 0;

      const croppingChange = getCroppingChange(store.getState());

      updateCrop({
        cropWidth: croppingChange.cropWidth,
        cropHeight: croppingChange.cropHeight,
        cropOffsetX: croppingChange.cropOffsetX,
        cropOffsetY: croppingChange.cropOffsetY
      });
      dispatch(
        SceneEditorActions.setCroppingChange({
          cropHeight: 0,
          cropOffsetX: 0,
          cropOffsetY: 0,
          cropWidth: 0
        })
      );
    }
  }, [updateCrop, dispatch, cropChangeRefY, cropChangeRefX, store]);

  useEffect(() => {
    document.addEventListener("mouseup", cropMouseUpHandler);
    document.addEventListener("mousemove", cropMouseMoveHandler);
    return () => {
      document.removeEventListener("mouseup", cropMouseUpHandler);
      document.removeEventListener("mousemove", cropMouseMoveHandler);
    };
  }, [cropMouseUpHandler, cropMouseMoveHandler]);

  return {
    cropMouseDownHandlerBottom,
    cropMouseDownHandlerTop,
    cropMouseDownHandlerLeft,
    cropMouseDownHandlerRight
  };
};
