import React from "react";

export const AssetImage = React.memo(
  (props: {
    width: number;
    height: number;
    glow: boolean;
    rounded: boolean;
    src: string;
    cropWidth: number;
    cropHeight: number;
    cropOffsetX: number;
    cropOffsetY: number;
  }) => {
    const {
      src,
      width,
      height,
      rounded,
      glow,
      cropHeight,
      cropWidth,
      cropOffsetX,
      cropOffsetY
    } = props;

    return (
      <div
        style={{
          width: cropWidth,
          height: cropHeight,
          overflow: "hidden",
          borderRadius: rounded ? "20px" : 0,
          boxShadow: glow ? "0 0 50px #fff" : ""
        }}
      >
        <img
          alt=""
          src={src}
          style={{
            width,
            height,
            position: "relative",
            top: -cropOffsetY,
            left: -cropOffsetX
          }}
          draggable={false}
        />
      </div>
    );
  }
);
