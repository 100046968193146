import React from "react";
import cx from "classnames";
import { reduxForm, submit } from "redux-form";
import { connect } from "react-redux";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";

import { Modal } from "../../ux/modal";
import Actions from "../scene-actions";
import validate from "./scene-validate";
import { SceneForm } from "./scene-form";
import { getBranding } from "../../config/config-selectors";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "scene-create";
const SceneFormCreate = reduxForm({
  form: FORM_NAME
})(SceneForm);

const mapDispatchToProps = {
  submitForm: () => submit(FORM_NAME),
  createScene: Actions.Creators.createScene
};

const mapStateToProps = (state) => ({
  roomName: getBranding(state).roomName,
  saveInProgress: getProgressForKeySelector(state)(Actions.Types.CREATE_SCENE)
});

export const SceneAdd = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ submitForm, createScene, roomName, saveInProgress }) => {
  const closeModal = () => window.history.back();

  return (
    <Modal
      title="New Scene"
      className="modalBoxSm"
      onCloseClick={closeModal}
      data-qa={MODAL_LOCATORS.getModal("new-scene")}
      footer={
        <button
          className={cx("button", {
            inProgress: saveInProgress()
          })}
          onClick={submitForm}
          data-qa={MODAL_LOCATORS.buttons.save}
        >
          Save
        </button>
      }
    >
      <SceneFormCreate
        validate={validate(roomName)}
        onSubmit={createScene}
        hideCollaborators={false}
      />
    </Modal>
  );
});
