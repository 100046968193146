import { Logger as logger } from "purplex-logging";

export enum EditBackgroundResizingTransformOrigin {
  LEFT_TOP = "left top",
  RIGHT_TOP = "right top",
  LEFT_BOTTOM = "left bottom",
  RIGHT_BOTTOM = "right bottom",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom"
}

const GUIDE_SNAP_THRESHOLD = 50;

//TODO try to refactor into something more manageable - try to separate transform + bounding + applying guides
// However, I am not sure if it's possible or makes it more readable
// ALso unit tests?? :D
export const resizingTransformationHandler = {
  [EditBackgroundResizingTransformOrigin.RIGHT_BOTTOM]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    wallHeight: number,
    totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      const boundedChangeX = Math.max(
        offset.offsetX / canvasScale,
        -assetWidth
      );

      if (
        Math.abs(totalWallWidth - (assetLeft + assetWidth + boundedChangeX)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying right edge guide.");
        const guidedChange = totalWallWidth - assetLeft - assetWidth;
        return {
          absoluteWidthChange: guidedChange,
          absoluteHeightChange: guidedChange / ratio,
          absoluteLeftChange: 0,
          absoluteTopChange: 0
        };
      }

      if (
        Math.abs(
          wallHeight - (assetTop + assetHeight + boundedChangeX / ratio)
        ) < GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying bottom edge guide.");
        const guidedChange = wallHeight - assetTop - assetHeight;
        return {
          absoluteWidthChange: guidedChange * ratio,
          absoluteHeightChange: guidedChange,
          absoluteLeftChange: 0,
          absoluteTopChange: 0
        };
      }

      return {
        absoluteWidthChange: boundedChangeX,
        absoluteHeightChange: boundedChangeX / ratio,
        absoluteLeftChange: 0,
        absoluteTopChange: 0
      };
    }

    const transformedState = {
      absoluteWidthChange: Math.max(offset.offsetX / canvasScale, -assetWidth),
      absoluteHeightChange: Math.max(
        offset.offsetY / canvasScale,
        -assetHeight
      ),
      absoluteLeftChange: 0,
      absoluteTopChange: 0
    };
    if (
      Math.abs(
        totalWallWidth - (assetLeft + assetWidth + offset.offsetX / canvasScale)
      ) < GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying right edge guide.");
      transformedState.absoluteWidthChange =
        totalWallWidth - assetLeft - assetWidth;
    }

    if (
      Math.abs(
        wallHeight - (assetTop + assetHeight + offset.offsetY / canvasScale)
      ) < GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying bottom edge guide.");
      transformedState.absoluteHeightChange =
        wallHeight - assetTop - assetHeight;
    }

    return transformedState;
  },
  [EditBackgroundResizingTransformOrigin.RIGHT_TOP]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    wallHeight: number,
    totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      const boundedChangeX = Math.max(
        offset.offsetX / canvasScale,
        -assetWidth
      );

      if (
        Math.abs(totalWallWidth - (assetLeft + assetWidth + boundedChangeX)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying right edge guide.");
        const guidedChange = totalWallWidth - assetLeft - assetWidth;
        return {
          absoluteWidthChange: guidedChange,
          absoluteHeightChange: guidedChange / ratio,
          absoluteLeftChange: 0,
          absoluteTopChange: -guidedChange / ratio
        };
      }

      if (
        Math.abs(0 - (assetTop - boundedChangeX / ratio)) < GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying top edge guide.");
        const guidedChange = assetTop;
        return {
          absoluteWidthChange: guidedChange * ratio,
          absoluteHeightChange: guidedChange,
          absoluteLeftChange: 0,
          absoluteTopChange: -guidedChange
        };
      }

      return {
        absoluteWidthChange: boundedChangeX,
        absoluteHeightChange: boundedChangeX / ratio,
        absoluteLeftChange: 0,
        absoluteTopChange: -boundedChangeX / ratio
      };
    }

    const transformedState = {
      absoluteWidthChange: Math.max(offset.offsetX / canvasScale, -assetWidth),
      absoluteHeightChange: -Math.max(
        offset.offsetY / canvasScale,
        -assetHeight
      ),
      absoluteLeftChange: 0,
      absoluteTopChange: Math.min(offset.offsetY / canvasScale, assetHeight)
    };

    if (
      Math.abs(
        totalWallWidth - (assetLeft + assetWidth + offset.offsetX / canvasScale)
      ) < GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying right edge guide.");
      transformedState.absoluteWidthChange =
        totalWallWidth - assetLeft - assetWidth;
    }

    if (
      Math.abs(0 - (assetTop - offset.offsetY / canvasScale)) <
      GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying top edge guide.");
      transformedState.absoluteHeightChange = assetTop;
      transformedState.absoluteTopChange = -assetTop;
    }

    return transformedState;
  },
  [EditBackgroundResizingTransformOrigin.LEFT_BOTTOM]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    wallHeight: number,
    _totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      if (
        Math.abs(0 - (assetLeft + offset.offsetX / canvasScale)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying left edge guide.");
        return {
          absoluteWidthChange: assetLeft,
          absoluteHeightChange: assetLeft / ratio,
          absoluteLeftChange: -assetLeft,
          absoluteTopChange: 0
        };
      }

      if (
        Math.abs(
          wallHeight -
            (assetTop + assetHeight - offset.offsetX / canvasScale / ratio)
        ) < GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying bottom edge guide.");
        return {
          absoluteWidthChange: (wallHeight - assetTop - assetHeight) * ratio,
          absoluteHeightChange: wallHeight - assetTop - assetHeight,
          absoluteLeftChange: -(wallHeight - assetTop - assetHeight) * ratio,
          absoluteTopChange: 0
        };
      }

      return {
        absoluteWidthChange: Math.max(
          -offset.offsetX / canvasScale,
          -assetWidth
        ),
        absoluteHeightChange: Math.max(
          -offset.offsetX / canvasScale / ratio,
          -assetHeight
        ),
        absoluteLeftChange: Math.min(offset.offsetX / canvasScale, assetWidth),
        absoluteTopChange: 0
      };
    }

    const transformedState = {
      absoluteWidthChange: Math.max(-offset.offsetX / canvasScale, -assetWidth),
      absoluteHeightChange: Math.max(
        offset.offsetY / canvasScale,
        -assetHeight
      ),
      absoluteLeftChange: Math.min(offset.offsetX / canvasScale, assetWidth),
      absoluteTopChange: 0
    };

    if (
      Math.abs(0 - (assetLeft + offset.offsetX / canvasScale)) <
      GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying left edge guide.");
      transformedState.absoluteWidthChange = assetLeft;
      transformedState.absoluteLeftChange = -assetLeft;
    }

    if (
      Math.abs(
        wallHeight - (assetTop + assetHeight + offset.offsetY / canvasScale)
      ) < GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying bottom edge guide.");
      transformedState.absoluteHeightChange =
        wallHeight - assetTop - assetHeight;
    }

    return transformedState;
  },
  [EditBackgroundResizingTransformOrigin.LEFT_TOP]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    _wallHeight: number,
    _totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      if (
        Math.abs(0 - (assetLeft + offset.offsetX / canvasScale)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying left edge guide.");
        return {
          absoluteWidthChange: assetLeft,
          absoluteHeightChange: assetLeft / ratio,
          absoluteLeftChange: -assetLeft,
          absoluteTopChange: -assetLeft / ratio
        };
      }

      if (
        Math.abs(0 - (assetTop + offset.offsetX / canvasScale / ratio)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying top edge guide.");
        return {
          absoluteWidthChange: assetTop * ratio,
          absoluteHeightChange: assetTop,
          absoluteLeftChange: -assetTop * ratio,
          absoluteTopChange: -assetTop
        };
      }

      return {
        absoluteWidthChange: Math.max(
          -offset.offsetX / canvasScale,
          -assetWidth
        ),
        absoluteHeightChange: Math.max(
          -offset.offsetX / canvasScale / ratio,
          -assetHeight
        ),
        absoluteLeftChange: Math.min(offset.offsetX / canvasScale, assetWidth),
        absoluteTopChange: Math.min(
          offset.offsetX / canvasScale / ratio,
          assetHeight
        )
      };
    }

    const transformedState = {
      absoluteWidthChange: Math.max(-offset.offsetX / canvasScale, -assetWidth),
      absoluteHeightChange: Math.max(
        -offset.offsetY / canvasScale,
        -assetHeight
      ),
      absoluteLeftChange: Math.min(offset.offsetX / canvasScale, assetWidth),
      absoluteTopChange: Math.min(offset.offsetY / canvasScale, assetHeight)
    };

    if (
      Math.abs(0 - (assetLeft + offset.offsetX / canvasScale)) <
      GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying left edge guide.");
      transformedState.absoluteWidthChange = assetLeft;
      transformedState.absoluteLeftChange = -assetLeft;
    }

    if (
      Math.abs(0 - (assetTop + offset.offsetY / canvasScale)) <
      GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying top edge guide.");
      transformedState.absoluteHeightChange = assetTop;
      transformedState.absoluteTopChange = -assetTop;
    }

    return transformedState;
  },
  [EditBackgroundResizingTransformOrigin.LEFT]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    wallHeight: number,
    _totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      if (
        Math.abs(0 - (assetLeft + offset.offsetX / canvasScale)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying left edge guide.");
        return {
          absoluteWidthChange: assetLeft,
          absoluteHeightChange: assetLeft / ratio,
          absoluteLeftChange: -assetLeft,
          absoluteTopChange: 0
        };
      }

      if (
        Math.abs(
          wallHeight -
            (assetTop + assetHeight - offset.offsetX / canvasScale / ratio)
        ) < GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying bottom edge guide.");
        return {
          absoluteWidthChange: (wallHeight - assetTop - assetHeight) * ratio,
          absoluteHeightChange: wallHeight - assetTop - assetHeight,
          absoluteLeftChange: -(wallHeight - assetTop - assetHeight) * ratio,
          absoluteTopChange: 0
        };
      }

      return {
        absoluteWidthChange: Math.max(
          -offset.offsetX / canvasScale,
          -assetWidth
        ),
        absoluteHeightChange: Math.max(
          -offset.offsetX / canvasScale / ratio,
          -assetHeight
        ),
        absoluteLeftChange: Math.min(offset.offsetX / canvasScale, assetWidth),
        absoluteTopChange: 0
      };
    }
    const transformedState = {
      absoluteWidthChange: -Math.max(offset.offsetX / canvasScale, -assetWidth),
      absoluteHeightChange: 0,
      absoluteLeftChange: Math.min(offset.offsetX / canvasScale, assetWidth),
      absoluteTopChange: 0
    };

    if (
      Math.abs(0 - (assetLeft + offset.offsetX / canvasScale)) <
      GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying left edge guide.");
      transformedState.absoluteWidthChange = assetLeft;
      transformedState.absoluteLeftChange = -assetLeft;
    }

    return transformedState;
  },
  [EditBackgroundResizingTransformOrigin.RIGHT]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    wallHeight: number,
    totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      const boundedChangeX = Math.max(
        offset.offsetX / canvasScale,
        -assetWidth
      );

      if (
        Math.abs(totalWallWidth - (assetLeft + assetWidth + boundedChangeX)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying right edge guide.");
        const guidedChange = totalWallWidth - assetLeft - assetWidth;
        return {
          absoluteWidthChange: guidedChange,
          absoluteHeightChange: guidedChange / ratio,
          absoluteLeftChange: 0,
          absoluteTopChange: 0
        };
      }

      if (
        Math.abs(
          wallHeight - (assetTop + assetHeight + boundedChangeX / ratio)
        ) < GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying bottom edge guide.");
        const guidedChange = wallHeight - assetTop - assetHeight;
        return {
          absoluteWidthChange: guidedChange * ratio,
          absoluteHeightChange: guidedChange,
          absoluteLeftChange: 0,
          absoluteTopChange: 0
        };
      }

      return {
        absoluteWidthChange: boundedChangeX,
        absoluteHeightChange: boundedChangeX / ratio,
        absoluteLeftChange: 0,
        absoluteTopChange: 0
      };
    }
    const transformedState = {
      absoluteWidthChange: Math.max(offset.offsetX / canvasScale, -assetWidth),
      absoluteHeightChange: 0,
      absoluteLeftChange: 0,
      absoluteTopChange: 0
    };

    if (
      Math.abs(
        totalWallWidth - (assetLeft + assetWidth + offset.offsetX / canvasScale)
      ) < GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying right edge guide.");
      transformedState.absoluteWidthChange =
        totalWallWidth - assetLeft - assetWidth;
    }

    return transformedState;
  },
  [EditBackgroundResizingTransformOrigin.TOP]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    wallHeight: number,
    totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      const boundedChangeY = offset.offsetY / canvasScale;
      if (
        Math.abs(
          totalWallWidth - (assetLeft + assetWidth - boundedChangeY * ratio)
        ) < GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying right edge guide.");
        const guidedChange = totalWallWidth - assetLeft - assetWidth;
        return {
          absoluteWidthChange: guidedChange,
          absoluteHeightChange: guidedChange / ratio,
          absoluteLeftChange: 0,
          absoluteTopChange: -guidedChange / ratio
        };
      }

      if (Math.abs(0 - (assetTop + boundedChangeY)) < GUIDE_SNAP_THRESHOLD) {
        logger.debug("Applying top edge guide.");
        const guidedChange = assetTop;
        return {
          absoluteWidthChange: guidedChange * ratio,
          absoluteHeightChange: guidedChange,
          absoluteLeftChange: 0,
          absoluteTopChange: -guidedChange
        };
      }

      return {
        absoluteWidthChange: -Math.min(boundedChangeY * ratio, assetWidth),
        absoluteHeightChange: -Math.min(boundedChangeY, assetHeight),
        absoluteLeftChange: 0,
        absoluteTopChange: Math.min(boundedChangeY, assetHeight)
      };
    }
    const transformedState = {
      absoluteWidthChange: 0,
      absoluteHeightChange: -Math.min(
        offset.offsetY / canvasScale,
        assetHeight
      ),
      absoluteLeftChange: 0,
      absoluteTopChange: Math.min(offset.offsetY / canvasScale, assetHeight)
    };

    if (
      Math.abs(0 - (assetTop + offset.offsetY / canvasScale)) <
      GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying top edge guide.");
      transformedState.absoluteHeightChange = assetTop;
      transformedState.absoluteTopChange = -assetTop;
    }
    return transformedState;
  },
  [EditBackgroundResizingTransformOrigin.BOTTOM]: (
    offset: { offsetX: number; offsetY: number },
    canvasScale: number,
    ratioLocked: boolean,
    assetWidth: number,
    assetHeight: number,
    assetLeft: number,
    assetTop: number,
    wallHeight: number,
    totalWallWidth: number
  ) => {
    const ratio = assetWidth / assetHeight;
    if (ratioLocked) {
      const boundedChangeY = offset.offsetY / canvasScale;

      if (
        Math.abs(
          totalWallWidth - (assetLeft + assetWidth + boundedChangeY * ratio)
        ) < GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying right edge guide.");
        const guidedChange = totalWallWidth - assetLeft - assetWidth;
        return {
          absoluteWidthChange: guidedChange,
          absoluteHeightChange: guidedChange / ratio,
          absoluteLeftChange: 0,
          absoluteTopChange: 0
        };
      }

      if (
        Math.abs(wallHeight - (assetTop + assetHeight + boundedChangeY)) <
        GUIDE_SNAP_THRESHOLD
      ) {
        logger.debug("Applying bottom edge guide.");
        const guidedChange = wallHeight - assetTop - assetHeight;
        return {
          absoluteWidthChange: guidedChange * ratio,
          absoluteHeightChange: guidedChange,
          absoluteLeftChange: 0,
          absoluteTopChange: 0
        };
      }

      return {
        absoluteWidthChange: Math.max(boundedChangeY * ratio, -assetWidth),
        absoluteHeightChange: Math.max(boundedChangeY, -assetHeight),
        absoluteLeftChange: 0,
        absoluteTopChange: 0
      };
    }

    const transformedState = {
      absoluteWidthChange: 0,
      absoluteHeightChange: Math.max(
        offset.offsetY / canvasScale,
        -assetHeight
      ),
      absoluteLeftChange: 0,
      absoluteTopChange: 0
    };

    if (
      Math.abs(
        wallHeight - (assetTop + assetHeight + offset.offsetY / canvasScale)
      ) < GUIDE_SNAP_THRESHOLD
    ) {
      logger.debug("Applying bottom edge guide.");
      transformedState.absoluteHeightChange =
        wallHeight - assetTop - assetHeight;
    }

    return transformedState;
  }
};
