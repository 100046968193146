import { main } from "./client/main";
main();

if (module.hot) {
  module.hot.accept("./client/main", async () => {
    let serverRestarted;

    do {
      const { status } = await fetch("/api/config");
      if (status === 504) {
        await new Promise((res) => setTimeout(res, 300));
      } else {
        serverRestarted = true;
      }
    } while (!serverRestarted);

    window.location.reload();
  });
}
