import { CustomFieldEntity } from "../../../../../../shared/types/custom-field-entity";
import { DropTargetMonitor, useDrop } from "react-dnd";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { CategoryTreeNode } from "../../custom-field-types";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";
import cx from "classnames";
import { ReactComponent as IconFolderOpened } from "../../../../../images/folder-open.svg";
import { ReactComponent as IconFolder } from "../../../../../images/folder.svg";
import { ReactComponent as IconSubFolderOpened } from "../../../../../images/sub-folder-open.svg";
import { ReactComponent as IconSubFolder } from "../../../../../images/sub-folder.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../../images/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../../images/arrow-down.svg";
import { ReactComponent as IconEdit } from "../../../../../images/btn-edit.svg";
import { ReactComponent as IconDelete } from "../../../../../images/btn-delete.svg";
import { ReactComponent as IconAdd } from "../../../../../images/add.svg";
import { CustomFieldsCategoryAddForm } from "../category-forms/custom-fields-category-add";
import { FilterListItem } from "./filter-list-item";
import { moveDownFactory, moveUpFactory } from "../../custom-field-utils";

export const CategoryListItem = (props: {
  label: string;
  categoryTree: any[];
  path: string;
  id: number;
  child?: boolean;
  assetType: CustomFieldEntity;
  filterValue: string;
  onAddCategory: (payload: {
    label: string;
    path: string;
    parentId: number | null;
    entity: CustomFieldEntity;
  }) => void;
  onEditCategory: (id: number) => void;
  onAddFilter: (id: number) => void;
  onEditFilter: (id: number) => void;
  onToggle: () => void;
  matchSelected: (id: number) => void;
  onOpen: () => void;
  onClose: () => void;
  onAssignFilter: (payload: {
    filterId: number;
    categoryId: number;
    entity: CustomFieldEntity;
  }) => void;
  onUnAssignFilter: (payload: { id: number; label: string }) => void;
  onMoveUp: (id: number) => void;
  onMoveDown: (id: number) => void;
  onChangeSort: (payload: {
    firstId: number;
    secondId: number;
  }) => Promise<void>;
  opened?: boolean;
  onDeleteCategory: (payload: { id: string | number }) => Promise<void>;
}) => {
  const {
    label,
    categoryTree,
    path,
    id,
    assetType,
    filterValue,
    onAddCategory,
    onEditCategory,
    onAddFilter,
    onEditFilter,
    onToggle,
    onAssignFilter,
    onUnAssignFilter,
    onMoveUp,
    onMoveDown,
    onChangeSort,
    child,
    opened: openedProps,
    onDeleteCategory,
    matchSelected,
    onClose,
    onOpen
  } = props;

  const [{ isOver }, drop] = useDrop({
    accept: "dndAssignFilter",
    canDrop: (item: never, monitor: DropTargetMonitor) => {
      return !categoryTree.find(
        (child: any) => child.customFieldId === monitor.getItem().id
      );
    },
    drop: (item: never, monitor: DropTargetMonitor) => {
      onAssignFilter({
        filterId: monitor.getItem().id,
        categoryId: id,
        entity: assetType
      });
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [opened, setOpened] = useState(false);
  const [creatingCategory, setCreatingCategory] = useState(false);
  const [openedChildren, setOpenedChildren] = useState<number[]>([]);
  const [localFilteredState, setLocalFilteredState] = useState(true);

  const isLeaf = !categoryTree.find(
    (item) => item.type === CustomFieldType.CATEGORY
  );
  const isEmpty = categoryTree.length === 0;
  const isFiltered = filterValue.trim() !== "";

  const editCategory = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onEditCategory(id);
    },
    [onEditCategory, id]
  );

  const onAddFilterHandler = useCallback(() => {
    if (isFiltered) {
      return;
    }
    onAddFilter(id);
  }, [onAddFilter, id, isFiltered]);

  const addCategory = useCallback(
    ({ label }: { label: string }) => {
      onAddCategory({
        label,
        path: `${path}${id}/`,
        parentId: id,
        entity: assetType
      });
    },
    [onAddCategory, path, id, assetType]
  );

  const toggleCategoryHandler = useCallback(
    (event: SyntheticEvent) => {
      if (isFiltered && localFilteredState) {
        matchSelected(id);
      }
      if (isFiltered) {
        return;
      }
      onToggle();
      setCreatingCategory(false);
      event.stopPropagation();
    },
    [isFiltered, matchSelected, onToggle, id, localFilteredState]
  );

  const onMatchSelectedItemHandler = useCallback(() => {
    matchSelected(id);
  }, [matchSelected, id]);

  const onMatchSelectedCategoryHandler = useCallback(
    (childId: number) => {
      matchSelected(id);
      setOpenedChildren([childId]);
    },
    [matchSelected, id]
  );

  const moveUpForChildren = useCallback(
    moveUpFactory(categoryTree, onChangeSort),
    [categoryTree, onChangeSort]
  );

  const moveDownForChildren = useCallback(
    moveDownFactory(categoryTree, onChangeSort),
    [categoryTree, onChangeSort]
  );

  const onMoveUpHandler = useCallback(
    (e) => {
      if (isFiltered) {
        return;
      }
      e.stopPropagation();
      onMoveUp(id);
    },
    [isFiltered, onMoveUp, id]
  );

  const onMoveDownHandler = useCallback(
    (e) => {
      if (isFiltered) {
        return;
      }
      e.stopPropagation();
      onMoveDown(id);
    },
    [isFiltered, onMoveDown, id]
  );

  const onDeleteHandler = useCallback(
    (e) => {
      if (isFiltered) {
        return;
      }
      e.stopPropagation();
      onDeleteCategory({ id });
    },
    [onDeleteCategory, id, isFiltered]
  );

  const checkChildren = useCallback(
    (nodes: CategoryTreeNode[]): boolean => {
      if (nodes.length === 0) {
        return false;
      }
      if (
        nodes.some((val) =>
          val.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      ) {
        return true;
      }
      return nodes.some((node) => checkChildren(node.children));
    },
    [filterValue]
  );

  const hasChildrenMatches = checkChildren(categoryTree);

  useEffect(() => {
    if (openedProps !== undefined) setOpened(openedProps);
    if (!openedProps) setOpenedChildren([]);
  }, [openedProps]);

  useEffect(() => {
    setLocalFilteredState(
      label.toLowerCase().includes(filterValue.toLowerCase())
    );
    if (isFiltered) {
      if (hasChildrenMatches) {
        onOpen();
      } else {
        onClose();
      }
    }
    // eslint-disable-next-line
  }, [filterValue, label, categoryTree, isFiltered, hasChildrenMatches]);

  return (
    <div
      className={cx({
        gridRowDnD: true,
        gridRowDndOver: isOver,
        rowFilter: isLeaf && opened && categoryTree.length,
        filtered: isFiltered && !hasChildrenMatches && !localFilteredState,
        filteredOpen: isFiltered && hasChildrenMatches && !localFilteredState
      })}
      ref={isLeaf && opened ? drop : null}
      data-qa={`row-${label}`}
    >
      <div
        className={cx("gridRow category ", {
          filtered: isFiltered && !hasChildrenMatches && !localFilteredState,
          highlighted: isFiltered && localFilteredState
        })}
        onClick={toggleCategoryHandler}
      >
        <span className={cx("gridIcon", { opened })}>
          {!child && (opened ? <IconFolderOpened /> : <IconFolder />)}
          {child && (opened ? <IconSubFolderOpened /> : <IconSubFolder />)}
        </span>

        <h3 className="gridRowTitle" data-qa="title">
          {label}
        </h3>

        <span className="gridActions" data-qa="grid-row-actions">
          <button
            type="button"
            className="button buttonIcon buttonOutlined circle"
            onClick={onMoveUpHandler}
            data-qa="btn-move-up"
          >
            <ArrowUpIcon />
          </button>
          <button
            type="button"
            className="button buttonIcon buttonOutlined circle"
            onClick={onMoveDownHandler}
            data-qa="btn-move-down"
          >
            <ArrowDownIcon />
          </button>
          <button
            type="button"
            className="button buttonIcon outlined"
            onClick={editCategory}
            data-qa="btn-edit"
          >
            <IconEdit />
          </button>
          <button
            type="button"
            className="button buttonIcon outlined danger"
            onClick={onDeleteHandler}
            data-qa="btn-delete"
          >
            <IconDelete />
          </button>
        </span>
      </div>
      {opened && (
        <div className="rowLevel">
          <div className="rowActions" data-qa="row-actions">
            {!creatingCategory && (isLeaf || isEmpty) && (
              <button
                className={cx("addItemButton child")}
                onClick={onAddFilterHandler}
                data-qa="btn-add-filter"
              >
                <IconAdd />
                Add Filter
              </button>
            )}
            {(!isLeaf || isEmpty) && (
              <CustomFieldsCategoryAddForm
                parentId={id}
                onSubmit={addCategory}
                onEditing={() => setCreatingCategory(true)}
                onCancel={() => setCreatingCategory(false)}
                child={true}
                disabled={isFiltered}
              />
            )}
          </div>

          {categoryTree.map((item) =>
            item.type === CustomFieldType.CATEGORY ? (
              <CategoryListItem
                {...item}
                categoryTree={item.children}
                key={item.id}
                assetType={assetType}
                onToggle={() => {
                  if (filterValue) {
                    setOpenedChildren((state) => [...state, item.id]);
                  } else {
                    if (openedChildren.includes(item.id)) {
                      setOpenedChildren([]);
                    } else {
                      setOpenedChildren([item.id]);
                    }
                  }
                }}
                onOpen={() => {
                  setOpenedChildren((state) => [...state, item.id]);
                }}
                onClose={() => {
                  setOpenedChildren((state) =>
                    state.filter((child) => child !== item.id)
                  );
                }}
                onAddCategory={onAddCategory}
                onEditCategory={onEditCategory}
                onAddFilter={onAddFilter}
                onAssignFilter={onAssignFilter}
                onUnAssignFilter={onUnAssignFilter}
                onEditFilter={onEditFilter}
                onMoveUp={moveUpForChildren}
                onMoveDown={moveDownForChildren}
                onChangeSort={onChangeSort}
                filterValue={filterValue}
                child={true}
                opened={openedChildren.includes(item.id)}
                onDeleteCategory={onDeleteCategory}
                matchSelected={onMatchSelectedCategoryHandler}
              />
            ) : (
              <FilterListItem
                {...item}
                key={item.id}
                onUnAssignFilter={onUnAssignFilter}
                onEdit={onEditFilter}
                onMoveUp={moveUpForChildren}
                onMoveDown={moveDownForChildren}
                filterValue={filterValue}
                onMatchSelected={onMatchSelectedItemHandler}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};
