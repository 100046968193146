import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditorSettings } from "shared/types/app-setting";

const initialState: {
  settings?: EditorSettings;
} = {
  settings: undefined
};

const { actions, reducer } = createSlice({
  name: "editorSettings",
  initialState,
  reducers: {
    onEditorSettingsFetched: (_, action: PayloadAction<EditorSettings>) => ({
      settings: action.payload
    })
  }
});

export const EditorSettingActions = actions;
export const editorSettingsReducer = reducer;
