import React, { useCallback } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { useSelector } from "react-redux";

import { TextField } from "../../../../forms/text-field";
import { useSignUpLink } from "../../hooks/use-sign-up-link";
import { isAllowedDomainInCorrectFormat } from "../../../../../../shared/utils";
import { RootState } from "../../../../root/root-reducer";
import { useNotifications } from "../../../../ux/notifications/use-notifications";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

interface FormData {
  allowedEmailDomains: string;
  signUpLink: string;
}
type Errors = Partial<Record<keyof FormData, string>>;

const validate = (values: FormData) => {
  const errs: Errors = {};

  if (!values.allowedEmailDomains) {
    return errs;
  }
  const domains = values.allowedEmailDomains.split(",");

  if (!domains.every(isAllowedDomainInCorrectFormat)) {
    errs.allowedEmailDomains =
      "Please use format @domain.com and separate by comma ";
  }

  return errs;
};

const FORM_NAME = "invite_users_form";

const allowedEmailDomainsSelector = (state: RootState) =>
  formValueSelector(FORM_NAME)(state, "allowedEmailDomains");
const signUpLinkSelector = (state: RootState) =>
  formValueSelector(FORM_NAME)(state, "signUpLink");

const Form = reduxForm<
  FormData,
  {
    generateNewLink: (values: { allowedEmailDomains: string }) => void;
    copyToClipboard: () => void;
    updateAllowedDomains: (values: { allowedEmailDomains: string }) => void;
  }
>({
  form: FORM_NAME,
  enableReinitialize: true,
  validate
})(
  ({
    generateNewLink,
    copyToClipboard,
    handleSubmit,
    updateAllowedDomains
  }) => {
    const allowedEmailDomains = useSelector(allowedEmailDomainsSelector);
    const signUpLink = useSelector(signUpLinkSelector);

    const onUpdateDomains = useCallback(async () => {
      const errors = validate({ allowedEmailDomains, signUpLink: "" });
      if (errors.allowedEmailDomains) {
        return;
      }
      if (!signUpLink || signUpLink.trim() === "") {
        generateNewLink({ allowedEmailDomains });
      } else {
        updateAllowedDomains({ allowedEmailDomains });
      }
    }, [
      updateAllowedDomains,
      allowedEmailDomains,
      signUpLink,
      generateNewLink
    ]);

    const onGenerateLink = useCallback(async () => {
      const errors = validate({ allowedEmailDomains, signUpLink: "" });
      if (!errors.allowedEmailDomains) {
        generateNewLink({ allowedEmailDomains });
      }
    }, [allowedEmailDomains, generateNewLink]);

    return (
      <form onSubmit={handleSubmit(() => {})}>
        <div style={{ display: "inline-block" }}>
          <h2>Invite users to sign up</h2>
          <TextField
            name="allowedEmailDomains"
            label={
              <>
                Allowed domains to sign up
                <br />
                <small>
                  Anyone with email addresses at these domains can automatically
                  sign up for an account. If you use multiple domains separate
                  them by a comma.
                </small>
              </>
            }
            onBlur={onUpdateDomains}
            dataQa={FORM_LOCATORS.inputs.getInput("allowed-domains")}
          />
          <TextField
            name="signUpLink"
            label={
              <>
                Invitation link
                <br />
                <small>
                  Share the link to add anyone with an allowed domain.
                </small>
              </>
            }
            disabled
            dataQa={FORM_LOCATORS.inputs.getInput("sign-up")}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="button buttonOutlined"
              data-qa={FORM_LOCATORS.buttons.getButton("generate-new")}
              type="button"
              onClick={onGenerateLink}
            >
              Generate New
            </button>
            <button
              className="button right"
              data-qa={FORM_LOCATORS.buttons.getButton("copy-link")}
              type="button"
              onClick={copyToClipboard}
            >
              Copy Link
            </button>
          </div>
        </div>
      </form>
    );
  }
);

export const InviteUsers = () => {
  const { generateLink, signUpLink, updateAllowedDomains } = useSignUpLink();
  const { notifySuccess } = useNotifications();

  let initialValues: FormData = { signUpLink: "", allowedEmailDomains: "" };
  if (signUpLink && signUpLink.token && signUpLink.allowedEmailDomains) {
    initialValues = {
      signUpLink: `${window.location.origin}/api/users/sign-up-confirm?token=${signUpLink.token}`,
      allowedEmailDomains: signUpLink.allowedEmailDomains.join(", ")
    };
  }

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(initialValues.signUpLink);
    notifySuccess("Sign up link copied to clipboard!");
  }, [initialValues, notifySuccess]);

  const generateNewLink = useCallback(
    async (values: { allowedEmailDomains: string }) => {
      await generateLink({ allowedEmailDomains: values.allowedEmailDomains });
    },
    [generateLink]
  );

  return (
    <Form
      initialValues={initialValues}
      generateNewLink={generateNewLink}
      copyToClipboard={copyToClipboard}
      updateAllowedDomains={updateAllowedDomains}
    />
  );
};
