import React from "react";

import IconAsset from "../../icon-asset";
import { ReactComponent as IconTrashSmall } from "client/images/trash-small.svg";
import { AssetType } from "../../../../../shared/types/asset-type";
import { useComponentAdapter } from "../../../root/feature-adapter";

export const AssignedWall = (props: {
  asset: {
    type: AssetType;
    alternativeAssets: { dimensions: { width: number; height: number } }[];
    dimensions: { width: number; height: number };
    title: string;
  };
  onRemoveClick: () => void;
  children?: React.ReactNode;
}) => {
  const { asset, onRemoveClick, children } = props;

  const { EditedBackgroundStatusForRoom } = useComponentAdapter();

  return (
    <div className="backgrounds-asset">
      <IconAsset type={asset.type} className="type" />
      <span className="title">
        <span className="status">
          <EditedBackgroundStatusForRoom wallAsset={asset} />
        </span>
        {asset.title}
      </span>
      {onRemoveClick && (
        <a
          onClick={onRemoveClick}
          className="remove"
          data-qa="remove-background-btn"
        >
          <IconTrashSmall className="icon icon-remove" />
        </a>
      )}
      {children}
    </div>
  );
};
