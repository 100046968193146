import { createReducer } from "reduxsauce";

import Actions from "./media-actions";
import { UploadStatus } from "./upload-statuses";

const INITIAL_STATE = {
  selectedAsset: null,
  dialogShown: false,
  assetsInDialog: [],
  uploadQueue: [],
  uploadStates: {},
  previewAssetUuid: null,
  infiniteScrollLoadingKey: 0,
  lastEditedSharedAsset: null
};

const onShowDialog = (state, { assetIds }) => ({
  ...state,
  selectedAsset: null,
  assetsInDialog: [...assetIds],
  dialogShown: true
});

const onHideDialog = (state) => ({ ...state, dialogShown: false });

const onAssetCreated = (state, { assetUuid }) => ({
  ...state,
  assetsInDialog: [...state.assetsInDialog, assetUuid]
});

const onSelectAsset = (state, { uuid }) => ({
  ...state,
  selectedAsset: uuid
});

const onRemoveAsset = (state, { uuid }) => {
  const assetsInDialog = state.assetsInDialog.filter(
    (assetUuid) => assetUuid !== uuid
  );

  return {
    ...state,
    assetsInDialog,
    selectedAsset:
      state.selectedAsset === uuid
        ? assetsInDialog[0] || null
        : state.selectedAsset
  };
};

const enqueueUploadAsset = (state, { uuid, size, uploadId }) => ({
  ...state,
  uploadQueue: [
    ...state.uploadQueue.filter((uploadUuid) => uploadUuid !== uuid),
    uuid
  ],
  uploadStates: {
    ...state.uploadStates,
    [uuid]: {
      uploaded: 0,
      size: size || 0,
      status: UploadStatus.PENDING,
      uploadId,
      hidden: false
    }
  }
});

const hideUploadByUploadId = (state, { uploadId }) => {
  const toHideUuid = Object.keys(state.uploadStates).find((uuid) => {
    const uploadState = state.uploadStates[uuid];
    return uploadState && uploadState.uploadId === uploadId;
  });

  if (!toHideUuid) {
    return state;
  }

  const updatedUploadStates = {
    ...state.uploadStates,
    [toHideUuid]: {
      ...state.uploadStates[toHideUuid],
      hidden: true
    }
  };

  const somethingIsVisible = state.uploadQueue.some((uuid) => {
    const uploadState = updatedUploadStates[uuid];
    return uploadState && uploadState.hidden === false;
  });

  if (!somethingIsVisible) {
    // reset the queue once the queue is emptied
    return {
      ...state,
      uploadQueue: [],
      uploadStates: {}
    };
  } else {
    return {
      ...state,
      uploadStates: updatedUploadStates
    };
  }
};

const setUploadAssetProgress = (state, { uuid, uploaded }) => {
  const uploadState = state.uploadStates[uuid];

  // the uploaded size can be a littler bit bigger than real file size
  // because of http headers sizes
  const sanitizedUploaded = uploadState
    ? Math.min(uploaded, uploadState.size) || 0
    : 0;

  return {
    ...state,
    uploadStates: {
      ...state.uploadStates,
      [uuid]: uploadState
        ? {
            ...uploadState,
            uploaded: sanitizedUploaded
          }
        : null
    }
  };
};

const setUploadAssetStatus = (state, { uuid, status }) => ({
  ...state,
  uploadStates: {
    ...state.uploadStates,
    [uuid]: state.uploadStates[uuid]
      ? {
          ...state.uploadStates[uuid],
          status
        }
      : null
  }
});

const openPreviewDialog = (state, { uuid }) => ({
  ...state,
  previewAssetUuid: uuid
});

const onIncrementLoadingKey = (state) => ({
  ...state,
  infiniteScrollLoadingKey: state.infiniteScrollLoadingKey + 1
});

const onChangeLastEditedSharedAsset = (state, { uuid }) => ({
  ...state,
  lastEditedSharedAsset: uuid
});

export const HANDLERS = {
  // add media dialog related actions
  [Actions.Types.SHOW_DIALOG]: onShowDialog,
  [Actions.Types.HIDE_DIALOG]: onHideDialog,
  [Actions.Types.ASSET_CREATED]: onAssetCreated,
  [Actions.Types.SELECT_ASSET]: onSelectAsset,
  [Actions.Types.REMOVE_ASSET]: onRemoveAsset,

  // general asset queue file upload actions
  [Actions.Types.ENQUEUE_UPLOAD_ASSET]: enqueueUploadAsset,
  [Actions.Types.HIDE_UPLOAD_BY_UPLOAD_ID]: hideUploadByUploadId,
  [Actions.Types.SET_UPLOAD_ASSET_PROGRESS]: setUploadAssetProgress,
  [Actions.Types.SET_UPLOAD_ASSET_STATUS]: setUploadAssetStatus,

  // preview dialog
  [Actions.Types.OPEN_PREVIEW_DIALOG]: openPreviewDialog,

  [Actions.Types.INCREMENT_LOADING_KEY]: onIncrementLoadingKey,
  [Actions.Types.LAST_EDITED_SHARED_ASSET]: onChangeLastEditedSharedAsset
};

export default createReducer(INITIAL_STATE, HANDLERS);
