import React, { useCallback } from "react";
import { Logger as logger } from "purplex-logging";
import { useDispatch, useSelector } from "react-redux";

import { getAssetFile } from "../get-asset-file";
import { getCdnHost } from "../../config/config-selectors";
import { Modal } from "../../ux/modal";

import { getPreviewAsset } from "../media-selectors";

import Actions from "../media-actions";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { AssetViewModel } from "../../entity-repository/entity.types";
import { useComponentAdapter } from "../../root/feature-adapter";

const { AssetType } = require("../../../../shared/types/asset-type");
const { AssetStatus } = require("../../../../shared/types/asset-status");

export const PreviewMedia = () => {
  const asset = useSelector(getPreviewAsset);
  const cdnHost = useSelector(getCdnHost);

  const dispatch = useDispatch();

  const { WallPreview } = useComponentAdapter();

  const onClose = useCallback(() => {
    dispatch(Actions.Creators.previewAsset(null));
  }, [dispatch]);

  useEscapeHook(asset !== undefined, onClose);

  if (!asset) {
    return <></>;
  }

  const {
    title,
    playAs,
    formatName,
    status
  } = (asset as unknown) as AssetViewModel;

  const renderContent = () => {
    const url = getAssetFile(asset, cdnHost);

    switch (playAs) {
      case AssetType.ASSET_WALL_IMAGE:
      case AssetType.ASSET_WALL_VIDEO:
        return <WallPreview wallAsset={(asset as unknown) as AssetViewModel} />;

      case AssetType.ASSET_IMAGE:
        return (
          <img
            src={url}
            alt={title}
            key={url}
            data-qa="modal-asset-preview-image"
          />
        );

      case AssetType.ASSET_VIDEO:
        return (
          <video controls key={url}>
            <source src={url} type={`video/${formatName}`} />
          </video>
        );

      case AssetType.ASSET_SOUND:
        return (
          <audio controls key={url}>
            <source
              src={url}
              type={formatName === "mp3" ? "audio/mpeg" : `audio/${formatName}`}
            />
          </audio>
        );

      case AssetType.ASSET_URL:
      case AssetType.ASSET_PRESENTATION:
      case AssetType.ASSET_PDF:
      default:
        logger.error(`Invalid asset playAs type=${playAs} for asset preview`);
    }
  };

  return (
    <Modal
      title={title}
      className="previewMediaModal"
      onCloseClick={onClose}
      enableBackdropClick
    >
      {status === AssetStatus.STATUS_READY
        ? renderContent()
        : "File is not ready"}
    </Modal>
  );
};
