import React, { ReactNode } from "react";

import { ModalDialogRoot } from "../../dialogs/components/modal-dialog-root";
import { PreviewMedia } from "../../media/components/preview-media";
import { SceneEditorHeader } from "./scene-editor-header";

export const SceneEditorPageTemplate = ({
  children
}: {
  children: ReactNode;
}) => {
  return (
    <div className="application">
      <SceneEditorHeader />
      {children}
      <ModalDialogRoot />
      <PreviewMedia />
    </div>
  );
};
