import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AclResource } from "../../../shared/acl";
import { useHasAccessFactory } from "../auth/use-has-access";
import { hasTriggersEnabled } from "../config/config-selectors";
import { CustomFieldsIndex } from "./custom-fields/components/custom-fields-index";
import PredefinedSceneNames from "./predefined-scene-names/predefined-scene-names-view";
import {
  CUSTOM_FIELDS_COMPANY_LIBRARY_ROUTE,
  CUSTOM_FIELDS_PRESENTATIONS_ROUTE,
  DOMAINS_ROUTE,
  EDITOR_SETTINGS_ROUTE,
  PREDEFINED_SCENE_NAMES_ROUTE,
  TRIGGERS_LIST_ROUTE,
  TRIGGERS_ROUTE,
  USERS_ROUTE
} from "../routing/route-names";
import { getRouteName } from "../routing/routing-selectors";
import { TriggersIndex } from "./triggers/components/triggers-index";
import { GuestDomains } from "./users/components/guest-domains/guest-domains";
import { UsersRoot } from "./users/components/users-root";
import { MenuItem, SubMenu } from "../ux/submenu";
import { useFetchEditorSettings } from "./editor-settings/hooks/use-fetch-editor-settings";
import { EditorSettings } from "./editor-settings/components/editor-setttings";

const defaultSettings = [
  {
    title: "Filters & Categories",
    href: CUSTOM_FIELDS_COMPANY_LIBRARY_ROUTE,
    aclResource: AclResource.CUSTOM_FIELDS_WRITE,
    children: [
      {
        title: "Company library",
        href: CUSTOM_FIELDS_COMPANY_LIBRARY_ROUTE,
        aclResource: AclResource.CUSTOM_FIELDS_WRITE,
        dataQa: "nav-filters-company-library"
      },
      {
        title: "Presentations",
        href: CUSTOM_FIELDS_PRESENTATIONS_ROUTE,
        aclResource: AclResource.CUSTOM_FIELDS_WRITE,
        dataQa: "nav-filters-presentations"
      }
    ],
    dataQa: "nav-filters-and-categories"
  },
  {
    title: "Scene Names",
    href: PREDEFINED_SCENE_NAMES_ROUTE,
    aclResource: AclResource.PREDEFINED_SCENES_WRITE,
    dataQa: "nav-scene-names"
  },
  {
    title: "Editor Settings",
    href: EDITOR_SETTINGS_ROUTE,
    aclResource: AclResource.EDITOR_SETTINS_WRITE,
    dataQa: "nav-editor-settings"
  }
];

const users = {
  title: "Users",
  href: USERS_ROUTE,
  aclResource: AclResource.USERS,
  children: [
    {
      title: "Users",
      href: USERS_ROUTE,
      aclResource: AclResource.USERS,
      dataQa: "nav-users"
    }
  ],
  dataQa: "nav-users-and-domains"
};

const usersAndDomains = {
  title: "Users & Domains",
  href: USERS_ROUTE,
  aclResource: AclResource.USERS,
  children: [
    {
      title: "Users",
      href: USERS_ROUTE,
      aclResource: AclResource.USERS,
      dataQa: "nav-users"
    },
    {
      title: "Guest Domains",
      href: DOMAINS_ROUTE,
      aclResource: AclResource.GUEST_DOMAINS_WRITE,
      dataQa: "nav-domains"
    }
  ],
  dataQa: "nav-users-and-domains"
};

const triggers = {
  title: "Triggers",
  href: TRIGGERS_LIST_ROUTE,
  aclResource: AclResource.TRIGGERS_WRITE,
  dataQa: "nav-triggers"
};

export const SettingsIndex = () => {
  const [settings, setSettings] = useState<MenuItem[]>(defaultSettings);

  const routeName = useSelector(getRouteName);
  const triggersEnabled = useSelector(hasTriggersEnabled);

  const hasAccess = useHasAccessFactory();
  const canWriteGuestDomains = hasAccess(AclResource.GUEST_DOMAINS_WRITE);

  useEffect(() => {
    let settings = [...defaultSettings];

    if (triggersEnabled) {
      settings = [...settings, triggers];
    }
    if (canWriteGuestDomains) {
      settings = [usersAndDomains, ...settings];
    } else {
      settings = [users, ...settings];
    }
    setSettings(settings);
  }, [triggersEnabled, canWriteGuestDomains]);

  useFetchEditorSettings();

  if (!routeName) {
    return <></>;
  }

  return (
    <>
      <SubMenu settings={settings} title="Settings" />
      {hasAccess(AclResource.USERS) && routeName.includes(USERS_ROUTE) && (
        <UsersRoot />
      )}
      {hasAccess(AclResource.GUEST_DOMAINS_WRITE) &&
        routeName.includes(DOMAINS_ROUTE) && <GuestDomains />}
      {hasAccess(AclResource.CUSTOM_FIELDS_WRITE) &&
        (routeName.includes(CUSTOM_FIELDS_COMPANY_LIBRARY_ROUTE) ||
          routeName.includes(CUSTOM_FIELDS_PRESENTATIONS_ROUTE)) && (
          <CustomFieldsIndex />
        )}
      {hasAccess(AclResource.PREDEFINED_SCENES_WRITE) &&
        routeName.includes(PREDEFINED_SCENE_NAMES_ROUTE) && (
          <PredefinedSceneNames />
        )}
      {hasAccess(AclResource.EDITOR_SETTINS_WRITE) &&
        routeName.includes(EDITOR_SETTINGS_ROUTE) && <EditorSettings />}
      {triggersEnabled &&
        hasAccess(AclResource.TRIGGERS_WRITE) &&
        routeName.includes(TRIGGERS_ROUTE) && <TriggersIndex />}
    </>
  );
};
