export enum UserTrackingEvent {
  PresentationCreated = "cms:presentation_created",
  SceneCreated = "cms:scene_created",
  UserProfileUpdated = "cms:profile_updated",
  PageView = "cms:viewpage",
  SceneChanged = "cms:scene.settings_updated",
  SceneChangeRequested = "cms:scene.settings_opened",
  SceneEditorEnter = "cms:scene_entered",
  AssetUploaded = "cms:asset_uploaded",
  SceneEditorSceneAssetAdded = "cms:asset_added",
  AssetPreviewed = "cms:asset_previewed",
  SceneEditorSceneAssetDetached = "cms:asset_detached",
  AssetDeleted = "cms:asset_deleted",
  SceneEditorAssetSearched = "cms:asset_searched",
  SceneEditorBackgroundChangeLoop = "cms:background.loop_updated",
  SceneEditorBackgroundChangeAutoplay = "cms:background.autoplay_updated",
  SceneEditorTransitionChangeDuration = "cms:Transition.duration",
  SceneEditorSceneAssetWallChanged = "cms:Asset_relocated",
  SceneEditorSceneAssetMoved = "cms:Asset_repositioned",
  SceneEditorSceneAssetPositionLocked = "cms:Asset_locked",
  SceneEditorSceneAssetGlowUpdated = "cms:Glow_updated",
  SceneEditorSceneAssetRoundedCornersUpdated = "cms:Rounded.corners_updated",
  SceneEditorSceneAssetVisibleOnStartUpdated = "cms:Visible.on.start_activated",
  SceneEditorSceneAssetScaleUpdated = "cms:Scale_updated",
  SceneEditorSceneAssetOpacityUpdated = "cms:Opacity_updated",
  SceneEditorGridSnapToGridUpdated = "cms:Grid_updated",
  SceneEditorOnClickActionsOpened = "cms:On-click.actions_opened",
  SceneEditorOnClickActionsAssigned = "cms:On-click.actions_assigned",
  SceneEditorOnClickActionsSearched = "cms:On-click.actions_searched",
  SceneEditorAssetResized = "cms:asset_resized",
  SceneEditorAssetCropped = "cms:cms:asset_cropped",
  SceneDetached = "cms:cms:scene_detached",
  UserAdded = "cms:user_added",
  ManualOpened = "cms:manual_opened",
  SceneEditorBackgroundAdded = "cms:background_added",
  BackgroundPreviewed = "cms:background_previewed",
  SceneEditorBackgroundDetached = "cms:background_detached",
  PresentationPublished = "cms:presentation_published",
  OnlineTestRunRequested = "cms:online_testrun_requested",
  OnlineSessionScheduleRequested = "cms:online_session_requested",
  FirstLogin = "cms:first_login"
}
