import React, { ReactNode } from "react";
import cx from "classnames";
import { LoggedOutHeader } from "./logged-out-header";

export const AuthPageShell = (props: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={cx("application", props.className)}>
      <LoggedOutHeader />
      <div className="loginPage">
        <div className="loginBox">{props.children}</div>
      </div>
    </div>
  );
};
