export enum AssetTab {
  BACKGROUNDS = "BACKGROUNDS",
  TRANSITIONS = "TRANSITIONS",
  SCENE_MEDIA = "SCENE_MEDIA"
}

export const getAssetTab = (routeName: string) => {
  if (!routeName) {
    return AssetTab.SCENE_MEDIA;
  }

  if (routeName.includes("background")) {
    return AssetTab.BACKGROUNDS;
  }

  if (routeName.includes("transition")) {
    return AssetTab.TRANSITIONS;
  }

  return AssetTab.SCENE_MEDIA;
};
