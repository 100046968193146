import {
  InputType,
  ToggleType,
  FormButtonType,
  SelectType
} from "../types/form.types";

export const FORM_LOCATORS = {
  inputs: {
    getInputPin: (value: number) => `input-pin-${value}`,
    getInputBox: (value: InputType) => `box-input-${value}`,
    getInput: (value: InputType) => `input-${value}`
  },
  select: {
    getSelect: (value: SelectType) => `select-${value}`
  },
  toggles: {
    getToggle: (value: ToggleType) => `toggle-${value}`,
    getToggleBox: (value: ToggleType) => `box-toggle-${value}`
  },
  buttons: {
    getButton: (value: FormButtonType) => `form-btn-${value}`
  }
};
