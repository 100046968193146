import { useCallback } from "react";
import stringStripHtml from "string-strip-html";
import { Logger as logger } from "purplex-logging";
import { useDispatch, useSelector, useStore } from "react-redux";

import { TextAssetAlignment } from "../../../../../shared/types/asset";
import {
  asset as assetSchema,
  scene as sceneSchema
} from "../../../entity-repository/schema";
import { SceneEditorAssetActionType } from "./scene-editor-actions";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { useApi } from "../../../api/use-api";
import { useNormalizeAndStore } from "../use-normalize-and-store";
import { useSceneEditorUtils } from "./use-scene-editor-utils";
import { getScene } from "../../scene-editor-selectors";
import { useSceneAssetCrudActions } from "./use-scene-asset-crud-actions";
import { useTracking } from "client/modules/user-tracking/use-tracking";
import { trackSceneEditorSceneAssetAdded } from "client/modules/user-tracking/user-tracking-creators";
import { AssetType } from "shared/types/asset-type";
import { getUser } from "client/modules/auth/auth-selectors";

export const useTextAssetActions = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const api = useApi();
  const normalizeAndStore = useNormalizeAndStore();
  const { initialize } = useSceneEditorUtils();
  const { deleteAssets } = useSceneAssetCrudActions();
  const track = useTracking();
  const { roles } = useSelector(getUser);

  const changeTextAssetConfig = useCallback(
    async (
      {
        fontSize,
        bold,
        italic,
        underline,
        alignment,
        font,
        color,
        opacity
      }: {
        fontSize?: string;
        bold?: boolean;
        italic?: boolean;
        underline?: boolean;
        alignment?: TextAssetAlignment;
        font?: string;
        color?: string;
        opacity?: number;
      },
      writeToHistory = true,
      selectedSceneAssetUuids
    ) => {
      const scene = getScene(store.getState());
      if (selectedSceneAssetUuids.length !== 1 || !scene) {
        return;
      }

      const sceneAsset = scene.sceneAssets.find(
        (sceneAsset) => sceneAsset.uuid === selectedSceneAssetUuids[0]
      );

      if (!sceneAsset) {
        return;
      }

      const nextValue = {
        fontSize,
        bold,
        italic,
        underline,
        alignment,
        font,
        color
      };

      normalizeAndStore(
        { uuid: sceneAsset.asset.uuid, metadata: { ...nextValue } },
        assetSchema
      );

      if (writeToHistory) {
        const assetActions = [sceneAsset].map((asset) => {
          return {
            type: SceneEditorAssetActionType.CHANGE_TEXT_ASSET_CONFIG,
            sceneAssetId: asset.id,
            previousValue: {
              fontSize: asset.asset.metadata.fontSize,
              bold: asset.asset.metadata.bold,
              italic: asset.asset.metadata.italic,
              underline: asset.asset.metadata.underline,
              alignment: asset.asset.metadata.alignment,
              font: asset.asset.metadata.font
            },
            nextValue
          };
        });

        dispatch(
          SceneEditorActions.addActionBatchToUndoStack({
            actions: assetActions
          })
        );
        dispatch(SceneEditorActions.resetRedoStack());
      }

      // Delete all the text asset metadata otherwise they will get rewritten
      const metadataUpdate = { ...sceneAsset.asset.metadata, ...nextValue };
      delete metadataUpdate.textContent;
      delete metadataUpdate.text;

      try {
        await api.updateAsset(
          sceneAsset.asset.uuid,
          sceneAsset.asset.title,
          sceneAsset.asset.description,
          metadataUpdate,
          [],
          [],
          false
        );
      } catch (e) {
        logger.error("Error while saving text asset", e);
        await initialize();
      }
    },
    [api, normalizeAndStore, dispatch, store, initialize]
  );

  const changeTextAssetText = useCallback(
    async (text: string, writeToHistory, selectedSceneAssetIds) => {
      const scene = getScene(store.getState());
      if (selectedSceneAssetIds.length !== 1 || !scene) {
        return;
      }

      const sceneAsset = scene.sceneAssets.find(
        (sceneAsset) => sceneAsset.uuid === selectedSceneAssetIds[0]
      );

      if (!sceneAsset) {
        return;
      }

      const textIsEmpty = text.trim() === "";

      if (textIsEmpty) {
        await deleteAssets([sceneAsset.uuid]);
      } else {
        const htmlTextString = stringStripHtml(text).result;
        normalizeAndStore(
          {
            uuid: sceneAsset.asset.uuid,
            title: htmlTextString,
            metadata: { text }
          },
          assetSchema
        );

        await api.updateAsset(
          sceneAsset.asset.uuid,
          htmlTextString,
          "",
          {
            textContent: text
          },
          [],
          [],
          false
        );
      }

      if (writeToHistory) {
        if (textIsEmpty) {
          dispatch(SceneEditorActions.resetUndoStack());
          dispatch(SceneEditorActions.resetRedoStack());
        } else {
          dispatch(
            SceneEditorActions.addActionBatchToUndoStack({
              actions: [
                {
                  type: SceneEditorAssetActionType.CHANGE_TEXT_ASSET_TEXT,
                  sceneAssetId: sceneAsset.id,
                  previousValue: sceneAsset.asset.title,
                  nextValue: text
                }
              ]
            })
          );
          dispatch(SceneEditorActions.resetRedoStack());
        }
      }
    },
    [deleteAssets, dispatch, normalizeAndStore, store, api]
  );

  const createTextAsset = useCallback(
    async (data: {
      left: number;
      top: number;
      text: string;
      fontSize: string;
      width: number;
      height: number;
      bold: boolean;
      italic: boolean;
      underline: boolean;
      alignment: string;
      color: string;
      font: string;
      rounded: boolean;
      glow: boolean;
      opacity: number;
      positionLocked: boolean;
      visible: boolean;
    }) => {
      const scene = getScene(store.getState());

      //TODO add initialize check once we add undo/redo to this action and do it optimistically
      if (scene && scene.id) {
        const updatedScene = await api.createTextAsset(
          scene.id,
          data.text,
          data.fontSize,
          Math.round(data.width),
          Math.round(data.height),
          data.bold,
          data.italic,
          data.underline,
          data.alignment,
          data.color,
          data.font,
          Math.round(data.left),
          Math.round(data.top),
          data.rounded,
          data.glow,
          data.positionLocked,
          data.visible,
          data.opacity
        );
        normalizeAndStore(updatedScene, sceneSchema);

        track(
          trackSceneEditorSceneAssetAdded(
            AssetType.ASSET_TEXT,
            roles,
            updatedScene.title
          )
        );
      }
    },
    [normalizeAndStore, api, store, track, roles]
  );

  return {
    changeTextAssetConfig,
    changeTextAssetText,
    createTextAsset
  };
};
