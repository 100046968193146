import { actions as routerActions } from "redux-router5";
import {
  all,
  takeEvery,
  put,
  fork,
  select,
  call,
  take
} from "redux-saga/effects";
import { Logger as logger } from "purplex-logging";
import { BusinessError } from "../api/client-errors";

import * as Api from "../api/client";
import { normalizeAndStore } from "../entity-repository/entity-repository-saga";
import {
  scene as sceneSchema,
  instances as instancesSchema,
  presentation as presentationSchema
} from "../entity-repository/schema";
import { getUser } from "../auth/auth-selectors";
import { takeEveryWithCheckProgress } from "../in-progress/in-progress-saga";
import { PRESENTATIONS_EDIT_ROUTE } from "../routing/route-names";
import Actions from "./presentation-actions";
import { onRouteEntered } from "../routing/on-route-enter-saga";
import { getRouteParams } from "../routing/routing-selectors";
import { getPresentation } from "./presentation-selectors";
import {
  notifySuccess,
  notifyError,
  notifyWarn
} from "../ux/notifications/notifications-saga";
import {
  confirmDialogSaga,
  userPickerDialogSaga,
  roomPickerDialogSaga
} from "../dialogs/dialogs-saga";
import { sanitizeSceneTitle } from "../scenes/scene-saga";
import AuthActions from "../auth/auth-actions";

import { AclResource } from "../../../shared/acl";
import { getCustomFieldToEntityMappings } from "../entity-repository/entity-repository-selectors";
import { entityFormToCustomFields } from "../settings/custom-fields/components/filters/filters-block";
import { getConfig } from "../root/root-selectors";
import { DialogResult } from "../dialogs/event-types";
import * as UserTrackingSaga from "../user-tracking/user-tracking-saga";
import * as UserTrackingCreators from "../user-tracking/user-tracking-creators";

const wrapSafely = (saga) =>
  function* safeSagaWrapper(...args) {
    try {
      yield call(saga, ...args);
    } catch (ex) {
      logger.warn("Safely wrapped exception", ex);
    }
  };

function* addPin(presentation) {
  let user = yield select(getUser);

  // If user is not loaded yet, wait for data
  if (!user) {
    yield take(AuthActions.Types.LOGGED_USER);
    user = yield select(getUser);
  }

  let pin = undefined;

  if (user.id === presentation.creatorId) {
    if (presentation.pinProtected) {
      pin = yield call(Api.getPresentationPin, presentation.id);
    } else {
      pin = null;
    }
  }

  return {
    ...presentation,
    pin: pin ? pin.pin : null
  };
}

function* onPresentationEditRouteEnter() {
  const { presentationId } = yield select(getRouteParams);
  const presentation = yield call(Api.getPresentation, presentationId);
  const presentationWithPin = yield call(addPin, presentation);

  yield call(normalizeAndStore, presentationWithPin, presentationSchema);
}

function* onPresentationEditScenesRouteEnter() {
  const instances = yield call(Api.getInstanceConfigs);
  yield call(normalizeAndStore, instances, instancesSchema);
}

const onAddPresentation = wrapSafely(function* onAddPresentationImpl({ data }) {
  const { tab } = yield select(getRouteParams);

  const customFieldToEntityMappings = yield select(
    getCustomFieldToEntityMappings
  );

  const customFields = entityFormToCustomFields(
    data,
    customFieldToEntityMappings
  );

  const presentation = yield call(Api.createPresentation, {
    ...data,
    pin: data.pinProtected ? data.pin : null,
    customFields
  });
  const id = yield call(normalizeAndStore, presentation, presentationSchema);
  yield put(
    routerActions.navigateTo(
      PRESENTATIONS_EDIT_ROUTE,
      { presentationId: id },
      { replace: true }
    )
  );

  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackPresentationCreated(
      "Presentations overview",
      tab,
      data
    )
  );
});

const onUpdatePresentation = wrapSafely(function* onUpdatePresentationImpl({
  data
}) {
  const currentPresentation = yield select(getPresentation);

  if (currentPresentation.public !== data.public) {
    logger.debug("Published has changed");
    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackPresentationPublished(data.public)
    );
  }

  const someTriggerIsNotSameAsSelectedInstance = currentPresentation.presentationSceneTriggers.some(
    (presentationSceneTrigger) =>
      !data.instances.includes(presentationSceneTrigger.trigger.instanceUuid)
  );

  if (someTriggerIsNotSameAsSelectedInstance) {
    // TODO: define microcopy
    const { type } = yield call(confirmDialogSaga, {
      title: "Remove triggers?",
      text: `Some of the triggers are assigned to room which is no longer assigned to the presentation and those will be deleted.`,
      subtext: "",
      confirmLabel: "Remove triggers & save",
      cancelLabel: "Cancel"
    });

    if (type === DialogResult.CANCEL) {
      return;
    }
  }

  const customFieldToEntityMappings = yield select(
    getCustomFieldToEntityMappings
  );

  const payload = { ...data };

  if (!payload.blackbelt) {
    payload.blackbeltEmail = null;
    payload.blackbeltInstance = null;
    payload.blackbeltFrom = null;
    payload.blackbeltTill = null;
  }

  if (!payload.pinProtected) {
    payload.pin = null;
  }

  const customFields = entityFormToCustomFields(
    payload,
    customFieldToEntityMappings
  );

  payload.customFields = customFields;
  payload.collaborators = payload.collaborators.map((c) => c.id);

  const presentation = yield call(Api.updatePresentation, payload.id, payload);
  const presentationWithPin = yield call(addPin, presentation);

  yield call(normalizeAndStore, presentationWithPin, presentationSchema);
  yield call(
    notifySuccess,
    `Presentation ${presentation.title} has been successfully saved.`
  );
});

const onCreateScene = wrapSafely(function* onCreateSceneImpl({ data }) {
  const presentation = yield select(getPresentation);

  const scene = yield call(Api.createScene, sanitizeSceneTitle(data));

  const { tab } = yield select(getRouteParams);
  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackSceneCreated(
      "Presentations overview",
      tab,
      data,
      scene.title,
      data.isDefault,
      data.autoswitch,
      data.collaborators.length > 0
    )
  );

  yield call(
    Api.createPresentationScene,
    scene.id,
    presentation.id,
    { autoswitch: data.autoswitch, isDefault: data.isDefault },
    false
  );

  const updatedPresentation = yield call(
    Api.updatePresentationSceneTrigger,
    presentation.id,
    scene.id,
    data.trigger ? data.trigger.id : null
  );

  yield call(normalizeAndStore, updatedPresentation, presentationSchema);
  yield put(
    routerActions.navigateTo(
      PRESENTATIONS_EDIT_ROUTE,
      { presentationId: presentation.id },
      { replace: true }
    )
  );
});

const onEditScene = wrapSafely(function* onEditSceneImpl({ data }) {
  const presentation = yield select(getPresentation);

  const sceneData = {
    title: data.title,
    title_text: data.title_text,
    description: data.description,
    public: data.public,
    instanceUuid: data.instanceUuid
  };

  const currentScene = presentation.presentationScenes.find(
    (scene) => scene.sceneId === data.id
  );

  let updatedPresentation;
  if (currentScene.autoswitch !== data.autoswitch) {
    updatedPresentation = yield call(
      Api.updatePresentationSceneChangeAutoSwitch,
      data.id,
      presentation.id,
      data.autoswitch
    );
  }

  if (currentScene.isDefault !== data.isDefault) {
    updatedPresentation = yield call(
      Api.updatePresentationSceneChangeDefault,
      data.id,
      presentation.id,
      data.isDefault
    );
  }

  updatedPresentation = yield call(
    Api.updatePresentationSceneTrigger,
    presentation.id,
    data.id,
    data.trigger ? data.trigger.id : null
  );

  if (updatedPresentation) {
    yield call(normalizeAndStore, updatedPresentation, presentationSchema);
  }

  const scene = yield call(
    Api.updateScene,
    data.id,
    sanitizeSceneTitle(sceneData)
  );
  yield call(normalizeAndStore, scene, sceneSchema);

  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackSceneChange("Presentation detail", sceneData)
  );

  yield put(
    routerActions.navigateTo(PRESENTATIONS_EDIT_ROUTE, {
      presentationId: presentation.id
    })
  );
});

function* onDeleteScene({ scene: { id, title, title_text } }) {
  const { type } = yield call(confirmDialogSaga, {
    title: "Delete Scene",
    text: `Are you sure you want to delete scene "${title || title_text}"?`,
    subtext: "",
    confirmLabel: "Delete",
    cancelLabel: "Cancel"
  });

  if (type === DialogResult.CONFIRM) {
    const presentation = yield select(getPresentation);

    try {
      yield call(Api.deleteScene, id);
      yield fork(notifySuccess, "Scene was successfully deleted");

      const updatedPresentation = yield call(
        Api.getPresentation,
        presentation.id
      );

      yield call(normalizeAndStore, updatedPresentation, presentationSchema);
    } catch (e) {
      logger.warn("Presentation Scene Delete Error", e);
      yield fork(notifyError, "Scene delete failed");
    }

    yield put(
      routerActions.navigateTo(
        PRESENTATIONS_EDIT_ROUTE,
        {
          presentationId: presentation.id
        },
        {
          replace: true
        }
      )
    );
  }
}

const onAttachScene = wrapSafely(function* onAttachSceneImpl({ sceneId }) {
  const presentation = yield select(getPresentation);

  const updatedPresentation = yield call(
    Api.createPresentationScene,
    sceneId,
    presentation.id,
    {},
    true
  );

  yield call(normalizeAndStore, updatedPresentation, presentationSchema);
});

const onDetachScene = wrapSafely(function* onDetachSceneImpl({ sceneId }) {
  const presentation = yield select(getPresentation);

  const { type } = yield call(confirmDialogSaga, {
    title: "Remove Scene",
    text: `Are you sure you want to remove this scene from your presentation?`,
    subtext: "",
    confirmLabel: "Remove",
    cancelLabel: "Cancel"
  });

  const scene = presentation.presentationScenes.find(
    (ps) => ps.sceneId === sceneId
  ).scene;

  if (type === DialogResult.CONFIRM) {
    try {
      yield call(Api.deletePresentationScene, sceneId, presentation.id);

      const updatedPresentation = yield call(
        Api.getPresentation,
        presentation.id
      );

      yield call(normalizeAndStore, updatedPresentation, presentationSchema);

      yield call(
        UserTrackingSaga.track,
        UserTrackingCreators.trackSceneDetachedFromPresentation(
          scene.collaborators.length > 0
        )
      );
    } catch (ex) {
      logger.warn("Presentation Scene Detach Error", ex);
      yield fork(notifyError, "Removing of scene failed");
    }
  }
});

function* onRequestDeletePresentation({ id, title }) {
  const { type } = yield call(confirmDialogSaga, {
    title: "Delete Presentation",
    text: `Are you sure you want to delete presentation "${title}"?`,
    subtext: "",
    cancelLabel: "Cancel",
    confirmLabel: "Delete"
  });

  if (type === DialogResult.CONFIRM) {
    try {
      yield call(Api.deletePresentation, id);
      yield put(Actions.Creators.incrementPresentationListVersion());
    } catch (ex) {
      logger.warn("Presentation delete error", ex);
    }
  }
}

function* onRequestSharePresentation({ id: presentationId, title }) {
  const userId = yield call(userPickerDialogSaga, {
    title: "Share Presentation",
    fieldLabel: "Share with"
  });

  if (userId) {
    try {
      yield call(Api.sharePresentation, presentationId, userId);

      yield call(
        notifySuccess,
        `Presentation ${title} has been successfully shared.`
      );
    } catch (ex) {
      if (ex instanceof BusinessError) {
        yield call(
          notifyWarn,
          `Presentation ${title} has already been shared with the user.`
        );
      }
      logger.warn("Presentation share error", ex);
    }
  }
}

function* onShareScene({ sceneId, title }) {
  const userId = yield call(userPickerDialogSaga, {
    title: "Share Scene",
    fieldLabel: "Share with"
  });

  if (userId) {
    try {
      yield call(Api.shareScene, sceneId, userId);

      yield call(notifySuccess, `Scene ${title} has been successfully shared.`);
    } catch (ex) {
      if (ex instanceof BusinessError) {
        yield call(
          notifyWarn,
          `Scene ${title} has already been shared with the user.`
        );
      }
      logger.warn("Scene share error", ex);
    }
  }
}

const onRequestPublishPresentation = wrapSafely(
  function* onRequestPublishPresentationImpl({ id, title }) {
    const userId = yield call(userPickerDialogSaga, {
      title: "Request Publish",
      fieldLabel: "Administrator to approve",
      resourceAccess: AclResource.MAKE_PRESENTATION_GLOBAL
    });

    if (userId) {
      try {
        yield call(Api.requestPublishPresentation, id, userId);

        yield call(
          notifySuccess,
          `Presentation ${title} has been requested to get published.`
        );
      } catch (ex) {
        if (ex instanceof BusinessError) {
          yield call(
            notifyWarn,
            `Presentation ${title} has already been requested to get published by the user.`
          );
        }

        logger.warn("Request presentation publish error", ex);
      }
    }
  }
);

const onDeletePresentationShare = wrapSafely(
  function* onDeletePresentationShareImpl({ id, title }) {
    const { type } = yield call(confirmDialogSaga, {
      title: "Delete share request",
      text: `Are you sure you want to delete share request of "${title}" presentation?`,
      subtext: "",
      confirmLabel: "Delete"
    });

    if (type === DialogResult.CONFIRM) {
      yield call(Api.deletePresentationShare, id);
      yield put(Actions.Creators.incrementPresentationListVersion());

      yield call(
        notifySuccess,
        `Shared presentation ${title} has been successfully removed.`
      );
    }
  }
);

const onPublishPresentation = wrapSafely(function* onPublishPresentationImpl({
  id,
  title
}) {
  yield call(Api.presentationPublish, id);
  yield put(Actions.Creators.incrementPresentationListVersion());

  yield call(
    notifySuccess,
    `Presentation ${title} has been successfully made public.`
  );
});

const onUnpublishPresentation = wrapSafely(
  function* onUnpublishPresentationImpl({ id, title }) {
    const { type } = yield call(confirmDialogSaga, {
      title: "Unpublish Presentation",
      text: `Are you sure you want to unpublish the "${title}" presentation?`,
      subtext: "",
      confirmLabel: "Unpublish"
    });

    if (type === DialogResult.CONFIRM) {
      yield call(Api.presentationUnpublish, id);
      yield put(Actions.Creators.incrementPresentationListVersion());

      yield call(notifySuccess, `Presentation ${title} has been unpublished.`);
    }
  }
);

const onChangeSceneOrder = wrapSafely(function* onChangeSceneOrderImpl({
  sceneId,
  order
}) {
  const presentation = yield select(getPresentation);

  const updatedPresentation = yield call(
    Api.updatePresentationScenesOrder,
    presentation.id,
    sceneId,
    order
  );

  yield call(normalizeAndStore, updatedPresentation, presentationSchema);
});

function* onDryRunPresentation({ id }) {
  const { vixUrl } = yield select(getConfig);
  const presentation = yield select(getPresentation);
  const roomUuid = yield call(roomPickerDialogSaga, {
    title: "Select room",
    confirmLabel: "Start",
    filteredInstances: presentation.instances
  });

  if (roomUuid) {
    const instances = yield call(Api.getInstanceConfigs);
    const instance = instances.find((instance) => instance.uuid === roomUuid);
    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackOnlineTestRunRequested(
        instance && instance.name
      )
    );
    window.open(`${vixUrl}/start-unplanned/${roomUuid}/${id}`);
  }
}

function* onPlanVixSession({ id }) {
  const { vixUrl } = yield select(getConfig);
  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackOnlineSessionScheduleRequested()
  );
  window.open(`${vixUrl}/create/${id}`);
}

function* onRequestEditScene({ sceneId }) {
  const routeParams = yield select(getRouteParams);

  yield put(
    routerActions.navigateTo(`presentations.edit.edit-scene`, {
      ...routeParams,
      sceneId
    })
  );

  yield call(
    UserTrackingSaga.track,
    UserTrackingCreators.trackSceneChangeRequested("Presentation detail")
  );
}

export default function* () {
  yield all([
    takeEveryWithCheckProgress(
      Actions.Types.ADD_PRESENTATION,
      onAddPresentation
    ),
    takeEveryWithCheckProgress(
      Actions.Types.UPDATE_PRESENTATION,
      onUpdatePresentation,
      ({ data: { id } }) => id
    ),
    takeEvery(Actions.Types.CREATE_SCENE, onCreateScene),
    takeEvery(Actions.Types.EDIT_SCENE, onEditScene),
    takeEveryWithCheckProgress(
      Actions.Types.DELETE_SCENE,
      onDeleteScene,
      ({ scene: { id } }) => id
    ),
    takeEveryWithCheckProgress(
      Actions.Types.ATTACH_SCENE,
      onAttachScene,
      ({ sceneId }) => sceneId
    ),
    takeEveryWithCheckProgress(
      Actions.Types.DETACH_SCENE,
      onDetachScene,
      ({ sceneId }) => sceneId
    ),
    takeEvery(Actions.Types.SHARE_SCENE, onShareScene),
    takeEvery(Actions.Types.DRY_RUN_PRESENTATION, onDryRunPresentation),
    takeEvery(Actions.Types.PLAN_VIX_SESSION, onPlanVixSession),
    takeEveryWithCheckProgress(
      Actions.Types.PUBLISH_PRESENTATION,
      onPublishPresentation,
      ({ id }) => id
    ),
    takeEveryWithCheckProgress(
      Actions.Types.UNPUBLISH_PRESENTATION,
      onUnpublishPresentation,
      ({ id }) => id
    ),
    takeEveryWithCheckProgress(
      Actions.Types.REQUEST_DELETE_PRESENTATION,
      onRequestDeletePresentation,
      ({ id }) => id
    ),
    takeEvery(
      Actions.Types.REQUEST_SHARE_PRESENTATION,
      onRequestSharePresentation
    ),
    takeEvery(Actions.Types.REQUEST_EDIT_SCENE, onRequestEditScene),
    takeEveryWithCheckProgress(
      Actions.Types.REQUEST_PUBLISH_PRESENTATION,
      onRequestPublishPresentation,
      ({ id }) => id
    ),
    takeEveryWithCheckProgress(
      Actions.Types.DELETE_PRESENTATION_SHARE,
      onDeletePresentationShare,
      ({ id }) => id
    ),
    takeEvery(Actions.Types.CHANGE_SCENE_ORDER, onChangeSceneOrder),
    fork(
      onRouteEntered,
      PRESENTATIONS_EDIT_ROUTE,
      onPresentationEditRouteEnter
    ),
    fork(
      onRouteEntered,
      "presentations.edit.scenes",
      onPresentationEditScenesRouteEnter
    )
  ]);
}
