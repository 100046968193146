import moment from "moment";
import Tooltip from "rc-tooltip";
import React, { useState, useMemo } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AclResource } from "../../../../shared/acl";
import { useHasAccess } from "../../auth/use-has-access";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { getUploadStates } from "../../media/media-selectors";
import { TabPanel, Tabs } from "../../ux/tab-panel";
import { ListInfiniteScroll, ROW_STATUS } from "../../ux/list-infinite-scroll";
import { FulltextAndFilters } from "../../ux/filters/fulltext-and-filters";
import { ChoiceLogicImplementations } from "../../ux/filters/choice-logic-implementations";
import * as Api from "../../api/client";
import { getCdnHost } from "../../config/config-selectors";
import { getAssetThumbnail } from "../../media/get-asset-thumbnail";
import { DateTimeField } from "../../ux/date-time-field";
import { RelativeTimeField } from "../../ux/relative-time-field";
import { getInfiniteScrollLoadingKey } from "../media-selectors";
import { getAssets } from "../../entity-repository/entity-repository-selectors";
import { ReactComponent as IconTrash } from "../../../images/trash.svg";
import { UploadStatus } from "../upload-statuses";
import { AssetUploadState } from "./asset-upload-state";
import Actions from "../media-actions";
import { getHumanReadableAssetTypeName } from "../asset-types";

import { ReactComponent as IconImage } from "../../../images/assets/image.svg";
import { ReactComponent as IconVideo } from "../../../images/assets/video.svg";
import { ReactComponent as IconExpand } from "../../../images/expand.svg";
import { ReactComponent as IconAdd } from "../../../images/add.svg";
import { useComponentAdapter } from "../../root/feature-adapter";

const { AssetStatus } = require("../../../../shared/types/asset-status");
const { AssetType } = require("../../../../shared/types/asset-type");

const WallsListLoading = () => <div className="gridItemPlaceholder"></div>;

const WallsListEmpty = () => (
  <div className="gridPlaceholder" data-qa="empty-list-assets">
    The list is empty
  </div>
);

const renderMetadata = (asset) => {
  return [
    asset.formatName,
    asset.dimensions && `${asset.dimensions.width}x${asset.dimensions.height}`
  ]
    .filter(Boolean)
    .map((value, index) => <span key={index}>{value}&nbsp;</span>);
};

const newAssetTimeout = moment.duration(2, "hours");

const WallsListRow = ({
  uploadState,
  asset,
  cdnHost,
  onDelete,
  onEdit,
  onPreview,
  isDeletingInProgress
}) => {
  const { title, description, createdAt, updatedAt, creator, type } = asset;
  const canEdit = useHasAccess(AclResource.WALL_WRITE);

  const status = useMemo(() => {
    if (asset.status === AssetStatus.STATUS_NEW) {
      const timeoutOn = moment(createdAt).add(newAssetTimeout);
      const isTimeouted = moment().isAfter(timeoutOn);

      if (isTimeouted) {
        return AssetStatus.STATUS_ERROR;
      }
    }

    return asset.status;
  }, [createdAt, asset.status]);

  const { InstancesWallStatuses } = useComponentAdapter();
  return (
    <div className="gridRow" data-qa={`row-${title}`}>
      {status === AssetStatus.STATUS_READY ? (
        <button
          className="gridImage"
          style={{
            backgroundImage: `url(${getAssetThumbnail(asset, cdnHost)})`
          }}
          onClick={onPreview}
          data-qa="grid-image-btn"
        >
          <i className="previewIcon">
            <IconExpand />
          </i>
          <small data-qa="background-type">
            {type === AssetType.ASSET_WALL_VIDEO ? "Video" : "Image"}
          </small>
        </button>
      ) : type === AssetType.ASSET_WALL_VIDEO ? (
        <i className="gridImage">
          <IconVideo />
          <small>Video</small>
        </i>
      ) : (
        <i className="gridImage">
          <IconImage />
          <small>Image</small>
        </i>
      )}
      <span className="gridCell">
        <h3 className="gridRowTitle" data-qa="title">
          {title}
        </h3>
        <em
          className={cx({
            error: status === AssetStatus.STATUS_ERROR,
            success: status === AssetStatus.STATUS_READY
          })}
          data-qa="status"
        >
          {status.replace("STATUS_", "")}
        </em>
        {description}
      </span>
      <span className="gridCell gridCellStatus">
        <InstancesWallStatuses wallAsset={asset} />
      </span>
      <hr />
      <span className="gridCell" data-qa="created-by">
        Created by
        <h4>{creator}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa="created">
        Created
        <Tooltip
          placement="bottomLeft"
          mouseEnterDelay={0.4}
          overlay={DateTimeField({ datetime: createdAt })}
        >
          <h4>
            <RelativeTimeField datetime={createdAt} />
          </h4>
        </Tooltip>
      </span>
      {updatedAt && (
        <>
          <hr />
          <span className="gridCell" data-qa="updated">
            Updated
            <Tooltip
              placement="bottomLeft"
              mouseEnterDelay={0.4}
              overlay={DateTimeField({ datetime: updatedAt })}
            >
              <h4>
                <RelativeTimeField datetime={updatedAt} />
              </h4>
            </Tooltip>
          </span>
        </>
      )}
      <hr />
      <span className="gridCell" data-qa="metadata">
        Metadata <h4>{renderMetadata(asset)}</h4>
      </span>
      {canEdit && (
        <>
          <hr />
          <span className="gridCell">
            <button className="button" onClick={onEdit} data-qa="edit-btn">
              Edit
            </button>
            <button
              className={cx("button buttonAlert buttonOutlined buttonIcon", {
                inProgress: isDeletingInProgress
              })}
              data-qa="delete-btn"
              onClick={onDelete}
            >
              <IconTrash />
            </button>
          </span>
        </>
      )}
      <AssetUploadState uploadState={uploadState} />
    </div>
  );
};

WallsListRow.propTypes = {
  asset: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    creator: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    playAs: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    metadata: PropTypes.object.isRequired,
    fileName: PropTypes.string
  }).isRequired,
  uploadState: PropTypes.object,
  cdnHost: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  isDeletingInProgress: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  cdnHost: getCdnHost(state),
  loadingKey: getInfiniteScrollLoadingKey(state),
  assetsRepository: getAssets(state),
  uploadStates: getUploadStates(state),
  isDeletingInProgress: getProgressForKeySelector(state)(
    Actions.Types.DELETE_ASSET
  )
});

const mapDispatchToProps = {
  addWall: Actions.Creators.addAsset,
  deleteWall: Actions.Creators.deleteAsset,
  editWall: Actions.Creators.editAsset,
  previewAsset: Actions.Creators.previewAsset,
  onAssetsFetched: Actions.Creators.assetsFetched
};

export const WallsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    cdnHost,
    addWall,
    editWall,
    deleteWall,
    loadingKey,
    assetsRepository,
    uploadStates,
    previewAsset,
    onAssetsFetched,
    isDeletingInProgress
  }) => {
    const canEdit = useHasAccess(AclResource.WALL_WRITE);

    const [search, changeSearch] = useState("");
    const [backgroundType, changeBackgroundType] = useState();

    return (
      <Tabs>
        <TabPanel
          tabs={[]}
          heading="Backgrounds"
          buttons={
            canEdit && (
              <button
                className="button"
                onClick={addWall}
                data-qa="new-background-btn"
              >
                <IconAdd />
                New Background
              </button>
            )
          }
        >
          {() => (
            <>
              <div className="tabFilter">
                <FulltextAndFilters
                  onChange={({ fulltext, filters }) => {
                    changeBackgroundType(filters.type);
                    changeSearch(fulltext);
                  }}
                  filters={[
                    {
                      id: "type",
                      label: "Background Type",
                      choiceLogic: ChoiceLogicImplementations.SELECT_MULTIPLE,
                      choices: async (searchPhrase) => {
                        const result = await Api.searchAssetTypesFilter(
                          searchPhrase,
                          [],
                          search,
                          {},
                          null,
                          true
                        );

                        return {
                          ...result,
                          rows: result.rows.map((row) => ({
                            ...row,
                            label: getHumanReadableAssetTypeName(row.label)
                          }))
                        };
                      }
                    }
                  ]}
                  additionalFilterData={{}}
                  category=""
                />
              </div>
              <div className="tabContent">
                <ListInfiniteScroll
                  rowHeight={70}
                  // loadingKey serves as easy option how to force refresh
                  // of data... eg after uploading assets - it's basically
                  // bridge between the infinite scroll and our redux world
                  key={`${backgroundType}${search}${loadingKey}`}
                  emptyPlaceholder={<WallsListEmpty />}
                  rowRendererProps={assetsRepository}
                  fetchData={async (startIndex, stopIndex) => {
                    const result = await Api.fetchWalls(
                      search,
                      startIndex,
                      stopIndex,
                      backgroundType
                    );

                    onAssetsFetched(result.rows);

                    return result;
                  }}
                >
                  {(status, record, assetsRepositoryDependency) => {
                    switch (status) {
                      case ROW_STATUS.LOADED:
                        if (record) {
                          // Here's the trick
                          // we basically let the library to pickup the
                          // record it downloaded from the API
                          // however if there is existing corresponding
                          // record in entity repo we will instead use that
                          // eg. it fetches the list of records but if for some reason
                          // media saga updates the entity through websocket (eg. asset worker)
                          // processed the asset we will render the fresh data instead
                          let asset = record;
                          if (assetsRepositoryDependency[record.uuid]) {
                            asset = assetsRepositoryDependency[record.uuid];
                          }

                          // So the walls might have more than one "uploading asset"
                          // since there are alternative assets
                          const allUploadingStates = Object.keys(uploadStates)
                            .filter(
                              // First let's find all the alternative assets uploading states
                              (uploadStateUuid) =>
                                asset.alternativeAssets
                                  .map(({ uuid }) => uuid)
                                  .includes(uploadStateUuid) ||
                                // and merge it will current root asset
                                uploadStateUuid === asset.uuid
                            )
                            .map((uploadStateUuid) => ({
                              ...uploadStates[uploadStateUuid]
                            }));

                          // Let's accumulate all the uploading states for particular wall
                          // into single uploading state object
                          const uploadingState = allUploadingStates.reduce(
                            (memo, uploadState) => {
                              if (memo === null) {
                                return uploadState;
                              } else {
                                return {
                                  ...memo,

                                  status: allUploadingStates.some(
                                    ({ status }) =>
                                      status === UploadStatus.UPLOADING
                                  )
                                    ? // if at least one of the uploading states is UPLOADING
                                      // lets mark the reduction as UPLOADING otherwise it's pending
                                      UploadStatus.UPLOADING
                                    : UploadStatus.PENDING,

                                  // And we have to accumulate all the
                                  // uploading info into single object
                                  size: uploadState.size + memo.size,
                                  uploaded: uploadState.uploaded + memo.uploaded
                                };
                              }
                            },
                            null
                          );

                          return (
                            <WallsListRow
                              asset={asset}
                              uploadState={uploadingState}
                              cdnHost={cdnHost}
                              onDelete={() =>
                                deleteWall(asset.uuid, asset.title)
                              }
                              isDeletingInProgress={isDeletingInProgress(
                                asset.uuid
                              )}
                              onEdit={() => editWall(asset.uuid)}
                              onPreview={() => previewAsset(asset.uuid)}
                            />
                          );
                        } else {
                          return false;
                        }
                      case ROW_STATUS.LOADING:
                        return <WallsListLoading />;
                      default:
                        throw new Error(`Unknown row state ${status}`);
                    }
                  }}
                </ListInfiniteScroll>
              </div>
            </>
          )}
        </TabPanel>
      </Tabs>
    );
  }
);
