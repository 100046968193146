import { createSelector } from "reselect";
import { getTriggers } from "../../entity-repository/entity-repository-selectors";
import {
  getRouteParams,
  RouteParamSelector
} from "../../routing/routing-selectors";

export const getEditingTrigger = createSelector(
  getTriggers,
  getRouteParams as RouteParamSelector<{ id: string }>,
  (triggers, routeParams) => routeParams.id && triggers[routeParams.id]
);
