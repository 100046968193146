import cx from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { PRESENTATION_LOCATORS } from "shared/tests/locators/presentation.locators";

export class ButtonDialog extends Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    className: PropTypes.string
  };

  state = {
    isOpen: false
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleOutsideClick, false);
  };

  handleClick = () => {
    // attach/remove event handler
    if (!this.state.isOpen) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleOutsideClick = (e) => {
    // ignore click on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  render = () => {
    const { children, title, className } = this.props;
    return (
      <div
        className={cx("buttonDialog", className)}
        ref={(node) => {
          this.node = node;
        }}
      >
        <button
          className={title ? "button" : "buttonPlus"}
          onClick={this.handleClick}
          data-qa={PRESENTATION_LOCATORS.buttons.getButton("add-new-scene")}
        >
          {title}
        </button>
        <div className={cx("buttonDialogBox", { isOpen: this.state.isOpen })}>
          {children}
        </div>
      </div>
    );
  };
}
