import React from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";

import { getEditedCustomFieldInitialValues } from "../../custom-fields-selectors";
import {
  CustomFieldFormData,
  CustomFieldsForm,
  CustomFieldsFormProps
} from "./custom-fields-form";
import { getRouteName } from "../../../../routing/routing-selectors";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";
import { RootState } from "../../../../root/root-reducer";
import { CustomFieldChoice } from "../../../../../../server/models/custom-field-choice";
import { useEditFilter } from "../../hooks/use-filters-crud";
import { useGetCustomFieldType } from "../../hooks/use-get-custom-field-type";

export const EDIT_CUSTOM_FIELD_FORM_NAME = "custom-field-edit";

const validate = (values: CustomFieldFormData) => {
  const errors: Partial<Record<keyof CustomFieldFormData, string>> = {};
  if (!values.label) {
    errors.label = `Value is required`;
  }
  return errors;
};

const CustomFieldEditForm = reduxForm<
  CustomFieldFormData,
  CustomFieldsFormProps
>({
  form: EDIT_CUSTOM_FIELD_FORM_NAME,
  validate,
  enableReinitialize: true
})(CustomFieldsForm);

export const CustomFieldsEdit = () => {
  const editCustomField = useEditFilter();
  const assetType = useGetCustomFieldType();

  const initialValues = useSelector(getEditedCustomFieldInitialValues);

  const selectedType = useSelector((state: RootState) =>
    formValueSelector(EDIT_CUSTOM_FIELD_FORM_NAME)(state, "type")
  ) as CustomFieldType;
  const helpers = useSelector((state: RootState) =>
    formValueSelector(EDIT_CUSTOM_FIELD_FORM_NAME)(state, "helpers")
  ) as {
    addChoiceInput: string;
  };
  const choices = useSelector((state: RootState) =>
    formValueSelector(EDIT_CUSTOM_FIELD_FORM_NAME)(state, "choices")
  ) as CustomFieldChoice[];
  const route = useSelector(getRouteName) as string;

  return createPortal(
    <CustomFieldEditForm
      initialValues={initialValues}
      onSubmit={editCustomField}
      assetType={assetType}
      selectedType={selectedType}
      addChoiceInput={helpers && helpers.addChoiceInput}
      route={route}
      choices={choices}
    />,
    document.getElementById("modal") as Element
  );
};
