import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as Selectors from "client/modules/settings/predefined-scene-names/predefined-scene-names-selectors";
import Actions from "client/modules/settings/predefined-scene-names/predefined-scene-names-actions";
import { ReactComponent as IconTrash } from "../../../images/trash.svg";

const mapStateToProps = (state) => ({
  records: Selectors.getPredefinedSceneNamesList(state)
});

const mapDispatchToProps = {
  onRecordDelete: Actions.Creators.deleteRecord
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class PredefinedSceneNamesList extends Component {
    static propTypes = {
      onRecordDelete: PropTypes.func.isRequired,
      records: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          sceneName: PropTypes.string.isRequired
        }).isRequired
      ).isRequired
    };

    onDelete = (id) => () => {
      this.props.onRecordDelete(id);
    };

    render() {
      const { records } = this.props;

      return (
        <div>
          {records.length === 0 && (
            <div className="gridPlaceholder">No Scene Names</div>
          )}
          {records.map((record) => (
            <div className="gridRow" key={record.id}>
              <span className="gridCell">
                Name
                <h4>{record.sceneName}</h4>
              </span>
              <span className="gridCell actions">
                <button
                  className="button buttonAlert buttonOutlined buttonIcon"
                  type="button"
                  onClick={this.onDelete(record.id)}
                >
                  <IconTrash />
                </button>
              </span>
            </div>
          ))}
        </div>
      );
    }
  }
);
