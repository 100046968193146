import { useCallback, useEffect, useMemo, useRef } from "react";

// We can not simply take e.movementX/Y beacuse different browser implementations of pointer lock API
export const useGetMouseMovement = () => {
  const previousPosition = useRef<
    { previousPositionX: number; previousPositionY: number } | undefined
  >(undefined);

  const getMovementChange = useCallback(
    (e: MouseEvent) => {
      const movementX =
        previousPosition.current?.previousPositionX !== undefined
          ? previousPosition.current?.previousPositionX - e.pageX
          : 0;
      const movementY =
        previousPosition.current?.previousPositionY !== undefined
          ? previousPosition.current?.previousPositionY - e.pageY
          : 0;

      previousPosition.current = {
        previousPositionX: e.pageX,
        previousPositionY: e.pageY
      };

      return { movementX, movementY };
    },
    [previousPosition]
  );

  const resetPosition = useCallback(() => {
    previousPosition.current = undefined;
  }, [previousPosition]);

  useEffect(() => {
    document.addEventListener("mouseup", resetPosition);

    return () => {
      document.removeEventListener("mouseup", resetPosition);
    };
  }, []);

  return useMemo(() => {
    return getMovementChange;
  }, [getMovementChange]);
};
