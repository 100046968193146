import cx from "classnames";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { PinInputBox } from "./pin-input-box";

import { ReactComponent as IconEyeHide } from "../../../images/eye-hide.svg";
import { ReactComponent as IconEyeShow } from "../../../images/eye-show.svg";

const normalizeValue = (value) =>
  String(value)
    .replace(/[^0-9]/g, "")
    .substr(0, 4); // pin length = 4

const focusInputStart = (input) => {
  input.focus();
};
const focusInputEnd = (input) => {
  input.focus();
};
const focusAndClearInput = (input) => {
  input.focus();
  input.value = "";
};

export const PinInput = ({ value, onChange, dataQa }) => {
  const inputs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [plainForm, setPlainForm] = useState(false);
  const prevValueContainer = useRef(null);

  const type = plainForm ? "text" : "password";
  const safeValue = value || "";

  const onInputChange = () => {
    const newValue = normalizeValue(
      inputs.map(({ current: { value } }) => value).join()
    );

    if (newValue !== value) {
      prevValueContainer.current = newValue;
      onChange(newValue);
    }
  };

  const applyIfInputExist = (inputIndex, fn) => {
    const input = inputs[inputIndex] && inputs[inputIndex].current;
    if (input) {
      fn(input);
    }
  };

  const valueChanged = prevValueContainer.current !== value;

  return (
    <div className="inputPin">
      {inputs.map((inputRef, index) => (
        <PinInputBox
          key={index}
          inputRef={inputRef}
          type={type}
          value={
            (valueChanged
              ? safeValue[index]
              : inputRef.current && inputRef.current.value) || ""
          }
          onChange={onInputChange}
          onFocusLeft={() => applyIfInputExist(index - 1, focusInputEnd)}
          onFocusRight={() => applyIfInputExist(index + 1, focusInputStart)}
          onClearLeft={() => applyIfInputExist(index - 1, focusAndClearInput)}
          onClearRight={() => applyIfInputExist(index + 1, focusAndClearInput)}
          dataQa={`${dataQa}-${index + 1}`}
        />
      ))}
      <button
        className={cx("button buttonIcon", {
          active: !plainForm,
          buttonOutlined: !plainForm
        })}
        type="button"
        onClick={() => setPlainForm(!plainForm)}
        title={plainForm ? "Hide PIN" : "Show PIN"}
      >
        <IconEyeHide className="iconEye" />
        <IconEyeShow className="iconEyeShow" />
      </button>
    </div>
  );
};

PinInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dataQa: PropTypes.string
};
