import {
  ApiEntityResult,
  ApiGenericJsonPayload,
  ApiGenericJsonResult,
  ApiListPayload,
  ApiListResult,
  VerifiedApiEntityPayload,
  VerifiedApiEntityResult,
  WrappedApiPayload
} from "./crud-types";
import { ApiClientError } from "../api/client-errors";

export const verifyApiEntityResult = <
  TEntity,
  TArgs extends Array<unknown> = []
>(
  fn: (...args: TArgs) => ApiEntityResult<TEntity>
) => async (...args: TArgs): VerifiedApiEntityResult<TEntity> => {
  const result = await fn(...args);

  if (!result) {
    throw new ApiClientError("Error during API, empty result.");
  }

  const { data } = result;

  if (!data) {
    throw new ApiClientError("Error during API, no data in result.");
  }

  if (!data.success) {
    if (data.reason) {
      throw new ApiClientError(data.reason);
    }
    throw new ApiClientError("Error during API call.");
  }

  return result as WrappedApiPayload<VerifiedApiEntityPayload<TEntity>>;
};

/**
 * Unwraps any json response
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unwrapAny = <TArgs extends Array<unknown>, TData = any>(
  fn: (...args: TArgs) => Promise<WrappedApiPayload<TData>>
) => async (...args: TArgs): Promise<TData> => {
  const { data } = await fn(...args);

  return data;
};

export const unwrapGenericJson = <TArgs extends Array<unknown>, TData = any>(
  fn: (...args: TArgs) => Promise<ApiGenericJsonResult<TData>>
) => async (...args: TArgs): Promise<ApiGenericJsonPayload<TData>> => {
  const { data } = await fn(...args);

  return data;
};

/**
 * Unwraps json response which contains { data, success, reason } fields
 */
export const unwrapEntity = <TArgs extends Array<unknown>, TEntity>(
  fn: (...args: TArgs) => ApiEntityResult<TEntity>
): ((...args: TArgs) => Promise<TEntity>) => {
  const verifyPayloadFn = verifyApiEntityResult<TEntity, TArgs>(fn);

  return async (...args: TArgs) => {
    const result = await verifyPayloadFn(...args);
    return result.data.data;
  };
};

/**
 * Unwraps json response which contains { rows, count, success, reason } fields
 */
export const unwrapList = <
  TArgs extends Array<unknown>,
  TRows extends Array<unknown> = []
>(
  fn: (...args: TArgs) => ApiListResult<TRows>
) => async (...args: TArgs): Promise<ApiListPayload<TRows>> => {
  const { data } = await fn(...args);

  return data;
};
