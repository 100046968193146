/* eslint one-var: 0 */
import { getMainRole } from "./roles";
import moment from "moment";

export const uniq = (a) => {
  return [...new Set(a)];
};

export function intersperse(array, intersperseElement) {
  return array.reduce((acc, element, index) => {
    acc.push(element);
    if (index !== array.length - 1) {
      acc.push(intersperseElement);
    }
    return acc;
  }, []);
}

export const isEmailValid = (email) => {
  const MIN_EMAIL_LENGTH = 6,
    re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  if (
    !email ||
    email === "" ||
    email.length < MIN_EMAIL_LENGTH ||
    !re.test(email)
  ) {
    return false;
  }

  return true;
};

export const deepCopy = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const compare = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const keywordsToArray = (str) => {
  return uniq(
    str
      .split(",")
      .map((v) => {
        return v.trim();
      })
      .filter((v) => v)
  );
};

export const keywordsFromArray = (array) => {
  if (array instanceof Array) {
    return array.join(", ");
  }

  return null;
};

export const timestamp = (time) => {
  if (time) {
    return moment(time).format("YYYY-MM-DD HH:mm");
  }

  return "-";
};

export const formatBytes = (a, b) => {
  if (0 === a) {
    return "0 Bytes";
  }
  const c = 1024,
    d = b || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));

  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

export const asyncDelay = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const arrayReorder = (input, fromIndex, toIndex) => {
  const inputCopy = [...input];
  inputCopy.splice(toIndex, 0, inputCopy.splice(fromIndex, 1)[0]);

  return inputCopy;
};

export default {
  uniq,
  isEmailValid,
  deepCopy,
  keywordsToArray,
  keywordsFromArray,
  compare,
  getMainRole,
  timestamp,
  formatBytes,
  asyncDelay,
  arrayReorder
};
