import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import { AclResource } from "../../../shared/acl";
import { useHasAccessFactory } from "../auth/use-has-access";
import { useNavigateTo } from "../routing/routing-hooks";
import { getRouteName } from "../routing/routing-selectors";

import { ReactComponent as IconCaret } from "client/images/caret-alt-right.svg";

export interface MenuItem {
  aclResource: AclResource;
  children?: MenuItem[];
  dataQa?: string;
  href: string;
  title: string;
}

const MenuItem: React.FC<{ item: MenuItem }> = ({ item }): JSX.Element => {
  const routeName = useSelector(getRouteName);

  const [opened, setOpened] = useState(false);

  const { navigateTo } = useNavigateTo();
  const hasAccess = useHasAccessFactory();

  useEffect(() => {
    if (
      item.children &&
      routeName &&
      item.children.some((child) => routeName.includes(child.href))
    ) {
      setOpened(true);
    }
  }, [routeName, item]);

  if (!routeName || !hasAccess(item.aclResource)) {
    return <></>;
  }

  if (!item.children || item.children.length === 0) {
    return (
      <li
        className={cx("submenu__item", {
          "is-active": routeName.includes(item.href)
        })}
        onClick={(event) => {
          event.stopPropagation();
          navigateTo(item.href);
        }}
      >
        <a data-qa={`${item.dataQa}`}>{item.title}</a>
      </li>
    );
  }

  return (
    <li
      className={cx("submenu__item", {
        "is-active": item.children.some((child) =>
          routeName.includes(child.href)
        )
      })}
      data-qa={item.dataQa}
      onClick={(event) => {
        event.stopPropagation();
        setOpened((state) => !state);
        !opened && navigateTo(item.href);
      }}
    >
      <span className={cx({ "is-active": opened })}>
        {item.title}
        <IconCaret />
      </span>
      {opened && (
        <ul>
          {item.children.map((item) => (
            <MenuItem item={item} key={item.title} />
          ))}
        </ul>
      )}
    </li>
  );
};

// Works just for for 2 levels of hierarchy
export const SubMenu: React.FC<{ settings: MenuItem[]; title: string }> = ({
  settings,
  title
}): JSX.Element => {
  return (
    <nav className="submenu">
      <header>
        <h3 data-qa="tab-header" className="submenu__title">
          {title}
        </h3>
      </header>
      <ul>
        {settings.map((item) => (
          <MenuItem item={item} key={item.title} />
        ))}
      </ul>
    </nav>
  );
};
