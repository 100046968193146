import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import { InjectedFormProps, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";

import { TextField } from "../../../forms/text-field";
import { PollableThumbnail } from "./pollable-thumbnail";
import IconAsset from "../../icon-asset";
import { AssetLibraryActions } from "../asset-library-reducer";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

const { AssetType } = require("../../../../../shared/types/asset-type");

export interface UploadAssetConfirmationModalFormData {
  name: string;
}

interface UploadAssetConfirmationModalProps {
  initialValues: { title: string | null };
  assetIndex: number;
  totalAssets: number;
  uuid: string;
  fileVersion: number;
  type: typeof AssetType;
}

const UploadAssetConfirmationModalComponent = (
  props: InjectedFormProps<
    UploadAssetConfirmationModalFormData,
    UploadAssetConfirmationModalProps
  > &
    UploadAssetConfirmationModalProps
) => {
  const {
    uuid,
    fileVersion,
    type,
    handleSubmit,
    assetIndex,
    totalAssets
  } = props;

  const dispatch = useDispatch();

  const saveAsset = useCallback(
    (data: UploadAssetConfirmationModalFormData) => {
      dispatch(AssetLibraryActions.saveAsset({ data }));
    },
    [dispatch]
  );

  const discardAsset = useCallback(
    (uuid: string) => {
      dispatch(AssetLibraryActions.discardAsset({ uuid }));
    },
    [dispatch]
  );

  return createPortal(
    <form className="modal-upload" onSubmit={handleSubmit(saveAsset)}>
      <div className="thumb">
        {[
          AssetType.ASSET_IMAGE,
          AssetType.ASSET_VIDEO,
          AssetType.ASSET_WALL_VIDEO,
          AssetType.ASSET_WALL_IMAGE
        ].includes(type) && (
          <PollableThumbnail key={uuid} uuid={uuid} fileVersion={fileVersion} />
        )}
        {[
          AssetType.ASSET_SOUND,
          AssetType.ASSET_URL,
          AssetType.ASSET_PDF,
          AssetType.ASSET_PRESENTATION
        ].includes(type) && <IconAsset type={type} className="type icon128" />}
        <i className="preload" />
      </div>
      <div className="modalUploadForm">
        <TextField
          name="title"
          label="Name"
          dataQa={FORM_LOCATORS.inputs.getInput("title")}
        />
        <div className="modalButtons">
          {totalAssets > 1 && (
            <span className="assets-count">
              {assetIndex + 1} of {totalAssets}
            </span>
          )}
          <button
            className="button buttonAlert buttonOutlined"
            type="button"
            onClick={() => discardAsset(uuid)}
          >
            Discard
          </button>
          <button
            className="button"
            type="submit"
            data-qa={MODAL_LOCATORS.buttons.submit}
          >
            {["Save", totalAssets > assetIndex + 1 && "Next"]
              .filter(Boolean)
              .join(" & ")}
          </button>
        </div>
      </div>
    </form>,
    document.getElementById("modal") as HTMLElement
  );
};

export const UploadAssetConfirmationModal = reduxForm<
  UploadAssetConfirmationModalFormData,
  UploadAssetConfirmationModalProps
>({
  form: "upload-asset-confirmation",
  enableReinitialize: true
})(UploadAssetConfirmationModalComponent);
