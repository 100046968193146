const WARNING_COUNT_LIMIT = 1000;

export const isAllowedDomainInCorrectFormat = (domain: string) => {
  return !!/^@([A-Z]|[a-z]|[0-9]|-)+\.([A-Z]|[a-z]|[0-9])+$/.exec(
    domain.trim()
  );
};

export const getPingLimiter = (limitCount = WARNING_COUNT_LIMIT) => {
  let pingCount = 0;

  return {
    resetCount: () => {
      pingCount = 0;
    },
    limitPinging: (callback: () => void) => {
      if (pingCount < limitCount) {
        callback();
        pingCount++;
      }
    }
  };
};
