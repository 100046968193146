import { createSelector } from "reselect";

import { getRouting as getState } from "client/modules/root/root-selectors";
import { RootState } from "../root/root-reducer";
import { startsWithSegment } from "router5-helpers";
import { WALLS_LIST_ROUTE } from "./route-names";

export type RouteParamSelector<Entity> = (state: RootState) => Partial<Entity>;

export const getRouteName = createSelector(
  getState,
  (state) => state.route && state.route.name
);

export const getRouteParams: unknown = createSelector(getState, (state) =>
  state.route ? state.route.params : {}
);

export const getPreviousRouteName = createSelector(
  getState,
  (state) => state.previousRoute && state.previousRoute.name
);

export const getActiveTab = createSelector(
  getRouteParams as RouteParamSelector<{ tab: string }>,
  (routeParams) => routeParams.tab
);

export const isWallsPageActive = createSelector(getRouteName, (routeName) => {
  const endsWith = startsWithSegment(routeName || "");
  return endsWith(WALLS_LIST_ROUTE);
});
