import { UserTrackingEvent } from "./user-tracking-event";
import { AssetType } from "shared/types/asset-type";

interface SceneData {
  title: string;
}

const getAccountType = (isGuestDomainAccount: boolean) =>
  isGuestDomainAccount ? "Guest" : "Paid";

const MetadataKeys = {
  EntryPoint: "Entry Point"
};

export const trackUser = (isGeustDomainAccount: boolean) => {
  return {
    "Company Type": getAccountType(isGeustDomainAccount)
  };
};

export const trackSceneCreated = (
  entryPoint: string,
  tab: string,
  sceneData: SceneData,
  title: string,
  defaultScene?: boolean,
  autoSwitch?: boolean,
  collaborators?: boolean
) => {
  return {
    eventName: UserTrackingEvent.SceneCreated,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      Library: tab,
      "Scene Name": title,
      "Table position": Boolean(sceneData.title),
      Default: defaultScene,
      "Auto Switch": autoSwitch,
      Collaborators: collaborators
    }
  };
};

export const trackPresentationCreated = (
  entryPoint: string,
  tab: string,
  data: {
    title: string;
    pinProtected: boolean;
    blackbelt: boolean;
    instances: string[];
  }
) => {
  return {
    eventName: UserTrackingEvent.PresentationCreated,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      Library: tab,
      "Presentation name": data.title,
      "Pin protection": Boolean(data.pinProtected),
      "Remote access": data.blackbelt,
      "# Instances selected": data.instances.length
    }
  };
};

export const trackProfileUpdated = (
  data: {
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
  },
  isGuestDomainAccount: boolean
) => {
  return {
    eventName: UserTrackingEvent.UserProfileUpdated,
    metadata: {
      Name: `${data.firstName} ${data.lastName}`,
      Email: `${data.email}`,
      "Account Type": getAccountType(isGuestDomainAccount),
      "User Role": data.roles.join(", ")
    }
  };
};

export const trackPageView = (url: string, title: string) => {
  return {
    eventName: UserTrackingEvent.PageView,
    metadata: {
      Url: url,
      "Page Title": title
    }
  };
};

export const trackSceneChangeRequested = (entryPoint: string, tab?: string) => {
  return {
    eventName: UserTrackingEvent.SceneChangeRequested,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      Library: tab
    }
  };
};

export const trackSceneChange = (
  entryPoint: string,
  sceneData: SceneData,
  tab?: string
) => {
  return {
    eventName: UserTrackingEvent.SceneChanged,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      Library: tab,
      "Table position": Boolean(sceneData.title)
    }
  };
};

export const trackEnterSceneEditor = (entryPoint: string) => {
  return {
    eventName: UserTrackingEvent.SceneEditorEnter,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint
    }
  };
};

export const trackAssetUploaded = (
  entryPoint: string,
  assetType: AssetType,
  roles: string[],
  tab?: string
) => {
  return {
    eventName: UserTrackingEvent.AssetUploaded,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      "Media Type": assetType,
      "User role": roles.join(", "),
      Library: tab
    }
  };
};

export const trackSceneEditorSceneAssetAdded = (
  assetType: AssetType,
  roles: string[],
  sceneName: string,
  tab?: string
) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetAdded,
    metadata: {
      "Media Type": assetType,
      "User role": roles.join(", "),
      "Scene Name": sceneName,
      Library: tab
    }
  };
};

export const trackAssetPreviewed = (
  entryPoint: string,
  assetType: AssetType,
  roles: string[],
  tab?: string
) => {
  return {
    eventName: UserTrackingEvent.AssetPreviewed,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      "Media Type": assetType,
      "User role": roles.join(", "),
      Library: tab
    }
  };
};

export const trackAssetDeleted = (
  entryPoint: string,
  assetType: AssetType,
  roles: string[]
) => {
  return {
    eventName: UserTrackingEvent.AssetDeleted,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      "Media Type": assetType,
      "User role": roles.join(", ")
    }
  };
};

export const trackSceneEditorSceneAssetDetached = (
  assetType: AssetType,
  roles: string[]
) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetDetached,
    metadata: {
      [MetadataKeys.EntryPoint]: "Scene Editor",
      "Media Type": assetType,
      "User role": roles.join(", ")
    }
  };
};

export const trackSceneEditorAssetSearched = (
  entryPoint: string,
  searchCompleted: boolean
) => {
  return {
    eventName: UserTrackingEvent.SceneEditorAssetSearched,
    metadata: {
      [MetadataKeys.EntryPoint]: entryPoint,
      "Search Completed": searchCompleted
    }
  };
};

export const trackSceneEditorBackgroundChangeLoop = (value: boolean) => {
  return {
    eventName: UserTrackingEvent.SceneEditorBackgroundChangeLoop,
    metadata: {
      Status: value
    }
  };
};

export const trackSceneEditorBackgroundChangeAutoplay = (value: boolean) => {
  return {
    eventName: UserTrackingEvent.SceneEditorBackgroundChangeAutoplay,
    metadata: {
      Status: value
    }
  };
};

export const trackSceneEditorTransitionChangeDuration = (value: number) => {
  return {
    eventName: UserTrackingEvent.SceneEditorTransitionChangeDuration,
    metadata: {
      "Duration value": value
    }
  };
};

export const trackSceneEditorSceneAssetWallChanged = (wallChanged: boolean) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetWallChanged,
    metadata: {
      "Wall Changed": wallChanged
    }
  };
};

export const trackSceneEditorSceneAssetMoved = () => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetMoved,
    metadata: {
      Repositioned: true
    }
  };
};

export const trackSceneEditorSceneAssetPositionLocked = () => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetPositionLocked,
    metadata: {
      Locked: true
    }
  };
};

export const trackSceneEditorSceneAssetGlowUpdated = (value: boolean) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetGlowUpdated,
    metadata: {
      Status: value
    }
  };
};

export const trackSceneEditorSceneAssetRoundedCornersUpdated = (
  value: boolean
) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetRoundedCornersUpdated,
    metadata: {
      Status: value
    }
  };
};

export const trackSceneEditorSceneAssetVisibleOnStartUpdated = (
  value: boolean
) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetVisibleOnStartUpdated,
    metadata: {
      "Visible on start": value
    }
  };
};

export const trackSceneEditorSceneAssetScaleUpdated = (value: number) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetScaleUpdated,
    metadata: {
      Scale: value
    }
  };
};

export const trackSceneEditorSceneAssetOpacityUpdated = (value: number) => {
  return {
    eventName: UserTrackingEvent.SceneEditorSceneAssetOpacityUpdated,
    metadata: {
      Scale: value
    }
  };
};

export const trackSceneEditorSnapToGridUpdated = (value: number) => {
  return {
    eventName: UserTrackingEvent.SceneEditorGridSnapToGridUpdated,
    metadata: {
      Status: value
    }
  };
};

export const trackSceneEditorOnClickActionsOpened = (sceneName: string) => {
  return {
    eventName: UserTrackingEvent.SceneEditorOnClickActionsOpened,
    metadata: {
      "Scene Name": sceneName
    }
  };
};

export const trackSceneEditorOnClickActionsAssigned = (
  type: string,
  assetsCount: number
) => {
  return {
    eventName: UserTrackingEvent.SceneEditorOnClickActionsAssigned,
    metadata: {
      "Assigned Element": type,
      "# assigned media": assetsCount
    }
  };
};

export const trackSceneEditorOnClickActionsSearched = (assigned: boolean) => {
  return {
    eventName: UserTrackingEvent.SceneEditorOnClickActionsSearched,
    metadata: {
      "Search Completed": assigned
    }
  };
};

export const trackSceneEditorAssetResized = () => {
  return {
    eventName: UserTrackingEvent.SceneEditorAssetResized,
    metadata: {}
  };
};

export const trackSceneEditorAssetCropped = () => {
  return {
    eventName: UserTrackingEvent.SceneEditorAssetCropped,
    metadata: {}
  };
};

export const trackSceneDetachedFromPresentation = (collaborators: boolean) => {
  return {
    eventName: UserTrackingEvent.SceneDetached,
    metadata: {
      Collaborators: collaborators
    }
  };
};

export const trackUserAdded = (company?: string) => {
  return {
    eventName: UserTrackingEvent.UserAdded,
    metadata: {
      "Company account": company
    }
  };
};

export const trackManualOpened = (url: string) => {
  return {
    eventName: UserTrackingEvent.ManualOpened,
    metadata: {
      [MetadataKeys.EntryPoint]: url
    }
  };
};

export const trackSceneEditorBackgroundAdded = (
  assetType: AssetType,
  sceneName: string
) => {
  return {
    eventName: UserTrackingEvent.SceneEditorBackgroundAdded,
    metadata: {
      "Media Type": assetType,
      "Scene Name": sceneName
    }
  };
};

export const trackSceneEditorBackgrounDetached = () => {
  return {
    eventName: UserTrackingEvent.SceneEditorBackgroundDetached,
    metadata: {}
  };
};

export const trackPresentationPublished = (published: boolean) => {
  return {
    eventName: UserTrackingEvent.PresentationPublished,
    metadata: { published }
  };
};

export const trackOnlineTestRunRequested = (instance: string) => {
  return {
    eventName: UserTrackingEvent.OnlineTestRunRequested,
    metadata: { instance }
  };
};

export const trackOnlineSessionScheduleRequested = () => {
  return {
    eventName: UserTrackingEvent.OnlineSessionScheduleRequested,
    metadata: {}
  };
};

export const trackFirstLogin = (
  loginDate: string,
  isGuestDomain: boolean,
  guestDomain: string
) => {
  return {
    eventName: UserTrackingEvent.FirstLogin,
    metadata: {
      "Company Account": guestDomain,
      "Account Type": isGuestDomain ? "Guest" : "Paid",
      "Login Date": loginDate
    }
  };
};
