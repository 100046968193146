import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { reduxForm } from "redux-form";

import * as RoutingSelectors from "../../routing/routing-selectors";
import AuthActions from "../auth-actions";
import { TextField } from "../../forms/text-field";
import { PasswordField } from "../../forms/password-field";
import { getProgressSelector } from "../../in-progress/in-progress-selectors";
import {
  AUTH_LOGIN_ROUTE,
  AUTH_NEW_PASSWORD_DONE_ROUTE
} from "../../routing/route-names";
import { RouteParamSelector } from "../../routing/routing-selectors";
import { useNavigateTo } from "../../routing/routing-hooks";
import { useNotifications } from "../../ux/notifications/use-notifications";
import { useCreateDomainAdminPassword } from "../hooks/use-create-domain-admin-password";
import { AuthPageShell } from "./auth-page-shell";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

interface FormData {
  password: {
    isValid: boolean;
    password: string;
  };
  passwordCheck: string;
}

type FormError = Partial<Record<keyof FormData, string>>;

const validate = ({ password, passwordCheck }: FormData) => {
  const err: FormError = {};

  if (!password || !password.isValid) {
    err.password = "Password is not secure enough";
  }

  if (password && password.password !== passwordCheck) {
    err.passwordCheck = "Passwords must match";
  }

  return err;
};

const NewPasswordForm = reduxForm<FormData, {}>({
  form: "AUTH_NEW_PASSWORD",
  validate
})(({ anyTouched, invalid, handleSubmit }) => {
  const setInProgress = useSelector(getProgressSelector)(
    AuthActions.Types.RESET_PASSWORD
  );
  const { domain } = useSelector(
    RoutingSelectors.getRouteParams as RouteParamSelector<{
      domain?: string;
    }>
  );

  const createGuestDomainPassword = useCreateDomainAdminPassword();

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    ({ password }: FormData) => {
      if (password && domain) {
        createGuestDomainPassword(password.password);
      } else if (password) {
        dispatch(AuthActions.Creators.resetPassword(password.password));
      }
    },
    [dispatch, createGuestDomainPassword, domain]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PasswordField
        name="password"
        label="New password"
        dataQa={FORM_LOCATORS.inputs.getInput("password")}
      />
      <TextField
        name="passwordCheck"
        type="password"
        label="Confirm password"
        dataQa={FORM_LOCATORS.inputs.getInput("confirm-password")}
      />
      <button
        disabled={setInProgress || (anyTouched && invalid)}
        className={cx("button", {
          inProgress: setInProgress
        })}
        type="submit"
        data-qa={FORM_LOCATORS.buttons.getButton("submit")}
      >
        Reset password
      </button>
    </form>
  );
});

const ErrorMessage = ({ error }: { error: string }) => (
  <div className="flash-message flash-message-error" data-qa="flash-message">
    <div className="flash-message-text" data-qa="flash-message-error">
      {error === "1" ? "Invalid token." : "An error occurred."}
    </div>
  </div>
);

export const AuthSelectNewPassword = () => {
  const routeName = useSelector(RoutingSelectors.getRouteName);
  const { token, error, domain } = useSelector(
    RoutingSelectors.getRouteParams as RouteParamSelector<{
      token?: string;
      error?: string;
      domain?: string;
    }>
  );

  const { navigateTo } = useNavigateTo();
  const { notifySuccess } = useNotifications();

  const goToLogin = useCallback(() => {
    navigateTo(AUTH_LOGIN_ROUTE, {});
  }, [navigateTo]);

  const hasError = !token || error;

  if (routeName === AUTH_NEW_PASSWORD_DONE_ROUTE) {
    return (
      <AuthPageShell className="login-passwordConfirm">
        <h1>Reset Password</h1>
        <p>Your password has been changed.</p>
        <button type="button" className="button" onClick={goToLogin}>
          Go to Login page
        </button>
      </AuthPageShell>
    );
  }

  if (hasError) {
    return (
      <AuthPageShell className="login-passwordForm">
        <h1>Reset Password</h1>
        <ErrorMessage error={error || "1"} />
      </AuthPageShell>
    );
  }

  if (domain) {
    notifySuccess(
      "You’re account has been verified! Please create a password.",
      {
        autoClose: false
      }
    );
    return (
      <AuthPageShell className="login-passwordForm">
        <h1>Create New Password</h1>
        <NewPasswordForm />
      </AuthPageShell>
    );
  }

  return (
    <AuthPageShell className="login-passwordForm">
      <h1>Reset Password</h1>

      <NewPasswordForm />
    </AuthPageShell>
  );
};
