import { useDispatch, useSelector } from "react-redux";
import {
  getRouteName,
  getRouteParams,
  RouteParamSelector
} from "./routing-selectors";
import { useCallback, useRef } from "react";
import { actions as routerActions } from "redux-router5";

export const useChangeRouteParam = <T>(
  paramName: string
): { navigate: (paramName: string) => void; param: T | undefined } => {
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{ [key: string]: T }>
  );
  const routeName = useSelector(getRouteName);

  const dispatch = useDispatch();

  const navigate = useCallback(
    (tabName) => {
      if (routeName) {
        dispatch(
          routerActions.navigateTo(
            routeName,
            {
              ...routeParams,
              [paramName]: tabName
            },
            { replace: true }
          )
        );
      }
    },
    [routeName, routeParams, dispatch, paramName]
  );

  return { navigate, param: routeParams[paramName] };
};

export const useNavigateTo = () => {
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<unknown>
  );
  const routeName = useSelector(getRouteName);

  const dispatch = useDispatch();

  const navigateTo = useCallback(
    (
      routeName: string,
      paramDictionary: { [key: string]: string | number } = {},
      options = {}
    ) => {
      if (routeName) {
        dispatch(
          routerActions.navigateTo(
            routeName,
            {
              ...routeParams,
              ...paramDictionary
            },
            options
          )
        );
      }
    },
    [routeParams, dispatch]
  );

  return {
    navigateTo,
    routeParams,
    routeName
  };
};

export const useReplaceUrl = () => {
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<unknown>
  );
  const routeName = useSelector(getRouteName);

  const dispatch = useDispatch();

  const replaceUrl = useCallback(
    (
      routeName: string,
      paramDictionary: { [key: string]: string | number } = {},
      options = { replace: true }
    ) => {
      if (routeName) {
        dispatch(
          routerActions.navigateTo(
            routeName,
            {
              ...routeParams,
              ...paramDictionary
            },
            options
          )
        );
      }
    },
    [routeParams, dispatch]
  );

  return {
    replaceUrl,
    routeParams,
    routeName
  };
};

export const useNavigateToWithDelay = (): ((
  routeName: string,
  paramDictionary: { [key: string]: string },
  delay: number
) => void) => {
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<unknown>
  );

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useDispatch();

  return useCallback(
    (
      routeName: string,
      paramDictionary: { [key: string]: string } = {},
      delay: number
    ) => {
      if (routeName) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          dispatch(
            routerActions.navigateTo(
              routeName,
              {
                ...routeParams,
                ...paramDictionary
              },
              { replace: true }
            )
          );
        }, delay);
      }
    },
    [routeParams, dispatch]
  );
};

export const goBack = () => window.history.back();
