import { Logger as logger } from "purplex-logging";
import { createSelector } from "reselect";
import { getMedia as getState } from "../root/root-selectors";
import { getAssets } from "../entity-repository/entity-repository-selectors";
import { ASSETS_ROUTE, WALLS_ROUTE } from "../routing/route-names";
import { getRouteParams, getRouteName } from "../routing/routing-selectors";
import { getAssetThumbnailBig } from "./get-asset-thumbnail";
import {
  getCdnHost,
  getUsesSapTransformations
} from "../config/config-selectors";
import { UploadStatus } from "./upload-statuses";
import { MediaDialogType } from "./components/media-dialog/media-dialog-type";
import { getInstances } from "../entity-repository/entity-repository-selectors";
import {
  getAssetCustomFieldMappings,
  customFieldMappingsToFiltersConfig
} from "../settings/custom-fields/custom-fields-selectors";
import { customFieldsToEntity } from "../settings/custom-fields/components/filters/filters-block";
import { ChoiceLogicImplementations } from "../ux/filters/choice-logic-implementations";
import * as Api from "../api/client";
import { getHumanReadableAssetTypeName } from "./asset-types";

const { AssetType } = require("../../../shared/types/asset-type");

export const getDialogType = createSelector(
  getRouteName,
  getRouteParams,
  (routeName, routeParams) => {
    if (routeName) {
      if (routeName.startsWith(WALLS_ROUTE)) {
        return MediaDialogType.WALL_ASSET;
      } else if (routeName.startsWith(ASSETS_ROUTE)) {
        if (routeParams.tab === "my") {
          return MediaDialogType.ASSET;
        } else {
          return MediaDialogType.SHARED_ASSET;
        }
      }
    }

    return null;
  }
);

export const isDialogShown = createSelector(getState, (state) =>
  Boolean(state.dialogShown)
);

export const getUploadStates = createSelector(
  getState,
  (state) => state.uploadStates
);

export const getUploadQueue = createSelector(
  getState,
  getUploadStates,
  getAssets,
  (state, uploadState, assets) =>
    state.uploadQueue.map((uuid) => ({
      upload: uploadState[uuid],
      asset: assets[uuid]
    }))
);

export const getCurrentNumberOfUploads = createSelector(
  getUploadQueue,
  (queue) =>
    queue.filter(({ upload: { status } }) => status === UploadStatus.UPLOADING)
      .length
);

export const getNextUpload = createSelector(
  getUploadQueue,
  (queue) =>
    queue.find(({ upload: { status } }) => status === UploadStatus.PENDING) ||
    null
);

export const getUploadsInDialog = createSelector(
  getState,
  getAssets,
  getUploadStates,
  (state, assets, uploadStates) =>
    state.assetsInDialog.map((assetUuid) => ({
      upload: uploadStates[assetUuid] || null,
      asset: assets[assetUuid]
    }))
);

export const getSelectedAsset = createSelector(
  getState,
  getAssets,
  (state, assets) => assets[state.selectedAsset] || null
);

export const getUploadingProgress = createSelector(getUploadQueue, (queue) => {
  const sum = (a, b) => a + b;

  const totalSize = queue.map(({ upload: { size } }) => size).reduce(sum, 0);
  const uploadedSize = queue
    .map(({ upload: { uploaded, status, size } }) =>
      status === UploadStatus.UPLOADING || status === UploadStatus.PENDING
        ? uploaded
        : // if an asset in queue is in any other state, than we consider as
          // finished, then it counts as 100% done to the overall progress
          size
    )
    .reduce(sum, 0);

  if (uploadedSize === 0) {
    return null;
  } else {
    return Math.min(Math.round((uploadedSize / totalSize) * 100), 100);
  }
});

export const enableAlternativeBackgroundAssets = createSelector(
  getInstances,
  getUsesSapTransformations,
  (instances, isUsingSapTransformations) =>
    Object.keys(instances || {}).length > 1 && isUsingSapTransformations
);

export const getMediaEditInitialValues = createSelector(
  getRouteParams,
  getAssets,
  getCdnHost,
  enableAlternativeBackgroundAssets,
  (routeParams, assets, cdnHost, alternativeBackgroundsEnabled) => {
    if (routeParams.uuid && assets[routeParams.uuid]) {
      let asset = assets[routeParams.uuid];
      asset = {
        ...asset,
        ...customFieldsToEntity(asset.customFields)
      };

      if (asset.type === AssetType.ASSET_URL) {
        return {
          ...asset,
          url: asset.metadata.url
        };
      } else {
        const result = {
          ...asset,
          files: [
            { file: getAssetThumbnailBig(asset, cdnHost), uuid: asset.uuid }
          ]
        };

        if (
          (asset.type === AssetType.ASSET_WALL_IMAGE ||
            asset.type === AssetType.ASSET_WALL_VIDEO) &&
          alternativeBackgroundsEnabled
        ) {
          result.files.push(
            ...asset.alternativeAssets.map((alternativeAsset) => ({
              file: getAssetThumbnailBig(alternativeAsset, cdnHost),
              uuid: alternativeAsset.uuid
            })),
            { file: null, uuid: null }
          );
        }

        return result;
      }
    } else {
      return undefined;
    }
  }
);

export const getPreviewAsset = createSelector(
  getState,
  getAssets,
  ({ previewAssetUuid }, assets) => assets[previewAssetUuid] || null
);

export const getInfiniteScrollLoadingKey = createSelector(
  getState,
  (state) => state.infiniteScrollLoadingKey
);

//TODO this is not nice :(
export const isSharedMediaTab = createSelector(
  getRouteParams,
  ({ tab, libraryTab }) => tab === "shared" || libraryTab === "shared"
);

export const getAssetFiltersConfig = createSelector(
  getAssetCustomFieldMappings,
  isSharedMediaTab,
  (assetCustomFieldMappings, sharedMediaTabActive) => {
    let result = [];
    if (sharedMediaTabActive) {
      result = customFieldMappingsToFiltersConfig(assetCustomFieldMappings);
    }

    return [
      {
        id: "type",
        label: "Media Type",
        choiceLogic: ChoiceLogicImplementations.SELECT_MULTIPLE,
        choices: async (
          searchPhrase,
          selectedFilters,
          globalSearch,
          additionalFilterData,
          category
        ) => {
          try {
            const data = await Api.searchAssetTypesFilter(
              searchPhrase,
              selectedFilters,
              globalSearch,
              additionalFilterData,
              category
            );
            return {
              ...data,
              rows: data.rows.map((row) => ({
                ...row,
                label: getHumanReadableAssetTypeName(row.label)
              }))
            };
          } catch (ex) {
            logger.error(ex);

            return {
              count: 0,
              rows: []
            };
          }
        }
      },
      ...result
    ];
  }
);

export const getLastEditedSharedAsset = createSelector(
  getState,
  (state) => state.lastEditedSharedAsset
);
