import React from "react";
import { useDragLayer } from "react-dnd";
import { CustomFieldRow } from "./lists/custom-fields-filter-list";
import { useSelector } from "react-redux";
import { getAllCustomFields } from "../custom-fields-selectors";
import { CustomField } from "../custom-field-types";

const noop = () => {};

export const CustomFieldDragLayer = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));

  const customFields: CustomField[] = useSelector(getAllCustomFields);
  const field = customFields.find(
    (customField) => item && customField.id === item.id
  );

  return (
    <div
      className="customFieldsDragLayer page-custom-fields"
      style={{ zIndex: isDragging ? 100 : 0 }}
    >
      {isDragging && field && (
        <div
          style={{
            transform: `translate(${currentOffset ? currentOffset.x : 0}px, ${
              currentOffset ? currentOffset.y : 0
            }px)`,
            zIndex: 1000,
            position: "relative"
          }}
        >
          <CustomFieldRow
            customField={field}
            onDelete={noop}
            onEdit={noop}
            isDragging={true}
          />
        </div>
      )}
    </div>
  );
};
