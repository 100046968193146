const { AssetType } = require("../../../shared/types/asset-type");

export const {
  ASSET_IMAGE,
  ASSET_VIDEO,
  ASSET_SOUND,
  ASSET_URL,
  ASSET_PDF,
  ASSET_PRESENTATION,
  ASSET_WALL_IMAGE,
  ASSET_WALL_VIDEO
} = AssetType;

export const ASSET_TYPES = [
  ASSET_IMAGE,
  ASSET_VIDEO,
  ASSET_SOUND,
  ASSET_URL,
  ASSET_PDF,
  ASSET_PRESENTATION
];

export const CREATABLE_ASSET_TYPES = [
  ASSET_IMAGE,
  ASSET_VIDEO,
  ASSET_SOUND,
  ASSET_URL,
  ASSET_PDF,
  ASSET_PRESENTATION
];

export const getCreateabeAssetTypes = (disabledAssetTypes) =>
  CREATABLE_ASSET_TYPES.filter(
    (assetType) => !disabledAssetTypes.includes(assetType)
  );

const HUMAN_READABLE_ASSET_TYPE_NAMES = {
  [ASSET_IMAGE]: "Image",
  [ASSET_VIDEO]: "Video",
  [ASSET_SOUND]: "Sound",
  [ASSET_URL]: "Link",
  [ASSET_PDF]: "PDF",
  [ASSET_PRESENTATION]: "Presentation",
  [ASSET_WALL_IMAGE]: "Image",
  [ASSET_WALL_VIDEO]: "Video"
};

export const getHumanReadableAssetTypeName = (assetType) =>
  HUMAN_READABLE_ASSET_TYPE_NAMES[assetType] || String(assetType);

const mediaString = "Media";
const backgroundString = "Background";

const HUMAN_READABLE_ASSET_TYPE_GROUP_NAME = {
  [ASSET_IMAGE]: mediaString,
  [ASSET_VIDEO]: mediaString,
  [ASSET_SOUND]: mediaString,
  [ASSET_URL]: mediaString,
  [ASSET_PDF]: mediaString,
  [ASSET_PRESENTATION]: mediaString,
  [ASSET_WALL_IMAGE]: backgroundString,
  [ASSET_WALL_VIDEO]: backgroundString
};

export const getHumanReadableAssetTypeGroupName = (assetType) =>
  HUMAN_READABLE_ASSET_TYPE_GROUP_NAME[assetType] || "";

export default ASSET_TYPES;
