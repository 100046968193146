import { createActions } from "reduxsauce";

export default createActions(
  {
    addPresentation: ["data"],
    updatePresentation: ["data"],
    requestDeletePresentation: ["id", "title"],
    requestSharePresentation: ["id", "title"],
    addSceneConnection: ["presentationId", "outboundSceneId", "inboundSceneId"],
    deleteSceneConnection: ["connectionId"],
    updateSceneConnection: ["connectionId", "updateData"],
    createScene: ["data"],
    editScene: ["data"],
    deleteScene: ["scene"],
    attachScene: ["sceneId"],
    detachScene: ["sceneId"],
    shareScene: ["sceneId", "title"],
    incrementPresentationListVersion: null,
    requestPublishPresentation: ["id", "title"],
    deletePresentationShare: ["id", "title"],
    publishPresentation: ["id", "title"],
    unpublishPresentation: ["id", "title"],
    clonePresentation: ["id", "title"],
    changeSceneOrder: ["sceneId", "order"],
    dryRunPresentation: ["id"],
    planVixSession: ["id"],
    requestEditScene: ["sceneId"]
  },
  { prefix: "PRESENTATIONS/" }
);
