import { ReactComponent as IconCircleWarning } from "../../../../images/circle-warning.svg";
import React from "react";

export const WarningStatus = ({ message }: { message: string }) => (
  <span
    className="wall-dimension-status warning"
    title={message}
    data-qa="wall-dimension-status-warning"
  >
    <IconCircleWarning className="icon icon-circle-warning icon20" />
  </span>
);
