import { DragSource, DropTarget } from "react-dnd";

const dragEventHandlers = () => ({
  beginDrag: (props) => props
});

const dragStateToProps = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  dndIsDragging: monitor.isDragging()
});

const dropEventHandlers = (params) => ({
  canDrop(targetProps, monitor) {
    return monitor.getItem().dndId !== targetProps.dndId;
  },
  drop(hoverProps, monitor, component) {
    if (!component) {
      return null;
    }

    if (params.onDndMove) {
      const draggedProps = monitor.getItem();
      params.onDndMove(draggedProps, hoverProps);
    }
  }
});

const dropStateToProps = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  dndIsOver: monitor.isOver(),
  dndDraggedProps: monitor.getItem()
});

export const withSortableDnd = (params, ItemComponent) => {
  const draggable = DragSource(
    params.dndIdentifier,
    dragEventHandlers(params),
    dragStateToProps
  )(ItemComponent);

  const droppable = DropTarget(
    params.dndIdentifier,
    dropEventHandlers(params),
    dropStateToProps
  )(draggable);

  return droppable;
};
