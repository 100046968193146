import { createReducer } from "reduxsauce";

import Actions from "./in-progress-actions";

const INITIAL_STATE = {};

const setProgress = (
  state,
  { progressKey, entityIdentification, progressState }
) => ({
  ...state,
  [progressKey]: {
    ...state[progressKey],
    [entityIdentification]: progressState
  }
});

export default createReducer(INITIAL_STATE, {
  [Actions.Types.SET_PROGRESS]: setProgress
});
