import React, { useCallback, useMemo } from "react";
import { Logger as logger } from "purplex-logging";
import { createPortal } from "react-dom";
import {
  FieldArray,
  getFormValues,
  reduxForm,
  WrappedFieldArrayProps
} from "redux-form";

import { ModalTemplate } from "../../ux/modal";
import { SelectTriggerField } from "client/modules/forms/select-trigger-field";
import { AsyncSelectField } from "client/modules/forms/async-select-field";
import { useSelector } from "react-redux";
import { getPresentation } from "client/modules/presentations/presentation-selectors";
import { useApi } from "client/modules/api/use-api";
import { useNormalizeAndStore } from "client/modules/scene-editor/hooks/use-normalize-and-store";
import { presentation as presentationSchema } from "../../entity-repository/schema";
import { goBack } from "client/modules/routing/routing-hooks";
import { useNotifications } from "client/modules/ux/notifications/use-notifications";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";

import { ReactComponent as IconAdd } from "client/images/plus.svg";
import { ReactComponent as IconDelete } from "client/images/cross-alt.svg";

const FORM_NAME = "triggers-management";

export interface TriggersManagementFormProps {
  triggers: {
    trigger?: { id: number; instanceUuid: string };
    scene?: { id: number; title: string };
  }[];
}

const closeModal = () => window.history.back();

const RenderTrigger = ({ fields }: WrappedFieldArrayProps) => {
  const presentation = useSelector(getPresentation);
  const sceneOptions = useMemo(
    () =>
      presentation
        ? presentation.presentationScenes.map(({ scene: { id, title } }) => ({
            id,
            title
          }))
        : [],
    [presentation]
  );

  const values = useSelector(
    getFormValues(FORM_NAME)
  ) as TriggersManagementFormProps;

  return (
    <>
      <div className="grid">
        {fields.map((field, index) => {
          const selectedTrigger = values.triggers[index].trigger;
          const selectedScene = values.triggers[index].scene;

          const isDuplicate = values.triggers.some((item, i) => {
            return (
              i !== index &&
              item.scene &&
              item.trigger &&
              selectedScene &&
              selectedTrigger &&
              selectedScene.id === item.scene.id &&
              selectedTrigger.instanceUuid === item.trigger.instanceUuid
            );
          });

          return (
            <div key={field} data-qa={`assign-triggers-row-${index}`}>
              <div>
                <button
                  type="button"
                  data-qa="assign-triggers-remove-button"
                  className="button buttonIcon buttonOutlined buttonAlert deleteTrigger"
                  onClick={() => fields.remove(index)}
                >
                  <IconDelete />
                </button>
              </div>
              <div>
                <SelectTriggerField
                  name={`${field}.trigger`}
                  dataQa="assign-triggers-trigger-field"
                  instanceUuids={presentation.instances}
                  filterOption={(trigger) => {
                    const isUnique = !values.triggers.some(
                      (selectedTriggerOption) =>
                        selectedTriggerOption.trigger &&
                        selectedTriggerOption.trigger.id === trigger.id
                    );

                    return isUnique;
                  }}
                />
                {isDuplicate && <>Combination needs to be unique.</>}
              </div>
              <div>
                <AsyncSelectField
                  async={false}
                  dataQa="assign-triggers-scene-field"
                  name={`${field}.scene`}
                  placeholder="Select a scene"
                  options={sceneOptions}
                  isClearable={false}
                  getOptionLabel={(option: typeof sceneOptions[0]) =>
                    option.title
                  }
                  getOptionValue={(option: typeof sceneOptions[0]) => option.id}
                />
              </div>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        className="button buttonOutlined addTrigger"
        data-qa="assign-triggers-add-another-row-button"
        onClick={() => fields.push({})}
      >
        <IconAdd />
        Add Trigger
      </button>
    </>
  );
};

const validate = (values: TriggersManagementFormProps) => {
  const errors: { [key: string]: any } = {};

  errors.triggers = (values.triggers || []).map(({ trigger, scene }) => {
    const triggerErrors: { [key: string]: string } = {};

    if (!trigger && scene) {
      triggerErrors.trigger = "Trigger is required";
    }

    if (!scene && trigger) {
      triggerErrors.scene = "Scene is required";
    }

    return triggerErrors;
  });

  return errors;
};

const TriggersManagementForm = reduxForm({
  form: FORM_NAME,
  validate
})(({ handleSubmit }) => {
  const presentation = useSelector(getPresentation);
  const normalizeAndStore = useNormalizeAndStore();
  const api = useApi();
  const notifications = useNotifications();

  const saveTriggers = useCallback(
    async (data: TriggersManagementFormProps) => {
      try {
        const updatedPresentation = await api.updatePresentationTriggers(
          presentation.id,
          {
            ...data,
            triggers: data.triggers.filter(
              (trigger) => trigger.scene && trigger.trigger
            )
          }
        );
        normalizeAndStore(updatedPresentation, presentationSchema);
        notifications.notifySuccess("Triggers have been sucesfully changed.");
      } catch (ex) {
        logger.error("Error while updating triggers", ex);
        notifications.notifyError(ex.message);
      } finally {
        goBack();
      }
    },
    [api, normalizeAndStore, presentation, notifications]
  );

  return (
    <form onSubmit={handleSubmit(saveTriggers)}>
      <FieldArray name="triggers" component={RenderTrigger} />
      <div className="modalFooter">
        <button
          type="submit"
          className="button"
          data-qa="assign-triggers-save-button"
        >
          Save
        </button>
      </div>
    </form>
  );
});

const TriggersModal = () => {
  const presentation = useSelector(getPresentation);
  const triggersInitialValues = useMemo(() => {
    if (presentation) {
      const triggers = [...presentation.presentationSceneTriggers];
      triggers.sort((a, b) => a.id - b.id);

      return {
        triggers
      };
    } else {
      return {};
    }
  }, [presentation]);

  useEscapeHook(true, closeModal);

  return (
    <ModalTemplate
      title="Assign Triggers"
      className="triggersModal has-footer primary is-bleeding"
      onCloseClick={closeModal}
      footer={null}
      enableBackdropClick={false}
      headerProps={undefined}
      contentProps={undefined}
      closeButtonProps={undefined}
      hideCloseButton={false}
    >
      <TriggersManagementForm
        enableReinitialize={true}
        initialValues={triggersInitialValues}
      />
    </ModalTemplate>
  );
};

export const PresentationTriggersManagementModal = () => {
  return createPortal(
    <TriggersModal />,
    document.getElementById("modal") as Element
  );
};
