import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actions as routerActions } from "redux-router5";
import {
  ASSETS_LIST_ROUTE,
  PRESENTATIONS_LIST_ROUTE,
  SCENES_LIST_ROUTE
} from "../../routing/route-names";

import { getStats } from "../dashboard-selectors";

export const DashboardTileStatsImpl = ({
  presentations,
  presentationsPublic,
  assets,
  scenes,
  gotoOwnPresentations,
  gotoOwnScenes,
  gotoAssets,
  gotoGlobalPresentations
}) => (
  <div className="card">
    <h2 className="title">Stats</h2>
    <div>
      <ul className="itemList stats">
        <li
          className="item itemClickable"
          onClick={gotoOwnPresentations}
          data-qa="dashboard-tile-presentations-link"
        >
          <span className="label">Presentation Amount</span>
          <span>{presentations}</span>
        </li>
        <li
          className="item itemClickable"
          onClick={gotoOwnScenes}
          data-qa="dashboard-tile-scenes-link"
        >
          <span className="label">Scene Amount</span>
          <span>{scenes}</span>
        </li>
        <li
          className="item itemClickable"
          onClick={gotoAssets}
          data-qa="dashboard-tile-media-link"
        >
          <span className="label">Media Amount</span>
          <span>{assets}</span>
        </li>
        <li
          className="item itemClickable"
          onClick={gotoGlobalPresentations}
          data-qa="dashboard-tile-presentations-global-link"
        >
          <span className="label">Presentations accepted to Global list</span>
          <span>{presentationsPublic}</span>
        </li>
      </ul>
    </div>
  </div>
);

DashboardTileStatsImpl.propTypes = {
  presentations: PropTypes.number.isRequired,
  presentationsPublic: PropTypes.number.isRequired,
  assets: PropTypes.number.isRequired,
  scenes: PropTypes.number.isRequired,
  gotoOwnPresentations: PropTypes.func.isRequired,
  gotoOwnScenes: PropTypes.func.isRequired,
  gotoAssets: PropTypes.func.isRequired,
  gotoGlobalPresentations: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  gotoOwnPresentations: () =>
    routerActions.navigateTo(PRESENTATIONS_LIST_ROUTE, { tab: "own" }),
  gotoOwnScenes: () =>
    routerActions.navigateTo(SCENES_LIST_ROUTE, { tab: "own" }),
  gotoAssets: () => routerActions.navigateTo(ASSETS_LIST_ROUTE, { tab: "my" }),
  gotoGlobalPresentations: () =>
    routerActions.navigateTo(PRESENTATIONS_LIST_ROUTE, { tab: "public" })
};

export const DashboardTileStats = connect(
  getStats,
  mapDispatchToProps
)(DashboardTileStatsImpl);
