import React, { useCallback, useEffect } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import { getBranding } from "../../config/config-selectors";
import {
  getScene,
  isInitializing,
  getInitializingError,
  isPreviewShown
} from "../scene-editor-selectors";
import { CriticalError } from "./ux/critical-error";

import { useNavigateTo } from "../../routing/routing-hooks";
import { SceneEditorPreview } from "./preview/scene-editor-preview";
import { SceneEditorPageTemplate } from "./scene-editor-page-template";
import { SceneEditor } from "./scene-editor";
import { useSceneEditorUtils } from "../hooks/asset-config/use-scene-editor-utils";

export const SceneEditorRoot = () => {
  const scene = useSelector(getScene);
  const roomName = useSelector(getBranding).roomName;
  const isPreviewViewShown = useSelector(isPreviewShown);
  const initializing = useSelector(isInitializing);
  const initializingError = useSelector(getInitializingError);

  const { initialize } = useSceneEditorUtils();

  useEffect(() => {
    initialize();
  }, [initialize]);

  const { navigateTo } = useNavigateTo();

  const openSceneDetail = useCallback(() => {
    if (scene) {
      navigateTo("scenes.list.edit", { tab: "own", sceneId: scene.id });
    }
  }, [navigateTo, scene]);

  let error = null;

  if (initializing) {
    if (initializingError) {
      error = initializingError;
    } else {
      error = "Loading scene...";
    }
  } else if (!scene || !scene.instance) {
    error = (
      <a onClick={openSceneDetail}>
        Please select scene&apos;s Targeted {roomName} first.
      </a>
    );
  }

  if (error) {
    return (
      <SceneEditorPageTemplate>
        <div
          className={cx("scene-editor", {
            preview: isPreviewViewShown
          })}
          data-qa="scene-editor"
        >
          <CriticalError>{error}</CriticalError>
        </div>
      </SceneEditorPageTemplate>
    );
  }

  return (
    <SceneEditorPageTemplate>
      <div
        className={cx("scene-editor", {
          preview: isPreviewViewShown
        })}
        data-qa="scene-editor"
      >
        {!isPreviewViewShown && <SceneEditor />}
        {isPreviewViewShown && <SceneEditorPreview />}
      </div>
    </SceneEditorPageTemplate>
  );
};
