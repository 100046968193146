export enum AssetType {
  ASSET_TEXT = "ASSET_TEXT",
  ASSET_IMAGE = "ASSET_IMAGE",
  ASSET_VIDEO = "ASSET_VIDEO",
  ASSET_SOUND = "ASSET_SOUND",
  ASSET_URL = "ASSET_URL",
  ASSET_PDF = "ASSET_PDF",
  ASSET_PRESENTATION = "ASSET_PRESENTATION",
  ASSET_WALL_VIDEO = "ASSET_WALL_VIDEO",
  ASSET_WALL_IMAGE = "ASSET_WALL_IMAGE"
}

export const REGULAR_ASSET_TYPES = [
  AssetType.ASSET_TEXT,
  AssetType.ASSET_IMAGE,
  AssetType.ASSET_VIDEO,
  AssetType.ASSET_SOUND,
  AssetType.ASSET_URL,
  AssetType.ASSET_PDF,
  AssetType.ASSET_PRESENTATION
];

export const WALL_ASSET_TYPES = [
  AssetType.ASSET_WALL_VIDEO,
  AssetType.ASSET_WALL_IMAGE
];
