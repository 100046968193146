import React from "react";

export const DashboardTileReporting = () => (
  <div className="card" data-qa="card-download-report">
    <h2 className="title" data-qa="card-title">
      Presentation Reporting
    </h2>
    <div>
      <div>List of all presentations, generated live.</div>
      <br />
      <a
        className="button"
        href="/api/presentations/export"
        data-qa="link-download-report"
      >
        Download Report
      </a>
    </div>
  </div>
);
