import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "rc-tooltip";

import { getOrderedInstances } from "../entity-repository/entity-repository-selectors";
import { AssetReduced } from "../../../shared/types/asset";
import { WallDimensionsStatus } from "../media/components/wall-compatibility/wall-dimensions-status";
import {
  getWarningMessage,
  isSapWallAssetOptimalForInstance,
  SAP_WALL_ASSET_OK_FOR_ROOM
} from "./sap-wall-dimensions-validity";
import { CompatibilityInfo } from "../media/components/wall-compatibility/wall-dimensions-compatibility-info";

export const SapInstancesWallStatuses = (props: {
  wallAsset: AssetReduced;
}) => {
  const { wallAsset } = props;

  const instances = useSelector(getOrderedInstances);

  if (!instances) {
    return null;
  }

  if (instances.length === 1) {
    return (
      <div className="wall-dimension-status-wrapper">
        <WallDimensionsStatus
          isValid={isSapWallAssetOptimalForInstance(wallAsset, instances[0])}
          okMessage={SAP_WALL_ASSET_OK_FOR_ROOM}
          warningMessage={getWarningMessage(
            instances[0].recommendedBackgrounds
          )}
        />
      </div>
    );
  }

  return (
    <Tooltip
      overlayClassName="instances-wall-statuses"
      trigger={["click"]}
      overlay={
        <div className="list">
          <h5>IX Compatible</h5>
          {instances.map((instance) => (
            <div className="item" key={instance.uuid}>
              <WallDimensionsStatus
                isValid={isSapWallAssetOptimalForInstance(wallAsset, instance)}
                okMessage={SAP_WALL_ASSET_OK_FOR_ROOM}
                warningMessage={getWarningMessage(
                  instance.recommendedBackgrounds
                )}
              />

              {instance.name}
            </div>
          ))}
        </div>
      }
    >
      <div className="wall-dimension-status-wrapper">
        <CompatibilityInfo />
      </div>
    </Tooltip>
  );
};
