import React from "react";
import { ReactComponent as IconPdf } from "client/images/assets/pdf.svg";

export const AssetPDF = (props: { width: number; height: number }) => {
  const { width, height } = props;
  return (
    <div
      className="placeholder-pdf"
      style={{
        width,
        height
      }}
    >
      <IconPdf className="icon icon-pdf icon192" />
    </div>
  );
};
