import { createSelector } from "reselect";
import { getPredefinedSceneNames as getState } from "client/modules/root/root-selectors";

export const getPredefinedSceneNamesTotalCount = createSelector(
  getState,
  (state) => state.totalCount
);

export const getPredefinedSceneNamesList = createSelector(
  getState,
  (state) => state.predefinedSceneNames
);

export const listHasMoreData = createSelector(
  getPredefinedSceneNamesTotalCount,
  getPredefinedSceneNamesList,
  (totalCount, list) => {
    return totalCount === null || totalCount > list.length;
  }
);
