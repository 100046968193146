import { call, fork, put } from "redux-saga/effects";

import { onRouteEntered } from "../routing/on-route-enter-saga";
import * as Api from "../api/client";
import { DASHBOARD_ROUTE } from "../routing/route-names";
import Actions from "./dashboard-actions";

function* onDashboardEntered() {
  const stats = yield call(Api.getLoggedInUserStats);
  yield put(Actions.Creators.statsFetched(stats));
}

export function* dashboardSaga() {
  yield fork(onRouteEntered, DASHBOARD_ROUTE, onDashboardEntered);
}
