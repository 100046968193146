import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { actions as routerActions } from "redux-router5";
import { startsWithSegment } from "router5-helpers";

import {
  ASSETS_LIST_ROUTE,
  ASSETS_ROUTE,
  AUTH_USER_PROFILE_ROUTE,
  DASHBOARD_ROUTE,
  PRESENTATIONS_LIST_ROUTE,
  PRESENTATIONS_ROUTE,
  SCENES_LIST_ROUTE,
  SCENES_ROUTE,
  WALLS_LIST_ROUTE,
  WALLS_ROUTE,
  SETTINGS_ROUTE
} from "../../routing/route-names";
import * as RoutingSelectors from "../../routing/routing-selectors";
import { withAcl } from "../../acl/with-acl";
import {
  getGitbookHelpLink,
  hasTriggersEnabled
} from "../../config/config-selectors.js";
import { useTracking } from "../../user-tracking/use-tracking";
import { trackManualOpened } from "../../user-tracking/user-tracking-creators";

import { ReactComponent as IconBackgrounds } from "client/images/navigation/backgrounds.svg";
import { ReactComponent as IconBackgroundsActive } from "client/images/navigation/backgrounds-active.svg";
import { ReactComponent as IconDashboard } from "client/images/navigation/dashboard.svg";
import { ReactComponent as IconDashboardActive } from "client/images/navigation/dashboard-active.svg";
import { ReactComponent as IconMedia } from "client/images/navigation/media.svg";
import { ReactComponent as IconMediaActive } from "client/images/navigation/media-active.svg";
import { ReactComponent as IconPresentations } from "client/images/navigation/presentations.svg";
import { ReactComponent as IconPresentationsActive } from "client/images/navigation/presentations-active.svg";
import { ReactComponent as IconScenes } from "client/images/navigation/scenes.svg";
import { ReactComponent as IconScenesActive } from "client/images/navigation/scenes-active.svg";
import { ReactComponent as IconSettings } from "client/images/navigation/settings.svg";
import { ReactComponent as IconSettingsActive } from "client/images/navigation/settings-active.svg";
import { ReactComponent as IconSupport } from "client/images/navigation/support.svg";
import { ReactComponent as IconSupportActive } from "client/images/navigation/support-active.svg";

import { AclResource } from "../../../../shared/acl";

const Navigation = ({
  className,
  navigateTo,
  routeName,
  hasAccess,
  triggersEnabled,
  gitbookHelpLink
}) => {
  const startsWith = startsWithSegment(routeName);
  const track = useTracking();

  const onClickHelp = useCallback(() => {
    track(trackManualOpened(window.location.pathname));
    window.location = gitbookHelpLink;
  }, [track, gitbookHelpLink]);

  const showSettings =
    hasAccess(AclResource.PREDEFINED_SCENES_WRITE) ||
    hasAccess(AclResource.USERS) ||
    hasAccess(AclResource.CUSTOM_FIELDS_WRITE) ||
    (hasAccess(AclResource.TRIGGERS_WRITE) && triggersEnabled);

  return (
    <nav className={cx("navigation", className)} data-qa="navigation">
      <a
        onClick={() => navigateTo(DASHBOARD_ROUTE)}
        className={cx({
          active:
            startsWith(AUTH_USER_PROFILE_ROUTE) || startsWith(DASHBOARD_ROUTE)
        })}
        data-qa="nav-dashboard"
      >
        <IconDashboard />
        <IconDashboardActive className="iconActive" />
        <b>Dashboard</b>
      </a>
      <a
        onClick={() => navigateTo(ASSETS_LIST_ROUTE, { tab: "my" })}
        className={cx({ active: startsWith(ASSETS_ROUTE) })}
        data-qa="nav-assets"
      >
        <IconMedia />
        <IconMediaActive className="iconActive" />
        <b>Media</b>
      </a>
      <a
        onClick={() => navigateTo(SCENES_LIST_ROUTE, { tab: "own" })}
        className={cx({ active: startsWith(SCENES_ROUTE) })}
        data-qa="nav-scenes"
      >
        <IconScenes />
        <IconScenesActive className="iconActive" />
        <b>Scenes</b>
      </a>
      <a
        onClick={() => navigateTo(PRESENTATIONS_LIST_ROUTE, { tab: "own" })}
        className={cx({ active: startsWith(PRESENTATIONS_ROUTE) })}
        data-qa="nav-presentations"
      >
        <IconPresentations />
        <IconPresentationsActive className="iconActive" />
        <b>Presentations</b>
      </a>
      <a
        onClick={() => navigateTo(WALLS_LIST_ROUTE, { tab: "all" })}
        className={cx({ active: startsWith(WALLS_ROUTE) })}
        data-qa="nav-walls"
      >
        <IconBackgrounds />
        <IconBackgroundsActive className="iconActive" />
        <b>Backgrounds</b>
      </a>
      {showSettings && (
        <a
          onClick={() => navigateTo(SETTINGS_ROUTE)}
          className={cx({ active: startsWith(SETTINGS_ROUTE) })}
          data-qa="nav-settings"
        >
          <IconSettings />
          <IconSettingsActive className="iconActive" />
          <b>Settings</b>
        </a>
      )}
      {gitbookHelpLink && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          data-qa="nav-support"
          onClick={onClickHelp}
        >
          <IconSupport />
          <IconSupportActive className="iconActive" />
          <b>Help</b>
        </a>
      )}
    </nav>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  routeName: PropTypes.string.isRequired,
  hasAccess: PropTypes.func.isRequired,
  gitbookHelpLink: PropTypes.string,
  triggersEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    routeName: RoutingSelectors.getRouteName(state),
    gitbookHelpLink: getGitbookHelpLink(state),
    triggersEnabled: hasTriggersEnabled(state)
  };
}

const mapDispatchToProps = {
  navigateTo: routerActions.navigateTo
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAcl(Navigation));
