import React, { useCallback } from "react";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";

import Actions from "../presentation-actions";
import { ListInfiniteScroll, ROW_STATUS } from "../../ux/list-infinite-scroll";
import { getScenesInfinite } from "../../api/client";
import { getPresentation } from "../presentation-selectors";
import { Modal } from "../../ux/modal";

import { ReactComponent as IconAdd } from "client/images/add.svg";
import { ReactComponent as IconTrash } from "client/images/trash.svg";
import { ReactComponent as IconCross } from "client/images/cross.svg";
import { ReactComponent as IconSearch } from "client/images/search.svg";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";

// locators
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";
import { PRESENTATION_LOCATORS } from "shared/tests/locators/presentation.locators";
import { ROW_LOCATORS } from "shared/tests/locators/row.locators";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const EmptyScenes = () => <div>Empty</div>;

const SceneRow = ({
  id,
  title,
  description,
  attached,
  attach,
  isAttachingInProgress
}) => (
  <div className="item" data-qa={ROW_LOCATORS.getRow(id)}>
    <span className="itemCell">
      <span className="itemTitle" data-qa={ROW_LOCATORS.getRowCell("title")}>
        {title}
      </span>
      <span
        className="itemDescription"
        data-qa={ROW_LOCATORS.getRowCell("description")}
      >
        {description}
      </span>
    </span>
    {!attached && (
      <button
        className={cx("button itemCellRight", {
          inProgress: isAttachingInProgress
        })}
        onClick={attach}
        data-qa={ROW_LOCATORS.getAction("add")}
      >
        {!isAttachingInProgress && (
          <>
            <IconAdd /> Add
          </>
        )}
      </button>
    )}
    {attached && (
      <div
        className="itemCellRight"
        data-qa={ROW_LOCATORS.getAction("already-added")}
      >
        Already added
      </div>
    )}
  </div>
);

SceneRow.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  attached: PropTypes.bool,
  attach: PropTypes.func.isRequired,
  isAttachingInProgress: PropTypes.bool
};

const SelectedScene = ({
  id,
  title,
  description,
  onRemove,
  isDetachingInProgress
}) => (
  <li className="item" data-qa={ROW_LOCATORS.getRow(id)}>
    <span className="itemCell">
      <span className="itemTitle" data-qa={ROW_LOCATORS.getRowCell("title")}>
        {title}
      </span>
      <span
        className="itemDescription"
        data-qa={ROW_LOCATORS.getRowCell("description")}
      >
        {description}
      </span>
    </span>
    <button
      className={cx("button itemCellRight buttonRemove", {
        inProgress: isDetachingInProgress
      })}
      onClick={onRemove}
      data-qa={ROW_LOCATORS.getAction("delete")}
    >
      {!isDetachingInProgress && <IconTrash />}
    </button>
  </li>
);

SelectedScene.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  isDetachingInProgress: PropTypes.bool
};

const SceneRowLoading = () => <div>Loading</div>;

const mapStateToProps = (state) => ({
  presentation: getPresentation(state),
  isDetachingInProgress: getProgressForKeySelector(state)(
    Actions.Types.DETACH_SCENE
  ),
  isAttachingInProgress: getProgressForKeySelector(state)(
    Actions.Types.ATTACH_SCENE
  )
});

const mapDispatchToProps = {
  attachScene: Actions.Creators.attachScene,
  detachScene: Actions.Creators.detachScene
};

const SEARCH_KEYSTROKE_DEBOUNCE_TIME_MS = 300;

const TABS = {
  MY_SCENES: "own",
  GLOBAL_SCENES: "global"
};

class PresentationEditAttachSceneImpl extends React.Component {
  state = {
    search: "",
    selectedTab: TABS.MY_SCENES
  };

  static propTypes = {
    presentation: PropTypes.shape({
      presentationScenes: PropTypes.array.isRequired
    }),
    attachScene: PropTypes.func.isRequired,
    detachScene: PropTypes.func.isRequired,
    isDetachingInProgress: PropTypes.func.isRequired,
    isAttachingInProgress: PropTypes.func.isRequired
  };

  changeSearch = (ev) => {
    this.setState({ search: ev.target.value }, () => {
      this.changeDebouncedSearch(this.state.search);
    });
  };

  clearSearch = () => {
    this.setState({ search: "" }, () => {
      this.changeDebouncedSearch(this.state.search);
    });
  };

  changeDebouncedSearch = debounce((value) => {
    this.setState({ debouncedSearch: value });
  }, SEARCH_KEYSTROKE_DEBOUNCE_TIME_MS);

  changeTab = (tab) => () => {
    this.setState({ selectedTab: tab });
  };

  componentWillUnmount() {
    this.changeDebouncedSearch.cancel();
  }

  render() {
    const { search, debouncedSearch, selectedTab } = this.state;
    const {
      presentation,
      attachScene,
      detachScene,
      isDetachingInProgress,
      isAttachingInProgress
    } = this.props;

    if (presentation) {
      const closeModal = () => window.history.back();

      return (
        <Modal
          title="Add Scenes"
          onCloseClick={closeModal}
          data-qa={MODAL_LOCATORS.getModal("add-scenes")}
        >
          <>
            <div className="inputWithButton searchBox">
              <input
                className="input"
                placeholder="Search scenes"
                type="text"
                value={search}
                onChange={this.changeSearch}
                data-qa={FORM_LOCATORS.inputs.getInput("search-scene")}
              />
              <button className="button" onClick={this.clearSearch}>
                {search === "" ? <IconSearch /> : <IconCross />}
              </button>
            </div>
            <div className="listBox">
              <h4 className="title">Selected Scenes</h4>
              <ul
                className="listBoxItems"
                data-qa={PRESENTATION_LOCATORS.addScenes.selectedScenes}
              >
                {presentation.presentationScenes.length === 0 && (
                  <li className="listPlaceholder">No selected scenes</li>
                )}
                {presentation.presentationScenes.map((presentationScene) => (
                  <SelectedScene
                    key={presentationScene.sceneId}
                    {...presentationScene.scene}
                    onRemove={() => detachScene(presentationScene.sceneId)}
                    isDetachingInProgress={isDetachingInProgress(
                      presentationScene.sceneId
                    )}
                  />
                ))}
              </ul>
            </div>
            <div className="listBox">
              <div className="listNav">
                <h4
                  className={cx({
                    title: true,
                    active: selectedTab === TABS.MY_SCENES
                  })}
                  onClick={this.changeTab(TABS.MY_SCENES)}
                  data-qa={PRESENTATION_LOCATORS.addScenes.getTab("own")}
                >
                  My Scenes
                </h4>
                <h4
                  className={cx({
                    title: true,
                    active: selectedTab === TABS.GLOBAL_SCENES
                  })}
                  onClick={this.changeTab(TABS.GLOBAL_SCENES)}
                  data-qa={PRESENTATION_LOCATORS.addScenes.getTab("global")}
                >
                  Global Scenes
                </h4>
              </div>
              <div
                className="listBoxItems listBoxItemsScroll"
                data-qa={PRESENTATION_LOCATORS.addScenes.list}
              >
                <ListInfiniteScroll
                  rowHeight={57}
                  key={`${selectedTab}${debouncedSearch}`}
                  emptyPlaceholder={<EmptyScenes />}
                  rowRendererProps={presentation.presentationScenes.map(
                    (presentationScene) => presentationScene.sceneId
                  )}
                  fetchData={async (startIndex, stopIndex) =>
                    getScenesInfinite(
                      debouncedSearch,
                      startIndex,
                      stopIndex,
                      selectedTab
                    )
                  }
                >
                  {(status, record, scenesInPresentation) => {
                    switch (status) {
                      case ROW_STATUS.LOADED:
                        return (
                          record && (
                            <SceneRow
                              {...record}
                              attached={scenesInPresentation.includes(
                                record.id
                              )}
                              attach={() => attachScene(record.id)}
                              isAttachingInProgress={isAttachingInProgress(
                                record.id
                              )}
                            />
                          )
                        );
                      case ROW_STATUS.LOADING:
                        return <SceneRowLoading />;
                      default:
                        throw new Error(`Unknown row state ${status}`);
                    }
                  }}
                </ListInfiniteScroll>
              </div>
            </div>
          </>
        </Modal>
      );
    } else {
      return false;
    }
  }
}

export const PresentationEditAttachSceneImpl2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentationEditAttachSceneImpl);

export const PresentationEditAttachScene = () => {
  const closeModal = useCallback(() => {
    window.history.back();
  }, []);

  useEscapeHook(true, closeModal);

  return <PresentationEditAttachSceneImpl2 />;
};
