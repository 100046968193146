import cx from "classnames";
import React from "react";
import { reduxForm } from "redux-form";
import { urlValidator } from "../../../scene-editor/asset-library/components/url-asset-modal";
import { TextField } from "../../../forms/text-field";

export const URL_ASSET_FORM = "add-url-asset-form";

const validateUrlAssetForm = (values: FormData) => {
  const errors: { [key: string]: string | undefined } = {};
  errors.url = urlValidator(values.url);
  return errors;
};

interface FormData {
  url: string;
}

interface UrlAssetFormProps {
  inProgress: boolean;
  createUrlAsset: (data: FormData) => void;
}
export const UrlAssetForm = reduxForm<FormData, UrlAssetFormProps>({
  form: URL_ASSET_FORM,
  validate: validateUrlAssetForm
})(({ handleSubmit, createUrlAsset, inProgress }) => (
  <form
    className="inputBox inputBoxUrl"
    onSubmit={handleSubmit(createUrlAsset)}
  >
    <label>URL</label>
    <TextField className="input" name="url" />
    <button
      className={cx("button", {
        inProgress
      })}
      type="submit"
    >
      Add
    </button>
  </form>
));
