import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as routerActions } from "redux-router5";
import cx from "classnames";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag } from "react-dnd";

import { ReactComponent as IconAdd } from "../../../../../images/add.svg";
import { ReactComponent as IconEdit } from "../../../../../images/btn-edit.svg";
import {
  getRouteName,
  getRouteParams,
  RouteParamSelector
} from "../../../../routing/routing-selectors";
import { DebouncedSearchInput } from "../../../../ux/debounced-search-input";
import {
  AddFilterRouteParentParam,
  CustomField
} from "../../custom-field-types";
import { ReactComponent as IconFilter } from "../../../../../images/filter.svg";
import { ReactComponent as IconDelete } from "../../../../../images/btn-delete.svg";
import { CustomFieldViewModel } from "../../../../entity-repository/entity.types";
import { useDeleteFilter } from "../../hooks/use-filters-crud";

interface CustomFieldRowProps {
  customField: CustomField;
  onEdit: (id: number) => void;
  onDelete: (payload: { id: number; label: string }) => void;
  isAssigned?: boolean;
  isDragging?: boolean;
}

export const CustomFieldRow = (props: CustomFieldRowProps) => {
  const { customField, onEdit, onDelete, isAssigned, isDragging } = props;

  const [, drag, preview] = useDrag({
    item: { type: "dndAssignFilter", id: customField.id }
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <div className={cx({ gridRowDnD: true, isDragging })} ref={drag}>
      <div
        className={cx("gridRow", { assigned: isAssigned })}
        data-qa={`grid-row-${customField.label}`}
      >
        <span className="gridIcon">
          <IconFilter />
        </span>
        <h3 className="gridRowTitle" data-qa="grid-row-title">
          {customField.label}{" "}
          <span className="gridRowSubtitle">
            {customField.values.map((val) => val.value).join(", ")}
          </span>
        </h3>
        <span className="gridActions">
          <button
            type="button"
            className="button buttonIcon outlined"
            onClick={() => onEdit(customField.id)}
            data-qa="btn-edit"
          >
            <IconEdit />
          </button>
          <button
            type="button"
            className="button buttonIcon outlined danger"
            onClick={() => onDelete(customField)}
            data-qa="btn-delete"
          >
            <IconDelete />
          </button>
        </span>
      </div>
    </div>
  );
};

export const CustomFieldsFilterList = (props: {
  filters: CustomFieldViewModel[];
}) => {
  const { filters } = props;

  const route = useSelector(getRouteName) as string;
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{
      id: number;
    }>
  );
  const dispatch = useDispatch();

  const [filterValue, setFilterValue] = useState("");
  const [assignedFilters, setAssignedFilters] = useState<typeof filters>([]);
  const [unAssignedFilters, setUnassignedFilters] = useState<typeof filters>(
    []
  );
  const [filteredAssignedFilters, setFilteredAssignedFilters] = useState<
    typeof filters
  >([]);
  const [filteredUnassignedFilters, setFilteredUnassignedFilters] = useState<
    typeof filters
  >([]);

  const editFilter = useCallback(
    (fieldId: number) => {
      dispatch(
        routerActions.navigateTo(`${route}.edit-filter`, {
          ...routeParams,
          fieldId
        })
      );
    },
    [dispatch, routeParams, route]
  );

  const addFilter = useCallback(() => {
    dispatch(
      routerActions.navigateTo(`${route}.add-filter`, {
        ...routeParams,
        id: AddFilterRouteParentParam.NONE
      })
    );
  }, [dispatch, routeParams, route]);

  const onDelete = useDeleteFilter();

  useEffect(() => {
    setAssignedFilters(
      filters.filter((filter) => filter.entityMappings.length !== 0)
    );
    setUnassignedFilters(
      filters.filter((filter) => filter.entityMappings.length === 0)
    );
  }, [filters]);

  useEffect(() => {
    setFilteredAssignedFilters(
      assignedFilters.filter((customField) =>
        customField.label.toLowerCase().includes(filterValue.toLowerCase())
      )
    );
    setFilteredUnassignedFilters(
      unAssignedFilters.filter((customField) =>
        customField.label.toLowerCase().includes(filterValue.toLowerCase())
      )
    );
  }, [filterValue, assignedFilters, unAssignedFilters]);

  return (
    <div className="filterPanel" data-qa="section-filters">
      <div className="contentHeader">
        <h2>Filters</h2>
        <div className="search inputWithButton">
          <DebouncedSearchInput
            placeholder="Search filters"
            onChange={(filterString: string) => {
              setFilterValue(filterString);
            }}
          />
        </div>
      </div>

      <button
        className="addItemButton"
        onClick={addFilter}
        data-qa="btn-add-filter"
      >
        <IconAdd />
        Add Filter
      </button>

      <div className="filterPanelContent">
        {filteredAssignedFilters.map((customField) => {
          return (
            <CustomFieldRow
              key={customField.id}
              customField={customField}
              onDelete={onDelete}
              onEdit={editFilter}
              isAssigned={true}
            />
          );
        })}
        <hr />
        <h2 className="pageTitle" style={{}}>
          Unassigned Filters
        </h2>
        {filteredUnassignedFilters.map((customField) => {
          return (
            <CustomFieldRow
              key={customField.id}
              customField={customField}
              onDelete={onDelete}
              onEdit={editFilter}
              isAssigned={false}
            />
          );
        })}
      </div>
    </div>
  );
};
