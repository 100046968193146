import React, { useCallback } from "react";
import { reduxForm } from "redux-form";
import { actions as routerActions } from "redux-router5";
import { Logger as logger } from "purplex-logging";

import { CreateRecordCommand } from "client/modules/crud/use-create-record";
import { Modal } from "../../../ux/modal";
import {
  TriggersForm,
  TriggersFormCustomProps,
  TriggersFormData
} from "./triggers-form";
import { TRIGGERS_LIST_ROUTE } from "client/modules/routing/route-names";
import { useDispatch } from "react-redux";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { asyncValidate, validate } from "./triggers-form-validate";
import { useNotifications } from "client/modules/ux/notifications/use-notifications";

const FORM_NAME = "triggers-create";
const TriggersCreateForm = reduxForm<TriggersFormData, TriggersFormCustomProps>(
  {
    form: FORM_NAME,
    validate,
    asyncValidate
  }
)(TriggersForm);

export const TriggersCreate = ({
  createRecordCommand
}: {
  createRecordCommand: CreateRecordCommand<TriggersFormData>;
}) => {
  const notifications = useNotifications();
  const dispatch = useDispatch();
  const goBack = useCallback(() => {
    dispatch(
      routerActions.navigateTo(TRIGGERS_LIST_ROUTE, {}, { replace: true })
    );
  }, [dispatch]);

  const onSave = useCallback(
    async (data: TriggersFormData) => {
      const { success, ex } = await createRecordCommand.createRecord(data);
      if (success) {
        notifications.notifySuccess("New trigger has been created.");
        goBack();
      } else {
        logger.error("Error durign creating of trigger", ex && ex.message);
        notifications.notifyError(
          "There was an error during creating of the trigger"
        );
      }
    },
    [createRecordCommand, goBack, notifications]
  );

  useEscapeHook(true, goBack);

  return (
    <Modal
      className="triggersModal has-footer is-bleeding"
      onCloseClick={goBack}
      title="New Trigger"
    >
      <TriggersCreateForm
        saving={createRecordCommand.inProgress}
        onSave={onSave}
      />
    </Modal>
  );
};
