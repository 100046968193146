import React from "react";

import { CustomFieldsFilterList } from "./custom-fields-filter-list";
import { CategoryTreeRoot } from "./category-tree";
import { CustomFieldEntity } from "../../../../../../shared/types/custom-field-entity";
import { useCustomFieldLists } from "../../hooks/use-custom-fields";
import { useGetCustomFieldType } from "../../hooks/use-get-custom-field-type";

export const CustomFieldsList = () => {
  const type = useGetCustomFieldType();

  const {
    presentationFilters,
    assetFilters,
    assetCategoryTree,
    presentationActiveFilters
  } = useCustomFieldLists();

  return (
    <div className="page-custom-fields">
      <header className="pageHeader">
        <h1 className="pageHeading" data-qa="tab-header">
          {type === CustomFieldEntity.ASSET
            ? "Company library"
            : "Presentations"}
        </h1>
      </header>

      <div className="pageContent">
        <div
          className="categoryTree"
          data-qa={`${
            type === CustomFieldEntity.ASSET ? "media" : "presentations"
          }-categories`}
        >
          <CategoryTreeRoot
            categoryTree={
              type === CustomFieldEntity.ASSET
                ? assetCategoryTree
                : presentationActiveFilters
            }
          />
        </div>
        <CustomFieldsFilterList
          filters={
            type === CustomFieldEntity.ASSET
              ? assetFilters
              : presentationFilters
          }
        />
      </div>
    </div>
  );
};
