import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { ClipBoardBufferAsset } from "../../../client/modules/entity-repository/entity.types";
import { SceneEditorAssetActionType } from "./hooks/asset-config/scene-editor-actions";
import { CropOrigin } from "./hooks/dragging/use-cropping";
import { ResizingTransformOrigin } from "./hooks/dragging/use-resizing";

export interface SceneEditorAssetActionBatch<T> {
  actions: {
    type: SceneEditorAssetActionType;
    sceneAssetId: number;
    previousValue: T;
    nextValue: T;
  }[];
  newVersion?: boolean;
  redoCallback?: (writeToHistory: boolean) => void;
  undoCallback?: (writeToHistory: boolean) => void;
}

export interface SceneEditorState {
  activeWall: number;
  sceneRotation: number;
  selectedSceneAssetIds: string[];
  saving: boolean;
  saveFailure: boolean;
  previewInstanceUuid: string | null;
  initializing: boolean;
  initializingError: string | null;
  assetActionsLoader: boolean;
  undoStack: SceneEditorAssetActionBatch<unknown>[];
  redoStack: SceneEditorAssetActionBatch<unknown>[];
  //TODO create slice just for dragging for better performance
  showHorizontalCenterHelper: boolean;
  showVerticalCenterHelper: boolean;
  dragging: boolean;
  draggingOffsetX: number;
  draggingOffsetY: number;
  isScaling: boolean;
  scaleChange: number;
  scaleOrigin: { left: number; top: number };
  isCropping: boolean;
  cropOrigin: CropOrigin;
  cropHeight: number;
  cropOffsetX: number;
  cropOffsetY: number;
  cropWidth: number;
  isResizing: boolean;
  resizingHeightChange: number;
  resizingWidthChange: number;
  resizingOrigin: ResizingTransformOrigin;
  //TODO create new store just for dragging, this becomes crowded.
  editBackgroundCanvasScale: number;
  editBackgroundDraggingOffsetX: number;
  editBackgroundDraggingOffsetY: number;
  editBackgroundResizingAbsoluteWidthChange: number;
  editBackgroundResizingAbsoluteHeightChange: number;
  editBackgroundResizingAbsoluteLeftChange: number;
  editBackgroundResizingAbsoluteTopChange: number;
  activeWallCoords: {
    left: number;
    top: number;
  };
  editingTextAssetId: number | null;
  selectionDraggingDisabled: boolean;
  clipboardBuffer: ClipBoardBufferAsset[] | null;
}

const initialState: SceneEditorState = {
  activeWall: 0,
  sceneRotation: 0,
  selectedSceneAssetIds: [],
  saving: false,
  saveFailure: false,
  previewInstanceUuid: null,
  initializing: true,
  initializingError: null,
  assetActionsLoader: false,
  undoStack: [],
  redoStack: [],
  showHorizontalCenterHelper: false,
  showVerticalCenterHelper: false,
  dragging: false,
  draggingOffsetX: 0,
  draggingOffsetY: 0,
  isScaling: false,
  scaleChange: 1,
  scaleOrigin: { left: 0, top: 0 },
  isCropping: false,
  cropOrigin: CropOrigin.LEFT,
  cropWidth: 0,
  cropHeight: 0,
  cropOffsetX: 0,
  cropOffsetY: 0,
  isResizing: false,
  resizingHeightChange: 0,
  resizingWidthChange: 0,
  resizingOrigin: ResizingTransformOrigin.LEFT_BOTTOM,
  editBackgroundCanvasScale: 1,
  editBackgroundDraggingOffsetX: 0,
  editBackgroundDraggingOffsetY: 0,
  editBackgroundResizingAbsoluteWidthChange: 0,
  editBackgroundResizingAbsoluteHeightChange: 0,
  editBackgroundResizingAbsoluteLeftChange: 0,
  editBackgroundResizingAbsoluteTopChange: 0,
  activeWallCoords: {
    left: 0,
    top: 0
  },
  selectionDraggingDisabled: false,
  editingTextAssetId: null,
  clipboardBuffer: null
};

const wallRotations = [0, 90, 180, -90];

const { actions, reducer } = createSlice({
  name: "sceneEditor",
  initialState,
  reducers: {
    addActionBatchToUndoStack: (
      state,
      action: PayloadAction<SceneEditorAssetActionBatch<unknown>>
    ) => {
      return {
        ...state,
        undoStack: [...state.undoStack, action.payload]
      };
    },
    popUndoStack: (state) => {
      return {
        ...state,
        undoStack: state.undoStack.length
          ? state.undoStack.slice(0, state.undoStack.length - 1)
          : []
      };
    },
    resetUndoStack: (state) => {
      return {
        ...state,
        undoStack: []
      };
    },
    addActionBatchToRedoStack: (
      state,
      action: PayloadAction<SceneEditorAssetActionBatch<unknown>>
    ) => {
      return {
        ...state,
        redoStack: [...state.redoStack, action.payload]
      };
    },
    popRedoStack: (state) => {
      return {
        ...state,
        redoStack: state.redoStack.length
          ? state.redoStack.slice(0, state.redoStack.length - 1)
          : []
      };
    },
    resetRedoStack: (state) => {
      return {
        ...state,
        redoStack: []
      };
    },
    setClipboard: (
      state,
      action: PayloadAction<{
        clipboardBuffer: ClipBoardBufferAsset[] | null;
      }>
    ) => {
      const { clipboardBuffer } = action.payload;
      const buffer: ClipBoardBufferAsset[] | null = cloneDeep(clipboardBuffer);

      return {
        ...state,
        clipboardBuffer: buffer
      };
    },
    wallChanged: (
      state,
      action: PayloadAction<{
        activeWall: number;
      }>
    ) => {
      // determine rotation angle so the rotation angle changes by minimal possible value
      // in order to make rotation animation rotate by shortest path to target active wall
      const prevWall = state.activeWall;
      const normalizedRotationIndex =
        (action.payload.activeWall - prevWall + 4) % 4;
      const rotationDelta = wallRotations[normalizedRotationIndex];
      const prevRotation = state.sceneRotation;

      return {
        ...state,
        activeWall: action.payload.activeWall,
        sceneRotation: prevRotation + rotationDelta
      };
    },
    selectAsset: (
      state,
      action: PayloadAction<{
        sceneAssetId: string;
      }>
    ) => ({
      ...state,
      selectedSceneAssetIds: [action.payload.sceneAssetId]
    }),
    selectMultipleAssets: (
      state,
      action: PayloadAction<{
        sceneAssetId: string[];
      }>
    ) => ({
      ...state,
      selectedSceneAssetIds: [
        ...state.selectedSceneAssetIds,
        ...action.payload.sceneAssetId
      ]
    }),
    setPreviewInstance: (
      state,
      action: PayloadAction<{
        instanceUuid: string | null;
      }>
    ) => ({
      ...state,
      previewInstanceUuid: action.payload.instanceUuid
    }),
    setInitializingError: (
      state,
      action: PayloadAction<{
        message: string;
      }>
    ) => ({
      ...state,
      initializing: true,
      initializingError: action.payload.message
    }),
    setInitializingSuccess: (state) => ({
      ...state,
      initializing: false,
      initializingError: null
    }),
    assetConfigFormChanged: (
      state,
      _action: PayloadAction<{ formData: any }>
    ) => {
      return {
        ...state,
        saving: true,
        saveFailure: false
      };
    },
    assetConfigFormSaved: (state) => ({
      ...state,
      saving: false,
      saveFailure: false
    }),
    assetConfigFormSaveFailed: (state) => ({
      ...state,
      saving: false,
      saveFailure: true
    }),
    deselectAsset: (state, action: PayloadAction<string[]>) => ({
      ...state,
      selectedSceneAssetIds: state.selectedSceneAssetIds.filter(
        (id) => !action.payload.includes(id)
      )
    }),
    deselectAllAssets: (state) => ({
      ...state,
      selectedSceneAssetIds: []
    }),
    updateAssetActionsSetLoader: (state) => ({
      ...state,
      assetActionsLoader: true
    }),
    updateAssetActionsResetLoader: (state) => ({
      ...state,
      assetActionsLoader: false
    }),
    roomSpecFetched: (
      state,
      _action: PayloadAction<{
        roomSpecUuid: string;
      }>
    ) => state,
    removeTransitionAsset: (state) => state,
    setSceneWallTrackingAction: (state) => state,
    setSceneTransitionAsset: (
      state,
      _action: PayloadAction<{
        assetUuid: string;
      }>
    ) => state,
    changeSceneAssetOrder: (
      state,
      _action: PayloadAction<{
        sceneAssetId: number;
        moveToIndex: number;
        wallNumber: number;
      }>
    ) => state,
    resetSceneEditor: () => initialState,
    transitionSettingFormHasChanged: (state) => state,
    createShowAssetAction: (
      state,
      _action: PayloadAction<{
        sceneAssetId: number;
      }>
    ) => state,
    deleteSceneAssetAction: (
      state,
      _action: PayloadAction<{
        assetAction: any;
      }>
    ) => state,
    createGotoSceneAction: (
      state,
      _action: PayloadAction<{
        sceneId: number;
      }>
    ) => state,
    saveAssetActionModal: (state) => state,
    closeAssetActionModal: (state) => state,
    leave: (state) => state,
    showHorizontalCenterHelper: (state) => ({
      ...state,
      showHorizontalCenterHelper: true
    }),
    hideHorizontalCenterHelper: (state) => ({
      ...state,
      showHorizontalCenterHelper: false
    }),
    showVerticalCenterHelper: (state) => ({
      ...state,
      showVerticalCenterHelper: true
    }),
    hideVerticalCenterHelper: (state) => ({
      ...state,
      showVerticalCenterHelper: false
    }),
    analyticsChangeQueryForAssetActions: (state) => state,
    setDragging: (
      state,
      action: PayloadAction<{
        dragging: boolean;
      }>
    ) => ({
      ...state,
      dragging: action.payload.dragging
    }),
    setActiveWallCoords: (
      state,
      action: PayloadAction<{
        left: number;
        top: number;
      }>
    ) => ({
      ...state,
      activeWallCoords: {
        left: action.payload.left,
        top: action.payload.top
      }
    }),
    setEditingTextAsset: (state, action: PayloadAction<number | null>) => ({
      ...state,
      editingTextAssetId: action.payload
    }),
    setDraggingOffset: (
      state,
      action: PayloadAction<{ offsetX: number; offsetY: number }>
    ) => ({
      ...state,
      draggingOffsetX: action.payload.offsetX,
      draggingOffsetY: action.payload.offsetY
    }),
    setCroppingChange: (
      state,
      action: PayloadAction<{
        cropWidth: number;
        cropHeight: number;
        cropOffsetX: number;
        cropOffsetY: number;
      }>
    ) => ({
      ...state,
      cropWidth: action.payload.cropWidth,
      cropHeight: action.payload.cropHeight,
      cropOffsetX: action.payload.cropOffsetX,
      cropOffsetY: action.payload.cropOffsetY
    }),
    setIsCropping: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isCropping: action.payload
    }),
    setCropOrigin: (state, action: PayloadAction<CropOrigin>) => ({
      ...state,
      cropOrigin: action.payload
    }),
    setIsScaling: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isScaling: action.payload
    }),
    setScaleOrigin: (
      state,
      action: PayloadAction<{ left: number; top: number }>
    ) => ({
      ...state,
      scaleOrigin: action.payload
    }),
    setScaleChange: (state, action: PayloadAction<number>) => ({
      ...state,
      scaleChange: action.payload
    }),
    setIsResizing: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isResizing: action.payload
    }),
    setResizingChange: (
      state,
      action: PayloadAction<{ widthChange: number; heightChange: number }>
    ) => ({
      ...state,
      resizingWidthChange: action.payload.widthChange,
      resizingHeightChange: action.payload.heightChange
    }),
    setResizingOrigin: (
      state,
      action: PayloadAction<ResizingTransformOrigin>
    ) => ({
      ...state,
      resizingOrigin: action.payload
    }),
    setSelectionDraggingDisabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      selectionDraggingDisabled: action.payload
    }),
    setEditBackgroundDraggingOffset: (
      state,
      action: PayloadAction<{ offsetX: number; offsetY: number }>
    ) => ({
      ...state,
      editBackgroundDraggingOffsetX: action.payload.offsetX,
      editBackgroundDraggingOffsetY: action.payload.offsetY
    }),
    setEditBackgroundResizingState: (
      state,
      action: PayloadAction<{
        absoluteWidthChange: number;
        absoluteHeightChange: number;
        absoluteLeftChange: number;
        absoluteTopChange: number;
      }>
    ) => ({
      ...state,
      editBackgroundResizingAbsoluteWidthChange:
        action.payload.absoluteWidthChange,
      editBackgroundResizingAbsoluteHeightChange:
        action.payload.absoluteHeightChange,
      editBackgroundResizingAbsoluteLeftChange:
        action.payload.absoluteLeftChange,
      editBackgroundResizingAbsoluteTopChange: action.payload.absoluteTopChange
    }),
    setEditBackgroundCanvasScale: (state, action: PayloadAction<number>) => ({
      ...state,
      editBackgroundCanvasScale: action.payload
    })
  }
});

export const SceneEditorActions = actions;
export const sceneEditorReducer = reducer;
