import { EditorSettings } from "./../../../shared/types/app-setting";
import axios from "axios";
import qs from "querystring";
import memoize from "lodash/memoize";
import { toast } from "react-toastify";

import {
  createControlledFileUpload,
  createFileUploadChannel
} from "./create-file-upload-channel";
import { BusinessError, NotFoundError, PermissionError } from "./client-errors";
import { AssetFull, AssetReduced } from "../../../shared/types/asset";
import { SceneFull, SceneReduced } from "../../../shared/types/scene";
import {
  PredefinedSceneNameFull,
  PredefinedSceneNameReduced
} from "../../../shared/types/predefined-scene-name";
import { UserFull, UserReduced, UserSafe } from "../../../shared/types/user";
import {
  PresentationFull,
  PresentationReduced
} from "../../../shared/types/presentation";
import {
  CustomFieldFull,
  CustomFieldReduced
} from "../../../shared/types/custom-field";
import { ScenesListType } from "../../../shared/types/scenes-list-type";
import { AssetType } from "../../../shared/types/asset-type";
import { PresentationsListType } from "../../../shared/types/presentations-list-type";
import { CustomFieldEntity } from "../../../shared/types/custom-field-entity";
import { CustomFieldType } from "../../../shared/types/custom-field-type";
import { ImInstance } from "../../../shared/types/im-instance";
import { CustomFieldUpdateCommand } from "../../../shared/types/custom-field-update-command";
import {
  ApiEntityResult,
  ApiListPayload,
  ApiListResult,
  ApiResult
} from "../crud/crud-types";
import {
  unwrapAny,
  unwrapEntity,
  unwrapGenericJson,
  unwrapList
} from "../crud/crud-utils";
import {
  GuestDomainFull,
  UniqueGuestDomainNameRequest,
  IsUniqueResponse,
  UniqueGuestDomainAdminRequest,
  GuestDomainFormData
} from "../../../shared/types/guest-domain";
import { ApiDetailParameters } from "../crud/use-fetch-record";
import { ApiEditEntityParameters } from "../crud/use-edit-records";
import { ApiDeleteEntityParameters } from "../crud/use-delete-records";
import { ApiListEntityParameters } from "../crud/use-fetch-list";
import { SignUpLinkFull } from "../../../shared/types/sign-up-link";
import { TriggerFull } from "shared/types/trigger";
import { TriggersFormData } from "../settings/triggers/components/triggers-form";
import { TriggersManagementFormProps } from "../presentations/components/presentation-triggers-management-modal";

const { PAGINATION_MAX_LIMIT } = require("../../../shared/api-config");

const BASE_URL = "/api";

const api = axios.create({
  baseURL: BASE_URL
});

// show generic "forbidden" error message
// to let user know something is wrong
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        return Promise.reject(
          new BusinessError(400, error.response.data.reason)
        );
      } else if (error.response.status === 401) {
        // @ts-ignore
        window.location = "/login";
      } else if (error.response.status === 403) {
        toast.error("Looks like you don't have enough permissions to do this.");
        return Promise.reject(new PermissionError(error.response.data.reason));
      } else if (error.response.status === 404) {
        return Promise.reject(new NotFoundError(error.response.data.reason));
      }
    }

    return Promise.reject(error);
  }
);

// TODO add type
export const getConfig = memoize(unwrapAny(() => api.get("/config")));

export const getInstanceConfigs = unwrapAny(
  (): ApiResult<ImInstance[]> => api.get("/instances")
);

/*
 * Scenes
 */

export const createScene = unwrapEntity(
  (data: object): ApiEntityResult<SceneFull> => api.post("/scenes", data)
);

export const changeSceneWall = unwrapEntity(
  (
    sceneId: number,
    assetUuid: string | undefined | null
  ): ApiEntityResult<SceneFull> => {
    if (assetUuid) {
      return api.put(`/scenes/${sceneId}/wall/${assetUuid}`);
    } else {
      return api.delete(`/scenes/${sceneId}/wall`);
    }
  }
);

export const changeSceneTransition = unwrapEntity(
  (sceneId: number, assetUuid: string): ApiEntityResult<SceneFull> => {
    if (assetUuid) {
      return api.put(`/scenes/${sceneId}/transition/${assetUuid}`);
    } else {
      return api.delete(`/scenes/${sceneId}/transition`);
    }
  }
);

export const updateScene = unwrapEntity(
  (sceneId: number, data: Partial<SceneReduced>): ApiEntityResult<SceneFull> =>
    api.put(`/scenes/${sceneId}`, data)
);

export const updateSceneAssets = unwrapEntity(
  (sceneId, sceneAssets): ApiEntityResult<SceneFull> =>
    api.put(`/scenes/${sceneId}/scene-assets`, sceneAssets)
);

export const updateBackgroundSettings = unwrapEntity(
  (sceneId, backgroundSettingsData): ApiEntityResult<SceneFull> =>
    api.put(`/scenes/${sceneId}/wall/settings`, backgroundSettingsData)
);

export const getScenesInfinite = unwrapList(
  (
    search: string | null,
    startIndex: number,
    stopIndex: number,
    type: ScenesListType = ScenesListType.OWN
  ): ApiListResult<SceneReduced[]> =>
    api.get(`/scenes?${qs.stringify({ search, startIndex, stopIndex, type })}`)
);

export const getAssetRelatedScenes = unwrapList(
  (uuid: string): ApiListResult<SceneReduced[]> =>
    api.get(`/scenes?asset=${uuid}`)
);

export const fetchScene = unwrapEntity(
  (id: number): ApiEntityResult<SceneFull> => api.get(`/scenes/${id}`)
);

export const fetchScenes = unwrapList(
  (
    search: string | null,
    startIndex: number,
    stopIndex: number,
    type: ScenesListType = ScenesListType.OWN
  ): ApiListResult<SceneReduced[]> =>
    api.get(`/scenes?${qs.stringify({ search, startIndex, stopIndex, type })}`)
);

export const deleteScene = unwrapEntity(
  (id: number): ApiEntityResult<void> => api.delete(`/scenes/${id}`)
);

export const shareScene = unwrapEntity(
  (id: number, userId: number): ApiEntityResult<SceneFull> =>
    api.put(`/scenes/${id}/share/${userId}`)
);

export const deleteSceneShare = unwrapEntity(
  (id: number): ApiEntityResult<SceneFull> => api.delete(`/scenes/${id}/shared`)
);

export const cloneScene = unwrapEntity(
  (id: number): ApiEntityResult<SceneFull> => api.post(`/scenes/${id}/clone`)
);

export const createTextAsset = unwrapEntity(
  (
    sceneId: number,
    text: string,
    fontSize: string,
    width: number,
    height: number,
    bold: boolean,
    italic: boolean,
    underline: boolean,
    alignment: string,
    color: string,
    font: string,
    left: number,
    top: number,
    rounded: boolean,
    glow: boolean,
    positionLocked: boolean,
    visible: boolean,
    opacity: number
  ): ApiEntityResult<SceneFull> =>
    api.post("/assets/text-asset", {
      sceneId,
      text,
      fontSize,
      width,
      height,
      bold,
      italic,
      underline,
      alignment,
      color,
      font,
      left,
      top,
      rounded,
      glow,
      positionLocked,
      visible,
      opacity
    })
);

/*
 * Assets/Walls
 */

export const createAsset = unwrapEntity(
  (
    title: string,
    description: string | null,
    type: AssetType,
    metadata: object,
    shared: boolean
  ): ApiEntityResult<AssetFull> =>
    api.post(`/assets${shared ? "/shared-assets" : ""}`, {
      title,
      description,
      type,
      metadata
    })
);

export const searchAssetTypesFilter = unwrapEntity(
  (
    searchPhrase: string | null,
    selectedCustomFields: SelectedCustomFields,
    globalSearch: string,
    additionalFilterData: object,
    category: string | null,
    walls?: boolean
  ): ApiEntityResult<ApiListPayload<{ label: string; value: AssetType }[]>> =>
    api.post("/assets/asset-types", {
      searchPhrase,
      selectedCustomFields,
      globalSearch,
      additionalFilterData,
      category,
      walls
    })
);

export const createWallAlternativeAsset = unwrapEntity(
  (parentAssetUuid: string): ApiEntityResult<AssetFull> =>
    api.post(`/assets/walls/${parentAssetUuid}/alternative-assets`, {})
);

export const createWallAsset = unwrapEntity(
  (
    title: string,
    description: string | null,
    type: AssetType
  ): ApiEntityResult<AssetFull> =>
    api.post("/assets/wall", { title, description, type })
);

export const getWall = unwrapEntity(
  (uuid: string): ApiEntityResult<AssetFull> => api.get(`/assets/walls/${uuid}`)
);

// TODO - type return value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadAsset = (uuid: string, file: string): any => {
  const body = new FormData();
  body.append("asset", file);

  return createFileUploadChannel(`${BASE_URL}/assets/${uuid}/upload`, body);
};

// TODO - type return value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const controlledUploadAsset = (uuid: string, file: string): any => {
  const body = new FormData();
  body.append("asset", file);

  return createControlledFileUpload(`${BASE_URL}/assets/${uuid}/upload`, body);
};

// TODO - type return value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadWall = (uuid: string, file: string): any => {
  const body = new FormData();
  body.append("asset", file);

  return createFileUploadChannel(
    `${BASE_URL}/assets/walls/${uuid}/upload`,
    body
  );
};

// TODO - type return value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const controlledUploadWall = (uuid: string, file: string): any => {
  const body = new FormData();
  body.append("asset", file);

  return createControlledFileUpload(
    `${BASE_URL}/assets/walls/${uuid}/upload`,
    body
  );
};

export const updateAsset = unwrapEntity(
  (
    uuid: string,
    title?: string,
    description?: string,
    metadata?: object,
    customFields?: CustomFieldUpdateCommand[],
    categories?: number[],
    sharedAsset?: boolean
  ): ApiEntityResult<AssetFull> =>
    api.put(`/assets/${sharedAsset ? "shared-assets/" : ""}${uuid}`, {
      title,
      description,
      metadata: metadata || {},
      customFields,
      categories
    })
);

export const deleteAsset = unwrapEntity(
  (uuid: string, sharedAsset: boolean): ApiEntityResult<void> => {
    if (sharedAsset) {
      return api.put(`/assets/shared-assets/bulk/delete`, {
        assetUuids: [uuid]
      });
    } else {
      return api.delete(`/assets/${uuid}`);
    }
  }
);

export const updateWall = unwrapEntity(
  (
    uuid: string,
    title: string,
    description: string | null,
    alternativeAssets: undefined | { uuid: string }[]
  ): ApiEntityResult<AssetFull> =>
    api.put(`/assets/walls/${uuid}`, { title, description, alternativeAssets })
);

export const deleteWall = unwrapEntity(
  (uuid: string): ApiEntityResult<void> => api.delete(`/assets/walls/${uuid}`)
);

export const getAsset = unwrapEntity(
  (uuid: string): ApiEntityResult<AssetFull> => api.get(`/assets/${uuid}`)
);

export const fetchAssets = unwrapList(
  (
    search: string | null,
    startIndex: number,
    stopIndex: number,
    type?: string,
    customFieldsFilter?: object,
    category?: string
  ): ApiListResult<AssetReduced[]> =>
    api.get(
      `/assets/${type === "shared" ? "shared-assets" : ""}?${qs.stringify({
        search,
        startIndex,
        stopIndex,
        type,
        customFieldsFilter:
          customFieldsFilter && JSON.stringify(customFieldsFilter),
        category
      })}`
    )
);

export enum FetchAssetType {
  WALLS = "walls",
  TRANSITIONS = "transitions",
  REGULAR_ASSETS = "regularAssets"
}

type fetchAssetFn = (
  limit: number,
  offset: number,
  options: { query?: string; type?: FetchAssetType; sceneId?: number }
) => Promise<ApiListPayload<AssetReduced[]>>;

export const fetchAssetsByRange: fetchAssetFn = unwrapList(
  async (limit, offset, { query, type, sceneId }) => {
    let url;
    if (type === FetchAssetType.WALLS) {
      url = "/assets/walls";
    } else if (type === FetchAssetType.TRANSITIONS) {
      url = "/assets/transitions";
    } else {
      url = "/assets";
    }

    return api.get(
      `${url}?${qs.stringify({
        sceneId,
        limit,
        offset,
        search: query
      })}`
    );
  }
);

export const fetchAssetsBySafeRange: fetchAssetFn = async (
  limit,
  offset,
  { query, type, sceneId }
) => {
  const maxLimit = Math.min(limit, PAGINATION_MAX_LIMIT);
  let currentOffset = offset;
  const result: ApiListPayload<AssetReduced[]> = {
    success: true,
    rows: [],
    count: 0
  };

  while (currentOffset < offset + limit) {
    // make sure the last request of all requests doesn't load rows outside the range
    const currentLimit = Math.min(maxLimit, offset + limit - currentOffset);

    const currentResult = await fetchAssetsByRange(
      currentLimit,
      currentOffset,
      {
        query,
        type,
        sceneId
      }
    );

    result.count = currentResult.count;
    result.rows = result.rows.concat(currentResult.rows);

    currentOffset += currentLimit;
  }

  return result;
};

export const fetchWalls = unwrapList(
  (
    search: string | null,
    startIndex: number,
    stopIndex: number,
    type: AssetType[]
  ): ApiListResult<AssetReduced[]> => {
    const sanitizedType = type && type.length === 1 ? type[0] : undefined;

    return api.get(
      `/assets/walls?${qs.stringify({
        search,
        startIndex,
        stopIndex,
        type: sanitizedType
      })}`
    );
  }
);

/*
 * PredefinedSceneNames
 */

export const getPredefinedSceneNames = unwrapList(
  (
    page: number,
    limit: number,
    query: string | null
  ): ApiListResult<PredefinedSceneNameReduced[]> =>
    api.get(`/predefined-scene-names?${qs.stringify({ page, limit, query })}`)
);

export const createPredefinedSceneName = unwrapEntity(
  (sceneName: string): ApiEntityResult<PredefinedSceneNameFull> =>
    api.put("/predefined-scene-names", { sceneName })
);

export const deletePredefinedSceneName = unwrapEntity(
  (id: number): ApiEntityResult<void> =>
    api.delete(`/predefined-scene-names/${id}`)
);

/*
 * Users
 */

// TODO specify better type for resourceAccess
export const searchUsers = unwrapList(
  (
    search: string,
    resourceAccess: string,
    page: number,
    limit: number
  ): ApiListResult<UserSafe[]> =>
    api.get(
      `/users/search?${qs.stringify({
        page,
        resourceAccess,
        limit,
        search
      })}`
    )
);

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

// TODO use the enum in the user router
export enum UserSortColumn {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  COUNTRY = "country"
}

export const getUsers = unwrapList(
  ({
    stopIndex,
    startIndex,
    search
  }: ApiListEntityParameters): ApiListResult<UserReduced[]> =>
    api.get(`/users?${qs.stringify({ startIndex, stopIndex, search })}`)
);

export const isEmailUnique = unwrapGenericJson(({ email }: { email: string }) =>
  api.get<{ data: { unique: boolean }; success: boolean }>(
    `/users/unique-email?${qs.stringify({ email })}`
  )
);

export const getUser = unwrapEntity(
  (id: number): ApiEntityResult<UserFull> => api.get(`/users/${id}`)
);

export const getLoggedInUser = unwrapEntity(
  (): ApiEntityResult<UserFull> => api.get("/me")
);

export const setFirstLogin = unwrapEntity(
  (): ApiEntityResult<UserFull> => api.put("/users/me/first-login")
);

export const requestUserPasswordReset = unwrapEntity(
  (email: string): ApiEntityResult<void> =>
    api.post("/users/resetpassword", { email })
);

export const resetUserPassword = unwrapEntity(
  (token: string, password: string): ApiEntityResult<void> =>
    api.post("/users/password-change", { token, password })
);

export const createGuestAdminPassword = unwrapEntity(
  (token: string, password: string): ApiEntityResult<void> =>
    api.post("/guest-domains/create-password", { token, password })
);

export const updateLoggedInUser = unwrapEntity(
  (data: Partial<UserReduced>): ApiEntityResult<UserFull> => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData.append(key, (data as any)[key]);
    });

    return api.put("/users/me", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
);

// TODO missing type
export const getLoggedInUserStats = unwrapEntity(
  (): ApiEntityResult<object> => api.get("/stats/user")
);

export const logout = unwrapEntity(
  (): ApiEntityResult<void> => api.get("/logout")
);

export const isUserLoggedIn = unwrapEntity(
  (): ApiEntityResult<void> => api.get("/is-logged-in")
);

export const updateUser = unwrapEntity(
  (id: number, data: Partial<UserReduced>): ApiEntityResult<UserFull> =>
    api.put(`/users/${id}`, data)
);

export const createUser = unwrapEntity(
  (data: Partial<UserReduced>): ApiEntityResult<UserFull> =>
    api.post("/users", data)
);

export const deleteUser = unwrapEntity(
  ({ id }: ApiDeleteEntityParameters): ApiEntityResult<void> =>
    api.delete(`/users/${id}`)
);

export const sendConfirmationEmail = unwrapEntity(
  (id: number): ApiEntityResult<void> =>
    api.post(`/users/${id}/send-confirmation-email`)
);

// TODO missing type
export const getShares = unwrapEntity(
  (): ApiEntityResult<object> => api.get("/stats/new-shares")
);

/*
 * Presentations
 */

export const getPresentation = unwrapEntity(
  (id: number): ApiEntityResult<PresentationFull> =>
    api.get(`/presentations/${id}`)
);

export const getPresentationPin = unwrapEntity(
  (id: number): ApiEntityResult<{ pin: number }> =>
    api.get(`/presentations/${id}/pin`)
);

export const getPresentations = unwrapList(
  (
    startIndex: number,
    stopIndex: number,
    type: PresentationsListType,
    search: string | null,
    customFieldsFilter: object | null // TODO better type
  ): ApiListResult<PresentationReduced[]> =>
    api.get(
      `/presentations?${qs.stringify({
        startIndex,
        stopIndex,
        type,
        search,
        customFieldsFilter:
          customFieldsFilter && JSON.stringify(customFieldsFilter)
      })}`
    )
);

export const createPresentation = unwrapEntity(
  (
    data: Partial<PresentationReduced> & {
      customFields: CustomFieldUpdateCommand[];
    }
  ): ApiEntityResult<PresentationFull> => api.post("/presentations", data)
);

export const updatePresentation = unwrapEntity(
  (
    id: number,
    data: Partial<
      PresentationReduced & {
        customFields: CustomFieldUpdateCommand[];
        collaborators: number[];
      }
    >
  ): ApiEntityResult<PresentationFull> => api.put(`/presentations/${id}`, data)
);

export const deletePresentation = unwrapEntity(
  (id: number): ApiEntityResult<void> => api.delete(`/presentations/${id}`)
);

export const sharePresentation = unwrapEntity(
  (presentationId: number, userId: number): ApiEntityResult<PresentationFull> =>
    api.put(`/presentations/${presentationId}/share/${userId}`)
);

export const requestPublishPresentation = unwrapEntity(
  (presentationId: number, userId: number): ApiEntityResult<PresentationFull> =>
    api.put(`/presentations/${presentationId}/request-publish/${userId}`)
);

export const deletePresentationShare = unwrapEntity(
  (presentationId: number): ApiEntityResult<void> =>
    api.delete(`/presentations/${presentationId}/share`)
);

export const presentationPublish = unwrapEntity(
  (presentationId: number): ApiEntityResult<PresentationFull> =>
    api.put(`/presentations/${presentationId}/publish`)
);

export const presentationUnpublish = unwrapEntity(
  (presentationId: number): ApiEntityResult<PresentationFull> =>
    api.put(`/presentations/${presentationId}/unpublish`)
);

export const presentationClone = unwrapEntity(
  (
    presentationId: number,
    data: Partial<PresentationReduced> & {
      instances: string[];
    }
  ): ApiEntityResult<PresentationFull> =>
    api.put(`/presentations/${presentationId}/clone`, data)
);

/*
 * PresentationScenes
 */

export const updatePresentationScenesOrder = unwrapEntity(
  (
    presentationId: number,
    sceneId: number,
    order: number
  ): ApiEntityResult<PresentationFull> =>
    api.post(`/presentations/${presentationId}/scenes/${sceneId}/order`, {
      order
    })
);

export const createPresentationScene = unwrapEntity(
  (
    sceneId: number,
    presentationId: number,
    data: { isDefault: boolean; autoswitch: boolean },
    existing: boolean
  ): ApiEntityResult<PresentationFull> =>
    api.put(`/presentations/${presentationId}/scenes/${sceneId}`, {
      ...data,
      existing
    })
);

export const deletePresentationScene = unwrapEntity(
  (sceneId: number, presentationId: number): ApiEntityResult<void> =>
    api.delete(`/presentations/${presentationId}/scenes/${sceneId}`)
);

export const updatePresentationSceneChangeAutoSwitch = unwrapEntity(
  (
    sceneId: number,
    presentationId: number,
    autoswitch: boolean
  ): ApiEntityResult<PresentationFull> =>
    api.post(`/presentations/${presentationId}/scenes/${sceneId}/autoswitch`, {
      autoswitch
    })
);

export const updatePresentationSceneChangeDefault = unwrapEntity(
  (
    sceneId: number,
    presentationId: number,
    isDefault: boolean
  ): ApiEntityResult<PresentationFull> =>
    api.put(`/presentations/${presentationId}/scenes/${sceneId}/default`, {
      isDefault
    })
);

/*
 * Presentation asset actions
 */
export const updateAssetActions = unwrapEntity(
  (
    presentationId: number,
    sceneAssetId: number,
    payload: object // TODO better type
  ): ApiEntityResult<PresentationFull> =>
    api.put(
      `/presentations/${presentationId}/asset-actions/${sceneAssetId}`,
      payload
    )
);

/*
 * PresentationSceneConnections
 */
export const createPresentationSceneConnection = unwrapEntity(
  (
    presentationId: number,
    outboundSceneId: number,
    inboundSceneId: number
  ): ApiEntityResult<PresentationFull> =>
    api.post(`/presentations/${presentationId}/scene-connections/`, {
      outboundSceneId,
      inboundSceneId
    })
);

export const updatePresentationSceneConnection = unwrapEntity(
  (
    presentationId: number,
    connectionId: number,
    { order }: { order: number }
  ): ApiEntityResult<PresentationFull> =>
    api.put(
      `/presentations/${presentationId}/scene-connections/${connectionId}`,
      { order }
    )
);

export const deletePresentationSceneConnection = unwrapEntity(
  (presentationId: number, connectionId: number): ApiEntityResult<void> =>
    api.delete(
      `/presentations/${presentationId}/scene-connections/${connectionId}`
    )
);

/*
 * CustomFields
 */

export const fetchCustomFields = unwrapList(
  (): ApiListResult<CustomFieldReduced[]> => api.get("/custom-fields")
);

interface SelectedCustomFields {
  [key: string]: number[] | string[];
}

export const searchChoiceCustomFields = unwrapEntity(
  (
    entityMappingId: number,
    searchPhrase: string | null,
    selectedCustomFields: SelectedCustomFields,
    globalSearch: string,
    additionalFilterData: object,
    entity: CustomFieldEntity,
    category: string | null
  ): ApiEntityResult<ApiListPayload<CustomFieldReduced[]>> =>
    api.post(
      `/custom-fields/entity-mappings/${entityMappingId}/choice-values`,
      {
        search: searchPhrase,
        selectedCustomFields,
        globalSearch,
        additionalFilterData,
        entity,
        category
      }
    )
);

export const searchTextCustomFields = unwrapEntity(
  (
    entityMappingId: number,
    searchPhrase: string | null,
    selectedCustomFields: SelectedCustomFields,
    globalSearch: string,
    additionalFilterData: object,
    entity: CustomFieldEntity,
    category: string | null
  ): ApiEntityResult<ApiListPayload<CustomFieldReduced[]>> =>
    api.post(`/custom-fields/entity-mappings/${entityMappingId}/text-values`, {
      search: searchPhrase,
      selectedCustomFields,
      globalSearch,
      additionalFilterData,
      CustomFieldEntity,
      entity,
      category
    })
);

export const validateFilterLabel = unwrapEntity(
  (
    label: string,
    entity: CustomFieldEntity
  ): ApiEntityResult<{ valid: boolean }> =>
    api.post("/custom-fields/validate", { label, entity })
);

export const createCustomField = unwrapEntity(
  (data: {
    type: CustomFieldType;
    label: string;
    required: boolean;
    filterable: boolean;
  }): ApiEntityResult<CustomFieldFull> => api.post(`/custom-fields/`, data)
);

export const validateCategoryLabel = unwrapEntity(
  (data: {
    label: string;
    parentId: number | null;
  }): ApiEntityResult<{ valid: boolean }> =>
    api.post(`/custom-fields/category/validate`, data)
);

export const createCategory = unwrapEntity(
  (data: {
    label: string;
    path: string;
    parentId: number | null;
    entity: string;
  }): ApiEntityResult<CustomFieldFull> =>
    api.post(`/custom-fields/category`, data)
);

export const editCategory = unwrapEntity(
  (
    id: number,
    data: {
      label: string;
      path: string;
      parentId: number | null;
    }
  ): ApiEntityResult<CustomFieldFull> =>
    api.put(`/custom-fields/category/${id}`, data)
);

export const deleteCategoryCount = unwrapEntity(
  (id: number): ApiEntityResult<any> =>
    api.get(`/custom-fields/category/count/${id}`)
);

export const deleteCategory = unwrapEntity(
  (id: number): ApiEntityResult<void> =>
    api.delete(`/custom-fields/category/${id}`)
);

export const deleteCustomFieldChoiceCount = unwrapEntity(
  (id: number): ApiEntityResult<{ count: number }> =>
    api.get(`/custom-fields/delete-choice-count/${id}`)
);

export const editCustomField = unwrapEntity(
  (
    id: number,
    data: {
      label: string;
      required: boolean;
      filterable: boolean;
      choices: string[];
    }
  ): ApiEntityResult<CustomFieldFull> => api.put(`/custom-fields/${id}`, data)
);

export const editCustomFieldMapping = unwrapEntity(
  (
    id: number,
    data: {
      label: string;
      entity: string;
      required: boolean;
      filterable: boolean;
      choices: string[];
    }
  ): ApiEntityResult<CustomFieldFull> =>
    api.put(`/custom-fields/entity-mappings/${id}`, data)
);

export const deleteCustomFieldCount = unwrapEntity(
  (id: number): ApiEntityResult<{ count: number }> =>
    api.get(`/custom-fields/delete-count/${id}`)
);

export const deleteCustomField = unwrapEntity(
  (id: number): ApiEntityResult<void> => api.delete(`/custom-fields/${id}`)
);

export const assignCustomField = unwrapEntity(
  (
    id: number,
    data: { categoryId: number; filterId: number }
  ): ApiEntityResult<void> => api.put(`/custom-fields/assign/${id}`, data)
);

export const unAssignCustomFieldCount = unwrapEntity(
  (id: number): ApiEntityResult<{ count: number }> =>
    api.get(`/custom-fields/unassign/${id}`)
);

export const unAssignCustomField = unwrapEntity(
  (id: number): ApiEntityResult<void> =>
    api.delete(`/custom-fields/assign/${id}`)
);

export const switchCustomFieldSort = unwrapEntity(
  (data: { firstId: number; secondId: number }): ApiEntityResult<void> =>
    api.put(`/custom-fields/${data.firstId}/sort`, data)
);

interface BulkUpdateCategories {
  [key: string]: { [key: number]: number };
}

interface BulkUpdateFilters {
  [key: string]: { [key: string]: string };
}

interface BulkUpdateTitles {
  [key: string]: string;
}

export const bulkUpdateSharedMediaAssets = unwrapAny(
  (
    selectedCategories: BulkUpdateCategories,
    selectedFilters: BulkUpdateFilters,
    assetTitles: BulkUpdateTitles
  ) =>
    api.put(`/assets/shared-assets/bulk`, {
      selectedCategories,
      selectedFilters,
      assetTitles
    })
);

export const bulkDeleteSharedMediaAssets = unwrapAny((assetUuids: string[]) =>
  api.put(`/assets/shared-assets/bulk/delete`, {
    assetUuids
  })
);

export const createGuestDomain = unwrapEntity<
  [GuestDomainFormData],
  GuestDomainFull
>((formData: GuestDomainFormData) => api.post(`/guest-domains`, formData));

export const isGuestDomainNameUnique = unwrapGenericJson<
  [UniqueGuestDomainNameRequest],
  IsUniqueResponse
>((data: UniqueGuestDomainNameRequest) =>
  api.post(`/guest-domains/unique-name`, data)
);

export const isGuestDomainAdminUnique = unwrapGenericJson<
  [UniqueGuestDomainAdminRequest],
  IsUniqueResponse
>((data: UniqueGuestDomainAdminRequest) =>
  api.post(`/guest-domains/unique-admin`, data)
);

export const fetchGuestDomainsList = unwrapList<
  [ApiListEntityParameters],
  GuestDomainFull[]
>(({ startIndex, stopIndex, search }: ApiListEntityParameters) =>
  api.get(
    `/guest-domains?${qs.stringify({
      startIndex,
      stopIndex,
      search
    })}`
  )
);

export const fetchGuestDomainDetail = unwrapEntity<
  [ApiDetailParameters],
  GuestDomainFull
>(({ id }: ApiDetailParameters) => api.get(`/guest-domains/${id}`));

export const editGuestDomain = unwrapEntity<
  [ApiEditEntityParameters<GuestDomainFormData>],
  GuestDomainFull
>(({ id, data }) => api.put(`/guest-domains/${id}`, data));

export const deleteGuestDomain = unwrapEntity<
  [ApiDeleteEntityParameters],
  GuestDomainFull
>(({ id }) => api.delete(`/guest-domains/${id}`));

export const fetchSignUpLink = unwrapEntity<
  [],
  { token: string; allowedEmailDomains: string[] }
>(() => api.get(`/guest-domains/sign-up-link`));

export const generateNewSignUpLink = unwrapGenericJson<
  [{ allowedEmailDomains: string }],
  SignUpLinkFull
>((body) => api.post(`/guest-domains/generate-sign-up-link`, body));

export const updateSignUpDomains = unwrapGenericJson<
  [{ allowedEmailDomains: string }],
  SignUpLinkFull
>((body) => api.put(`/guest-domains/sign-up-link`, body));

export const selfSignUp = unwrapGenericJson<
  [
    {
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      token?: string;
      ssoSignup: boolean;
    }
  ],
  {}
>((body) => api.post(`/users/sign-up`, body));

interface ApiListEntityParametersTriggers extends ApiListEntityParameters {
  instanceUuids?: string[];
}

export const getTriggers = unwrapList(
  (listParams: ApiListEntityParametersTriggers): ApiListResult<TriggerFull[]> =>
    api.get(
      `/triggers?${qs.stringify({
        search: listParams.search,
        startIndex: listParams.startIndex,
        stopIndex: listParams.stopIndex,
        instanceUuids: listParams.instanceUuids
      })}`
    )
);

export const createTrigger = unwrapEntity(
  (data: TriggersFormData): ApiEntityResult<TriggerFull> =>
    api.post(`/triggers`, data)
);

export const deleteTrigger = unwrapEntity(
  ({ id }: ApiDeleteEntityParameters): ApiEntityResult<void> =>
    api.delete(`/triggers/${id}`)
);

export const updateTrigger = unwrapEntity(
  (
    data: ApiEditEntityParameters<TriggersFormData>
  ): ApiEntityResult<TriggerFull> => api.put(`/triggers/${data.id}`, data.data)
);

export const getTrigger = unwrapEntity(
  ({ id }: ApiDetailParameters): ApiEntityResult<TriggerFull> =>
    api.get(`/triggers/${id}`)
);

export const getAffectedPresentationSceneTriggersCount = unwrapGenericJson<
  [number, string],
  { triggersToDelete: number }
>((id, instanceUuid) => api.get(`/triggers/affected/${id}/${instanceUuid}`));

export const updatePresentationSceneTrigger = unwrapEntity<
  [number, number, number | null],
  PresentationFull
>((presentationId: number, sceneId: number, triggerId: number | null) =>
  api.put(`/triggers/${presentationId}/${sceneId}`, { triggerId })
);

export const updatePresentationTriggers = unwrapEntity<
  [number, TriggersManagementFormProps],
  PresentationFull
>((presentationId: number, data: TriggersManagementFormProps) =>
  api.put(`/presentations/${presentationId}/triggers`, data)
);

export const isUniqueTrigger = unwrapGenericJson<
  [number | undefined, string, string],
  { unique: boolean }
>((id, instanceUuid, triggerId) =>
  api.post("/triggers/unique", { id, instanceUuid, triggerId })
);

export const getEditorSettings = unwrapGenericJson<[], EditorSettings>(() =>
  api.get("/editor-settings")
);

export const setEditorSettings = unwrapGenericJson<
  [EditorSettings],
  EditorSettings
>((data) => api.post("/editor-settings", data));
