export const getMainRole = (user) => {
  const roles = user.roles;

  if (roles.indexOf("SUPER_ADMIN") >= 0) {
    return "SUPER_ADMIN";
  } else if (roles.indexOf("ADMIN") >= 0) {
    return "ADMIN";
  } else if (roles.includes("GUEST_DOMAIN_SUPER_ADMIN")) {
    return "GUEST_DOMAIN_SUPER_ADMIN";
  }

  return "USER";
};

export default {
  getMainRole
};
