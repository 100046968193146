const validate = (roomName) => (values) => {
  const errors = {};

  if (
    (!values.title || values.title === "") &&
    (!values.title_text || values.title_text.trim() === "")
  ) {
    errors.title_text = "Please provide title or title of table position";
  }

  if (!values.instanceUuid) {
    errors.instanceUuid = `Please provide ${roomName}`;
  }

  return errors;
};

export default validate;
