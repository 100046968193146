import { Logger as logger } from "purplex-logging";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "redux-router5";
import cx from "classnames";

import { DeleteRecordCommand } from "client/modules/crud/use-delete-records";
import { useConfirmationDialog } from "client/modules/dialogs/dialog-hooks";
import { DialogResult } from "client/modules/dialogs/event-types";
import {
  TRIGGERS_CREATE_ROUTE,
  TRIGGERS_EDIT_ROUTE
} from "client/modules/routing/route-names";
import { useNotifications } from "client/modules/ux/notifications/use-notifications";
import { TriggerFull } from "shared/types/trigger";
import { useExpandableInstanceList } from "../hooks/use-expandable-instance-list";

import { ReactComponent as IconAdd } from "client/images/add.svg";
import { ReactComponent as IconCaret } from "client/images/caret-right.svg";
import { ReactComponent as IconDelete } from "client/images/btn-delete.svg";

interface TriggersListProps {
  triggers: TriggerFull[];
  deleteRecordCommand: DeleteRecordCommand;
}

export const TriggersList = ({
  triggers,
  deleteRecordCommand
}: TriggersListProps) => {
  const dispatch = useDispatch();
  const { instances, toggleInstance } = useExpandableInstanceList();

  const addTrigger = useCallback(() => {
    dispatch(routerActions.navigateTo(TRIGGERS_CREATE_ROUTE));
  }, [dispatch]);

  const confirmDialog = useConfirmationDialog();
  const notifications = useNotifications();

  const onRequestDelete = useCallback(
    async (id: number, name: string) => {
      const { type } = await confirmDialog({
        title: "Delete Trigger",
        text: `Are you sure you want to delete trigger "${name}"?`,
        subtext: "",
        confirmLabel: "Delete",
        cancelLabel: "Cancel"
      });

      if (type === DialogResult.CONFIRM) {
        const { success } = await deleteRecordCommand.deleteRecord({ id });
        if (!success) {
          logger.error("Error during deleting of trigger");
          notifications.notifyError(
            "There was an error during deleting of the trigger."
          );
        } else {
          notifications.notifySuccess("Trigger has been successfully deleted");
        }
      }
    },
    [deleteRecordCommand, confirmDialog, notifications]
  );

  const onEditTrigger = useCallback(
    (id: number) => {
      dispatch(routerActions.navigateTo(TRIGGERS_EDIT_ROUTE, { id }));
    },
    [dispatch]
  );

  return (
    <div className="subpage">
      <header className="pageHeader">
        <h1 className="pageHeading" data-qa="tab-header">
          Triggers
        </h1>
        <div>
          <button
            className="button"
            onClick={addTrigger}
            data-qa="add-new-trigger-btn"
          >
            <IconAdd />
            Add Trigger
          </button>
        </div>
      </header>

      <div className="pageContent">
        <ul className="triggersList">
          {instances.map((instance, index) => {
            const instanceTriggers = triggers.filter(
              (trigger) => trigger.instanceUuid === instance.uuid
            );

            return (
              <li key={instance.uuid} className="triggersList__room">
                <h3
                  className={cx("triggersList__toggle", {
                    "is-active": instance.expanded
                  })}
                  data-qa={`triggers-expand-room-${instance.uuid}`}
                  onClick={() => toggleInstance(index)}
                >
                  {instance.name}
                  <IconCaret />
                </h3>
                {instance.expanded && instanceTriggers.length < 1 && (
                  <p className="triggersList__items">
                    No triggers are assigned to this room
                  </p>
                )}
                {instance.expanded && instanceTriggers.length > 0 && (
                  <ul className="triggersList__items">
                    {instanceTriggers.map((trigger, index) => (
                      <li
                        key={trigger.id}
                        className="gridRow"
                        data-qa={`triggers-list-item-${trigger.id}`}
                        style={{
                          ["--delay" as string]: `${0.05 * index}s`
                        }}
                      >
                        <div className="gridCell">
                          Name
                          <h4 data-qa="triggers-list-item-title">
                            {trigger.name}
                          </h4>
                        </div>
                        <div className="gridCell">
                          ID
                          <h4>{trigger.triggerId}</h4>
                        </div>
                        <div className="gridCell">
                          <button
                            type="button"
                            className="button"
                            onClick={() => onEditTrigger(trigger.id)}
                            data-qa="triggers-list-item-edit-button"
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="button buttonIcon buttonOutlined buttonAlert"
                            data-qa="triggers-list-item-delete-button"
                            onClick={() =>
                              onRequestDelete(trigger.id, trigger.name)
                            }
                          >
                            <IconDelete />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
