import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "rc-tooltip";

import { getOrderedInstances } from "../../../entity-repository/entity-repository-selectors";
import { WallDimensionsStatus } from "./wall-dimensions-status";
import { CompatibilityInfo } from "./wall-dimensions-compatibility-info";
import { isWallAssetOptimalForInstance } from "./wall-dimensions-validity";
import { AssetReduced } from "../../../../../shared/types/asset";

export const InstancesWallStatuses = (props: { wallAsset: AssetReduced }) => {
  const { wallAsset } = props;

  const instances = useSelector(getOrderedInstances);

  if (!instances) {
    return <></>;
  }

  return (
    <Tooltip
      overlayClassName="instances-wall-statuses"
      trigger={["click"]}
      overlay={
        <div className="list">
          <h5>IX Compatible</h5>
          {instances.map((instance) => (
            <div className="item" key={instance.uuid}>
              <WallDimensionsStatus
                isValid={isWallAssetOptimalForInstance(
                  wallAsset.dimensions.width,
                  instance.roomDimensions.width
                )}
                okMessage="File is wide enough for this room."
                warningMessage="File doesn't have recommended dimensions (at least 80% of the room width), this will result in poor quality."
              />
              {instance.name}
            </div>
          ))}
        </div>
      }
    >
      <div className="wall-dimension-status-wrapper">
        <CompatibilityInfo />
      </div>
    </Tooltip>
  );
};
