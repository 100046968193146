import React from "react";
import { connect, useSelector } from "react-redux";
import { actions as routerActions } from "redux-router5";
import PropTypes from "prop-types";
import cx from "classnames";

import { DashboardTileProfile } from "./dashboard-tile-profile";
import { DashboardTileStats } from "./dashboard-tile-stats";
import { DashboardTileReporting } from "./dashboard-tile-reporting";
import { DashboardTileVix } from "./dashboard-tile-vix";
import { withAcl } from "../../acl/with-acl";
import { hasVix } from "../../config/config-selectors";
import { AUTH_USER_PROFILE_ROUTE } from "../../routing/route-names";

import { AclResource } from "../../../../shared/acl";
import { getRouteParams } from "client/modules/routing/routing-selectors";

import { ReactComponent as IconAlert } from "client/images/alert-circle.svg";

const UserAccountMergedLandingBox = () => {
  const params = useSelector(getRouteParams);

  return Object.keys(params).some((param) => param === "merged") ? (
    <div className="alert --warning">
      <IconAlert className="ico" />
      Your Hyro account is now connected to your Single Sign-on account.
      <br />
      You can only login via SSO.
    </div>
  ) : (
    <></>
  );
};

const DashboardImpl = ({ hasAccess, hasVix, goToEditProfile }) => {
  const params = useSelector(getRouteParams);
  const hasUserAccountMergeBox =
    Object.keys(params).some((param) => param === "merged") || false;

  return (
    <div>
      <div
        className={cx("pageHeader", { "has-alert": hasUserAccountMergeBox })}
      >
        <h1 className="pageTitle" data-qa="tab-header">
          Your Dashboard
        </h1>
        {hasUserAccountMergeBox && <UserAccountMergedLandingBox />}
        <div className="actionButtons">
          <button type="button" className="button" onClick={goToEditProfile}>
            Edit Profile
          </button>
        </div>
      </div>
      <div className="cardContainer">
        <DashboardTileProfile className="Card" />
        <DashboardTileStats />
        {hasAccess(AclResource.DOWNLOAD_PRESENTATIONS_REPORT) && (
          <DashboardTileReporting />
        )}
        {hasVix && <DashboardTileVix />}
      </div>
    </div>
  );
};

DashboardImpl.propTypes = {
  goToEditProfile: PropTypes.func.isRequired,
  hasAccess: PropTypes.func.isRequired,
  hasVix: PropTypes.bool.isRequired
};

export const Dashboard = connect(
  (state) => ({
    hasVix: hasVix(state)
  }),
  {
    goToEditProfile: () => routerActions.navigateTo(AUTH_USER_PROFILE_ROUTE)
  }
)(withAcl(DashboardImpl));
