import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EditedBackgroundState,
  getBackgroundSettingsUuid,
  getBackgroundState,
  getEditedBackgroundValidity,
  getInstanceWallHeight,
  getIsEditedBackgroundLowResolution,
  getSceneInstanceWalls,
  getTotalInstanceWidth
} from "../../../scene-editor-selectors";
import { EditBackgroundWallAsset } from "./edit-background-wall-asset";
import { EditBackgroundWall } from "./edit-background-wall";
import { EditBackgroundScheme } from "./edit-background-scheme";
import { EditBackgroundGuide } from "./edit-background-guide";
import { useRenderCount } from "../../../utils";
import { useEditBackgroundPositioning } from "../../../hooks/dragging/use-edit-background-positioning";
import { SceneEditorActions } from "../../../scene-editor-reducer";

import { ReactComponent as IconAlertCircle } from "client/images/alert-circle.svg";

const EDIT_BACKGROUND_SCENE_PADDING = 100;
const HORIZONTAL_GUIDE_OVERLAP = 200;
const VERTICAL_GUIDE_OVERLAP = 200;
const CANVAS_HEIGHT_LIMIT = 0.8;

const EditBackgroundMessages = {
  invalidState: `Background needs to cover whole width of the room. Changes will not be saved.`,
  resolutionWarning: `Attention! This image is quite small which will result in poor image quality. Use a larger image to get a better experience.`
};

const getEditBackgroundScreenScale = (
  screenWidth: number,
  screenHeight: number,
  padding: number,
  totalWidth: number,
  height: number
) => {
  if (totalWidth === 0) {
    return 1;
  }

  const widthLimitedScale = (screenWidth - padding) / totalWidth;

  if (height * widthLimitedScale > CANVAS_HEIGHT_LIMIT * screenHeight) {
    return (CANVAS_HEIGHT_LIMIT * screenHeight) / height;
  }

  return widthLimitedScale;
};

export const EditBackgroundSceneView = (props: {
  width: number;
  height: number;
  ratioLocked: boolean;
  onUpdateBackground: (data: {
    left?: number;
    top?: number;
    height?: number;
    width?: number;
  }) => void;
}) => {
  const { width, height, onUpdateBackground, ratioLocked } = props;

  const instanceWalls = useSelector(getSceneInstanceWalls);
  const totalWidth = useSelector(getTotalInstanceWidth) || 1;
  const backgroundState = useSelector(getBackgroundState) || {
    top: 0,
    left: 0,
    width: 0,
    height: 0
  };
  const wallHeight = useSelector(getInstanceWallHeight) || 0;
  const wallWidth = useSelector(getTotalInstanceWidth) || 0;
  const isEditedBackgroundLowResolution = useSelector(
    getIsEditedBackgroundLowResolution
  );
  const stateValidity = useSelector(getEditedBackgroundValidity);
  const uuid = useSelector(getBackgroundSettingsUuid);

  const dispatch = useDispatch();
  const renderCount = useRenderCount();

  const screenScale = getEditBackgroundScreenScale(
    width,
    height,
    EDIT_BACKGROUND_SCENE_PADDING,
    totalWidth,
    wallHeight
  );

  const {
    mouseDownHandler,
    draggingState,
    isVerticalCenterGuideShown,
    isHorizontalCenterGuideShown
  } = useEditBackgroundPositioning(
    screenScale,
    wallHeight,
    wallWidth,
    backgroundState.height,
    backgroundState.width,
    backgroundState.top,
    backgroundState.left,
    onUpdateBackground
  );

  useEffect(() => {
    dispatch(SceneEditorActions.setEditBackgroundCanvasScale(screenScale));
  }, [dispatch, screenScale]);

  const calculatedHorizontalPadding = width - screenScale * totalWidth;

  const isMessageShown =
    (stateValidity === EditedBackgroundState.INVALID && uuid) ||
    isEditedBackgroundLowResolution;
  return (
    <>
      {renderCount}
      {isMessageShown && (
        <div
          className="alert --warning"
          style={{
            top: 100
          }}
        >
          <IconAlertCircle className="ico" />
          <div className="alert__body">
            {/* <h5 className="alert__title"></h5> */}
            <p>
              {stateValidity === EditedBackgroundState.INVALID &&
                uuid &&
                EditBackgroundMessages.invalidState}
              {isEditedBackgroundLowResolution &&
                EditBackgroundMessages.resolutionWarning}
            </p>
          </div>
        </div>
      )}
      <EditBackgroundScheme
        state={stateValidity}
        x={calculatedHorizontalPadding / 2}
        y={height / 2 - (wallHeight / 2) * screenScale}
        width={totalWidth * screenScale}
        height={wallHeight * screenScale}
      />
      <EditBackgroundWallAsset
        height={height}
        screenScale={screenScale}
        padding={calculatedHorizontalPadding}
        onDraggingStart={mouseDownHandler}
        draggingOffsetX={draggingState.draggingOffsetX}
        draggingOffsetY={draggingState.draggingOffsetY}
        onUpdateBackground={onUpdateBackground}
        ratioLocked={ratioLocked}
      />
      {instanceWalls &&
        instanceWalls.map((wall) => {
          return (
            <EditBackgroundWall
              key={wall.num}
              wall={wall}
              screenScale={screenScale}
              height={height}
              padding={calculatedHorizontalPadding}
            />
          );
        })}
      {isHorizontalCenterGuideShown && (
        <EditBackgroundGuide
          type="horizontal"
          x={(calculatedHorizontalPadding - HORIZONTAL_GUIDE_OVERLAP) / 2}
          y={height / 2}
          width={wallWidth * screenScale + HORIZONTAL_GUIDE_OVERLAP}
        />
      )}
      {isVerticalCenterGuideShown && (
        <EditBackgroundGuide
          type="vertical"
          x={calculatedHorizontalPadding / 2 + (wallWidth / 2) * screenScale}
          y={
            height / 2 -
            (wallHeight / 2) * screenScale -
            VERTICAL_GUIDE_OVERLAP / 2
          }
          height={wallHeight * screenScale + VERTICAL_GUIDE_OVERLAP}
        />
      )}
    </>
  );
};
