import { useCallback } from "react";
import { useDispatch, useStore } from "react-redux";

import { getActiveWallNumber, getSceneAssets } from "../scene-editor-selectors";
import { SceneEditorActions } from "../scene-editor-reducer";

export const useSelectAsset = () => {
  const store = useStore();
  const dispatch = useDispatch();

  return useCallback(
    (uuid: string) => {
      const activeWallNumber = getActiveWallNumber(store.getState());
      const sceneAssets = getSceneAssets(store.getState());

      const asset = sceneAssets.find((asset) => asset.uuid === uuid);

      if (!asset || !asset.wall) {
        return;
      }

      if (asset.wall === activeWallNumber) {
        dispatch(SceneEditorActions.selectAsset({ sceneAssetId: uuid }));
        return;
      }

      if (asset.wall !== activeWallNumber) {
        dispatch(SceneEditorActions.selectAsset({ sceneAssetId: uuid }));
        dispatch(
          SceneEditorActions.wallChanged({ activeWall: asset.wall - 1 })
        );
        return;
      }
    },
    [dispatch, store]
  );
};

export const useSelectMultipleAssets = () => {
  const store = useStore();
  const dispatch = useDispatch();

  return useCallback(
    (uuid: string) => {
      const activeWallNumber = getActiveWallNumber(store.getState());
      const sceneAssets = getSceneAssets(store.getState());

      const asset = sceneAssets.find((asset) => asset.uuid === uuid);

      if (!asset || !asset.wall) {
        return;
      }

      if (asset.wall === activeWallNumber) {
        dispatch(
          SceneEditorActions.selectMultipleAssets({ sceneAssetId: [uuid] })
        );
        return;
      }
    },
    [dispatch, store]
  );
};

export const useDeselectAssets = () => {
  const dispatch = useDispatch();

  return useCallback(
    (uuids: string[]) => {
      dispatch(SceneEditorActions.deselectAsset(uuids));
    },
    [dispatch]
  );
};

export const useDeselectAllAssets = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(SceneEditorActions.deselectAllAssets());
  }, [dispatch]);
};
