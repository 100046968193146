import React from "react";
import { connect } from "react-redux";
import { formValues } from "redux-form";
import PropTypes from "prop-types";

import { SelectRoomField } from "../../forms/select-room-field";
import { ToggleField } from "../../forms/toggle-field";
import { TextField } from "../../forms/text-field";
import { DropdownField } from "../../forms/dropdown-field";
import { TextareaField } from "../../forms/textarea-field";
import { withAcl } from "../../acl/with-acl";
import { getBranding } from "../../config/config-selectors";
import { getPredefinedSceneNamesList } from "../../settings/predefined-scene-names/predefined-scene-names-selectors";

import { AclResource } from "../../../../shared/acl";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

const SceneFormImpl = ({
  hasAccess,
  isEdit,
  roomName,
  predefinedScenes,
  presentationId,
  title
}) => {
  const scenePresentation = Boolean(presentationId);
  const isPredefinedSceneSelected = () => !!title;

  return (
    <div>
      <SelectRoomField
        disabled={isEdit}
        name="instanceUuid"
        required
        isClearable
        isMulti={false}
        label={roomName}
        dataQa={FORM_LOCATORS.inputs.getInput("room")}
      />

      <DropdownField
        name="title"
        required
        label="Title of table position"
        dataQa={FORM_LOCATORS.select.getSelect("defined-title")}
      >
        <option key="-1" value="">
          Select
        </option>
        {predefinedScenes.map((scene) => (
          <option value={scene.sceneName} key={scene.id}>
            {scene.sceneName}
          </option>
        ))}
      </DropdownField>

      <TextField
        name="title_text"
        maxLength={100}
        label="Title"
        required
        dataQa={FORM_LOCATORS.inputs.getInput("title")}
        disabled={isPredefinedSceneSelected()}
      />

      <TextareaField
        name="description"
        label="Description"
        required
        dataQa={FORM_LOCATORS.inputs.getInput("description")}
      />
      {hasAccess(AclResource.MAKE_SCENE_GLOBAL) && (
        <ToggleField
          name="public"
          dataQa={FORM_LOCATORS.toggles.getToggle("public")}
        >
          Public
          <small>
            Check this feature to make scene available in the Global List.
          </small>
        </ToggleField>
      )}
      {scenePresentation && (
        <ToggleField
          name="autoswitch"
          dataQa={FORM_LOCATORS.toggles.getToggle("autoswitch")}
        >
          Auto Switch
          <small>
            With Auto Switch turned on this scene will automatically switch to
            the next scene when background video is done playing.
          </small>
        </ToggleField>
      )}
      {scenePresentation && (
        <ToggleField
          name="isDefault"
          dataQa={FORM_LOCATORS.toggles.getToggle("is-default")}
        >
          Is Default
          <small>
            A default scene is shown before launching your presentation in the
            room.
          </small>
        </ToggleField>
      )}
    </div>
  );
};

SceneFormImpl.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
  hasAccess: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  predefinedScenes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sceneName: PropTypes.string.isRequired
    })
  ).isRequired,
  presentationId: PropTypes.number
};

const mapStateToProps = (state) => ({
  roomName: getBranding(state).roomName,
  predefinedScenes: getPredefinedSceneNamesList(state)
});

export const SceneForm = withAcl(
  connect(mapStateToProps)(formValues("title")(SceneFormImpl))
);
