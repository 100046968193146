import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { reduxForm, submit } from "redux-form";
import { SelectRoomField } from "../../forms/select-room-field";

import { ModalDialogBase } from "./modal-dialog-base";
import { DialogEvent, DialogResult } from "../event-types";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const FORM_NAME = "room-picker-form";

interface FormData {
  instance: string;
}

interface FormProps {
  filteredInstances: string[];
  onEvent: (type: DialogEvent | DialogResult, payload: string) => void;
}

const validate = (values: FormData) => {
  const errors: Partial<FormData> = {};

  if (!values.instance) {
    errors.instance = "Please, select room";
  }

  return errors;
};

const RoomDialogPickerContent = reduxForm<FormData, FormProps>({
  form: FORM_NAME,
  validate,
  onSubmit: (values, _, props) => {
    props.onEvent(DialogResult.ROOM_SELECTED, values.instance);
  }
})(({ handleSubmit, filteredInstances }) => (
  <form onSubmit={handleSubmit}>
    <SelectRoomField
      name="instance"
      required
      isClearable
      isMulti={false}
      label="Room"
      filteredInstances={filteredInstances}
      help=""
      dataQa={FORM_LOCATORS.inputs.getInput("room")}
    />
  </form>
));

export const RoomPickerDialog = (props: {
  open: boolean;
  title: string;
  confirmLabel: string;
  onEvent: (type: DialogEvent | DialogResult, payload: string) => void;
  filteredInstances: string[];
}) => {
  const { open, title, confirmLabel, onEvent, filteredInstances } = props;

  const dispatch = useDispatch();

  const save = useCallback(() => {
    dispatch(submit(FORM_NAME));
  }, [dispatch]);

  return (
    <ModalDialogBase
      open={open}
      title={title}
      className="modalOverflowVisible"
      text={
        <RoomDialogPickerContent
          onEvent={onEvent}
          filteredInstances={filteredInstances}
        />
      }
      onEvent={onEvent}
      buttons={
        <React.Fragment>
          <button
            className="button"
            onClick={save}
            data-qa={MODAL_LOCATORS.buttons.save}
          >
            {confirmLabel}
          </button>
        </React.Fragment>
      }
    />
  );
};
