import { useRef, useCallback, useEffect } from "react";
import debounce from "lodash/debounce";

/**
 * Creates debounced function
 * @param fn function to be debounced
 * @param inputs array of inputs on which debounced function relies. Whenever
 * objects in this array changes, the debounced function is recreated.
 * @param wait debounce time
 * @param options options of debounce function, format same as options
 * of lodash/debounce function
 * @return {debounced} Returns debounced function
 */
export const useSafeDebounce = (fn, inputs, wait, options) => {
  const prevDebouncedRef = useRef(null);

  // Utilise useCallback to make sure new debounced function is changed everytime input function changes.
  const debounced = useCallback(debounce(fn, wait, options), inputs);

  // When debounced function is changed and there's still old one, let's cancel it
  if (prevDebouncedRef.current && prevDebouncedRef.current !== debounced) {
    prevDebouncedRef.current.cancel();
  }

  prevDebouncedRef.current = debounced;

  useEffect(
    () => () => {
      if (prevDebouncedRef.current) {
        // Upon unmounting, when there's a debounced function, just cancel it.
        prevDebouncedRef.current.cancel();
      }
    },
    []
  );

  return debounced;
};
