import Tooltip from "rc-tooltip";
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLastOpenedContextMenuId } from "./context-menu-selectors";
import Actions from "./context-menu-actions";

class ContextMenu extends React.PureComponent {
  static placement = {
    left: "left",
    right: "right",
    top: "top",
    bottom: "bottom",
    topLeft: "topLeft",
    topRight: "topRight",
    bottomLeft: "bottomLeft",
    bottomRight: "bottomRight"
  };

  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.node.isRequired,
    className: PropTypes.string,
    triggerClass: PropTypes.string,
    placement: PropTypes.oneOf(Object.values(ContextMenu.placement)).isRequired,
    lastOpenedContextMenuId: PropTypes.number,
    onOpen: PropTypes.func.isRequired,
    "data-qa": PropTypes.string
  };

  static defaultProps = {
    placement: ContextMenu.placement.bottomRight
  };

  static nextId = 1;
  id = ContextMenu.nextId++;

  state = {
    opened: false
  };

  contentRef = React.createRef();
  buttonRef = React.createRef();

  toggle = (ev) => {
    ev.preventDefault();
    const shouldOpen = !this.isOpened();
    this.setState({
      opened: shouldOpen
    });
    if (shouldOpen) {
      this.props.onOpen(this.id);
    }
  };

  onWindowClick = (ev) => {
    // any click outside menu will close the menu
    if (
      ev.target &&
      ev.target !== this.contentRef.current &&
      !this.contentRef.current.contains(ev.target) &&
      ev.target !== this.buttonRef.current &&
      !this.buttonRef.current.contains(ev.target)
    ) {
      this.setState({
        opened: false
      });
    }
  };

  isOpened = () =>
    this.state.opened && this.id === this.props.lastOpenedContextMenuId;

  componentDidUpdate(prevProps, prevState) {
    const wasOpened =
      prevState.opened && this.id === prevProps.lastOpenedContextMenuId;

    if (!wasOpened && this.isOpened()) {
      window.addEventListener("click", this.onWindowClick);
    }

    if (wasOpened && !this.isOpened()) {
      window.removeEventListener("click", this.onWindowClick);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }

  render() {
    const { children, title, placement, className, triggerClass } = this.props;

    return (
      <Tooltip
        overlayClassName={cx("context-menu", className)}
        visible={this.isOpened()}
        trigger={["click"]}
        overlay={
          <div className="list" onClick={this.toggle} ref={this.contentRef}>
            {children}
          </div>
        }
        placement={placement}
      >
        <button
          className={cx("context-menu-trigger", triggerClass)}
          onClick={this.toggle}
          ref={this.buttonRef}
          data-qa={this.props["data-qa"] || `filter-${title}`}
        >
          {title}
        </button>
      </Tooltip>
    );
  }
}

ContextMenu.Item = ({ onClick, children, ...rest }) => (
  <button onClick={onClick} {...rest}>
    {children}
  </button>
);

ContextMenu.Item.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

const stopPropagation = (ev) => ev.stopPropagation();

ContextMenu.InteractiveContent = ({ children }) => (
  <div onClick={stopPropagation}>{children}</div>
);

ContextMenu.InteractiveContent.propTypes = {
  children: PropTypes.node
};

export default connect(
  (state) => ({
    lastOpenedContextMenuId: getLastOpenedContextMenuId(state)
  }),
  {
    onOpen: Actions.Creators.setOpenedContextMenu
  }
)(ContextMenu);
