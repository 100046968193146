import Tooltip from "rc-tooltip";
import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { actions as routerActions } from "redux-router5";
import PropTypes from "prop-types";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { FulltextAndFilters } from "../../ux/filters/fulltext-and-filters";

import Actions from "../presentation-actions";
import { withAcl } from "../../acl/with-acl";
import { ListInfiniteScroll, ROW_STATUS } from "../../ux/list-infinite-scroll";
import {
  getPresentations,
  getPresentation,
  presentationClone
} from "../../api/client";
import { DateTimeField } from "../../ux/date-time-field";
import { RelativeTimeField } from "../../ux/relative-time-field";
import { TabPanel, Tabs } from "../../ux/tab-panel";
import { getRouteName, getRouteParams } from "../../routing/routing-selectors";
import { getPresentationListVersion } from "../presentation-selectors";
import {
  getPresentionCollaborations,
  getPresentationShares
} from "../../shares/shares-selectors";
import { CustomFieldListRowFactory } from "../../settings/custom-fields/components/presentations/custom-field-list-row-factory";
import { PRESENTATIONS_LIST_ROUTE } from "../../routing/route-names";

import { ReactComponent as IconTrash } from "../../../images/trash.svg";
import { ReactComponent as IconShare } from "../../../images/share.svg";
import { ReactComponent as IconAdd } from "../../../images/add.svg";

import { AclResource } from "../../../../shared/acl";
import { getPresentationFiltersConfig } from "../../settings/custom-fields/custom-fields-selectors";
import { ClonePresentation } from "./presentation-clone";
import notificationActions from "client/modules/ux/notifications/notification-actions";
import { BusinessError } from "client/modules/api/client-errors";
import { CloningErrors } from "shared/types/bussiness-errors";
import { ROW_LOCATORS } from "shared/tests/locators/row.locators";

const {
  CustomFieldEntity
} = require("../../../../shared/types/custom-field-entity");

const TABS = [
  { route: "own", label: "My Presentations" },
  { route: "public", label: "Global Presentations" },
  { route: "shared", label: "Shared with me" },
  { route: "collaborate", label: "Collaborate" }
];

const CustomFieldListRow = CustomFieldListRowFactory((id, label, value) => (
  <React.Fragment key={id}>
    <hr />
    <span className="gridCell" data-qa={ROW_LOCATORS.getCustomFieldColumn(id)}>
      {label}:
      <h4>
        {value || (
          // thi ensures vertical alignment same for content-less custom fields as for
          // custom fields with content
          <>&nbsp;</>
        )}
      </h4>
    </span>
  </React.Fragment>
));

const PresentationListRow = withAcl((props) => {
  const {
    id,
    title,
    createdAt,
    updatedAt,
    description,
    creator,
    clones,
    public: isPublic,
    publishRequested,
    onEdit,
    onShare,
    onDelete,
    isDeletingInProgress,
    onDeleteShare,
    isShareDeletingInProgress,
    onClone,
    cloneInProgress,
    onPublish,
    isPublishingInProgress,
    onUnpublish,
    isUnpublishingInProgress,
    hasAccess,
    tab,
    customFields
  } = props;

  const rowActions = [];

  const editButton = (
    <button
      className="button"
      key="edit"
      onClick={onEdit}
      data-qa={ROW_LOCATORS.getAction("edit")}
    >
      Edit
    </button>
  );
  const shareButton = (
    <button
      className="button buttonRounded buttonOutlined buttonIcon"
      key="share"
      onClick={onShare}
      title="Share"
      data-qa={ROW_LOCATORS.getAction("share")}
    >
      <IconShare />
    </button>
  );
  const deleteButton = (
    <button
      className={cx("button buttonAlert buttonIcon buttonOutlined", {
        inProgress: isDeletingInProgress
      })}
      key="delete"
      onClick={onDelete}
      data-qa={ROW_LOCATORS.getAction("delete")}
    >
      <IconTrash />
    </button>
  );
  const cloneButton = (
    <button
      className={cx("button", { inProgress: cloneInProgress })}
      key="clone"
      onClick={onClone}
      data-qa={ROW_LOCATORS.getAction("clone")}
    >
      Clone
    </button>
  );
  const publishButton = (
    <button
      className={cx("button", {
        inProgress: isPublishingInProgress
      })}
      key="publish"
      onClick={onPublish}
      data-qa={ROW_LOCATORS.getAction("publish")}
    >
      Publish
    </button>
  );
  const unpublishButton = (
    <button
      className={cx("button", {
        inProgress: isUnpublishingInProgress
      })}
      key="unpublish"
      onClick={onUnpublish}
      data-qa={ROW_LOCATORS.getAction("unpublish")}
    >
      Unpublish
    </button>
  );
  const deleteShare = (
    <button
      className={cx("button", {
        inProgress: isShareDeletingInProgress
      })}
      key="deleteShare"
      onClick={onDeleteShare}
      data-qa={ROW_LOCATORS.getAction("delete-share")}
    >
      Delete Share
    </button>
  );

  switch (tab) {
    case "own":
      rowActions.push(shareButton, editButton, deleteButton);
      break;
    case "public":
      rowActions.push(cloneButton);

      if (hasAccess(AclResource.MAKE_PRESENTATION_GLOBAL)) {
        rowActions.push(unpublishButton);
      }
      break;
    case "shared":
      rowActions.push(cloneButton, deleteShare);

      if (
        hasAccess(AclResource.MAKE_PRESENTATION_GLOBAL) &&
        !isPublic &&
        publishRequested
      ) {
        rowActions.push(publishButton);
      }
      break;
    case "collaborate":
      rowActions.push(shareButton, editButton);
      break;
    default:
      throw new Error(`Unknown tab ${tab}`);
  }

  return (
    <div className="gridRow" data-qa={ROW_LOCATORS.getRow(id)}>
      <span className="gridCell">
        <h3 className="gridRowTitle" data-qa={ROW_LOCATORS.title}>
          {title}
        </h3>
        {tab === "public" && <em>clones #{clones || 0}</em>}
        {(tab === "own" || tab === "collaborate") && isPublic && (
          <em>Public</em>
        )}
        {description}
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.creator}>
        Created by
        <h4>{creator}</h4>
      </span>
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.created}>
        Created
        <Tooltip
          placement="bottomLeft"
          mouseEnterDelay={0.4}
          overlay={DateTimeField({ datetime: createdAt })}
        >
          <h4>
            <RelativeTimeField datetime={createdAt} />
          </h4>
        </Tooltip>
      </span>
      {updatedAt && (
        <>
          <hr />
          <span className="gridCell" data-qa={ROW_LOCATORS.updated}>
            Updated
            <Tooltip
              placement="bottomLeft"
              mouseEnterDelay={0.4}
              overlay={DateTimeField({ datetime: updatedAt })}
            >
              <h4>
                <RelativeTimeField datetime={updatedAt} />
              </h4>
            </Tooltip>
          </span>
        </>
      )}
      <CustomFieldListRow
        customFields={customFields}
        entity={CustomFieldEntity.PRESENTATION}
      />
      <hr />
      <span className="gridCell" data-qa={ROW_LOCATORS.actions}>
        {rowActions}
      </span>
    </div>
  );
});

const PresentationListRowLoading = () => (
  <div className="gridItemPlaceholder"></div>
);

const PresentationListEmpty = () => (
  <div className="gridPlaceholder" data-qa="empty-list-presentation">
    The list is empty
  </div>
);

const mapDispatchToProps = {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  changeRoute: routerActions.navigateTo,
  requestDeletePresentation: Actions.Creators.requestDeletePresentation,
  requestSharePresentation: Actions.Creators.requestSharePresentation,
  publish: Actions.Creators.publishPresentation,
  unpublish: Actions.Creators.unpublishPresentation,
  deleteShare: Actions.Creators.deletePresentationShare,
  notifySuccess: notificationActions.Creators.notifySuccess,
  notifyError: notificationActions.Creators.notifyError
};

const mapStateToProps = (state) => ({
  route: getRouteName(state),
  routeParams: getRouteParams(state),
  presentationListVersion: getPresentationListVersion(state),
  presentationCollaborations: getPresentionCollaborations(state),
  presentationShares: getPresentationShares(state),
  cloneInProgress: getProgressForKeySelector(state)(
    Actions.Types.CLONE_PRESENTATION
  ),
  filterConfig: getPresentationFiltersConfig(state),
  isDeletingInProgress: getProgressForKeySelector(state)(
    Actions.Types.REQUEST_DELETE_PRESENTATION
  ),
  isPublishingInProgress: getProgressForKeySelector(state)(
    Actions.Types.PUBLISH_PRESENTATION
  ),
  isUnpublishingInProgress: getProgressForKeySelector(state)(
    Actions.Types.UNPUBLISH_PRESENTATION
  ),
  isShareDeletingInProgress: getProgressForKeySelector(state)(
    Actions.Types.DELETE_PRESENTATION_SHARE
  )
});

export const PresentationList = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class PresentationListConnected extends React.Component {
    state = {
      filtersAndFulltext: null,
      filtersAndFulltextVersion: 0,
      clonePresentationDialog: false
    };

    static propTypes = {
      changeRoute: PropTypes.func.isRequired,
      route: PropTypes.string.isRequired,
      routeParams: PropTypes.object,
      requestDeletePresentation: PropTypes.func.isRequired,
      requestSharePresentation: PropTypes.func.isRequired,
      publish: PropTypes.func.isRequired,
      unpublish: PropTypes.func.isRequired,
      cloneInProgress: PropTypes.func.isRequired,
      presentationListVersion: PropTypes.number.isRequired,
      deleteShare: PropTypes.func.isRequired,
      presentationCollaborations: PropTypes.number.isRequired,
      presentationShares: PropTypes.number.isRequired,
      filterConfig: PropTypes.any.isRequired,
      isDeletingInProgress: PropTypes.func.isRequired,
      isPublishingInProgress: PropTypes.func.isRequired,
      isUnpublishingInProgress: PropTypes.func.isRequired,
      isShareDeletingInProgress: PropTypes.func.isRequired,
      notifySuccess: PropTypes.func.isRequired,
      notifyError: PropTypes.func.isRequired
    };

    addPresentation = () => {
      this.props.changeRoute(`${this.props.route}.add`, this.props.routeParams);
    };

    onFiltersAndFulltextChange = (filtersAndFulltext) => {
      this.setState(({ filtersAndFulltextVersion }) => ({
        filtersAndFulltext,
        filtersAndFulltextVersion: filtersAndFulltextVersion + 1
      }));
    };

    onRequestClonePresentation = async (id) => {
      const presentation = await getPresentation(id);

      if (presentation.instances.length <= 1) {
        this.clonePresentation(id, presentation.instances);
      } else {
        this.setState({
          clonePresentationDialog: {
            instances: presentation.instances,
            id: presentation.id,
            title: presentation.title
          }
        });
      }
    };

    onCloseCloneDialog = () => {
      this.setState({ clonePresentationDialog: false });
    };

    clonePresentation = async (id, title, instances) => {
      try {
        await presentationClone(id, {
          instances: instances
        });
        this.props.notifySuccess(`Presentation ${title} has been cloned`);
      } catch (e) {
        if (e instanceof BusinessError) {
          const error = e.error;
          if (error === CloningErrors.NOT_READY_ASSET) {
            this.props.notifyError(
              `Presentation ${title} has not been cloned because its assets are currently being processed.`
            );
          }
          if (error === CloningErrors.CORRUPTED_ASSET) {
            this.props.notifyError(
              `Presentation ${title} has not been cloned because its assets are corrupted. Please contact presentation's owner.`
            );
          }
        }
        this.props.notifyError(
          `An error occurred while cloning presentation ${title}.`
        );
      }

      this.onCloseCloneDialog();
      this.props.changeRoute(PRESENTATIONS_LIST_ROUTE, { tab: "own" });
    };

    render() {
      const { filtersAndFulltext, filtersAndFulltextVersion } = this.state;
      const {
        changeRoute,
        requestDeletePresentation,
        requestSharePresentation,
        publish,
        unpublish,
        cloneInProgress,
        presentationListVersion,
        deleteShare,
        presentationCollaborations,
        presentationShares,
        filterConfig,
        isDeletingInProgress,
        isPublishingInProgress,
        isUnpublishingInProgress,
        isShareDeletingInProgress
      } = this.props;

      const editPresentation = (id) =>
        changeRoute("presentations.edit", { presentationId: id });

      const getTabProps = (tab) => {
        return {
          "data-badge":
            tab.route === "shared" && presentationShares
              ? presentationShares
              : tab.route === "collaborate" && presentationCollaborations > 0
              ? presentationCollaborations
              : null
        };
      };

      return (
        <Tabs>
          {this.state.clonePresentationDialog && (
            <ClonePresentation
              {...this.state.clonePresentationDialog}
              onClone={this.clonePresentation}
              onClose={this.onCloseCloneDialog}
            />
          )}
          <TabPanel
            tabs={TABS}
            getTabProps={getTabProps}
            heading="Presentations"
            buttons={
              <button
                className={cx("button", {
                  "is-enter": this.props.routeParams.tab === "own",
                  "is-leave": this.props.routeParams.tab !== "own"
                })}
                onClick={this.addPresentation}
                data-qa="btn-new-presentation"
              >
                <IconAdd />
                New Presentation
              </button>
            }
          >
            {(tab) => (
              <>
                <div className="tabFilter">
                  <FulltextAndFilters
                    onChange={this.onFiltersAndFulltextChange}
                    filters={filterConfig}
                    additionalFilterData={{ type: tab }}
                  />
                </div>
                <div className="tabContent">
                  <ListInfiniteScroll
                    rowHeight={70}
                    key={`${filtersAndFulltextVersion}-${tab}-${presentationListVersion}`}
                    emptyPlaceholder={<PresentationListEmpty />}
                    fetchData={(startIndex, stopIndex) =>
                      getPresentations(
                        startIndex,
                        stopIndex,
                        tab,
                        filtersAndFulltext && filtersAndFulltext.fulltext,
                        filtersAndFulltext && filtersAndFulltext.filters
                      )
                    }
                  >
                    {(status, record) => {
                      switch (status) {
                        case ROW_STATUS.LOADED:
                          return (
                            record && (
                              <PresentationListRow
                                {...record}
                                tab={tab}
                                onClone={() =>
                                  this.onRequestClonePresentation(record.id)
                                }
                                cloneInProgress={cloneInProgress(record.id)}
                                onEdit={() => editPresentation(record.id)}
                                onShare={() =>
                                  requestSharePresentation(
                                    record.id,
                                    record.title
                                  )
                                }
                                onDelete={() =>
                                  requestDeletePresentation(
                                    record.id,
                                    record.title
                                  )
                                }
                                isDeletingInProgress={isDeletingInProgress(
                                  record.id
                                )}
                                onDeleteShare={() =>
                                  deleteShare(record.id, record.title)
                                }
                                isShareDeletingInProgress={isShareDeletingInProgress(
                                  record.id
                                )}
                                onPublish={() =>
                                  publish(record.id, record.title)
                                }
                                isPublishingInProgress={isPublishingInProgress(
                                  record.id
                                )}
                                onUnpublish={() =>
                                  unpublish(record.id, record.title)
                                }
                                isUnpublishingInProgress={isUnpublishingInProgress(
                                  record.id
                                )}
                              />
                            )
                          );
                        case ROW_STATUS.LOADING:
                          return <PresentationListRowLoading />;
                        default:
                          throw new Error(`Unknown row state ${status}`);
                      }
                    }}
                  </ListInfiniteScroll>
                </div>
              </>
            )}
          </TabPanel>
        </Tabs>
      );
    }
  }
);
