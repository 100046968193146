/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger as logger } from "purplex-logging";
import { createStore, compose, applyMiddleware } from "redux";
import { router5Middleware } from "redux-router5";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { rootReducer } from "./root-reducer";

import rootSaga from "./root-saga";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

const sagaMiddleware = createSagaMiddleware({
  onError: (ex) => {
    logger.error("Saga Error: ", ex);
  }
});

const configureStore = (router: any) => {
  const middleware: any[] = [];

  middleware.push(thunk);
  middleware.push(sagaMiddleware);
  middleware.push(router5Middleware(router));

  const composeEnhancers =
    process.env.NODE_ENV === "production"
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
