import React, { useCallback } from "react";
import { reduxForm } from "redux-form";
import cx from "classnames";
import { createPortal } from "react-dom";

import { TextField } from "../../../../forms/text-field";
import { CreateRecordCommand } from "../../../../crud/use-create-record";
import { GuestDomainFormData } from "../../../../../../shared/types/guest-domain";
import { useNavigateTo } from "../../../../routing/routing-hooks";
import { notifyError } from "../../../../ux/notifications/notifications-saga";
import {
  guestDomainAsyncValidate,
  guestDomainBlurFields,
  guestDomainValidate
} from "./guest-domain-validate";
import { ModalTemplate } from "../../../../ux/modal";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";
import { DOMAINS_ROUTE } from "../../../../routing/route-names";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

export const GUEST_DOMAIN_ADD_FORM = "guest-domains-form-add";

export interface GuestDomainAddProps {
  createRecordCommand: CreateRecordCommand<GuestDomainFormData>;
}

const Form = reduxForm<GuestDomainFormData, GuestDomainAddProps>({
  form: GUEST_DOMAIN_ADD_FORM,
  validate: guestDomainValidate,
  asyncValidate: guestDomainAsyncValidate,
  asyncBlurFields: guestDomainBlurFields
})(({ handleSubmit, createRecordCommand }) => {
  const { navigateTo, routeParams } = useNavigateTo();

  const onSubmit = useCallback(
    async (values: GuestDomainFormData) => {
      const success = await createRecordCommand.createRecord(values);
      if (success) {
        navigateTo(DOMAINS_ROUTE);
      } else {
        notifyError("Creating of guest domain has failed.");
      }
    },
    [createRecordCommand, navigateTo]
  );

  const onBack = useCallback(() => {
    navigateTo(DOMAINS_ROUTE, { ...routeParams });
  }, [navigateTo, routeParams]);

  useEscapeHook(true, onBack);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalTemplate
        title="Add Guest Domain"
        className=""
        onCloseClick={onBack}
        footer={
          <>
            <button
              type="submit"
              className={cx("button", {
                inProgress: createRecordCommand.inProgress
              })}
              data-qa={MODAL_LOCATORS.buttons.submit}
            >
              Add Domain
            </button>
          </>
        }
        hideCloseButton={undefined}
        enableBackdropClick={undefined}
        headerProps={undefined}
        contentProps={undefined}
        closeButtonProps={undefined}
      >
        <TextField
          name="name"
          label="Domain name"
          required
          dataQa={FORM_LOCATORS.inputs.getInput("domain-name")}
        />
        <h4 className="wideMargin">Domain Admin</h4>
        <TextField
          name="adminFirstName"
          label="First name"
          required
          dataQa={FORM_LOCATORS.inputs.getInput("first-name")}
        />
        <TextField
          name="adminLastName"
          label="Last name"
          required
          dataQa={FORM_LOCATORS.inputs.getInput("last-name")}
        />
        <TextField
          name="adminEmail"
          label="Email"
          required
          dataQa={FORM_LOCATORS.inputs.getInput("email")}
        />
      </ModalTemplate>
    </form>
  );
});

export const AddGuestDomain = (props: {
  createRecordCommand: CreateRecordCommand<GuestDomainFormData>;
}) => {
  return createPortal(
    <Form {...props} />,
    document.getElementById("modal") as Element
  );
};
