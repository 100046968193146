import { useCallback, useEffect } from "react";
import { useSceneEditorActions } from "./asset-config/use-scene-editor-actions";
import { SceneEditorActions } from "../scene-editor-reducer";
import { useDispatch, useStore, useSelector } from "react-redux";
import {
  getClipboard,
  getSelectedSceneAssetUuids,
  getScene,
  getEditingTextAssetId,
  getSelectedSceneAssets,
  isAddUrlAssetModalOpenAndFocused
} from "../scene-editor-selectors";
import { TextAssetAlignment } from "shared/types/asset";
import { getRouteName } from "client/modules/routing/routing-selectors";
import { includesSegment } from "router5-helpers";

const useKeyboardShortcutsEnabledBasedOnRouting = () => {
  const route = useSelector(getRouteName);

  if (route) {
    const includesRoute = includesSegment(route);

    return includesRoute("editor");
  } else {
    return false;
  }
};

export const useKeyboardShortcuts = () => {
  const {
    actionsMap,
    undoLastAction,
    redoLastAction
  } = useSceneEditorActions();
  const store = useStore();
  const dispatch = useDispatch();
  const shortcutsEnabled = useKeyboardShortcutsEnabledBasedOnRouting();

  const stopPropagation = (ev: KeyboardEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
  };

  const onDocumentKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      const clipboardBuffer = getClipboard(store.getState());
      const selectedSceneAssetUuids = getSelectedSceneAssetUuids(
        store.getState()
      );
      const scene = getScene(store.getState());
      const isEditing = getEditingTextAssetId(store.getState());
      const selectedAssets = getSelectedSceneAssets(store.getState());
      const urlAssetModalFieldFocused = isAddUrlAssetModalOpenAndFocused(
        store.getState()
      );

      const ctrlPressed = ev.metaKey || ev.ctrlKey;

      if (ctrlPressed && ev.key === "z") {
        undoLastAction();
        stopPropagation(ev);
      }

      if (ctrlPressed && ev.key === "y") {
        redoLastAction();
        stopPropagation(ev);
      }

      if (ctrlPressed && ev.key === "c") {
        dispatch(
          SceneEditorActions.setClipboard({ clipboardBuffer: selectedAssets })
        );
        stopPropagation(ev);
      }

      if (ctrlPressed && ev.key === "v") {
        if (isEditing || urlAssetModalFieldFocused) {
          return;
        }

        if (clipboardBuffer) {
          actionsMap.CLONE_ASSETS(clipboardBuffer);
        }
        stopPropagation(ev);
      }

      if (ctrlPressed && ev.key === "d") {
        actionsMap.CLONE_ASSETS(selectedAssets);
        stopPropagation(ev);
      }

      if (ctrlPressed && ev.key === "x") {
        dispatch(
          SceneEditorActions.setClipboard({ clipboardBuffer: selectedAssets })
        );
        actionsMap.DELETE_ASSETS(selectedSceneAssetUuids);
        stopPropagation(ev);
      }

      if (
        ev.key === "Delete" &&
        (!ev.target || (ev.target as HTMLElement).tagName !== "INPUT")
      ) {
        if (isEditing) {
          return;
        }
        actionsMap.DELETE_ASSETS(selectedSceneAssetUuids);
        stopPropagation(ev);
      }

      if (selectedSceneAssetUuids.length === 1 && scene) {
        const selectedSceneAsset = scene.sceneAssets.find(
          ({ uuid }) => uuid === selectedSceneAssetUuids[0]
        );

        // only one text asset available for change properties
        if (ctrlPressed && ev.key === "i" && selectedSceneAsset) {
          actionsMap.CHANGE_TEXT_ASSET_CONFIG(
            { italic: !selectedSceneAsset.asset.metadata.italic },
            true,
            selectedSceneAssetUuids
          );

          stopPropagation(ev);
        }

        if (ctrlPressed && ev.key === "b" && selectedSceneAsset) {
          actionsMap.CHANGE_TEXT_ASSET_CONFIG(
            { bold: !selectedSceneAsset.asset.metadata.bold },
            true,
            selectedSceneAssetUuids
          );
          stopPropagation(ev);
        }

        if (ctrlPressed && ev.key === "u" && selectedSceneAsset) {
          actionsMap.CHANGE_TEXT_ASSET_CONFIG(
            { underline: !selectedSceneAsset.asset.metadata.underline },
            true,
            selectedSceneAssetUuids
          );
          stopPropagation(ev);
        }

        if (ctrlPressed && ev.key === "l") {
          actionsMap.CHANGE_TEXT_ASSET_CONFIG(
            { alignment: TextAssetAlignment.Left },
            true,
            selectedSceneAssetUuids
          );
          stopPropagation(ev);
        }

        if (ctrlPressed && ev.key === "r") {
          actionsMap.CHANGE_TEXT_ASSET_CONFIG(
            { alignment: TextAssetAlignment.Right },
            true,
            selectedSceneAssetUuids
          );
          stopPropagation(ev);
        }
      }
    },
    [store, actionsMap, undoLastAction, redoLastAction, dispatch]
  );

  useEffect(() => {
    if (shortcutsEnabled) {
      document.addEventListener("keydown", onDocumentKeyDown);
    }

    return () => {
      if (shortcutsEnabled) {
        document.removeEventListener("keydown", onDocumentKeyDown);
      }
    };
  }, [onDocumentKeyDown, shortcutsEnabled]);
};
