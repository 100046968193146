import _ from "lodash";
import React, { useCallback } from "react";
import { useSelector, useStore } from "react-redux";
import { TextAssetFontSize } from "shared/types/asset";
import {
  getEditorSettingsFormValues,
  getInitialFormValues
} from "../editor-settings-selectors";
import { useSetEditorSettings } from "../hooks/use-set-editor-settings";

import {
  EditorSettingsForm,
  EDITOR_SETTINGS_FORM_DATA
} from "./editor-settings-form";

export const EditorSettings = () => {
  const initialValues = useSelector(getInitialFormValues);
  const store = useStore();

  const setEditorSettings = useSetEditorSettings();

  const submitChange = useCallback(
    _.debounce(() => {
      const formData = getEditorSettingsFormValues(store.getState()) as
        | EDITOR_SETTINGS_FORM_DATA
        | undefined;

      if (formData) {
        setEditorSettings({
          ...formData,
          EDITOR_DEFAULT_TEXT_FONT:
            formData.EDITOR_DEFAULT_TEXT_FONT &&
            formData.EDITOR_DEFAULT_TEXT_FONT.value,
          EDITOR_DEFAULT_TEXT_SIZE:
            formData.EDITOR_DEFAULT_TEXT_SIZE &&
            (formData.EDITOR_DEFAULT_TEXT_SIZE.value as TextAssetFontSize)
        });
      }
    }, 500),
    [store, setEditorSettings]
  );

  return (
    <div className="subpage">
      <header className="pageHeader">
        <h1 className="pageHeading" data-qa="tab-header">
          Editor Settings
        </h1>
      </header>

      <div className="pageContent">
        <EditorSettingsForm
          initialValues={initialValues}
          onChange={submitChange}
        />
      </div>
    </div>
  );
};
