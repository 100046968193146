import { AppSettingType } from "shared/types/app-setting";
import {
  getEditorSettings,
  getForms
} from "client/modules/root/root-selectors";
import { createSelector } from "reselect";
import { EDITOR_SETTINGS_FORM_NAME } from "./components/editor-settings-form";

export const getInitialFormValues = createSelector(
  getEditorSettings,
  (state) => {
    return (
      state.settings && {
        ...state.settings,
        [AppSettingType.EDITOR_DEFAULT_TEXT_FONT]: {
          label: state.settings.EDITOR_DEFAULT_TEXT_FONT,
          value: state.settings.EDITOR_DEFAULT_TEXT_FONT
        },
        [AppSettingType.EDITOR_DEFAULT_TEXT_SIZE]: {
          label: state.settings.EDITOR_DEFAULT_TEXT_SIZE.toUpperCase(),
          value: state.settings.EDITOR_DEFAULT_TEXT_SIZE
        }
      }
    );
  }
);

export const getDefaultEditorValues = createSelector(
  getEditorSettings,
  (state) => {
    return state.settings;
  }
);

export const getEditorSettingsFormValues = createSelector(getForms, (state) => {
  return (
    state[EDITOR_SETTINGS_FORM_NAME] && state[EDITOR_SETTINGS_FORM_NAME].values
  );
});
