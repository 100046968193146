import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as IconImage } from "client/images/assets16/image.svg";
import { ReactComponent as IconText } from "client/images/assets16/text.svg";
import { ReactComponent as IconVideo } from "client/images/assets16/video.svg";
import { ReactComponent as IconAudio } from "client/images/assets16/audio.svg";
import { ReactComponent as IconLink } from "client/images/assets16/link.svg";
import { ReactComponent as IconPdf } from "client/images/assets16/pdf.svg";
import { ReactComponent as IconPresentation } from "client/images/assets16/presentation.svg";
import { ReactComponent as IconGlobe } from "client/images/assets16/globe.svg";

const IconAsset = ({ type, className }) => {
  switch (type) {
    case "ASSET_TEXT":
      return <IconText className={cx("icon", className)} />;
    case "ASSET_IMAGE":
      return <IconImage className={cx("icon", className)} />;
    case "ASSET_VIDEO":
      return <IconVideo className={cx("icon", className)} />;
    case "ASSET_SOUND":
      return <IconAudio className={cx("icon", className)} />;
    case "ASSET_URL":
      return <IconLink className={cx("icon", className)} />;
    case "ASSET_PDF":
      return <IconPdf className={cx("icon", className)} />;
    case "ASSET_WALL_IMAGE":
      return <IconImage className={cx("icon", className)} />;
    case "ASSET_WALL_VIDEO":
      return <IconVideo className={cx("icon", className)} />;
    case "ASSET_PRESENTATION":
      return <IconPresentation className={cx("icon", className)} />;
    default:
      return <IconGlobe className={cx("icon", className)} />;
  }
};

IconAsset.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default IconAsset;
