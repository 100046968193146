import { Logger as logger } from "purplex-logging";
import React, { useCallback } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import { getScene } from "client/modules/scene-editor/scene-editor-selectors";
import { useDropWall } from "../../scene-editor-dnd";
import { AssignedWall } from "./assigned-wall";
import { BackgroundPlaceholder } from "./background-placeholder";
import { BackgroundSettingForm } from "./background-setting-form";
import { useEditBackgroundActions } from "../../hooks/edit-background/use-edit-background-actions";

const noop = () => {};

export const BackgroundPanel = () => {
  const scene = useSelector(getScene);
  let wall = null;

  if (scene && scene.wall) {
    wall = scene.wall;
  }

  const { setBackgroundAsset } = useEditBackgroundActions();

  const setSceneWall = useCallback(
    async (assetUuid: string) => {
      await setBackgroundAsset(assetUuid);
    },
    [setBackgroundAsset]
  );

  const removeWall = useCallback(async () => {
    logger.debug("Removing wall");
    await setBackgroundAsset(undefined);
  }, [setBackgroundAsset]);

  const [{ canDrop, wallOver }, dropRef] = useDropWall(
    wall ? wall.uuid : null,
    setSceneWall
  );

  return (
    <div
      className={cx("backgrounds", {
        droppable: canDrop
      })}
      data-qa="backgrounds-panel"
      ref={dropRef}
    >
      {wallOver ? (
        <AssignedWall asset={wallOver} onRemoveClick={noop} />
      ) : wall ? (
        <AssignedWall asset={wall} onRemoveClick={removeWall}>
          <BackgroundSettingForm playAs={wall.playAs} />
        </AssignedWall>
      ) : (
        <BackgroundPlaceholder />
      )}
    </div>
  );
};
