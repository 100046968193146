import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AssetRepositoryEntity,
  CustomFieldHierarchyRepositoryEntity,
  CustomFieldRepositoryEntity,
  CustomFieldToEntityMappingRepositoryEntity,
  PredefinedSceneNameRepositoryEntity,
  PresentationRepositoryEntity,
  PresentationSceneRepositoryEntity,
  SceneActionRepositoryEntity,
  SceneAssetRepositoryEntity,
  SceneRepositoryEntity,
  UserRepositoryEntity
} from "./entity.types";
import { GuestDomainFull } from "../../../shared/types/guest-domain";
import { TriggerFull } from "../../../shared/types/trigger";
import { ImInstance } from "../../../shared/types/im-instance";

const initialState: EntityRepositoryState = {
  scenes: {},
  assets: {},
  assetactions: {},
  sceneassets: {},
  instances: {},
  users: {},
  presentations: {},
  presentationscenes: {},
  predefinedscenes: {},
  customFields: {},
  customFieldToEntityMappings: {},
  customFieldHierarchies: {},
  guestDomains: {},
  triggers: {}
};

export type EntityRepositoryHasChangedAction = Partial<EntityRepositoryState> & {};

export interface EntityRepositoryState {
  scenes: { [key: string]: SceneRepositoryEntity };
  assets: { [key: string]: AssetRepositoryEntity };
  assetactions: {
    [key: string]: SceneActionRepositoryEntity;
  };
  sceneassets: { [key: string]: SceneAssetRepositoryEntity };
  instances: { [key: string]: ImInstance };
  users: { [key: string]: UserRepositoryEntity };
  presentations: { [key: string]: PresentationRepositoryEntity };
  presentationscenes: { [key: string]: PresentationSceneRepositoryEntity };
  predefinedscenes: { [key: string]: PredefinedSceneNameRepositoryEntity };
  customFields: { [key: string]: CustomFieldRepositoryEntity };
  customFieldToEntityMappings: {
    [key: string]: CustomFieldToEntityMappingRepositoryEntity;
  };
  customFieldHierarchies: {
    [key: string]: CustomFieldHierarchyRepositoryEntity;
  };
  guestDomains: {
    [key: string]: GuestDomainFull;
  };
  triggers: {
    [key: string]: TriggerFull;
  };
}

const { actions, reducer } = createSlice({
  name: "entityRepository",
  initialState,
  reducers: {
    repositoryHasChanged: (
      state,
      action: PayloadAction<{
        repository: any;
      }>
    ) =>
      mergeWith({}, state, action.payload.repository, (objValue, srcValue) => {
        // If merging two arrays, just replace original value
        // with new one
        if (isArray(objValue) && isArray(srcValue)) {
          return srcValue;
        }

        // Other values merge as expected
        return undefined;
      })
  }
});

export const EntityRepositoryActions = actions;
export const entityRepositoryReducer = reducer;
