import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useStore } from "react-redux";

import {
  getActiveWallInfo,
  getIsResizing,
  getResizingChange,
  getResizingOrigin,
  getSelectionBoundingBox
} from "../../scene-editor-selectors";
import { useDraggingActions } from "../asset-config/use-dragging-actions";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { useGetMouseMovement } from "./utils/use-mouse-movement";

export enum ResizingTransformOrigin {
  LEFT_TOP = "left top",
  RIGHT_TOP = "right top",
  LEFT_BOTTOM = "left bottom",
  RIGHT_BOTTOM = "right bottom"
}

export const getResizedCoords = (
  origin: ResizingTransformOrigin,
  widthChange: number,
  heightChange: number,
  left: number,
  top: number,
  width: number,
  height: number
) => {
  switch (origin) {
    case ResizingTransformOrigin.LEFT_TOP:
      return {
        left: Math.max(left + widthChange, 0),
        top: Math.max(top + heightChange, 0),
        width: Math.max(width - widthChange, 0),
        height: Math.max(height - heightChange, 0)
      };
    case ResizingTransformOrigin.RIGHT_TOP:
      return {
        left: left,
        top: Math.max(top + heightChange, 0),
        width: Math.max(width + widthChange, 0),
        height: height - Math.max(heightChange, -height)
      };
    case ResizingTransformOrigin.LEFT_BOTTOM:
      return {
        left: Math.max(left + widthChange, 0),
        top: top,
        width: Math.max(width - widthChange, 0),
        height: Math.max(height + heightChange, 0)
      };
    case ResizingTransformOrigin.RIGHT_BOTTOM:
      return {
        left: left,
        top: top,
        width: Math.max(width + widthChange, 0),
        height: Math.max(height + heightChange, 0)
      };
  }
  return { left, top, width, height };
};

export const useResizing = (canvasScale: number) => {
  const resizeChangeRefX = useRef(0);
  const resizeChangeRefY = useRef(0);

  const dispatch = useDispatch();
  const store = useStore();
  const { resizeTextAsset } = useDraggingActions();
  const getMouseMovement = useGetMouseMovement();

  const resizeAnchorMouseDownHandlerFactory = useCallback(
    (origin: ResizingTransformOrigin) => (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      e.stopPropagation();
      dispatch(SceneEditorActions.setIsResizing(true));
      dispatch(SceneEditorActions.setResizingOrigin(origin));
      resizeChangeRefX.current = 0;
      resizeChangeRefY.current = 0;
    },
    [resizeChangeRefY, resizeChangeRefX, dispatch]
  );

  const resizeMouseDownHandlerLeftTop = useCallback(
    resizeAnchorMouseDownHandlerFactory(ResizingTransformOrigin.LEFT_TOP),
    [resizeAnchorMouseDownHandlerFactory]
  );

  const resizeMouseDownHandlerLeftBottom = useCallback(
    resizeAnchorMouseDownHandlerFactory(ResizingTransformOrigin.LEFT_BOTTOM),
    [resizeAnchorMouseDownHandlerFactory]
  );

  const resizeMouseDownHandlerRightTop = useCallback(
    resizeAnchorMouseDownHandlerFactory(ResizingTransformOrigin.RIGHT_TOP),
    [resizeAnchorMouseDownHandlerFactory]
  );

  const resizeMouseDownHandlerRightBottom = useCallback(
    resizeAnchorMouseDownHandlerFactory(ResizingTransformOrigin.RIGHT_BOTTOM),
    [resizeAnchorMouseDownHandlerFactory]
  );

  const resizeMouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      const isResizing = getIsResizing(store.getState());
      const boundingBox = getSelectionBoundingBox(store.getState());
      const wallInfo = getActiveWallInfo(store.getState());
      const resizeOrigin = getResizingOrigin(store.getState());

      if (isResizing && boundingBox && wallInfo) {
        const { movementX, movementY } = getMouseMovement(e);

        resizeChangeRefX.current -= movementX;
        resizeChangeRefY.current -= movementY;

        let boundedWidthChange;
        let boundedHeightChange;
        switch (resizeOrigin) {
          case ResizingTransformOrigin.RIGHT_BOTTOM:
            boundedWidthChange = Math.min(
              resizeChangeRefX.current / canvasScale,
              wallInfo.end - boundingBox.right
            );
            boundedHeightChange = Math.min(
              resizeChangeRefY.current / canvasScale,
              wallInfo.height - boundingBox.bottom
            );
            dispatch(
              SceneEditorActions.setResizingChange({
                widthChange: boundedWidthChange,
                heightChange: boundedHeightChange
              })
            );
            break;
          case ResizingTransformOrigin.LEFT_BOTTOM:
            boundedWidthChange = Math.min(
              Math.max(
                resizeChangeRefX.current / canvasScale,
                wallInfo.start - boundingBox.left
              ),
              boundingBox.right - boundingBox.left
            );
            boundedHeightChange = Math.min(
              resizeChangeRefY.current / canvasScale,
              wallInfo.height - boundingBox.bottom
            );
            dispatch(
              SceneEditorActions.setResizingChange({
                widthChange: boundedWidthChange,
                heightChange: boundedHeightChange
              })
            );
            break;
          case ResizingTransformOrigin.LEFT_TOP:
            boundedWidthChange = Math.min(
              Math.max(
                resizeChangeRefX.current / canvasScale,
                wallInfo.start - boundingBox.left
              ),
              boundingBox.right - boundingBox.left
            );
            boundedHeightChange = Math.min(
              Math.max(
                resizeChangeRefY.current / canvasScale,
                -boundingBox.top
              ),
              boundingBox.bottom - boundingBox.top
            );
            dispatch(
              SceneEditorActions.setResizingChange({
                widthChange: boundedWidthChange,
                heightChange: boundedHeightChange
              })
            );
            break;
          case ResizingTransformOrigin.RIGHT_TOP:
            boundedWidthChange = Math.min(
              resizeChangeRefX.current / canvasScale,
              wallInfo.end - boundingBox.right
            );
            boundedHeightChange = Math.min(
              Math.max(
                resizeChangeRefY.current / canvasScale,
                -boundingBox.top
              ),
              boundingBox.bottom - boundingBox.top
            );
            dispatch(
              SceneEditorActions.setResizingChange({
                widthChange: boundedWidthChange,
                heightChange: boundedHeightChange
              })
            );
            break;
        }
      }
    },
    [
      store,
      dispatch,
      canvasScale,
      resizeChangeRefX,
      resizeChangeRefY,
      getMouseMovement
    ]
  );

  const resizeMouseUpHandler = useCallback(() => {
    const isResizing = getIsResizing(store.getState());
    const boundingBox = getSelectionBoundingBox(store.getState());
    const resizeOrigin = getResizingOrigin(store.getState());
    const resizeChange = getResizingChange(store.getState());

    if (isResizing && boundingBox) {
      const resizedCoords = getResizedCoords(
        resizeOrigin,
        resizeChange.widthChange,
        resizeChange.heightChange,
        boundingBox.left,
        boundingBox.top,
        boundingBox.right - boundingBox.left,
        boundingBox.bottom - boundingBox.top
      );

      dispatch(SceneEditorActions.setIsResizing(false));
      dispatch(
        SceneEditorActions.setResizingChange({
          widthChange: 0,
          heightChange: 0
        })
      );
      resizeChangeRefY.current = 0;
      resizeChangeRefX.current = 0;

      resizeTextAsset({
        width: resizedCoords.width,
        height: resizedCoords.height,
        top: resizedCoords.top,
        left: resizedCoords.left
      });
    }
  }, [resizeTextAsset, store, dispatch]);

  useEffect(() => {
    document.addEventListener("mouseup", resizeMouseUpHandler);
    document.addEventListener("mousemove", resizeMouseMoveHandler);
    return () => {
      document.removeEventListener("mouseup", resizeMouseUpHandler);
      document.removeEventListener("mousemove", resizeMouseMoveHandler);
    };
  }, [resizeMouseUpHandler, resizeMouseMoveHandler]);

  return {
    resizeMouseDownHandlerLeftBottom,
    resizeMouseDownHandlerLeftTop,
    resizeMouseDownHandlerRightBottom,
    resizeMouseDownHandlerRightTop
  };
};
