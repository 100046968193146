import React, { useCallback, useRef, useState } from "react";
import { components } from "react-select";
import { ImInstance } from "shared/types/im-instance";
import { TriggerFull, TriggerReduced } from "shared/types/trigger";
import * as Api from "../api/client";
import { useEffectWithMountGuard } from "../utils/use-effect-with-mount-guard";
import { AsyncSelectField } from "./async-select-field";

const MAX_RESULTS = 30;

const Group = (props: any) => (
  <div style={{}}>
    <components.Group {...props} />
  </div>
);

export const SelectTriggerField = ({
  name,
  label,
  help,
  disabled,
  instanceUuids,
  filterOption,
  dataQa,
  ...rest
}: {
  name: string;
  label?: string;
  help?: string;
  disabled?: boolean;
  instanceUuids?: string[];
  dataQa?: string;
  filterOption?: (option: TriggerFull) => boolean;
}) => {
  const [query, setQuery] = useState("");
  const [rows, setRows] = useState<{ label: string; options: TriggerFull[] }[]>(
    []
  );

  const instancesRef = useRef<ImInstance[] | null>(null);

  const fetchTriggersEffect = useCallback(async () => {
    if (instancesRef.current === null) {
      instancesRef.current = await Api.getInstanceConfigs(); // eslint-disable-line require-atomic-updates
    }

    const triggers = await Api.getTriggers({
      search: query,
      startIndex: 0,
      stopIndex: MAX_RESULTS - 1
    });

    setRows(
      instancesRef.current
        .filter((instance) =>
          instanceUuids ? instanceUuids.includes(instance.uuid) : true
        )
        .map((instance) => ({
          label: instance.name,
          options: triggers.rows.filter(
            (trigger) => trigger.instanceUuid === instance.uuid
          )
        }))
        .filter((group) => group.options.length)
    );
  }, [query]);

  useEffectWithMountGuard(fetchTriggersEffect);

  const onInputChange = useCallback(
    (query: string) => {
      setQuery(query);
    },
    [setQuery]
  );

  const filterOptionInternal = useCallback(
    ({ data }: { data: TriggerFull }) => {
      if (filterOption) {
        return filterOption(data);
      } else {
        return () => true;
      }
    },
    [filterOption]
  );

  return (
    <AsyncSelectField
      name={name}
      async={false}
      options={rows}
      onInputChange={onInputChange}
      filterOption={filterOptionInternal}
      defaultOptions
      label={label}
      placeholder="Trigger"
      help={help}
      isDisabled={disabled}
      isClearable={false}
      getOptionLabel={(trigger: TriggerReduced) => `${trigger.name}`}
      getOptionValue={(trigger: TriggerReduced) => trigger.id}
      components={{ Group }}
      dataQa={dataQa}
      {...rest}
    />
  );
};
