import { Logger as logger } from "purplex-logging";
import createRouter from "router5";
import browserPlugin from "router5-plugin-browser";
import listenersPlugin from "router5-plugin-listeners";

import routes from "client/modules/routing/routes";

const loggingPlugin = () => {
  return {
    onTransitionSuccess: (toState, fromState) => {
      logger.debug(
        `Router transition success ${fromState && fromState.name} -> ${
          toState && toState.name
        }`
      );
    }
  };
};

loggingPlugin.pluginName = "LOGGING";

const buildRouter = () => {
  const router = createRouter(routes, { allowNotFound: true });
  router.usePlugin(
    loggingPlugin,
    browserPlugin({ useHash: false }),
    listenersPlugin()
  );
  return router;
};

export default buildRouter;
