import {
  all,
  call,
  put,
  fork,
  select,
  takeEvery,
  takeLatest,
  delay
} from "redux-saga/effects";
import { Logger as logger } from "purplex-logging";
import { getFormValues } from "redux-form";
import { actions as routerActions } from "redux-router5";
import { takeEveryWithCheckProgress } from "../../in-progress/in-progress-saga";
import { notifyError } from "../../ux/notifications/notifications-saga";

import assetLibrarySaga from "../asset-library/asset-library-saga";
import {
  getScene,
  getSceneId,
  getSortedSceneAssets,
  loggedUserIsScenesCreator
} from "../scene-editor-selectors";
import { normalizeAndStore } from "../../entity-repository/entity-repository-saga";
import { scene as sceneSchema } from "../../entity-repository/schema";
import * as ErSelectors from "../../entity-repository/entity-repository-selectors";
import { FORM_NAME as TRANSITION_SETTING_FORM_NAME } from "../components/background-and-transition-panel/transition-setting-form";

import * as Api from "../../api/client";
import { initializeSceneEditorSaga } from "./initialize-scene-editor-saga";
import { onClickActionModalSaga } from "./on-click-action-modal-saga";
import { AssetLibraryActions } from "../asset-library/asset-library-reducer";
import { SceneEditorActions } from "../scene-editor-reducer";
import * as UserTrackingCreators from "../../user-tracking/user-tracking-creators";
import * as UserTrackingSaga from "../../user-tracking/user-tracking-saga";
import { SceneAssetBulkOperationFlag } from "../../../../shared/types/scene-asset";

const transitionSettingFormSelector = getFormValues(
  TRANSITION_SETTING_FORM_NAME
);

function* onRemoveTransition() {
  try {
    const scene = yield select(getScene);

    const updatedScene = yield call(Api.changeSceneTransition, scene.id, null);
    yield call(normalizeAndStore, updatedScene, sceneSchema);
    // reload library so removed transition can appear back in the library
    yield put(AssetLibraryActions.reloadAssets());
  } catch (e) {
    logger.error("Removing of the transition file from the scene failed.", e);
    yield call(
      notifyError,
      "Removing of the transition file from the scene failed."
    );
    yield call(initializeSceneEditorSaga);
  }
}

function* setSceneTransitionAsset({ payload: assetUuid }) {
  const sceneId = yield select(getSceneId);
  const scenes = yield select(ErSelectors.getScenes);
  const scene = scenes[sceneId];
  const assets = yield select(ErSelectors.getAssets);
  const transitionAsset = assets[assetUuid];

  try {
    let updatedScene;

    // 1st update scene locally (so it feels responsive on slow internet connections)
    updatedScene = {
      ...scene,
      transitionAsset
    };
    yield call(normalizeAndStore, updatedScene, sceneSchema);

    // 2nd update scene on BE
    updatedScene = yield call(Api.changeSceneTransition, sceneId, assetUuid);
    yield call(normalizeAndStore, updatedScene, sceneSchema);
  } catch (e) {
    logger.error("Setting transition of the scene failed.", e);
    yield call(notifyError, "Setting transition of the scene failed.");
    yield call(initializeSceneEditorSaga);
  } finally {
    // reloading library ensures that background which was attached to the scene
    // before the new background was dropped appears back in the library
    yield put(AssetLibraryActions.reloadAssets());
  }
}

function* onChangeSceneAssetOrder({
  payload: { sceneAssetId, moveToIndex, wallNumber }
}) {
  try {
    const scene = yield select(getScene);
    // We will need sorted list (by wall and layer) of scene assets
    const sceneAssets = yield select(getSortedSceneAssets);

    const indexOfSceneAssetToMove = sceneAssets.findIndex(
      ({ id }) => id === sceneAssetId
    );

    if (indexOfSceneAssetToMove === -1) {
      logger.warn(
        "onSceneAssetOrderChanged: scene asset not found, assetUuid=" +
          sceneAssetId
      );

      return;
    }

    let sceneAssetToMove = sceneAssets[indexOfSceneAssetToMove];
    const wallChange = sceneAssetToMove.wall !== wallNumber;

    // If wall has changed we need to reflect it too
    if (wallChange) {
      const { start } = scene.instance.walls.find(
        ({ num }) => num === wallNumber
      );

      sceneAssetToMove = { ...sceneAssetToMove, left: start };
    }

    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackSceneEditorSceneAssetWallChanged(wallChange)
    );

    let sceneAssetsModified;

    if (moveToIndex === indexOfSceneAssetToMove) {
      if (!wallChange) {
        return;
      }
      sceneAssetsModified = [
        ...sceneAssets.slice(0, indexOfSceneAssetToMove),
        sceneAssetToMove,
        ...sceneAssets.slice(indexOfSceneAssetToMove + 1)
      ];
    } else if (moveToIndex > indexOfSceneAssetToMove) {
      sceneAssetsModified = [
        ...sceneAssets.slice(0, indexOfSceneAssetToMove),
        ...sceneAssets.slice(indexOfSceneAssetToMove + 1, moveToIndex),
        sceneAssetToMove,
        ...sceneAssets.slice(moveToIndex)
      ];
    } else {
      // moveToIndex < sceneAssetToMove
      sceneAssetsModified = [
        ...sceneAssets.slice(0, moveToIndex),
        sceneAssetToMove,
        ...sceneAssets.slice(moveToIndex, indexOfSceneAssetToMove),
        ...sceneAssets.slice(indexOfSceneAssetToMove + 1)
      ];
    }

    // update layer so it reflects changes in order
    let updatedScene = {
      ...scene,
      sceneAssets: sceneAssetsModified.map((sceneAsset, index) => ({
        ...sceneAsset,
        layer: sceneAssetsModified.length - index - 1,
        operationFlag: SceneAssetBulkOperationFlag.UPDATE
      }))
    };

    // Save the scene locally to improve responsiveness on slow internet connections
    yield call(normalizeAndStore, updatedScene, sceneSchema);

    // update on BE
    updatedScene = yield call(
      Api.updateSceneAssets,
      scene.id,
      updatedScene.sceneAssets
    );
    yield call(normalizeAndStore, updatedScene, sceneSchema);
  } catch (e) {
    logger.error("Update of order of scene assets failed.", e);
    yield call(notifyError, "Update of order of scene assets failed.");
    yield call(initializeSceneEditorSaga);
  }
}

function* updateScene(updateData) {
  try {
    yield delay(300);
    const scene = yield select(getScene);

    if (scene) {
      const updatedScene = yield call(Api.updateScene, scene.id, updateData);
      yield call(normalizeAndStore, updatedScene, sceneSchema);
    }
  } catch (e) {
    logger.error("Update of the scene failed.", e);
    yield call(notifyError, "Update of the scene failed.");
    yield call(initializeSceneEditorSaga);
  }
}

function* onTransitionSettingFormHasChanged() {
  const formData = yield select(transitionSettingFormSelector);

  const scene = yield select(getScene);
  if (scene.transitionFadeMs !== formData.transitionFadeMs) {
    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackSceneEditorTransitionChangeDuration(
        formData.transitionFadeMs
      )
    );
  }
  yield call(updateScene, formData);
}

function* leave() {
  const currentUserIsCreator = yield select(loggedUserIsScenesCreator);

  if (window.history.length === 0) {
    yield put(
      routerActions.navigateTo(
        "scenes.list",
        { tab: currentUserIsCreator ? "own" : "cooperation" },
        {
          replace: true
        }
      )
    );
  } else {
    window.history.back();
  }
}

export default function* () {
  yield all([
    fork(onClickActionModalSaga),
    fork(assetLibrarySaga),
    takeLatest(
      SceneEditorActions.removeTransitionAsset.type,
      onRemoveTransition
    ),
    takeEveryWithCheckProgress(
      SceneEditorActions.setSceneTransitionAsset.type,
      setSceneTransitionAsset
    ),
    takeEvery(
      SceneEditorActions.changeSceneAssetOrder.type,
      onChangeSceneAssetOrder
    ),
    takeLatest(SceneEditorActions.leave.type, leave),
    takeLatest(
      SceneEditorActions.transitionSettingFormHasChanged.type,
      onTransitionSettingFormHasChanged
    )
  ]);
}
