export type WallLikeType<T> = T & { width: number; height: number };

export const getTransformedWallParams = <T>(
  walls: WallLikeType<T>[],
  activeWall: number
) => {
  const wallsCloned = walls.map((wall: WallLikeType<T>) => ({
    ...wall,
    scale: 1
  }));

  const sortedWallsByWidth = wallsCloned
    .slice()
    .sort((a: { width: number }, b: { width: number }) => a.width - b.width);

  const shortWallWidth =
    sortedWallsByWidth[
      // in cases like NY room where the room is not rectangular and where is
      // a short wall in front of a longer wall we want to show the shorter
      // wall in scene editor in length of the longer wall in order to
      // have rectangular shape of room.
      sortedWallsByWidth.length === 4 ? 1 : 0
    ].width;
  const longWallWidth = sortedWallsByWidth[sortedWallsByWidth.length - 1].width;

  if (sortedWallsByWidth.length === 4) {
    sortedWallsByWidth[0].scale =
      sortedWallsByWidth[1].width / sortedWallsByWidth[0].width;
    sortedWallsByWidth[2].scale =
      sortedWallsByWidth[3].width / sortedWallsByWidth[2].width;
  }

  const wallHeight = wallsCloned.reduce(
    (max: number, wall: { height: number }) =>
      wall.height > max ? wall.height : max,
    walls[0].height
  );

  const activeWallWidth =
    wallsCloned[activeWall].width * wallsCloned[activeWall].scale;

  const sideWall =
    sortedWallsByWidth.length === 1
      ? wallsCloned[0]
      : wallsCloned[Math.abs(activeWall - 1)];
  const sideWallWidth = sideWall.width * sideWall.scale;

  // empirical consts for best looking result of various room shapes
  const perspectiveOffset = 1.4 + Math.pow(longWallWidth / shortWallWidth, 1.6);
  const perspective = sideWallWidth / perspectiveOffset;

  return {
    longWallWidth,
    shortWallWidth,
    wallHeight,
    activeWallWidth,
    perspective,
    sideWallWidth,
    wallsWithScale: wallsCloned
  };
};

export const getRoomScale = (
  width: number,
  height: number,
  walls: { width: number; height: number }[] | undefined,
  activeWall: number
) => {
  if (!walls) {
    return { scale: 1, canvasScale: 1 };
  }

  const {
    longWallWidth,
    shortWallWidth,
    wallHeight,
    perspective,
    activeWallWidth,
    sideWallWidth
  } = getTransformedWallParams(walls, activeWall);

  const padding = longWallWidth / shortWallWidth + 1;
  const verticalScale = (height / wallHeight) * padding;
  const horizontalScale = (width / activeWallWidth) * padding;

  const scale =
    horizontalScale < verticalScale ? horizontalScale : verticalScale;

  const canvasScale =
    (activeWallWidth *
      (perspective / (perspective - sideWallWidth / -2)) *
      scale) /
    activeWallWidth;

  return {
    scale,
    canvasScale
  };
};
