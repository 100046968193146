import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as routerActions } from "redux-router5";

import {
  getSortedSceneAssets,
  getScene,
  getActiveWall,
  byWall,
  toWallNum,
  getOnClickActions,
  getSelectedSceneAssetUuids
} from "../../scene-editor-selectors";

import { DndSceneAssetRow } from "./scene-asset-row";
import { WallBlock } from "./wall-block";
import {
  getRouteParams,
  getRouteName,
  RouteParamSelector
} from "../../../routing/routing-selectors";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { UserTrackingActions } from "client/modules/user-tracking/user-tracking-slice";
import * as UserTrackingCreators from "client/modules/user-tracking/user-tracking-creators";
import {
  useSelectAsset,
  useSelectMultipleAssets
} from "../../hooks/use-select-asset";
import { useSceneAssetCrudActions } from "../../hooks/asset-config/use-scene-asset-crud-actions";

export const SceneAssetsPanelContainer = () => {
  let prevWallsAssetCount = 0;
  let layer = 1;

  const sceneAssets = useSelector(getSortedSceneAssets);
  const selectedSceneAssetUuids = useSelector(getSelectedSceneAssetUuids);
  const scene = useSelector(getScene);
  const activeWall = useSelector(getActiveWall);
  const onClickActions = useSelector(getOnClickActions);
  const routeParams = useSelector(getRouteParams as RouteParamSelector<any>);
  const currentRoute = useSelector(getRouteName);
  const { createSceneAssets } = useSceneAssetCrudActions();

  const dispatch = useDispatch();

  const changeSceneAssetOrder = useCallback(
    (payload: {
      sceneAssetId: number;
      moveToIndex: number;
      wallNumber: number;
    }) => {
      dispatch(SceneEditorActions.changeSceneAssetOrder(payload));
    },
    [dispatch]
  );

  const selectAsset = useSelectAsset();
  const selectMultipleAssets = useSelectMultipleAssets();
  const { deleteAssets } = useSceneAssetCrudActions();

  const changeWall = useCallback(
    (payload: { activeWall: number }) => {
      dispatch(SceneEditorActions.wallChanged(payload));
    },
    [dispatch]
  );

  const editOnClickAction = useCallback(
    (sceneAssetId, currentRoute, routeParams) => {
      dispatch(
        routerActions.navigateTo(
          `${currentRoute}.asset-actions`,
          {
            ...routeParams,
            sceneAssetId
          },
          { replace: true }
        )
      );
    },
    [dispatch]
  );

  const trackOpenOnClickActions = useCallback(() => {
    if (scene) {
      dispatch(
        UserTrackingActions.track(
          UserTrackingCreators.trackSceneEditorOnClickActionsOpened(scene.title)
        )
      );
    }
  }, [dispatch, scene]);

  return (
    <div className="walls">
      {scene &&
        scene.instance &&
        scene.instance.walls
          .map(toWallNum)
          .sort()
          .map((num: number) => {
            const wallFirstIndex = prevWallsAssetCount;
            const wallSceneAssets = sceneAssets.filter(byWall(num));
            prevWallsAssetCount += wallSceneAssets.length;

            const wallAssetsNodes = wallSceneAssets.map((sceneAsset) => (
              <DndSceneAssetRow
                key={sceneAsset.uuid}
                sceneAsset={sceneAsset}
                layer={layer++}
                selected={selectedSceneAssetUuids.includes(sceneAsset.uuid)}
                hasOnClickAction={onClickActions.some(
                  (onClickAction: any) =>
                    onClickAction.sceneAssetId === sceneAsset.id
                )}
                onClick={(e: MouseEvent) => {
                  if (e.shiftKey) {
                    selectMultipleAssets(sceneAsset.uuid);
                  } else {
                    selectAsset(sceneAsset.uuid);
                  }
                }}
                onClickDelete={() => {
                  deleteAssets([sceneAsset.uuid]);
                }}
                onEditOnClickAction={() => {
                  editOnClickAction(sceneAsset.id, currentRoute, routeParams);
                  trackOpenOnClickActions();
                }}
                changeSceneAssetOrder={changeSceneAssetOrder}
              />
            ));

            return (
              <WallBlock
                key={num}
                wallNumber={num}
                onlySceneAssetId={
                  wallSceneAssets.length === 1 ? wallSceneAssets[0].id : null
                }
                createSceneAsset={createSceneAssets}
                wallFirstIndex={wallFirstIndex}
                changeSceneAssetOrder={changeSceneAssetOrder}
                wallAssets={wallAssetsNodes}
                onChangeWall={() => changeWall({ activeWall: num - 1 })}
                isActiveWall={activeWall === num - 1}
              />
            );
          })}
    </div>
  );
};
