import React from "react";
import PropTypes from "prop-types";

import { UploadStatus } from "../upload-statuses";

export const AssetUploadState = ({ uploadState }) =>
  uploadState && uploadState.status === UploadStatus.UPLOADING ? (
    <i
      className="gridRowProgressbar"
      style={{
        transform: `translateX(${Math.min(
          (uploadState.uploaded / uploadState.size) * 100,
          100
        )}%)`
      }}
    />
  ) : (
    <></>
  );

AssetUploadState.propTypes = {
  uploadState: PropTypes.shape({
    status: PropTypes.string.isRequired,
    uploaded: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired
  })
};
