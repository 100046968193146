import { call, select, put, takeEvery } from "redux-saga/effects";
import { getProgressSelector } from "./in-progress-selectors";
import Actions from "./in-progress-actions";

function checkProgressCreator(progressKey, entityIdSelector, forkSaga) {
  return function* checkProgress(...args) {
    let entityId;
    try {
      const progressSelector = yield select(getProgressSelector);
      entityId = yield call(entityIdSelector, ...args);

      if (progressSelector(progressKey, entityId)) {
        // eslint-disable-next-line no-console
        console.info(
          `Ignoring action because it is already in progress progressKey=${progressKey}, entityId=${entityId}`
        );
      } else {
        try {
          yield put(Actions.Creators.setProgress(progressKey, entityId, true));
          yield call(forkSaga, ...args);
        } finally {
          yield put(Actions.Creators.setProgress(progressKey, entityId, false));
        }
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(
        `Error in progress forked saga progressKey=${progressKey}, entityId=${entityId}`,
        ex.message,
        ex
      );
    }
  };
}

export function takeEveryWithCheckProgress(
  action,
  forkSaga,
  // default selector which is good when checking progress of creation of a new entity
  entityIdSelector = () => null
) {
  if (typeof action !== "string") {
    throw Error(
      "Argument 'action' of takeEveryWithCheckProgress saga must be string."
    );
  }

  if (typeof entityIdSelector !== "function") {
    throw Error(
      "Argument 'entityIdSelector' of takeEveryWithCheckProgress saga must be function."
    );
  }

  if (typeof forkSaga !== "function") {
    throw Error(
      "Argument 'forkSaga' of takeEveryWithCheckProgress saga must be function."
    );
  }

  return takeEvery(
    action,
    checkProgressCreator(action, entityIdSelector, forkSaga)
  );
}
