import { Dimensions } from "./dimensions";

export interface ImInstance {
  uuid: string;
  name: string;
  roomSpecificationId: number;
  projectors: ImProjector[];
  recommendedBackgrounds: ImRecommendedBackground[];
  roomDimensions: ImDimensions;
  walls: ImWall[];
  url: string;
}

export type ImDimensions = Dimensions;

export interface ImProjector {
  id: number;
  num: number;
  room_specification_id: number;
  startOffset: number;
  width: number;
  stretchX: string;
  stretchY: string;
  updatedAt: string | null;
}

export interface ImRecommendedBackground {
  height: number;
  scaleUnproportionally: boolean;
  // Number for showing in UI, not for use in algorithms
  width: number;
  widthMax: number;
  widthMin: number;
}

export enum ImAssetTransformation {
  SCALE_TO_FIT_WIDTH = "scaleToFitWidth",
  MOVE = "move"
}

export interface ImWall {
  assetTransformation: ImAssetTransformation;
  start: number;
  width: number;
  end: number;
  height: number;
  id: number;
  num: number;
  room_specification_id: number;
  createdAt: string;
  updatedAt: string | null;
}
