import { Logger as logger } from "purplex-logging";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { endsWithSegment, includesSegment } from "router5-helpers";
import { memoize } from "lodash";

import { getRouteName } from "client/modules/routing/routing-selectors";
import { TriggersList } from "./triggers-list";
import { TriggersCreate } from "./triggers-create";
import { useRecordCrud } from "client/modules/crud/use-record-crud";
import { trigger as triggerSchema } from "client/modules/entity-repository/schema";
import { getTriggers } from "client/modules/entity-repository/entity-repository-selectors";
import { TriggerFull } from "shared/types/trigger";
import { ApiExtractorList } from "client/modules/crud/use-fetch-list";
import { ApiExtractorDelete } from "client/modules/crud/use-delete-records";
import { ApiExtractorCreate } from "client/modules/crud/use-create-record";
import { TriggersFormData } from "./triggers-form";
import { TriggersEdit } from "./triggers-edit";
import { useEffectWithMountGuard } from "client/modules/utils/use-effect-with-mount-guard";

const listApiExtractor: ApiExtractorList<TriggerFull> = (api) =>
  api.getTriggers;
const deleteApiExtractor: ApiExtractorDelete<void> = (api) => api.deleteTrigger;
const createApiExtractor: ApiExtractorCreate<TriggersFormData, TriggerFull> = (
  api
) => api.createTrigger;

const PAGE_SIZE = 49;
const QUERY = "";

const getQueryParams = memoize((page: number, search: string) => {
  return {
    startIndex: (page - 1) * PAGE_SIZE + (page === 1 ? 0 : 1),
    stopIndex: page * PAGE_SIZE,
    search
  };
});

export const TriggersIndex = () => {
  const route = useSelector(getRouteName) as string;

  const crud = useRecordCrud(
    triggerSchema,
    getTriggers,
    listApiExtractor,
    deleteApiExtractor,
    createApiExtractor,
    getQueryParams(1, QUERY)
  );

  const totalCount = crud.totalCount;
  const fetchEffect = useCallback(
    async (mountGuard) => {
      if (totalCount > PAGE_SIZE) {
        const pages = Math.floor(totalCount / PAGE_SIZE);

        for (let i = 2; i <= pages; i++) {
          logger.debug(`Requesting page ${i}`);
          if (mountGuard()) {
            await crud.fetchList(getQueryParams(i, QUERY), true);
          } else {
            logger.debug("Ignoring beacuse of component unmount");
          }
        }
      }
    },
    [totalCount]
  );

  useEffectWithMountGuard(fetchEffect);

  const ends = endsWithSegment(route);
  const includes = includesSegment(route);

  if (includes("list")) {
    return (
      <>
        <TriggersList
          triggers={crud.records}
          deleteRecordCommand={crud.deleteRecordCommand}
        />
        {ends("add-trigger") && (
          <TriggersCreate createRecordCommand={crud.createRecordCommand} />
        )}
        {ends("edit-trigger") && <TriggersEdit />}
      </>
    );
  } else {
    throw new Error(`Unknown route ${route}`);
  }
};
