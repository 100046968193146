import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState
} from "react";
import { Field, WrappedFieldProps } from "redux-form";
import cx from "classnames";
import _ from "lodash";

import { ReactComponent as IconMinus } from "../../images/minus-alt.svg";
import { ReactComponent as IconPlus } from "../../images/plus-alt.svg";

const REGEXP_NUMBER = new RegExp(/^(-?\d*(?:[.,]\d*)?)$/);

interface Props {
  className?: string;
  dataQa?: string;
  id?: string;
  disabled?: boolean;
  max?: number;
  min?: number;
  step?: number;
  style?: object;
  unit?: string;
}

const Input: React.FC<Props & WrappedFieldProps> = ({
  className,
  dataQa,
  id,
  input: { onChange, value: propsValue },
  disabled,
  max,
  meta: { active },
  min,
  step = 1,
  style,
  unit
}) => {
  const [value, setValue] = useState<number | string>(0);

  const normalize = (value: number, lower = min, upper = max) => {
    return lower !== undefined && upper !== undefined
      ? _.clamp(value, lower, upper)
      : value;
  };

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const onChangeDebounced = useCallback(
    _.debounce((value) => {
      onChange(value);
    }, 500),
    [onChange]
  );

  const handleChange = useCallback(
    (e) => {
      const localValue = e.target.value;
      const numberValue = parseInt(localValue, 10);

      if (REGEXP_NUMBER.test(localValue)) {
        setValue(localValue);
      }

      if (!isNaN(numberValue)) {
        onChangeDebounced(normalize(numberValue));
      }
    },
    [value, onChangeDebounced]
  );

  const handleKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case "ArrowDown":
          decrementValue();
          e.preventDefault();
          break;

        case "ArrowUp":
          incrementValue();
          e.preventDefault();
          break;
      }
    },
    [value, onChange]
  );

  const handleBlur = useCallback(() => {
    const normalizedValue = normalize(parseInt(value.toString(), 10) || 0);

    setValue(normalizedValue);
    onChange(normalizedValue);
  }, [value, onChange]);

  const incrementValue = useCallback(() => {
    const normalizedValue = normalize(
      (parseInt(value.toString(), 10) || 0) + step
    );

    setValue(normalizedValue);
    onChangeDebounced(normalizedValue);
  }, [value, onChange]);

  const decrementValue = useCallback(() => {
    const normalizedValue = normalize(
      (parseInt(value.toString(), 10) || 0) - step
    );

    setValue(normalizedValue);
    onChangeDebounced(normalizedValue);
  }, [value, onChange]);

  return (
    <label
      className={cx("nymber", className, {
        "is-disabled": disabled
      })}
      style={{ ...style }}
    >
      <span
        className="nymber__body"
        data-value={unit ? `${value}${unit}` : null}
      >
        <input
          type="text"
          value={value}
          disabled={disabled}
          autoComplete="off"
          id={id}
          data-qa={dataQa}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      </span>
      <span className="nymber__controls">
        <button
          type="button"
          className="nymber__control"
          onClick={() => decrementValue()}
        >
          <IconMinus />
        </button>
        <button
          type="button"
          className="nymber__control"
          onClick={() => incrementValue()}
        >
          <IconPlus />
        </button>
      </span>
    </label>
  );
};

export const NumberField = (
  props: Props & InputHTMLAttributes<HTMLInputElement>
) => <Field component={Input} {...props} />;
