/* eslint-disable @typescript-eslint/no-explicit-any */
import transitionPath from "router5.transition-path";
import { actionTypes as routerActions } from "redux-router5";
import {
  all,
  call,
  take,
  fork,
  cancel,
  ActionPattern
} from "redux-saga/effects";
import { Task } from "redux-saga";

function* enterLogic(
  action: ActionPattern,
  routeToEnter: string,
  saga: Function,
  ...params: any[]
): IterableIterator<any> {
  const activeProcesses: {
    [key: string]: Task;
  } = {};

  while (true) {
    const {
      payload: { route, previousRoute }
    }: any = yield take(action);

    const {
      toActivate,
      toDeactivate
    }: { toActivate: string[]; toDeactivate: string[] } = transitionPath(
      route,
      previousRoute
    );

    // First let's start the
    // saga if it matches the route condition
    // store the reference to running subprocess in map
    // of running processes so that we can deactivate that on next transition
    if (toActivate.some((activatedRoute) => activatedRoute === routeToEnter)) {
      // @ts-ignore
      activeProcesses[routeToEnter] = yield fork(saga, ...params, route);
    }

    yield all(
      toDeactivate
        .map((toDeactivateRoute) => {
          const possiblyDeactivateProcess = activeProcesses[toDeactivateRoute];

          // If there's running saga in list of active processes
          // it needs to be deactivated upon leaving the route
          if (possiblyDeactivateProcess) {
            delete activeProcesses[toDeactivateRoute];
            return cancel(possiblyDeactivateProcess);
          } else {
            return false;
          }
        })
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        .filter(Boolean)
    );
  }
}

export function* onRouteEntered(
  routeToEnter: string,
  saga: Function,
  ...params: any[]
) {
  // @ts-ignore
  yield call(
    enterLogic,
    routerActions.TRANSITION_SUCCESS,
    routeToEnter,
    saga,
    ...params
  );
}

export function* onAnyOfRoutesEntered(
  routesToEnter: string[],
  saga: Function,
  ...params: any[]
) {
  yield all(
    // @ts-ignore
    routesToEnter.map((route) => call(onRouteEntered, route, saga, ...params))
  );
}
