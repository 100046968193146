import { createSelector } from "reselect";

import { getConfig as getState } from "client/modules/root/root-selectors";

export const getVersion = createSelector(getState, (state) => state.version);

export const getCdnHost = createSelector(getState, (state) => state.cdnHost);

export const getMaxUploadSize = createSelector(
  getState,
  (state) => state.maxUploadFileSize
);

export const getInstanceConfig = createSelector(
  getState,
  (state) => state.instance
);

export const getBranding = createSelector(
  getInstanceConfig,
  (instanceConfig) => instanceConfig && instanceConfig.branding
);

export const getAcl = createSelector(
  getInstanceConfig,
  (instanceConfig) => instanceConfig.acl
);

export const getDisabledAssetTypes = createSelector(
  getInstanceConfig,
  (instanceConfig) => instanceConfig.disabledAssetTypes || []
);

export const getRoleLabelFactory = (state) => {
  const { roles } = getBranding(state);
  return (role) => roles[role];
};

export const getFeatureFlags = createSelector(
  getInstanceConfig,
  (instanceConfig) => instanceConfig.featureFlags
);

export const isSceneBundlesEnabled = createSelector(
  getFeatureFlags,
  (featureFlags) => featureFlags.sceneBundles
);

export const getGitbookHelpLink = createSelector(
  getState,
  (state) => state.gitbookHelp
);

export const hasInstanceConfig = createSelector(
  getInstanceConfig,
  (instanceConfig) => !!instanceConfig
);

export const getSiteTitle = createSelector(
  getBranding,
  (branding) => branding && branding.siteTitle
);

export const hasQ32Features = createSelector(
  getState,
  (state) => state.q32Features
);

export const getVixUrl = createSelector(getState, (state) => state.vixUrl);

export const hasVix = createSelector(getVixUrl, (vixUrl) => Boolean(vixUrl));

export const getMixpanelToken = createSelector(
  getState,
  (state) => state.mixpanel
);

export const getIntercomAppId = createSelector(
  getState,
  (state) => state.intercom
);

export const getClientName = createSelector(getState, (state) => state.client);

export const hasTriggersEnabled = createSelector(
  getState,
  (state) => state.triggersEnabled
);

export const getSsoProvider = createSelector(getState, (state) => state.sso);

export const getUsesSapTransformations = createSelector(
  getState,
  (state) => state.usingSapTransformations
);

export const hasSsoEnabled = createSelector(
  getSsoProvider,
  (sso) => sso !== false
);
