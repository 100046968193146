import { TextAssetFontSize } from "./asset";

export enum AppSettingType {
  EDITOR_DEFAULT_OPACITY = "EDITOR_DEFAULT_OPACITY",
  EDITOR_DEFAULT_VISIBLE_ON_START = "EDITOR_DEFAULT_VISIBLE_ON_START",
  EDITOR_DEFAULT_POSITION_LOCKED = "EDITOR_DEFAULT_POSITION_LOCKED",
  EDITOR_DEFAULT_GLOW = "EDITOR_DEFAULT_GLOW",
  EDITOR_DEFAULT_ROUNDED = "EDITOR_DEFAULT_ROUNDED",
  EDITOR_DEFAULT_LOOP = "EDITOR_DEFAULT_LOOP",
  EDITOR_DEFAULT_AUTOPLAY = "EDITOR_DEFAULT_AUTOPLAY",
  EDITOR_DEFAULT_TEXT_SIZE = "EDITOR_DEFAULT_TEXT_SIZE",
  EDITOR_DEFAULT_TEXT_FONT = "EDITOR_DEFAULT_TEXT_FONT"
}

export enum AppSettingValueType {
  INTEGER = "INTEGER",
  BOOLEAN = "BOOLEAN",
  TEXT = "TEXT"
}

export interface AppSettingReduced {
  id: number;
  settingsType: AppSettingType;
  valueBoolean: boolean;
  valueInteger: boolean;
  valueText: string;
}

export interface EditorSettings {
  EDITOR_DEFAULT_OPACITY: number;
  EDITOR_DEFAULT_VISIBLE_ON_START: boolean;
  EDITOR_DEFAULT_POSITION_LOCKED: boolean;
  EDITOR_DEFAULT_GLOW: boolean;
  EDITOR_DEFAULT_ROUNDED: boolean;
  EDITOR_DEFAULT_LOOP: boolean;
  EDITOR_DEFAULT_AUTOPLAY: boolean;
  EDITOR_DEFAULT_TEXT_SIZE: TextAssetFontSize;
  EDITOR_DEFAULT_TEXT_FONT: string;
}

export type AppSettingFull = AppSettingReduced;
