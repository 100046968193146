/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { noop } from "lodash";

import ASSET_MIMETYPES from "../../../media/asset-mime-types";
import { WALL_TYPES } from "../../../media/wall-types";
import * as ConfigSelectors from "../../../config/config-selectors";
import ContextMenu from "../../../ux/context-menu/context-menu";
import * as MediaSelectors from "../../../media/media-selectors";
import IconAsset from "../../icon-asset";
import MediaActions from "../../../media/media-actions";
import { ReactComponent as IconDrag } from "client/images/drag.svg";
import { ReactComponent as IconOptions } from "client/images/options.svg";
import { ReactComponent as IconFullscreen } from "client/images/fullscreen.svg";
import { useLibraryDragSource } from "../../scene-editor-dnd";
import { AssetReduced } from "../../../../../shared/types/asset";
import { AssetType } from "../../../../../server/models/enums/asset-type";
import { AssetStatus } from "../../../../../shared/types/asset-status";
import { AclResource } from "../../../../../shared/acl";
import { AssetLibraryActions } from "../asset-library-reducer";
import { useHasAccess } from "../../../auth/use-has-access";
import { trackAssetPreviewed } from "client/modules/user-tracking/user-tracking-creators";
import { useTracking } from "client/modules/user-tracking/use-tracking";
import { useComponentAdapter } from "../../../root/feature-adapter";

const NO_PREVIEW_ASSETS = [
  AssetType.ASSET_SOUND,
  AssetType.ASSET_URL,
  AssetType.ASSET_PDF,
  AssetType.ASSET_PRESENTATION
];

const someAssetFileIsReady = (asset: AssetReduced) =>
  asset &&
  (asset.status === AssetStatus.STATUS_READY ||
    asset.alternativeAssets.some(
      (alternativeAsset) => alternativeAsset.status === AssetStatus.STATUS_READY
    ));

const isWallAsset = (asset: any) => WALL_TYPES.includes(asset.type);

const getAssetThumbnailTooltip = (disabled: boolean, asset: any) => {
  if (!disabled) {
    return asset.description;
  }

  if (asset.status === AssetStatus.STATUS_ERROR) {
    return "Media file is broken";
  }

  return "Media file is being processed";
};

export const DraggableAsset = ({ asset }: { asset: AssetReduced }) => {
  // chrome blocks for some reason multiple usage of single file input field multiple
  // times, therefore it is needed to create new input field in dom after any file selection

  const hasWallWriteAccess = useHasAccess(AclResource.WALL_WRITE);
  const [
    alternativeFileInputFieldId,
    setAlternativeFileInputFieldId
  ] = useState(1);

  const dispatch = useDispatch();
  const tracking = useTracking();

  const cdnHost = useSelector(ConfigSelectors.getCdnHost);
  const enableAlternativeBackgroundAssets = useSelector(
    MediaSelectors.enableAlternativeBackgroundAssets
  );

  const deleteAsset = useCallback(
    (assetuuid: string) => {
      dispatch(MediaActions.Creators.deleteAsset(assetuuid));
    },
    [dispatch]
  );

  const deleteWall = useCallback(
    (assetuuid: string) => {
      dispatch(MediaActions.Creators.deleteAsset(assetuuid));
    },
    [dispatch]
  );

  const previewAsset = useCallback(
    (assetuuid: string) => {
      dispatch(MediaActions.Creators.previewAsset(assetuuid));
      tracking(trackAssetPreviewed("Scene Editor", asset.type, []));
    },
    [dispatch, asset, tracking]
  );

  const createWallAlternativeAssets = useCallback(
    (parentAsset: object, files: File[]) => {
      dispatch(
        AssetLibraryActions.createWallAlternativeAssets({ parentAsset, files })
      );
    },
    [dispatch]
  );

  const { InstancesWallStatuses } = useComponentAdapter();

  const [{ isDragging }, dragRef] = useLibraryDragSource(asset, noop);
  const alternativeFileInputRef = useRef<HTMLInputElement>(null);

  const isWall = isWallAsset(asset);
  const assetNoun = isWall ? "Background" : "Asset";
  const onDelete: any = isWall ? hasWallWriteAccess && deleteWall : deleteAsset;

  const noPreviewType = NO_PREVIEW_ASSETS.includes(asset.type);
  const assetUrl = `${cdnHost}/assets/${asset.uuid}_${asset.fileVersion}_thumb.jpg`;
  const disabled = !someAssetFileIsReady(asset);

  return (
    <div
      className={cx({
        [asset.status]: true,
        asset: true,
        "no-preview": noPreviewType,
        dragging: isDragging,
        disabled,
        "has-alternatives": asset.alternativeAssets.length > 0
      })}
      title={getAssetThumbnailTooltip(disabled, asset)}
      ref={dragRef}
      data-qa="asset-library-draggable-asset-wrapper"
    >
      <span
        className="thumb"
        style={
          noPreviewType || asset.status !== AssetStatus.STATUS_READY
            ? undefined
            : { backgroundImage: `url('${assetUrl}')` }
        }
      />
      <IconDrag className="icon icon-drag drag" />
      <IconAsset type={asset.type} className="type icon32" />
      {isWall && <InstancesWallStatuses wallAsset={asset} />}
      <div className="title" data-qa="asset-library-draggable-asset-title">
        {asset.title}
      </div>
      <div className="asset-menu">
        {hasWallWriteAccess ? (
          <React.Fragment>
            <input
              key={alternativeFileInputFieldId}
              type="file"
              ref={alternativeFileInputRef}
              accept={(ASSET_MIMETYPES[asset.type] || []).join(",")}
              style={{ display: "none" }}
              onChange={(ev) => {
                if (ev.target.files) {
                  createWallAlternativeAssets(asset, [...ev.target.files]);
                }
                setAlternativeFileInputFieldId(alternativeFileInputFieldId + 1);
              }}
            />
            <ContextMenu
              title={
                <div className="asset-menu-button" title="">
                  <IconOptions className="icon icon-options" />
                </div>
              }
              data-qa="asset-library-draggable-asset-context-menu"
            >
              {someAssetFileIsReady(asset) && (
                <ContextMenu.Item
                  onClick={() => {
                    previewAsset(asset.uuid);
                  }}
                  data-qa="asset-library-draggable-asset-context-menu-preview-button"
                >
                  Preview {assetNoun}
                </ContextMenu.Item>
              )}
              {isWall &&
                enableAlternativeBackgroundAssets &&
                hasWallWriteAccess && (
                  <ContextMenu.Item
                    onClick={() => {
                      if (alternativeFileInputRef.current) {
                        alternativeFileInputRef.current.click();
                      }
                    }}
                  >
                    Upload Alternative File
                  </ContextMenu.Item>
                )}
              {hasWallWriteAccess && (
                <ContextMenu.Item
                  onClick={() => {
                    onDelete(asset.uuid);
                  }}
                  data-qa="asset-library-draggable-asset-context-menu-delete-button"
                >
                  Delete {assetNoun}
                </ContextMenu.Item>
              )}
            </ContextMenu>
          </React.Fragment>
        ) : (
          <button
            className="asset-menu-button"
            title="Preview"
            onClick={(ev) => {
              ev.preventDefault();
              previewAsset(asset.uuid);
            }}
          >
            <IconFullscreen className="icon icon-fullscreen icon24" />
          </button>
        )}
      </div>
    </div>
  );
};
