import React, { useCallback } from "react";
import { createPortal } from "react-dom";

import { ModalTemplate } from "./modal";

interface LegacyConfirmationModalProps {
  title: string;
  text: string;
  cancelText: string;
  okText: string;
  onResolve: (confirmed: boolean) => void;
}

export const LegacyConfirmationModal = ({
  onResolve,
  title,
  text,
  cancelText,
  okText
}: LegacyConfirmationModalProps) => {
  const close = (confirmed: boolean) => () => {
    onResolve(confirmed);
  };

  const cancel = close(false);
  const ok = close(true);

  const onClose = useCallback(() => {
    cancel();
  }, [cancel]);

  return createPortal(
    <ModalTemplate
      title={title}
      onCloseClick={onClose}
      className="legacyConfirmationModal"
      footer=""
      enableBackdropClick={false}
      headerProps={undefined}
      contentProps={undefined}
      closeButtonProps={undefined}
      hideCloseButton={undefined}
    >
      <>
        <p>{text}</p>
        <div className="buttonWrapper">
          <div className="left">
            <button className="button buttonAlert buttonOutlined" onClick={ok}>
              {okText}
            </button>
          </div>
          <div className="right">
            <button className="button" onClick={cancel}>
              {cancelText}
            </button>
          </div>
        </div>
      </>
    </ModalTemplate>,
    document.getElementById("legacy-modal") as Element
  );
};
