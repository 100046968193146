import React from "react";
import { Logger as logger } from "purplex-logging";
import { AssetStatus } from "../../../../shared/types/asset-status";

export const assetStatusToText = (status: AssetStatus) => {
  switch (status) {
    case AssetStatus.STATUS_ERROR:
      return "Error";
    case AssetStatus.STATUS_NEW:
      return "Pending";
    case AssetStatus.STATUS_PENDING:
      return "Pending";
    case AssetStatus.STATUS_PROCESSING:
      return "Processing";
    case AssetStatus.STATUS_READY:
      return "Ready";
    default:
      logger.warn("Unknown asset status value", status);
      return "";
  }
};

export const AssetStatusBadge = ({ status }: { status: AssetStatus }) => (
  <span className={`mediaStatus mediaStatus-${status.toLowerCase()}`}>
    {assetStatusToText(status)}
  </span>
);
