import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import { TextField } from "client/modules/forms/text-field";
import Actions from "client/modules/settings/predefined-scene-names/predefined-scene-names-actions";

export const FORM_NAME = "createPredefinedSceneForm";

const CreatePredefinedSceneNameForm = ({
  handleSubmit,
  beforeSubmit,
  createPredefinedSceneName
}) => (
  <form
    onSubmit={handleSubmit((...args) => {
      beforeSubmit();
      createPredefinedSceneName(...args);
    })}
  >
    <TextField name="sceneName" label="Predefined Scene Name" required />
    <div className="modalButtons">
      <button type="submit" className="button">
        Create Scene Name
      </button>
    </div>
  </form>
);

CreatePredefinedSceneNameForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  beforeSubmit: PropTypes.func.isRequired,
  createPredefinedSceneName: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  createPredefinedSceneName: Actions.Creators.createPredefinedSceneName
};

const validate = (values) => {
  const errors = {};

  if (!values.sceneName) {
    errors.sceneName = "Value must not be empty";
  }

  return errors;
};

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME,
    validate
  })(CreatePredefinedSceneNameForm)
);
