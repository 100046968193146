import { Logger as logger } from "purplex-logging";
import { all, call, fork, select, takeEvery, put } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";

import { onRouteEntered } from "../../routing/on-route-enter-saga";
import { getRouteParams } from "../../routing/routing-selectors";
import { userSchema } from "../../entity-repository/schema";
import { normalizeAndStore } from "../../entity-repository/entity-repository-saga";

import Actions from "./users-actions";
import * as api from "../../api/client";

import { FORM_NAME } from "./components/users/users-add";
import { notifyError } from "../../ux/notifications/notifications-saga";
import * as UserTrackingSaga from "../../user-tracking/user-tracking-saga";
import * as UserTrackingCreators from "../../user-tracking/user-tracking-creators";
import { getUser } from "../../auth/auth-selectors";

function* goBack() {
  yield call([window.history, window.history.back]);
}

function* onUserEdit() {
  const { id } = yield select(getRouteParams);

  const user = yield call(api.getUser, id);
  yield call(normalizeAndStore, user, userSchema);
}

function* onAddUser({ data }) {
  try {
    yield put(startSubmit(FORM_NAME));

    const user = yield call(api.createUser, data);
    yield call(normalizeAndStore, user, userSchema);

    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackUserAdded(
        user.guestDomain && user.guestDomain.name
      )
    );

    yield put(stopSubmit(FORM_NAME));
    yield call(goBack);
  } catch (ex) {
    if (ex.response && ex.response.status === 409) {
      yield put(
        stopSubmit(FORM_NAME, {
          email: `Email '${data.email}' is already taken`
        })
      );
    } else {
      logger.error("Error during adding user", ex);
      yield call(notifyError, "An error occurred while adding a user");
    }
  }
}

function* onEditUser({ data }) {
  const { id, ...rest } = data;

  try {
    const user = yield call(api.updateUser, id, rest);
    yield call(normalizeAndStore, user, userSchema);
    const loggedInProfile = yield select(getUser);

    const isGuestDomainAccount = !!loggedInProfile.guestDomain;

    yield call(UserTrackingSaga.trackUser, {
      "Company Account": isGuestDomainAccount
        ? loggedInProfile.guestDomain.name
        : "Master Account"
    });

    yield call(
      UserTrackingSaga.track,
      UserTrackingCreators.trackProfileUpdated(user, isGuestDomainAccount)
    );
  } catch (ex) {
    logger.error("Error during editing user", ex);
    yield call(notifyError, "An error occurred while updating an user");
  }
}

function* onSendConfirmationEmail({ userId }) {
  try {
    yield call(api.sendConfirmationEmail, userId);
  } catch (ex) {
    logger.error("Error during sending confirmation email", ex);
    yield call(
      notifyError,
      "An error occurred while trying to send confirmation email"
    );
  }
}

export default function* usersSaga() {
  yield all([
    fork(onRouteEntered, "users.list.edit", onUserEdit),
    takeEvery(Actions.Types.ADD_USER, onAddUser),
    takeEvery(Actions.Types.EDIT_USER, onEditUser),
    takeEvery(Actions.Types.SEND_CONFIRMATION_EMAIL, onSendConfirmationEmail)
  ]);
}
