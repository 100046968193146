import { schema } from "normalizr";
import { OutputSelector } from "reselect";
import { useCallback, useMemo } from "react";

import { RootState } from "../root/root-reducer";
import {
  ApiDeleteEntityParameters,
  ApiExtractorDelete,
  DeleteRecordCommand,
  useDeleteRecord
} from "./use-delete-records";
import {
  ApiExtractorCreate,
  CreateRecordCommand,
  useCreateRecord
} from "./use-create-record";
import {
  ApiExtractorList,
  ApiListEntityParameters,
  useFetchList
} from "./use-fetch-list";
import { LongTask } from "./crud-types";

export interface UseRecordCrudC<MappedEntity, EntityFormData> extends LongTask {
  records: MappedEntity[];
  totalCount: number;
  fetchList: (
    params: ApiListEntityParameters,
    infinite?: boolean
  ) => Promise<void>;
  createRecordCommand: CreateRecordCommand<EntityFormData>;
  deleteRecordCommand: DeleteRecordCommand;
}
export const useRecordCrud = <
  ApiResult,
  NormalizeEntity,
  MappedEntity,
  DeleteResult,
  CreateResult,
  CreateCallParams
>(
  entitySchema: schema.Entity<NormalizeEntity>,
  entityRepositorySelector: OutputSelector<
    RootState,
    { [key: string]: MappedEntity },
    unknown
  >,
  apiListExtractor: ApiExtractorList<ApiResult>,
  apiDeleteExtractor: ApiExtractorDelete<DeleteResult>,
  apiCreateExtractor: ApiExtractorCreate<CreateCallParams, CreateResult>,
  listParams?: ApiListEntityParameters
): UseRecordCrudC<MappedEntity, CreateCallParams> => {
  const { deleteRecord, inProgress: deleteInProgress } = useDeleteRecord(
    apiDeleteExtractor
  );

  const { createRecord, inProgress: createInProgress } = useCreateRecord(
    entitySchema,
    apiCreateExtractor
  );

  const {
    fetchList,
    records,
    totalCount,
    inProgress: fetchInProgress,
    addEntity,
    deleteEntity
  } = useFetchList(
    entitySchema,
    entityRepositorySelector,
    apiListExtractor,
    listParams
  );

  const deleteRecordHandler = useCallback(
    async ({ id }: ApiDeleteEntityParameters) => {
      const result = await deleteRecord({ id });
      if (result.success && result.id) {
        deleteEntity(result.id);
      }
      return result;
    },
    [deleteRecord, deleteEntity]
  );

  const createRecordHandler = useCallback(
    async (data: CreateCallParams) => {
      const result = await createRecord(data);
      if (result.success && result.id) {
        addEntity(result.id);
      }
      return result;
    },
    [createRecord, addEntity]
  );

  return useMemo(() => {
    return {
      records,
      totalCount,
      fetchList,
      createRecordCommand: {
        createRecord: createRecordHandler,
        inProgress: createInProgress
      },
      deleteRecordCommand: {
        deleteRecord: deleteRecordHandler,
        inProgress: deleteInProgress
      },
      inProgress: fetchInProgress
    };
  }, [
    records,
    totalCount,
    fetchList,
    createRecordHandler,
    deleteRecordHandler,
    fetchInProgress,
    deleteInProgress,
    createInProgress
  ]);
};
