import React from "react";
import * as Sentry from "@sentry/browser";
import { render } from "react-dom";
import { Provider } from "react-redux";

import configureStore from "./modules/root/configure-store";

import Root from "./modules/root/components/root-component";
import buildRouter from "./modules/routing/build-router";
import { getConfig } from "./modules/api/client";

import "react-toastify/dist/ReactToastify.css";
import { getMixpanelToken } from "./modules/config/config-selectors";
import { UserTrackingActions } from "./modules/user-tracking/user-tracking-slice";
import { IntercomConnector } from "./modules/settings/users/intercom-connector";
import { Logger } from "purplex-logging";

const router = buildRouter();
const store = configureStore(router);

export const main = async () => {
  const config = await getConfig();

  Logger.setupClientLogging(
    config.logging.level,
    config.logging.environment,
    {
      app: "CLIENT",
      instanceId: config.instanceId,
      versionInfo: config && config.logging && config.logging.versionInfo
    },
    config.logging.dsn !== undefined
      ? { dsn: config.logging.dsn, Sentry }
      : undefined,
    config.logzIoConfig
  );

  store.dispatch(
    UserTrackingActions.initiate({
      mixpanelToken: getMixpanelToken(store.getState())
    })
  );

  router.start(() => {
    Logger.info("Rendering React app");

    render(
      <Provider store={store}>
        <IntercomConnector />
        <Root />
      </Provider>,
      document.getElementById("root")
    );
  });
};
