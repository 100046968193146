import React from "react";
import { useSelector } from "react-redux";
import {
  getHorizontalCenterHelper,
  getIsDragging
} from "../../scene-editor-selectors";

export const HorizontalCenterHelper = ({
  canvasScale
}: {
  canvasScale: number;
}) => {
  const isHorizontalCenterHelperShown = useSelector(getHorizontalCenterHelper);
  const dragging = useSelector(getIsDragging);

  if (!dragging || !isHorizontalCenterHelperShown) {
    return <> </>;
  }

  return (
    <div
      style={{
        top: "50%",
        position: "absolute",
        height: 1 / canvasScale,
        width: "100%",
        transform: "translateY(-50%)",
        background: "#fc4444"
      }}
    />
  );
};
