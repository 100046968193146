import { useSelector } from "react-redux";
import { getRouteName } from "../../../routing/routing-selectors";
import { CustomFieldEntity } from "../../../../../shared/types/custom-field-entity";

export const getCustomFieldTypeFromRouteName = (routeName: string) => {
  if (routeName.includes("company-library")) {
    return CustomFieldEntity.ASSET;
  } else {
    return CustomFieldEntity.PRESENTATION;
  }
};

export const useGetCustomFieldType = () => {
  const route = useSelector(getRouteName) as string;
  return getCustomFieldTypeFromRouteName(route);
};
