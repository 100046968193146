import { createSelector } from "reselect";
import { Logger as logger } from "purplex-logging";

import { getSceneEditor as getState, getForms } from "../root/root-selectors";
import {
  getScenes as getERScenes,
  getInstances,
  getPresentations as getERPresentations,
  getSceneAssets as getERSceneAssets,
  getScenes
} from "../entity-repository/entity-repository-selectors";
import {
  getRouteParams,
  RouteParamSelector
} from "../routing/routing-selectors";
import { getUser } from "../auth/auth-selectors";
import { ASSET_IMAGE } from "../media/asset-types";
import {
  getCdnHost,
  getUsesSapTransformations,
  hasQ32Features
} from "../config/config-selectors";
import { checkEditBackgroundStateValidity } from "./utils";
import { transformScene } from "../../../shared/scene-transformations/transformations-adapter";
import { getFormMeta } from "redux-form";

const { AssetType } = require("../../../shared/types/asset-type");

export const isInitializing = createSelector(
  getState,
  (state) => state.initializing
);
export const getInitializingError = createSelector(
  getState,
  (state) => state.initializingError
);

export const getPresentationId = createSelector(
  getRouteParams as RouteParamSelector<{ presentationId: string }>,
  ({ presentationId }) => (presentationId ? parseInt(presentationId, 10) : null)
);

export const getSceneId = createSelector(
  getRouteParams as RouteParamSelector<{ sceneId: string }>,
  ({ sceneId }) => {
    if (sceneId) {
      return parseInt(sceneId, 10);
    } else {
      return null;
    }
  }
);

export const getAssetActionSceneAssetId = createSelector(
  getRouteParams as RouteParamSelector<{ sceneAssetId: string }>,
  ({ sceneAssetId }) => (sceneAssetId ? parseInt(sceneAssetId, 10) : null)
);

export const getPresentation = createSelector(
  getPresentationId,
  getERPresentations,
  (presentationId, presentations) =>
    presentationId ? presentations[presentationId] : null
);

export const getSortedInstances = createSelector(getInstances, (instances) =>
  Object.keys(instances || [])
    .map((uuid) => instances[uuid])
    .sort((a, b) => String(a.name).localeCompare(String(b.name)))
);

export const getPreviewInstance = createSelector(
  getState,
  getInstances,
  ({ previewInstanceUuid }, instances) =>
    previewInstanceUuid ? instances[previewInstanceUuid] : null
);

export const isPreviewShown = createSelector(
  getPreviewInstance,
  (instance) => !!instance
);

export const getOriginalInstance = createSelector(
  getSceneId,
  getERScenes,
  (id, scenes) => {
    if (!id) {
      return null;
    }
    const scene = scenes[id];

    if (!scene) {
      return null;
    }

    return scene.instance;
  }
);

export const getUndoStack = createSelector(
  getState,
  (state) => state.undoStack
);

export const getRedoStack = createSelector(
  getState,
  (state) => state.redoStack
);

export const getScene = createSelector(
  isInitializing,
  getSceneId,
  getERScenes,
  getPreviewInstance,
  getInstances,
  getUsesSapTransformations,
  (
    initializing,
    id,
    scenes,
    previewInstance,
    instances,
    usesSapTransformations
  ) => {
    if (!id) {
      return null;
    }
    const scene = scenes[id];
    if (initializing || !scene) {
      return null;
    }

    // if scene is missing its room instance, than we can still render it as preview
    const originalInstance = scene.instance || previewInstance;
    const shouldTransformScene =
      previewInstance && previewInstance !== originalInstance;

    const walls =
      (shouldTransformScene
        ? previewInstance && previewInstance.walls
        : originalInstance && originalInstance.walls) || [];

    const findWallNumber = (sceneAsset: {
      left: number;
      sceneId: number;
      asset: { uuid: string };
    }) => {
      const wall = walls.find(
        ({ start, end }) => start <= sceneAsset.left && end > sceneAsset.left
      );
      if (wall) {
        return wall.num;
      } else {
        logger.warn(
          `SceneAsset (sceneId=${sceneAsset.sceneId}, assetUuid=${sceneAsset.asset.uuid})has no proper wall. (fallback to 1st room wall)`
        );
        return walls.length > 0 ? walls[0].num : null;
      }
    };

    // find all walls where the asset can be visible (due to the overflow
    // to a next wall, e.g. in case of scene preview for another room)
    const getVisibleOnWalls = (sceneAsset: {
      left: number;
      scale: number;
      width: number;
    }) => {
      const assetRight =
        sceneAsset.left + (sceneAsset.scale / 100) * sceneAsset.width;

      return walls.filter(
        ({ start, end }) => start <= assetRight && end - 1 >= sceneAsset.left
      );
    };

    const transformedScene = transformScene(
      { ...scene, assets: scene.sceneAssets },
      originalInstance,
      previewInstance || originalInstance,
      usesSapTransformations
    );

    return {
      ...scene,
      instance: shouldTransformScene ? previewInstance : originalInstance,
      sceneAssets: transformedScene.assets.map((sceneAsset) => ({
        ...sceneAsset,
        wall: findWallNumber(sceneAsset) || 0,
        visibleOnWalls: getVisibleOnWalls(sceneAsset)
      })),
      transformedWall: transformedScene.wall
    };
  }
);

export const getIsSceneAndInstanceSelected = createSelector(
  getScene,
  (scene) => !!scene && !!scene.instance
);

export const getSceneWallAsset = createSelector(
  getScene,
  (scene) => scene && scene.transformedWall
);

export const getMainSceneWallAsset = createSelector(
  getScene,
  (scene) => scene && scene.wall
);

export const getSceneInstanceWalls = createSelector(
  getScene,
  (scene) => scene && scene.instance && scene.instance.walls
);

export const getTitle = createSelector(
  getScene,
  (scene) => scene && scene.title
);

export const getClipboard = createSelector(
  getState,
  (scene) => scene && scene.clipboardBuffer
);

export const getActiveWall = createSelector(
  getState,
  getScene,
  ({ activeWall }, scene) => {
    if (scene && scene.instance && activeWall >= scene.instance.walls.length) {
      return 0;
    }

    return activeWall;
  }
);

export const getSceneRotation = createSelector(
  getState,
  getScene,
  ({ sceneRotation, activeWall }, scene) => {
    if (scene && scene.instance && activeWall >= scene.instance.walls.length) {
      return sceneRotation - (sceneRotation % 360);
    }

    return sceneRotation;
  }
);

export const getActiveWallNumber = createSelector(
  getActiveWall,
  (activeWall) => activeWall + 1
);

export const loggedUserIsScenesCreator = createSelector(
  getScene,
  getUser,
  (scene, user) => scene && user && user.id === scene.creatorId
);

export const getSelectedSceneAssetUuids = createSelector(
  getState,
  (state) => state.selectedSceneAssetIds
);

export const hasSelectedTextAsset = createSelector(
  getSelectedSceneAssetUuids,
  getERSceneAssets,
  (selectedSceneAssetIds, sceneAssets) =>
    selectedSceneAssetIds.length === 1 &&
    selectedSceneAssetIds
      .map((id) => sceneAssets[id])
      .filter((sceneAsset) => sceneAsset.asset.type === AssetType.ASSET_TEXT)
      .length === 1
);

export const getAssetConfigForm = createSelector(
  getForms,
  (forms) => forms["asset-config"]
);

export const getAssetConfigFormValues = createSelector(
  getAssetConfigForm,
  (assetConfigForm) => {
    if (assetConfigForm) {
      return assetConfigForm.values;
    } else {
      return {};
    }
  }
);

export const getSceneAssets = createSelector(
  getScene,
  (scene) => (scene && scene.sceneAssets) || []
);

export const getSortedSceneAssets = createSelector(
  getSceneAssets,
  (sceneAssets) =>
    [...sceneAssets].sort((a, b) => {
      // this can happen only if scene's room is in IM defined without any wall
      if (!a.wall || !b.wall) {
        throw Error("Some of the assets is missing wall number");
      }

      const wallDiff = a.wall - b.wall;

      if (wallDiff !== 0) {
        return wallDiff;
      }

      return b.layer - a.layer;
    })
);

export const getSelectedSceneAssets = createSelector(
  getSceneAssets,
  getSelectedSceneAssetUuids,
  (sceneAssets, selectedSceneAssetId) =>
    sceneAssets.filter((sceneAsset) =>
      selectedSceneAssetId.includes(sceneAsset.uuid)
    )
);

export const getSelectedSceneAssetsType = createSelector(
  getSelectedSceneAssets,
  (sceneAssets) => {
    return sceneAssets.length &&
      sceneAssets.every(
        (asset) => asset.asset.type === sceneAssets[0].asset.type
      )
      ? sceneAssets[0].asset.type
      : undefined;
  }
);

export const getSelectedSceneAssetsFormConfig = createSelector(
  getSelectedSceneAssets,
  hasQ32Features,
  (selectedSceneAssets, q32Features) => ({
    showGlowAndRoundedCorners: selectedSceneAssets.every(
      (sceneAsset) =>
        sceneAsset.asset.type !== AssetType.ASSET_PDF &&
        sceneAsset.asset.type !== AssetType.ASSET_TEXT
    ),
    showAutoPlayAndLoop: selectedSceneAssets.every(
      (sceneAsset) =>
        sceneAsset.asset.type === AssetType.ASSET_VIDEO ||
        sceneAsset.asset.type === AssetType.ASSET_SOUND ||
        (sceneAsset.asset.type === AssetType.ASSET_IMAGE &&
          sceneAsset.asset.metadata.animated)
    ),
    showOnClickActions:
      q32Features &&
      selectedSceneAssets.length === 1 &&
      (selectedSceneAssets[0].asset.playAs === ASSET_IMAGE ||
        selectedSceneAssets[0].asset.playAs === AssetType.ASSET_TEXT),
    singleAsset:
      selectedSceneAssets.length === 1 ? selectedSceneAssets[0] : undefined
  })
);

const resolveConflictForProperty = (
  propertyName: string,
  array: { [key: string]: unknown }[],
  conflictValue: number | string | boolean | null
) => {
  if (!array || !array.length) {
    return conflictValue;
  }
  const value = array[0][propertyName];
  return array.every((item) => item[propertyName] === value)
    ? value
    : conflictValue;
};

export const isSaving = createSelector(getState, (state) => state.saving);
export const isSaveFailure = createSelector(
  getState,
  (state) => state.saveFailure
);

export const getAssetConfigWall = createSelector(
  getAssetConfigFormValues,
  getScene,
  (assetConfigFormValues, scene) => {
    if (!assetConfigFormValues) {
      return null;
    }
    const wall = assetConfigFormValues.wall || 1;

    return (
      scene &&
      scene.instance &&
      scene.instance.walls.find(({ num }) => num === wall)
    );
  }
);

export const byWall = (wallNumber: number | null) => (sceneAsset: {
  wall: number | null;
}) => sceneAsset.wall === wallNumber;

export const toWallNum = (wall: { num: number }) => wall.num;

export const toAssetUuid = (sceneAsset: { asset: { uuid: string } }) =>
  sceneAsset.asset.uuid;

export const getActiveWallInfo = createSelector(
  getScene,
  getActiveWallNumber,
  (scene, activeWallNumber) =>
    scene &&
    scene.instance &&
    scene.instance.walls.find((wall) => wall.num === activeWallNumber)
);

export const getSelectedAssetSizeRange = createSelector(
  getScene,
  getSelectedSceneAssetUuids,
  getSceneAssets,
  getActiveWallInfo,
  (scene, selectedSceneAssetId, sceneAssets, wallInfo) => {
    if (!selectedSceneAssetId) {
      return {};
    }

    const sceneAsset = sceneAssets.find((sceneAsset) =>
      selectedSceneAssetId.includes(sceneAsset.uuid)
    );

    if (!sceneAsset || !scene || !scene.instance) {
      return {};
    }

    const selectedAsset = sceneAssets.find(
      (sceneAsset) => sceneAsset.uuid === selectedSceneAssetId[0]
    );
    if (
      sceneAsset.asset.type === AssetType.ASSET_TEXT &&
      wallInfo &&
      selectedAsset
    ) {
      return {
        minWidth: 0,
        maxWidth: wallInfo.width - selectedAsset.left + wallInfo.start,
        minHeight: 0,
        maxHeight: wallInfo.height - selectedAsset.top
      };
    }

    if (sceneAsset.asset.type === AssetType.ASSET_URL) {
      const availableWidth = Math.min(
        scene.instance.roomDimensions.width,
        3840
      );
      const minWidth = Math.min(200, availableWidth);
      const maxWidth = Math.max(minWidth, availableWidth);

      const availableHeight = scene.instance.roomDimensions.height;
      const minHeight = Math.min(200, availableHeight);
      const maxHeight = Math.max(minHeight, availableHeight);

      return {
        minWidth,
        maxWidth,
        minHeight,
        maxHeight
      };
    }

    return undefined;
  }
);

export const getSceneBackgroundAutoplay = createSelector(getScene, (scene) => {
  if (!scene) {
    return undefined;
  }
  return scene.autoplay;
});

export const getSceneBackgroundLoop = createSelector(getScene, (scene) => {
  if (!scene) {
    return undefined;
  }
  return scene.loop;
});

export const getBackgroundSettingFormInitialValues = createSelector(
  getSceneBackgroundAutoplay,
  getSceneBackgroundLoop,
  (autoplay, loop) => ({ autoplay, loop })
);

export const getTransitionSettingFormInitialValues = createSelector(
  getScene,
  (scene) => {
    if (!scene) {
      return null;
    }
    return {
      transitionLoop: scene.transitionLoop,
      transitionFadeMs: scene.transitionFadeMs,
      transitionUseWholeVideo: scene.transitionUseWholeVideo
    };
  }
);

export const getWallBackgroundDimensions = createSelector(getScene, (scene) => {
  if (scene && scene.wall) {
    const wall = (scene.wall as unknown) as {
      scale: { width: number; height: number };
    };
    return wall.scale;
  }
});

export const getOnClickActions = createSelector(
  getPresentation,
  (presentation) => (presentation ? presentation.assetActions : [])
);

export const getGotoSceneAction = createSelector(
  getPresentation,
  getAssetActionSceneAssetId,
  (presentation, sceneAssetId) => {
    if (!presentation || !sceneAssetId) {
      return null;
    }
    return presentation.assetActions.find(
      (assetAction) =>
        assetAction.sceneAssetId === sceneAssetId && !!assetAction.targetSceneId
    );
  }
);

export const getGotoSceneActionsScene = createSelector(
  getScenes,
  getGotoSceneAction,
  (scenes, gotoSceneAssetAction) => {
    if (
      !scenes ||
      !gotoSceneAssetAction ||
      !gotoSceneAssetAction.targetSceneId
    ) {
      return null;
    }
    return gotoSceneAssetAction && scenes[gotoSceneAssetAction.targetSceneId];
  }
);

export const getShowAssetActions = createSelector(
  getPresentation,
  getERSceneAssets,
  getAssetActionSceneAssetId,
  (presentation, sceneAssets, sceneAssetId) => {
    if (!presentation || !sceneAssetId) {
      return;
    }

    const sceneAsset = Object.values(sceneAssets).find(
      (asset) => asset.id === sceneAssetId
    );

    if (!sceneAsset) {
      return;
    }

    const assetActions = presentation.assetActions.filter((assetAction) => {
      return (
        assetAction.sceneAssetId === sceneAssetId &&
        !!assetAction.targetSceneAssetId
      );
    });

    return assetActions.map((assetAction) => {
      const sceneAsset = Object.values(sceneAssets).find(
        (asset) => asset.id === assetAction.targetSceneAssetId
      );
      return {
        assetAction,
        sceneAsset: sceneAsset
      };
    });
  }
);

export const isAssetActionsApiInProgress = createSelector(
  getState,
  (state) => state.assetActionsLoader
);

export const getHorizontalCenterHelper = createSelector(
  getState,
  (state) => state.showHorizontalCenterHelper
);

export const getVerticalCenterHelper = createSelector(
  getState,
  (state) => state.showVerticalCenterHelper
);

export const getSelectionBoundingBox = createSelector(
  getSelectedSceneAssets,
  getActiveWallInfo,
  (assets, wallInfo) => {
    if (!assets.length || !wallInfo) {
      return;
    }

    const left = assets.reduce<number>((result, asset) => {
      return !isNaN(result) && result < asset.left ? result : asset.left;
    }, wallInfo.start + wallInfo.width);
    const right = assets.reduce<number>((result, asset) => {
      let scalingFactorWidth = 1;

      if (asset.asset.type === AssetType.ASSET_TEXT && wallInfo) {
        scalingFactorWidth = wallInfo.width / 100;
      }

      return !isNaN(result) &&
        result >
          asset.left +
            (asset.cropWidth * asset.scale * scalingFactorWidth) / 100
        ? result
        : asset.left +
            (asset.cropWidth * asset.scale * scalingFactorWidth) / 100;
    }, wallInfo.start);

    const top = assets.reduce<number>(
      (result, asset) =>
        !isNaN(result) && result < asset.top ? result : asset.top,
      wallInfo.height
    );
    const bottom = assets.reduce<number>((result, asset) => {
      let scalingFactorHeight = 1;

      if (asset.asset.type === AssetType.ASSET_TEXT && wallInfo) {
        scalingFactorHeight = wallInfo.height / 100;
      }

      return !isNaN(result) &&
        result >
          asset.top +
            (asset.cropHeight * asset.scale * scalingFactorHeight) / 100
        ? result
        : asset.top +
            (asset.cropHeight * asset.scale * scalingFactorHeight) / 100;
    }, 0);
    return { left, right, top, bottom };
  }
);

export const getIsDragging = createSelector(
  getState,
  (state) => state.selectedSceneAssetIds.length && state.dragging
);

export const getActiveWallCoords = createSelector(
  getState,
  (state) => state.activeWallCoords
);

export const getSelectAssetBounds = createSelector(
  getScene,
  getSelectedSceneAssetUuids,
  getSelectionBoundingBox,
  getSceneAssets,
  getActiveWallInfo,
  (scene, selectedSceneAssetId, boundingBox, sceneAssets, wallData) => {
    if (!selectedSceneAssetId) {
      return undefined;
    }

    const sceneAsset = sceneAssets.find((sceneAsset) =>
      selectedSceneAssetId.includes(sceneAsset.uuid)
    );

    if (!sceneAsset || !wallData || !scene || !scene.instance || !boundingBox) {
      return undefined;
    }
    // counting in relative coordinates to the active wall

    const leftEnd =
      sceneAssets.length === 1 && sceneAsset.asset.type === AssetType.ASSET_URL
        ? // URL assets can overflow between walls
          scene.instance.roomDimensions.width - wallData.start
        : // other assets can not overflow walls
          wallData.width;

    const leftStart =
      sceneAssets.length === 1 && sceneAsset.asset.type === AssetType.ASSET_URL
        ? // URL assets can overflow between walls
          -wallData.start
        : // other assets can not overflow walls
          0;

    const leftMax = leftEnd - Math.round(boundingBox.right - boundingBox.left);

    const topMax =
      wallData.height - Math.round(boundingBox.bottom - boundingBox.top);

    return {
      leftMin: leftStart,
      leftMax,
      topMin: 0,
      topMax
    };
  }
);

export const getEditingTextAssetId = createSelector(
  getState,
  (state) => state.editingTextAssetId
);

export const getIsScalingShown = createSelector(
  getSelectedSceneAssets,
  (state) =>
    state.every(
      (assetScene) =>
        assetScene.asset.type !== AssetType.ASSET_TEXT &&
        assetScene.asset.type !== AssetType.ASSET_URL
    )
);

export const getIsResizingShown = createSelector(
  getSelectedSceneAssets,
  (state) =>
    state.every((assetScene) => assetScene.asset.type === AssetType.ASSET_TEXT)
);

export const getIsCroppingShown = createSelector(
  getSelectedSceneAssets,
  (state) =>
    state.length === 1 && state[0].asset.playAs === AssetType.ASSET_IMAGE
);

export const getIsBoundingBox = createSelector(
  getState,
  (state) => !state.editingTextAssetId
);

export const getAssetConfigInitialValues = createSelector(
  getSelectedSceneAssets,
  getActiveWallInfo,
  (selectedSceneAssets, activeWallInfo) => {
    return {
      left: activeWallInfo
        ? (resolveConflictForProperty(
            "left",
            selectedSceneAssets,
            null
          ) as number) - activeWallInfo.start
        : resolveConflictForProperty("left", selectedSceneAssets, null),
      top: resolveConflictForProperty("top", selectedSceneAssets, null),
      positionLocked: resolveConflictForProperty(
        "positionLocked",
        selectedSceneAssets,
        false
      ),
      scale: resolveConflictForProperty("scale", selectedSceneAssets, null),
      width: resolveConflictForProperty("width", selectedSceneAssets, null),
      height: resolveConflictForProperty("height", selectedSceneAssets, null),
      visible: resolveConflictForProperty(
        "visible",
        selectedSceneAssets,
        false
      ),
      opacity: resolveConflictForProperty("opacity", selectedSceneAssets, null),
      loop: resolveConflictForProperty("loop", selectedSceneAssets, false),
      autoplay: resolveConflictForProperty(
        "autoplay",
        selectedSceneAssets,
        false
      ),
      glow: resolveConflictForProperty("glow", selectedSceneAssets, false),
      rounded: resolveConflictForProperty("rounded", selectedSceneAssets, false)
    };
  }
);

export const getDraggingOffsetX = createSelector(
  getState,
  (state) => state.draggingOffsetX
);
export const getDraggingOffsetY = createSelector(
  getState,
  (state) => state.draggingOffsetY
);
export const getDraggingOffset = createSelector(getState, (state) => ({
  offsetX: state.draggingOffsetX,
  offsetY: state.draggingOffsetY
}));
export const getCroppingChange = createSelector(getState, (state) => ({
  cropWidth: state.cropWidth,
  cropHeight: state.cropHeight,
  cropOffsetX: state.cropOffsetX,
  cropOffsetY: state.cropOffsetY
}));

export const getIsCropping = createSelector(
  getState,
  (state) => state.isCropping
);

export const getCropOrigin = createSelector(
  getState,
  (state) => state.cropOrigin
);

export const getIsScaling = createSelector(
  getState,
  (state) => state.isScaling
);

export const getScaleOrigin = createSelector(
  getState,
  (state) => state.scaleOrigin
);

export const getScaleChange = createSelector(
  getState,
  (state) => state.scaleChange
);

export const getIsResizing = createSelector(
  getState,
  (state) => state.isResizing
);

export const getResizingChange = createSelector(getState, (state) => ({
  widthChange: state.resizingWidthChange,
  heightChange: state.resizingHeightChange
}));

export const getResizingOrigin = createSelector(
  getState,
  (state) => state.resizingOrigin
);

export const getIsSelectionDraggingDisabled = createSelector(
  getState,
  (state) => state.selectionDraggingDisabled
);

export const getTotalInstanceWidth = createSelector(
  getSceneInstanceWalls,
  (walls) => {
    return (
      walls &&
      walls.reduce((accumulator, value) => accumulator + value.width, 0)
    );
  }
);

export const getInstanceWallHeight = createSelector(
  getSceneInstanceWalls,
  (walls) => {
    if (
      walls &&
      walls[0] &&
      !walls.every((wall) => wall.height === walls[0].height)
    ) {
      logger.warn(`Instance walls have different heights`);
    }
    return walls && walls[0].height;
  }
);

export const getWallAssetUrl = createSelector(
  getSceneWallAsset,
  getCdnHost,
  (wall, cdnHost) => {
    let url = "";
    if (wall) {
      url = `${cdnHost}/assets/${wall.uuid}_${wall.fileVersion}_thumb_big.jpg`;
    }
    return url;
  }
);

export const getMainWallAssetUrl = createSelector(
  getMainSceneWallAsset,
  getCdnHost,
  (wall, cdnHost) => {
    let url = "";
    if (wall) {
      url = `${cdnHost}/assets/${wall.uuid}_${wall.fileVersion}_thumb_big.jpg`;
    }
    return url;
  }
);

export const getWallAssetHeight = createSelector(
  getMainSceneWallAsset,
  (wall) => {
    return wall && wall.dimensions.height;
  }
);

export const getWallAssetWidth = createSelector(
  getMainSceneWallAsset,
  (wall) => {
    return wall && wall.dimensions.width;
  }
);

export const getBackgroundState = createSelector(getScene, (scene) => {
  if (!scene) {
    return;
  }

  const backgroundSettings =
    scene.backgroundSettings && scene.backgroundSettings.find(Boolean);

  //Just until there are more scene settings for several instances
  if (backgroundSettings) {
    return {
      left: backgroundSettings.backgroundLeft,
      top: backgroundSettings.backgroundTop,
      width: backgroundSettings.backgroundWidth,
      height: backgroundSettings.backgroundHeight,
      uuid: backgroundSettings.uuid
    };
  }
});

export const getBackgroundSettingsUuid = createSelector(getScene, (scene) => {
  return (
    scene && scene.backgroundSettings[0] && scene.backgroundSettings[0].uuid
  );
});

export const getBackgroundAssetRatio = createSelector(
  getSceneWallAsset,
  (asset) => {
    if (!asset) {
      return;
    }
    return asset.dimensions.width / asset.dimensions.height;
  }
);

export const getEditBackgroundDraggingOffset = createSelector(
  getState,
  (state) => {
    return {
      offsetX: state.editBackgroundDraggingOffsetX,
      offsetY: state.editBackgroundDraggingOffsetY
    };
  }
);

export const getIsEditedBackgroundLowResolution = createSelector(
  getSceneWallAsset,
  getTotalInstanceWidth,
  (wallAsset, totalWallWidth) => {
    if (!wallAsset || !totalWallWidth) {
      return;
    }

    const LOW_RESOLUTION_THRESHOLD = 0.8;

    return (
      wallAsset.dimensions.width < totalWallWidth * LOW_RESOLUTION_THRESHOLD
    );
  }
);

export const getEditBackgroundResizingState = createSelector(
  getState,
  (state) => {
    return {
      absoluteWidthChange: state.editBackgroundResizingAbsoluteWidthChange,
      absoluteHeightChange: state.editBackgroundResizingAbsoluteHeightChange,
      absoluteLeftChange: state.editBackgroundResizingAbsoluteLeftChange,
      absoluteTopChange: state.editBackgroundResizingAbsoluteTopChange
    };
  }
);

export enum EditedBackgroundState {
  VALID = "VALID",
  INVALID = "INVALID",
  RECOMMENDED = "RECOMMENDED"
}

export const getEditedBackgroundScreenScale = createSelector(
  getState,
  (state) => {
    return state.editBackgroundCanvasScale;
  }
);

export const getEditedBackgroundValidity = createSelector(
  getBackgroundState,
  getEditBackgroundDraggingOffset,
  getEditBackgroundResizingState,
  getEditedBackgroundScreenScale,
  getInstanceWallHeight,
  getTotalInstanceWidth,
  (
    backgroundState,
    draggingState,
    resizingState,
    screenScale,
    wallHeight,
    totalWidth
  ) => {
    if (!backgroundState || !totalWidth || !wallHeight) {
      return EditedBackgroundState.INVALID;
    }

    return checkEditBackgroundStateValidity(
      backgroundState,
      { width: totalWidth, height: wallHeight },
      resizingState,
      {
        absoluteOffsetX: draggingState.offsetX,
        absoluteOffsetY: draggingState.offsetY
      }
    );
  }
);

export const isAddUrlAssetModalOpenAndFocused = createSelector(
  getFormMeta("url-asset-modal"),
  (meta) => {
    const typedMeta = meta as {
      url?: { active: boolean };
      title?: { active: boolean };
    };
    return (
      typedMeta &&
      ((typedMeta.url && typedMeta.url.active) ||
        (typedMeta.title && typedMeta.title.active))
    );
  }
);
