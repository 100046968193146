import { useSelector } from "react-redux";
import { ReactNode } from "react";

import { getCustomFieldMappingsForEntity } from "../../custom-fields-selectors";
import { CustomFieldEntity } from "../../../../../../shared/types/custom-field-entity";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";

export const CustomFieldListRowFactory = (
  renderer: (
    id: number | string,
    label: string,
    value: string | number
  ) => ReactNode
) => {
  const CustomFieldsRow = (props: {
    customFields: { value: string | number; customFieldId: number }[];
    entity: CustomFieldEntity;
  }) => {
    const { customFields, entity } = props;
    const customFieldMappings = useSelector(getCustomFieldMappingsForEntity)(
      entity
    );

    return (customFieldMappings || []).map(
      ({ customField: { label, id, type, values } }) => {
        const customField = customFields.find(
          ({ customFieldId }) => id === customFieldId
        );
        let value: string | number = "";

        if (customField) {
          switch (type) {
            case CustomFieldType.TEXT:
              value = customField.value;
              break;
            case CustomFieldType.SINGLE_CHOICE:
              // eslint-disable-next-line no-case-declarations
              const valueInRepository = values.find(
                (customFieldValue) => customFieldValue.id === customField.value
              );

              if (valueInRepository) {
                value = valueInRepository.value;
              }
              break;
            default:
              throw new Error(`Unknown state ${type}`);
          }
        }

        return renderer(id, label, value);
      }
    );
  };

  return CustomFieldsRow;
};
