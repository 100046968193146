import { schema } from "normalizr";

export const predefinedScene = new schema.Entity("predefinedscenes");
export const predefinedScenes = [predefinedScene];

export const userSchema = new schema.Entity("users");

export const instance = new schema.Entity(
  "instances",
  {},
  {
    idAttribute: "uuid",
    processStrategy: (entity) => {
      const walls = [...(entity.walls || [])].sort((a, b) => a.num - b.num);

      return {
        ...entity,
        walls
      };
    }
  }
);

export const instances = [instance];

export const asset = new schema.Entity(
  "assets",
  {},
  {
    idAttribute: "uuid"
  }
);

export const assets = [asset];
asset.define({ alternativeAssets: assets });

export const sceneAsset = new schema.Entity(
  "sceneassets",
  {
    asset
  },
  {
    idAttribute: "uuid"
  }
);

export const sceneAssets = [sceneAsset];

export const scene = new schema.Entity(
  "scenes",
  {
    sceneAssets,
    wall: asset
  },
  {
    idAttribute: "id"
  }
);

export const scenes = [scene];

export const presentationScene = new schema.Entity(
  "presentationscenes",
  {
    scene
  },
  {
    idAttribute: (value, presentation) => `${value.sceneId}-${presentation.id}`
  }
);
export const presentationScenes = [presentationScene];

export const assetAction = new schema.Entity("assetactions");
export const assetActions = [assetAction];

export const customFieldHierarchy = new schema.Entity("customFieldHierarchies");
export const customFieldHierarchies = [customFieldHierarchy];

export const customFieldToEntityMapping = new schema.Entity(
  "customFieldToEntityMappings",
  {
    customFieldHierarchies: customFieldHierarchies
  }
);
export const customFieldToEntityMappings = [customFieldToEntityMapping];

export const customField = new schema.Entity("customFields", {
  entityMappings: customFieldToEntityMappings
});
export const customFields = [customField];

export const presentation = new schema.Entity("presentations", {
  assetActions,
  presentationScenes
});

export const guestDomain = new schema.Entity("guestDomains");

export const trigger = new schema.Entity("triggers");
export const triggers = [trigger];
