import { useCallback, useState } from "react";
import { Logger as logger } from "purplex-logging";

const DRAGGING_GUIDE_SNAP_THRESHOLD_CENTER_HORIZONTAL = 50;
const DRAGGING_GUIDE_SNAP_THRESHOLD_CENTER_VERTICAL = 50;
const DRAGGING_GUIDE_SNAP_THRESHOLD_HORIZONTAL_EDGES = 50;
const DRAGGING_GUIDE_SNAP_THRESHOLD_VERTICAL_EDGES = 50;

export const useHorizontalCenterGuide = () => {
  const [
    isHorizontalCenterGuideShown,
    setIsHorizontalCenterGuideShown
  ] = useState(false);

  const applyHorizontalCenterGuide = useCallback(
    (
      position: {
        draggingOffsetX: number;
        draggingOffsetY: number;
      },
      canvasScale: number,
      wallHeight: number,
      assetHeight: number,
      assetTop: number
    ) => {
      const tempPosition = { ...position };
      if (
        Math.abs(
          wallHeight / 2 -
            (assetHeight / 2 +
              assetTop +
              position.draggingOffsetY / canvasScale)
        ) < DRAGGING_GUIDE_SNAP_THRESHOLD_CENTER_HORIZONTAL
      ) {
        logger.debug("Applying horizontal center guide.");
        setIsHorizontalCenterGuideShown(true);
        tempPosition.draggingOffsetY =
          (wallHeight / 2 - assetHeight / 2 - assetTop) * canvasScale;
      } else {
        setIsHorizontalCenterGuideShown(false);
      }
      return tempPosition;
    },
    []
  );

  return { isHorizontalCenterGuideShown, applyHorizontalCenterGuide };
};

export const useVerticalCenterGuide = () => {
  const [isVerticalCenterGuideShown, setIsVerticalCenterGuideShown] = useState(
    false
  );

  const applyVerticalCenterGuide = useCallback(
    (
      position: {
        draggingOffsetX: number;
        draggingOffsetY: number;
      },
      canvasScale: number,
      totalWallWidth: number,
      assetWidth: number,
      assetLeft: number
    ) => {
      const tempPosition = { ...position };
      if (
        Math.abs(
          totalWallWidth / 2 -
            (assetWidth / 2 +
              assetLeft +
              position.draggingOffsetX / canvasScale)
        ) < DRAGGING_GUIDE_SNAP_THRESHOLD_CENTER_VERTICAL
      ) {
        logger.debug("Applying vertical center guide.");
        setIsVerticalCenterGuideShown(true);
        tempPosition.draggingOffsetX =
          (totalWallWidth / 2 - assetWidth / 2 - assetLeft) * canvasScale;
      } else {
        setIsVerticalCenterGuideShown(false);
      }
      return tempPosition;
    },
    []
  );

  return { isVerticalCenterGuideShown, applyVerticalCenterGuide };
};

export const useLeftEdgeGuide = () => {
  const applyLeftEdgeGuide = useCallback(
    (
      position: {
        draggingOffsetX: number;
        draggingOffsetY: number;
      },
      canvasScale: number,
      assetLeft: number
    ) => {
      const tempPosition = { ...position };
      if (
        Math.abs(0 - (assetLeft + position.draggingOffsetX / canvasScale)) <
        DRAGGING_GUIDE_SNAP_THRESHOLD_HORIZONTAL_EDGES
      ) {
        logger.debug("Applying left edge guide.", {
          assetLeft,
          draggingOffsetX: position.draggingOffsetX,
          canvasScale,
          position: assetLeft + position.draggingOffsetX / canvasScale
        });
        tempPosition.draggingOffsetX = (0 - assetLeft) * canvasScale;
      }

      return tempPosition;
    },
    []
  );

  return { applyLeftEdgeGuide };
};

export const useTopEdgeGuide = () => {
  const applyTopEdgeGuide = useCallback(
    (
      position: {
        draggingOffsetX: number;
        draggingOffsetY: number;
      },
      canvasScale: number,
      assetTop: number
    ) => {
      const tempPosition = { ...position };
      if (
        Math.abs(0 - (assetTop + position.draggingOffsetY / canvasScale)) <
        DRAGGING_GUIDE_SNAP_THRESHOLD_VERTICAL_EDGES
      ) {
        logger.debug("Applying top edge guide.");
        tempPosition.draggingOffsetY = (0 - assetTop) * canvasScale;
      }

      return tempPosition;
    },
    []
  );

  return { applyTopEdgeGuide };
};

export const useRightEdgeGuide = () => {
  const applyRightEdgeGuide = useCallback(
    (
      position: {
        draggingOffsetX: number;
        draggingOffsetY: number;
      },
      canvasScale: number,
      wallWidth: number,
      assetWidth: number,
      assetLeft: number
    ) => {
      const tempPosition = { ...position };
      if (
        Math.abs(
          wallWidth -
            (assetWidth + assetLeft + position.draggingOffsetX / canvasScale)
        ) < DRAGGING_GUIDE_SNAP_THRESHOLD_VERTICAL_EDGES
      ) {
        logger.debug("Applying right edge guide.");
        tempPosition.draggingOffsetX =
          (wallWidth - (assetWidth + assetLeft)) * canvasScale;
      }

      return tempPosition;
    },
    []
  );

  return { applyRightEdgeGuide };
};

export const useBottomEdgeGuide = () => {
  const applyBottomEdgeGuide = useCallback(
    (
      position: {
        draggingOffsetX: number;
        draggingOffsetY: number;
      },
      canvasScale: number,
      wallHeight: number,
      assetHeight: number,
      assetTop: number
    ) => {
      const tempPosition = { ...position };
      if (
        Math.abs(
          wallHeight -
            (assetHeight + assetTop + position.draggingOffsetY / canvasScale)
        ) < DRAGGING_GUIDE_SNAP_THRESHOLD_VERTICAL_EDGES
      ) {
        logger.debug("Applying bottom edge guide.");
        tempPosition.draggingOffsetY =
          (wallHeight - (assetHeight + assetTop)) * canvasScale;
      }

      return tempPosition;
    },
    []
  );

  return { applyBottomEdgeGuide };
};
