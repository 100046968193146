import { Logger as logger } from "purplex-logging";

import { AssetCh } from "../../types/asset";
import { ImInstance } from "../../types/im-instance";
import * as ChMessageTypes from "../../types/ch-message-types";
import { BackgroundSettingsReduced } from "../../types/background-settings";
import * as DefaultTransformations from "../default/default-scene-transformations";

interface RecommendedBackground {
  widthMin: number;
  widthMax: number;
  height: number;
  scaleUnproportionally: boolean;
}
interface Dimensions {
  width: number;
  height: number;
}

export const dimensionsFitRecommendedBackground = (
  dimensions: Dimensions,
  recommendedBackground: RecommendedBackground
) => {
  const { widthMin, widthMax, height } = recommendedBackground;

  return (
    widthMin <= dimensions.width &&
    widthMax >= dimensions.width &&
    height === dimensions.height
  );
};

const findRecommendedBackgroundForDimensions = (
  dimensions: Dimensions,
  recommendedBackgrounds: RecommendedBackground[]
) =>
  (recommendedBackgrounds || []).find((recommendedBackground) =>
    dimensionsFitRecommendedBackground(dimensions, recommendedBackground)
  );

const transformToBestWallAsset = (
  wallAsset: AssetCh,
  targetInstanceConfig: ImInstance
) => {
  const { recommendedBackgrounds, roomDimensions } = targetInstanceConfig;

  const assets = [wallAsset, ...wallAsset.alternativeAssets].filter(
    // fileVersion >= 0 means the file have been already uploaded
    ({ fileVersion }) => fileVersion >= 0
  );

  const bests = assets.filter(
    ({ dimensions }) =>
      !!findRecommendedBackgroundForDimensions(
        dimensions,
        recommendedBackgrounds
      )
  );

  let best = wallAsset;

  if (bests.length === 1) {
    best = bests[0];
  } else if (bests.length > 1) {
    let minimalDelta = Number.POSITIVE_INFINITY;

    for (let i = 0; i < bests.length; i++) {
      const asset = bests[i];
      const delta = Math.abs(roomDimensions.width - asset.dimensions.width);

      if (delta < minimalDelta) {
        minimalDelta = delta;
        best = asset;
      }
    }
  }

  if (best === wallAsset) {
    return best;
  }

  // recreate the asset so the best asset is the new parent and the former parent
  // becomes an alternative asset
  return {
    ...best,
    alternativeAssets: [
      {
        ...wallAsset,
        alternativeAssets: []
      },
      ...wallAsset.alternativeAssets.filter(
        (alternative) => alternative !== best
      )
    ],
    // alternative assets doesn't contain user's data -> must be copied from the parent asset
    title: wallAsset.title
  };
};

const transformWallAutomatically = (
  sceneWall: AssetCh,
  targetInstanceConfig: ImInstance
) => {
  if (!sceneWall || !targetInstanceConfig) {
    throw Error("Arguments sceneWall and targetInstanceConfig must be given.");
  }

  const bestAsset = transformToBestWallAsset(sceneWall, targetInstanceConfig);

  const { dimensions: assetDimensions } = bestAsset;
  const { roomDimensions, projectors } = targetInstanceConfig;

  /*
   * Determine background scaling
   */
  const scale = {
    width: assetDimensions.width,
    height: assetDimensions.height
  };

  let scaleWidth = 1;

  if (roomDimensions.width < assetDimensions.width) {
    scaleWidth = roomDimensions.width / assetDimensions.width;
    scale.width = roomDimensions.width;
    scale.height = Math.round(assetDimensions.height * scaleWidth);
  }

  const recommendedBackground = findRecommendedBackgroundForDimensions(
    assetDimensions,
    targetInstanceConfig.recommendedBackgrounds
  );

  const scaleUnproportionally =
    recommendedBackground && recommendedBackground.scaleUnproportionally;

  if (scaleUnproportionally) {
    scale.height = roomDimensions.height;
  }

  /*
   * Determine background padding
   */
  const pad = {
    width: roomDimensions.width,
    height: roomDimensions.height,
    top: roomDimensions.height - scale.height,
    left: 0
  };

  /*
   * Determine background slices
   */
  const slices = projectors
    .map(({ startOffset, num, width }) => {
      let sliceWidth = width;

      if (startOffset >= roomDimensions.width) {
        logger.warn(
          `Projector num=${num} is configured so it would start outside room's width. Projector's startOffset=${startOffset}, width=${width}. Room's width=${roomDimensions.width}`
        );

        return false;
      } else if (startOffset + width > roomDimensions.width) {
        logger.warn(
          `Projector num=${num} is configured so it would overflow room's width. Projector's startOffset=${startOffset}, width=${width}. Room's width=${roomDimensions.width}`
        );

        sliceWidth = roomDimensions.width - startOffset;
      }

      return {
        width: sliceWidth,
        height: roomDimensions.height,
        leftOffset: startOffset,
        num
      };
    })
    .filter(Boolean);

  return {
    ...bestAsset,
    scale,
    pad,
    slices
  };
};

export const transformWall = (
  sceneWall: AssetCh,
  targetInstanceConfig: ImInstance,
  backgroundSettings: BackgroundSettingsReduced | undefined
) => {
  if (backgroundSettings) {
    return DefaultTransformations.transformWall(
      sceneWall,
      targetInstanceConfig,
      backgroundSettings
    );
  } else {
    return transformWallAutomatically(sceneWall, targetInstanceConfig);
  }
};

export const getSceneMessages = (
  scene: { wall: { dimensions: Dimensions } },
  targetInstanceConfig: ImInstance
) => {
  const messages = [];

  if (scene.wall) {
    const dimensions = scene.wall.dimensions;
    if (
      targetInstanceConfig.recommendedBackgrounds.length > 0 &&
      !targetInstanceConfig.recommendedBackgrounds.some(
        ({ height, widthMin, widthMax }) =>
          dimensions.height === height &&
          dimensions.width >= widthMin &&
          dimensions.width <= widthMax
      )
    ) {
      messages.push(ChMessageTypes.INVALID_BACKGROUND_DIMENSIONS);
    }
  }

  return messages;
};
