import React, { Component } from "react";
import { ButtonDialog } from "./button-dialog";

import { ReactComponent as IconSearch } from "client/images/search.svg";
import { ReactComponent as IconTrash } from "client/images/trash.svg";
import { ReactComponent as IconShare } from "client/images/share.svg";
import { ReactComponent as IconCog } from "client/images/cog.svg";

export class ComponentPages extends Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      text: "",
      checked: false
    };
  }
  // handler for component page select box..
  handleClick() {
    this.setState((prevState) => ({
      checked: !prevState.checked
    }));
  }

  render() {
    return (
      <div className="componentPage">
        <h1>Component Page</h1>
        <p>Here you can see example of styled components</p>

        <div className="box">
          <h2>typography</h2>

          <h1>Title</h1>
          <h2>Title</h2>
          <h3>Title</h3>
          <h4>Title</h4>
          <h5>Title</h5>
          <h6>Title</h6>

          <p>Paragraph</p>
          <span>span</span>
          <p>
            <b>bold text</b>
          </p>
        </div>
        <div className="box">
          <h2 id="buttons">Buttons</h2>

          <h4>Default buttons</h4>
          <a href="#buttons" className="button">
            link
          </a>
          <span className="button">span</span>
          <button className="button">button</button>
          <button className="button buttonSecondary">.buttonSecondary</button>
          <button className="button buttonAlert">.buttonAlert</button>
          <button className="button noRipple">.noRipple</button>
          <button className="button" disabled>
            [disabled]
          </button>

          <h4>Rounded buttons</h4>
          <button className="button buttonRounded">.buttonRounded</button>
          <button className="button buttonRounded buttonSecondary">
            .buttonRounded .buttonSecondary
          </button>
          <button className="button buttonRounded buttonAlert">
            .buttonRounded .buttonAlert
          </button>
          <button className="button buttonRounded" disabled>
            .buttonRounded [disabled]
          </button>

          <h4>Outlined buttons</h4>
          <button className="button buttonOutlined">.buttonOutlined</button>
          <button className="button buttonOutlined buttonRounded">
            .buttonOutlined .buttonRounded
          </button>
          <button className="button buttonOutlined buttonRounded buttonAlert">
            <IconTrash />
            .buttonOutlined .buttonRounded .buttonAlert
          </button>
          <button className="button buttonOutlined buttonRounded buttonSecondary">
            .buttonOutlined .buttonRounded .buttonSecondary
          </button>
          <button className="button buttonOutlined" disabled>
            .buttonOutlined [disabled]
          </button>

          <h4>Icon buttons</h4>
          <button className="button buttonIcon" title="Share">
            <IconShare />
          </button>
          <button
            className="button buttonIcon buttonOutlined buttonRounded"
            title="Settings"
          >
            <IconCog />
          </button>
          <button className="button buttonIcon buttonOutlined buttonAlert">
            <IconTrash />
          </button>

          <h4>Button Dialog</h4>
          <ButtonDialog>
            <button className="button itemDialog">Create new scene</button>
            <button className="button itemDialog active">
              Add existing scene
            </button>
            <button className="button itemDialog">Add existing scene</button>
          </ButtonDialog>

          <h4 id="link">Link</h4>
          <a href="#link">just normal link</a>
        </div>

        <div className="box">
          <h2>Slider box</h2>

          <div className="toggle" style={{ maxWidth: 200 }}>
            <label className="toggleLabel">
              <input checked={this.state.checked} type="checkbox" />
              <button className="toggleSlider" onClick={this.handleClick} />
              Example text
            </label>
          </div>
        </div>

        <div className="box">
          <h2>Inputs</h2>

          <div className="inputBox">
            <label>Basic input</label>
            <input
              className="input"
              value={this.state.text}
              type="text"
              placeholder="placeholder"
              onChange={(e) => this.setState({ text: e.target.value })}
            />
          </div>

          <div className="inputBox">
            <label>With button</label>
            <div className="inputWithButton">
              <input
                className="input"
                value={this.state.text}
                type="text"
                placeholder="placeholder"
                onChange={(e) => this.setState({ text: e.target.value })}
              />
              <button className="button">
                <IconSearch />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
