import { put, call, take, fork, cancel, delay } from "redux-saga/effects";
import { actionTypes as routerActions } from "redux-router5";

import { getShares } from "../api/client";
import {
  PRESENTATIONS_LIST_ROUTE,
  SCENES_LIST_ROUTE
} from "../routing/route-names";
import Actions from "./shares-actions";

const DEAMON_TIMEOUT = 60000;

function* sharesDeamon() {
  while (true) {
    const shares = yield call(getShares);
    yield put(Actions.Creators.statsFetched(shares));
    yield delay(DEAMON_TIMEOUT);
  }
}

export function* sharesSaga() {
  let lastDaemonProcess = null;

  while (true) {
    const {
      payload: { route }
    } = yield take(routerActions.TRANSITION_SUCCESS);

    // We schedule daemon just for presentation list and create modal on top of it
    if (
      route.name === PRESENTATIONS_LIST_ROUTE ||
      route.name === "presentations.list.add" ||
      route.name === SCENES_LIST_ROUTE ||
      route.name === "scenes.list.add"
    ) {
      // Since it may take a while until next daemon process picks that up we just clean
      // the share statistics straight on the front-end when user clicks on the tab
      if (
        route.params.type === "collaborate" ||
        route.params.type === "cooperation"
      ) {
        yield put(Actions.Creators.resetPresentationCollaborate());
        yield put(Actions.Creators.resetSceneCollaborate());
      } else if (route.params.type === "shared") {
        yield put(Actions.Creators.resetPresentationShared());
        yield put(Actions.Creators.resetSceneShared());
      }

      // Initiate the daemon
      if (lastDaemonProcess === null) {
        lastDaemonProcess = yield fork(sharesDeamon);
      }

      // If it's already active eg going from list -> active do nothing
    } else {
      // If we are leaving the url and daemon is still active let's cancel it
      if (lastDaemonProcess) {
        yield cancel(lastDaemonProcess);
        lastDaemonProcess = null;
      }
    }
  }
}
