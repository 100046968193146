import { put, select, take, call } from "redux-saga/effects";

import * as Selectors from "./dialogs-selectors";
import { DialogEvent, DialogResult } from "./event-types";

import { DialogsActions } from "./dialogs-reducer";
import { DialogType } from "./dialog-type";

// @deprecated
function* sanitizeDialog() {
  const isAlreadyOpened = yield select(Selectors.isDialogOpened);

  if (isAlreadyOpened) {
    // eslint-disable-next-line no-console
    console.error("Forcefully closing previously opened dialog.");

    yield put(DialogsActions.onEvent({ eventType: DialogEvent.ON_CLOSE }));
  }

  yield put(DialogsActions.cleanUp());
}

// @deprecated
export function* userPickerDialogSaga(payload: {
  title: string;
  fieldLabel: string;
  resourceAccess: string;
}) {
  const { title, fieldLabel, resourceAccess } = payload;

  yield call(sanitizeDialog);

  yield put(
    DialogsActions.configure({
      dialogType: DialogType.USER_PICKER,
      config: {
        title,
        fieldLabel,
        resourceAccess
      }
    })
  );
  yield put(DialogsActions.open());

  const {
    payload: { eventType, eventPayload }
  } = yield take(DialogsActions.onEvent);
  yield put(DialogsActions.close());

  switch (eventType) {
    case DialogResult.USER_SELECTED:
      return eventPayload;
    default:
      return false;
  }
}

// @deprecated
export function* roomPickerDialogSaga(payload: {
  title: string;
  confirmLabel: string;
  filteredInstances: string[];
}) {
  const { title, confirmLabel, filteredInstances } = payload;

  yield call(sanitizeDialog);

  yield put(
    DialogsActions.configure({
      dialogType: DialogType.ROOM_PICKER,
      config: {
        title,
        confirmLabel,
        filteredInstances
      }
    })
  );
  yield put(DialogsActions.open());

  const {
    payload: { eventType, eventPayload }
  } = yield take(DialogsActions.onEvent);
  yield put(DialogsActions.close());

  switch (eventType) {
    case DialogResult.ROOM_SELECTED:
      return eventPayload;
    default:
      return false;
  }
}

// @deprecated
export function* confirmDialogSaga(payload: {
  title: string;
  text: string;
  subtext: string;
  confirmLabel: string;
  cancelLabel: string;
}) {
  const { title, text, subtext, confirmLabel, cancelLabel } = payload;
  yield call(sanitizeDialog);

  yield put(
    DialogsActions.configure({
      dialogType: DialogType.CONFIRM,
      config: {
        title,
        text,
        subtext,
        confirmLabel,
        cancelLabel
      }
    })
  );
  yield put(DialogsActions.open());

  const {
    payload: { eventType }
  } = yield take(DialogsActions.onEvent);

  yield put(DialogsActions.close());

  switch (eventType) {
    case DialogEvent.ON_CONFIRM:
      return {
        type: DialogResult.CONFIRM
      };

    default:
      return {
        type: DialogResult.CANCEL
      };
  }
}

// @deprecated
export function* confirmWitInputDialogSaga(payload: {
  title: string;
  text: string;
  subtext: string;
  confirmLabel: string;
  cancelLabel: string;
  fieldLabel: string;
  confirmText: string;
}) {
  const {
    title,
    text,
    subtext,
    confirmLabel,
    cancelLabel,
    fieldLabel,
    confirmText
  } = payload;
  yield call(sanitizeDialog);

  yield put(
    DialogsActions.configure({
      dialogType: DialogType.CONFIRM_INPUT,
      config: {
        title,
        text,
        subtext,
        confirmLabel,
        cancelLabel,
        fieldLabel,
        confirmText
      }
    })
  );
  yield put(DialogsActions.open());

  const {
    payload: { eventType }
  } = yield take(DialogsActions.onEvent);
  yield put(DialogsActions.close());

  switch (eventType) {
    case DialogEvent.ON_CONFIRM:
      return {
        type: DialogResult.CONFIRM
      };

    default:
      return {
        type: DialogResult.CANCEL
      };
  }
}

// @deprecated
export function* messageDialogSaga(payload: {
  title: string;
  text: string;
  subtext: string;
  confirmLabel: string;
  cancelLabel: string;
}) {
  const { title, text, subtext, confirmLabel, cancelLabel } = payload;

  yield call(sanitizeDialog);

  yield put(
    DialogsActions.configure({
      dialogType: DialogType.MESSAGE,
      config: {
        title,
        text,
        subtext,
        confirmLabel,
        cancelLabel
      }
    })
  );
  yield put(DialogsActions.open());

  // we do not care about particular event since message dialog has only one
  // event type which is close dialog event
  yield take(DialogsActions.onEvent);
  yield put(DialogsActions.close());

  return {
    type: DialogResult.CLOSE
  };
}
