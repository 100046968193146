export enum DialogEvent {
  ON_CLOSE = "ON_CLOSE",
  ON_CANCEL = "ON_CANCEL",
  ON_CONFIRM = "ON_CONFIRM"
}

export enum DialogResult {
  USER_SELECTED = "USER_SELECTED",
  ROOM_SELECTED = "ROOM_SELECTED",
  COLLABORATORS_SELECTED = "COLLABORATORS_SELECTED",
  CANCEL = "CANCEL",
  CONFIRM = "CONFIRM",
  CLOSE = "CLOSE"
}
