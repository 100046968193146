import React from "react";
import cx from "classnames";

import { EditedBackgroundState } from "../../../scene-editor-selectors";

export const EditBackgroundScheme = React.memo(
  (props: {
    state: EditedBackgroundState;
    x: number;
    y: number;
    width: number;
    height: number;
  }) => {
    const { state, x: left, y: top, width, height } = props;

    return (
      <div
        className={cx("background-scheme", {
          "--success": state === EditedBackgroundState.RECOMMENDED,
          "--danger": state === EditedBackgroundState.INVALID,
          "--warning": state === EditedBackgroundState.VALID
        })}
        style={{
          height,
          left,
          top,
          width
        }}
      />
    );
  }
);
