import { GuestDomainFormData } from "../../../../../../shared/types/guest-domain";
import { InjectedFormProps } from "redux-form";
import * as api from "../../../../api/client";

export type GuestDomainErrors = Partial<
  Record<keyof GuestDomainFormData, string>
>;

export const guestDomainBlurFields = ["name", "adminEmail"];

export const guestDomainValidate = (
  values: GuestDomainFormData
): GuestDomainErrors => {
  const errors: GuestDomainErrors = {};

  if (!values.name || values.name.trim() === "") {
    errors.name = "Value is required.";
  }

  if (!values.adminFirstName || values.adminFirstName.trim() === "") {
    errors.adminFirstName = "Value is required.";
  }

  if (!values.adminLastName || values.adminLastName.trim() === "") {
    errors.adminLastName = "Value is required.";
  }

  if (!values.adminEmail || values.adminEmail.trim() === "") {
    errors.adminEmail = "Value is required.";
  }
  return errors;
};

export const guestDomainAsyncValidate = async <FormData>(
  values: GuestDomainFormData,
  _: never,
  props: FormData & InjectedFormProps<GuestDomainFormData, FormData>
): Promise<void> => {
  const errors: GuestDomainErrors = {};

  const hasNameChanged = props.initialValues
    ? props.initialValues.name !== values.name
    : true;
  if (values.name && hasNameChanged) {
    const result = await api.isGuestDomainNameUnique({
      name: values.name
    });
    if (!result.data.isUnique)
      errors.name = "Guest domain name must be unique.";
  }

  const hasAdminEmailChanged = props.initialValues
    ? props.initialValues.adminEmail !== values.adminEmail
    : true;
  if (values.adminEmail && hasAdminEmailChanged) {
    const result = await api.isGuestDomainAdminUnique({
      adminEmail: values.adminEmail
    });
    if (!result.data.isUnique) errors.adminEmail = "Admin must be unique.";
  }

  if (Object.values(errors).length) {
    throw errors;
  }
};
