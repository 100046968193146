import { all, fork } from "redux-saga/effects";

import presentationSaga from "../presentations/presentation-saga";
import sceneSaga from "../scenes/scene-saga";
import configSaga from "../config/config-saga";
import sceneEditorSaga from "../scene-editor/sagas/scene-editor-saga";
import predefinedSceneNames from "../settings/predefined-scene-names/predefined-scene-names-saga";
import usersSaga from "../settings/users/users-saga";
import { routingSaga } from "../routing/routing-saga";
import { zendeskSaga } from "../zendesk/zendesk-saga";
import notificationsSaga from "../ux/notifications/notifications-saga";
import { sharesSaga } from "../shares/shares-saga";
import { dashboardSaga } from "../dashboard/dashboard-saga";
import { authSaga } from "../auth/auth-saga";
import { mediaSaga } from "../media/media-saga";
import { userTrackingSaga } from "../user-tracking/user-tracking-saga";

export default function* () {
  yield all([
    fork(presentationSaga),
    fork(sceneSaga),
    fork(configSaga),
    fork(sceneEditorSaga),
    fork(predefinedSceneNames),
    fork(routingSaga),
    fork(usersSaga),
    fork(zendeskSaga),
    fork(notificationsSaga),
    fork(sharesSaga),
    fork(dashboardSaga),
    fork(authSaga),
    fork(mediaSaga),
    fork(userTrackingSaga)
  ]);
}
