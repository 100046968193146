import React, { useCallback, useEffect, useState } from "react";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

import { ReactComponent as IconCross } from "../../images/cross.svg";
import { ReactComponent as IconSearch } from "../../images/search.svg";
import { useSafeDebounce } from "../utils/use-safe-debounce";

const KEYSTROKE_DEBOUNCE_TIME = 300;

const useDebouncedSearch = (onChange: (value: string) => void) => {
  const [search, changeSearch] = useState("");

  const changedValueDebounced = useSafeDebounce(
    onChange,
    [onChange],
    KEYSTROKE_DEBOUNCE_TIME,
    {}
  );

  const updater = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    changeSearch(value);
    changedValueDebounced(value);
  };

  const clearSearch = () => {
    changeSearch("");
    changedValueDebounced("");
  };

  return {
    search,
    changeSearch: updater,
    clearSearch
  };
};

export const DebouncedSearchInput = (props: {
  onChange: (value: string) => void;
  value?: string;
  [key: string]: any;
}) => {
  const [focused, setFocused] = useState(false);
  const [localValue, setLocalValue] = useState("");

  const { value, onChange } = props;

  const { search, changeSearch, clearSearch } = useDebouncedSearch(onChange);

  const onFocusHandler = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlurHandler = useCallback(() => {
    setFocused(false);
  }, []);

  const onChangeHandler = useCallback(
    (e) => {
      if (focused) {
        changeSearch(e);
      } else {
        onChange(e);
      }
    },
    [focused, onChange, changeSearch]
  );

  useEffect(() => {
    if (value !== undefined) {
      onChange(value);
      setLocalValue(value);
    }
  }, [value, onChange]);

  useEffect(() => {
    if (search !== undefined) {
      setLocalValue(search);
    }
  }, [search]);

  return (
    <React.Fragment>
      <input
        className="input"
        type="text"
        value={localValue}
        onChange={onChangeHandler}
        data-qa={FORM_LOCATORS.inputs.getInput("search")}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
      />
      <button
        className="button"
        onClick={clearSearch}
        type="button"
        data-qa={FORM_LOCATORS.buttons.getButton("clear-search")}
      >
        {search === "" ? <IconSearch /> : <IconCross />}
      </button>
    </React.Fragment>
  );
};
