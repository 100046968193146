import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, Field, WrappedFieldInputProps } from "redux-form";
import cx from "classnames";

import { useSceneEditorActions } from "../../hooks/asset-config/use-scene-editor-actions";
import {
  getSelectedSceneAssetUuids,
  getScene,
  hasSelectedTextAsset
} from "../../scene-editor-selectors";
import { AsyncSelectField } from "client/modules/forms/async-select-field";
import { ToggleField } from "client/modules/forms/toggle-field";
import { TextAssetAlignment, TextAssetFontSize } from "shared/types/asset";
import { FONTS } from "../../hooks/use-preload-fonts";

import { ReactComponent as IconTextAlignCenter } from "client/images/text-align-center.svg";
import { ReactComponent as IconTextAlignLeft } from "client/images/text-align-left.svg";
import { ReactComponent as IconTextAlignRight } from "client/images/text-align-right.svg";
import { ReactComponent as IconTextBold } from "client/images/text-bold.svg";
import { ReactComponent as IconTextItalic } from "client/images/text-italic.svg";
import { ReactComponent as IconTextUnderline } from "client/images/text-underline.svg";
import { ColorPickerField } from "client/modules/forms/color-picker-field";
import { SceneEditorActions } from "../../scene-editor-reducer";
import { Hint } from "../ux/hint";

const COLORS = ["#fff", "#000", "#ff0000"];

const TextAlignmentField = ({
  input: { onChange, value }
}: {
  input: WrappedFieldInputProps;
}) => {
  const changeValue = useCallback(
    (newValue: TextAssetAlignment) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <>
      <Hint label="Align Left" shortcut="super+l" placement="top">
        <button
          type="button"
          className={cx("button", "buttonIcon", "buttonConfigPanel", {
            selected: value === TextAssetAlignment.Left
          })}
          data-qa="text-align-left"
          onClick={() => changeValue(TextAssetAlignment.Left)}
        >
          <IconTextAlignLeft />
        </button>
      </Hint>
      <Hint label="Align Center" placement="top">
        <button
          type="button"
          className={cx("button", "buttonIcon", "buttonConfigPanel", {
            selected: value === TextAssetAlignment.Center
          })}
          data-qa="text-align-center"
          onClick={() => changeValue(TextAssetAlignment.Center)}
        >
          <IconTextAlignCenter />
        </button>
      </Hint>
      <Hint label="Align Right" shortcut="super+r" placement="top">
        <button
          type="button"
          className={cx("button", "buttonIcon", "buttonConfigPanel", {
            selected: value === TextAssetAlignment.Right
          })}
          data-qa="text-align-right"
          onClick={() => changeValue(TextAssetAlignment.Right)}
        >
          <IconTextAlignRight />
        </button>
      </Hint>
    </>
  );
};

const fontFamilyFieldOptions = FONTS.map((font) => ({
  label: font,
  value: font
}));
const fontSizeFieldOptions = Object.entries(TextAssetFontSize).map((entry) => {
  const [key, value] = entry;
  return { label: key, value: value };
});

const RenderFontOption = ({ value, label }: any) => (
  <span style={{ fontFamily: value }}>{label}</span>
);

const TextAssetForm = reduxForm({
  form: "text-assets-configuration",
  enableReinitialize: true
})(({ handleSubmit }) => {
  const dispatch = useDispatch();

  const onVisibleChange = useCallback(
    (value: boolean) => {
      dispatch(SceneEditorActions.setSelectionDraggingDisabled(value));
    },
    [dispatch]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="fontColorAndSize">
        <AsyncSelectField
          name="font"
          async={false}
          options={fontFamilyFieldOptions}
          formatOptionLabel={RenderFontOption}
          dataQa="text-font-family"
        />
        <AsyncSelectField
          wrapperClass="fontCombo"
          name="fontSize"
          async={false}
          options={fontSizeFieldOptions}
          dataQa="text-font-size"
        />
        <ColorPickerField
          name="color"
          colors={COLORS}
          onVisibleChange={onVisibleChange}
          data-qa="text-font-color"
        />
      </div>

      <ToggleField name="bold" className="buttonToggleConfigPanel">
        <Hint label="Bold" shortcut="super+b" placement="top">
          <span
            className="button buttonIcon buttonConfigPanel"
            data-qa="text-bold"
          >
            <IconTextBold />
          </span>
        </Hint>
      </ToggleField>
      <ToggleField name="italic" className="buttonToggleConfigPanel">
        <Hint label="Italic" shortcut="super+i" placement="top">
          <span
            className="button buttonIcon buttonConfigPanel"
            data-qa="text-italics"
          >
            <IconTextItalic />
          </span>
        </Hint>
      </ToggleField>
      <ToggleField name="underline" className="buttonToggleConfigPanel">
        <Hint label="Underline" shortcut="super+u" placement="top">
          <span
            className="button buttonIcon buttonConfigPanel"
            data-qa="text-underline"
          >
            <IconTextUnderline />
          </span>
        </Hint>
      </ToggleField>
      <Field component={TextAlignmentField} name="alignment" />
    </form>
  );
});

const getInitialValues = (
  selectedSceneAssetIds: string[],
  scene: ReturnType<typeof getScene>
) => {
  if (selectedSceneAssetIds.length === 1 && scene) {
    const selectedSceneAssetId = selectedSceneAssetIds[0];
    const sceneAsset = scene.sceneAssets.find(
      (sceneAsset) => sceneAsset.uuid === selectedSceneAssetId
    );

    if (!sceneAsset) {
      return {};
    }

    return {
      bold: sceneAsset.asset.metadata.bold,
      italic: sceneAsset.asset.metadata.italic,
      underline: sceneAsset.asset.metadata.underline,
      fontSize: {
        value: sceneAsset.asset.metadata.fontSize,
        label:
          sceneAsset.asset.metadata.fontSize &&
          sceneAsset.asset.metadata.fontSize.toUpperCase()
      },
      alignment: sceneAsset.asset.metadata.alignment,
      color: sceneAsset.asset.metadata.color,
      font: {
        label: sceneAsset.asset.metadata.font,
        value: sceneAsset.asset.metadata.font
      }
    };
  } else {
    return {};
  }
};

export const TextAssetsConfig = () => {
  const { actionsMap } = useSceneEditorActions();
  const selectedSceneAssetUuids = useSelector(getSelectedSceneAssetUuids);
  const selectedTextAsset = useSelector(hasSelectedTextAsset);
  const scene = useSelector(getScene);

  const onChangeForm = useCallback(
    (data, _, { dirty }) => {
      if (dirty) {
        actionsMap.CHANGE_TEXT_ASSET_CONFIG(
          {
            ...data,
            fontSize: data.fontSize.value,
            font: data.font.value
          },
          true,
          selectedSceneAssetUuids
        );
      }
    },
    [actionsMap, selectedSceneAssetUuids]
  );

  return (
    <>
      {selectedTextAsset && (
        <fieldset className="textConfigFieldset">
          <h3>Text</h3>
          <TextAssetForm
            initialValues={getInitialValues(selectedSceneAssetUuids, scene)}
            onChange={onChangeForm}
          />
        </fieldset>
      )}
    </>
  );
};
