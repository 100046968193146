import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { DebouncedSearchInput } from "../debounced-search-input";
import { Filter } from "./filter";
import { ChoiceLogicImplementations } from "./choice-logic-implementations";
import { FiltersContainer } from "./filters-container";
import { FiltersOverview } from "./filters-overview";

export const FulltextAndFilters = ({
  filters = [],
  category,
  onChange,
  additionalFilterData,
  dataQa
}) => {
  const [filterValues, setFilterValues] = useState({});
  const [fulltextValue, setFulltextValue] = useState("");
  const [knownChoices, setKnownChoices] = useState({});

  useEffect(() => {
    // Filter out all filter values
    // from local state which are not in current filter specification
    setFilterValues((currentFilterValues) =>
      Object.keys(currentFilterValues).reduce((memo, filterId) => {
        if (
          filters
            .map((filter) => filter.id.toString())
            .includes(filterId.toString())
        ) {
          memo[filterId] = currentFilterValues[filterId];
        }
        return memo;
      }, {})
    );
  }, [filters, setFilterValues]);

  const onFilterChange = useCallback(
    (value, filterId, choice) => {
      const updatedValues = {
        ...filterValues,
        [filterId]: value
      };
      setKnownChoices({
        ...knownChoices,
        [filterId]: {
          ...knownChoices[filterId],
          [choice.value]: choice.label
        }
      });
      setFilterValues(updatedValues);
      onChange({ fulltext: fulltextValue, filters: updatedValues });
    },
    [filterValues, fulltextValue, knownChoices, onChange]
  );

  const onFulltextChange = useCallback(
    (value) => {
      setFulltextValue(value);
      onChange({ fulltext: value, filters: filterValues });
    },
    [filterValues, onChange]
  );

  const clearAll = useCallback(() => {
    setFilterValues({});
    onChange({ fulltext: fulltextValue, filters: {} });
  }, [fulltextValue, onChange]);

  const clearSingle = useCallback(
    (filterId, value) => {
      // filter is always found because this callback is always called
      // with a particular filter data
      const filter = filters.find(({ id }) => id === filterId);

      const updatedValues = {
        ...filterValues,
        [filterId]: filter.choiceLogic.toggleChoice(
          filterValues[filterId],
          value
        )
      };
      setFilterValues(updatedValues);
      onChange({ fulltext: fulltextValue, filters: updatedValues });
    },
    [filters, filterValues, onChange, fulltextValue]
  );

  return (
    <>
      <div className="filtersAndSearch" data-qa={dataQa}>
        <FiltersContainer>
          {filters.map((filter) => (
            <Filter
              selectedFilters={filterValues}
              globalSearch={fulltextValue}
              additionalFilterData={additionalFilterData}
              category={category}
              key={filter.id}
              value={filterValues[filter.id]}
              onChange={onFilterChange}
              id={filter.id}
              label={filter.label}
              choiceLogic={filter.choiceLogic}
              choices={filter.choices}
            />
          ))}
        </FiltersContainer>
        <div className="search inputWithButton">
          <DebouncedSearchInput
            placeholder="Type to search"
            onChange={onFulltextChange}
          />
        </div>
      </div>
      <FiltersOverview
        activeFilters={filters
          .map((filter) => {
            return filter.choiceLogic
              .getSelectedChoiceValuesAsArray(filterValues[filter.id])
              .map((value) => ({
                filterId: filter.id,
                value,
                label: knownChoices[filter.id][value]
              }));
          })
          .flat()}
        onClearAll={clearAll}
        onClearSingle={clearSingle}
      />
    </>
  );
};

FulltextAndFilters.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  additionalFilterData: PropTypes.object,
  category: PropTypes.string,
  dataQa: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired
      ]).isRequired,
      label: PropTypes.string.isRequired,
      choiceLogic: PropTypes.oneOf(Object.values(ChoiceLogicImplementations))
        .isRequired,
      choices: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
          }).isRequired
        ).isRequired
      ]).isRequired
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired
};
