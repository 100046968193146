import { createSelector } from "reselect";
import { getUserTracking as getState } from "../root/root-selectors";

export const getMixpanelToken = createSelector(
  getState,
  (state) => state.mixpanelToken
);

export const isMixpanelEnabled = createSelector(
  getMixpanelToken,
  (token) => !!token
);
