import React, { useCallback } from "react";
import { reduxForm, SubmissionError } from "redux-form";
import { useDispatch } from "react-redux";

import { UsersForm, UsersFormData } from "./users-form";
import Actions from "../../users-actions";
import validate from "../../users-validate";
import { passwordNormalizer } from "./users-form";
import { InviteUsers } from "./invite-users";
import { createPortal } from "react-dom";
import { ModalTemplate } from "../../../../ux/modal";
import { useNavigateTo } from "../../../../routing/routing-hooks";

import { ReactComponent as IconReturn } from "../../../../../images/return.svg";
import * as api from "../../../../api/client";
import { USERS_ROUTE } from "../../../../routing/route-names";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";

export const UsersAdd = reduxForm<UsersFormData, {}>({
  form: "users-add",
  validate,
  initialValues: {
    roles: []
  }
})(({ handleSubmit }) => {
  const dispatch = useDispatch();

  const addUser = useCallback(
    async (values: UsersFormData) => {
      const {
        data: { unique }
      } = await api.isEmailUnique({ email: values.email });
      if (unique) {
        dispatch(Actions.Creators.addUser(passwordNormalizer(values)));
      } else {
        throw new SubmissionError({ email: "Email is already taken." });
      }
    },
    [dispatch]
  );

  return (
    <div className="subpage">
      <header className="pageHeader">
        <h1 className="pageHeading" data-qa="tab-header">
          Users
        </h1>
      </header>

      <div className="pageContent">
        <p>
          <button
            className="button buttonOutlined returnButton"
            type="button"
            onClick={() => window.history.back()}
          >
            <IconReturn />
            Return
          </button>
        </p>
        <div data-grid>
          <form onSubmit={handleSubmit(addUser)}>
            <div style={{ display: "inline-block" }}>
              <h2>Create New User</h2>
              <UsersForm editableEmail ssoUser={false} />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="button"
                  type="submit"
                  data-qa={FORM_LOCATORS.buttons.getButton("submit")}
                >
                  Create User
                </button>
              </div>
            </div>
          </form>
          <InviteUsers />
        </div>
      </div>
    </div>
  );
});

export const UserInviteDialog = () => {
  const { navigateTo, routeParams } = useNavigateTo();

  const onBack = useCallback(() => {
    navigateTo(USERS_ROUTE, { ...routeParams });
  }, [navigateTo, routeParams]);

  return (
    <ModalTemplate
      title="Invite Users"
      className="modalInviteUser"
      onCloseClick={onBack}
      footer={undefined}
      enableBackdropClick={undefined}
      closeButtonProps={undefined}
      hideCloseButton={undefined}
      headerProps={undefined}
      contentProps={undefined}
    >
      <InviteUsers />
    </ModalTemplate>
  );
};

export const UsersAddInvite = () => {
  return createPortal(
    <UserInviteDialog />,
    document.getElementById("modal") as Element
  );
};
