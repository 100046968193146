import { createSelector } from "reselect";

import { getShares as getState } from "../root/root-selectors";

export const getPresentionCollaborations = createSelector(
  getState,
  (state) => state.presentations.coop
);

export const getPresentationShares = createSelector(
  getState,
  (state) => state.presentations.shared
);

export const getSceneCollaborations = createSelector(
  getState,
  (state) => state.scenes.coop
);

export const getSceneShares = createSelector(
  getState,
  (state) => state.scenes.shared
);
