import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CustomFieldsState {
  customFields: number[];
  customFieldHierarchies: number[];
  customFieldMappings: number[];
}

const initialState: CustomFieldsState = {
  customFields: [],
  customFieldHierarchies: [],
  customFieldMappings: []
};

const { actions, reducer } = createSlice({
  name: "customFields",
  initialState,
  reducers: {
    onFetchedCustomFields: (
      state,
      action: PayloadAction<{
        customFieldIds: number[];
      }>
    ) => ({
      ...state,
      customFields: action.payload.customFieldIds
    }),
    onFetchedCustomFieldMappings: (
      state,
      action: PayloadAction<{
        customFieldMappingIds: number[];
      }>
    ) => ({
      ...state,
      customFieldMappings: action.payload.customFieldMappingIds
    }),
    onFetchedCustomFieldHierarchies: (
      state,
      action: PayloadAction<{
        customFieldHierarchyIds: number[];
      }>
    ) => ({
      ...state,
      customFieldHierarchies: action.payload.customFieldHierarchyIds
    })
  }
});

export const CustomFieldsActions = actions;
export const customFieldsReducer = reducer;
