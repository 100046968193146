import React, { useState, useCallback } from "react";
import cx from "classnames";
import { CustomFieldReduced } from "shared/types/custom-field";
import { CustomFieldType } from "shared/types/custom-field-type";

interface CustomFieldCategoryTree extends CustomFieldReduced {
  children: CustomFieldCategoryTree[];
}

export type ChangeSelectedPath = (path: string) => void;

interface CategoryTreeProps {
  currentPath: string;
  title?: string;
  nodes: CustomFieldCategoryTree[];
  selectedPath: string;
  hideServiceCategories: boolean;
  changeSelectedPath: ChangeSelectedPath;
}

const CategoryTree: React.FC<CategoryTreeProps> = ({
  title,
  currentPath,
  nodes,
  selectedPath,
  hideServiceCategories,
  changeSelectedPath
}) => {
  const categoryNodes = nodes.filter(
    (node) => node.type === CustomFieldType.CATEGORY
  );
  const childNodes = categoryNodes.length ? (
    <ul>
      {categoryNodes
        .filter((node) => !hideServiceCategories || !node.service)
        .map((node) => (
          <CategoryTree
            currentPath={`${currentPath}/${node.id}`}
            key={node.id}
            title={node.label}
            nodes={node.children}
            selectedPath={selectedPath}
            hideServiceCategories={hideServiceCategories}
            changeSelectedPath={changeSelectedPath}
          />
        ))}
    </ul>
  ) : (
    <></>
  );

  const onClickNode = useCallback(
    (ev: React.BaseSyntheticEvent) => {
      ev.stopPropagation();

      if (selectedPath !== currentPath) {
        changeSelectedPath(currentPath);
      } else {
        const splitPath = selectedPath.split("/");
        splitPath.pop();

        changeSelectedPath(splitPath.join("/"));
      }
    },
    [selectedPath, currentPath, changeSelectedPath]
  );

  if (title) {
    const selected = `${selectedPath}/`.startsWith(`${currentPath}/`);

    return (
      <li
        onClick={onClickNode}
        className={cx({ selected })}
        data-qa={`category-${title}`}
      >
        <span>{title}</span>
        {selected && childNodes}
      </li>
    );
  } else {
    return childNodes;
  }
};

interface CustomFieldsCategorySelectorProps {
  hideServiceCategories: boolean;
  onPathSelected: ChangeSelectedPath;
  root: any[];
}

export const CustomFieldsCategorySelector: React.FC<CustomFieldsCategorySelectorProps> = ({
  // eslint-disable-next-line react/prop-types
  hideServiceCategories,
  root,
  onPathSelected
}) => {
  const rootPath = "";
  const [selectedPath, changeSelectedPathState] = useState(rootPath);

  const changeSelectedPath: ChangeSelectedPath = useCallback(
    (path: string) => {
      changeSelectedPathState(path);
      onPathSelected(path);
    },
    [changeSelectedPathState, onPathSelected]
  );

  return (
    <div className="customFieldsCategorySelector">
      <CategoryTree
        currentPath={rootPath}
        selectedPath={selectedPath}
        changeSelectedPath={changeSelectedPath}
        nodes={root}
        hideServiceCategories={hideServiceCategories}
      />
    </div>
  );
};
