import { useApi } from "../../../api/use-api";
import { useCallback } from "react";
import { Logger as logger } from "purplex-logging";
import { notifyError } from "../../../ux/notifications/notifications-saga";
import { useSelector } from "react-redux";
import { getCreateFilterParentData } from "../custom-fields-selectors";
import {
  useConfirmationDialog,
  useConfirmationWithInputDialog
} from "../../../dialogs/dialog-hooks";

import {
  getRouteParams,
  RouteParamSelector
} from "../../../routing/routing-selectors";
import { DialogResult } from "../../../dialogs/event-types";
import { CustomFieldType } from "../../../../../shared/types/custom-field-type";
import { CustomFieldChoiceValue } from "../custom-field-types";
import { CustomFieldEntity } from "../../../../../shared/types/custom-field-entity";
import { useFetchCustomFields } from "./use-custom-fields";
import { goBack } from "../../../routing/routing-hooks";
import { useGetCustomFieldType } from "./use-get-custom-field-type";

export const useCreateFilter = () => {
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();
  const parentData = useSelector(getCreateFilterParentData);

  return useCallback(
    async (payload: {
      required: boolean;
      filterable: boolean;
      label: string;
      type: CustomFieldType;
      choices: { value: string }[];
    }) => {
      try {
        await api.createCustomField({
          ...parentData,
          ...payload
        });
        await fetchCustomFields();
        goBack();
      } catch (ex) {
        logger.warn("Create Custom Field Error", ex);
        notifyError("Creating of filter has failed.");
      }
    },
    [fetchCustomFields, api, parentData]
  );
};

export const useEditFilter = () => {
  const params = useSelector(
    getRouteParams as RouteParamSelector<{ fieldId: number }>
  );
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();

  return useCallback(
    async (payload: {
      required: boolean;
      filterable: boolean;
      label: string;
      type: CustomFieldType;
      choices: CustomFieldChoiceValue[];
    }) => {
      if (!params.fieldId) {
        return;
      }
      const body = {
        ...payload,
        choices: payload.choices.map((choice) => choice.value)
      };

      try {
        await api.editCustomField(params.fieldId, body);
        await fetchCustomFields();
        goBack();
      } catch (ex) {
        logger.warn("Edit Category Error", ex);
        notifyError("Editing of filter has failed.");
      }
    },
    [api, params, fetchCustomFields]
  );
};

export const useEditFilterMapping = () => {
  const { fieldId } = useSelector(
    getRouteParams as RouteParamSelector<{
      fieldId: number;
    }>
  );
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();
  const assetType = useGetCustomFieldType();

  return useCallback(
    async (payload: {
      required: boolean;
      filterable: boolean;
      label: string;
      type: CustomFieldType;
      choices: CustomFieldChoiceValue[];
    }) => {
      if (!fieldId) {
        return;
      }

      const body = {
        ...payload,
        choices: payload.choices
          .filter((choice) => !choice.toBeDeleted)
          .map((choice) => choice.value),
        entity: assetType
      };
      try {
        await api.editCustomFieldMapping(fieldId, body);
        await fetchCustomFields();
        goBack();
      } catch (ex) {
        logger.warn("Edit Category Error", ex);
        notifyError("Editing of assigned filter has failed.");
      }
    },
    [api, assetType, fieldId, fetchCustomFields]
  );
};

export const useChangeFilterOrder = () => {
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();

  return useCallback(
    async (payload: { firstId: number; secondId: number }) => {
      try {
        await api.switchCustomFieldSort(payload);
        await fetchCustomFields();
      } catch (ex) {
        logger.warn("Custom field change sort error", ex);
        notifyError("Changing order of custom field has failed.");
      }
    },
    [api, fetchCustomFields]
  );
};

export const useAssignFilterToCategory = () => {
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();

  return useCallback(
    async (payload: {
      filterId: number;
      categoryId: number;
      entity: CustomFieldEntity;
    }) => {
      try {
        await api.assignCustomField(payload.filterId, payload);
        await fetchCustomFields();
      } catch (ex) {
        logger.warn("Filter assign error", ex);
        notifyError("Assigning of filter to category has failed.");
      }
    },
    [api, fetchCustomFields]
  );
};

export const useUnAssignFilterFromCategory = () => {
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();
  const confirmDialog = useConfirmationDialog();
  const confirmDialogWithInput = useConfirmationWithInputDialog();

  return useCallback(
    async (payload: { id: number; label: string }) => {
      try {
        const { id, label } = payload;
        const { count } = await api.unAssignCustomFieldCount(id);

        let type;

        if (count === 0) {
          const result = await confirmDialog({
            title: "Unassign Filter",
            text: `WARNING! Are you sure you want to unassign filter ${label}?`,
            subtext: ``,
            confirmLabel: "Unassign",
            cancelLabel: "Cancel"
          });
          type = result.type;
        } else {
          const result = await confirmDialogWithInput({
            title: "Unassign Filter",
            text: `WARNING! Are you sure you want to unassign filter ${label}? Doing so will delete selected values across all entities.`,
            subtext: `Values to be deleted: ${count}`,
            confirmLabel: "Unassign",
            cancelLabel: "Cancel",
            fieldLabel: 'Type "LOSE DATA" to proceed',
            confirmText: "LOSE DATA"
          });
          type = result.type;
        }

        if (type === DialogResult.CONFIRM) {
          await api.unAssignCustomField(id);
          await fetchCustomFields();
        }
      } catch (ex) {
        logger.warn("Filter unassign error", ex);
        notifyError("Unassigning of filter to category has failed.");
      }
    },
    [api, fetchCustomFields, confirmDialog, confirmDialogWithInput]
  );
};

export const useDeleteFilter = () => {
  const api = useApi();
  const fetchCustomFields = useFetchCustomFields();
  const confirmDialog = useConfirmationDialog();
  const confirmDialogWithInput = useConfirmationWithInputDialog();

  return useCallback(
    async (payload: { id: number; label: string }) => {
      try {
        const { id, label } = payload;
        const { count } = await api.deleteCustomFieldCount(id);

        let type;

        if (count === 0) {
          const result = await confirmDialog({
            title: "Delete Filter",
            text: `WARNING! Are you sure you want to delete Filter ${label}?`,
            subtext: ``,
            confirmLabel: "Delete",
            cancelLabel: "Cancel"
          });
          type = result.type;
        } else {
          const result = await confirmDialogWithInput({
            title: "Delete Filter",
            text: `WARNING! Are you sure you want to delete Filter ${label}? Doing so will delete selected values across all entities.`,
            subtext: `Values to be deleted: ${count}`,
            confirmLabel: "Delete",
            cancelLabel: "Cancel",
            fieldLabel: 'Type "LOSE DATA" to proceed',
            confirmText: "LOSE DATA"
          });
          type = result.type;
        }

        if (type === DialogResult.CONFIRM) {
          await api.deleteCustomField(id);
          await fetchCustomFields();
        }
      } catch (ex) {
        logger.warn("Delete custom field error", ex);
        notifyError("Deleting of filter has failed.");
      }
    },
    [api, fetchCustomFields, confirmDialog, confirmDialogWithInput]
  );
};
