import React from "react";
import { useSelector } from "react-redux";

import { getScene, getWallAssetUrl } from "../../scene-editor-selectors";
import { AssetType } from "../../../../../shared/types/asset-type";
import { useRenderCount } from "../../utils";
import { useDeselectAllAssets } from "../../hooks/use-select-asset";

const WallAssetTypes = [AssetType.ASSET_WALL_VIDEO, AssetType.ASSET_WALL_IMAGE];

export const WallAsset = (props: {
  asset: { type: AssetType; uuid: string; fileVersion: number };
  wallStart: number;
}) => {
  const { asset, wallStart } = props;

  const scene = useSelector(getScene);
  const url = useSelector(getWallAssetUrl);

  const deselectAllAssets = useDeselectAllAssets();

  const renderCount = useRenderCount();

  if (
    !WallAssetTypes.includes(asset.type) ||
    !url ||
    !scene ||
    !scene.transformedWall
  ) {
    return <></>;
  }

  const transformedAsset = scene.transformedWall;

  return (
    <>
      {renderCount}
      <img
        alt=""
        src={url}
        onMouseDown={deselectAllAssets}
        style={{
          position: "absolute",
          width: transformedAsset.scale.width,
          height: transformedAsset.scale.height,
          left: -wallStart + transformedAsset.pad.left,
          top: transformedAsset.pad.top
        }}
        // So we don't drag the wall while dragging assets on it
        draggable={false}
      />
    </>
  );
};
