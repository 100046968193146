import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "rc-tooltip";

import {
  getBackgroundState,
  getPresentation,
  getScene
} from "../../scene-editor-selectors";
import { getOrderedInstances } from "../../../entity-repository/entity-repository-selectors";
import { WallDimensionsStatus } from "../../../media/components/wall-compatibility/wall-dimensions-status";
import { isBackgroundValidForScene } from "../../../media/components/wall-compatibility/wall-dimensions-validity";

export const EditedBackgroundStatusForRoom = (props: {
  wallAsset: {
    dimensions: { width: number; height: number };
    alternativeAssets: { dimensions: { width: number; height: number } }[];
  };
}) => {
  const { wallAsset } = props;

  const backgroundState = useSelector(getBackgroundState);
  const scene = useSelector(getScene);
  const presentation = useSelector(getPresentation);
  const instances = useSelector(getOrderedInstances);

  if (!scene || !instances) {
    return <></>;
  }

  const sceneInstance = instances.find(
    (instance) => scene.instance && instance.uuid === scene.instance.uuid
  );

  if (!sceneInstance) {
    return <></>;
  }

  const presentationInstances =
    (presentation &&
      instances.filter((instance) =>
        presentation.instances.includes(instance.uuid)
      )) ||
    [];

  return (
    <Tooltip
      overlayClassName="instances-wall-statuses"
      trigger={["click"]}
      overlay={
        presentationInstances.length !== 0 && (
          <div className="list">
            <h5>IX Compatible</h5>
            {presentationInstances.map((instance) => (
              <div className="item" key={instance.uuid}>
                <WallDimensionsStatus
                  isValid={isBackgroundValidForScene(
                    backgroundState
                      ? backgroundState.width
                      : wallAsset.dimensions.width,
                    instance.roomDimensions.width
                  )}
                  okMessage="Background is wide enough for the room."
                  warningMessage="Width of the background is smaller than width of the room."
                />
                {instance.name}
              </div>
            ))}
          </div>
        )
      }
    >
      <div className="wall-dimension-status-wrapper">
        <WallDimensionsStatus
          isValid={isBackgroundValidForScene(
            backgroundState
              ? backgroundState.width
              : wallAsset.dimensions.width,
            sceneInstance.roomDimensions.width
          )}
          okMessage="Background is wide enough for the room."
          warningMessage="Width of the background is smaller than width of the room."
        />
      </div>
    </Tooltip>
  );
};
