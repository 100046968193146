import { urlValidator } from "../scene-editor/asset-library/components/url-asset-modal";

const REQUIRED_FIELDS = ["title"];

export const validate = (isUrlAsset) => (values) => {
  const errors = {};

  if (isUrlAsset) {
    errors.url = urlValidator(values.url);
  }

  const invalidFields = [...REQUIRED_FIELDS].filter(
    (field) => !values[field] || values[field].trim() === ""
  );

  return invalidFields.reduce((memo, field) => {
    memo[field] = `${field} is required`;
    return memo;
  }, errors);
};
