import { editorSettingsReducer } from "./../settings/editor-settings/editor-settings-reducer";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { router5Reducer as routing } from "redux-router5";

import config from "../config/config-reducer";
import { entityRepositoryReducer } from "../entity-repository/entity-repository-reducer";
import { assetLibraryReducer } from "../scene-editor/asset-library/asset-library-reducer";
import predefinedSceneNames from "../settings/predefined-scene-names/predefined-scene-names-reducer";
import auth from "../auth/auth-reducer";
import { dialogsReducer } from "../dialogs/dialogs-reducer";
import contextMenu from "../ux/context-menu/context-menu-reducer";
import shares from "../shares/shares-reducer";
import presentation from "../presentations/presentation-reducer";
import dashboard from "../dashboard/dashboard-reducer";
import media from "../media/media-reducer";
import scene from "../scenes/scene-reducer";
import inProgress from "../in-progress/in-progress-reducer";
import { customFieldsReducer } from "../settings/custom-fields/custom-fields-reducer";
import { sceneEditorReducer } from "../scene-editor/scene-editor-reducer";
import { userTrackingReducer } from "../user-tracking/user-tracking-slice";

export const rootReducer = combineReducers({
  form: formReducer,
  routing,
  config,
  entityRepository: entityRepositoryReducer,
  sceneEditor: sceneEditorReducer,
  assetLibrary: assetLibraryReducer,
  predefinedSceneNames,
  auth,
  dialogs: dialogsReducer,
  contextMenu,
  shares,
  presentation,
  dashboard,
  media,
  scene,
  inProgress,
  userTracking: userTrackingReducer,
  customFields: customFieldsReducer,
  editorSettings: editorSettingsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
