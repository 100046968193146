import React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";

import { Modal } from "../../../ux/modal";
import { TextField } from "../../../forms/text-field";
import validator from "validator";
import { FORM_LOCATORS } from "shared/tests/locators/form.locators";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

const notEmptyString = (message: string) => (value: string) => {
  if (!value || value.trim() === "") {
    return message;
  }
};

const validUrl = (message: string) => (value: string) => {
  const validationOptions = {
    require_tld: false
  };
  if (!value || !validator.isURL(value, validationOptions)) {
    return message;
  }
};

export const titleValidator = notEmptyString("Title can't be empty");
export const urlValidator = validUrl("URL of the website needs to be valid.");

export interface UrlAssetModalFormData {
  title: string;
  url: string;
}

interface UrlAssetModalProps {
  onModalConfirmed: (values: { title: string; url: string }) => void;
  onModalCanceled: () => void;
}

const UrlAssetModalComponent = (
  props: InjectedFormProps<UrlAssetModalFormData, UrlAssetModalProps> &
    UrlAssetModalProps
) => {
  const { handleSubmit, onModalConfirmed, onModalCanceled } = props;
  return (
    <Modal
      title="Add a Website to library"
      onCloseClick={onModalCanceled}
      data-qa={MODAL_LOCATORS.getModal("add-url")}
    >
      <form onSubmit={handleSubmit(onModalConfirmed)}>
        <TextField
          name="title"
          label="Name"
          validate={titleValidator}
          dataQa={FORM_LOCATORS.inputs.getInput("title")}
        />
        <TextField
          name="url"
          label="URL"
          validate={urlValidator}
          dataQa={FORM_LOCATORS.inputs.getInput("url")}
        />
        <div className="modalButtons">
          <button
            className="button"
            type="submit"
            data-qa={MODAL_LOCATORS.buttons.submit}
          >
            Add Website
          </button>
        </div>
      </form>
    </Modal>
  );
};

export const UrlAssetModal = reduxForm<
  UrlAssetModalFormData,
  UrlAssetModalProps
>({
  form: "url-asset-modal"
})(UrlAssetModalComponent);
