import { createReducer } from "reduxsauce";

import Actions from "./auth-actions";

const INITIAL_STATE = {
  user: null,
  passwordResetSendTo: null
};

const userLoggedIn = (state, { id }) => ({
  ...state,
  user: id
});

const sendPasswordResetToken = (state, { email }) => ({
  ...state,
  passwordResetSendTo: email
});

export default createReducer(INITIAL_STATE, {
  [Actions.Types.LOGGED_USER]: userLoggedIn,
  [Actions.Types.SEND_PASSWORD_RESET_TOKEN]: sendPasswordResetToken
});
