import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";

import cx from "classnames";
import { startsWithSegment, endsWithSegment } from "router5-helpers";
import { constants } from "router5";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import "rc-tooltip/assets/bootstrap_white.css";

import "../../../styles/themes/default.less";

import { AuthLogin } from "../../auth/components/auth-login";
import { AuthResetPassword } from "../../auth/components/auth-reset-password";
import { AuthSelectNewPassword } from "../../auth/components/auth-select-new-password";
import { PreviewMedia } from "../../media/components/preview-media";
import {
  ASSETS_ROUTE,
  AUTH_LOGIN_ROUTE,
  AUTH_NEW_PASSWORD_ROUTE,
  AUTH_RESET_PASSWORD_ROUTE,
  AUTH_SIGN_UP_ROUTE,
  AUTH_USER_PROFILE_ROUTE,
  DASHBOARD_ROUTE,
  PRESENTATIONS_ROUTE,
  SCENES_ROUTE,
  SETTINGS_ROUTE,
  WALLS_ROUTE
} from "../../routing/route-names";
import { ComponentPages } from "../../ux/components-page";

// New
import Sidebar from "../../ux/sidebar";
import * as RoutingSelectors from "../../routing/routing-selectors";
import { GlobalScenePreview } from "../../scene-editor/components/preview/global-scene-preview";
import {
  getSiteTitle,
  hasInstanceConfig as hasInstanceConfigSelector
} from "../../config/config-selectors";
import { PresentationIndex } from "../../presentations/components/presentation-index";
import { AssetList } from "../../media/components/lists/asset-list";
import { WallsList } from "../../media/components/walls-list";
import { SceneIndex } from "../../scenes/components/scene-index";
import { Dashboard } from "../../dashboard/components/dashboard-component";
import { UserProfile } from "../../auth/components/user-profile";
import { UploadMedia } from "../../media/components/upload-media";
import {
  hasProfile,
  isRegistrationCompleted,
  getUser
} from "../../auth/auth-selectors";
import { isDialogShown } from "../../media/media-selectors";
import { UploadingBar } from "../../media/components/uploading-bar";
import { EditSharedAsset } from "../../media/components/shared-library/edit-shared-asset";
import Header from "./header";
import { EditMedia } from "../../media/components/edit-media";
import { CustomFieldDragLayer } from "../../settings/custom-fields/components/custom-field-drag-layer";
import { ModalDialogRoot } from "../../dialogs/components/modal-dialog-root";
import { AuthSignUp } from "../../auth/components/auth-sign-up";
import { SceneEditorRoot } from "../../scene-editor/components/scene-editor-root";
import { SettingsIndex } from "../../settings/settings-index";

const Root = ({
  routeName,
  hasProfile,
  hasInstanceConfig,
  siteTitle,
  userProfile,
  mediaDialogShown,
  registrationCompleted
}) => {
  const endsWith = endsWithSegment(routeName);
  const startsWith = startsWithSegment(routeName);

  if (hasProfile && !registrationCompleted) {
    return (
      <AuthSignUp
        initialValues={{
          email: userProfile.email,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName
        }}
        ssoSignup
      />
    );
  }

  if (startsWith(AUTH_LOGIN_ROUTE)) {
    return <AuthLogin />;
  } else if (startsWith(AUTH_SIGN_UP_ROUTE)) {
    return <AuthSignUp />;
  } else if (startsWith(AUTH_RESET_PASSWORD_ROUTE)) {
    return <AuthResetPassword />;
  } else if (startsWith(AUTH_NEW_PASSWORD_ROUTE)) {
    return <AuthSelectNewPassword />;
  } else if (!hasProfile || !hasInstanceConfig) {
    // makes sure nothing is rendered until initial load of data is not done
    return <div />;
  }

  if (
    startsWith("scenes.list.edit.editor") ||
    startsWith("presentations.edit.editor")
  ) {
    return <SceneEditorRoot />;
  } else if (
    startsWith("scenes.list.edit.preview") ||
    startsWith("presentations.edit.preview")
  ) {
    return <GlobalScenePreview />;
  } else if (endsWith(constants.UNKNOWN_ROUTE)) {
    return (
      <div>
        The page does not exist. <a href="/">Goto homepage</a>
      </div>
    );
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <CustomFieldDragLayer />
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <Sidebar />
        <div className="application">
          <Header />
          <div
            className={cx(
              "page",
              `page-${routeName.split(".")[0]}`,
              `page-${routeName.replace(/\./g, "-")}`
            )}
          >
            {(endsWith(DASHBOARD_ROUTE) || endsWith("index")) && <Dashboard />}
            {endsWith(AUTH_USER_PROFILE_ROUTE) && <UserProfile />}
            {startsWith(ASSETS_ROUTE) && <AssetList />}
            {startsWith("components") && <ComponentPages />}
            {startsWith(SCENES_ROUTE) && <SceneIndex />}
            {startsWith(PRESENTATIONS_ROUTE) && <PresentationIndex />}
            {startsWith(WALLS_ROUTE) && <WallsList />}
            {startsWith(SETTINGS_ROUTE) && <SettingsIndex />}
            {mediaDialogShown && <UploadMedia />}
            {endsWith("edit-media") && <EditMedia />}
            {endsWith("edit-shared-asset") && <EditSharedAsset />}
          </div>
        </div>
        <PreviewMedia />
        <ModalDialogRoot />
        <UploadingBar />
      </DndProvider>
    );
  }
};

const withDnd = (Component) => (args) => (
  <DndProvider backend={HTML5Backend}>
    <Component {...args} />
  </DndProvider>
);

const mapStateToProps = (state) => ({
  siteTitle: getSiteTitle(state),
  routeName: RoutingSelectors.getRouteName(state),
  hasProfile: hasProfile(state),
  userProfile: getUser(state),
  registrationCompleted: isRegistrationCompleted(state),
  hasInstanceConfig: hasInstanceConfigSelector(state),
  mediaDialogShown: isDialogShown(state)
});

export default connect(mapStateToProps)(withDnd(Root));
