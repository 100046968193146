import React, { useState, useCallback } from "react";
import cx from "classnames";
import { createPortal } from "react-dom";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { ListInfiniteScroll, ROW_STATUS } from "../../ux/list-infinite-scroll";
import { ModalTemplate } from "../../ux/modal";
import { TabPanel } from "../../ux/tab-panel";
import {
  getScene,
  getPresentation,
  getAssetActionSceneAssetId,
  getGotoSceneAction,
  getGotoSceneActionsScene,
  getShowAssetActions,
  isAssetActionsApiInProgress
} from "../scene-editor-selectors";

import { getActiveTab } from "../../routing/routing-selectors";

import { ReactComponent as IconAdd } from "client/images/add.svg";
import { ReactComponent as IconTrash } from "client/images/trash.svg";
import { ReactComponent as IconCross } from "client/images/cross.svg";
import { ReactComponent as IconSearch } from "client/images/search.svg";
import { SceneEditorActions } from "../scene-editor-reducer";

const SCENE_TAB = "scenes";
const ASSET_TAB = "assets";

const TABS = [
  {
    label: "Presentation Scenes",
    route: SCENE_TAB
  },
  {
    label: "Scene Media",
    route: ASSET_TAB
  }
];

const EmptyList = () => <div>Empty</div>;
const LoadingRow = () => <div>Loading...</div>;

const mapStateToProps = (state) => ({
  scene: getScene(state),
  presentation: getPresentation(state),
  currentSceneAssetId: getAssetActionSceneAssetId(state),
  tab: getActiveTab(state),
  gotoSceneAssetAction: getGotoSceneAction(state),
  gotoSceneActionScene: getGotoSceneActionsScene(state),
  showAssetActions: getShowAssetActions(state),
  inProgress: isAssetActionsApiInProgress(state)
});

const mapDispatchToProps = {
  createShowAssetAction: SceneEditorActions.createShowAssetAction,
  deleteSceneAssetAction: SceneEditorActions.deleteSceneAssetAction,
  createGotoSceneAction: SceneEditorActions.createGotoSceneAction,
  onSave: SceneEditorActions.saveAssetActionModal,
  onClose: SceneEditorActions.closeAssetActionModal
};

const SceneAssetRow = ({ asset: { title }, onSelect }) => (
  <li className="item">
    <span className="itemCell itemTitle">{title}</span>
    <button className="itemCellRight" onClick={onSelect}>
      <IconAdd /> Select
    </button>
  </li>
);
SceneAssetRow.propTypes = {
  onSelect: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired
};

const SceneRow = ({ scene: { title }, onSelect }) => (
  <li className="item">
    <span className="itemCell itemTitle">{title}</span>
    <button className="itemCellRight" onClick={onSelect}>
      <IconAdd /> Select
    </button>
  </li>
);
SceneRow.propTypes = {
  onSelect: PropTypes.func.isRequired,
  scene: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired
};

const filterData = (
  data,
  startOffset,
  endOffset,
  query,
  filterMapper = (value) => value,
  sortingMapper = (value) => value
) => {
  const filteredData = data.filter(
    (record) =>
      filterMapper(record).toLowerCase().indexOf(query.toLowerCase()) > -1
  );

  const paginatedData = filteredData
    .sort((a, b) => sortingMapper(a).localeCompare(sortingMapper(b)))
    .filter((_, index) => index >= startOffset && index <= endOffset);

  return {
    rows: paginatedData,
    count: filteredData.length
  };
};

const AssetActionsModalImpl = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    scene,
    presentation,
    currentSceneAssetId,
    createShowAssetAction,
    deleteSceneAssetAction,
    createGotoSceneAction,
    gotoSceneActionScene,
    showAssetActions,
    inProgress,
    gotoSceneAssetAction,
    onSave,
    onClose
  }) => {
    const [query, setQuery] = useState("");

    const selectedSceneMedias = presentation.assetActions
      .filter((assetAction) => assetAction.sceneAssetId === currentSceneAssetId)
      .map((assetAction) => assetAction.targetSceneAssetId)
      .filter(Boolean);

    const selectedPresentationScenes = presentation.assetActions
      .filter((assetAction) => assetAction.sceneAssetId === currentSceneAssetId)
      .map((assetAction) => assetAction.targetSceneId)
      .filter(Boolean);

    const dispatch = useDispatch();

    const changeQuery = useCallback(
      (query) => {
        setQuery(query);
        dispatch(SceneEditorActions.analyticsChangeQueryForAssetActions(query));
      },
      [setQuery, dispatch]
    );

    return (
      <ModalTemplate
        title="On-click action"
        className="modalOnClickAction"
        onCloseClick={onClose}
        contentProps={{ className: "listBox" }}
        closeButtonProps={{ className: inProgress && "displayNone" }}
        footer={
          <button className={cx({ button: true, inProgress })} onClick={onSave}>
            Save
          </button>
        }
      >
        {/* We want to replace the route in the tabpanel here, because it's within modal and modal should never modify history stack so that browser's back button is not mesed up */}
        <TabPanel tabs={TABS} replaceRoute={true}>
          {(tab) => {
            return (
              <>
                <div className="inputWithButton searchBox">
                  <input
                    className="input"
                    placeholder={
                      tab === SCENE_TAB ? "Search scene" : "Search media"
                    }
                    type="text"
                    value={query}
                    onChange={(ev) => changeQuery(ev.target.value)}
                  />
                  <button className="button" onClick={() => setQuery("")}>
                    {query === "" ? <IconSearch /> : <IconCross />}
                  </button>
                </div>
                <div className="listBox">
                  {tab === SCENE_TAB && (
                    <>
                      <h4 className="title">Clicking will change scene to:</h4>
                      <ul className="listBoxItems">
                        {gotoSceneActionScene ? (
                          <li className="item">
                            <span className="itemCell itemTitle">
                              {gotoSceneActionScene.title}
                            </span>
                            <button
                              className="itemCellRight buttonRemove"
                              onClick={() => {
                                deleteSceneAssetAction({
                                  assetAction: gotoSceneAssetAction
                                });
                              }}
                            >
                              <IconTrash />
                            </button>
                          </li>
                        ) : (
                          <li className="listPlaceholder">Select a scene</li>
                        )}
                      </ul>
                    </>
                  )}
                  {tab === ASSET_TAB && (
                    <>
                      <h4 className="title">
                        Clicking will activate/deactivate following media:
                      </h4>
                      <ul className="listBoxItems">
                        {showAssetActions.length === 0 && (
                          <li className="listPlaceholder">Select a media</li>
                        )}
                        {showAssetActions.map(({ sceneAsset, assetAction }) => (
                          <li className="item" key={sceneAsset.id}>
                            <span className="itemCell itemTitle">
                              {sceneAsset.asset.title}
                            </span>
                            <button
                              className="itemCellRight buttonRemove"
                              onClick={() => {
                                deleteSceneAssetAction({
                                  assetAction: assetAction
                                });
                              }}
                            >
                              <IconTrash />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <hr className="listSeparator" />
                  <div className="listBoxItems listBoxItemsScroll">
                    <ListInfiniteScroll
                      rowHeight={57}
                      key={`${tab}${query}${selectedSceneMedias.join(
                        ""
                      )}${selectedPresentationScenes.join("")}`}
                      emptyPlaceholder={<EmptyList />}
                      fetchData={(startOffset, endOffset) => {
                        if (tab === ASSET_TAB) {
                          const extractSceneAssetTitle = (sceneAsset) =>
                            sceneAsset.asset.title;

                          const excludeCurrentAsset = (sceneAsset) =>
                            sceneAsset.id !== currentSceneAssetId;

                          const excludeSelectedSceneMedia = (sceneAsset) =>
                            !selectedSceneMedias.includes(sceneAsset.id);

                          return filterData(
                            scene.sceneAssets
                              .filter(excludeCurrentAsset)
                              .filter(excludeSelectedSceneMedia),
                            startOffset,
                            endOffset,
                            query,
                            extractSceneAssetTitle,
                            extractSceneAssetTitle
                          );
                        } else if (tab === SCENE_TAB) {
                          const extractPresentationSceneTitle = (
                            presentationScene
                          ) => presentationScene.scene.title;

                          const excludeCurrentScene = (presentationScene) =>
                            presentationScene.sceneId !== scene.id;

                          const excludeSelectedScenes = (scene) =>
                            !selectedPresentationScenes.includes(scene.sceneId);

                          return filterData(
                            presentation.presentationScenes
                              .filter(excludeCurrentScene)
                              .filter(excludeSelectedScenes),
                            startOffset,
                            endOffset,
                            query,
                            extractPresentationSceneTitle,
                            extractPresentationSceneTitle
                          );
                        } else {
                          throw new Error(`Invalid state ${tab}`);
                        }
                      }}
                    >
                      {(status, record) => {
                        switch (status) {
                          case ROW_STATUS.LOADED:
                            if (tab === ASSET_TAB) {
                              return (
                                <SceneAssetRow
                                  {...record}
                                  onSelect={() =>
                                    createShowAssetAction({
                                      sceneAssetId: record.id
                                    })
                                  }
                                />
                              );
                            } else if (tab === SCENE_TAB) {
                              return (
                                <SceneRow
                                  {...record}
                                  onSelect={() =>
                                    createGotoSceneAction({
                                      sceneId: record.sceneId
                                    })
                                  }
                                />
                              );
                            } else {
                              throw new Error(`Invalid state ${tab}`);
                            }
                          case ROW_STATUS.LOADING:
                            return <LoadingRow {...record} />;
                          default:
                            throw new Error(`Unknown row state ${status}`);
                        }
                      }}
                    </ListInfiniteScroll>
                  </div>
                </div>
              </>
            );
          }}
        </TabPanel>
      </ModalTemplate>
    );
  }
);

export const AssetActionsModal = () =>
  createPortal(<AssetActionsModalImpl />, document.getElementById("modal"));
