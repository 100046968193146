import { Logger as logger } from "purplex-logging";

import * as DefaultTransformations from "./default/default-scene-transformations";
import * as SapTransformations from "./sap/sap-scene-transformations";
import { ImInstance } from "../types/im-instance";
import { BackgroundSettingsReduced } from "../types/background-settings";
import { Dimensions } from "../types/dimensions";
import { BusinessError } from "../../server/api/v3/crud/crud-exceptions";

export type WallTransformer = () => {};

export type SceneAssetTransformer = () => {};

export type SceneMessagesHandler = (
  scene: { wall: { dimensions: Dimensions } },
  targetInstanceConfig: ImInstance
) => string[];

export const transformScene = (
  scene: {
    id: number;
    assets: any[];
    wall: any;
    transitionAsset: any;
    backgroundSettings: BackgroundSettingsReduced[];
  },
  originalInstanceConfig: ImInstance,
  targetInstanceConfig: ImInstance,
  usingSapTransformations: boolean
) => {
  const transformWall = usingSapTransformations
    ? SapTransformations.transformWall
    : DefaultTransformations.transformWall;

  const transformSceneAssets = DefaultTransformations.transformSceneAssets;

  const getSceneMessages: SceneMessagesHandler = usingSapTransformations
    ? SapTransformations.getSceneMessages
    : DefaultTransformations.getSceneMessages;

  let assets = scene.assets;
  let wall: any = scene.wall;
  let transitionAsset: any = scene.transitionAsset;

  try {
    assets = transformSceneAssets(
      scene.assets,
      originalInstanceConfig,
      targetInstanceConfig
    );
  } catch (er) {
    logger.warn(
      `Error while transforming scene assets, scene ${scene.id} for instance ${targetInstanceConfig.uuid}.`,
      er
    );
  }

  try {
    const backgroundSettings = scene.backgroundSettings.find(Boolean);
    wall =
      scene.wall &&
      transformWall(scene.wall, targetInstanceConfig, backgroundSettings);
  } catch (er) {
    logger.warn(
      `Error while transforming scene wall ${
        scene.wall && scene.wall.uuid
      }, scene ${scene.id} for instance ${targetInstanceConfig.uuid}.`,
      er
    );
  }

  try {
    transitionAsset =
      scene.transitionAsset &&
      transformWall(scene.transitionAsset, targetInstanceConfig, undefined);
  } catch (er) {
    logger.warn(
      `Error while transforming transition asset ${
        scene.wall && scene.wall.uuid
      }, scene ${scene.id} for instance ${targetInstanceConfig.uuid}.`,
      er
    );
  }

  const updatedScene = {
    ...scene,
    assets,
    wall,
    transitionAsset
  };

  return {
    ...updatedScene,
    messages: getSceneMessages(updatedScene, targetInstanceConfig)
  };
};

export const transformPresentation = (
  presentation: {
    id: number;
    scenes: {
      id: number;
      assets: any[];
      wall: any;
      transitionAsset: any;
      instanceUuid: string;
      backgroundSettings: BackgroundSettingsReduced[];
    }[];
  },
  targetInstanceUuid: string,
  instances: ImInstance[],
  usingSapTransformations: boolean
) => {
  logger.debug(
    `Transforming presentation for instance ${targetInstanceUuid}, using transformation type: ${
      usingSapTransformations ? "SAP" : "DEFAULT"
    }`
  );
  const targetInstanceConfig = instances.find(
    (instance) => instance.uuid === targetInstanceUuid
  );

  if (!targetInstanceConfig) {
    logger.error(
      `Error transforming presentation ${presentation.id} for instance ${targetInstanceUuid}, missing target instance.`
    );
    throw new BusinessError("Error transforming presentation");
  }

  const newScenes = [];
  for (let i = 0; i < presentation.scenes.length; i++) {
    const scene = presentation.scenes[i];

    const originalInstanceConfig = instances.find(
      (instance) => instance.uuid === scene.instanceUuid
    );

    if (!originalInstanceConfig) {
      logger.error(
        `Error transforming scene ${scene.id} for instance ${targetInstanceUuid}, missing target instance.`
      );
      throw new BusinessError("Error transforming scene");
    }

    const transformedScene = transformScene(
      scene,
      originalInstanceConfig,
      targetInstanceConfig,
      usingSapTransformations
    );
    newScenes.push(transformedScene);
  }

  return {
    ...presentation,
    scenes: newScenes
  };
};
