import { ReactComponent as IconCircleOk } from "../../../../images/circle-ok.svg";
import React from "react";

export const OkStatus = ({ message }: { message: string }) => (
  <span
    className="wall-dimension-status ok"
    title={message}
    data-qa="wall-dimension-status"
  >
    <IconCircleOk className="icon icon-circle-ok icon20" />
  </span>
);
