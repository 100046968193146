import { Logger as logger } from "purplex-logging";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { normalize, schema } from "normalizr";

import { LongTask } from "./crud-types";
import { ApiClient, useApi } from "../api/use-api";
import {
  EntityRepositoryActions,
  EntityRepositoryHasChangedAction
} from "../entity-repository/entity-repository-reducer";
import {
  getRouteParams,
  RouteParamSelector
} from "../routing/routing-selectors";

export interface ApiEditEntityParameters<Entity> {
  id: string | number;
  data: Entity;
}

interface EditRecordCommand<Entity> extends LongTask {
  editRecord: (params: Entity) => Promise<boolean>;
}

export type ApiCallExtractorEdit<Result, Entity> = (
  api: ApiClient
) => (params: ApiEditEntityParameters<Entity>) => Promise<Result>;

export function useEditRecord<Result, Entity, ENormalized>(
  entitySchema: schema.Entity<ENormalized>,
  apiExtractor: ApiCallExtractorEdit<Result, Entity>
): EditRecordCommand<Entity> {
  const routeParams = useSelector(
    getRouteParams as RouteParamSelector<{ id: number }>
  );
  const id = routeParams && routeParams.id;
  const [inProgress, setInProgress] = useState<boolean>(false);

  const dispatch = useDispatch();
  const api = useApi();

  const editRecord = useCallback(
    async (params: Entity) => {
      if (!id) {
        logger.error("Missing id parameter, aborting...");
        return false;
      }
      try {
        logger.silly("Requesting save data", params);

        setInProgress(true);
        const apiCall = apiExtractor(api);
        const data = await apiCall({ id, data: params });
        const { entities: repository } = normalize<
          ENormalized,
          EntityRepositoryHasChangedAction,
          number
        >(data, entitySchema);

        logger.silly(
          "Entity has been succesfully changed - storing entity to repo",
          data
        );
        dispatch(EntityRepositoryActions.repositoryHasChanged({ repository }));

        return true;
      } catch (ex) {
        logger.error("There was an error during editing record - ", ex);
        return false;
      } finally {
        setInProgress(false);
      }
    },
    [api, dispatch, entitySchema, apiExtractor, id]
  );

  return { editRecord, inProgress };
}
