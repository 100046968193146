import { RootState } from "./root-reducer";

export const getRouting = (state: RootState) => state.routing;
export const getConfig = (state: RootState) => state.config;
export const getEntityRepository = (state: RootState) => state.entityRepository;
export const getSceneEditor = (state: RootState) => state.sceneEditor;
export const getForms = (state: RootState) => state.form;
export const getAssetLibrary = (state: RootState) => state.assetLibrary;
export const getPredefinedSceneNames = (state: RootState) =>
  state.predefinedSceneNames;
export const getAuth = (state: RootState) => state.auth;
export const getDialogs = (state: RootState) => state.dialogs;
export const getContextMenu = (state: RootState) => state.contextMenu;
export const getShares = (state: RootState) => state.shares;
export const getPresentation = (state: RootState) => state.presentation;
export const getDashboard = (state: RootState) => state.dashboard;
export const getMedia = (state: RootState) => state.media;
export const getScene = (state: RootState) => state.scene;
export const getInProgress = (state: RootState) => state.inProgress;
export const getCustomFields = (state: RootState) => state.customFields;
export const getUserTracking = (state: RootState) => state.userTracking;
export const getEditorSettings = (state: RootState) => state.editorSettings;
