import { useEffect, useRef } from "react";

export const useEffectWithMountGuard = <T>(
  effect: (mountedGuard: () => boolean) => Promise<T>
) => {
  const mounted = useRef(true);
  const guard = () => mounted.current;

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    effect(guard);
  }, [effect]);
};
