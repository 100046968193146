import React from "react";
import { reduxForm, FieldArray } from "redux-form";
import cx from "classnames";
import { createPortal } from "react-dom";
import { connect } from "react-redux";

import { TextField } from "../../forms/text-field";
import { TextareaField } from "../../forms/textarea-field";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { AssetFileField } from "./asset-file-field";
import { getMediaEditInitialValues } from "../media-selectors";
import { DateTimeField } from "../../ux/date-time-field";
import { ModalTemplate } from "../../ux/modal";
import { getHumanReadableAssetTypeName } from "../asset-types";
import { urlValidator } from "../../scene-editor/asset-library/components/url-asset-modal";
import { getDialogType } from "../media-selectors";
import { MEDIA_TITLE_MAPPING } from "./media-dialog/media-title-mapping";

import Actions from "../media-actions";
import { useEscapeHook } from "client/modules/dialogs/dialog-hooks";

const { AssetType } = require("../../../../shared/types/asset-type");

const mapStateToProps = (state) => ({
  asset: getMediaEditInitialValues(state),
  initialValues: getMediaEditInitialValues(state),
  saveInProgress: getProgressForKeySelector(state)(Actions.Types.SAVE_MEDIA),
  dialogType: getDialogType(state)
});

const mapDispatchToProps = {
  onSubmit: Actions.Creators.saveMedia,
  onClose: Actions.Creators.closeEditing,
  onChange: Actions.Creators.mediaChange
};

const validate = (values) => {
  const errors = {};

  if (values.file && values.file.rejected) {
    errors.file = "Invalid file provided.";
  }

  if (values.type === AssetType.ASSET_URL) {
    errors.url = urlValidator(values.url);
  }

  return ["title"]
    .filter((field) => !values[field] || values[field].trim() === "")
    .reduce((memo, field) => {
      memo[field] = `${field} is required`;
      return memo;
    }, errors);
};

const renderFiles = ({ fields, rootAsset }) => {
  return fields.map((file, index) => {
    const value = fields.get(index);
    return (
      <AssetFileField
        key={value.uuid || index}
        className="dropzone"
        name={file}
        rootAssetType={rootAsset.type}
        alternative={index > 0}
        onRemoveFile={() => fields.remove(index)}
        useNativePreview={true}
      />
    );
  });
};

const EditMediaImpl = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: "edit-media", enableReinitialize: true, validate })(
    ({
      handleSubmit,
      asset,
      onSubmit,
      onClose,
      saveInProgress,
      dialogType
    }) => {
      const { uuid, createdAt, title, type, dimensions } = asset;

      useEscapeHook(asset !== undefined, onClose);

      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalTemplate
            title={`Edit ${MEDIA_TITLE_MAPPING[dialogType]}`}
            className={cx("assetEditModal", {
              backgroundEditModal: dialogType === "WALL_ASSET"
            })}
            onCloseClick={onClose}
            footer={
              <button
                className={cx("button", {
                  inProgress: saveInProgress(uuid)
                })}
                type="submit"
                data-qa="update-info-btn"
              >
                Update Info
              </button>
            }
          >
            <>
              {asset.type !== AssetType.ASSET_URL && (
                <FieldArray
                  name="files"
                  rootAsset={asset}
                  component={renderFiles}
                />
              )}
              <div className="mediaDetail">
                <table className="mediaSummary">
                  <tbody>
                    <tr>
                      <th>Created on:</th>
                      <td>
                        <DateTimeField datetime={createdAt} />
                      </td>
                    </tr>
                    <tr>
                      <th>Name:</th>
                      <td>{title}</td>
                    </tr>
                    <tr>
                      <th>Type:</th>
                      <td>{getHumanReadableAssetTypeName(type)}</td>
                    </tr>
                    {dimensions && dimensions.width !== 0 && (
                      <tr>
                        <th>Dimensions:</th>
                        <td>
                          {dimensions.width}x{dimensions.height}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="mediaEdit">
                  <TextField name="title" label="Name" />
                  {asset.type === AssetType.ASSET_URL && (
                    <TextField name="url" label="URL" />
                  )}
                  <TextareaField name="description" label="Description" />
                </div>
              </div>
            </>
          </ModalTemplate>
        </form>
      );
    }
  )
);

export const EditMedia = () =>
  createPortal(<EditMediaImpl />, document.getElementById("modal"));
