import { useSelector } from "react-redux";
import { UserReduced } from "../../../shared/types/user";
import { getUser } from "./auth-selectors";
import { AclPermission, AclResource, hasAccess } from "../../../shared/acl";
import { getAcl } from "../config/config-selectors";
import { useCallback } from "react";

export const useHasAccess = (resource: AclResource) => {
  const user: UserReduced = useSelector(getUser);
  const acl: AclPermission[] = useSelector(getAcl);

  if (!user) {
    return false;
  }

  return hasAccess(acl, resource, user.roles);
};

export const useHasAccessFactory = () => {
  const user: UserReduced = useSelector(getUser);
  const acl: AclPermission[] = useSelector(getAcl);

  return useCallback(
    (resource: AclResource) => hasAccess(acl, resource, user.roles),
    [acl, user]
  );
};
