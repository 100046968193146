import { all, call, put, takeEvery, fork, select } from "redux-saga/effects";
import { startSubmit, stopSubmit, reset } from "redux-form";
import { Logger as logger } from "purplex-logging";

import Actions from "client/modules/settings/predefined-scene-names/predefined-scene-names-actions";
import * as api from "client/modules/api/client";
import { FORM_NAME } from "client/modules/settings/predefined-scene-names/create-predefined-scene-name-form";
import { onRouteEntered } from "../../routing/on-route-enter-saga";
import { confirmDialogSaga } from "../../dialogs/dialogs-saga";
import {
  PREDEFINED_SCENE_NAMES_ROUTE,
  PRESENTATIONS_ROUTE,
  SCENES_ROUTE
} from "../../routing/route-names";
import { notifyInfo } from "../../ux/notifications/notifications-saga";
import { listHasMoreData } from "./predefined-scene-names-selectors";
import { DialogResult } from "../../dialogs/event-types";

function* onCreatePredefinedSceneName({ data }) {
  yield put(startSubmit(FORM_NAME));

  try {
    const predefinedSceneName = yield call(
      api.createPredefinedSceneName,
      data.sceneName
    );

    yield put(Actions.Creators.predefinedSceneNameCreated(predefinedSceneName));
    yield put(stopSubmit(FORM_NAME));
    yield put(reset(FORM_NAME));
  } catch (ex) {
    if (ex.response && ex.response.status === 409) {
      yield put(
        stopSubmit(FORM_NAME, {
          sceneName: `Predefined Scene Name '${data.sceneName}' already exists`
        })
      );
    } else {
      logger.error("Predefined scene name creation error", ex);
    }
  }
}

function* onDeleteRecord({ id }) {
  const { type } = yield call(confirmDialogSaga, {
    title: "Delete predefined scene name",
    text: `Are you sure you want to delete this predefined scene name?`,
    subtext: "",
    confirmLabel: "Delete",
    cancelLabel: "Cancel"
  });

  if (type === DialogResult.CONFIRM) {
    yield call(api.deletePredefinedSceneName, id);
    yield call(
      notifyInfo,
      "Predefined scene name has been successfully deleted"
    );
    yield put(Actions.Creators.recordDeleted(id));
  }
}

function* fetchAllPredefinedScenes() {
  yield put(Actions.Creators.reset());

  const pageSize = 20;
  let page = 1;

  let hasMoreData = true;
  do {
    const { count, rows } = yield call(
      api.getPredefinedSceneNames,
      page,
      pageSize
    );

    yield put(Actions.Creators.fetched(count, rows));

    hasMoreData = yield select(listHasMoreData);
    page++;
  } while (hasMoreData);
}

export default function* () {
  yield all([
    takeEvery(
      Actions.Types.CREATE_PREDEFINED_SCENE_NAME,
      onCreatePredefinedSceneName
    ),
    takeEvery(Actions.Types.DELETE_RECORD, onDeleteRecord),
    fork(onRouteEntered, PRESENTATIONS_ROUTE, fetchAllPredefinedScenes),
    fork(onRouteEntered, SCENES_ROUTE, fetchAllPredefinedScenes),
    fork(onRouteEntered, PREDEFINED_SCENE_NAMES_ROUTE, fetchAllPredefinedScenes)
  ]);
}
