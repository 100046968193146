import { createActions } from "reduxsauce";

export default createActions(
  {
    addUser: ["data"],
    editUser: ["data"],
    sendConfirmationEmail: ["userId"]
  },
  { prefix: "USERS/" }
);
