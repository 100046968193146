import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { UserTrackingEvent } from "./user-tracking-event";
import { UserTrackingActions } from "./user-tracking-slice";

export const useTracking = () => {
  const dispatch = useDispatch();

  return useCallback(
    (payload: { eventName: UserTrackingEvent; metadata: object }) => {
      dispatch(UserTrackingActions.track(payload));
    },
    [dispatch]
  );
};
