import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as DialogSelectors from "../dialogs-selectors";
import { ConfirmDialog } from "./confirm-dialog";
import { MessageDialog } from "./message-dialog";
import { UserPickerDialog } from "./user-picker-dialog";
import { ConfirmDialogWithInput } from "./confirm-dialog-with-input";
import { RoomPickerDialog } from "./room-picker-dialog";
import { DialogsActions } from "../dialogs-reducer";
import { DialogEvent, DialogResult } from "../event-types";
import { DialogType } from "../dialog-type";
import { useEscapeHook } from "../dialog-hooks";
import { CollaboratorsDialog } from "./collaborators-dialog";

export const ModalDialogRoot = () => {
  const open = useSelector(DialogSelectors.isDialogOpened);
  const type = useSelector(DialogSelectors.getDialogType);
  const config = useSelector(DialogSelectors.getDialogConfig);

  const dispatch = useDispatch();

  const onEvent = useCallback(
    (eventType: DialogEvent | DialogResult, eventPayload?: any) => {
      dispatch(DialogsActions.onEvent({ eventType, eventPayload }));
    },
    [dispatch]
  );

  const onWindowPopState = useCallback(() => {
    dispatch(DialogsActions.close());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("popstate", onWindowPopState);
    return () => window.removeEventListener("popstate", onWindowPopState);
  });

  const closeDialog = useCallback(() => {
    dispatch(DialogsActions.onEvent({ eventType: DialogEvent.ON_CANCEL }));
  }, [dispatch]);

  useEscapeHook(open, closeDialog);

  switch (type) {
    case null:
      return null;

    case DialogType.CONFIRM:
      return (
        <ConfirmDialog
          onEvent={onEvent}
          open={open}
          title={config.title}
          text={config.text}
          subtext={config.subtext}
          confirmLabel={config.confirmLabel}
          cancelLabel={config.cancelLabel}
        />
      );

    case DialogType.CONFIRM_INPUT:
      return (
        <ConfirmDialogWithInput
          onEvent={onEvent}
          open={open}
          title={config.title}
          text={config.text}
          subtext={config.subtext}
          confirmLabel={config.confirmLabel}
          fieldLabel={config.fieldLabel}
          cancelLabel={config.cancelLabel}
          confirmText={config.confirmText}
        />
      );

    case DialogType.MESSAGE:
      return (
        <MessageDialog
          onEvent={onEvent}
          open={open}
          title={config.title}
          text={config.text}
          subtext={config.subtext}
          confirmLabel={config.confirmLabel}
        />
      );

    case DialogType.USER_PICKER:
      return (
        <UserPickerDialog
          onEvent={onEvent}
          open={open}
          fieldLabel={config.fieldLabel}
          title={config.title}
          resourceAccess={config.resourceAccess}
        />
      );

    case DialogType.ROOM_PICKER:
      return (
        <RoomPickerDialog
          onEvent={onEvent}
          open={open}
          title={config.title}
          confirmLabel={config.confirmLabel}
          filteredInstances={config.filteredInstances}
        />
      );

    case DialogType.COLLABORATORS:
      return (
        <CollaboratorsDialog
          open={open}
          onEvent={onEvent}
          creatorId={config.creatorId}
          collaborators={config.collaborators}
        />
      );

    default:
      // eslint-disable-next-line no-console
      console.error("Invalid dialog type", type);
      return null;
  }
};
