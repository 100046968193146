import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import MediaActions from "../media/media-actions";
import { ReactComponent as IconTrash } from "../../images/trash.svg";
import { AssetViewModel } from "../entity-repository/entity.types";
import { useHasAccess } from "../auth/use-has-access";
import { AclResource } from "../../../shared/acl";
import { AssetStatusBadge } from "../media/components/asset-status-badge";
import { getOrderedInstances } from "../entity-repository/entity-repository-selectors";
import { getCdnHost } from "../config/config-selectors";
import { AssetStatus } from "../../../shared/types/asset-status";
import { getAssetFile } from "../media/get-asset-file";
import { getAssetThumbnailBig } from "../media/get-asset-thumbnail";
import {
  Tabs,
  TabsNavigationButton,
  TabsNavigationButtons,
  TabsNavigationContainer
} from "../ux/tab-panel";
import { WallDimensionsStatus } from "../media/components/wall-compatibility/wall-dimensions-status";
import {
  getWarningMessage,
  isSapWallAssetOptimalForInstance,
  SAP_WALL_ASSET_OK_FOR_ROOM
} from "./sap-wall-dimensions-validity";

const WallRoomCompatibility = ({
  wallAsset
}: {
  wallAsset: AssetViewModel;
}) => {
  const instances = useSelector(getOrderedInstances);

  return (
    <div className="wall-room-compatibility">
      {instances.map((instance) => (
        <span key={instance.uuid} className="room">
          <WallDimensionsStatus
            isValid={isSapWallAssetOptimalForInstance(wallAsset, instance)}
            okMessage={SAP_WALL_ASSET_OK_FOR_ROOM}
            warningMessage={getWarningMessage(instance.recommendedBackgrounds)}
          />
          {instance.name}
        </span>
      ))}
    </div>
  );
};

const WallFile = ({
  wallFile,
  isAlternative
}: {
  wallFile: AssetViewModel;
  isAlternative: boolean;
}) => {
  const { uuid, dimensions, status } = wallFile;
  const dispatch = useDispatch();
  const cdnHost: string = useSelector(getCdnHost);
  const canEditWalls = useHasAccess(AclResource.WALL_WRITE);

  return (
    <>
      {isAlternative && canEditWalls && (
        <button
          onClick={() => dispatch(MediaActions.Creators.deleteAsset(uuid))}
          className="button buttonAlert buttonDelete buttonIcon"
          title="Delete Alternative File"
          data-qa="delete-alt-file-btn"
        >
          <IconTrash />
        </button>
      )}
      <div className="fileInfo">
        {status === AssetStatus.STATUS_READY && (
          <strong>
            {dimensions.width} x {dimensions.height} px
          </strong>
        )}
        <AssetStatusBadge status={status} />
        <WallRoomCompatibility wallAsset={wallFile} />
      </div>
      <a
        target="_blank" // eslint-disable-line
        title="Open Original File"
        className="backgroundLink"
        href={`${getAssetFile(wallFile, cdnHost)}?_${Date.now()}`}
      >
        <img
          alt="wall"
          className="backgroundImage"
          src={`${getAssetThumbnailBig(wallFile, cdnHost)}?_${Date.now()}`}
        />
      </a>
    </>
  );
};

export const SAPWallPreview = ({
  wallAsset
}: {
  wallAsset: AssetViewModel;
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // splits the wall into separate assets, so the room BG compatibility is properly
  // calculated for each wall file separately
  const allWallFiles = [
    { ...wallAsset, alternativeAssets: [] },
    ...wallAsset.alternativeAssets
  ];

  const validatedTabIndex = allWallFiles[activeTabIndex] ? activeTabIndex : 0;

  return (
    <Tabs>
      {allWallFiles.length > 1 && (
        <TabsNavigationContainer>
          <TabsNavigationButtons>
            {allWallFiles.map((wallFile, index) => {
              const isAlternative = index > 0;

              return (
                <TabsNavigationButton
                  key={index}
                  active={index === validatedTabIndex}
                  onClick={() => setActiveTabIndex(index)}
                  dataQa={`${
                    !isAlternative ? "main-file" : `alternative-file-${index}`
                  }-tab`}
                >
                  {!isAlternative
                    ? "Main File"
                    : `Alternative File ${
                        allWallFiles.length > 2 ? "#" + index : ""
                      }`}
                </TabsNavigationButton>
              );
            })}
          </TabsNavigationButtons>
        </TabsNavigationContainer>
      )}
      <WallFile
        isAlternative={validatedTabIndex > 0}
        wallFile={
          (allWallFiles[validatedTabIndex] as unknown) as AssetViewModel
        }
      />
    </Tabs>
  );
};
