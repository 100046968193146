import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import cx from "classnames";

import { AssetFull } from "../../../../../shared/types/asset";
import { getUploadsInDialog } from "../../media-selectors";
import {
  getCdnHost,
  getMaxUploadSize,
  getDisabledAssetTypes
} from "../../../config/config-selectors";
import Actions from "../../media-actions";
import {
  getCreatableAssetMimes,
  CREATABLE_WALL_MIMES
} from "../../asset-mime-types";
import { AssetType } from "../../../../../shared/types/asset-type";
import { AssetStatus } from "../../../../../shared/types/asset-status";
import { getAssetThumbnail } from "../../get-asset-thumbnail";
import { MediaDialogType } from "./media-dialog-type";
import { ReactComponent as IconCheck } from "../../../../images/check.svg";
import { renderAssetIcon } from "../lists/render-asset-icon";

interface UploadMediaGridProps {
  assetClasses?: AssetClasses;
  cols?: number;
  dialogType: MediaDialogType;
  multiselectEnabled?: boolean;
  multiselectMode?: boolean;
  onDeselectAll: () => void;
  onMultiSelectAsset: (asset: AssetFull) => void;
  onSelectAll: (uuids: string[]) => void;
  onSelectAsset: (asset: AssetFull) => void;
  onUploadButton?: (button: React.ReactNode) => void;
  rows?: number;
  selectedAssets: string[];
}

export interface AssetClasses {
  [key: string]: string;
}

export interface AssetUploads {
  asset: AssetFull;
  upload?: UploadInformation;
}

interface UploadInformation {
  uploaded: number;
  size: number;
}

interface MediaGridTileProps {
  asset: AssetFull;
  upload?: UploadInformation;
  cdnHost: string;
  onClick: () => void;
  onMultiSelect: () => void;
  selected: boolean;
  multiselectEnabled?: boolean;
  className?: string;
}

const MediaGridTile: React.FC<MediaGridTileProps> = ({
  asset,
  upload,
  cdnHost,
  onClick,
  onMultiSelect,
  className,
  selected,
  multiselectEnabled
}) => {
  const { title, status } = asset;

  let src = null;

  // TODO show somewhere asset status

  if (
    asset.type === AssetType.ASSET_IMAGE ||
    asset.type === AssetType.ASSET_VIDEO ||
    asset.type === AssetType.ASSET_WALL_IMAGE ||
    asset.type === AssetType.ASSET_WALL_VIDEO
  ) {
    if (status === AssetStatus.STATUS_READY) {
      src = getAssetThumbnail(asset, cdnHost);
    }
  } else {
    // TODO:  add URL asset thumbnail
    src = "";
  }

  const progress = upload ? upload.uploaded / (upload.size || 1) : 0;
  const cssClasses = {
    [status]: true,
    selected
  };

  if (className) {
    cssClasses[className] = true;
  }

  return (
    <div
      className={cx("uploadItem", cssClasses)}
      onClick={(ev) => {
        ev.stopPropagation();
        onClick();
      }}
    >
      <span className="overlay"></span>
      {multiselectEnabled && (
        <span
          className="selectorHandle"
          onClick={(ev) => {
            ev.stopPropagation();

            onMultiSelect();
          }}
        >
          {selected && <IconCheck />}
        </span>
      )}

      <span className="title">{title}</span>
      <span
        className="image"
        style={{
          backgroundImage: src ? `url(${src})` : "none",
          backgroundColor: src ? "#fff" : "transparent"
        }}
      />
      {renderAssetIcon(asset.type, status)}
      {upload && status !== AssetStatus.STATUS_READY && (
        <svg
          className="circleProgress"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle r="23" cx="25" cy="25" />
          <circle
            className="bar"
            r="23"
            cx="25"
            cy="25"
            strokeDasharray="145"
            strokeDashoffset={!upload ? 0 : 145 - 145 * progress}
          />
        </svg>
      )}
    </div>
  );
};

export const UploadMediaGrid: React.FC<UploadMediaGridProps> = ({
  assetClasses = {},
  cols,
  dialogType,
  multiselectEnabled,
  multiselectMode,
  onDeselectAll,
  onMultiSelectAsset,
  onSelectAll,
  onSelectAsset,
  onUploadButton = () => {},
  rows,
  selectedAssets
}) => {
  const [rejected, setRejected] = useState<string[]>([]);
  const [dropping, setDropping] = useState(false);

  const dispatch = useDispatch();
  const assetUploads: AssetUploads[] = useSelector(getUploadsInDialog);
  const cdnHost: string = useSelector(getCdnHost);
  const maxUploadFileSize: number = useSelector(getMaxUploadSize);
  const disabledAssetTypes: string[] = useSelector(getDisabledAssetTypes);
  const uploadFiles = useCallback(
    (...args) => {
      dispatch(Actions.Creators.uploadFiles(...args));
    },
    [dispatch]
  );

  let accept = getCreatableAssetMimes(disabledAssetTypes);
  if (dialogType === MediaDialogType.WALL_ASSET) {
    accept = CREATABLE_WALL_MIMES;
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open
  } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    accept: accept.join(","),
    maxSize: maxUploadFileSize,
    onDragEnter: () => setDropping(true),
    onDragLeave: () => setDropping(false)
  });
  useEffect(() => {
    setDropping(false);

    if (acceptedFiles.length) {
      uploadFiles(acceptedFiles);
    }

    setRejected(fileRejections.map(({ file }) => file.name));
  }, [acceptedFiles, fileRejections, uploadFiles]);

  const uploadButton = useMemo(
    () => (
      <button className="button" onClick={open} data-qa="browse-file-btn">
        Browse Files
      </button>
    ),
    [open]
  );

  const uploadsCount = assetUploads.length;
  useEffect(() => {
    if (uploadsCount) {
      onUploadButton(uploadButton);
    } else {
      onUploadButton(null);
    }
  }, [onUploadButton, uploadsCount, uploadButton]);

  return (
    <div className="uploadMediaGrid">
      <div
        {...getRootProps({
          className: cx({
            multiselect: multiselectMode,
            singleSelect: !multiselectMode,
            dropzone: true,
            dropping,
            empty: assetUploads.length === 0,
            error: rejected.length > 0
          })
        })}
      >
        {assetUploads.length > 0 &&
          multiselectEnabled &&
          (selectedAssets.length === 0 ? (
            <div
              className="massSelector"
              onClick={() =>
                onSelectAll(assetUploads.map(({ asset: { uuid } }) => uuid))
              }
            >
              Select all
            </div>
          ) : (
            <div className="massSelector" onClick={onDeselectAll}>
              Deselect all
            </div>
          ))}
        <input {...getInputProps()} />
        <div
          className="dropzoneThumbs"
          style={{
            ["--grid-cols" as string]: cols ? cols : null,
            ["--grid-rows" as string]: rows ? rows : -1,
            ["--scrollbar-size" as string]:
              rows && cols && assetUploads.length > cols * rows ? null : 0
          }}
        >
          {assetUploads.map(({ asset, upload }) => (
            <MediaGridTile
              selected={selectedAssets.includes(asset.uuid)}
              key={asset.uuid}
              asset={asset}
              upload={upload}
              cdnHost={cdnHost}
              multiselectEnabled={multiselectEnabled}
              className={assetClasses[asset.uuid] || ""}
              onMultiSelect={() => onMultiSelectAsset(asset)}
              onClick={() => onSelectAsset(asset)}
            />
          ))}
        </div>

        <div
          className={cx("dropzoneText", {
            "--compact": assetUploads.length > 0
          })}
        >
          <span>Drag &amp; Drop Files Here</span>
          {uploadButton}
          {assetUploads.length === 0 && (
            <>
              <br />
              Accepting {accept.join(", ")}. Maximum file size is{" "}
              {Math.round(maxUploadFileSize / 1024 / 1024)} MB.
            </>
          )}
        </div>
        {rejected.length > 0 && (
          <div className="dropzoneFlash flash-message flash-message-error">
            {rejected.length === 1 ? "File" : "Files"} {rejected.join(", ")}{" "}
            cannot be uploaded.
          </div>
        )}
      </div>
    </div>
  );
};
