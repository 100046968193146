import { createSelector } from "reselect";

import {
  getRouteParams,
  RouteParamSelector
} from "../../routing/routing-selectors";
import {
  getGuestDomains,
  getUsers
} from "../../entity-repository/entity-repository-selectors";
import { SortDirection, UserSortColumn } from "../../api/client";

export const getSort = createSelector(
  getRouteParams as RouteParamSelector<{ sort: UserSortColumn }>,
  (state) => state.sort
);

export const getSortDirection = createSelector(
  getRouteParams as RouteParamSelector<{ sortDir: SortDirection }>,
  (routeParams) => routeParams.sortDir
);

export const getUserEditInitialValues = createSelector(
  getRouteParams as RouteParamSelector<{ id: string | number }>,
  getUsers,
  (routeParams, users) => {
    if (routeParams.id && users[routeParams.id]) {
      return users[routeParams.id];
    } else {
      return {
        roles: []
      };
    }
  }
);

export const getGuestDomainFormData = createSelector(
  getGuestDomains,
  getRouteParams as RouteParamSelector<{ id: string }>,
  (guestDomainEntities, { id }) => {
    if (id && guestDomainEntities[id]) {
      const record = guestDomainEntities[id];
      return {
        name: record.name,
        adminLastName: record.adminLastName,
        adminFirstName: record.adminFirstName,
        adminEmail: record.adminEmail
      };
    } else {
      return {
        name: "",
        adminLastName: "",
        adminFirstName: "",
        adminEmail: ""
      };
    }
  }
);
