import React from "react";
import { useSelector } from "react-redux";

import { getCdnHost } from "../../../config/config-selectors";
import { getOrderedInstances } from "../../../entity-repository/entity-repository-selectors";
import { AssetStatus } from "../../../../../shared/types/asset-status";
import { ImInstance } from "../../../../../shared/types/im-instance";
import { getAssetThumbnailBig } from "../../get-asset-thumbnail";
import { getAssetFile } from "../../get-asset-file";
import { AssetStatusBadge } from "../asset-status-badge";
import { Tabs } from "../../../ux/tab-panel";
import { AssetViewModel } from "../../../entity-repository/entity.types";
import { WallDimensionsStatus } from "./wall-dimensions-status";
import { isWallAssetOptimalForInstance } from "./wall-dimensions-validity";

const WallRoomCompatibility = ({
  wallAsset
}: {
  wallAsset: AssetViewModel;
}) => {
  const instances = useSelector(getOrderedInstances);

  return (
    <div className="wall-room-compatibility">
      {instances.map((instance) => (
        <span key={instance.uuid} className="room">
          <WallDimensionsStatus
            isValid={isWallAssetOptimalForInstance(
              wallAsset.dimensions.width,
              instance.roomDimensions.width
            )}
            okMessage="File is wide enough for this room."
            warningMessage="File doesn't have recommended dimensions (at least 80% of the room width), this will result in poor quality."
          />
          {instance.name}
        </span>
      ))}
    </div>
  );
};

const WallFile = ({ wallFile }: { wallFile: AssetViewModel }) => {
  const { dimensions, status } = wallFile;
  const instances: ImInstance[] = useSelector(getOrderedInstances);
  const cdnHost: string = useSelector(getCdnHost);

  return (
    <>
      <div className="fileInfo">
        {status === AssetStatus.STATUS_READY && (
          <strong>
            {dimensions.width} x {dimensions.height} px
          </strong>
        )}
        <AssetStatusBadge status={status} />
        {instances.length > 1 && <WallRoomCompatibility wallAsset={wallFile} />}
      </div>
      <a
        target="_blank" // eslint-disable-line
        title="Open Original File"
        className="backgroundLink"
        href={`${getAssetFile(wallFile, cdnHost)}?_${Date.now()}`}
      >
        <img
          alt="wall"
          className="backgroundImage"
          src={`${getAssetThumbnailBig(wallFile, cdnHost)}?_${Date.now()}`}
        />
      </a>
    </>
  );
};

export const WallPreview = ({ wallAsset }: { wallAsset: AssetViewModel }) => {
  return (
    <Tabs>
      <WallFile wallFile={wallAsset} />
    </Tabs>
  );
};
