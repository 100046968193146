import cx from "classnames";
import Tooltip from "rc-tooltip";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as ConfigSelectors from "../../config/config-selectors";
import { hasVix, hasTriggersEnabled } from "../../config/config-selectors";
import { getProgressForKeySelector } from "../../in-progress/in-progress-selectors";
import { PresentationCollaborate } from "../../presentations/components/presentation-collaborate/presentation-collaborate";
import PresentationActions from "../../presentations/presentation-actions";
import { getPresentationInitialValues } from "../../presentations/presentation-selectors";

import { ReactComponent as IconElipsis } from "client/images/elipsis.svg";
import { ReactComponent as IconBack } from "client/images/back.svg";

import { AclResource } from "shared/acl";
import { PRESENTATION_LOCATORS } from "shared/tests/locators/presentation.locators";
import { withAcl } from "../../acl/with-acl";

class Header extends React.Component {
  static propTypes = {
    hasAccess: PropTypes.func.isRequired,
    isRequestPublishInProgress: PropTypes.func.isRequired,
    onDryRun: PropTypes.func.isRequired,
    onGoBack: PropTypes.func,
    onPlanSession: PropTypes.func.isRequired,
    presentation: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    }),
    requestPublish: PropTypes.func.isRequired,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired
  };

  render() {
    const {
      hasAccess,
      isRequestPublishInProgress,
      onDryRun,
      onGoBack,
      onPlanSession,
      presentation,
      requestPublish,
      subtitle,
      title
    } = this.props;

    const onRequestPublish = () =>
      requestPublish(presentation.id, presentation.title);

    return (
      <header className="header">
        <div>
          {onGoBack && (
            <button
              type="button"
              onClick={onGoBack}
              className={cx("appName", { appNameBack: !!onGoBack })}
            >
              <IconBack />
            </button>
          )}
          {subtitle && <span className="appNameSecondary">{title}</span>}
        </div>

        <div>
          {presentation && (
            <>
              <PresentationCollaborate />

              {hasVix && (
                <>
                  <Tooltip
                    placement="bottomRight"
                    trigger={["click"]}
                    overlayClassName="dropdown"
                    overlay={
                      <>
                        <button
                          type="button"
                          onClick={() => onDryRun(presentation.id)}
                          data-qa={PRESENTATION_LOCATORS.buttons.testRun}
                        >
                          Test Run
                        </button>
                        <button
                          type="button"
                          onClick={() => onPlanSession(presentation.id)}
                          data-qa={PRESENTATION_LOCATORS.buttons.planSession}
                        >
                          Plan session
                        </button>
                        {!hasAccess(AclResource.MAKE_PRESENTATION_GLOBAL) && (
                          <button
                            type="button"
                            className={cx({
                              inProgress: isRequestPublishInProgress(
                                presentation.id
                              )
                            })}
                            key="requestPublish"
                            onClick={onRequestPublish}
                            data-qa="request-publish-btn"
                          >
                            Request Publish
                          </button>
                        )}
                      </>
                    }
                  >
                    <button
                      type="button"
                      className="button buttonOutlined buttonIcon toggle"
                      data-dropdown="toggle"
                    >
                      <IconElipsis />
                    </button>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  gitbookHelpLink: ConfigSelectors.getGitbookHelpLink(state),
  hasVix: hasVix(state),
  isRequestPublishInProgress: getProgressForKeySelector(state)(
    PresentationActions.Types.REQUEST_PUBLISH_PRESENTATION
  ),
  presentation: getPresentationInitialValues(state),
  title: ConfigSelectors.getSiteTitle(state),
  triggersEnabled: hasTriggersEnabled(state)
});

const mapDispatchToProps = {
  onDryRun: PresentationActions.Creators.dryRunPresentation,
  onPlanSession: PresentationActions.Creators.planVixSession,
  requestPublish: PresentationActions.Creators.requestPublishPresentation
};

export default connect(mapStateToProps, mapDispatchToProps)(withAcl(Header));
