import isObject from "lodash/isObject";
import {
  all,
  take,
  takeEvery,
  call,
  fork,
  put,
  select
} from "redux-saga/effects";
import { Logger as logger } from "purplex-logging";
import { BusinessError, NotFoundError } from "../api/client-errors";
import { requestUserPasswordReset, resetUserPassword } from "../api/client";
import { userSchema } from "../entity-repository/schema";
import {
  actionTypes as routerActionTypes,
  actions as RouterActions
} from "redux-router5";

import * as Api from "../api/client";
import { normalizeAndStore } from "../entity-repository/entity-repository-saga";
import { takeEveryWithCheckProgress } from "../in-progress/in-progress-saga";
import {
  AUTH_LOGIN_ROUTE,
  AUTH_NEW_PASSWORD_DONE_ROUTE,
  AUTH_RESET_PASSWORD_ROUTE,
  AUTH_RESET_PASSWORD_SEND_ROUTE,
  DASHBOARD_ROUTE
} from "../routing/route-names";
import { getRouteName, getRouteParams } from "../routing/routing-selectors";
import {
  notifyError,
  notifySuccess
} from "../ux/notifications/notifications-saga";
import Actions from "./auth-actions";
import { isLoginProtectedPage } from "./auth-selectors";

function* fetchLoggedInUser() {
  const user = yield call(Api.getLoggedInUser);
  const userId = yield call(normalizeAndStore, user, userSchema);
  yield put(Actions.Creators.loggedUser(userId));
}

function* onLogout() {
  try {
    yield call(Api.logout);
  } catch (e) {
    logger.error("Logout failed", e);
  } finally {
    window.location.reload();
  }
}

function* onSaveUserProfile({ data }) {
  try {
    const payload = {};
    payload.firstName = data.firstName;
    payload.lastName = data.lastName;
    payload.phone = data.phone || "";
    payload.country = data.country || "";
    if (isObject(data.avatar)) {
      payload.avatar = data.avatar.file;
    }

    if (data.password) {
      payload.password = data.password;
    }

    const userProfile = yield call(Api.updateLoggedInUser, payload);
    yield call(normalizeAndStore, userProfile, userSchema);

    yield call(notifySuccess, "Your user profile has been updated");
    yield put(RouterActions.navigateTo(DASHBOARD_ROUTE));
  } catch (e) {
    logger.error("Update of your user profile failed", e);
    yield call(notifyError, "Update of your user profile failed");
  }
}

function* sendPasswordResetToken({ email }) {
  try {
    yield call(requestUserPasswordReset, email);
    yield put(RouterActions.navigateTo(AUTH_RESET_PASSWORD_SEND_ROUTE));
  } catch (ex) {
    if (ex instanceof BusinessError) {
      yield call(notifyError, ex.message);
    } else if (ex instanceof NotFoundError) {
      yield call(notifyError, "Account not found");
    } else {
      yield call(notifyError, "An error occurred during the request");
      logger.warn("UserPasswordResetRequest failed", {
        exception: ex,
        email
      });
    }
  }
}

function* resetPassword({ newPassword }) {
  const { token } = yield select(getRouteParams);

  if (!token) {
    logger.info("No token provided, ignoring user reset action");
    return;
  }

  try {
    yield call(resetUserPassword, token, newPassword);
    yield put(RouterActions.navigateTo(AUTH_NEW_PASSWORD_DONE_ROUTE));
  } catch (ex) {
    if (ex instanceof BusinessError) {
      yield call(notifyError, "Password is not secure");
    } else if (ex instanceof NotFoundError) {
      yield call(notifyError, "Invalid token");
    } else {
      yield call(notifyError, "An error occurred during the request");
      logger.warn("UserPasswordReset failed", {
        exception: ex,
        token
      });
    }
  }
}

export function* authSaga() {
  yield takeEveryWithCheckProgress(
    Actions.Types.SAVE_USER_PROFILE,
    onSaveUserProfile
  );

  yield takeEveryWithCheckProgress(
    Actions.Types.SEND_PASSWORD_RESET_TOKEN,
    sendPasswordResetToken
  );

  yield takeEveryWithCheckProgress(Actions.Types.RESET_PASSWORD, resetPassword);

  /*
   * Initial user load when page is loaded
   */
  yield take(routerActionTypes.TRANSITION_SUCCESS);

  if (yield select(isLoginProtectedPage)) {
    yield fork(fetchLoggedInUser);
  } else {
    const isLoggedIn = yield call(Api.isUserLoggedIn);

    const routesRequireNotLoggedInUser = [
      AUTH_LOGIN_ROUTE,
      AUTH_RESET_PASSWORD_ROUTE
    ];

    const routeName = yield select(getRouteName);
    if (
      isLoggedIn &&
      routesRequireNotLoggedInUser.some((route) =>
        String(routeName).startsWith(route)
      )
    ) {
      yield fork(fetchLoggedInUser);
      yield put(RouterActions.navigateTo(DASHBOARD_ROUTE));
    }
  }

  yield all([takeEvery(Actions.Types.LOGOUT, onLogout)]);
}
