import cx from "classnames";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextAssetMetadata } from "shared/types/asset";

import { WallAsset } from "../assets/wall-asset";
import { HorizontalCenterHelper } from "../drag-helpers/horizontal-center-helper";
import { VerticalCenterHelper } from "../drag-helpers/vertical-center-helper";
import { BoundingBox } from "../drag-helpers/bounding-box";
import { AssetType } from "../../../../../shared/types/asset-type";
import {
  getActiveWallCoords,
  getSceneAssets,
  getSelectedSceneAssetUuids
} from "../../scene-editor-selectors";
import { SceneEditorActions } from "../../scene-editor-reducer";
import {
  useSelectAsset,
  useSelectMultipleAssets
} from "../../hooks/use-select-asset";
import { Asset } from "../assets/asset";
import { useRenderCount } from "../../utils";
import { ResizingTransformOrigin } from "../../hooks/dragging/use-resizing";

export const Wall = React.memo(
  (props: {
    wallNum: number;
    wallWidth: number;
    wallScale: number;
    wallStart: number;
    wallHeight: number;
    active: boolean;
    longWallWidth: number;
    wallAsset:
      | { type: AssetType; uuid: string; fileVersion: number }
      | undefined
      | null;
    canvasScale: number;
    enableDnD: boolean;
    dragOffsetX: number;
    dragOffsetY: number;
    scaleChangeOriginLeft: number;
    scaleChangeOriginTop: number;
    scaleChangeScale: number;
    cropChangeOffsetY: number;
    cropChangeOffsetX: number;
    cropChangeWidth: number;
    cropChangeHeight: number;
    resizeChangeOrigin: ResizingTransformOrigin;
    resizeChangeWidth: number;
    resizeChangeHeight: number;
  }) => {
    const {
      wallHeight,
      wallNum,
      wallScale,
      wallStart,
      wallWidth,
      active,
      longWallWidth,
      wallAsset,
      canvasScale,
      enableDnD,
      dragOffsetX,
      dragOffsetY,
      scaleChangeScale,
      scaleChangeOriginLeft,
      scaleChangeOriginTop,
      cropChangeOffsetY,
      cropChangeOffsetX,
      cropChangeHeight,
      cropChangeWidth,
      resizeChangeHeight,
      resizeChangeOrigin,
      resizeChangeWidth
    } = props;

    const { left, top } = useSelector(getActiveWallCoords);
    const selectedSceneAssetUuids = useSelector(getSelectedSceneAssetUuids);
    const sceneAssets = useSelector(getSceneAssets);

    const renderCount = useRenderCount("Wall");

    const dispatch = useDispatch();

    const wallRef = useRef<HTMLDivElement>(null);

    const selectAsset = useSelectAsset();

    const filterByVisibleWall = useCallback((wallNum: number) => {
      return (sceneAsset: { visibleOnWalls: { num: number }[] }) =>
        sceneAsset.visibleOnWalls.some(({ num }) => num === wallNum);
    }, []);

    const selectMultipleAsset = useSelectMultipleAssets();

    if (active) {
      setTimeout(() => {
        const rects = wallRef.current && wallRef.current.getClientRects();
        if (
          rects &&
          rects[0] &&
          (left !== rects[0].left || top !== rects[0].top)
        ) {
          dispatch(
            SceneEditorActions.setActiveWallCoords({
              top: rects[0].top,
              left: rects[0].left
            })
          );
        }
        //we need to wait for wall animation to finish
      }, 1050);
    }

    return (
      <div
        key={wallNum}
        className={cx({
          wall: true,
          [`wall-${wallNum}`]: true,
          active
        })}
        ref={wallRef}
        style={{
          width: wallWidth,
          height: wallHeight,
          transform: [
            `translateZ(${longWallWidth / -2}px) scaleX(${wallScale})`,
            `translateZ(${
              longWallWidth / 2
            }px) rotateY(-90deg) scaleX(${wallScale})`,
            `translateZ(${
              longWallWidth / 2
            }px) scaleX(-${wallScale}) translateX(-100%)`,
            `translateZ(${
              longWallWidth / 2
            }px) rotateY(90deg) scaleX(${wallScale})`
          ][wallNum - 1],
          transformOrigin: ["0 50%", "100% 50%", "0 50%", "0 50%"][wallNum - 1]
        }}
        data-qa={`scene-wall-${wallNum}`}
      >
        {renderCount}
        <div className="wall-overflow">
          {wallAsset && <WallAsset asset={wallAsset} wallStart={wallStart} />}
          {sceneAssets
            .filter(filterByVisibleWall(wallNum))
            .map((sceneAsset) => {
              const isSelected = selectedSceneAssetUuids.includes(
                sceneAsset.uuid
              );
              return (
                <Asset
                  key={sceneAsset.uuid}
                  sceneAssetId={sceneAsset.id}
                  sceneAssetUuid={sceneAsset.uuid}
                  wallStart={wallStart}
                  type={sceneAsset.asset.type}
                  uuid={sceneAsset.asset.uuid}
                  fileVersion={sceneAsset.asset.fileVersion}
                  top={sceneAsset.top}
                  left={sceneAsset.left}
                  scale={sceneAsset.scale}
                  width={sceneAsset.width}
                  height={sceneAsset.height}
                  cropHeight={sceneAsset.cropHeight}
                  cropWidth={sceneAsset.cropWidth}
                  cropOffsetX={sceneAsset.cropOffsetX}
                  cropOffsetY={sceneAsset.cropOffsetY}
                  canvasScale={canvasScale}
                  layer={sceneAsset.layer}
                  opacity={sceneAsset.opacity}
                  rounded={sceneAsset.rounded}
                  glow={sceneAsset.glow}
                  selected={isSelected}
                  textMetadata={
                    sceneAsset.asset.type === AssetType.ASSET_TEXT
                      ? (sceneAsset.asset
                          .metadata as unknown as TextAssetMetadata)
                      : undefined
                  }
                  selectMultipleAsset={selectMultipleAsset}
                  selectAsset={selectAsset}
                  enableDragging={enableDnD}
                  dragOffsetX={isSelected ? dragOffsetX : 0}
                  dragOffsetY={isSelected ? dragOffsetY : 0}
                  scaleChangeOriginLeft={isSelected ? scaleChangeOriginLeft : 0}
                  scaleChangeOriginTop={isSelected ? scaleChangeOriginTop : 0}
                  scaleChangeScale={isSelected ? scaleChangeScale : 1}
                  cropChangeOffsetY={isSelected ? cropChangeOffsetY : 0}
                  cropChangeOffsetX={isSelected ? cropChangeOffsetX : 0}
                  cropChangeWidth={isSelected ? cropChangeWidth : 0}
                  cropChangeHeight={isSelected ? cropChangeHeight : 0}
                  resizeChangeOrigin={
                    isSelected
                      ? resizeChangeOrigin
                      : ResizingTransformOrigin.LEFT_BOTTOM
                  }
                  resizeChangeWidth={isSelected ? resizeChangeWidth : 0}
                  resizeChangeHeight={isSelected ? resizeChangeHeight : 0}
                />
              );
            })}
        </div>
        {active && enableDnD && (
          <>
            <HorizontalCenterHelper canvasScale={canvasScale} />
            <VerticalCenterHelper canvasScale={canvasScale} />
            <BoundingBox canvasScale={canvasScale} />
          </>
        )}
      </div>
    );
  }
);
