import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { Wall } from "./wall";
import {
  getActiveWallInfo,
  getCroppingChange,
  getDraggingOffset,
  getResizingChange,
  getResizingOrigin,
  getScaleChange,
  getScaleOrigin,
  getSceneRotation
} from "../../scene-editor-selectors";
import { useDragging } from "../../hooks/dragging/use-dragging";
import {
  getRoomScale,
  getTransformedWallParams
} from "../../transformation-utils";
import { ImWall } from "../../../../../shared/types/im-instance";
import { SelectionBox } from "../drag-helpers/selection-box";
import { useSelection } from "../../hooks/dragging/use-selection";
import { useRenderCount } from "../../utils";
import { ResizingTransformOrigin } from "../../hooks/dragging/use-resizing";

export const SceneModel = React.memo(
  ({
    width,
    height,
    enableDnD,
    walls,
    activeWall,
    wallAsset,
    sceneAssets
  }: {
    width: number;
    height: number;
    enableDnD: boolean;
    walls: ImWall[];
    activeWall: number;
    //TODO fix type
    wallAsset: any;
    sceneAssets: any[];
  }) => {
    const sceneRotation = useSelector(getSceneRotation);
    const activeWallInfo = useSelector(getActiveWallInfo);

    const sceneRef = useRef<HTMLDivElement>(null);

    const renderCount = useRenderCount("Scene Model", {
      top: "50%",
      left: "50%"
    });

    const { scale, canvasScale } = getRoomScale(
      width,
      height,
      walls,
      activeWall
    );

    const {
      wallHeight,
      perspective,
      longWallWidth,
      shortWallWidth,
      wallsWithScale
    } = getTransformedWallParams<ImWall>(walls, activeWall);

    useDragging(canvasScale);

    const draggingOffset = useSelector(getDraggingOffset);
    const croppingChange = useSelector(getCroppingChange);
    const scalingChange = useSelector(getScaleChange);
    const scalingOrigin = useSelector(getScaleOrigin);
    const resizingChange = useSelector(getResizingChange);
    const resizingOrigin = useSelector(getResizingOrigin);

    const { selectionBox, selectionMouseDownHandler } = useSelection(
      sceneAssets.filter(
        (asset) => activeWallInfo && asset.wall === activeWallInfo.num
      ),
      canvasScale
    );

    const roomStyles: { [key: string]: string | number } = {
      width: shortWallWidth,
      height: wallHeight
    };

    if (scale !== 0) {
      roomStyles.transform = `scale(${scale}) rotateY(${sceneRotation}deg)`;
      roomStyles.display = "block";
    }

    let coord: any = [{ left: 0, top: 0 }];
    if (sceneRef.current) {
      coord = sceneRef.current.getClientRects();
    }

    return (
      <div
        className="scene"
        ref={sceneRef}
        style={{
          perspective,
          width: shortWallWidth,
          height: wallHeight,
          marginLeft: shortWallWidth / -2,
          marginTop: wallHeight / -2
        }}
      >
        {renderCount}
        <div
          className="room"
          style={roomStyles}
          data-qa="room"
          onMouseDown={selectionMouseDownHandler}
        >
          {wallsWithScale
            .sort((a, b) => a.num - b.num)
            .map((wall) => {
              const isActive = wall.num === activeWall + 1;
              return (
                <Wall
                  key={wall.num}
                  wallHeight={wall.height}
                  wallStart={wall.start}
                  wallNum={wall.num}
                  wallScale={wall.scale}
                  wallWidth={wall.width}
                  active={isActive}
                  longWallWidth={longWallWidth}
                  wallAsset={wallAsset}
                  canvasScale={canvasScale}
                  enableDnD={enableDnD}
                  dragOffsetX={isActive ? draggingOffset.offsetX : 0}
                  dragOffsetY={isActive ? draggingOffset.offsetY : 0}
                  scaleChangeOriginLeft={isActive ? scalingOrigin.left : 0}
                  scaleChangeOriginTop={isActive ? scalingOrigin.top : 0}
                  scaleChangeScale={isActive ? scalingChange : 1}
                  cropChangeOffsetY={isActive ? croppingChange.cropOffsetY : 0}
                  cropChangeOffsetX={isActive ? croppingChange.cropOffsetX : 0}
                  cropChangeWidth={isActive ? croppingChange.cropWidth : 0}
                  cropChangeHeight={isActive ? croppingChange.cropHeight : 0}
                  resizeChangeOrigin={
                    isActive
                      ? resizingOrigin
                      : ResizingTransformOrigin.LEFT_BOTTOM
                  }
                  resizeChangeWidth={isActive ? resizingChange.widthChange : 0}
                  resizeChangeHeight={
                    isActive ? resizingChange.heightChange : 0
                  }
                />
              );
            })}
        </div>
        {selectionBox && enableDnD && (
          <SelectionBox
            left={selectionBox.left - coord[0].left}
            top={selectionBox.top - coord[0].top}
            width={Math.abs(selectionBox.width)}
            height={Math.abs(selectionBox.height)}
          />
        )}
      </div>
    );
  }
);
