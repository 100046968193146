export const moveUpFactory = (
  categoryTree: { id: number }[],
  changeSort: (payload: { firstId: number; secondId: number }) => unknown
) => (id: number) => {
  const indexOfItem = categoryTree.findIndex((item) => item.id === id);
  if (indexOfItem === 0) {
    return;
  }
  changeSort({
    firstId: categoryTree[indexOfItem].id,
    secondId: categoryTree[indexOfItem - 1].id
  });
};

export const moveDownFactory = (
  categoryTree: { id: number }[],
  changeSort: (payload: { firstId: number; secondId: number }) => unknown
) => (id: number) => {
  const indexOfItem = categoryTree.findIndex((item) => item.id === id);
  if (indexOfItem === categoryTree.length - 1) {
    return;
  }
  changeSort({
    firstId: categoryTree[indexOfItem].id,
    secondId: categoryTree[indexOfItem + 1].id
  });
};
