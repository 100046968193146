import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { createPortal } from "react-dom";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

export const ModalTemplate = ({
  title,
  onCloseClick,
  className,
  footer,
  children,
  enableBackdropClick,
  headerProps,
  contentProps,
  closeButtonProps,
  hideCloseButton,
  ...props
}) => (
  <>
    {enableBackdropClick && (
      <span className="modalCloseOverlay" onClick={onCloseClick} />
    )}
    <div className={cx("modalBox", className)} {...props}>
      <h2
        {...headerProps}
        className={cx("modalHeader", headerProps && headerProps.className)}
        data-qa={MODAL_LOCATORS.header}
      >
        {title}
        {!hideCloseButton && (
          <button
            onClick={onCloseClick}
            {...closeButtonProps}
            className={cx(
              "closeBtn",
              closeButtonProps && closeButtonProps.className
            )}
            data-qa={MODAL_LOCATORS.buttons.close}
            type="button"
          />
        )}
      </h2>
      <div
        {...contentProps}
        className={cx("modalContent", contentProps && contentProps.className)}
        data-qa="modal-content"
      >
        {children}
      </div>
      {footer && (
        <div className="modalFooter" data-qa={MODAL_LOCATORS.footer}>
          {footer}
        </div>
      )}
    </div>
  </>
);

ModalTemplate.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onCloseClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  footer: PropTypes.node,
  enableBackdropClick: PropTypes.bool,
  headerProps: PropTypes.object,
  contentProps: PropTypes.object,
  closeButtonProps: PropTypes.object,
  hideCloseButton: PropTypes.bool
};

ModalTemplate.defaultProps = {
  footer: null,
  enableBackdropClick: false
};

export const Modal = (...props) =>
  createPortal(ModalTemplate(...props), document.getElementById("modal"));
