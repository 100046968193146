import PropTypes from "prop-types";

import React from "react";
import cx from "classnames";
import { Field } from "redux-form";
import Select from "react-select";
import Async from "react-select/async";

const AsyncSelectInput = ({
  meta: { error, touched },
  input: {
    // do not pass onBlur to the inner Async/Select component since it resets field's value
    // on every blur event
    // eslint-disable-next-line no-unused-vars
    onBlur, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...restInput
  },
  help,
  label,
  title,
  id,
  hidden,
  className,
  wrapperClass,
  async = true,
  dataQa,
  disabled,
  ...rest
}) => {
  const Cmp = async ? Async : Select;

  return (
    <div
      title={title}
      className={cx(
        "inputBox",
        { hidden, error: touched && error },
        wrapperClass
      )}
      id={id}
      data-qa={dataQa || `async-field-${label ? label : title}`.toLowerCase()}
    >
      {label && <label>{label}</label>}
      <Cmp
        isDisabled={disabled}
        class
        classNamePrefix="combobox"
        {...restInput}
        {...rest}
        className={cx("combobox", className)}
        data-qa="test"
      />
      {!(touched && error) && help && <strong>{help}</strong>}
      {touched && error && <strong>{error}</strong>}
    </div>
  );
};

AsyncSelectInput.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }).isRequired,
  input: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
    value: PropTypes.any
  }).isRequired,
  help: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  hidden: PropTypes.bool,
  async: PropTypes.bool,
  dataQa: PropTypes.string
};

export const AsyncSelectField = (props) => (
  // The "format={null}" must be used as workaround since the Field converts
  // null value into empty string
  <Field component={AsyncSelectInput} format={null} {...props} />
);
