import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export const FiltersContainer = ({ children }) => {
  const rootEl = useRef(null);
  const [collapsed, setCollapsed] = useState(true);
  const [collapsible, setCollapsible] = useState(true);

  useEffect(() => {
    const el = rootEl.current;

    if (!el) {
      return;
    }

    const resizeObserverRef = new ResizeObserver(() => {
      if (el) {
        const { offsetHeight, scrollHeight } = el;
        setCollapsible(!collapsed || offsetHeight < scrollHeight);
      }
    });
    resizeObserverRef.observe(el);

    return () => resizeObserverRef.unobserve(el);
  }, [rootEl.current, collapsed]);

  return (
    <div ref={rootEl} className={cx("filters", { collapsed, collapsible })}>
      {collapsible && (
        <button
          className="filterSwitch"
          onClick={() => setCollapsed(!collapsed)}
          data-qa="all-less-filter"
        >
          {collapsed ? "All filters" : "Less filters"}
        </button>
      )}
      {children}
    </div>
  );
};

FiltersContainer.propTypes = {
  children: PropTypes.node
};
