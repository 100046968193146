import { normalize } from "normalizr";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { EntityRepositoryActions } from "client/modules/entity-repository/entity-repository-reducer";

export const useNormalizeAndStore = () => {
  const dispatch = useDispatch();

  return useCallback(
    (data: any, schema: any) => {
      const { entities: repository, result } = normalize(data, schema);
      dispatch(EntityRepositoryActions.repositoryHasChanged({ repository }));
      return result;
    },
    [dispatch]
  );
};
