import React from "react";
import { MODAL_LOCATORS } from "shared/tests/locators/modal.locators";

import { DialogEvent } from "../event-types";
import { ModalDialogBase } from "./modal-dialog-base";

export const ConfirmDialog = (props: {
  open: boolean;
  title: string;
  text: string;
  subtext: string;
  confirmLabel: string;
  cancelLabel: string;
  onEvent: (eventType: DialogEvent, eventPayload?: object) => void;
}) => {
  const {
    open,
    title,
    text,
    subtext,
    onEvent,
    confirmLabel,
    cancelLabel
  } = props;
  return (
    <ModalDialogBase
      open={open}
      title={title}
      text={text}
      subtext={subtext}
      onEvent={onEvent}
      buttons={
        <React.Fragment>
          <button
            className="button buttonOutlined"
            onClick={() => onEvent(DialogEvent.ON_CANCEL)}
            data-qa={MODAL_LOCATORS.buttons.cancel}
          >
            {cancelLabel || "Cancel"}
          </button>
          <button
            className="button buttonAlert"
            onClick={() => onEvent(DialogEvent.ON_CONFIRM)}
            data-qa={MODAL_LOCATORS.buttons.confirm}
          >
            {confirmLabel || "Ok"}
          </button>
        </React.Fragment>
      }
    />
  );
};
