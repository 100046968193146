import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { ReactComponent as IconFilter } from "../../../../../images/filter.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../../images/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../../images/arrow-down.svg";
import { ReactComponent as IconEdit } from "../../../../../images/btn-edit.svg";
import { ReactComponent as IconDelete } from "../../../../../images/btn-delete.svg";
import { CustomFieldType } from "../../../../../../shared/types/custom-field-type";

export const FilterListItem = (props: {
  label: string;
  type: CustomFieldType;
  id: number;
  filterValue: string;
  customFieldId: number;
  values: string[];
  onUnAssignFilter: (payload: { id: number; label: string }) => void;
  onEdit: (id: number) => void;
  onMoveUp: (id: number) => void;
  onMoveDown: (id: number) => void;
  onMatchSelected: () => void;
}) => {
  const {
    label,
    id,
    filterValue,
    customFieldId,
    onUnAssignFilter,
    onEdit,
    onMoveUp,
    onMoveDown,
    values,
    onMatchSelected
  } = props;

  const [localFilteredState, setLocalFilteredState] = useState(true);

  useEffect(() => {
    setLocalFilteredState(
      label.toLowerCase().includes(filterValue.toLowerCase())
    );
  }, [filterValue, label]);

  const isFiltered = filterValue.trim() !== "";

  const onItemClickedHandler = useCallback(
    (e: React.MouseEvent) => {
      if (localFilteredState && filterValue) {
        e.stopPropagation();
        onMatchSelected();
      }
    },
    [localFilteredState, filterValue, onMatchSelected]
  );

  const onMoveUpHandler = useCallback(
    (e) => {
      if (isFiltered) return;
      e.stopPropagation();
      onMoveUp(id);
    },
    [isFiltered, onMoveUp, id]
  );

  const onMoveDownHandler = useCallback(
    (e) => {
      if (isFiltered) return;
      e.stopPropagation();
      onMoveDown(id);
    },
    [isFiltered, onMoveDown, id]
  );

  const onEditHandler = useCallback(() => {
    if (isFiltered) return;
    onEdit(customFieldId);
  }, [isFiltered, onEdit, customFieldId]);

  const onUnAssignHandler = useCallback(() => {
    if (isFiltered) return;
    onUnAssignFilter({ id, label });
  }, [isFiltered, onUnAssignFilter, id, label]);

  return (
    <div
      className={cx({
        gridRowDnD: true,
        filtered: !localFilteredState,
        highlighted: localFilteredState && filterValue
      })}
      onClick={onItemClickedHandler}
    >
      <div
        className={cx("gridRow", { filtered: !localFilteredState })}
        data-qa={`grid-row-${label}`}
      >
        <span className="gridIcon">
          <IconFilter />
        </span>

        <h3 className="gridRowTitle">
          {label} <span className="gridRowSubtitle">{values.join(", ")}</span>
        </h3>

        <span className="gridActions">
          <button
            type="button"
            className="button buttonIcon buttonOutlined circle"
            onClick={onMoveUpHandler}
          >
            <ArrowUpIcon />
          </button>
          <button
            type="button"
            className="button buttonIcon buttonOutlined circle"
            onClick={onMoveDownHandler}
          >
            <ArrowDownIcon />
          </button>
          <button
            type="button"
            className="button buttonIcon outlined"
            onClick={onEditHandler}
          >
            <IconEdit />
          </button>
          <button
            type="button"
            className="button buttonIcon outlined danger"
            onClick={onUnAssignHandler}
          >
            <IconDelete />
          </button>
        </span>
      </div>
    </div>
  );
};
