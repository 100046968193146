import { useCallback } from "react";
import { useDispatch, useStore } from "react-redux";

import { SceneEditorAssetActionType } from "./scene-editor-actions";
import * as UserTrackingCreators from "../../../user-tracking/user-tracking-creators";
import { SceneEditorActions } from "../../scene-editor-reducer";
import {
  SceneAssetUpdatableProperties,
  useSceneEditorUtils
} from "./use-scene-editor-utils";
import { useTracking } from "../../../user-tracking/use-tracking";
import { getSelectedSceneAssets } from "../../scene-editor-selectors";

export const useAssetPropertiesActions = () => {
  const store = useStore();
  const { updateAssets } = useSceneEditorUtils();
  const track = useTracking();
  const dispatch = useDispatch();

  const updateAssetProperties = useCallback(
    async (
      data: {
        updatedAssets: SceneAssetUpdatableProperties &
          { uuid: string; id: number }[];
        oldAssets: SceneAssetUpdatableProperties &
          { uuid: string; id: number }[];
      },
      writeToHistory = true
    ) => {
      const { updatedAssets, oldAssets } = data;

      if (writeToHistory) {
        dispatch(
          SceneEditorActions.addActionBatchToUndoStack({
            actions: [
              {
                type: SceneEditorAssetActionType.UPDATE_ASSETS_PROPERTIES,
                previousValue: {
                  updatedAssets: oldAssets,
                  oldAssets: updatedAssets
                },
                sceneAssetId: 0,
                nextValue: data
              }
            ]
          })
        );
        dispatch(SceneEditorActions.resetRedoStack());
      }

      await updateAssets(updatedAssets);
    },
    [dispatch, updateAssets]
  );

  const propertySetterFactory = useCallback(
    (propertyName: keyof SceneAssetUpdatableProperties) => async <T>(
      nextValue: T
    ) => {
      const assetsToProcess = getSelectedSceneAssets(store.getState());

      if (propertyName === "left") {
        track(UserTrackingCreators.trackSceneEditorSceneAssetMoved());
      } else if (propertyName === "positionLocked") {
        track(UserTrackingCreators.trackSceneEditorSceneAssetPositionLocked());
      } else if (propertyName === "glow") {
        track(
          UserTrackingCreators.trackSceneEditorSceneAssetGlowUpdated(
            (nextValue as unknown) as boolean
          )
        );
      } else if (propertyName === "rounded") {
        track(
          UserTrackingCreators.trackSceneEditorSceneAssetRoundedCornersUpdated(
            (nextValue as unknown) as boolean
          )
        );
      } else if (propertyName === "visible") {
        track(
          UserTrackingCreators.trackSceneEditorSceneAssetVisibleOnStartUpdated(
            (nextValue as unknown) as boolean
          )
        );
      } else if (propertyName === "scale") {
        track(
          UserTrackingCreators.trackSceneEditorSceneAssetScaleUpdated(
            (nextValue as unknown) as number
          )
        );
      } else if (propertyName === "opacity") {
        track(
          UserTrackingCreators.trackSceneEditorSceneAssetOpacityUpdated(
            (nextValue as unknown) as number
          )
        );
      }

      const normalizeProperty = <T>(propertyName: string, nextValue: T) => {
        if (propertyName === "top" || propertyName === "left") {
          return ((nextValue as unknown) as number) < 0 ? 0 : nextValue;
        }

        return nextValue;
      };

      const updatedAssets = assetsToProcess.map((asset) => {
        return {
          id: asset.id,
          uuid: asset.uuid,
          [propertyName]: normalizeProperty(propertyName, nextValue)
        };
      });
      const oldAssets = assetsToProcess.map((asset) => {
        return {
          id: asset.id,
          uuid: asset.uuid,
          [propertyName]: asset[propertyName]
        };
      });

      await updateAssetProperties({ updatedAssets, oldAssets });
    },
    [track, store, updateAssetProperties]
  );

  const setY = useCallback(propertySetterFactory("top"), [
    propertySetterFactory
  ]);

  const setX = useCallback(propertySetterFactory("left"), [
    propertySetterFactory
  ]);

  const setAutoplay = useCallback(propertySetterFactory("autoplay"), [
    propertySetterFactory
  ]);

  const setGlow = useCallback(propertySetterFactory("glow"), [
    propertySetterFactory
  ]);

  const setLoop = useCallback(propertySetterFactory("loop"), [
    propertySetterFactory
  ]);

  const setOpacity = useCallback(propertySetterFactory("opacity"), [
    propertySetterFactory
  ]);

  const setPositionLocked = useCallback(
    propertySetterFactory("positionLocked"),
    [propertySetterFactory]
  );

  const setRounded = useCallback(propertySetterFactory("rounded"), [
    propertySetterFactory
  ]);

  const setScale = useCallback(propertySetterFactory("scale"), [
    propertySetterFactory
  ]);

  const setVisible = useCallback(propertySetterFactory("visible"), [
    propertySetterFactory
  ]);

  const setCropHeight = useCallback(propertySetterFactory("cropHeight"), [
    propertySetterFactory
  ]);

  const setCropWidth = useCallback(propertySetterFactory("cropWidth"), [
    propertySetterFactory
  ]);

  const setCropOffsetX = useCallback(propertySetterFactory("cropOffsetX"), [
    propertySetterFactory
  ]);

  const setCropOffsetY = useCallback(propertySetterFactory("cropOffsetY"), [
    propertySetterFactory
  ]);

  const setHeight = useCallback(propertySetterFactory("height"), [
    propertySetterFactory
  ]);

  const setWidth = useCallback(propertySetterFactory("width"), [
    propertySetterFactory
  ]);

  return {
    setY,
    setX,
    setAutoplay,
    setGlow,
    setLoop,
    setOpacity,
    setPositionLocked,
    setRounded,
    setVisible,
    setCropHeight,
    setCropWidth,
    setCropOffsetX,
    setCropOffsetY,
    setHeight,
    setWidth,
    setScale,
    updateAssetProperties
  };
};
