/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import IconAsset from "../../icon-asset";

import { ReactComponent as IconAction } from "../../../../images/action.svg";
import { ReactComponent as IconTrashSmall } from "client/images/trash-small.svg";
import { hasQ32Features } from "client/modules/config/config-selectors";
import {
  DropPosition,
  useDropAsset,
  useSceneAssetDragSource
} from "../../scene-editor-dnd";
import { AssetReduced } from "../../../../../shared/types/asset";
import { useSceneAssetCrudActions } from "../../hooks/asset-config/use-scene-asset-crud-actions";

export const SceneAssetRow = ({
  hasOnClickAction,
  layer,
  type,
  title,
  dragging,
  canDrop,
  selected,
  onClick,
  onClickDelete,
  onEditOnClickAction,
  rootRef,
  wallNumber
}: any) => {
  const q32Features = useSelector(hasQ32Features);

  return (
    <li
      ref={rootRef}
      className={cx("dnd-item", { dragging, selected, canDrop })}
      onClick={onClick}
    >
      <IconAsset type={type} />
      <span
        className="order"
        data-qa="item-order"
        title={"Drag and drop to change order of assets in this scene"}
      >
        {layer === null ? "" : layer}
      </span>
      <span className="title" data-qa="item-title">
        {title}
      </span>
      {q32Features && hasOnClickAction && (
        <IconAction className="action" onClick={onEditOnClickAction} />
      )}
      {!dragging && (
        <a
          onClick={(ev) => {
            ev.stopPropagation();
            onClickDelete();
          }}
          data-qa={`wall-${wallNumber}-item-${title}-remove-btn`}
          title={"Remove from this scene"}
        >
          <IconTrashSmall className="icon icon-remove" />
        </a>
      )}
    </li>
  );
};

export const DndSceneAssetRow = (props: {
  sceneAsset: {
    asset: AssetReduced;
    id: number;
    wall: number;
  };
  layer: number;
  hasOnClickAction: boolean;
  onEditOnClickAction: () => void;
  selected: boolean;
  onClick: ($event: MouseEvent) => void;
  onClickDelete: () => void;
  changeSceneAssetOrder: (payload: {
    sceneAssetId: number;
    moveToIndex: number;
    wallNumber: number;
  }) => void;
}) => {
  const {
    sceneAsset,
    layer,
    hasOnClickAction,
    onEditOnClickAction,
    selected,
    onClick,
    onClickDelete,
    changeSceneAssetOrder
  } = props;

  const { createSceneAssets } = useSceneAssetCrudActions();

  const getDropIndexBaseOnDropPosition = (position: DropPosition): number => {
    if (position === DropPosition.TOP) {
      // 1st scene asset has layer = 1 -> scene asset index = layer - 1
      return layer - 1;
    }

    return layer;
  };

  const [{ isDragging }, refDrag] = useSceneAssetDragSource(sceneAsset);
  const [{ canDrop, assetOver, position }, refDrop] = useDropAsset(
    sceneAsset.id,
    (asset, dropPosition) => {
      return createSceneAssets([
        {
          asset: asset,
          index: getDropIndexBaseOnDropPosition(dropPosition),
          wallNumber: sceneAsset.wall
        }
      ]);
    },
    (sceneAssetId, dropPosition) => {
      return changeSceneAssetOrder({
        sceneAssetId,
        moveToIndex: getDropIndexBaseOnDropPosition(dropPosition),
        wallNumber: sceneAsset.wall
      });
    }
  );

  const setRef = (ref: HTMLDivElement) => {
    refDrag(ref);
    refDrop(ref);
  };

  const dropPreview = assetOver && (
    <SceneAssetRow
      hasOnClickAction={false}
      onEditOnClickAction={onEditOnClickAction}
      type={assetOver.asset.type}
      layer={null}
      title={assetOver.asset.title}
      selected={false}
      dragging={false}
      onClick={() => {}}
      onClickDelete={() => {}}
      wallNumber={sceneAsset.wall}
    />
  );

  return (
    <div
      ref={setRef}
      data-qa={`wall-${sceneAsset.wall}-item-${sceneAsset.asset.title}`}
    >
      {position === DropPosition.TOP && dropPreview}
      {!isDragging && (
        <SceneAssetRow
          hasOnClickAction={hasOnClickAction}
          onEditOnClickAction={onEditOnClickAction}
          type={sceneAsset.asset.type}
          layer={layer}
          title={sceneAsset.asset.title}
          selected={selected}
          dragging={isDragging}
          canDrop={canDrop}
          onClick={onClick}
          onClickDelete={onClickDelete}
          wallNumber={sceneAsset.wall}
        />
      )}
      {position === DropPosition.BOTTOM && dropPreview}
    </div>
  );
};
